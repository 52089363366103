import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faMinus, faPlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import Swal from 'sweetalert2';
import {useNavigate,useParams} from "react-router-dom";
import Modal from "react-modal";
import FullScreenImage from './../widgets/fullScreenImage';
import Page_message from "../components/Page_message";
import "../assets/styles/carrito.scss"

const customStyles2 = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '100',
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0.75rem',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'visible'
    },
};

const ItemCarritoModal = ({
                              producto,
                              indice,
                              ps_updateDato,
                              colour,
                              descripcion,
                              cantidad,
                              precioVenta,
                              moneda,
                              importe,
                              imagen,
                              unidadMedida,
                              IdMarket,
                              IdSucursal,
                              ps_deleteDato,
                              favorito,
                              changeFavorito,
                              ps_startModalPieza,
                              ps_startModalFavorito,
                              ps_endModal,
                              ps_pedidoCheked
                          }) => {
    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [disponible, SetDisponible] = useState(true);


    useEffect(() => {
        if (!producto.Disponible) {
            SetDisponible(false);
        }

    }, []);

    function iniciar_modal_peso() {
        if (_es_pesso()) {
            ps_startModalPieza(producto, IdMarket);
        }
    }

    function iniciar_modal_favorito() {
        ps_startModalFavorito(favorito, producto.Cantidad, producto.IdProducto, IdMarket,producto.Pesos);
    }


    function decrement_carrito() {
        if (_es_pieza()) {
            navegarDetalleProducto();
        } else {
            normal('decremento');
        }
    }

    function incremento_carrito() {
        if (_es_pieza()) {
            navegarDetalleProducto();
        } else {
            normal('incremento');
        }
    }

    function navegarDetalleProducto(){
        navigate(`/${ciudadp+'/'+sucursalp}/carrito/${producto.IdProducto}/${IdMarket}/${IdSucursal}`);
    }

    function _es_pieza() {
        if (producto) {
            return producto.EsFraccionado && producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }

    function _es_pesso() {
        if (producto) {
            return producto.EsFraccionado && !producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }
    function _es_peso_o_pieza() {
        if (producto) {
            return producto.EsFraccionado || producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }


    function _cantidad(cantidad) {
        if (cantidad % 1 === 0) {
            return cantidad;
        } else {
            return cantidad.toFixed(2);
        }

    }

    function normal(operacion) {
        if (operacion === 'incremento') {
            ps_updateDato(producto.IdProducto, 'incremento', IdMarket, null);
        } else {
            ps_updateDato(producto.IdProducto, 'decremento', IdMarket, null);
        }
    }

    // delete
    function preguntar(titulo, operacion) {
        Swal.fire({
            title: titulo,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
        }).then((result) => {
            if (result.isConfirmed) {
                operacion();
            } else if (result.isDenied) {
            }
        })
    }

    function delete_item_carrito() {
        ps_deleteDato(producto.IdProducto, IdMarket,IdSucursal);
    }

    const delete_item = (idProducto) => {
        delete_registro(idProducto,IdMarket,IdSucursal)
        // preguntar("¿Estás seguro que quieres quitar este producto de tu carrito?", delete_item_carrito);
    };

    // end delete
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const closeFullScreen = () => {
        // console.log("close");
        setShowFullScreen(false);

    };

    function valorPrecio(precio) {
        return parseFloat(precio).toFixed(2);
    }
    async function delete_registro(idproducto,idmarket,idsucursal) {
        let title= '¿Está seguro de quitar este producto del carrito?';
        let resp = await Page_message.delete_register_confirmation(title,'');
        if (resp) {
            ps_deleteDato(idproducto,idmarket,idsucursal);
        }
  }

  function _cantidad_pieza(producto){
        let cantidad = 0;
      producto.Pesos.forEach((elem)=>{
          cantidad += elem.Piezas * elem.PesoAproxMax;
      })

        return Number(cantidad).toFixed(2);
  }

    return (

        <div className={classNames("flex", "justify-between","md:px-2", "py-1","mobile_width_fit",)}
            style={{
                borderBottom:'1px solid #9CA3AF',
            }}
            >
            <div className="product_descript w-auto flex">
                <div className="flex justify-center items-center h-[9rem] max-w-[4rem]">
                    <div className="max-w-max">
                        <img className="w-100" src={producto.UrlFoto + '&size=120x120'} alt={producto.Descripcion}
                             style={{
                                 filter: ps_pedidoCheked ? 'none' : 'grayscale(100%)'
                             }}   onClick={openModal}/>
                    </div>
                </div>
                <div className="flex flex-col pl-3 h-100 relative">
                    <div>
                        <div className="flex flex-wrap">
                            <label htmlFor="" className="text-black  font-bold w-100 cursor-pointer" onClick={()=> navegarDetalleProducto()} style={{fontSize:'17px'}} >
                                {producto.Descripcion}
                            </label>
                            <label htmlFor="" className="text-black font-bold w-100" style={{fontSize:'14px'}}>
                                {_es_pieza()?(
                                    <span>Precio por Kg</span>
                                ):(
                                    <span>Precio por Und</span>
                                )

                                }
                                
                            </label>
                        </div>
                        {
                            producto.ConOferta ?
                                <div className="flex gap-2">
                                    <div className=" flex items-center gap-2  font-semibold mt-2 m-0 text-black ">
                                        <span className="text_precio">Bs. </span>
                                        <span className="text_precio text-black">  {valorPrecio(producto.PrecioOferta)}  </span>
                                        <span className="text_precio   text-zinc-400 line-through">{valorPrecio(producto.PrecioOriginal)}</span>
                                        
                                    </div>

                                </div>
                                :
                                // <p className="text-[0.8rem] text-zinc-400 font-semibold mt-2 m-0 ">
                                //     Precio u. {producto.Moneda}. {valorPrecio(producto.PrecioVenta)}
                                // </p>
                                <div className=" flex items-center gap-2 text-black  mt-2 m-0 ">
                                    <span className="text_precio">Bs. </span>
                                     <span className="text_precio"> {valorPrecio(producto.PrecioVenta)} </span>
                                </div>

                        }
                    </div>

                    <div className="cantidad flex items-end gap-1 text-white"
                         style={{minWidth: '9.4rem', maxWidth: '9.4rem'}}>
                        {disponible ?
                            <div className="flex gap-2" style={{position: 'absolute', bottom: '10px'}}>
                                <button className={classNames("bg_primario", "flex", "h-8", "w-8", "p-2", "rounded-md","border", "border-slate-200", "text-white",
                                "self-center", "justify-center", "items-center",
                                {"bg-hiperplomo_fuerte":!ps_pedidoCheked}
                            )}
                            
                                        onClick={() => decrement_carrito()}>
                                    <FontAwesomeIcon icon={faMinus}
                                                     className="text-white text-xs"/>
                                </button>
                                <div className={classNames("text-black","font-bold", "text-center","self-center", {
                                    "cursor-pointer": _es_pesso()
                                })}
                                style={{minWidth:'5rem'}}  
                                onClick={() => iniciar_modal_peso()}
                                >
                                    {
                                        _es_pesso() ?
                                        <span className="" style={{fontSize:'14px'}}>{_cantidad(producto.Cantidad) * 1000}g</span>
                                            :
                                        <span className="" style={{fontSize:'14px'}}>{_cantidad(producto.Cantidad) }Und</span>
                                    }
                                </div>
                                <button className={classNames("bg_primario", "flex", "h-8", "w-8", "p-2", "rounded-md","border", "border-slate-200", "text-white",
                                "self-center", "justify-center", "items-center",
                                {"bg-hiperplomo_fuerte":!ps_pedidoCheked}
                            )}
                                        onClick={() => incremento_carrito()}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="text-white text-xs"/>
                                </button>
                            </div>
                            :
                            <div className="flex" style={{position: 'absolute', bottom: '10px'}}>
                                <div className="agotado bg-[#FFF] bg-gray-400 text-[#FF1010] p-1 rounded-md">
                                    <span style={{
                                        fontSize: '1.8rem',
                                        fontWeight: 'bold',
                                        color: '#FF1010'
                                    }}>AGOTADO</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="product_price flex justify-end">
                <div className="grid justify-items-end pr-2 h-100">
                    <div className="flex items-center">
                        <button className="ml-2" onClick={() => iniciar_modal_favorito()}>
                            <FontAwesomeIcon icon={faHeart}
                                             className={`${(favorito) ? 'text-hiperorange' : 'text-hipergrisdark'} w-7 h-7`}
                                             style={{
                                                filter: ps_pedidoCheked ? 'none' : 'grayscale(100%)'
                                            }}
                                             />
                        </button>
                    </div>
                    <div className="flex flex-column items-center">
                        { _es_pesso() &&
                            <p className="mb-0 font-bold text-black w-100 flex justify-end">{_cantidad(producto.Cantidad)} kg</p>
                        }
                        {
                            _es_pieza() &&
                            <p className="mb-0 font-bold text-black w-100 flex justify-end">{_cantidad_pieza(producto)} kg</p>
                        }

                        <p className={`${(disponible) ? 'text-black' : 'text-black'} font-bold text_precio m-0`}
                           style={{textAlign: 'end', fontWeight: '600',minWidth:"100px"}}>
                            {producto.Moneda} {valorPrecio(producto.Importe)}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <button className={classNames("ml-2", "underline", "font-bold",
                            {"text-hiperplomo_fuerte":!ps_pedidoCheked}
                        )}
                        style={{color:ps_pedidoCheked ?'#ED6A0F':'#5A5A5A'}}
                        onClick={() => delete_item(producto.IdProducto)}>
                            Eliminar
                        </button>
                    </div>



                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                appElement={document.getElementById('root')}
                onRequestClose={closeModal}
                style={customStyles2}
                contentLabel="Example Modal"
                key={"keyModal"}

            >
                <FullScreenImage
                    imagenes={[]}
                    onClose={closeFullScreen}
                    tiene_varias_imagenes={"no"}
                    imagen={producto.UrlFoto}

                ></FullScreenImage>
            </Modal>
        </div>
    );
};

export default ItemCarritoModal;