import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";

function Capsula3({descripcion, Items}) {
    const [productos, guardarProductos] = useState([]);
    useEffect(() => {
        guardarProductos(Items);
    }, []);
    return (
        <>
            <div className="mb-10 w-100">
                <div className=" h-auto row">
                    {
                        productos &&
                        productos !== null &&
                        productos !== undefined &&
                        productos.map((producto, index) => (
                                <div className="col-sm-3 mb-3" key={index}>
                                    <div className="flex justify-center">
                                        <div className="w-[20rem] flex justify-center items-center"
                                             style={{maxHeight: '20rem'}}>
                                            <a href={producto.Url_Destino} target="_blank">
                                                <img src={producto.UrlFoto} alt=""
                                                     className=" w-[19rem]  shadow-sm "
                                                     style={{minHeight: '19rem', maxHeight: '19rem'}}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(Capsula3)