import React from "react";
import cafe from "../assets/images/cart/cafe.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faMinus, faPlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import '../assets/styles/radio.scss';

const ItemHorario = ({idHorario,Descripcion,CostoDelivery,Costo,sucursal,changeSelec,IdHorarioEspecial,tipo_entrega , is_check }) => {
     
    const changeSelec1 = (nro,e) => {
        changeSelec(idHorario,IdHorarioEspecial,sucursal,CostoDelivery,Costo);
   };


    return (
        <div className={classNames("py-1", "w-auto", "h-auto", "overflow-hidden","mb-2",
            {'bg-hiperorange border border-hiperorange text-white':is_check},
            {'border border-black ':!is_check}
        )} 
        style={{borderRadius:'10px'}} key={idHorario}>
            <div className="d-flex justify-between py-1 px-2  " >
                <label  className="custom-radio-checkbox">
                    <input className="custom-radio-checkbox__input"  type="radio"  checked={is_check} name={`radio${sucursal}`}
                        onChange={ (e) => changeSelec1( e)}
                    />
                    <span className="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                    <span className="custom-radio-checkbox__text">{Descripcion}</span>
                </label>
                {
                    tipo_entrega.Id === 2 &&
                    <div className="custom-radio-checkbox__text">
                        Costo:  &nbsp; {CostoDelivery}
                    </div>
                }

            </div>
        </div>
    );
};

export default ItemHorario;