import React, {useEffect,useState} from 'react';
import logo from '../../../assets/images/login/logo.png';
import background from '../../../assets/images/login/Background.jpg';
import texture from '../../../assets/images/login/texture_complete.jpg';
import {Link} from 'react-router-dom';
import '../../../assets/fontawesome';
import {faFacebook, faGoogle} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage,faMobileAndroidAlt,faClose} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import '../../../assets/styles/mystyle.scss'

import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/stylePageGlobals.scss";
import PhoneInput from "react-phone-number-input";
import {connect} from "react-redux";
import {get_anonimo_token,codigo_acceso,get_token,get_prefijos,activate,activate_sin_autologin, set_token_externo,terminos_condiciones} from "../../../redux/actions/auth";
import {useNavigate} from "react-router-dom";
import FacebookLogin from 'react-facebook-login';
import {FacebookLoginButton} from 'react-social-login-buttons';
import {LoginSocialFacebook} from 'reactjs-social-login';
import { GoogleOAuthProvider  } from '@react-oauth/google';
import { GoogleLogin } from 'react-google-login';
import {info_session} from "../../../redux/actions/profile";
import {useForm} from "react-hook-form";
import ButtonIcon from "../../../widgets/button_icon";
import {Circles} from "react-loader-spinner";
import ButtonText from "../../../widgets/button_text";
import '../../../assets/styles/terminos_condiciones.css'
import axios from "axios";

const containerStyle = {
    backgroundImage:
        `url(${background})`,
    width: "100%",
    height: "92.5vh",
};
const containerStyle2 = {
    backgroundImage:
        `url(${texture})`,
    width: "70%",
    borderRadius:'40px'

};


const Termino_condicion = ({array_prefijo, peticion, sendAcceso, get_prefijos,codigo_acceso,datoLogin,get_token,access_token,ps_posicion,
    activate,
    activate_sin_autologin,
    phone,
    isAuthenticated,
    authenticated,
    set_token_externo,
    ps_save_pedidos,
    ps_endModalRegistro,
    ps_opcion_numero,
    ps_check_no_anonimo,
    terminos_condiciones, info_session, loading, profile

}) => {

    const navigate = useNavigate();
    const [objectTerminoCondicion, setObjectTerminoCondicion] = useState(null)
    const [html, setHtml] = useState(null)
    const [formData, setFormData] = useState({
        token: ''
    })
    const {
        token
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    useEffect(() => {
        let token = localStorage.getItem('access_token');
        if (profile) {
            let terminos = profile.TerminoCondicion;
            if (!terminos.AceptoTerminoActual) {
                setObjectTerminoCondicion(profile.TerminoCondicion)
            }
        }
        if (!profile && token) {
            info_session();
        }
    }, [profile]);

    useEffect(() => {
        console.log("termi",objectTerminoCondicion);
        if (objectTerminoCondicion) {
            console.log("pide terminos");
            getTerminosCondiciones();
        }
    }, [objectTerminoCondicion]);

    useEffect(() => {
        // let token = localStorage.getItem('access_token');
        // if (!token) {
        //     navigate("/login");
        // }
    }, []);

    async function getTerminosCondiciones() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
        };
        try {
            const res = await axios.get(`${objectTerminoCondicion.ContenidoUrl}`, config);
            console.log("resp",res);
            if (res.status === 200) {
                setHtml(res.data)
            }
        } catch (err) {
            console.log("error ", err)
        }
    }


    const onSubmit = async (e) => {
        const result = await terminos_condiciones();
        console.log("aceptado");

        if (result) {
            // navigate("/ubicacion");
            console.log("aceptado y guardar ")
            ps_endModalRegistro();
        }
    }
   return (
        <>
            <div  className="relative w-[80%] h-auto flex justify-center content_modal_registro" style={{minHeight:'40rem'}}>
               
                <div className="hiper-color absolute bottom-0 w-full" style={containerStyle2}>
                    <div className="flex justify-end">
                        <FontAwesomeIcon icon={faClose}
                        onClick={ps_endModalRegistro}
                            className='bg-white text_primario p-1  mr-2 w-[1.3rem] h-[1.3rem] cursor-pointer'
                            style={{borderRadius:'50%'}}
                        /> 
                    </div>
                    <div className="h-[20rem] max-h-full">
                    <div className="flex justify-center">
                            <div style={{width: "90%", minHeight:'17rem', maxHeight:'17rem', overflow:'auto', borderTopLeftRadius:'20px', borderBottomLeftRadius:'20px'}} className="bg-white my-4 p-4">
                                <div dangerouslySetInnerHTML={{ __html: html }} />

                                <div className='p-4'>
                                    {
                                        loading ?
                                            <button
                                                className=" py-1 px-4 border bg-hiperorange border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between">
                                                <Circles
                                                    color="#fff"
                                                    width={20}
                                                    height={20}
                                                    ariaLabel="circles-loading"
                                                />
                                            </button>
                                            :(
                                                html!==null?(
                                                    <button
                                                    className="flex justify-center py-1 px-4 border bg-hiperorange text-white border-white hover:border-transparent rounded-lg w-full mt-2  items-center"
                                                    onClick={() => onSubmit()}>Aceptar</button>
                                                ):(
                                                    <p className='text-gray-400'>La información no se ha podido cargar</p>
                                                )
                                                
                                            )
                                            
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    );
};
const mapStateToProps = state => ({
    loading: state.Auth.loading,
    datoLogin: state.Auth.datoLogin,
    sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
    authenticated: state.Auth.authenticated,
    profile: state.Profile.profile,
    access_token: state.Auth.access_token,
    // isAuthenticated: state.Auth.isAuthenticated
    peticion: state.Peticion.peticion,
    array_prefijo: state.Auth.array_prefijo,
    sendAcceso: state.Auth.sendAcceso,
    phone: state.Auth.phone,
    isAuthenticated: state.Auth.isAuthenticated,
    

})

export default connect(mapStateToProps, {
    get_prefijos,codigo_acceso,get_token,
    activate, info_session, set_token_externo,activate_sin_autologin,terminos_condiciones
})(Termino_condicion)

// export default Login;

