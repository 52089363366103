import React, {Fragment} from 'react';
import {Menu, Popover, Transition} from '@headlessui/react';
import {Link} from 'react-router-dom';
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import {Pagination, Navigation} from "swiper";

import background_white from '../assets/images/home/fondowhite.jpg';
import qr from '../assets/images/home/qr.png';

import {
    faFacebook,
    faGooglePlus,
    faInstagram, faSnapchat,
    faTelegram, faTelegramPlane,
    faTiktok,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    MenuIcon,
    XIcon,
} from '@heroicons/react/outline';
import logo from '../assets/images/login/logo.png';
import {
    faBagShopping,
    faBars,
    faBell,
    faCamera,
    faCartShopping, faHeadset, faHeart, faLocationDot, faMagnifyingGlass, faMapLocationDot,
    faMinus,
    faMotorcycle, faPenToSquare, faPlus, faQrcode, faRightFromBracket, faShareNodes,
    faStore, faTrash, faTrashCan, faUser
} from "@fortawesome/free-solid-svg-icons";
import {ChevronDownIcon} from "@heroicons/react/solid";
 
import cafe from "../assets/images/cart/cafe.jpg";
import Modal from "react-modal";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const containerStyle3 = {
    borderRadius: '2rem',
    width: '900px',
};

const containerStyle2 = {
    borderRadius: '2rem',
    width: '100%',
};

const customStyles = {
    overlay: {
        backgroundColor: '#000000a6'
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0.75rem',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};
const customStylesFondo = {
    overlay: {
        backgroundImage:
            `url(${background_white})`,
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0.75rem',
        overflow: 'visible',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};
const customStylesFondoNoBorder = {
    overlay: {
        backgroundImage:
            `url(${background_white})`,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        border: 'none',
        background: 'none',
        overflow: 'visible',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};


function Nav() {

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalAddress, setAddress] = React.useState(false);
    const [modalPurchase, setPurchase] = React.useState(false);
    const [modalAccount, setAccount] = React.useState(false);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    const startAddress = () => setAddress(true);
    const endAddress = () => setAddress(false);
    const startPurchase = () => setPurchase(true);
    const endPurchase = () => setPurchase(false);
    const startAccount = () => setAccount(true);
    const endAccount = () => setAccount(false);


    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
   
    return (
        <>
            <Popover className="relative">
                <div className="absolute inset-0 shadow z-30 pointer-events-none" aria-hidden="true"/>
                <div className="relative z-20 bg-hiperorange">
                    <div
                        className="flex justify-between items-center px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start md:space-x-10">
                        <div>
                            <a href="#" className="flex">
                                <span className="sr-only">Workflow</span>
                                <img
                                    className="h-8 w-auto sm:h-10"
                                    src={logo}
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <Popover.Button
                                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                            </Popover.Button>
                        </div>
                        <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                            <div>
                                <div className="relative flex w-full flex-wrap items-stretch ">
                                    <input type="text"
                                     
                                           className="px-3 py-2 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded-2xl text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full pr-10 "/>
                                    <span
                                        className="z-10  leading-snug font-normal absolute text-center absolute bg-transparent rounded-2xl text-base items-center justify-center w-8 right-0 pr-3 py-2">
                                <FontAwesomeIcon icon={faCamera}/>
                              
                              </span>
                                </div>
                            </div>

                            <div>
                                <Menu as="div" className="relative inline">

                                    <Menu.Button
                                        className="text-white px-2">
                                        Modo de entrega   
                                    </Menu.Button>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="absolute left-0 mt-2 w-80 origin-top-right divide-y divide-gray-100 rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className=" pb-1">
                                                <Menu.Item>
                                                    <button
                                                        className='bg-hiperblue text-white flex w-full items-center px-4 py-2 text-sm'
                                                    >
                                                        <FontAwesomeIcon icon={faMotorcycle} style={{height: "20px"}}/>
                                                        <span className="ml-3">Delivery</span>
                                                    </button>
                                                </Menu.Item>
                                                <Menu.Item>
                                                    <button
                                                        className='flex w-full items-center px-4 py-2 text-sm'
                                                    >
                                                        <FontAwesomeIcon icon={faStore} style={{height: "20px"}}
                                                                         className="text-hiperblue"/>
                                                        <span className="ml-3">Retirar de Sucursal</span>
                                                    </button>
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <Link to="/login">
                                    <button className="text-white px-2">Ingreso</button>
                                </Link>
                                <FontAwesomeIcon icon={faWhatsapp} className="text-white px-2"
                                                 style={{fontSize: '30px'}}/> 
                                    
                                <Popover className="relative inline">
                                    {({open}) => (
                                        <>
                                            <Popover.Button
                                                className="text-white">
                                                <FontAwesomeIcon icon={faCartShopping} className="text-white px-2"
                                                                 style={{fontSize: '30px'}}/>

                                                                 
                                            </Popover.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Popover.Panel
                                                    className="absolute left-0 mt-2 px-4  rounded-b-2xl bg-white shadow-lg focus:outline-none sm:px-0 w-96">
                                                    <div
                                                        className="overflow-hidden shadow-lg w-96 ring-1 ring-black ring-opacity-5 ">
                                                        <div className="px-3 py-1">
                                                            <h3 className="text-hiperblue font-bold">Carrito</h3>
                                                            <p className="font-inter font-light text-xs">Tiene 3
                                                                productos en tu carrito</p>
                                                        </div>
                                                        <div>
                                                            <div className="bg-neutral-300 flex px-2 py-1">
                                                                <div>
                                                                    <img src={cafe} alt="cafe" width={100}
                                                                         className="rounded-xl"/>
                                                                </div>
                                                                <div
                                                                    className="flex justify-between w-full overflow-hidden">
                                                                    <div
                                                                        className="px-2 grid grid-rows-2 gap-4 content-between">
                                                                        <div>
                                                                            <h3 className="text-xs font-bold text-gray-500">Nescafe</h3>
                                                                            <div
                                                                                className="text-xs font-light text-gray-600">
                                                                                Cafe nescafe tradicion tarro 400gr
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="flex items-center justify-between  max-w-fit ">
                                                                            <div
                                                                                className="bg-hiperorange flex items-center px-2 py-0.5 rounded-2xl">
                                                                    <span
                                                                        className="text-white text-xs mr-1">Unidades</span>
                                                                                <div
                                                                                    className="flex items-center justify-between rounded overflow-hidden inline-flex">
                                                                                    <button
                                                                                        className="flex items-center justify-center w-4 h-4 border rounded-full">
                                                                                        <FontAwesomeIcon icon={faMinus}
                                                                                                         className="text-white text-xs"/>
                                                                                    </button>

                                                                                    <span
                                                                                        className="text-white text-center w-4 text-xs">2</span>
                                                                                    <button
                                                                                        className="flex items-center justify-center w-4 h-4 border rounded-full">
                                                                                        <FontAwesomeIcon icon={faPlus}
                                                                                                         className="text-white text-xs"/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <button className="ml-2">
                                                                                <FontAwesomeIcon icon={faTrashCan}
                                                                                                 className="text-hiperblue"/>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex items-center">
                                                                        <span className="text-lime-600 font-bold">BS. 7.00</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="bg-white flex px-2 py-1">
                                                                <div>
                                                                    <img src={cafe} alt="cafe" width={100}
                                                                         className="rounded-xl"/>
                                                                </div>
                                                                <div
                                                                    className="flex justify-between w-full overflow-hidden">
                                                                    <div
                                                                        className="px-2 grid grid-rows-2 gap-4 content-between">
                                                                        <div>
                                                                            <h3 className="text-xs font-bold text-gray-500">Nescafe</h3>
                                                                            <div
                                                                                className="text-xs font-light text-gray-600">
                                                                                Cafe nescafe tradicion tarro 400gr
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="flex items-center justify-between  max-w-fit ">
                                                                            <div
                                                                                className="bg-hiperorange flex items-center px-2 py-0.5 rounded-2xl">
                                                                    <span
                                                                        className="text-white text-xs mr-1">Unidades</span>
                                                                                <div
                                                                                    className="flex items-center justify-between rounded overflow-hidden inline-flex">
                                                                                    <button
                                                                                        className="flex items-center justify-center w-4 h-4 border rounded-full">
                                                                                        <FontAwesomeIcon icon={faMinus}
                                                                                                         className="text-white text-xs"/>
                                                                                    </button>

                                                                                    <span
                                                                                        className="text-white text-center w-4 text-xs">2</span>
                                                                                    <button
                                                                                        className="flex items-center justify-center w-4 h-4 border rounded-full">
                                                                                        <FontAwesomeIcon icon={faPlus}
                                                                                                         className="text-white text-xs"/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <button className="ml-2">
                                                                                <FontAwesomeIcon icon={faTrashCan}
                                                                                                 className="text-hiperblue"/>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-center">
                                                                        <span className="text-lime-600 font-bold">BS. 7.00</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="bg-neutral-300 flex px-2 py-1">
                                                                <div>
                                                                    <img src={cafe} alt="cafe" width={100}
                                                                         className="rounded-xl"/>
                                                                </div>
                                                                <div
                                                                    className="flex justify-between w-full overflow-hidden">
                                                                    <div
                                                                        className="px-2 grid grid-rows-2 gap-4 content-between">
                                                                        <div>
                                                                            <h3 className="text-xs font-bold text-gray-500">Nescafe</h3>
                                                                            <div
                                                                                className="text-xs font-light text-gray-600">
                                                                                Cafe nescafe tradicion tarro 400gr
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="flex items-center justify-between  max-w-fit ">
                                                                            <div
                                                                                className="bg-hiperorange flex items-center px-2 py-0.5 rounded-2xl">
                                                                    <span
                                                                        className="text-white text-xs mr-1">Unidades</span>
                                                                                <div
                                                                                    className="flex items-center justify-between rounded overflow-hidden inline-flex">
                                                                                    <button
                                                                                        className="flex items-center justify-center w-4 h-4 border rounded-full">
                                                                                        <FontAwesomeIcon icon={faMinus}
                                                                                                         className="text-white text-xs"/>
                                                                                    </button>

                                                                                    <span
                                                                                        className="text-white text-center w-4 text-xs">2</span>
                                                                                    <button
                                                                                        className="flex items-center justify-center w-4 h-4 border rounded-full">
                                                                                        <FontAwesomeIcon icon={faPlus}
                                                                                                         className="text-white text-xs"/>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <button className="ml-2">
                                                                                <FontAwesomeIcon icon={faTrashCan}
                                                                                                 className="text-hiperblue"/>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-center">
                                                                        <span className="text-lime-600 font-bold">BS. 7.00</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="bg-white mb-9">

                                                        </div>
                                                        <div
                                                            className="bg-hiperblue flex justify-between px-4 pt-2 pb-4 rounded-b-2xl">
                                                            <div>
                                                                <h3 className="text-white font-bold">Total</h3>
                                                                <p className="text-white font-inter font-light text-xs">Monto
                                                                    minimo de compra de 20bs</p>
                                                            </div>
                                                            <div className="grid grid-rows-2">
                                                                <span className="text-white font-bold">BS. 7.00</span>
                                                                <button
                                                                    className="bg-hiperorange px-5 py-0.5 rounded-xl">
                                                                    <span
                                                                        className="text-xs text-white font-normal">Continuar</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>
                                <Popover className="relative inline ">
                                    {({open}) => (
                                        <>
                                            <Popover.Button
                                                className="text-white">
                                                <FontAwesomeIcon icon={faBell} className="text-white px-2"
                                                                 style={{fontSize: '30px'}}/>
                                            </Popover.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Popover.Panel className="absolute right-0 mt-2 px-4 z-20">
                                                    <div
                                                        className="overflow-hidden shadow-lg w-60 z-20 ring-1 ring-black rounded-b-2xl">

                                                        <div>
                                                            <div className="bg-neutral-300 flex px-2 py-1">
                                                                <div>
                                                                    <FontAwesomeIcon icon={faHeart}
                                                                                     className="text-hiperorange px-1"
                                                                                     style={{fontSize: '20px'}}/>
                                                                </div>
                                                                <div className="grid grid-rows-3 overflow-hidden">
                                                                    <h3 className="text-xs font-bold text-hiperblue">Te
                                                                        han
                                                                        compartido una lista de compra</h3>
                                                                    <div className="text-xs font-light text-hiperblue">
                                                                        el usuario 3615621681 te ha compartido su lusta
                                                                        de compra
                                                                        "snack"
                                                                    </div>

                                                                    <div className="flex items-end mb-2">
                                                                        <button
                                                                            className="bg-hiperblue  text-white text-xs w-20 rounded-2xl mx-1 border-hiperblue border-2">
                                                                            Rechazar
                                                                        </button>
                                                                        <button
                                                                            className="bg-white text-hiperorange text-xs w-20 rounded-2xl mx-1 border-hiperorange border-2">
                                                                            ver
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="bg-white flex px-2 py-1">
                                                                <div>
                                                                    <FontAwesomeIcon icon={faHeart}
                                                                                     className="text-hiperorange px-1"
                                                                                     style={{fontSize: '20px'}}/>
                                                                </div>
                                                                <div className="grid grid-rows-3 overflow-hidden">
                                                                    <h3 className="text-xs font-bold text-hiperblue">Te
                                                                        han
                                                                        compartido una lista de compra</h3>
                                                                    <div className="text-xs font-light text-hiperblue">
                                                                        el usuario 3615621681 te ha compartido su lusta
                                                                        de compra
                                                                        "snack"
                                                                    </div>

                                                                    <div className="flex items-end mb-2">
                                                                        <button
                                                                            className="bg-hiperblue  text-white text-xs w-20 rounded-2xl mx-1 border-hiperblue border-2">
                                                                            Rechazar
                                                                        </button>
                                                                        <button
                                                                            className="bg-white text-hiperorange text-xs w-20 rounded-2xl mx-1 border-hiperorange border-2">
                                                                            ver
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>

                                <button
                                    className="bg-hiperblue text-white text-left font-inter font-light hover:text-white py-1 px-4  rounded-2xl mr-3"
                                    type="button"
                                    onClick={openModal}>
                                    <span>Sucursal</span>
                                </button>
                                <button
                                    className="bg-hiperblue text-white text-left font-inter font-light hover:text-white py-1 px-4  rounded-2xl mr-3"
                                    type="button">
                                    <span>Ir a Farmacia</span>
                                </button>
                            </div>

                            <div className="flex items-center md:ml-12">
                                <Popover className="relative inline ">
                                    {({open}) => (
                                        <>
                                            <Popover.Button
                                                className="text-white">
                                                <FontAwesomeIcon icon={faBars} style={{fontSize: '30px'}}
                                                                 className="text-white"/>
                                            </Popover.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Popover.Panel
                                                    className="absolute bg-hiperorange -right-8 px-4 z-20 rounded-bl-2xl">
                                                    <div
                                                        className=" w-52 ">
                                                        <div className="mb-11">
                                                            <ul className="list-none divide-y divide-gray-800 divide-opacity-10">
                                                                <li className="cursor-pointer relative py-2 px-3"
                                                                    onClick={startAddress}>
                                                    <span className="flex items-center">
                                                    <FontAwesomeIcon icon={faMapLocationDot} className="text-white"/>
                                                        <span className="text-white font-normal block truncate ml-2">Mis Direcciones </span>
                                                    </span>
                                                                </li>
                                                                <li className="cursor-pointer relative py-2 px-3 ">
                                                    <span className="flex items-center">
                                                    <FontAwesomeIcon icon={faBagShopping} className="text-white"/>
                                                        <span className="text-white font-normal block truncate ml-2">Mis Pedidos</span>
                                                    </span>
                                                                </li>
                                                                <li className="cursor-pointer relative py-2 px-3 "
                                                                    onClick={startPurchase}>
                                                    <span className="flex items-center">
                                                    <FontAwesomeIcon icon={faHeart} className="text-white"/>
                                                        <span className="text-white font-normal block truncate ml-2">Listas
                                                        de compras</span>
                                                    </span>
                                                                </li>
                                                                <li className="cursor-pointer relative py-2 px-3 ">
                                                                    <span className="flex items-center">
                                                                    <FontAwesomeIcon icon={faUser}
                                                                                     className="text-white"/>
                                                                        <span
                                                                            className="text-white font-normal block truncate ml-2">Mi
                                                                        cuenta</span>
                                                                    </span>
                                                                </li>
                                                                <li className="cursor-pointer relative py-2 px-3 ">
                                                    <span className="flex items-center">
                                                    <FontAwesomeIcon icon={faRightFromBracket} className="text-white"/>
                                                        <span className="text-white font-normal block truncate ml-2">Cerrar
                                                        Sesión</span>
                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="my-2">
                                                            <button
                                                                className="bg-hiperblue px-2 text-white rounded-2xl">
                                                                <FontAwesomeIcon icon={faHeadset}
                                                                                 className="text-white mr-2"/>
                                                                <span>Atención al cliente</span>
                                                            </button>
                                                        </div>

                                                    </div>
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div style={containerStyle2}>
                        <div>
                            <Swiper
                                slidesPerView={12}
                                spaceBetween={20}
                                slidesPerGroup={1}
                                loop={false}
                                loopFillGroupWithBlank={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper bg-hiperblue py-2 text-xs text-center text-white font-light"
                            >
                                <SwiperSlide>
                                    Alimentos
                                </SwiperSlide>
                                <SwiperSlide>
                                    Bebidas
                                </SwiperSlide>
                                <SwiperSlide>
                                    Carnes
                                </SwiperSlide>
                                <SwiperSlide>
                                    Congelados
                                </SwiperSlide>
                                <SwiperSlide>
                                    Cuidado del bebe
                                </SwiperSlide>
                                <SwiperSlide>
                                    Cuidado del hogar
                                </SwiperSlide>
                                <SwiperSlide>
                                    Cuidado personal
                                </SwiperSlide>
                                <SwiperSlide>
                                    farmacia eticos
                                </SwiperSlide>
                                <SwiperSlide>
                                    farmacia galenicos
                                </SwiperSlide>
                                <SwiperSlide>
                                    Fiambres
                                </SwiperSlide>
                                <SwiperSlide>
                                    Frutas y verduras
                                </SwiperSlide>
                                <SwiperSlide>
                                    Granos y hortalizadas
                                </SwiperSlide>
                                <SwiperSlide>
                                    Otros
                                </SwiperSlide>


                            </Swiper>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <div style={containerStyle2}>
                        <div>
                            <Swiper
                                slidesPerView={12}
                                spaceBetween={20}
                                slidesPerGroup={1}
                                loop={false}
                                loopFillGroupWithBlank={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper bg-hiperblue py-2 text-xs text-center text-white font-light"
                            >
                                <SwiperSlide>
                                    <Link to="/categoria">
                                        Alimentos
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/categoria">
                                        Bebidas
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/categoria">
                                        Carnes
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/categoria">
                                        Congelados
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/categoria">
                                        Cuidado del bebe
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    Cuidado del hogar
                                </SwiperSlide>
                                <SwiperSlide>
                                    Cuidado personal
                                </SwiperSlide>
                                <SwiperSlide>
                                    farmacia eticos
                                </SwiperSlide>
                                <SwiperSlide>
                                    farmacia galenicos
                                </SwiperSlide>
                                <SwiperSlide>
                                    Fiambres
                                </SwiperSlide>
                                <SwiperSlide>
                                    Frutas y verduras
                                </SwiperSlide>
                                <SwiperSlide>
                                    Granos y hortalizadas
                                </SwiperSlide>
                                <SwiperSlide>
                                    Otros
                                </SwiperSlide>


                            </Swiper>
                        </div>
                    </div>
                </div>


                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                    >
                        <div
                            className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="pt-5 pb-6 px-5 sm:pb-8">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <img
                                            className="h-8 w-auto"
                                            src={logo}
                                            alt="Workflow"
                                        />
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button
                                            className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Close menu</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                                        </Popover.Button>
                                    </div>
                                </div>

                            </div>
                            <div className="py-6 px-5">
                                <div className="grid grid-cols-2 gap-4">
                                    <a href="#"
                                       className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                                        Categorias
                                    </a>

                                    <a href="#"
                                       className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                                        modo entrga
                                    </a>

                                    <a href="#"
                                       className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                                        whatsapp
                                    </a>

                                    <a href="#"
                                       className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700">
                                        otros
                                    </a>
                                </div>
                                <div className="mt-6">
                                    <a
                                        href="#"
                                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                                    >
                                        REGISTRO
                                    </a>
                                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                                        Cliente existente?{' '}
                                        <a href="#" className="text-indigo-600 hover:text-indigo-500">
                                            INGRESO
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>


            </Popover>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div>
                    <h1 className="text-4xl font-inter font-normal text-center text-hiperblue">Seleccione una
                        sucursal</h1>
                    <div style={containerStyle3}>
                        <div>
                            <Swiper
                                slidesPerView={2}
                                spaceBetween={30}
                                slidesPerGroup={2}
                                loop={true}
                                loopFillGroupWithBlank={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="mySwiper bg-white rounded-3xl py-6"
                            >
                                <SwiperSlide>
                                    <div className="container mx-auto flex justify-end">
                                        <div className="bg-zinc-200 max-w-sm border shadow-lg rounded-xl px-4 py-5">
                                            <div className="font-inter font-bold text-center mb-2">
                                                Sucursal Blacutt
                                                <br/>
                                                1.09km
                                            </div>
                                            <div className="grid grid-cols-2 gap-4">
                                                <Link to="/">
                                                    <div className="border rounded-2xl overflow-auto">
                                                        <img
                                                            src="https://www.rsrincondelsibarita.com/Imagenes/Fondos/Frutas_y_Verduras.jpg"
                                                            alt=""/>
                                                        <div className="bg-hiperblue text-white text-center py-2">
                                                            Supermercado
                                                        </div>
                                                    </div>
                                                </Link>

                                                <div className="border rounded-2xl overflow-auto">
                                                    <img
                                                        src="https://www.rsrincondelsibarita.com/Imagenes/Fondos/Frutas_y_Verduras.jpg"
                                                        alt=""/>
                                                    <div className="bg-hiperblue text-white text-center py-2">
                                                        Farmacia
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>

                                    <div className="container mx-auto flex justify-start">
                                        <div className="bg-zinc-200 max-w-sm border shadow-lg rounded-xl px-4 py-5">
                                            <div className="font-inter font-bold text-center mb-2">
                                                Sucursal Las Brisas
                                                <br/>
                                                4.16km
                                            </div>
                                            <div className="grid grid-cols-2 gap-4">
                                                <div className="border rounded-2xl overflow-auto">
                                                    <img
                                                        src="https://www.rsrincondelsibarita.com/Imagenes/Fondos/Frutas_y_Verduras.jpg"
                                                        alt=""/>
                                                    <div className="bg-hiperblue text-white text-center py-2">
                                                        Supermercado
                                                    </div>
                                                </div>
                                                <div className="border rounded-2xl overflow-auto">
                                                    <img
                                                        src="https://www.rsrincondelsibarita.com/Imagenes/Fondos/Frutas_y_Verduras.jpg"
                                                        alt=""/>
                                                    <div className="bg-hiperblue text-white text-center py-2">
                                                        Farmacia
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalAddress}
                onAfterOpen={afterOpenModal}
                onRequestClose={endAddress}
                style={customStyles}
                contentLabel="address"
            >
                <div className="">
                    <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">Mis
                        Direcciones</h1>
                    <div>
                        <div className="grid gap-2">
                            <div
                                className="bg-hiperorange flex justify-between px-4 py-0.5 rounded-lg border-hiperorange border-2 text-white">
                                <div className="cursor-pointer">
                                    <FontAwesomeIcon icon={faLocationDot}/>
                                    <span className="ml-3">Casa</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faPenToSquare} className="mx-2 cursor-pointer"/>
                                    <FontAwesomeIcon icon={faTrash} className="cursor-pointer"/>
                                </div>
                            </div>
                            <div
                                className="flex justify-between px-4 py-0.5 rounded-lg border-hiperorange border-2 text-hiperorange">
                                <div className="cursor-pointer">
                                    <FontAwesomeIcon icon={faLocationDot}/>
                                    <span className="ml-3">Oficina</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faPenToSquare} className="mx-2 cursor-pointer"/>
                                    <FontAwesomeIcon icon={faTrash} className="cursor-pointer"/>
                                </div>
                            </div>
                            <div
                                className="flex justify-between px-4 py-0.5 rounded-lg border-hiperorange border-2 text-hiperorange">
                                <div className="cursor-pointer">
                                    <FontAwesomeIcon icon={faLocationDot}/>
                                    <span className="ml-3">Casa de la abuela</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faPenToSquare} className="mx-2 cursor-pointer"/>
                                    <FontAwesomeIcon icon={faTrash} className="cursor-pointer"/>
                                </div>
                            </div>

                        </div>
                        <div className="h-20"></div>
                        <div>
                            <button
                                className="bg-hiperblue px-4 rounded-lg border-hiperblue border-2 text-white w-full mt-5">Añadir
                                nueva dirección  
                            </button>
                        </div>

                    </div>
                </div>
            </Modal>


            {/*<Modal*/}
            {/*    isOpen={modalPurchase}*/}
            {/*    onAfterOpen={afterOpenModal}*/}
            {/*    onRequestClose={endPurchase}*/}
            {/*    style={customStylesFondo}*/}
            {/*    contentLabel="address"*/}
            {/*>*/}
            {/*    <div>*/}
            {/*        <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">*/}
            {/*            Mis listas de compras</h1>*/}
            {/*        <div>*/}
            {/*            <div className="grid gap-2">*/}
            {/*                <div className="bg-violet-600 flex justify-between px-4 py-0.5 rounded-lg border-violet-600 border-2 text-white">*/}
            {/*                    <div className="cursor-pointer">*/}
            {/*                        <FontAwesomeIcon icon={faCartShopping}/>*/}
            {/*                        <span className="ml-3">Snack</span>*/}
            {/*                    </div>*/}
            {/*                    <div>*/}
            {/*                        <span>Bs. 150.00</span>*/}
            {/*                        <FontAwesomeIcon icon={faShareNodes} className="mx-2 cursor-pointer"/>*/}
            {/*                        <FontAwesomeIcon icon={faPenToSquare} className="mx-2 cursor-pointer"/>*/}
            {/*                        <FontAwesomeIcon icon={faTrash} className="cursor-pointer"/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="bg-green-700 flex justify-between px-4 py-0.5 rounded-lg border-green-700 border-2 text-white">*/}
            {/*                    <div className="cursor-pointer">*/}
            {/*                        <FontAwesomeIcon icon={faCartShopping}/>*/}
            {/*                        <span className="ml-3">Parrillada</span>*/}
            {/*                    </div>*/}
            {/*                    <div>*/}
            {/*                        <span>Bs. 250.00</span>*/}
            {/*                        <FontAwesomeIcon icon={faShareNodes} className="mx-2 cursor-pointer"/>*/}
            {/*                        <FontAwesomeIcon icon={faPenToSquare} className="mx-2 cursor-pointer"/>*/}
            {/*                        <FontAwesomeIcon icon={faTrash} className="cursor-pointer"/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="bg-blue-500 flex justify-between px-4 py-0.5 rounded-lg border-blue-500 border-2 text-white">*/}
            {/*                    <div className="cursor-pointer">*/}
            {/*                        <FontAwesomeIcon icon={faCartShopping}/>*/}
            {/*                        <span className="ml-3">Peliculas</span>*/}
            {/*                    </div>*/}
            {/*                    <div>*/}
            {/*                        <span>Bs. 80.00</span>*/}
            {/*                        <FontAwesomeIcon icon={faShareNodes} className="mx-2 cursor-pointer"/>*/}
            {/*                        <FontAwesomeIcon icon={faPenToSquare} className="mx-2 cursor-pointer"/>*/}
            {/*                        <FontAwesomeIcon icon={faTrash} className="cursor-pointer"/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*            </div>*/}
            {/*            <div className="h-20"></div>*/}
            {/*            <div>*/}
            {/*                <button*/}
            {/*                    className="grid grid-cols-3 items-center px-4 rounded-lg border-hiperorange border-2 text-hiperorange w-full mt-5">*/}
            {/*                    <FontAwesomeIcon icon={faQrcode}/>*/}
            {/*                        <span>Scanear</span>*/}
            {/*                </button>*/}
            {/*                <button*/}
            {/*                    className="bg-hiperblue px-4 rounded-lg border-hiperblue border-2 text-white w-full mt-5">Añadir*/}
            {/*                    Nueva lista*/}
            {/*                </button>*/}

            {/*            </div>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Modal>*/}


            {/*<Modal*/}
            {/*    isOpen={modalPurchase}*/}
            {/*    onAfterOpen={afterOpenModal}*/}
            {/*    onRequestClose={endPurchase}*/}
            {/*    style={customStylesFondo}*/}
            {/*    contentLabel="address"*/}
            {/*>*/}
            {/*    <div>*/}
            {/*        <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">*/}
            {/*            Crear nueva lista</h1>*/}
            {/*        <div className="grid grid-rows-1 gap-3">*/}
            {/*            <div className="mr-3">*/}
            {/*                <label htmlFor="email" className="block text-sm font-medium text-hiperorange">*/}
            {/*                    Nombre de la lista:*/}
            {/*                </label>*/}
            {/*                <div className="mt-1">*/}
            {/*                    <input*/}
            {/*                        id="phone"*/}
            {/*                        name="phone"*/}
            {/*                        type="text"*/}
            {/*                        autoComplete="phone"*/}
            {/*                        placeholder="Nombre de la lista"*/}
            {/*                        required*/}
            {/*                        className="appearance-none block w-full px-3 py-1 border border-hiperorange rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <label htmlFor="email" className="block text-sm font-medium text-hiperorange">*/}
            {/*                    Seleccione el color:*/}
            {/*                </label>*/}
            {/*                <div className="flex justify-center border border-hiperorange px-2 py-4">*/}
            {/*                    <div className="grid grid-cols-3 grid-rows-3 gap-2 ">*/}
            {/*                        <div className="bg-blue-600 w-11 h-11 rounded-md"></div>*/}
            {/*                        <div className="bg-red-600 w-11 h-11 rounded-md"></div>*/}
            {/*                        <div className="bg-amber-900 w-11 h-11 rounded-md"></div>*/}
            {/*                        <div className="bg-green-800 w-11 h-11 rounded-md"></div>*/}
            {/*                        <div className="bg-orange-500 w-11 h-11 rounded-md"></div>*/}
            {/*                        <div className="bg-indigo-500 w-11 h-11 rounded-md"></div>*/}
            {/*                        <div className="bg-pink-500 w-11 h-11 rounded-md"></div>*/}
            {/*                        <div className="bg-black w-11 h-11 rounded-md"></div>*/}
            {/*                        <div className="bg-sky-900 w-11 h-11 rounded-md"></div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <button*/}
            {/*                    className="bg-hiperblue px-4 rounded-lg border-hiperblue border-2 text-white w-full mt-5">*/}
            {/*                    Crear*/}
            {/*                </button>*/}

            {/*            </div>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Modal>*/}


            <Modal
                isOpen={modalPurchase}
                onAfterOpen={afterOpenModal}
                onRequestClose={endPurchase}
                style={customStylesFondo}
                contentLabel="address"
            >
                <div>
                    <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                        Mis listas de comprasc</h1>
                    <div className="grid grid-rows-1 gap-3">
                        <button
                            className="bg-violet-700 px-4 rounded-lg border-violet-700 border-2 text-white w-full mt-5">
                            Compartir la lista Snak
                        </button>
                        <div className="flex justify-center">
                            <img src={qr} className="w-48"
                                 alt="logo"/>
                        </div>
                        <div>
                            <Popover className="relative inline ">
                                {({open}) => (
                                    <>
                                        <Popover.Button
                                            className="px-4 rounded-lg border-hiperorange border-2 text-hiperorange w-full mt-5">
                                            <span>Compartir por redes</span>
                                        </Popover.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Popover.Panel className="absolute mt-2 w-full">
                                                <div
                                                    className="bg-white px-4 py-4 border border-gray-500 shadow-2xl">
                                                    <div className="grid grid-cols-6 gap-2 flex justify-center">
                                                        <div className="text-center"><FontAwesomeIcon icon={faSnapchat}
                                                                                                      style={{height: "35px"}}
                                                                                                      className="text-zinc-400"/>
                                                        </div>
                                                        <div className="text-center"><FontAwesomeIcon icon={faTelegram}
                                                                                                      style={{height: "35px"}}
                                                                                                      className="text-zinc-400"/>
                                                        </div>
                                                        <div className="text-center"><FontAwesomeIcon icon={faTelegram}
                                                                                                      style={{height: "35px"}}
                                                                                                      className="text-zinc-400"/>
                                                        </div>
                                                        <div className="text-center"><FontAwesomeIcon icon={faFacebook}
                                                                                                      style={{height: "35px"}}
                                                                                                      className="text-zinc-400"/>
                                                        </div>
                                                        <div className="text-center"><FontAwesomeIcon
                                                            icon={faGooglePlus} style={{height: "35px"}}
                                                            className="text-zinc-400"/></div>
                                                        <div className="text-center"><FontAwesomeIcon icon={faInstagram}
                                                                                                      style={{height: "35px"}}
                                                                                                      className="text-zinc-400"/>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <button
                                                            className="bg-hiperblue w-full border-2 border-hiperblue text-white rounded-lg">Cancelar
                                                        </button>
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>


                            <Popover className="relative inline ">
                                {({open}) => (
                                    <>
                                        <Popover.Button
                                            className="bg-hiperblue px-4 rounded-lg border-hiperblue border-2 text-white w-full mt-5">
                                            <span>Invitar contactos</span>
                                        </Popover.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Popover.Panel className="absolute -top-56 mt-2 w-full">
                                                <div
                                                    className="bg-white px-4 py-4 border border-gray-500 shadow-2xl">
                                                    <div className="">
                                                        <div className="text-center mb-2">
                                                            <label className="text-hiperblue">Invitar Contactos</label>
                                                            <div
                                                                className="relative flex w-full flex-wrap items-stretch ">
                                                                <input type="text"
                                                                       className="relative rounded-2xl text-sm border border-hiperorange pr-10 w-full"/>
                                                                <span
                                                                    className="absolute text-center absolute text-base items-center justify-center w-8 right-0 text-hiperorange">
                                                                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="grid grid gap-2 ">
                                                            <div
                                                                className="flex justify-between bg-hipergris px-2 rounded-lg">
                                                                <div className="text-xs flex flex-col">
                                                                    <span>Max</span>
                                                                    <span>76054234</span>
                                                                </div>
                                                                <div className="flex items-center text-hiperblue">
                                                                    <FontAwesomeIcon icon={faTelegramPlane}/>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="flex justify-between bg-hipergris px-2 rounded-lg">
                                                                <div className="text-xs flex flex-col">
                                                                    <span>Max</span>
                                                                    <span>76054234</span>
                                                                </div>
                                                                <div className="flex items-center text-hiperblue">
                                                                    <FontAwesomeIcon icon={faTelegramPlane}/>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="flex justify-between bg-hipergris px-2 rounded-lg">
                                                                <div className="text-xs flex flex-col">
                                                                    <span>Max</span>
                                                                    <span>76054234</span>
                                                                </div>
                                                                <div className="flex items-center text-hiperblue">
                                                                    <FontAwesomeIcon icon={faTelegramPlane}/>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="flex justify-between bg-hipergris px-2 rounded-lg">
                                                                <div className="text-xs flex flex-col">
                                                                    <span>Max</span>
                                                                    <span>76054234</span>
                                                                </div>
                                                                <div className="flex items-center text-hiperblue">
                                                                    <FontAwesomeIcon icon={faTelegramPlane}/>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="flex justify-between bg-hipergris px-2 rounded-lg">
                                                                <div className="text-xs flex flex-col">
                                                                    <span>Max</span>
                                                                    <span>76054234</span>
                                                                </div>
                                                                <div className="flex items-center text-hiperblue">
                                                                    <FontAwesomeIcon icon={faTelegramPlane}/>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="mt-2">
                                                            <button
                                                                className="bg-hiperblue w-full border-2 border-hiperblue text-white rounded-lg">Cancelar
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>

                        </div>

                    </div>
                </div>
            </Modal>


            <Modal
                isOpen={modalAccount}
                onAfterOpen={afterOpenModal}
                onRequestClose={endAccount}
                style={customStylesFondoNoBorder}
                contentLabel="address"
            >
                <div>
                    <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                        Mi cuenta</h1>
                    <div className="w-full max-w-xs">
                        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                            <div className="mb-3">
                           
                                <label className="block text-hiperorange text-sm font-normal mb-1" htmlFor="name">
                                    Nombre:
                                </label>
                                <input
                                    className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none
                                     focus:border-hiperorange focus:ring-1"
                                    id="name" type="text" />
                            </div>
                            <div className="mb-3">
                                <label className="block text-hiperorange text-sm font-normal mb-1" htmlFor="username">
                                    Apellido:
                                </label>
                                <input
                                    className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="username" type="text" />
                            </div>
                            <div className="mb-3">
                                <label className="block text-hiperorange text-sm font-normal mb-1" htmlFor="date">
                                    Fecha de nacimiento :
                                </label>
                                <div className="grid grid-cols-3 gap-2">
                                    <input
                                        className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="username" type="text" placeholder="dia"/>
                                    <input
                                        className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="username" type="text" placeholder="mes"/>
                                    <input
                                        className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="username" type="text" placeholder="año"/>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="block text-hiperorange text-sm font-normal mb-1" htmlFor="email">
                                    Correo:
                                </label>
                                <input
                                    className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email" type="text" />
                            </div>
                            <div className="mb-3">
                                <label className="block text-hiperorange text-sm font-normal mb-1" htmlFor="ci">
                                    Documento de identidad:
                                </label>
                                <input
                                    className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="ci" type="text" />
                            </div>



                            <div className="grid grid-rows-2 gap-2">
                                <button
                                    className="bg-white w-full border border-hiperorange text-hiperorange rounded-lg">Eliminar cuenta
                                </button>
                                <button
                                    className="bg-hiperblue w-full border border-hiperblue text-white rounded-lg">Actualizar informacion
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>


        </>
    )
}

export default Nav;