import {
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    SET_AUTH_LOADING,
    REMOVE_AUTH_LOADING,
    LOGIN_SUCCESS,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    REFRESH_SUCCESS,
    REFRESH_FAIL,
    ACTIVACION_ANONIMO_SUCCESS,
    PREFIJO_SUCCESS,
    DEPARTAMENTO_SUCCESS,
    PREFIJO_FAIL,
    DEPARTAMENTO_FAIL,
    CLEAR_AUTH,
    CODE_ACCESS_EXTERM_SUCCESS,
    CODE_ACCESS_EXTERM_FAILS,


} from './types'
import axios from 'axios';
import {setSwAlert, showRespuesta} from "./swalert";
import {start_peticion, end_peticion} from "./peticion";
import storage from "../../components/Utils/storage";
import respuestas from '../../components/Alertas';



export const get_prefijos = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        }
    };


    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/markets/prefijos`, null, config);
        console.log("prefijos", res);
        if (res.status === 200) {
            dispatch({
                type: PREFIJO_SUCCESS,
                payload: res.data
            });
        } else {

            dispatch({
                type: PREFIJO_FAIL
            });


            dispatch(showRespuesta(dispatch,res) ) ;



        }
    } catch (err) {
        //console.log("pasooo error  ",err);

        dispatch({
            type: PREFIJO_FAIL
        });

        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }

}
export const get_departamento = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/markets/pais/ciudades`, config);
        if (res.status === 200) {
            dispatch({
                type: DEPARTAMENTO_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: DEPARTAMENTO_FAIL
            });

            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        dispatch({
            type: DEPARTAMENTO_FAIL
        });

        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }

}
export const check_authenticated = () => async dispatch => {
    if (localStorage.getItem('access_token')) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        const body = JSON.stringify({
            token: localStorage.getItem('access_token')
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/Info-Sesion`, body, config);

            if (res.status === 200) {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
                dispatch(showRespuesta(dispatch,res) ) ;
            }
        } catch (err) {
            dispatch({
                type: AUTHENTICATED_FAIL
            });
            console.log(" check_authenticated fais");
            dispatch(setSwAlert(respuestas.peticionError, true, 500));

        }
    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }
}

// 01
export const codigo_acceso = (telefono, prefijo, TipoEnvioAcceso) => async dispatch => {
    dispatch(start_peticion('enviando código'));
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const body = JSON.stringify({
        telefono,
        prefijo,
        TipoEnvioAcceso
    });


    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/Codigo-Acceso`, body, config);
        if (res.status === 200 && res.data.Estado === 200) {
            localStorage.setItem("phonex", telefono);
            localStorage.setItem("prefijo", prefijo);
            localStorage.setItem("tipoEnvicoAcceso", TipoEnvioAcceso);
            res.data.phone = telefono;
            dispatch({
                type: SIGNUP_SUCCESS,
                payload: res.data
            });
            dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
        } else {
            console.log("ingresa errt");
            dispatch({
                type: SIGNUP_FAIL
            });
            //dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));

            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());

    } catch (err) {
        dispatch({
            type: SIGNUP_FAIL
        });
        dispatch(end_peticion());
          //dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const codigo_acceso_externo = (token, TipoAcceso) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const body = JSON.stringify({
        token,
        TipoAcceso
    });


    try {
        console.log("get token externo 2 data  ", body);
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/codigo-acceso-por-externo2`, body, config);
        console.log("get token externo 2 respuesta API ", res);
        if (res.status === 200 && res.data.Estado === 200) {


            dispatch({
                type: CODE_ACCESS_EXTERM_SUCCESS,
                payload: res.data
            });
            dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
        } else {
            console.log("ingresa errt");
            dispatch({
                type: CODE_ACCESS_EXTERM_FAILS
            });
            //dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());

    } catch (err) {
        dispatch({
            type: CODE_ACCESS_EXTERM_FAILS
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
        // dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
    }
};
export const set_token_externo = (Token, TipoAcceso) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const body = JSON.stringify({
        Token,
        TipoAcceso
    });


    try {
        if (Token === null) {
            console.log("no se encontró la redsocialId");
            return;
        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/token-externo`, body, config);
        if (res.status === 200 && res.data.Estado === 200) {


            /*  dispatch({
                  type: CODE_ACCESS_EXTERM_SUCCESS,
                  payload: res.data
              });
              dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
              */
        } else {
            console.log("ingresa errt");
            /*   dispatch({
                   type: CODE_ACCESS_EXTERM_FAILS
               });
               dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
               */
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());

    } catch (err) {
        dispatch({
            type: CODE_ACCESS_EXTERM_FAILS
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
        // dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
    }
};

export const activate = (username, CodigoAcceso) => async dispatch => {
    console.log("activando", username);
    console.log("activando", CodigoAcceso);

    dispatch(start_peticion('activando...'));
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        }
    };
    let grant_type = "password";
    let aplicacion = "AuBeprIys2NE/1utxHE4/A==";
    let cuenta = "AuBeprIys2NE/1utxHE4/A==";
    let autologin = "Epis0XOlHDuuDhl6xSvL9Q==";
    let user = JSON.parse(username);
    const body = {
        'grant_type': grant_type,
        'aplicacion': aplicacion,
        'cuenta': cuenta,
        'username': user,
        'CodigoAcceso': CodigoAcceso,
        'Autologin': autologin
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/token`, body, config)
        if (res.status === 200) {
            console.log("rescxd",res)
            storage.set('phone', username);
            storage.set('codigo_acceso', CodigoAcceso);
            storage.set('user', 'usuario');
            storage.set('reg_phone', 'si');
            storage.remove('prefijo');

            dispatch({
                type: ACTIVATION_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: ACTIVATION_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;

        }
        dispatch(end_peticion());
    } catch (err) {
        if (typeof (err.response) !== undefined && err !== null) {
            console.log("err", err.response.data.error_description)
            dispatch(setSwAlert(err.response.data.error_description, true, 400));
        } else {
            dispatch(setSwAlert(null, true, 400));
        }
        console.log("err", err);
        dispatch({
            type: ACTIVATION_FAIL
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const activate_sin_autologin = (username, CodigoAcceso) => async dispatch => {
    console.log("activando", username);
    console.log("activando", CodigoAcceso);

    dispatch(start_peticion('activando...'));
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        }
    };
    let grant_type = "password";
    let aplicacion = "AuBeprIys2NE/1utxHE4/A==";
    let cuenta = "AuBeprIys2NE/1utxHE4/A==";
    let user = JSON.parse(username);

    console.log("user act", user);

    const body = {
        'grant_type': grant_type,
        'aplicacion': aplicacion,
        'cuenta': cuenta,
        'username': user,
        'CodigoAcceso': CodigoAcceso,
    };

    console.log("bodyxcxf", body)
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/token`, body, config)
        console.log("resp activate", res)
        if (res.status === 200) {
            storage.set('phone', username);
            storage.set('codigo_acceso', CodigoAcceso);
            storage.set('user', 'usuario');
            storage.set('reg_phone', 'si');
            storage.remove('prefijo');
            dispatch({
                type: ACTIVATION_SUCCESS,
                payload: res.data
            });
            dispatch(setSwAlert('Código activado correctamente', false, 200));

        } else {
            dispatch({
                type: ACTIVATION_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;

        }
        dispatch(end_peticion());
    } catch (err) {
        if (typeof (err.response) !== undefined && err !== null) {
            console.log("err", err.response.data.error_description)
            dispatch(setSwAlert(err.response.data.error_description, true, 400));
        } else {
            dispatch(setSwAlert(null, true, 400));
        }
        console.log("err", err);
        dispatch({
            type: ACTIVATION_FAIL
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const get_token = (Token, CodigoAcceso) => async dispatch => {
    dispatch(start_peticion('activando...'));
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        }
    };
    let grant_type = "password";
    let aplicacion = "AuBeprIys2NE/1utxHE4/A==";
    let cuenta = "AuBeprIys2NE/1utxHE4/A==";
    let autologin = "Epis0XOlHDuuDhl6xSvL9Q==";

    const body = {
        'grant_type': grant_type,
        'aplicacion': aplicacion,
        'cuenta': cuenta,
        'CodigoAcceso': CodigoAcceso,
        'Token': Token
    };


    try {
        console.log("pasametro enviados token ", body);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/token`, body, config)
        if (res.status === 200) {
            /* storage.set('phone', username);
             storage.set('codigo_acceso', CodigoAcceso);
             */
            storage.set('user', 'usuario');
            storage.remove('prefijo');
            dispatch({
                type: ACTIVATION_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: ACTIVATION_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());
    } catch (err) {
       /* if (typeof (err.response) !== undefined && err !== null) {
            console.log("err", err.response.data.error_description)
            dispatch(setSwAlert(err.response.data.error_description, true, 400));
        } else {
            dispatch(setSwAlert(null, true, 400));
        }
        */
        console.log("err", err);
        dispatch({
            type: ACTIVATION_FAIL
        });
        dispatch(end_peticion());

        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }


};

export const limpiar_auth = () => async dispatch => {
    dispatch({
        type: CLEAR_AUTH
    });
};

// 02
export const test_loading_token = (access_token) => async dispatch => {

    console.log("quiere guardar token", access_token);
    dispatch({
        type: SET_AUTH_LOADING
    });

    // console.log("estos son los parametros que se envia : ",access_token);

    try {

        setTimeout(() => {
            if (access_token != null) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {access_token}
                });
                dispatch(setSwAlert('Cuenta activada correctamente', false, 200));
            } else {
                dispatch({
                    type: ACTIVATION_FAIL
                });
                dispatch(setSwAlert('Error activando cuenta', true, 400));
            }
            dispatch({
                type: REMOVE_AUTH_LOADING
            });
        }, 2000);
    } catch (err) {
        console.log("err")
        console.log(err)
        console.log("err")
        dispatch({
            type: ACTIVATION_FAIL

        });
        dispatch({
            type: REMOVE_AUTH_LOADING
        });

        dispatch(setSwAlert('Error al conectar con el servidor, intenta mas tarde.', true, 500));
    }
};

//03
export const terminos_condiciones = () => async dispatch => {
    dispatch({
        type: SET_AUTH_LOADING
    });
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        let idTerminoCondicion = 1;
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/terminos-condiciones/${idTerminoCondicion}/aceptar?=3`, null, config);
        console.log(res)
        if (!res.data.ConError) {
            return true;
        } else {
        //    dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch({
            type: REMOVE_AUTH_LOADING
        });
    } catch (err) {
        console.log("err")
        console.log(err)
        console.log("err")
        dispatch({
            type: ACTIVATION_FAIL

        });
        dispatch({
            type: REMOVE_AUTH_LOADING
        });

        //dispatch(setSwAlert('Error al conectar con el servidor, intenta mas tarde.', true, 500));

        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

// autenticacion anonimo
export const get_anonimo_token = () => async dispatch => {
    dispatch(end_peticion());
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/Anonimo-Por-Token`, config);

        console.log("res 10.10", res);

        if (res.status === 200 && res.data.Estado === 200) {
            console.log("ingresa bien anonimo");
            console.log(res.data.Dato.Codigo);

            let CodigoAcceso = res.data.Dato.Codigo;
            let Token = res.data.Dato.Token;

            const configAcceso = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                }
            };
            let grant_type = "password";
            let aplicacion = "AuBeprIys2NE/1utxHE4/A==";
            let cuenta = "AuBeprIys2NE/1utxHE4/A==";
            let password = 'hipermaxi'

            const body = {
                'grant_type': grant_type,
                'aplicacion': aplicacion,
                'cuenta': cuenta,
                'password': password,
                'CodigoAcceso': CodigoAcceso,
                'Token': Token,
            };
            const respuesta = await axios.post(`${process.env.REACT_APP_API_URL}/token`, body, configAcceso);
            console.log("act", respuesta);

            if (respuesta.status === 200) {
                console.log("ingresa resp acti");
                storage.set('user', 'anonimo');
                dispatch({
                    type: ACTIVACION_ANONIMO_SUCCESS,
                    payload: respuesta.data
                });

            } else {
                dispatch({
                    type: ACTIVATION_FAIL
                });
                dispatch(setSwAlert(respuesta.data.Mensaje, respuesta.data.ConError, 400));
            }


        } else {
            dispatch({
                type: SIGNUP_FAIL
            });
            // dispatch(setAlert('Error activando cuenta', 'red'));
            //dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch({
            type: REMOVE_AUTH_LOADING
        });

    } catch (err) {
        dispatch({
            type: SIGNUP_FAIL
        });
        dispatch({
            type: REMOVE_AUTH_LOADING
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));

    }
};

export const refresh = () => async dispatch => {

    let username = storage.get('phone');
    let codigo_acceso = storage.get('codigo_acceso');
    let user = storage.get('user');

    //COMENTADO TEMPORALMENTE

    if (username && codigo_acceso) {
        console.log('ya inicio sesion refresh');
        console.log("fue logueado como", user);

        dispatch({
            type: SET_AUTH_LOADING
        });
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            }
        };
        let grant_type = "password";
        let aplicacion = "AuBeprIys2NE/1utxHE4/A==";
        let cuenta = "AuBeprIys2NE/1utxHE4/A==";
        let autologin = "Epis0XOlHDuuDhl6xSvL9Q==";

        const body = {
            'grant_type': grant_type,
            'aplicacion': aplicacion,
            'cuenta': cuenta,
            'username': username,
            'CodigoAcceso': codigo_acceso,
            'Autologin': autologin
        };

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/token`, body, config);
            console.log("token_refresh",res);
            if (res.status === 200) {
                dispatch({
                    type: REFRESH_SUCCESS,
                    payload: res.data
                });
            } else {
                dispatch({
                    type: REFRESH_FAIL
                });
                dispatch(showRespuesta(dispatch,res) ) ;

            }
        } catch (err) {
            dispatch({
                type: REFRESH_FAIL
            });

            dispatch(setSwAlert(respuestas.peticionError, true, 500));
        }
    } else {
        console.log("fue logueado como", user);
        if (user === 'usuario') {
            dispatch({
                type: REFRESH_FAIL
            });
        }

    }
}

