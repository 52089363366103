import React, {useEffect, useState} from "react";
import {Navigate, Outlet, useParams, useNavigate} from "react-router-dom";

import {useLocalStorage} from "react-use";
import convertSlug from "./slug";
import {
    get_ciudades,
    get_sucursales,
    get_sucursales_cerca,
    set_sucursal,
    set_tipo_entrega
} from "../../redux/actions/branch_office";
import {end_peticion_total, update_url_navigation} from "../../redux/actions/url_navegacion";
import {get_anonimo_token} from "../../redux/actions/auth";

import {connect} from "react-redux";

const ProtectedRoute = ({
                            canAtivate,
                            branch1,
                            redirectPath = "/ubicacion",
                            get_ciudades,
                            ciudades,
                            get_sucursales,
                            get_sucursales_cerca,
                            sucursales,
                            user_session_active, set_sucursal,
                            get_anonimo_token,
                            set_tipo_entrega,
                            update_url_navigation, end_peticion_total
                        }) => {
    const {ciudadp, sucursalp} = useParams();
    const [url_navigation, setUrl_navigation] = useState(null);

    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");

    const [direccion, seDireccion] = useLocalStorage("direccion");
    const [sucursal, setSucursal] = useLocalStorage("sucursal");
    const [tipo_entrega, setTipo_entrega] = useLocalStorage("tipo_entrega");

    const [phone, setPhone] = useLocalStorage("phone");
    const [codigo_acceso, setCodigoAcceso] = useLocalStorage("codigo_acceso");


    const [state_pase, setState_pase] = useState(false);


    useEffect(() => {
        if (!sesionIniciado() && verifyParametroUrl()) {
            fetchData();
        } else if (ciudades.length === 0 && sesionIniciado()) {
            fetchDataCity();
        } else if (activeUserSession() && !verifyParametroUrl()) {
            sesionParamOnload();
        } else if (verifyParametroUrl() && inactiveUserSession() && verifySucursales() && verifyCiudades() && url_navigation === null) {
            onloadSoloParametro();
        }

        //usuario nuevo inicio de sesion
        else if (inactiveUserSession() && !sesionIniciado() && !verifyCiudades() && !verifySucursales() && !verifyParametroUrl()) {
            redirectSesion();
        }


    }, [ciudades, sucursales]);

    // ciudades,sucursales
    useEffect(() => {
        if (url_navigation) {
            redirectSesion();
        }

    }, [url_navigation]);


    const fetchData = async () => {
        await get_anonimo_token();
        await get_ciudades();
        console.log(direccion)
        if (direccion) {
            await get_sucursales_cerca(direccion);
        }else{
            await get_sucursales();
        }

    };
    const fetchDataCity = async () => {
        await get_ciudades();
        console.log(direccion)
        if (direccion) {
            console.log("netra")
            await get_sucursales_cerca(direccion);
        }else{
            await get_sucursales();
        }
    };


    function sesionIniciado() {
        if (token) {
            return true;
        } else {
            return false;
        }
    }

    // USUARIO ACTIVO SESION INICIADO
    async function sesionParamOnload() {
        let ciudad = await ciudadSeleccionado(sucursal.idRegion);
        let sucurs = await sucursalSeleccionado(sucursal.IdSucursal);
        // let sucurs= sucursal;
        let sucursal_slug = await convertSlug.data_slug(sucurs.Descripcion);
        let ciudad_slug = await convertSlug.data_slug(ciudad);
        let resp = ciudad_slug + " " + sucursal_slug;
        let url_parameters = await convertSlug.data_barra(resp);
        setUrl_navigation(url_parameters);

    }


    // SECCION CUANDO LLEGA SOLO PARAMETROS
    async function onloadSoloParametro() {
        //obtener token anonimo
        let sucursal_p = await getItemSucursal(sucursalp);
        // let suc=sucursal_p.Locatarios[0];
        let suc = sucursal_p;
        if (suc.TipoEntregas.length > 0) {
            await set_tipo_entrega(suc.TipoEntregas[0])
        }
        await set_sucursal(suc);
        await register_url(suc)

        // await update_url_navigation({url_parameters, id_sucursal: sucursal.IdSucursal, id_ciudad: sucursal.IdRegion});


    }

    async function register_url(sucursal) {
        let ciudad = await ciudadSeleccionado(sucursal.idRegion);
        let sucurs = await sucursalSeleccionado(sucursal.IdSucursal);
        let sucursal_slug = await convertSlug.data_slug(sucurs.Descripcion);
        let ciudad_slug = await convertSlug.data_slug(ciudad);
        let resp = ciudad_slug + " " + sucursal_slug;
        let url_parameters = await convertSlug.data_barra(resp);
        setUrl_navigation(url_parameters);
        await update_url_navigation({url_parameters, id_sucursal: sucursal.IdSucursal, id_ciudad: sucursal.idRegion});

    }

    const obtenerTokenAnonimo = async () => {
        try {
            await get_anonimo_token();
        } catch (error) {
        }

    };

    async function getItemSucursal(slug) {
        let item_slected = null;
        sucursales.forEach((sucursal) => {
            if (!item_slected) {
                item_slected = sucursal.Locatarios.find(pre => convertSlug.data_slug(pre.Descripcion) === slug);
            }
        })

        return item_slected;


    }

    async function cargar_url_var() {
        let resp = ciudadp + " " + sucursalp;
        let url_parameters = await convertSlug.data_barra(resp);
        setUrl_navigation(url_parameters);
    }


    async function sucursalSeleccionado(IdSucursal) {
        let selected_suc = null;
        sucursales.forEach((sucursal) => {
            if (!selected_suc) {
                selected_suc = sucursal.Locatarios.find(pre => pre.IdSucursal === IdSucursal)
            }
        })
        return selected_suc;
    }

    function ciudadSeleccionado(id_region) {
        let seleccionado = ciudades.find(pre => parseInt(pre.IdRegion) === parseInt(id_region));
        return seleccionado.Nombre;
    }

    function verificar(objDato) {
        if (!inactiveUserSession()) {
            if (Object.keys(objDato).length != 0) {
                return true;
            } else {
                return false;
            }

        }

    }

    function activeUserSession() {
        if (sesionIniciado() && verificar(sucursal) && verificar(tipo_entrega) && verifyCiudades() && verifySucursales()) {
            return true;
        }
        return false;
    }

    function inactiveUserSession() {
        if (sucursal === undefined || tipo_entrega === undefined) {
            return true;
        }
        return false;
    }


    function verifyCiudades() {
        return ciudades.length > 0 ? true : false;
    }

    function verifySucursales() {
        return sucursales.length > 0 ? true : false;
    }

    function verifyParametroUrl() {
        if (sucursalp && ciudadp) {
            return true;
        }
        return false;
    }

    function redirectSesion() {
        if (activeUserSession() && !verifyParametroUrl()) {
            setState_pase(true);
            navigate("/" + url_navigation);
            return <Outlet/>;
        } else if (verifyParametroUrl()) {
            setState_pase(true);
            return <Outlet/>;
        } else if (inactiveUserSession()) {
            setState_pase(false);
            navigate("/ubicacion");
        }
    }

    if (state_pase || activeUserSession()) {
        return <Outlet/>;
    }
};
// export default ProtectedRoute;
const mapStateToProps = (state) => ({
    ciudades: state.BranchOffice.ciudades,
    sucursales: state.BranchOffice.sucursales,
    user_session_active: state.UrlNavegacion.user_session_active
});

export default connect(mapStateToProps, {
    get_ciudades,
    get_sucursales,
    set_sucursal,
    get_sucursales_cerca,
    get_anonimo_token,
    set_tipo_entrega,
    update_url_navigation,
    end_peticion_total
})(ProtectedRoute);
