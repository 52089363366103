import React, {useEffect, useState} from 'react';
import texture from '../../../assets/images/login/texture_complete.jpg';
import '../../../assets/fontawesome';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage, faMobileAndroidAlt, faClose} from "@fortawesome/free-solid-svg-icons";
import '../../../assets/styles/mystyle.scss'
import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/stylePageGlobals.scss";
import {connect} from "react-redux";
import {
    codigo_acceso,
    get_token,
    get_prefijos,
    activate,
    activate_sin_autologin,
    set_token_externo
} from "../../../redux/actions/auth";
import {useNavigate} from "react-router-dom";
import {info_session} from "../../../redux/actions/profile";
import {useForm} from "react-hook-form";

import ButtonText from "../../../widgets/button_text";
import {clear_pedidos} from "../../../redux/actions/pedido";

const containerStyle2 = {
    backgroundImage:
        `url(${texture})`,
    width: "70%",
    borderRadius: '20px'

};


const Registro_activate = ({
                               clear_pedidos,
                               array_prefijo,
                               peticion,
                               loading,
                               sendAcceso,
                               get_prefijos,
                               codigo_acceso,
                               profile,
                               datoLogin,
                               get_token,
                               access_token,
                               ps_posicion,
                               activate,
                               activate_sin_autologin,
                               phone,
                               isAuthenticated,
                               authenticated,
                               info_session,
                               set_token_externo,
                               ps_save_pedidos,
                               ps_endModalRegistro,
                               ps_opcion_numero,
                               ps_check_no_anonimo
                           }) => {

    const [counter, setCounter] = useState(60);
    const [deshabilitar, setDeshabilitar] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        CodigoAcceso: '',
        username: phone,
    })
    const {
        CodigoAcceso,
        username
    } = formData;
    const {register, handleSubmit, formState: {errors}} = useForm();
    const messages = {
        required: "Este campo es obligatorio",
        minLength: "No puede ingresar menor caracteres de lo establecido",
        maxLength: "No puede ingresar más caracteres en este campo",
        espacioBlanco: 'No es permitido espacios en blanco',
        requiredColor: 'Debe seleccionar un color',
        aceptaNumeros: 'Solo se acepta números en este campo',
        aceptaLetra: 'Solo se acepta letras en este campo'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
        aceptaNumeros: /^[0-9,-]+$/,
        aceptaLetra: /^[A-Za-z\s]+$/,
    };

    useEffect(() => {
        tiempo_permitido();
    }, []);

    useEffect(() => {
        if (access_token) {
            info_session();
        }
    }, [access_token]);
    useEffect(() => {
        console.log("profile activate", profile);
        if (profile) {
            if (profile.TerminoCondicion.AceptoTerminoActual) {
                let tipo_user = localStorage.getItem('user');
                if (profile.Ubicaciones.length > 0 && profile.Telefono !== "") {
                    // navigate("/sucursal");
                    console.log("redirigir a home");
                    // ps_check_no_anonimo();
                    ps_endModalRegistro();

                } else if (tipo_user === '"anonimo"') {
                    // navigate("/ubicacion");
                    console.log("redirigir a enviar codigo");
                }

            } else {
                // navigate("/terminoscondiciones");
                console.log("redirigir a termino y condicion");
                ps_posicion(3);
            }
        }
    }, [profile]);


    const onSubmit = async (data) => {
        ejecutar();
        await activate_sin_autologin(phone, data.codigoAcceso);
        clear_pedidos()
    }


    const ejecutar = () => {
        console.log("ejecutar guardar");
        setDeshabilitar(true);
    }

    const reenviarCodigo = () => {
        if (counter===60) {
            console.log("reenviar codigo");
            let prefijo = localStorage.getItem("prefijo");
            let tipoEnvicoAcceso = localStorage.getItem("tipoEnvicoAcceso");
            let phone = localStorage.getItem("phone");
            codigo_acceso(phone,prefijo,tipoEnvicoAcceso);
            tiempo_permitido();
            console.log(prefijo,tipoEnvicoAcceso,phone)
        }
    }


    function tiempo_permitido() {
        const interval = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        setTimeout(() => {
            console.log("limpiar");
            clearInterval(interval);
            setDeshabilitar(false);
            setCounter(60);
        }, 60000);

    }


    return (
        <>
            <div className="relative  h-auto flex justify-center content_modal_registro" style={{minHeight: '40rem'}}>

                <div className="hiper-color absolute bottom-0 w-full" style={containerStyle2}>
                    <div className="h-auto max-h-full p-4">
                        <div className="flex justify-end">
                            <FontAwesomeIcon icon={faClose}
                                             onClick={ps_endModalRegistro}
                                             className='bg-white text_primario p-1  mr-2 w-[1.3rem] h-[1.3rem] cursor-pointer'
                                             style={{borderRadius: '50%'}}
                            />
                        </div>
                        <h1 className="text-4xl font-inter font-normal text-center text-white my-3">Ingresar</h1>
                        <form onSubmit={handleSubmit(onSubmit)} className="flex justify-center mb-4">
                            <div className="box-input">
                                <div>
                                    <label htmlFor="codigo" className="block text-sm font-medium text-white">
                                        <FontAwesomeIcon icon={faMobileAndroidAlt}
                                                         style={{height: "20px"}}/> Ingresa el código
                                    </label>
                                    <div className="grid grid-cols-1">
                                        <input
                                            name="CodigoAcceso"
                                            type="text"
                                            className="shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            autoComplete='off'
                                            {...register("codigoAcceso", {
                                                required: {
                                                    value: true,
                                                    message: messages.required,
                                                },
                                                minLength: {
                                                    value: 6,
                                                    message: messages.minLength
                                                },
                                                maxLength: {
                                                    value: 6,
                                                    message: messages.maxLength
                                                },
                                                pattern: {
                                                    value: patterns.espacioBlanco,
                                                    message: messages.espacioBlanco
                                                },
                                                pattern: {
                                                    value: patterns.aceptaNumeros,
                                                    message: messages.aceptaNumeros
                                                }
                                            })}
                                        />
                                        {errors.codigoAcceso &&
                                            <small className="text-red-600">{errors.codigoAcceso.message}</small>}

                                    </div>
                                </div>
                                <div className="d-grid gap-2 mt-2 text-white">
                                    <button
                                        className="bg-transparent  font-inter font-light  py-1 px-2 border border-white  rounded-lg w-full mt-2 mb-2 flex items-center cursor-default"
                                        type="button" onClick={()=>reenviarCodigo()}>
                                        <FontAwesomeIcon icon={faMessage} style={{height: "20px"}}/> <span
                                        className="ml-1"
                                        style={{fontSize: '12px'}}>Reenviar código de nuevo en {counter} segundos</span>
                                    </button>

                                    {deshabilitar ? (
                                        <>

                                            <button
                                                disabled={deshabilitar}
                                                style={{cursor: 'not-allowed'}}
                                                className={'flex justify-center items-center py-1.5 px-4 border w-full rounded-lg bg-hiperblue hover:bg-hiperblue_hover   transition-colors duration-300 font-medium'}>

                                                Aceptar
                                            </button>
                                        </>
                                    ) : (<>

                                        <ButtonText
                                            type='submit'
                                            label='Aceptar'
                                            principal={true}
                                            className="bg-hiperblue hover:bg-hiperblue_hover text-white "
                                            onClick={() => {
                                            }}
                                        />

                                    </>)

                                    }


                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    );
};
const mapStateToProps = state => ({
    loading: state.Auth.loading,
    datoLogin: state.Auth.datoLogin,
    sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
    authenticated: state.Auth.authenticated,
    profile: state.Profile.profile,
    access_token: state.Auth.access_token,
    // isAuthenticated: state.Auth.isAuthenticated
    peticion: state.Peticion.peticion,
    array_prefijo: state.Auth.array_prefijo,
    sendAcceso: state.Auth.sendAcceso,
    phone: state.Auth.phone,
    isAuthenticated: state.Auth.isAuthenticated,


})

export default connect(mapStateToProps, {
    get_prefijos, codigo_acceso, get_token,
    activate, info_session, set_token_externo, activate_sin_autologin,clear_pedidos
})(Registro_activate)

// export default Login;

