import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import EstadoPedidoItem from "./estadoPedidoItem";



const ItemPedido = ({nropedido, metodoPago, item, fechaEntrega, sala, importeTotal, profile, pedid,refresh_pedido}) => {
    const actualizar_item = () => {
        refresh_pedido();
    }
    function formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <div
            key={item}
            className={classNames(
                {
                    'bg-[#e5e7eb]': true, 'bg-gray-300 px-2 py-2 rounded-md mb-4': true
                })}

            style={{minHeight: '5rem', height: 'auto'}}

        >
            <div className="flex justify-between w-100 px-2 text-hipergrisdark">
                <h6 className="text-gray-500 " style={{fontWeight:"bold", color:"#s43434"}}>Pedido #{nropedido}</h6>
                <span className="text-gray-500 " style={{fontWeight:"bold"}}>{fechaEntrega}</span>
                <div>

                </div>
            </div>


            <div className="flex justify-between bg-white  py-1 px-2 "
                 style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                <div>
                    <p className="mb-0 text-sm text-hipergrisdark">{sala}  </p>
                    <p className="mb-0 text-sm text-hipergrisdark">{metodoPago}</p>
                </div>
                <div className="flex items-end" style={{width :"100px"}}>
                    <p className="mb-0 text-hipergrisdark">Bs. {formatPrice(importeTotal)}</p>
                </div>
            </div>
            <EstadoPedidoItem
                key={item}
                pedido={pedid}
                profile={profile}
                actualizar_item={actualizar_item}
            >
            </EstadoPedidoItem>

        </div>
    );
};
const mapStateToProps = state => ({
    profile: state.Profile.profile,

})
export default connect(mapStateToProps, {
})(ItemPedido)
// export default ItemPedido;