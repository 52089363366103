import React, {useEffect, useState, useRef, useCallback} from "react";
import {connect} from "react-redux";
import {get_prefijos} from "../redux/actions/auth";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import axios from "axios";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";


const Prueba01 = ({}) => {


    return (
        <>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-4 bg-gray-400">
                        {/*<img src="https://apidos.hipermaxi.com//marketfile/Image?hashfile=be248db_fd86_4017_9caf_051cc1ad2194.png&co=5&size=400x400" alt=""/>*/}
                        {/*<img src="https://apidos.hipermaxi.com//marketfile/Image?hashfile=cddb81c_1040_4bba_bf67_316f531e1f4a.jpeg&co=5&size=400x400" alt=""/>*/}
                        {/*<img src="https://apidos.hipermaxi.com//marketfile/Image?hashfile=e27675d_c0de_4abc_ba79_4d4dc5dda7ac.jpg&co=5&size=400x400" alt=""/>*/}

                        <div className="imagenes-vertical" >
                            <Swiper
                                mousewheel={true}
                                navigation={true}
                                slidesPerView={2}
                                spaceBetween={2}
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                modules={[Navigation]}
                                className="h-80"
                            >
                                <SwiperSlide key={'ima1'} className="flex justify-center items-center">
                                    <div className="py-4">
                                        <div className="flex justify-center items-center">
                                            <img className="w-20"
                                                src={"https://apidos.hipermaxi.com//marketfile/Image?hashfile=be248db_fd86_4017_9caf_051cc1ad2194.png&co=5&size=400x400"}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide className="flex justify-center items-center">
                                    <div className="py-4">
                                        <div className="flex justify-center">
                                            <img className="w-20"
                                                src={'https://apidos.hipermaxi.com//marketfile/Image?hashfile=e27675d_c0de_4abc_ba79_4d4dc5dda7ac.jpg&co=5&size=400x400'}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="flex justify-center items-center">
                                    <div className="py-4">
                                        <div className="flex justify-center">
                                            <img className="w-20"
                                                src={'https://apidos.hipermaxi.com//marketfile/Image?hashfile=cddb81c_1040_4bba_bf67_316f531e1f4a.jpeg&co=5&size=400x400'}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(Prueba01)



