import {
    GET_BRANCH_OFFICE_SUCCESS,
    GET_BRANCH_OFFICE_FAIL,
    SET_BRANCH_OFFICE_SUCCESS, SET_DELIVERY_TYPE_SUCCESS, GET_BRANCH_OFFICE_CERCA_SUCCESS,
    GET_CIUDADES_SUCCESS,
    GET_CIUDAD_FAIL,
    SET_CIUDAD_SUCCESS,
    CLEAR_BRANCH_OFFICE, SET_CAMBIO_SUCURSAL

} from './types'
import axios from 'axios';
import {end_peticion, start_peticion} from "./peticion";
import {setSwAlert, showRespuesta} from "./swalert";
import respuestas from '../../components/Alertas';


export const limpiar_sucursal = () => async dispatch => {
    dispatch({
        type: CLEAR_BRANCH_OFFICE
    });
};

export const set_cambio_sucursal = (bool) => async dispatch => {
    dispatch({
        type: SET_CAMBIO_SUCURSAL,
        payload: bool
    });
};

export const get_sucursales = () => async dispatch => {
    dispatch(start_peticion(''));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        let IdMarket = 0;
        let IdTipoServicio = 0;

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/markets/activos?IdMarket=${IdMarket}&IdTipoServicio=${IdTipoServicio}`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_BRANCH_OFFICE_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_BRANCH_OFFICE_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());
    } catch (err) {
         console.log("pasoooo");
        dispatch({
            type: GET_BRANCH_OFFICE_FAIL
        });
        dispatch(end_peticion());
        console.log("get_sucursales fails");
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const get_sucursales_cerca = (direccion) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        let IdMarket = 0;
        let IdTipoServicio = 0;
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/markets/activos?IdMarket=${IdMarket}&IdTipoServicio=${IdTipoServicio}`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_BRANCH_OFFICE_CERCA_SUCCESS,
                payload: {res: res.data, direccion: direccion}
            });
        } else {
            dispatch({
                type: GET_BRANCH_OFFICE_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        dispatch({
            type: GET_BRANCH_OFFICE_FAIL
        });
        console.log("get_sucursales_cerca fails");
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};


export const set_sucursal = (sucursal) => async dispatch => {
    dispatch(set_cambio_sucursal(true))
    dispatch({
        type: SET_BRANCH_OFFICE_SUCCESS,
        payload: sucursal
    });
};
export const set_tipo_entrega = (tipo_entrega) => async dispatch => {
    dispatch({
        type: SET_DELIVERY_TYPE_SUCCESS,
        payload: tipo_entrega
    });
};

// get_ciudades

export const get_ciudades = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/markets/ciudades`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_CIUDADES_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_CIUDAD_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        dispatch({
            type: GET_CIUDAD_FAIL
        });
         console.log("get_ciudades fails ");
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

export const set_ciudad = (ciudad) => async dispatch => {
    dispatch({
        type: SET_CIUDAD_SUCCESS,
        payload: ciudad
    });
};

