import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {
    limpiar_horario
} from "../../../redux/actions/carrito";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import {useForm} from "react-hook-form";
import {eliminar_cliente_nit, get_list_metodos_pagos, save_formas_pago} from "../../../redux/actions/metodoPagos";
import ItemOpcionPago from "../../../widgets/item_opcion_pago";

import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/stylePageGlobals.scss";
import "../../../assets/styles/responsive.scss"

import Tarjeta from "./tarjeta";
import Direccion from "../direccion";
import Modal from "react-modal";
import ButtonText from "../../../widgets/button_text";
import Swal from "sweetalert2";
import ButtonClose from "../../../widgets/button_close";
import Page_message from "../../Page_message";
import classNames from "classnames";
const style_factura = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '31',
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        // borderRadius: '0.75rem',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'auto',
        padding:'0',
        background:'transparent',
        border:'none'
    },
};

function FormaPago({
                       mt_indice,
                       get_list_metodos_pagos,
                       metodopagos,
                       save_formas_pago,
                       profile,
                       tipo_entrega,
                       datos_pago,
                       limpiar_horario,
                       eliminar_cliente_nit,
                       list_carrito,
                       width,
                       height,
                       exit
                   }) {
    const [tipoDocumento, setTipoDocumento] = useState(5);
    const [pago_selec, setPagoSelec] = useState(1);
    const [facturaciones, setFacturacion] = useState([]);
    const [tarjeta_selec, setTarjetaSelec] = useState();
    const [tarjeta_online, setTarjetaOnline] = useState(false);
    const [modal_factura, setModalFactura] = useState(false);
    const {register, formState: {errors}, handleSubmit, setValue} = useForm();
    const [subPedidos, guardarsubPedidos] = useState([]);

    const endModalFactura = () => setModalFactura(false);

    function startModalFactura() {
        setModalFactura(true);
    }
    const ahorro_total = _ahorroTotal();

    function _ahorroTotal() {
        let total = 0;
        subPedidos.forEach(sp => {
            if (sp.check) {
                total += Number(sp.ahorro);
            }
        });
        return total.toFixed(2);
    }
    const messages = {
        required: "Este campo es obligatorio",
        minLength: "No puede ingresar menor caracteres de lo establecido",
        maxLength: "No puede ingresar más caracteres en este campo",
        espacioBlanco: 'No es permitido espacios en blanco',
        requiredColor: 'Debe seleccionar un color',
        aceptaNumeros: 'Solo se acepta números en este campo',
        aceptaLetra: 'Solo se acepta letras en este campo'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
        aceptaNumeros: /^[0-9,-]+$/,
        aceptaLetra: /^[A-Za-z\s]+$/,
    };
    useEffect(() => {
        if (list_carrito) {
            let subPedido = list_carrito.SubPedidos.filter((pedido) => pedido.check);
            guardarsubPedidos(subPedido);
        }
    }, [list_carrito]);

    useEffect(() => {
        if (metodopagos) {
            setFacturacion(metodopagos.Facturaciones);
        }
    }, [metodopagos]);
    useEffect(() => {
        if (facturaciones.length > 0) {
            let data = facturaciones[facturaciones.length - 1];
            setValue('nit', data.Nit);
            setValue('razon', data.RazonSocial);
        }
    }, [facturaciones]);

    useEffect(() => {
        if (datos_pago) {
            if (!datos_pago.ConError) {
                mt_indice(5);
            }

        }
    }, [datos_pago]);


    useEffect(() => {
        get_list_metodos_pagos();
        limpiar_horario();
    }, []);


    function cambiar_seleccion(id) {
        setPagoSelec(id);
        id == 12 ? setTarjetaOnline(true) : setTarjetaOnline(false);
    }

    function cambiar_seleccion_tarjeta(id) {
        setTarjetaSelec(id);
    }

    function existe_metodos_pagos() {
        return metodopagos != null;
    }

    const onSubmit = async (data) => {
        var metodo_pago = {
            IdMetodoPago: pago_selec,
            Nit: data.nit,
            RazonSocial: data.razon,
            tipoDocumento: tipoDocumento,
            complemento: data.complemento ? data.complemento : '',
            IdTarjeta: tarjeta_selec
        };
        console.log("onsubmifpago", metodo_pago);
        localStorage.setItem("metodo_pago", JSON.stringify(metodo_pago));
        await save_formas_pago(metodo_pago);
    }

    function volverAtras() {
        if (tipo_entrega.Id == 2) {
            mt_indice(3);
        } else {
            mt_indice(2);
        }
    }

    function existe_perfil() {
        return profile != null;
    }

    const seleccionar_opcion = (facturacion, e) => {
        setValue('nit', facturacion.Nit);
        setValue('razon', facturacion.RazonSocial);
        endModalFactura();
    };
    const eliminarFactura = (facturacion) => {
        // preguntar(`¿Estás seguro que quiere eliminar el nit seleccionado? \n Nit:  ${facturacion.Nit}`  , delete_item_factura,facturacion);
        let title= '¿Estás seguro que quiere eliminar el nit seleccionado?';
        let txt= `Nit:  ${facturacion.Nit}`;
        // preguntar(`¿Estás seguro que quiere eliminar el nit seleccionado? \n Nit:  ${facturacion.Nit}`  , delete_item_factura,facturacion);
        delete_registro(facturacion,title,txt);
    };

    function preguntar(titulo, operacion,facturacion) {
        Swal.fire({
            title: titulo,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
        }).then((result) => {
            if (result.isConfirmed) {
                operacion(facturacion);
            } else if (result.isDenied) {

            }
        })
    }

    function delete_item_factura(factura) {
        let dato = {
            IdCliente: 0,
            Nit: factura.Nit
        };
        eliminar_cliente_nit(dato);
    }
    async function delete_registro(factura,title,txt) {
        let resp = await Page_message.delete_register_confirmation(title,txt);
        if (resp) {
            let dato = {
                IdCliente: 0,
                Nit: factura.Nit
            };
            eliminar_cliente_nit(dato);
        }

 
  }


    function get_total_carrito() {
        let total = 0;
        let precio_no_disponible = 0;
        subPedidos.forEach((sp) => {
            if (sp.check) {
                sp.Productos.forEach(prod => {
                    if (!prod.Disponible) {
                        precio_no_disponible += prod.Importe;
                    }
                })
            }
        });

        subPedidos.forEach((sp) => {
            if (sp.check) {
                total += sp.ImporteSubtotal;
            }
        });

        let result = parseFloat(total) - parseFloat(precio_no_disponible);
        return parseFloat(result).toFixed(2);
    }



    return (
        <>
            <div className='contenedor_mobile md:w-[82%]'>
                <form className='contenedor_flex  gap-2 ' onSubmit={handleSubmit(onSubmit)}>
                    <div className="contenedor_mobile md:w-[70%]">

                        <div className="bg-white overflow-hidden rounded-hiper_g">
                            <div className="bg-hiperorange relative w-100 text-white flex items-center justify-center content_title">
                                <h2 className='p-0 m-0 title_carrito_responsivo '>Formas de pago</h2>
                                <div className="absolute right-2 ">
                                    <ButtonClose className="text-white bg-transparent"  onClick={() => exit()}/>
                                 </div>

                            </div>
                            <div className={classNames("overflow-y-auto", "container_scroll",
                                {"h-[62vh]": height <= 667},
                                {"h-[66vh]": height > 667 && height <= 843},
                                {"h-[72vh]": height > 843},
                            )}>
                                <div className=' bg-white overflow-y-auto container_scroll'>
                                    <div className="px-2 m-2 bg-hiperplomo_claro pb-2 pt-3 rounded-hiper_g  text-start">
                                        <p className="text-black fontzize_text_carrito mb-4 font-bold">Seleccionar el método de pago</p>
                                        <div className="m-auto   my-2 py-2">
                                            <div>
                                                {existe_metodos_pagos() &&
                                                    metodopagos.Metodos.map((m) =>
                                                        <ItemOpcionPago
                                                            key={m.Id}
                                                            id={m.Id}
                                                            Descripcion={m.Descripcion}
                                                            change_selec={cambiar_seleccion}
                                                            valor={pago_selec}
                                                        ></ItemOpcionPago>
                                                    )
                                                }
                                            </div>
                                            {
                                                tarjeta_online &&
                                                <Tarjeta change_selec={cambiar_seleccion_tarjeta}
                                                        valor={tarjeta_selec}
                                                ></Tarjeta>
                                            }
                                            
                                        </div>

                                    </div>
                                    <div className="m-2 p-2 rounded-hiper_g bg-hiperplomo_claro mt-3">
                                            <div className="flex space">
                                                <p  className="text-black fontzize_text_carrito mb-4 font-bold">Datos de facturación:</p>
                                                <label htmlFor="" className="text-hiperorange ml-auto underline font-bold cursor-pointer"
                                                    onClick={(e) => startModalFactura()}>Cambiar</label>
                                            </div>

                                            <div className="mb-3">
                                                <label className="block text-black fontzize_label mb-2"
                                                    htmlFor="documento">
                                                    Tipo de Documento
                                                </label>
                                                <select id="documento" name="documento"
                                                        defaultValue={tipoDocumento}
                                                        onChange={e => setTipoDocumento(e.target.value)}
                                                        // className="peer shadow-sm  border_primario rounded w-full py-0.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        className="peer h-full w-full radius_input py-2 px-3 border_black text-gray-700 focus:outline-none focus:shadow-outline "
                                                        >
                                                    <option value="1">Cedula de identidad</option>
                                                    <option value="2">Cedula de identidad extranjero</option>
                                                    <option value="3">Pasaporte</option>
                                                    <option value="4">Otros</option>
                                                    <option value="5">Nit</option>
                                                </select>
                                            </div>

                                            <div className="mb-3">
                                                <label className="block text-black fontzize_label  mb-1"
                                                    htmlFor="nit">
                                                    Nit:
                                                </label>
                                                <input
                                                    className="h-full w-full radius_input  py-2 px-3 border_black text-gray-700 focus:outline-none focus:shadow-outline "
                                                    id="nit" type="number"
                                                    name="nit"
                                                    {...register('nit',  {
                                                        required: {
                                                            value: true,
                                                            message: messages.required,
                                                        },
                                                        maxLength: {
                                                            value: 12,
                                                            message: messages.maxLength
                                                        },

                                                        pattern: {
                                                            value: patterns.aceptaNumeros,
                                                            message: messages.aceptaNumeros
                                                        }
                                                    })}
                                                />
                                                {/*
                                                {errors.nit?.type === 'required' &&
                                                    <p style={{"color": "#920909"}}>El campo nit obligatorio*</p>}
                                                */}
                                                {errors.nit &&
                                                <small className="text-red-600">{errors.nit.message}</small>}

                                            </div>
                                            <div className="mb-3">
                                                <label className="block text-black fontzize_label mb-1"
                                                    htmlFor="username">
                                                    Razon Social:
                                                </label>
                                                <input
                                                    className=" h-full w-full radius_input py-2 px-3 border_black text-gray-700 focus:outline-none focus:shadow-outline"
                                                    id="username" type="text"
                                                    name="razon"
                                                    {...register('razon', {required: true})}
                                                />

                                                {errors.razon?.type === 'required' &&
                                                <p style={{"color": "#920909"}}>El campo razón social obligatorio*</p>}
                                            </div>
                                            {
                                                tipoDocumento === "1" &&
                                                <div className="mb-3">
                                                    <label className="block text-black fontzize_label mb-1"
                                                        htmlFor="complemento">
                                                        Complemento:
                                                    </label>
                                                    <input
                                                        className=" h-full w-full radius_input py-2 px-3 border_black text-gray-700 focus:outline-none focus:shadow-outline"
                                                        id="complemento" type="text"
                                                        name="complemento"
                                                        placeholder="opcional"
                                                        {...register('complemento')}
                                                    />

                                                </div>
                                            }

                                    </div>
                                </div>
                            </div>   
                          
                        </div>

                    </div>
                    <div className="md:w-[30%]">
                    <div className="content_card_footer bg-white text-darck rounded-hiper_g "
                         style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem'}}>
                            <div className="content_child_footer mb-1">
                                <div className="px-3 py-1 bg-hiperorange mb-1 text-white" style={{borderRadius:'20px', width:'fit-content', float:'right'}}>
                                    <p className="mb-0 font-bold " style={{fontSize:'15px'}} >Bs. {ahorro_total} ahorrado</p>
                                </div>
                                <div className='flex p-1 justify-between  text-black '>
                                    <span className='text-center m-0 fontzize_text_carrito font-bold'>Total:</span>
                                    <span className='fontzize_text_carrito font-bold'>Bs {get_total_carrito()}</span>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center  text-center gap-2 text-white">
                                <ButtonText
                                    type='button'
                                    label='Volver'
                                    principal={false}
                                    className="bg-hiperorange_claro hover:bg-hiperorange_claro_hover text-black hover:text-black border border-black"
                                    onClick={() => volverAtras()}
                            
                                />
                                <ButtonText
                                    label='Continuar'
                                    type='submit'
                                    principal={true}
                                    className="bg-hiperorange hover:bg-hiperorange_hover"
                                    onClick={() =>{ }}
                                />
                            
                            </div>
                    </div>

                        {/* <ButtonText
                            type='submit'
                            label='Continuar'
                            principal={true}
                            className="bg-[#84B111] mb-2"
                            onClick={() => {
                            }}
                        />
                        <ButtonText
                            type="cancel"
                            label='Volver'
                            principal={false}
                            className="bg-[#32338E]"
                            onClick={() => volverAtras()}
                        /> */}
                    </div>
                </form>
            </div>
            <Modal
                isOpen={modal_factura}
                ariaHideApp={false}
                onRequestClose={endModalFactura}
                style={style_factura}
                contentLabel="factura"
            >
                <div className="bg-white overflow-hidden rounded-hiper_g   content_modal" style={{height:'30rem', maxHeight:'30rem'}}>
                    <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                            <h2 className='p-0 m-0 title_responsivo  '>Seleccione una razón social o nit</h2>
                            <div className="absolute right-2 ">
                                <ButtonClose className="text-white bg-transparent"  onClick={() => endModalFactura()}/>
                            </div>
                    </div>



                    <div className='overflow-auto p-4' style={{minHeight:'10rem', maxHeight:'25rem'}}>

                    {
                        facturaciones &&
                        facturaciones.length>0 ?(
                            facturaciones.map((fact,key) =>
                                <div key={key}
                                    className="flex border border-black  justify-between items-center bg-white hover:bg-hiperorange text-black hover:text-white   py-2 pl-4 pr-2 rounded w-100 mb-2 cursor-pointer"
                                    onClick={(e) => seleccionar_opcion(fact, e)}
                                    >
                                    <label
                                        className="cursor-pointer"
                                        onClick={(e) => seleccionar_opcion(fact, e)}
                                    >{fact.RazonSocial} - {fact.Nit} </label>
                                    <FontAwesomeIcon icon={faTrashCan} onClick={() => eliminarFactura(fact)} style={{fontSize:'1.4rem'}}
                                                     className="mr-1 cursor-pointer"/>
                                </div>
                            )
                        ):(
                            <span className='text-gray-400'>No existen datos registrados</span>
                        )
                        

                    }

                    </div>
                </div>
            </Modal>

        </>
    );
}

const mapStateToProps = state => ({
    metodopagos: state.MetodoPago.metodopagos,
    datos_pago: state.MetodoPago.datos_pago,
    profile: state.Profile.profile,
    tipo_entrega: state.BranchOffice.tipo_entrega,
    list_carrito: state.Carrito.list_carrito,
})

export default connect(mapStateToProps, {
    get_list_metodos_pagos, save_formas_pago, limpiar_horario,eliminar_cliente_nit
})(FormaPago)