import React, {useEffect,useState} from 'react';

import {useNavigate} from "react-router-dom";
import Map from "../../components/Map";
import {connect} from "react-redux";
import {update_ubicacion} from "../../redux/actions/location";
import BackToPage from "../../components/BackPage/BackToPage";
import {useParams} from 'react-router'
import ButtonText from "../../widgets/button_text";
import "../../assets/styles/seccionResponsive.scss";
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/responsive.scss"
import Alert from "../../components/Swalert";
import {cleadSwAlert} from "../../redux/actions/swalert";
import {useForm} from "react-hook-form";
const ActualizarDireccion = ({show_alert,redirect,cleadSwAlert,update_ubicacion, loading, locations}) => {
    const navigate = useNavigate();
    const params = useParams()
    const locationId = params.locationId


    let location = '';
    getObjectLocation();

    const [formData, setFormData] = useState({
        Id: location.Id,
        Descripcion: location.Descripcion,
        Direccion: location.Direccion,
        Referencia: location.Referencia,
        Latitud: location.Latitud,
        Longitud: location.Longitud,
        EsPrincipal: location.EsPrincipal,
    });
    const [guardado, setGuardado] = useState(false);
    const {
        Id,
        Descripcion,
        Direccion,
        Referencia,
        Latitud,
        Longitud,
        EsPrincipal
    } = formData;
    const {register, handleSubmit, formState: {errors}} = useForm();
    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});


    const messages = {
        required: "Este campo es obligatorio",
        minLength: "No puede ingresar menos de 3 caracteres en este campo",
        maxLength: "No puede ingresar más 30 caracteres en este campo",
        espacioBlanco: 'No es permitido espacios en blanco',
        requiredColor: 'Debe seleccionar un color'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
    };
    useEffect(() => {
        return () => {
            cleadSwAlert()
        };
    }, []);

    useEffect(() => {
        if (show_alert && redirect!==null) {
            if(redirect){
                console.log(redirect.type , 'modal')
                if (redirect.type === 'modal') {
                    if (redirect.ruta === 'back') {
                        window.history.back();
                    }
                }
            }
        }
    }, [show_alert]);

    useEffect(() => {
       console.log("form, dataa ",formData);
        if(guardado){
            let object = {
                type:'modal',
                type_modal: true,
                type_title: 'direccion',
                ruta : 'back'
            };
            update_ubicacion(formData, locationId,object);
        }

    }, [formData]);
    function getObjectLocation() {
        locations.forEach((item) => {
            if (item.Id === Number(locationId)) {
                location = item;
            }
        });
    }

    function setLatLng(latlng) {
        console.log("entra a sadffas",latlng)
        setFormData({
            ...formData,
            'Latitud': latlng.lat.toString(),
            'Longitud': latlng.lng.toString()
        });
    }

    const onSubmit = (data) => {


        function guardar(data) {
            setGuardado(true);
            try {
               /* let object = {
                    type:'modal',
                    type_modal: true,
                    type_title: 'direccion',
                    ruta : 'back'
                };
                */
                console.log("formData",formData)
                console.log("data ",data);

                    setFormData(
                        {
                            Id: location.Id,
                            Descripcion: data.Descripcion,
                            Direccion: data.Direccion,
                            Referencia: data.Referencia,
                            Latitud: location.Latitud,
                            Longitud: location.Longitud,
                            EsPrincipal: location.EsPrincipal,
                        }
                    )


               // update_ubicacion(formData, locationId,object);
            } catch (e) {
                console.error(e);
            }
        };
        guardar(data);
    }

    // function volverHome() {
    //     navigate("/");
    // }


    return (
        <>
             <Alert></Alert>
            <div className=" mt-12 mb-5">
                <BackToPage ps_url_page={''}></BackToPage>
                <div className="container">
                    <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                        Actualizar dirección </h1>
                    <div className=" h-auto border border-hiperorange shadow rounded-xl overflow-hidden main_container_update_direccionA">
                        <div className="container_direccion">
                            <div className="col-md-8 col-sm-12  h-[38rem]">
                                <div className='h-full w-full' style={{marginTop:'2rem'}}>
                                     <Map onlatlng={setLatLng} data={location} editable={false}  />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="grid gap-2  mt-3">
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="alias">
                                                Alias:
                                            </label>
                                            <input
                                                className="shadow-sm appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="alias" type="text" placeholder="Nombre para direccion"
                                                name="Descripcion"
                                                defaultValue={formData.Descripcion }
                                                {...register("Descripcion", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },

                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    }

                                                })}
                                            />
                                            {errors.Descripcion &&
                                            <small
                                                className="text-red-600">{errors.Descripcion.message}</small>
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="referencia">
                                                Referencia:
                                            </label>
                                            <input
                                                className="shadow-sm appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="referencia" type="text" placeholder="referencia del lugar"
                                                name="Referencia"
                                                defaultValue={formData.Referencia }

                                                {...register("Referencia", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },

                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    }

                                                })}
                                            />
                                            {errors.Referencia &&
                                            <small
                                                className="text-red-600">{errors.Referencia.message}</small>
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="direccion">
                                                Dirección:
                                            </label>
                                            <textarea
                                                className="shadow-sm appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="direccion" type="text" placeholder="dirección del lugar"
                                                rows={2}
                                                name="Direccion"
                                                defaultValue={formData.Direccion }
                                                {...register("Direccion", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },

                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    }

                                                })}
                                            >
                                            </textarea>

                                            {errors.Direccion &&
                                            <small
                                                className="text-red-600">{errors.Direccion.message}</small>
                                            }
                                        </div>

                                        <div className="grid gap-3 text-white">
                                            <ButtonText
                                                type='submit'
                                                label='Guardar'
                                                principal={true}
                                                className="bg-hiperorange hover:bg-hiperorange_hover text-white w-40"
                                                onClick={() => {
                                                }}
                                            />
{/* 
                                            <ButtonText
                                                label='Cancelar'
                                                principal={false}
                                                className="w-40 text_primario"
                                                onClick={() => volverHome()}
                                            /> */}
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};


const mapStateToProps = state => ({
    loading: state.Location.loading,
    locations: state.Location.locations,
    show_alert: state.Swalerts.show_alert,
    redirect: state.Swalerts.redirect,
})

export default connect(mapStateToProps, {
    update_ubicacion,cleadSwAlert
})(ActualizarDireccion)