import React,{useState,useRef,useCallback}   from "react";

import {Link} from "react-router-dom";
import Layout from "../../hocs/Layout";
import '../../assets/styles/scanear.scss';
// import {Navigate} from 'react-router';
import { useNavigate } from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBarcode
} from "@fortawesome/free-solid-svg-icons";

import "../../assets/styles/globalColorPrincipal.css"
import "../../assets/styles/responsive.scss"

import BarcodeRead from '../../components/Scanner/BarcodeRead'
 const state= { 
    delay: 2000,
    result: '',
    statecamara:false,
};

 
//const [estadoCamara, setEstado] = useState(false);


 

 
 const BuscarPorCodigoBarra = () => {
    const navigate = useNavigate();
    
    const buscarProducto= ()=>{
        let filtro='75046224';
        // navigate("/busqueda_producto_codigo/"+filtro.trim());
        navigate(`/categoria/productos/`+filtro.trim());
    }

    return (
        <Layout>

            <div className="container_scan fondo_scanear  h-[60rem]">
                <div className="titulo_scanear"> 
                  <span className="text-4xl">  Scanear código</span>
                  {/* <button className="btn text-white" onClick={handleClick}>scann qr</button> */}
                  {/* <input type="file" ref={fileRef} onChange={handleChange} accept=".png , .jpg" className="d-none" />  */}

                </div>
                <div className="cuadro_scan">
                    <div className="body_scan_barcode" style={{overflow:'hidden'}}>
                        <BarcodeRead/>

                    </div>
                    {/* <div className="flex mb-1">
                        <aside>
                            <ul>{data}</ul>
                        </aside>
                    </div> */}


                    <div className="w-100 items-center px-1 pt-4">   
                        <div className="contenedor_flex justify-around   justify-center  px-2 ">
                                <button    className="flex justify-center contenedor_mobile gap-2 items-center   rounded-lg
                                     text-hiperorange py-1 p-1
                                     md:w-[300px] texto_botones_scan "
                                     style={{cursor:'default'}}
                                     onClick={buscarProducto}  >
                                    <FontAwesomeIcon icon={faBarcode}  />
                                  Código Barra
                                        
                                </button>  
                                <Link to={'/'} 
                                    className="bg_secundario contenedor_mobile text-center py-1 rounded-lg p-1 border-2 text-white py-1 md:w-[300px] texto_botones_scan">
                                    <span> Salir </span>                                       
                                </Link>
                        </div>
                    </div>
                         
                </div>
             


            </div>
        </Layout>
    );
};

 export default BuscarPorCodigoBarra;