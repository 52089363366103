import React, {useEffect, useState} from 'react';
import '../../assets/styles/hiper.scss'
import logo from '../../assets/images/login/logo.png';
import background from '../../assets/images/branch_office/sucursal.jpg';
import Map from "../../components/Map";
import {Link} from "react-router-dom";
import '../../assets/styles/mystyle.scss'
import {connect} from "react-redux";
import {
    post_inicio_ubicacion,
    set_ubicacion_omitido,
    get_ubicacion,
    post_inicio_ubicacion_existente
} from "../../redux/actions/location";
import {get_anonimo_token} from "../../redux/actions/auth";
import {Circles} from "react-loader-spinner";

import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import Alert from "../../components/Swalert";
import BackToPage from "../../components/BackPage/BackToPage";
import "../../assets/styles/responsive.scss"
import {info_session} from "../../redux/actions/profile";
import ButtonText from "../../widgets/button_text";

const containerStyle = {
    backgroundImage:
        `url(${background})`,
    width: "100%",
    minHeight: "60rem",
    height: "auto",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
};

const InicioDireccion = ({
                             post_inicio_ubicacion,
                             set_ubicacion_omitido,
                             loading,
                             post_location,
                             get_anonimo_token,
                             sendAccesoAnonimo,
                             authenticated,
                             get_ubicacion,
                             locations,
                             post_inicio_ubicacion_existente,
                             profile,
                             info_session
                         }) => {

    const navigate = useNavigate();
    const [Longitud, setLongitud] = useState('');
    const [Latitud, setLatitud] = useState('');
    const [EsPrincipal, setEsPrincipal] = useState(true);
    const [opcionDireccion, setOpcionDireccion] = useState(true);
    const {register, handleSubmit, formState: {errors}, setValue} = useForm();
    const messages = {
        required: "Este campo es obligatorio",
        minLength: "No puede ingresar menor caracteres de lo establecido",
        maxLength: "No puede ingresar más caracteres en este campo",
        espacioBlanco: 'No es permitido espacios en blanco',
        requiredColor: 'Debe seleccionar un color',
        aceptaNumeros: 'Solo se acepta números en este campo',
        aceptaLetra: 'Solo se acepta letras en este campo'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
        aceptaNumeros: /^[0-9,-]+$/,
        aceptaLetra: /^[A-Za-z\s]+$/,
        // mail:/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    };

    let token_user = (localStorage.getItem('access_token'));
    let tipo_user = (localStorage.getItem('user'));


    function setLatLng(latlng) {
        setLatitud(latlng.lat);
        setLongitud(latlng.lng);
    }

    // const onSubmit = async (e) => {
    const onSubmit = async (formInfo) => {
        // e.preventDefault();
        const datos = {
            "Descripcion": formInfo.Descripcion,
            "Direccion": formInfo.Direccion,
            "Referencia": formInfo.Referencia,
            "Longitud": Longitud,
            "Latitud": Latitud,
            "EsPrincipal": EsPrincipal
        }
        console.log("dato entrda", datos);
        await post_inicio_ubicacion(datos);
    }

    useEffect(() => {
        get_token_anonimo();
    }, []);

    useEffect(() => {
        if (authenticated) {
            if ('"usuario"' === authenticated) {
                info_session();
            }
        }
    }, [authenticated]);
    useEffect(() => {
        if (profile) {
            console.log("test_profile", profile)
        }
    }, [profile]);


    useEffect(() => {
        if (post_location) {
            renderizar();
        }
    }, [post_location]);
    useEffect(() => {
        if (locations.length > 0) {
            leerDirecciones();
        }
    }, [locations]);

    async function get_token_anonimo() {
        if (token_user === null && tipo_user === null) {
            if (authenticated === null) {
                get_anonimo_token();
            }
        } else if (tipo_user !== '"anonimo"') {
            get_ubicacion();
        }
    }

    function checkedDireccion() {
        console.log("estado", opcionDireccion);
        let estado = opcionDireccion === true ? false : true;
        console.log("cambiado", estado);
        setOpcionDireccion(estado);
        set_ubicacion_omitido(estado);
    }

    function renderizar() {
        navigate("/sucursal");
    }

    const setDireccion = (value) => {
        setValue('Direccion', value);
    }


    // SI EL USUARIO INICIA CON NRO CEL. UBICA DIRECCION QUE YA TIENE Y SI ES ANONIMO PUEDE ELEGIR OPCION
    function leerDirecciones() {
        let direccionDefinido = null;

        locations.map(element => {
            if (element.EsPrincipal) {
                direccionDefinido = element;
            }
        });

        if (direccionDefinido !== null) {
            redirecToSucursal(direccionDefinido);
        }

    }

    async function redirecToSucursal(datos) {
        await post_inicio_ubicacion_existente(datos);
        renderizar();

    }

    function volver() {
        navigate("/login");
    }

    return (
        <>
            <Alert></Alert>
            <div id="wrapper">
                <div className='shadow-md w-full static top-0 left-0'>
                    <div className='md:flex items-center justify-center bg-white py-2 md:px-10 px-7'>
                        <img src={logo} width="70"
                             alt="logo"/>
                    </div>
                </div>


                <div className="main relative flex items-start justify-center pt-1" style={containerStyle}>

                    <div className='container'>
                        <BackToPage ps_url_page={'/login'}></BackToPage>
                        <div className="flex flex-wrap gap-4 justify-center w-[100%] p-2">
                            <div className=' contenedor_mobile md:w-[100%] lg:w-[60%]'>
                                <h1 className="text-3xl font-inter font-normal text-center text-white my-3">Nueva
                                    Ubicación</h1>
                                <div className="rounded-2 h-[30rem]">
                                    <Map onlatlng={setLatLng} onClicDireccion={setDireccion} editable={true}/>
                                </div>
                            </div>
                            <div className='contenedor_mobile sm:w-[100%] md:w-[30%] lg:max-w-[30%]  mt-20'>
                                <div className="inline-flex items-center">
                                    <div className="relative inline-block h-4 w-8 cursor-pointer rounded-full">
                                        <input
                                            id="auto-update"
                                            type="checkbox"
                                            checked={opcionDireccion}
                                            className="peer absolute h-4 w-8 cursor-pointer appearance-none rounded-full bg-gray-200 transition-colors duration-300 checked:bg-[#32338E] peer-checked:border-[#32338E] peer-checked:before:bg-[#32338E]"
                                            onChange={e => {
                                                checkedDireccion(
                                                    opcionDireccion,
                                                    e.target.checked
                                                );
                                            }}

                                        />
                                        <label
                                            htmlFor="auto-update"
                                            className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-pink-500 peer-checked:before:bg-pink-500"
                                        >
                                            <div
                                                className="top-2/4 left-2/4 inline-block -translate-x-2/4 -translate-y-2/4 rounded-full p-5"
                                                data-ripple-dark="true"
                                            ></div>
                                        </label>
                                    </div>
                                    <label
                                        htmlFor="auto-update"
                                        className="mt-px ml-3 mb-0 font-medium cursor-pointer select-none font-light text-gray-100"
                                    >
                                        {opcionDireccion ? (
                                            <span>Registrar dirección</span>
                                        ) : (
                                            <span>Omitir dirección</span>
                                        )
                                        }
                                    </label>
                                </div>


                                {/* <div className="form-check form-switch">
                                    <input className="form-check-input peer checked:bg-pink-500 peer-checked:before:bg-pink-500"
                                    type="checkbox" 
                                    role="switch" id="flexSwitchCheckChecked" checked={true} />
                                    <label className="form-check-label inline-block pl-[0.15rem] hover:cursor-pointer" 
                                    htmlFor="flexSwitchCheckChecked">Checked switch checkbox input</label>
                                </div> */}


                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="mb-3">
                                        <label className="block text-white text-sm font-bold mb-2" htmlFor="alias">
                                            Alias:
                                        </label>
                                        <input
                                            // className="shadow appearance-none border border-hiperorange rounded-lg w-full px-2 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            className="shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="alias" type="text" placeholder="Nombre para direccion"
                                            {...register("Descripcion", {
                                                required: {
                                                    value: true,
                                                    message: messages.required,
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: messages.minLength
                                                },
                                                maxLength: {
                                                    value: 100,
                                                    message: messages.maxLength
                                                },
                                                pattern: {
                                                    value: patterns.espacioBlanco,
                                                    message: messages.espacioBlanco
                                                },
                                            })}

                                        />
                                        {errors.Descripcion &&
                                            <small className="text-white">{errors.Descripcion.message}</small>
                                        }
                                    </div>
                                    <div className="mb-3">
                                        <label className="block text-white text-sm font-bold mb-2"
                                               htmlFor="referencia">
                                            Referencia:
                                        </label>
                                        <input
                                            // className="shadow appearance-none border border-hiperorange rounded-lg w-full px-2 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            className="shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="referencia" type="text" placeholder="Referencia del lugar"
                                            {...register("Referencia", {
                                                required: {
                                                    value: true,
                                                    message: messages.required,
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: messages.minLength
                                                },
                                                maxLength: {
                                                    value: 100,
                                                    message: messages.maxLength
                                                },
                                                pattern: {
                                                    value: patterns.espacioBlanco,
                                                    message: messages.espacioBlanco
                                                },
                                            })}
                                        />
                                        {errors.Referencia &&
                                            <small className="text-white">{errors.Referencia.message}</small>
                                        }
                                    </div>

                                    <div className="mb-3">
                                        <label className="block text-white text-sm font-bold mb-2"
                                               htmlFor="direccion">
                                            Dirección:
                                        </label>
                                        <textarea
                                            className="shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="direccion" placeholder="Dirección del lugar"
                                            row={2}
                                            {...register("Direccion", {
                                                required: {
                                                    value: true,
                                                    message: messages.required,
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: messages.minLength
                                                },
                                                maxLength: {
                                                    value: 100,
                                                    message: messages.maxLength
                                                },
                                                pattern: {
                                                    value: patterns.espacioBlanco,
                                                    message: messages.espacioBlanco
                                                },
                                            })}
                                        >
                                            </textarea>
                                        {errors.Direccion &&
                                            <small className="text-white">{errors.Direccion.message}</small>
                                        }

                                    </div>
                                    <div className="flex flex-col gap-4 mb-5">
                                        {opcionDireccion ? (
                                            <ButtonText
                                                type='submit'
                                                label='Guardar'
                                                className="bg-hiperblue hover:bg-hiperblue_hover text-white w-40"
                                                onClick={() => {
                                                }}
                                            />
                                        ) : (
                                            <ButtonText
                                                type='button'
                                                label='Continuar'
                                                className="bg-hiperblue hover:bg-hiperblue_hover text-white w-40"
                                                onClick={() => renderizar()}
                                            />
                                        )
                                        }
                                        <ButtonText
                                            type='button'
                                            label='Cancelar'
                                            className="bg-white w-40"
                                            onClick={() => volver()}
                                        />
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </>
    );
};

const mapStateToProps = state => ({
    loading: state.Location.loading,
    post_location: state.Location.post_location,
    sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
    profile: state.Profile.profile,
    authenticated: state.Auth.authenticated,
    locations: state.Location.locations

})

export default connect(mapStateToProps, {
    post_inicio_ubicacion,
    get_anonimo_token,
    set_ubicacion_omitido,
    get_ubicacion,
    post_inicio_ubicacion_existente,
    info_session
})(InicioDireccion)

