import React, {useState, useEffect} from 'react';
import ItemFavorito from "./item_favorito";
import {get_lista_compra} from '../redux/actions/shoppingList';
import {connect} from "react-redux";
import '../assets/styles/mycart.scss'
import {useNavigate,useParams} from "react-router-dom";
import ButtonText from "./button_text";
import {get_producto_item, limpiar_item_producto} from "../redux/actions/producto";
import {limpiar_data_lista_compra} from '../redux/actions/shoppingList';
import {set_cambio_favorito} from "../redux/actions/carrito";
import Alert from "../components/Swalert";
import ButtonClose from './button_close';
import '../assets/styles/responsive.scss'

const FavoritoMycart = ({
                            get_lista_compra,
                            shopping_list,
                            idProducto,
                            IdSucursal,
                            cantidad,
                            producto,
                            pesos = [],
                            get_producto_item,
                            limpiar_item_producto,
                            delete_producto= true,
                            limpiar_data_lista_compra,
                            set_cambio_favorito,carrito_favorito,
                            ps_closeModalFavorito
                        }) => {
    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();
    const [listaCompra, setListaCompra] = useState([]);
    useEffect(() => {
        if (shopping_list.length === 0) {
            get_lista_compra(IdSucursal)
        } else if (shopping_list.length > 0) {
            get_producto_item(IdSucursal, idProducto);
        }
    }, [shopping_list]);
    useEffect(() => {
        if (producto && shopping_list) {
            if (producto.EnListadoCliente) {
                let aux = shopping_list.map((lista) => {
                    let item = producto.IdListadosCompra.find(idLista => idLista === lista.Id);
                    if (item) {
                        return {
                            ...lista,
                            favorito: true
                        }
                    } else {
                        return {
                            ...lista,
                            favorito: false
                        }
                    }
                })
                setListaCompra(aux);
            } else {
                setListaCompra(shopping_list);
            }
        }
    }, [producto]);
    useEffect(() => {
        if (delete_producto) {
            return () => limpiar_item_producto();
        }
    }, []);
    useEffect(() => {
        return () => {
            limpiar_data_lista_compra();

            if(carrito_favorito){
                set_cambio_favorito(false);
                
            }
           
        }
    }, [])

    const reload_list = (idProducto, IdSucursal) => {
        get_producto_item(IdSucursal, idProducto);

    }
    const redireccionar = (e) => {
        // navigate('/nueva_lista_compra');
        navigate_menu('nueva_lista_compra');
    };
    async function navigate_menu(url_menu) {
        let param = await get_url();
        navigate(param + '/' + url_menu);
    }

    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/" + ciudadp + "/" + sucursalp);
        } else if (url_nav) {
            return ("/" + url_nav.url_parameters);
        }
    }
    return (
        <>
            <Alert></Alert>
            <div className=" estilo_modal_fav  ">
                <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                    <h2 className='p-0 m-0 title_carrito_responsivo'>Mis listas de compras</h2>
                    <div className="absolute right-2 ">
                        <ButtonClose className="text-white bg-transparent"  onClick={() => ps_closeModalFavorito()} />
                    </div>
                </div>
                <div className="flex  flex-column  h-auto">
                    <div className="px-3 pt-2">
                        <label className="text-black px-1   mb-2" style={{fontSize:'15px', fontWeight:'600'}} htmlFor="">Añadir este producto a:</label>
                        {/* h-[calc(100%-5.5rem)] */}
                        <div className="flex flex-column h-[20rem] overflow-y-auto gap-1.5 px-1 text-white container_scroll">
                            {listaCompra && producto &&
                                listaCompra !== null &&
                                listaCompra.map((item_shopping_list) =>
                                    <ItemFavorito
                                        ps_shopping_list={item_shopping_list}
                                        key={item_shopping_list.Id}
                                        idProducto={idProducto}
                                        IdSucursal={IdSucursal}
                                        cantidad={cantidad}
                                        pesos={pesos}
                                        peso_definitivo={producto.PesoDefinido}
                                        favorito={item_shopping_list.favorito}
                                        recargar_listado={reload_list}
                                    >
                                    </ItemFavorito>
                                )
                            }
                        </div>
                    </div>

                    <div className="text-center pt-3 pb-4 px-4 text-white">
                        <ButtonText
                            label='Nueva lista'
                            className="bg-hiperorange hover:bg-hiperorange_hover"
                            onClick={() => redireccionar()}
                        />
                    </div>
                </div>
            </div>


        </>
    );
};
const mapStateToProps = state => ({
    shopping_list: state.Shopping_list.shopping_list,
    producto: state.Producto.producto,
    carrito_favorito:state.Carrito.carrito_favorito

})

export default connect(mapStateToProps, {
    get_lista_compra,  get_producto_item, limpiar_item_producto,limpiar_data_lista_compra,set_cambio_favorito
})(FavoritoMycart)

// export default Favorito;