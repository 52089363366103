import React from "react";
import classNames from "classnames";
import "../assets/styles/globalColorPrincipal.css";
import "../assets/styles/confirmacionPedido.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

const ItemConfirmacionPedido = ({
                                    colour,
                                    producto,
                                    idProducto,
                                    descripcion,
                                    cantidad,
                                    precioVenta,
                                    moneda,
                                    importe,
                                    imagen,
                                    IdMarket,
                                    unidadMedida
                                }) => {


    function formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    function _es_pieza() {
        if (producto) {
            return producto.EsFraccionado && producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }

    function _es_pesso() {
        if (producto) {
            return producto.EsFraccionado && !producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }

    function valorPrecio(precio) {
        return parseFloat(precio).toFixed(2);
    }

    function _cantidad_pieza(producto){
        let cantidad = 0;
        producto.Pesos.forEach((elem)=>{
            cantidad += elem.Piezas * elem.PesoAproxMax;
        })

        return cantidad;
    }
    return (
        <>
            <div className="flex justify-between md:px-2 py-1 mobile_width_fit"
                 style={{borderBottom: '1px solid #9CA3AF'}}>
                <div className="product_descript w-auto flex">
                    <div className="flex justify-center items-center h-[9rem] max-w-[4rem]">
                        <div className="max-w-max">
                            <img className="w-100" src={imagen + '&size=120x120'} alt={descripcion}/>
                        </div>
                    </div>
                    <div className="flex flex-col pl-3 h-100 relative">
                        <div>
                            <div className="flex flex-wrap">
                                <label htmlFor="" className="text-black  font-bold w-100" style={{fontSize: '17px'}}>
                                    {producto.Descripcion}
                                </label>
                                <label htmlFor="" className="text-black font-bold w-100" style={{fontSize: '14px'}}>
                                    {_es_pieza() ? (
                                        <span>Precio por Kg</span>
                                    ) : (
                                        <span>Precio por Und</span>
                                    )

                                    }

                                </label>
                            </div>
                            {
                                producto.ConOferta ?
                                    <div className="flex gap-2">
                                        <div className=" flex items-center gap-2  font-semibold mt-2 m-0 text-black ">
                                            <span className="text_precio">Bs. </span>
                                            <span
                                                className="text_precio text-black">  {valorPrecio(producto.PrecioOferta)}  </span>
                                            <span
                                                className="text_precio   text-zinc-400 line-through">{valorPrecio(producto.PrecioOriginal)}</span>

                                        </div>

                                    </div>
                                    :
                                    <div className=" flex items-center gap-2 text-black  mt-2 m-0 ">
                                        <span className="text_precio">Bs. </span>
                                        <span className="text_precio"> {valorPrecio(producto.PrecioVenta)} </span>
                                    </div>

                            }
                        </div>
                        <div className="cantidad flex items-end gap-1">
                            <div className="flex gap-2" style={{position: 'absolute', bottom: '5px'}}>
                                {
                                    _es_pesso() ?
                                        <p className="font-bold">Cantidad: {cantidad * 1000} g</p>
                                        :
                                        <p className="font-bold">Cantidad: {cantidad} Und</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product_price flex justify-end">
                    <div className="flex flex-column items-start">

                        { _es_pesso() &&
                            <p className="mb-0 font-bold text-black w-100 flex justify-end">{producto.Cantidad} kg</p>
                        }
                        {
                            _es_pieza() &&
                            <p className="mb-0 font-bold text-black w-100 flex justify-end">{_cantidad_pieza(producto)} kg</p>
                        }
                        <p className={`text-black font-bold text_precio m-0`}
                           style={{textAlign: 'end', fontWeight: '600', minWidth: "100px"}}>
                            {producto.Moneda} {valorPrecio(producto.Importe)}
                        </p>
                    </div>
                </div>
            </div>
        </>


    );
};

export default ItemConfirmacionPedido;