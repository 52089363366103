import React, {useEffect, useState} from 'react';
import '../../assets/styles/hiper.scss'
import logo from '../../assets/images/login/logo.png';
import background from '../../assets/images/login/Background.jpg';
import texture from '../../assets/images/login/texture_complete.jpg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage, faMobileButton, faMobileAndroidAlt} from "@fortawesome/free-solid-svg-icons";
import {Link, Redirect} from "react-router-dom";
import {
    activate,
    activate_sin_autologin,
    codigo_acceso,
    limpiar_auth,
    set_token_externo
} from "../../redux/actions/auth";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Circles} from "react-loader-spinner";
import "../../assets/styles/globalColorPrincipal.css";
import Alert from "../../components/Swalert";
import {info_session} from "../../redux/actions/profile";
import ButtonText from "../../widgets/button_text";
import ButtonIcon from "../../widgets/button_icon";
import { useLocalStorage } from "react-use";

const containerStyle = {
    backgroundImage:
        `url(${background})`,
    width: "100%",
    height: "92.5vh",
};
const containerStyle2 = {
    backgroundImage:
        `url(${texture})`,
    width: "100%",
};


const Activate = ({
                      limpiar_auth,
                      codigo_acceso,
                      activate,
                      activate_sin_autologin,
                      phone,
                      loading,
                      isAuthenticated,
                      access_token,
                      info_session,
                      profile,
                      set_token_externo
                  }) => {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(60);

    const [deshabilitar, setDeshabilitar] = useState(false);
    const [formData, setFormData] = useState({
        CodigoAcceso: '',
        username: phone,
    })

    const {
        CodigoAcceso,
        username
    } = formData;

    const {register, handleSubmit, formState: {errors}} = useForm();

    const messages = {
        required: "Este campo es obligatorio",
        minLength: "No puede ingresar menor caracteres de lo establecido",
        maxLength: "No puede ingresar más caracteres en este campo",
        espacioBlanco: 'No es permitido espacios en blanco',
        requiredColor: 'Debe seleccionar un color',
        aceptaNumeros: 'Solo se acepta números en este campo',
        aceptaLetra: 'Solo se acepta letras en este campo'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
        aceptaNumeros: /^[0-9,-]+$/,
        aceptaLetra: /^[A-Za-z\s]+$/,
    };
    useEffect(() => {
        tiempo_permitido();
    }, []);
    useEffect(() => {
        if (access_token) {
            info_session();
        }
    }, [access_token]);

    useEffect(() => {
        if (profile) {
            if (profile.TerminoCondicion.AceptoTerminoActual) {
                let tipo_user = localStorage.getItem('user');
                if (profile.Ubicaciones.length > 0) {
                    navigate("/sucursal");
                } else if (tipo_user !== '"anonimo"') {
                    navigate("/ubicacion");
                }
            } else {
                navigate("/terminoscondiciones");

            }
        }
    }, [profile]);

    const onSubmit = async (data) => {
        ejecutar();
        // await activate(phone, data.codigoAcceso);
        await activate_sin_autologin(phone, data.codigoAcceso);
        let redSocialId = localStorage.getItem("redId");
        console.log(redSocialId);
        console.log("social id",redSocialId);
        console.log("cod acceso Activate ",data.codigoAcceso);
        if (redSocialId !== null) {
            await set_token_externo(redSocialId, data.codigoAcceso);
        }


    }

    const volverAtras = () => {
        localStorage.clear();
        limpiar_auth();
        navigate("/registro_mobil");
    }

    const ejecutar = () => {
        console.log("ejecutar guardar");
        setDeshabilitar(true);
    }

    const reenviarCodigo = () => {
        if (counter===60) {
            console.log("reenviar codigo");
            let prefijo = localStorage.getItem("prefijo");
            let tipoEnvicoAcceso = localStorage.getItem("tipoEnvicoAcceso");
            let phone = localStorage.getItem("phone");
            codigo_acceso(phone,prefijo,tipoEnvicoAcceso);
            tiempo_permitido();
            console.log(prefijo,tipoEnvicoAcceso,phone)
        }
    }


    function tiempo_permitido() {
        const interval = setInterval(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        setTimeout(() => {
            console.log("limpiar");
            clearInterval(interval);
            setDeshabilitar(false);
            setCounter(60);
        }, 60000);

    }


    return (
        <>
            <Alert></Alert>
            <div id="wrapper relative" style={containerStyle}>

                <div className='shadow-md w-full static top-0 left-0'>
                    <div className='flex items-center justify-center bg-white py-2 md:px-10 px-7'>
                            <img src={logo} width="70"
                                 alt="logo"/>
                    </div>
                </div>

                <div className="main">
                    <div className="hiper-color absolute bottom-0 w-full" style={containerStyle2}>
                        <div className="h-96 max-h-full">
                            <h1 className="text-4xl font-inter font-normal text-center text-white my-5">Ingresar</h1>
                            <form onSubmit={handleSubmit(onSubmit)} className="flex justify-center m-0">
                                <div className="box-input">
                                    <div>
                                        <label htmlFor="codigo" className="block text-sm font-medium text-white">
                                            <FontAwesomeIcon icon={faMobileAndroidAlt}
                                                             style={{height: "20px"}}/> Ingresa el
                                            código
                                        </label>
                                        <div className="grid grid-cols-1">
                                            <input
                                                name="CodigoAcceso"
                                                type="text"
                                                className="shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                //    className="appearance-none block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                autoComplete='off'
                                                {...register("codigoAcceso", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },
                                                    minLength: {
                                                        value: 6,
                                                        message: messages.minLength
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: messages.maxLength
                                                    },
                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    },
                                                    pattern: {
                                                        value: patterns.aceptaNumeros,
                                                        message: messages.aceptaNumeros
                                                    }
                                                })}
                                            />
                                            {errors.codigoAcceso &&
                                                <small className="text-red-600">{errors.codigoAcceso.message}</small>}

                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 mt-2 text-white">
                                        <button
                                            className="text-[#DC842D] bg-white hover:bg-hiperblue font-inter font-light hover:text-white py-2 px-2 border border-white hover:border-transparent rounded-lg w-full mt-2 mb-2 flex items-center"
                                            type="button" onClick={()=>reenviarCodigo()}>
                                            <FontAwesomeIcon icon={faMessage} style={{height: "20px"}}/> <span
                                            className="ml-1"
                                            style={{fontSize: '12px'}}>Reenviar código de nuevo en {counter} segundos</span>
                                        </button>

                                        <ButtonText
                                            label='Cancelar'
                                            className="text-[#DC842D] bg-white hover:bg-white"
                                            onClick={() => volverAtras()}
                                        />
                                        {deshabilitar ? (
                                            <button
                                                disabled={deshabilitar}
                                                style={{cursor: 'not-allowed'}}
                                                className={'flex justify-center items-center py-1.5 px-4 border w-full rounded-lg bg-hiperblue    transition-colors duration-300 font-medium'}>

                                                Aceptar
                                            </button>
                                        ) : (
                                            <ButtonText
                                                type='submit'
                                                label='Aceptar'
                                                principal={true}
                                                className="bg-hiperblue hover:bg-hiperblue_hover text-white"
                                                onClick={() => {
                                                }}

                                            />
                                        )

                                        }

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};


const mapStateToProps = state => ({
    phone: state.Auth.phone,
    loading: state.Auth.loading,
    isAuthenticated: state.Auth.isAuthenticated,
    access_token: state.Auth.access_token,
    profile: state.Profile.profile,
})

export default connect(mapStateToProps, {
    activate, info_session, set_token_externo, activate_sin_autologin,codigo_acceso,limpiar_auth,
})(Activate)



