import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faTrash} from "@fortawesome/free-solid-svg-icons";



const ItemTarjeta = ({ps_tarjeta,ps_deleteTarjeta}) => {

    function getFormatoFecha(fecha){
        let fechaSub= fecha.substring(0, 10);
       return fechaSub;
    }
    function eliminarTarjeta(id){
        ps_deleteTarjeta(id);
    }


    return (

        <div className='w-[15rem] h-auto p-2 rounded-lg border border-1'
            style={{backgroundColor:ps_tarjeta.color}}
            key={ps_tarjeta.idTarjeta}
        >   
            <div className="flex justify-end h-[1rem]">
                {ps_tarjeta.preferida &&
                    <FontAwesomeIcon className=" text-white" icon={faHeart}/>  
                }
                
            </div>
            <div className='flex flex-col justify-center text-center text-white'>
                <span>{ps_tarjeta.propietario}</span>
                <div className='text-center p-1 mb-1 bg-white text-gray-500 rounded-lg'>
                    <span>{ps_tarjeta.cardPrefix}xxxxxx{ps_tarjeta.cardSufix}</span>
                </div>
                <div className='text-center p-1 mb-1 bg-white text-black rounded-lg'>
                    <span className="font-extrabold  uppercase text-xl">{ps_tarjeta.descripcionTypeCard}</span>
                </div>
            </div>
            <div className='flex justify-between mt-1 text-white items-center'>
                <span className="font-bold" style={{fontSize:'0.7rem'}}>Registro {getFormatoFecha(ps_tarjeta.fecha_Crea)}</span>
                <FontAwesomeIcon icon={faTrash}
                className="cursor-pointer"
                onClick={()=>eliminarTarjeta(ps_tarjeta.idTarjeta)}
                style={{fontSize:'0.8rem'}}
                />
            </div>

        </div>


    );
};

export default ItemTarjeta;