import React, {useState} from 'react';
import '../../assets/styles/hiper.scss'
import background from '../../assets/images/login/Background.jpg';
import texture from '../../assets/images/login/texture_complete.jpg';
import {connect} from "react-redux";
import {test_loading_token} from "../../redux/actions/auth";

import {Circles} from 'react-loader-spinner'

const containerStyle = {
    backgroundImage:
        `url(${background})`,
    width: "100%",
    height: "92.5vh",
};
const containerStyle2 = {
    backgroundImage:
        `url(${texture})`,
    width: "100%",
};

function TokenMomentaneo({test_loading_token,loading}) {

    const [formData, setFormData] = useState({
        token: ''
    })
    const {
        token
    } = formData;

    if (!loading) {
        // console.log("entra a loading");
    }


    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = e => {

        test_loading_token(token);

        // console.log(localStorage.getItem('access_token'));
    }
    return (
        <div>
            <div className="flex justify-center">
                <div className="w-96">
                    <label htmlFor="token">token:</label>
                    <textarea className="bg-white border w-100 px-4 py-2 rounded" type="text"
                              name="token"
                              value={token}
                              placeholder="token"
                              onChange={e => onChange(e)}
                    />
                    {
                        loading ?
                            <button
                                className=" py-1 px-4 border bg-hiperorange border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between">
                                <Circles
                                    color="#fff"
                                    width={20}
                                    height={20}
                                    ariaLabel="circles-loading"
                                />
                            </button>
                            :
                            <button
                                className=" py-1 px-4 border bg-hiperorange border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between"
                                onClick={onSubmit}>Guardar</button>
                    }
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = state => ({
    loading: state.Auth.loading,
})

export default connect(mapStateToProps, {
    test_loading_token
})(TokenMomentaneo)



