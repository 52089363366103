import React,{useState,useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCartShopping,
    faHeart
} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {actualizar_lista_compra_favorito} from "../../src/redux/actions/shoppingList";
import {set_cambio_favorito} from "../redux/actions/carrito";
import {Circles} from "react-loader-spinner";

const ItemFavorito = ({
                          ps_shopping_list,
                          recargar_listado,
                          idProducto,
                          IdSucursal,
                          cantidad,
                          pesos,
                          peso_definitivo,
                          set_cambio_favorito,
                          favorito, actualizar_lista_compra_favorito,peticion
                      }) => {

                        const [selected, setSelected]= useState(0);
    const marcar_favorito = (item, e) => {
        setSelected(item.Id)
        ejecutar_procesos(item);
    };

    useEffect(()=>{
        return ()=>{
            // set_cambio_favorito(false);
            // console.log("desactua");
        }
    },[]);

    async function ejecutar_procesos(item) {
        var prod = {
            IdProducto: idProducto,
            IdSucursal: IdSucursal,
            Cantidad: cantidad,
            PesoDefinido: peso_definitivo,
            Pesos: pesos ? pesos : []
        };
        const datos = {
            Id: item.Id,
            Descripcion: item.Descripcion,
            Color: item.Color,
            Productos: [],
            EliminarProductos: [],
        };

        if (esFavorito()) {
            datos.EliminarProductos.push(prod);
        } else {
            datos.Productos.push(prod);
        }
        await actualizar_lista_compra_favorito(datos, datos.Productos, datos.EliminarProductos, IdSucursal);
        recargar_listado(idProducto, IdSucursal);
        set_cambio_favorito(true);
        setSelected(0);
    }

    function esFavorito() {
        return typeof (favorito) !== 'undefined' && favorito === true;
    }

    return (

        <>
            {
                ps_shopping_list &&
                <div
                    // className={`bg-[${ps_shopping_list.Color}]  flex justify-between px-3 py-0.5 mb-1 rounded-lg hover:text-orange-500 hover:bg-white hover:border-hiperorange hover:border-2  transition-colors duration-300 font-medium`}
                className="flex justify-between px-3 py-0.5 mb-1 rounded-lg transition-colors duration-300 font-medium cursor-pointer hover:border-black"
                style={{background: ps_shopping_list.Color}}
                onClick={(e) => marcar_favorito(ps_shopping_list, e)}
                >
                    <div className="flex cursor-pointer  items-center" key={ps_shopping_list.Id}
                        >
                        <FontAwesomeIcon icon={faCartShopping}/>
                        <span className="ml-2"> {ps_shopping_list.Descripcion} </span>
                    </div>
                    <div>
                       
                        {peticion && selected===ps_shopping_list.Id?(
                            <div className='flex justify-center items-center text-black text-center h-100'>
                                <Circles
                                    color="#FFFFFF"
                                    width={18}
                                    height={18}
                                    ariaLabel="circles-loading"
                                    visible={true}
                                    className='items-center'
                                />
                            </div>
                            )
                            :(
                                esFavorito() && selected!==ps_shopping_list.Id &&
                                <FontAwesomeIcon icon={faHeart} className="cursor-pointer " />
                            )
                        }
                    </div>
                </div>
            //      { peticion? (
            //         <div className='flex justify-center text-black text-center'>
            //         <Circles
            //             color="#32338E"
            //             width={40}
            //             height={40}
            //             ariaLabel="circles-loading"
            //             visible={true}
            //         />
            //     </div>
            //    ) : (verifyLista() &&
            //        <p className='text-md text-gray-400 text-center'>
            //            No hay lista para mostrar
            //        </p>
            //    )

            //    }

            }

        </>
    );
};


const mapStateToProps = state => ({
    peticion:state.Peticion.peticion,
})


export default connect(mapStateToProps, {
    set_cambio_favorito, actualizar_lista_compra_favorito
})(ItemFavorito)