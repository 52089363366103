import {
    SET_AUTH_LOADING,
    GET_LIST_CARRITO_SUCCESS,
    GET_LIST_CARRITO_FAIL,
    DELETE_SUBLIST_CARRITO_FAIL,
    DELETE_SUBLIST_CARRITO_SUCCESS,
    GET_FECHAS_ENTREGA_SUCURSAL_SUCCESS,
    GET_FECHAS_ENTREGA_SUCURSAL_FAIL,
    GET_HORARIO_DISPONIBLE_SUCCESS,
    GET_HORARIO_DISPONIBLE_FAIL,
    ADD_TO_CART,
    INC_TO_CART,
    INC_TO_CART_SHOPPING_LIST,
    DEC_TO_CART_SHOPPING_LIST,
    SET_PESO_TO_CART,
    DEC_TO_CART,
    REMOVE_TO_CART,
    REMOVE_TO_CART_SUCURSAL,
    GET_CARRITO_SUCURSAL_SUCCESS,
    REMOVE_AUTH_LOADING,
    UPDATE_ITEM_CARRITO,
    UPDATE_LIST_CARRITO,
    GET_SAVE_PEDIDO_SUCCESS,
    UPDATE_HORARIOS_CLEAR,
    ACT_DATA_CART,
    GET_SAVE_PEDIDO_FAIL,
    UPDATE_HORARIOS_SUCCESS,
    UPDATE_HORARIOS_FAIL,
    SAVE_CONFIRMAR_CARRITO_FAIL,
    DELETE_PEDIDO_CARRITO,
    SET_CHANGE_FAVORITE_SUCCESS,
    SET_CHANGE_CARRITO_SUCCESS,
    SET_SUBPEDIDOS_SUCCESS,
    SET_UPDATE_CARRITO_SUCCESS, SAVE_CONFIRMAR_CARRITO_SUCCESS,
    CLEAR_CART,
    UPDATE_CARRITO_ANONIMO,
    UPDATE_CARRITO_ANONIMO_FAIL,
    CLEAR_STATE_ANONIMO,
    CLEAR_STATE_CONFIRMADO,
    SET_CHANGE_DETALLE_CARRITO_SUCCESS,

} from './types'
import axios from 'axios';
import {setSwAlert,showRespuesta} from "./swalert";
import {end_peticion, start_peticion} from "./peticion";
import respuestas from '../../components/Alertas';

export const limpiar_carrito = (todo = true) => async dispatch => {
    dispatch({
        type: CLEAR_CART
    });
};

export const limpiar_solo_carrito = () => async dispatch => {
    dispatch({
        type: UPDATE_ITEM_CARRITO
    });
};
export const set_delete_pedido_carrito = (bool) => async dispatch => {
    dispatch({
        type: DELETE_PEDIDO_CARRITO,
        payload: bool
    });
};



export const limpiar_estado_anonimo = ()=>async dispatch =>{
    dispatch({
        type:CLEAR_STATE_ANONIMO
    })
}
export const limpiar_estado_confirmado = ()=>async dispatch =>{
    dispatch({
        type:CLEAR_STATE_CONFIRMADO
    })
}


export const store_carrito = (sucursal, tipo_entrega, direccion, carritos, producto_delete) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    function mapearProductos() {
        let array = [];
        let pesos = [];
        carritos.forEach((item) => {
            if (item.PesoDefinido) {
                if (item.Pesos !== null) {
                    pesos = item.Pesos.map((e) => {
                        return {
                            IdPeso: e.IdPeso,
                            Piezas: e.Piezas,
                        };
                    });
                }
            }

            array.push({
                'IdProducto': item.IdProducto,
                'Cantidad': item.Cantidad,
                'Pesos': pesos,
            })
        });
        return array;
    }

    let productos = mapearProductos();
    let IdUbicacion = direccion ? direccion.Id : null;
    if (tipo_entrega.Id === 2) {
        IdUbicacion = direccion ? direccion.Id : null;
    }
    const data = {
        "IdMarket": sucursal.IdMarket,
        "IdSucursal": sucursal.IdSucursal,
        "IdTipoEntrega": tipo_entrega.Id,
        "IdUbicacion": IdUbicacion,
        "Productos": productos,
        "EliminarProductos": producto_delete
    };
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/carrito`, data, config);
        if (res.status === 200 && res.data.ConError === false) {
            console.log("cargado action carrito")
            dispatch(get_carrito_por_sucursal(sucursal));
        } else {

            dispatch({
                type: GET_LIST_CARRITO_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        dispatch({
            type: GET_LIST_CARRITO_FAIL,

        });

        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

export const store_carrito_data = (params, carritos, producto_delete, cambio = false) => async dispatch => {
    dispatch(start_peticion(''));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };

    function mapearProductos() {
        let array = [];
        let pesos = [];
        carritos.forEach((item) => {
            if (item.PesoDefinido) {
                if (item.Pesos !== null) {
                    pesos = item.Pesos.map((e) => {
                        return {
                            IdPeso: e.IdPeso,
                            Piezas: e.Piezas,
                        };
                    });
                }
            }

            array.push({
                'IdProducto': item.IdProducto,
                'Cantidad': item.Cantidad,
                'Pesos': pesos,
            })
        });
        return array;
    }

    let productos = mapearProductos();
    const data = {
        "IdMarket": params.IdMarket,
        "IdSucursal": params.IdSucursal,
        "IdTipoEntrega": params.IdTipoEntrega,
        "IdUbicacion": params.IdDireccion,
        "Productos": productos,
        "EliminarProductos": producto_delete
    }
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/carrito`, data, config);
        if (res.status === 200 && res.data.ConError === false) {
             dispatch(set_cambio_carrito(cambio));
        } else {
            dispatch({
                type: GET_LIST_CARRITO_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());
    } catch (err) {
        dispatch({
            type: GET_LIST_CARRITO_FAIL
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const store_carrito_data_anonimo = (params, carritos, producto_delete) => async dispatch => {
    dispatch(start_peticion(''));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };

    function mapearProductos() {
        let array = [];
        let pesos = [];
        carritos.forEach((item) => {
            if (item.PesoDefinido) {
                if (item.Pesos !== null) {
                    pesos = item.Pesos.map((e) => {
                        return {
                            IdPeso: e.IdPeso,
                            Piezas: e.Piezas,
                        };
                    });
                }
            }

            array.push({
                'IdProducto': item.IdProducto,
                'Cantidad': item.Cantidad,
                'Pesos': pesos,
            })
        });
        return array;
    }

    let productos = mapearProductos();
    const data = {
        "IdMarket": params.IdMarket,
        "IdSucursal": params.IdSucursal,
        "IdTipoEntrega": params.IdTipoEntrega,
        "IdUbicacion": params.IdDireccion,
        "Productos": productos,
        "EliminarProductos": producto_delete
    }
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/carrito`, data, config);
        if (res.status === 200 && res.data.ConError === false) {
            // dispatch(set_cambio_carrito(true));
            dispatch({
                type: UPDATE_CARRITO_ANONIMO
            });

        } else {
            dispatch({
                type: GET_LIST_CARRITO_FAIL
            });
            dispatch({
                type: UPDATE_CARRITO_ANONIMO_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());
    } catch (err) {
        dispatch({
            type: GET_LIST_CARRITO_FAIL
        });
        dispatch({
            type: UPDATE_CARRITO_ANONIMO_FAIL
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

export const get_lista_carrito_compras = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/carrito`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_LIST_CARRITO_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_LIST_CARRITO_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        dispatch({
            type: GET_LIST_CARRITO_FAIL
        });

        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const save_horarios = (datos) => async dispatch => {
    dispatch(start_peticion(''));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {

        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/carrito/horarios-disponible`, datos, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_HORARIOS_SUCCESS,
                payload: res.data
            });


           if (res.data.ConError) {
                if (res.data.Errores.length > 0) {
                    let mensaje = res.data.Errores[0].Mensaje;
                    dispatch(setSwAlert(mensaje, res.data.ConError, res.data.Estado));
                } else {
                    dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 412));
                }

            }


        } else {
            dispatch({
                type: UPDATE_HORARIOS_FAIL,
                payload: res.data
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());
    } catch (err) {
        dispatch({
            type: UPDATE_HORARIOS_FAIL
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));

    }
};

export const get_carrito_por_sucursal = (sucursal) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/carrito`, config);

        if (res.status === 200) {
            dispatch({
                type: GET_LIST_CARRITO_SUCCESS,
                payload: res.data
            });
             if(res.data.Dato!=null){
                 let list_carts = res.data.Dato.SubPedidos;
                 list_carts.forEach((cart) => {
                     if (cart.Sala.Id === sucursal.IdSucursal) {
                         dispatch({
                             type: GET_CARRITO_SUCURSAL_SUCCESS,
                             payload: {cart}
                         });
                     }
                 })
             }


        } else {
            dispatch({
                type: GET_LIST_CARRITO_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        dispatch({
            type: GET_LIST_CARRITO_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));

    }
};
export const save_pedido = (token, datos, datosAux) => async dispatch => {
    dispatch({
        type: SET_AUTH_LOADING
    });

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };


    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/confirmar-pago`, datos, config);
        const res2 = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/confirmar-carrito`, datosAux, config);

        if (res.status === 200 && res2.status === 200) {

            dispatch({
                type: GET_SAVE_PEDIDO_SUCCESS,
                payload: res.data

            });

        } else {
            if (res.status !== 200) {
                dispatch({
                    type: GET_SAVE_PEDIDO_FAIL,
                    payload: res.data
                });
            }
            if (res2.status !== 200) {
                dispatch({
                    type: SAVE_CONFIRMAR_CARRITO_FAIL,
                    payload: res.data
                });
            }
            dispatch(showRespuesta(dispatch,res) ) ;

        }


    } catch (err) {

        dispatch({
            type: GET_SAVE_PEDIDO_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));

    }
};

export const confirmar_pago = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/confirmar-pago`, datos, config);
        //este endpoint cualquier dato q envias igual da correcto.
        if (res.status != 200  ) {
            dispatch(showRespuesta(dispatch,res)) ;
        }

    } catch (err) {

        dispatch({
            type: GET_SAVE_PEDIDO_FAIL
        });

        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};


export const confirmar_carrito = (datos) => async dispatch => {
    dispatch(start_peticion(''));

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };

    try {

        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/confirmar-carrito`, datos, config);
        if (res.status === 200 && res.data.ConError === false) {
            dispatch(limpiar_carrito());
            dispatch({
                type: SAVE_CONFIRMAR_CARRITO_SUCCESS,
                payload: res.data
            });
        } else {
            if (res.status !== 200) {
                dispatch({
                    type: GET_SAVE_PEDIDO_FAIL,
                    payload: res.data
                });
            }
            if (res.status !== 200) {
                dispatch({
                    type: SAVE_CONFIRMAR_CARRITO_FAIL,
                    payload: res.data
                });

                dispatch(showRespuesta(dispatch,res) ) ;

            }
          /*  if (res.data.Errores.length>0) {
                console.log("res.data.Errores",res.data.Errores)
                dispatch(setSwAlert(res.data.Errores[0].Mensaje, res.data.ConError, 400));
            }else {
                dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
            }
            */
        }
        dispatch(end_peticion());

    } catch (err) {
        dispatch({
            type: GET_SAVE_PEDIDO_FAIL
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

export const update_list_carrito_compras = (lista) => async dispatch => {
    try {
        dispatch({
            type: UPDATE_LIST_CARRITO,
            payload: lista
        });
    } catch (err) {
        dispatch({
            type: GET_LIST_CARRITO_FAIL
        });
    }
};


export const get_fechas_entrega_sucursal_carrito = (IdTipoEntrega, sucursal_ids) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        let url_sucursals = '';
        sucursal_ids.forEach((id, index) => {
            url_sucursals += `&Sucursales[${index}]=${id}`
        });
        let url = `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/fechas-disponible-entrega?IdTipoEntrega=${IdTipoEntrega}` + url_sucursals;
        const res = await axios.get(url, config);
        if (res.status === 200) {
            dispatch({
                type: GET_FECHAS_ENTREGA_SUCURSAL_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_FECHAS_ENTREGA_SUCURSAL_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;

        }
    } catch (err) {
        dispatch({
            type: GET_FECHAS_ENTREGA_SUCURSAL_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));

    }
};

export const get_horarios_disponible_carrito = (data) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {

        let url = `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/carrito/horarios-disponible?IdSucursal=${data.IdSucursal}&FechaEntrega=${data.FechaEntrega}&IdFecha=${data.IdFecha}&Importe=${Number(data.Importe)}&IdTipoEntrega=${data.IdTipoEntrega}`;
        const res = await axios.get(url, config);
        if (res.status === 200) {
            dispatch({
                type: GET_HORARIO_DISPONIBLE_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_HORARIO_DISPONIBLE_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        dispatch({
            type: GET_HORARIO_DISPONIBLE_FAIL
        });

        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

export const actualizar_list_carrito_compras = (sucursal, tipo_entrega, direccion, productos, producto_delete) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    // function mapearProductos() {
    //     let array = [];
    //     carritos.forEach((item) => {
    //         array.push({
    //             'IdProducto': item.IdProducto,
    //             'Cantidad': item.Cantidad,
    //         })
    //     })
    //     return array;
    // }

    //let productos = mapearProductos();
    const data = {
        "IdMarket": sucursal.IdMarket,
        "IdSucursal": sucursal.IdSucursal,
        "IdTipoEntrega": tipo_entrega.Id,
        "IdUbicacion": direccion.Id,
        "Productos": productos,
        "EliminarProductos": producto_delete
    }

    // const data=null;
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/carrito`, data, config);
        if (res.status === 200) {
            // dispatch({
            //     type: UPDATE_ITEM_CARRITO,
            //     payload: res.data
            // });
        } else {
            // dispatch({
            //     type: GET_LIST_CARRITO_FAIL
            // });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        dispatch({
            type: GET_LIST_CARRITO_FAIL
        });

        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const delete_list_carrito = (idPedido) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        let url = `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/carrito/subpedidos/${idPedido}`;
        const res = await axios.delete(url, config);
        if (res.status === 200 && res.data.Estado === 200) {
            dispatch({
                type: DELETE_SUBLIST_CARRITO_SUCCESS,
            });
            dispatch(set_delete_pedido_carrito(false));
            dispatch(get_lista_carrito_compras());

            dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
        } else {
            dispatch({
                type: DELETE_SUBLIST_CARRITO_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }

    } catch (err) {
        dispatch({
            type: DELETE_SUBLIST_CARRITO_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));

    }
};

export const add_al_carrito = (producto, cantidad) => async dispatch => {
    dispatch({
        type: ADD_TO_CART,
        payload: {producto, cantidad}
    });
};

export const inc_al_carrito = (producto) => async dispatch => {
    dispatch({
        type: INC_TO_CART,
        payload: producto
    });
};
export const dec_al_carrito = (producto) => async dispatch => {
    dispatch({
        type: DEC_TO_CART,
        payload: producto
    });
};
export const inc_al_carrito_lista_compra = (producto) => async dispatch => {
    dispatch({
        type: INC_TO_CART_SHOPPING_LIST,
        payload: producto
    });
};
export const dec_al_carrito_lista_compra = (producto) => async dispatch => {
    dispatch({
        type: DEC_TO_CART_SHOPPING_LIST,
        payload: producto
    });
};

export const remove_al_carrito = (producto) => async dispatch => {
    dispatch({
        type: REMOVE_TO_CART,
        payload: producto
    });
};

export const remove_al_carrito_sucursal = (producto) => async dispatch => {
    dispatch({
        type: REMOVE_TO_CART_SUCURSAL,
        payload: producto
    });
};


export const set_peso_al_carrito = (producto_peso, cant, pesos) => async dispatch => {
    dispatch({
        type: SET_PESO_TO_CART,
        payload: {producto_peso, cant, pesos}
    });
};
export const actualizar_item_carrito = (producto, valor) => async dispatch => {
    dispatch({
        type: ACT_DATA_CART,
        payload: {producto, valor}
    });
};
export const set_subpedidos = (SubPedidos) => async dispatch => {
    dispatch({
        type: SET_SUBPEDIDOS_SUCCESS,
        payload: {SubPedidos}
    });
};

export const update_carrito = (carrito) => async dispatch => {
    dispatch({
        type: SET_UPDATE_CARRITO_SUCCESS,
        payload: {carrito}
    });
};


export const set_cambio_favorito = (bool) => async dispatch => {
    dispatch({
        type: SET_CHANGE_FAVORITE_SUCCESS,
        payload: bool
    });
};

export const set_cambio_carrito = (bool) => async dispatch => {
    dispatch({
        type: SET_CHANGE_CARRITO_SUCCESS,
        payload: bool
    });
};
export const set_cambio_detalle_carrito = (bool) => async dispatch => {
    dispatch({
        type: SET_CHANGE_DETALLE_CARRITO_SUCCESS,
        payload: bool
    });
};

export const limpiar_horario = () => async dispatch => {
    dispatch({
        type: UPDATE_HORARIOS_CLEAR
    });
};





