import {
    GET_METODO_PAGO_SUCCESS,
    GET_METODO_PAGO_FAIL,
    SET_DATOS_PAGO
} from '../actions/types';

const initialState = {
    metodopagos: null,
    datos_pago: null
};

export default function metodoPagos(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case GET_METODO_PAGO_SUCCESS:
            return {
                ...state,
                metodopagos: payload.Dato
            }
        case GET_METODO_PAGO_FAIL:
            return {
                ...state
            }
        case SET_DATOS_PAGO:
            return {
                ...state,
                datos_pago: payload
            }


        default:
            return state
    }
}