import React, {useState, useEffect} from 'react';
import '../../assets/styles/terminos_condiciones.css'
import {connect} from "react-redux";
import {terminos_condiciones} from "../../redux/actions/auth";

import {Circles} from 'react-loader-spinner'
import Alert from "../../components/Swalert";
import {useNavigate} from "react-router-dom";
import {info_session} from "../../redux/actions/profile";
import axios from "axios";

function TerminosCondiciones({terminos_condiciones, info_session, loading, profile}) {
    const navigate = useNavigate();
    const [objectTerminoCondicion, setObjectTerminoCondicion] = useState(null)
    const [html, setHtml] = useState(null)
    const [formData, setFormData] = useState({
        token: ''
    })
    const {
        token
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    useEffect(() => {
        let token = localStorage.getItem('access_token');
        if (profile) {
            let terminos = profile.TerminoCondicion;
            if (!terminos.AceptoTerminoActual) {
                setObjectTerminoCondicion(profile.TerminoCondicion)
            }
        }
        if (!profile && token) {
            info_session();
        }
    }, [profile]);

    useEffect(() => {
        if (objectTerminoCondicion) {
            getTerminosCondiciones();
        }
    }, [objectTerminoCondicion]);

    useEffect(() => {
        let token = localStorage.getItem('access_token');
        if (!token) {
            navigate("/login");
        }
    }, []);

    async function getTerminosCondiciones() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        try {
            const res = await axios.get(`${objectTerminoCondicion.ContenidoUrl}`, config);
            if (res.status === 200) {
                setHtml(res.data)
            }
        } catch (err) {
            console.log("error ", err)
        }
    }


    const onSubmit = async e => {
        const result = await terminos_condiciones();
        if (result) {
            navigate("/ubicacion");
        }
    }
    return (
        <div>
            <Alert></Alert>
            <div className="flex justify-center">
                <div style={{width: "50%"}} className="my-5">
                    <div dangerouslySetInnerHTML={{ __html: html }} />

                    <div>
                        {
                            loading ?
                                <button
                                    className=" py-1 px-4 border bg-hiperorange border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between">
                                    <Circles
                                        color="#fff"
                                        width={20}
                                        height={20}
                                        ariaLabel="circles-loading"
                                    />
                                </button>
                                :
                                <button
                                    className=" py-1 px-4 border bg-hiperorange border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between"
                                    onClick={() => onSubmit()}>Aceptar</button>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
};


const mapStateToProps = state => ({
    loading: state.Auth.loading,
    profile: state.Profile.profile,

})

export default connect(mapStateToProps, {
    terminos_condiciones, info_session
})(TerminosCondiciones)



