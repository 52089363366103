import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleXmark,
    faSquareMinus, faSquarePlus,
    faTrashCan,
    faChevronUp, faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import {
    delete_list_carrito,
    get_lista_carrito_compras, limpiar_estado_confirmado,
    remove_al_carrito_sucursal,
    set_cambio_carrito, set_delete_pedido_carrito,
    store_carrito,
    store_carrito_data,
    update_carrito,
    update_list_carrito_compras
} from "../../../redux/actions/carrito";
import ItemCarritoModal from "../../../widgets/item_carrito_modal";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import Modal from "react-modal";
import Favorito from "../../../widgets/favoritoMycart";
import {isPointInPolygon} from "geolib";
import classNames from "classnames";

import {Disclosure} from '@headlessui/react'
import Direccion from "../direccion";
import "../../../assets/styles/responsive.scss"
import ButtonText from "../../../widgets/button_text";
import ButtonClose from "../../../widgets/button_close";
import {setSwAlert} from "../../../redux/actions/swalert";
import Alert from "../../Swalert";
import {Circles} from "react-loader-spinner";
import useScreenSize from "../../../widgets/useScreenSize";

const customStyles = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '1',
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0.75rem',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'visible'
    },
};

const style_favorito = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '31',
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '0',
        borderRadius: '0.75rem',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'auto'
    },
};
const style_direccion = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '31',
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0.75rem',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'auto'
    },
};


const pesajes = [
    {name: '500g', peso: '500'},
    {name: '1Kg', peso: '1000'},
    {name: '2Kg', peso: '2000'},
    {name: '3Kg', peso: '3000'},
    {name: '5Kg', peso: '5000'},
    {name: '10Kg', peso: '10000'},
    {name: '15Kg', peso: '15000'},
    {name: '20Kg', peso: '20000'},
    {name: '25Kg', peso: '25000'},
    {name: '30Kg', peso: '30000'},
    {name: '35Kg', peso: '35000'},
    {name: '40Kg', peso: '40000'},
    {name: '45Kg', peso: '45000'},
    {name: '50Kg', peso: '50000'},
];

function Carrito({
                     limpiar_estado_confirmado,
                     setSwAlert,
                     get_lista_carrito_compras,
                     set_delete_pedido_carrito,
                     list_carrito,
                     sucursal,
                     branch_offices,
                     tipo_entrega,
                     carritos,
                     producto_delete,
                     direccion,
                     store_carrito,
                     store_carrito_data,
                     delete_list_carrito,
                     carrito_favorito,
                     mt_indice,
                     update_list_carrito_compras,
                     set_cambio_carrito,
                     update_carrito,
                     remove_al_carrito_sucursal,
                     cambio_carrito,
                     error_save_carrito,
                     width,
                     height,
                     exit
                 }) {

    const [subPedidos, guardarsubPedidos] = useState([]);
    const [token] = useState(localStorage.getItem('access_token'));
    const [producto_aux, setProductoAux] = useState({});
    const [IdSucursal_aux, setIdSucursalAux] = useState('');
    const [modal_pieza, setModalPieza] = useState(false);
    const [modal_favorito, setModalFavorito] = useState(false);
    const [modal_direccion, setModalDireccion] = useState(false);
    const [sucursales, guardarSucursales] = useState([]);
    const [favoritoselect, setFavoritoselect] = useState({productId: 0, IdSucursal: 0, cantidad: 0})
    const [clicDelete, setClicDelete] = useState(false);
    const navigate = useNavigate();

    const endModalPieza = () => setModalPieza(false);
    const endModalDireccion = () => setModalDireccion(false);
    const endModalFavorito = () => {
        if (carrito_favorito) {
            get_lista_carrito_compras();
        }
        setModalFavorito(false);
    };
    const ahorro_total = _ahorroTotal();

    function _ahorroTotal() {
        let total = 0;
        subPedidos.forEach(sp => {
            if (sp.check) {
                total += Number(sp.ahorro);
            }
        });
        return total.toFixed(2);
    }

    useEffect(() => {
        if (cambio_carrito && clicDelete) {
            get_lista_carrito_compras();
            set_cambio_carrito(false);
            setClicDelete(false);
        }
    }, [cambio_carrito, clicDelete]);

    useEffect(() => {
        if (error_save_carrito) {
            setSwAlert('Problemas técnicos para procesar la solicitud.', true, 400)
            limpiar_estado_confirmado()
            // "Problemas técnicos para continuar."
        }
    }, [error_save_carrito]);


    useEffect(() => {
        let lista = false;
        if (list_carrito) {
            if (list_carrito.SubPedidos.length > 0) {
                if (list_carrito.SubPedidos[0].check !== undefined) {
                    lista = list_carrito.SubPedidos[0].check;
                }
            }
        }
        if (lista === false) {
            console.log("carga carrito")
            store_carrito(sucursal, tipo_entrega, direccion, carritos, producto_delete)
        }

        return () => {
            guardarsubPedidos([]);
        };
    }, []);


    useEffect(() => {
        let array_aux = [];
        branch_offices.forEach((branch_office) => {
            let locatarios = branch_office.Locatarios;
            locatarios.forEach((sucursal) => {
                let array_zona_cobertura = sucursal.ZonasCobertura;
                array_zona_cobertura.forEach((zona) => {
                    const points = zona.PuntosCobertura.map((e) => {
                        return {
                            latitude: Number(e.Latitud),
                            longitude: Number(e.Longitud),
                        };
                    });
                    let coberturaSucursal = isPointInPolygon(
                        {
                            latitude: Number(direccion.Latitud),
                            longitude: Number(direccion.Longitud)
                        }, points)
                    if (coberturaSucursal) {
                        array_aux.push(sucursal);
                    }
                });
            })
        });
        guardarSucursales(array_aux);
    }, [direccion]);

    useEffect(() => {
        // if (list_carrito && sucursales.length > 0) {
        if (list_carrito) {
            guardarsubPedidos(list_carrito.SubPedidos.map(ps => {
                let _ahorro = 0;
                ps.Productos.forEach((prod) => {
                    if (_es_pieza(prod) && prod.ConOferta) {
                        let cantidad_pieza = _estimado_pieza(prod.Pesos);
                        let aux = prod.ConOferta ? prod.PrecioOferta : prod.PrecioVenta;
                        let total_sin_oferta = cantidad_pieza * prod.PrecioOriginal;
                        let total_oferta = cantidad_pieza * aux;
                        _ahorro += total_sin_oferta - total_oferta;
                    } else if (prod.ConOferta) {
                        _ahorro += (prod.PrecioOriginal * prod.Cantidad) - (prod.PrecioOferta * prod.Cantidad);
                    }
                });
                let check_activo = sucursales.find(sucursal => sucursal.IdSucursal === ps.Sala.Id);
                let check = ps.check !== undefined ? ps.check : true;
                return {
                    ...ps,
                    check: check_activo ? check : false,
                    ahorro: _ahorro.toFixed(2),
                    check_activo: check_activo ? true : false,
                }
            }));
        }
    }, [list_carrito, sucursales]);

    function _estimado_pieza(pesos) {
        let result = 0;
        pesos.forEach((peso) => {
            result += peso.Piezas * peso.PesoAproxMax;
        });

        return Number(result).toFixed(2);

    }


    useEffect(() => {
        if (Object.keys(producto_aux).length > 0) {
            actualizarDato(producto_aux.IdProducto, 'ninguna', IdSucursal_aux, 'pieza');
        }
    }, [producto_aux]);


    function _importeSubTotal(importeSubTotal, array) {
        let precio_no_disponible = 0;
        array.forEach(prod => {
            if (!prod.Disponible) {
                precio_no_disponible += Number(prod.Importe);
            }
        });
        let result = Number(importeSubTotal) - precio_no_disponible;
        return Number(result).toFixed(2);
    }

    function startModalDireccion() {
        setModalDireccion(true);
    }

    function startModalFavorito(favorito, cantidad, productId, IdSucursal, Pesos) {
        setFavoritoselect(
            {productId: productId, IdSucursal: IdSucursal, cantidad: cantidad, Pesos: Pesos}
        )
        setModalFavorito(true);
    }

    function startModalPieza(producto, IdSucursal) {
        setProductoAux(producto);
        setIdSucursalAux(IdSucursal);
        setModalPieza(true);
    }

    function check_carrito_sucursal(IdPedido) {
        const objetoClonado = Object.assign({}, list_carrito);
        let subpedidosaux = subPedidos.map(ps => {
            if (ps.IdPedido === IdPedido) {
                return {
                    ...ps,
                    check: !ps.check
                };
            } else {
                return {...ps};
            }

        });
        const listAux = {
            IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
            IdUbicacion: objetoClonado.IdUbicacion,
            ImporteTotal: objetoClonado.ImporteTotal,
            InstruccionDelivery: objetoClonado.InstruccionDelivery,
            InstruccionEntrega: objetoClonado.InstruccionEntrega,
            NroTotalProductos: objetoClonado.NroTotalProductos,
            SubPedidos: subpedidosaux
        }
        update_list_carrito_compras(listAux);
    }

    //validacion
    function eliminarCarrito(idPedido, IdMarket, idSala) {
        Swal.fire({
            title: 'Está seguro de eliminar la lista?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#32338E',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                delete_sublista_carrito(idPedido, IdMarket, idSala)
            }
        })
    }

    function delete_sublista_carrito(idPedido, IdMarket, idSala) {
        if (idSala === sucursal.IdSucursal) {
            set_delete_pedido_carrito(true);
            update_carrito([]);
        }

        delete_list_carrito(idPedido);


    }

    function _es_pieza(producto) {
        if (producto) {
            return producto.EsFraccionado && producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }

    function _es_peso(producto) {
        if (producto) {
            return producto.EsFraccionado && !producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }


    function obtener_precio(producto) {
        let monto_aux = producto.ConOferta ? producto.PrecioOferta : producto.PrecioVenta;
        return monto_aux;
    }

    function _importe(array) {
        let resultado = 0;
        array.forEach((elem) => {
            resultado += Number(elem.Importe);
        })
        return resultado.toFixed(2);
    }

    function actualizarDato(productId, operacion, IdSucursal, pieza) {
        const objetoClonado = Object.assign({}, list_carrito);
        var subpedidosaux = [];
        for (let item of objetoClonado.SubPedidos) {
            var ob = item;
            var productoaux = item.Productos;
            if (item.IdMarket == IdSucursal) {
                productoaux = [];
                for (let prod of item.Productos) {
                    const aux = prod;
                    if (pieza == 'pieza' && prod.IdProducto == productId) {
                        aux.Cantidad = producto_aux.Cantidad;
                        aux.Importe = producto_aux.Importe;
                    } else {
                        if (prod.IdProducto == productId) {
                            let quantity = 1;
                            if (_es_peso(prod)) {
                                quantity = 0.1;
                            }
                            if (operacion == 'incremento') {
                                aux.Cantidad = prod.Cantidad + quantity;

                            }
                            if (operacion == 'decremento' && prod.Cantidad > 0) {
                                aux.Cantidad = prod.Cantidad - quantity;
                            }
                            let monto = obtener_precio(prod);
                            let importe = aux.Cantidad * monto;
                            aux.Importe = importe.toFixed(2);
                        }
                    }
                    productoaux.push(aux);
                }
            }
            ob.ImporteSubtotal = _importe(productoaux);
            subpedidosaux.push(ob);
        }

        const listAux = {
            IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
            IdUbicacion: objetoClonado.IdUbicacion,
            ImporteTotal: objetoClonado.ImporteTotal,
            InstruccionDelivery: objetoClonado.InstruccionDelivery,
            InstruccionEntrega: objetoClonado.InstruccionEntrega,
            NroTotalProductos: objetoClonado.NroTotalProductos,
            SubPedidos: subpedidosaux
        }
        update_list_carrito_compras(listAux);
        set_cambio_carrito(true);
    }

    const fueraFoco = () => {

    }

    function actualizar_producto_aux(valor, operacion) {
        let producto = Object.assign({}, producto_aux);
        if (operacion == 'incremento') {
            producto.Cantidad += valor;
        }
        if (operacion == 'decremento') {
            producto.Cantidad -= valor;
        }
        if (operacion == 'ninguna') {
            let resultado = valor.peso / 1000;
            producto.Cantidad = resultado;
        }
        let monto =
            obtener_precio(producto);
        let importe = producto.Cantidad * monto;
        producto.Importe = importe.toFixed(2);
        setProductoAux(producto);
    }

    function get_total_carrito() {
        let total = 0;
        let precio_no_disponible = 0;
        subPedidos.forEach((sp) => {
            if (sp.check) {
                sp.Productos.forEach(prod => {
                    if (!prod.Disponible) {
                        precio_no_disponible += prod.Importe;
                    }
                })
            }
        });

        subPedidos.forEach((sp) => {
            if (sp.check) {
                total += sp.ImporteSubtotal;
            }
        });

        let result = parseFloat(total) - parseFloat(precio_no_disponible);
        return parseFloat(result).toFixed(2);
    }

    function validar_monto_minimo() {
        let cantidad = 0;
        for (let sp of subPedidos) {
            if (sp.check) {
                cantidad = 1;
                if (sp.ImpMinimoPedido > sp.ImporteSubtotal) {
                    return {
                        sala: sp.Sala,
                        monto_faltante: (sp.ImpMinimoPedido - sp.ImporteSubtotal),
                        validacion: false,
                        cantidad: cantidad,
                    };
                }
            }
        }
        return {
            sala: null,
            monto_faltante: 0,
            validacion: true,
            cantidad: cantidad,
        }
    }

    function mensaje_alert(text) {
        Swal.fire({
            position: 'top',
            icon: 'error',
            title: text,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function getTexto(t) {
        return "Faltan Bs." + t.monto_faltante.toFixed(2) + " para completar el mínimo de compra en " + t.sala.Descripcion;
    }

    async function actualizar_lista_carrito_db() {
        for (var item of subPedidos) {
            let params = {
                IdMarket: item.IdMarket,
                IdSucursal: item.IdMarket,
                IdTipoEntrega: item.IdTipoEntrega,
                IdDireccion: direccion.Id,
            }
            let carritos_aux = item.Productos;
            console.log("carga store_carrito_data carrito 1")
            await store_carrito_data(params, carritos_aux, []);
        }
    }

    function deleteDato(productId, IdMarket, IdSucursal) {
        let params = {
            IdMarket: IdMarket,
            IdSucursal: IdSucursal,
            IdTipoEntrega: tipo_entrega.Id,
            IdDireccion: direccion.Id,
        }
        let carritos_aux = get_carrito_sucursal(IdSucursal);
        let producto_delete = [{IdProducto: productId}];
        setClicDelete(true);
        console.log("carga store_carrito_data carrito 2")
        store_carrito_data(params, carritos_aux, producto_delete, true);
        if (IdSucursal === sucursal.IdSucursal) {
            remove_al_carrito_sucursal({IdProducto: productId})
        }
    }

    function get_carrito_sucursal(IdSucursal) {
        let array = [];
        subPedidos.forEach((sp) => {
            if (sp.Sala.Id === IdSucursal) {
                array = sp.Productos;
            }
        });
        return array;
    }

    function volverAtras() {
        exit();
    }

    function guardarProceso() {
        var validacion = validar_monto_minimo();
        if (validacion.validacion) {
            localStorage.setItem("mycart", JSON.stringify(list_carrito));
            localStorage.setItem("monto_mycart", JSON.stringify(get_total_carrito()));
            actualizar_lista_carrito_db();
            if (validacion.cantidad === 1) {
                setTimeout(() => {
                    next_indice();
                }, 1000);
            } else {
                mensaje_alert("No tiene producto seleccionado para realizar la compra.");
            }

        } else {
            var texto = getTexto(validacion);
            mensaje_alert(texto);
        }


    }

    function next_indice() {
        const objetoClonado = Object.assign({}, list_carrito);
        const listAux = {
            IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
            IdUbicacion: objetoClonado.IdUbicacion,
            ImporteTotal: objetoClonado.ImporteTotal,
            InstruccionDelivery: objetoClonado.InstruccionDelivery,
            InstruccionEntrega: objetoClonado.InstruccionEntrega,
            NroTotalProductos: objetoClonado.NroTotalProductos,
            SubPedidos: subPedidos
        }
        update_list_carrito_compras(listAux);
        mt_indice(2);
    }

    return (
        <>
            <Alert></Alert>
            <div
                className={classNames(
                    {"altura_carrito_default contenedor_mobile md:w-[60%]": width > 768},
                    {"h-[calc(100%-12rem)] w-100": width <= 768},
                )}
            >
                <div className="w-100  bg-white rounded-2xl h-full overflow-hidden">
                    <div className="bg-white rounded-hiper_g ">
                        <div
                            className="bg-hiperorange w-100 relative text-white flex items-center justify-center    content_title">
                            <h2 className='p-0 m-0 title_carrito_responsivo'>Carrito </h2>
                            <div className="absolute right-2 ">
                                <ButtonClose className="text-white bg-transparent" onClick={() => volverAtras()}/>
                            </div>
                        </div>

                        <div className={classNames("bg-white", "overflow-y-auto", "container_scroll",
                            {"max-h-[62vh]": height <= 667},
                            {"max-h-[66vh]": height > 667 && height <= 843},
                            {"max-h-[72vh]": height > 843},
                        )}>
                            <div className="px-2 pb-2 pt-1">
                                <p className="text-black mb-0">Dirección </p>
                                <span
                                    className="text-black font-bold cursor-pointer no-underline hover:underline  "
                                    onClick={() => startModalDireccion()}>{direccion.Descripcion}
                                    <FontAwesomeIcon
                                        style={{width: '15px', height: '15px'}}
                                        icon={faChevronDown}/>
                            </span>
                            </div>
                            <div className=' w-auto overflow-auto'>
                                {list_carrito ?
                                    subPedidos.length > 0 ?
                                        subPedidos.map((pedido, key) =>
                                            <div
                                                className="sm:w-100 border border-hiperorange shadow px-0  mb-2 w-fit"
                                                key={key}>
                                                <Disclosure defaultOpen as="div" className="my-2 px-2">
                                                    {({open}) => (
                                                        <>
                                                            {/*header*/}
                                                            <div
                                                                className=" w-full px-2 bg-hiperplomo_claro overflow-hidden py-0 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75"
                                                                style={{
                                                                    borderTopLeftRadius: '8px',
                                                                    borderTopRightRadius: '8px',
                                                                    borderBottom: '1px solid #9CA3AF',
                                                                }}>
                                                                <div
                                                                    className={classNames("w-full", "text-sm", "flex", "flex-wrap", "justify-between", "items-center", "md:px-2",
                                                                        {
                                                                            "bg-hiperplomo_claro": pedido.check_activo
                                                                        })}
                                                                    style={{
                                                                        boxShadow: '0px 10px 10px -10px rgba(33, 35, 38, 0.1)',
                                                                        // backgroundColor: pedido.check_activo ? '#B8B7B7' : '#DEDEDE',
                                                                        color: pedido.check_activo ? '#ffffff' : '#494A4E'
                                                                    }}
                                                                >
                                                                    <div className="flex flex-col py-1 gap-3 col_uno">
                                                                        <div
                                                                            className={classNames("rounded-lg", "rounded-xl", "text-hiperdark",
                                                                                {"bg-transparent": pedido.check_activo}
                                                                            )}
                                                                        >
                                                                            <div className="flex items-center  h-100">
                                                                                {pedido.check_activo &&

                                                                                    <div className="round w-8 mr-2">
                                                                                        <input type="checkbox"
                                                                                               checked={pedido.check}
                                                                                               onChange={() => check_carrito_sucursal(pedido.IdPedido)}
                                                                                               className="checkbox "
                                                                                               id={'checkbox' + pedido.IdPedido}/>
                                                                                        <label
                                                                                            htmlFor={'checkbox' + pedido.IdPedido}></label>
                                                                                        {/* <label for="checkbox"></label> */}
                                                                                    </div>
                                                                                }

                                                                                <h6 className="mb-0 font-bold "><span
                                                                                    className=" font-bold uppercase">{pedido.TipoServicio.Descripcion} </span> {pedido.Sala.Descripcion}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div>
                                                                                <button
                                                                                    className={classNames("cursor-pointer", "bg-hiperorange", "p-1", "text-white", "text-start",
                                                                                        {"bg-hiperplomo_fuerte": !pedido.check}
                                                                                    )}
                                                                                    style={{borderRadius: '8px'}}
                                                                                    onClick={() => eliminarCarrito(pedido.IdPedido, pedido.IdMarket, pedido.Sala.Id)}>
                                                                                <span
                                                                                    className="mx-2 fontzize_text_carrito">Eliminar lista</span>
                                                                                </button>
                                                                            </div>

                                                                            {
                                                                                !pedido.check_activo &&
                                                                                <p className="fontzize_text_carrito font-bold text-red-600 ">Se
                                                                                    encuentra
                                                                                    fuera de
                                                                                    cobertura de
                                                                                    entrega</p>
                                                                            }
                                                                        </div>

                                                                    </div>

                                                                    <div
                                                                        className="hipergrisdark text-right text-hiperdark  py-2  col_dos flex justify-end flex-wrap">
                                                                        <div className='w-100'>
                                                                            <p className="mb-0  fontzize_text_carrito font-bold">Compra
                                                                                mínima
                                                                                Bs. {pedido.ImpMinimoPedido}</p>
                                                                        </div>

                                                                        {
                                                                            pedido.ahorro > 0 &&
                                                                            <div
                                                                                className={classNames("px-3", "py-1", "bg-hiperorange", "text-white",
                                                                                    {"bg-hiperplomo_fuerte": !pedido.check}
                                                                                )}
                                                                                style={{
                                                                                    borderRadius: '20px',
                                                                                    width: 'fit-content',
                                                                                    float: 'right'
                                                                                }}>
                                                                                <p className="mb-0 font-bold "
                                                                                   style={{fontSize: '15px'}}>Bs. {pedido.ahorro} ahorrado</p>
                                                                            </div>

                                                                        }

                                                                        <div>
                                                                            <p className="mb-0 fontzize_text_carrito font-bold"
                                                                                //    style={{color: pedido.check_activo ? '#84B111' : '#494A4E'}}
                                                                            >Total
                                                                                parcial &#40; {pedido.Productos.length} productos&#41;&#58; Bs. {_importeSubTotal(pedido.ImporteSubtotal, pedido.Productos)}</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*end header*/}
                                                            <Disclosure.Panel
                                                                className="flex justify-between  mt-1 flex-column ">
                                                                {/*body*/}
                                                                <div
                                                                    className="overflow-y-auto  container_scroll">
                                                                    {
                                                                        pedido.Productos.map((producto, key) =>
                                                                            <ItemCarritoModal
                                                                                producto={producto}
                                                                                indice={key}
                                                                                IdMarket={pedido.IdMarket}
                                                                                IdSucursal={pedido.Sala.Id}
                                                                                favorito={producto.EnListadoCliente}
                                                                                ps_updateDato={actualizarDato}
                                                                                ps_startModalPieza={startModalPieza}
                                                                                ps_startModalFavorito={startModalFavorito}
                                                                                ps_deleteDato={deleteDato}
                                                                                key={producto.IdProducto}
                                                                                ps_pedidoCheked={pedido.check}
                                                                            ></ItemCarritoModal>
                                                                        )
                                                                    }
                                                                </div>

                                                            </Disclosure.Panel>
                                                            <div
                                                                className='w-100 bg-hiperorange flex justify-center overflow-hidden'
                                                                style={{
                                                                    borderBottomLeftRadius: '10px',
                                                                    borderBottomRightRadius: '10px',
                                                                    height: '30px'
                                                                }}>
                                                                <Disclosure.Button
                                                                    className={classNames('bg-hiperorange', {'bg-hiperplomo_fuerte': !pedido.check})}

                                                                    style={{width: '100%'}}>
                                                                    <FontAwesomeIcon
                                                                        className={`${
                                                                            !open ? 'rotate-180 transform' : ''
                                                                        } h-5 w-5 text-white`}
                                                                        style={{width: '28px', height: '28px'}}
                                                                        icon={faChevronUp}/>
                                                                </Disclosure.Button>
                                                            </div>
                                                        </>
                                                    )}
                                                </Disclosure>
                                            </div>
                                        )
                                        :
                                        <div className="h-32 flex justify-center items-center font-bold text-xl">
                                            No hay datos a mostrar
                                        </div>

                                    :
                                    <div
                                        className="h-32 flex flex-column justify-center items-center font-bold text-xl">
                                        <Circles
                                            color={'#100404'}
                                            width={60}
                                            height={60}
                                            ariaLabel="circles-loading"
                                        />
                                        <p>cargando...</p>
                                    </div>
                                }

                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className=" md:w-[22%]">
                <div className="content_card_footer text-darck  rounded-hiper_g "
                     style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem'}}
                >
                    <div className="content_child_footer mb-1">
                        <div className="px-3 py-1 bg-hiperorange mb-1 text-white"
                             style={{borderRadius: '20px', width: 'fit-content', float: 'right'}}>
                            <p className="mb-0 font-bold " style={{fontSize: '15px'}}>Bs. {ahorro_total} ahorrado</p>
                        </div>
                        <div className='flex p-1 justify-between  text-black '>
                            <span className='text-center m-0 fontzize_text_carrito font-bold'>Total:</span>
                            <span className='fontzize_text_carrito font-bold'>Bs {get_total_carrito()}</span>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center  text-center gap-2 text-white">
                        <ButtonText
                            type='button'
                            label='Seguir comprando'
                            principal={false}
                            className="bg-hiperorange_claro hover:bg-hiperorange_claro_hover text-black hover:text-black"
                            onClick={() => volverAtras()}
                            style={{border: '1px solid black'}}
                        />
                        <ButtonText
                            label='Continuar'
                            principal={true}
                            className="bg-hiperorange hover:bg-hiperorange_hover"
                            onClick={() => guardarProceso()}
                        />

                    </div>
                </div>
            </div>
            <Modal
                isOpen={modal_pieza}
                ariaHideApp={false}
                onRequestClose={endModalPieza}
                style={customStyles}
                contentLabel="carrito"
            >
                <div className="w-80 relative">
                    <div className="card border-0 relative">
                        <div className="absolute top-[-30px] right-[-2rem]">
                            <FontAwesomeIcon icon={faCircleXmark}
                                             className='w-[2.5rem] h-[2.5rem] rounded-full text-hiperorange bg-white cursor-pointer '
                                             onClick={() => endModalPieza()}
                            />
                        </div>
                        <div className="text-center">
                            <h5 className="font-bold">Seleccione el Peso</h5>
                        </div>
                        <div className="card-body pt-0">
                            <div className="row">
                                {
                                    pesajes.map((pesaje, index) => (
                                        <div className="col-6" key={index}>
                                            <div
                                                className="bg-[#EEEEEE] m-2 px-3 py-2 rounded text-center cursor-pointer hover:bg-gray-400"
                                                onClick={() => actualizar_producto_aux(pesaje, 'ninguna')}>
                                                <span className="font-semibold text-xl">{pesaje.name}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center">
                            <div className="flex border-[3px] border-hiperorange p-1 rounded">
                                <FontAwesomeIcon icon={faSquareMinus}
                                                 className='w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer'
                                                 onClick={() => actualizar_producto_aux(0.1, 'decremento')}
                                />
                                <div className="mx-2 flex align-items-center">
                                    <span>{Number(producto_aux.Cantidad).toFixed(2)}</span>
                                </div>
                                <FontAwesomeIcon icon={faSquarePlus}
                                                 className='w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer'
                                                 onClick={() => actualizar_producto_aux(0.1, 'incremento')}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modal_favorito}
                ariaHideApp={false}
                onRequestClose={endModalFavorito}
                style={style_favorito}
                contentLabel="favorito"
            >
                <Favorito
                    idProducto={favoritoselect.productId}
                    IdSucursal={favoritoselect.IdSucursal}
                    cantidad={favoritoselect.cantidad}
                    pesos={favoritoselect.Pesos}
                    onBlur={fueraFoco}
                    ps_closeModalFavorito={endModalFavorito}
                ></Favorito>
            </Modal>

            <Modal
                isOpen={modal_direccion}
                ariaHideApp={false}
                onRequestClose={endModalDireccion}
                style={style_direccion}
                contentLabel="direccion"
            >
                <Direccion close_modal={endModalDireccion}/>
            </Modal>
        </>
    );
}

const mapStateToProps = state => ({
    branch_offices: state.BranchOffice.branch_offices,
    list_carrito: state.Carrito.list_carrito,
    carrito_favorito: state.Carrito.carrito_favorito,
    sucursal: state.BranchOffice.sucursal,
    tipo_entrega: state.BranchOffice.tipo_entrega,
    direccion: state.Location.direccion,
    carritos: state.Carrito.carrito,
    cambio_carrito: state.Carrito.cambio_carrito,
    producto_delete: state.Carrito.producto_delete,
    error_save_carrito: state.Carrito.error_save_carrito,
})

export default connect(mapStateToProps, {
    get_lista_carrito_compras,
    update_carrito,
    store_carrito,
    store_carrito_data,
    delete_list_carrito,
    update_list_carrito_compras,
    set_cambio_carrito,
    remove_al_carrito_sucursal,
    limpiar_estado_confirmado,
    set_delete_pedido_carrito,
    setSwAlert
})(Carrito)