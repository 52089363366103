import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faCartShopping,
    faChevronDown, faCommentDots,
    faLocationDot, faMessage, faMoneyBill,
    faMotorcycle,
    faRightFromBracket,
    faCheck
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import "../../../assets/styles/responsive.scss"

function Indice({posicion,tipo_entrega}) {

    const [sucursales, guardarSucursales] = useState([]);

    useEffect(() => {

    }, []);

    useEffect(() => {

    }, []);
    const verify_position=(pos_current)=>{
        if(pos_current < posicion){
            return true;
        }
        return false;
    }
 


    return (
        <>
            <div className="w-100">
                <div className="bg-[#EEEEEE] rounded-hiper_g overflow-hidden ">
                    <div className={classNames("py-2.5", "px-2","text-hiperplomo_fuerte","flex","justify-between",
                        {
                            "bg-hiperplomo_fuerte text-white": posicion === 1
                        })}
                        
                        >
                         
                        <div className='flex gap-2'>
                        <div className='flex justify-center' style={{width:'1.7rem'}}>
                            <FontAwesomeIcon 
                            icon={faMotorcycle} className="mx-2  icon_indice"/></div>Datos entrega
                        </div>
                        {verify_position(1) && 
                            <FontAwesomeIcon 
                            icon={faCheck} className="mx-2 self-center text-hiperplomo_fuerte icon_indice"/>
                        }

                    </div>
                    <div className={classNames("py-2.5", "px-2","text-hiperplomo_fuerte","flex","justify-between",
                        {
                            "bg-hiperplomo_fuerte text-white": posicion === 2
                        })}
                        >
                        <div className='flex gap-2'>
                        <div className='flex justify-center' style={{width:'1.7rem'}}>
                            <FontAwesomeIcon 
                            icon={faMoneyBill} className="mx-2 icon_indice"/></div>Métodos de pago
                        </div>
                        {verify_position(2) && 
                            <FontAwesomeIcon 
                            icon={faCheck} className="mx-2 self-center text-hiperplomo_fuerte icon_indice"/>
                        }

                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    tipo_entrega: state.BranchOffice.tipo_entrega,
})

export default connect(mapStateToProps, {})(Indice)