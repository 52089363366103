import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronUp,
    faEye,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {Popover, Transition} from '@headlessui/react';
import {
    confirmar_carrito,
    confirmar_pago,
    delete_list_carrito,
    get_lista_carrito_compras, save_pedido, set_cambio_carrito, store_carrito, store_carrito_data, update_carrito,
    update_list_carrito_compras, save_horarios, remove_al_carrito_sucursal, store_carrito_data_anonimo
} from "../../../redux/actions/carrito";
import {save_formas_pago} from "../../../redux/actions/metodoPagos";
import {Disclosure} from '@headlessui/react'
import {useNavigate} from "react-router-dom";
import Modal from "react-modal";
import ItemConfirmacionPedido from "../../../widgets/item_confirmacion_pedido";
import {reset_datos_pago} from "../../../redux/actions/metodoPagos";
import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/responsive.scss"
import ButtonText from "../../../widgets/button_text";
import {huellaDigital} from './../../../huellaDigital';
import Registro_user from './Partials/registro_user';
import Registro_activate from './Partials/registro_activate';
import Termino_condicion from './Partials/termino_condicion';
import {clear_pedidos} from "../../../redux/actions/pedido";
import {end_peticion} from "../../../redux/actions/peticion";
import classNames from "classnames";
import RegistroVale from "./Partials/RegistroVale";
import ButtonClose from "../../../widgets/button_close";

const customStyles2 = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '35',
    },
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0 px',
        border: 'none',
        background: 'none',
        padding: '0px 10px 10px 10px',
        marginRight: '-50%',
        // marginTop: '50px',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
};

const style_vale = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '31',
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '0',
        borderRadius: '10px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'auto',
        border: '0px',
        background: 'transparent'
    },
};


function ComentarioVale({
                            end_peticion,
                            clear_pedidos,
                            profile,
                            confirmar_pago,
                            confirmar_carrito,
                            reset_datos_pago,
                            loading,
                            save_pedido, respuesta_save_pedido, respuesta_confirmar_carrito,
                            list_carrito,
                            direccion,
                            finalizado,
                            mt_indice, exit,
                            store_carrito_data,
                            save_formas_pago,
                            save_horarios,
                            get_lista_carrito_compras,
                            update_list_carrito_compras,

                            remove_al_carrito_sucursal,
                            branch_offices,
                            respuesta_carrito_anonimo,
                            store_carrito_data_anonimo,
                            width,
                            height,
                            exitAnonimo
                        }) {
    const [token] = useState(localStorage.getItem('access_token'));
    const [subPedidos, guardarsubPedidos] = useState([]);
    const [listaVales, setListaVales] = useState([]);
    const [listaComentarios, setListaComentarios] = useState([]);
    const [es_anonimo, setEsAnonimo] = useState(false);
    const [pedidoSeleccionado, SetPedidoSeleccionado] = useState(null);
    const [vale, setVale] = useState('');
    const navigate = useNavigate();
    const ahorro_total = _ahorroTotal();

    const [opc_numero, setOpcion_numero] = useState(true);
    const [posicion, setPosicion] = useState(1);

    const [modalRegistro, setModalRegistro] = useState(false);
    const [modalVale, setModalVale] = useState(false);
    const [sucursales, guardarSucursales] = useState([]);
    const [errorMonto, setErrorMonto] = useState("");
    useEffect(() => {
        // end_peticion()
    }, []);
    useEffect(() => {
        console.log("proflie", profile);
        if (profile) {
            if (!profile.Telefono) {
                setEsAnonimo(true);
                return;
            } else {
                setEsAnonimo(false);
            }
        }
    }, [profile]);


    useEffect(() => {
        console.log("actu mylist", list_carrito);
        if (list_carrito) {
            let subPedido = list_carrito.SubPedidos.filter((pedido) => pedido.check);
            console.log("1.1", subPedido)
            if (subPedido.length > 0) {
                guardarsubPedidos(subPedido);
            }

        }
    }, [list_carrito]);

    useEffect(() => {
        console.log("respuesta_confirmar_carrito", respuesta_confirmar_carrito);
        if (respuesta_confirmar_carrito) {
            if (!respuesta_confirmar_carrito.ConError) {
                // exit(); //verifica cambios y guarda lista carrito y limpia
                finalizado(); //cambia estado para mostrar carro o mensaje finalizado
            }
        }
    }, [respuesta_confirmar_carrito]);

    useEffect(() => {
        console.log("resp anonimato", respuesta_carrito_anonimo);
        if (respuesta_carrito_anonimo) {
            exitAnonimo();
        }
    }, [respuesta_carrito_anonimo])
    useEffect(() => {
        async function fetchData() {
            try {
            } catch (e) {
                console.error(e);
            }
        };
        fetchData();
        reset_datos_pago();

    }, []);

    function startModalVale(pedido) {
        SetPedidoSeleccionado(pedido)
        setModalVale(true);
    }

    function get_total_carrito() {
        let total = 0;
        let precio_no_disponible = 0;
        subPedidos.forEach((sp) => {
            if (sp.check) {
                sp.Productos.forEach(prod => {
                    if (!prod.Disponible) {
                        precio_no_disponible += prod.Importe;
                    }
                })
            }
        });
        subPedidos.forEach((sp) => {
            if (sp.check) {
                total += sp.ImporteSubtotal;
            }
        });
        let result = parseFloat(total) - parseFloat(precio_no_disponible);
        return parseFloat(result).toFixed(2);
    }

    function _ahorroTotal() {
        let total = 0;
        subPedidos.forEach(sp => {
            if (sp.check) {
                total += Number(sp.ahorro);
            }
        });
        return parseFloat(total).toFixed(2);
    }

    function existe_list_carrito() {
        return list_carrito != null ? true : false;
    }

    function getTipoEntrega() {
        return JSON.parse(localStorage.getItem("tipo_entrega_mycart"))

    }

    function get_ubicacion() {
        var mycart_storage = JSON.parse(localStorage.getItem("datos_personales"));
        if (typeof (mycart_storage) != 'undefined' && mycart_storage != null) {
            return mycart_storage.direccion;
        }
        return null;
    }

    function get_horario(sucursal_id) {
        var horario = JSON.parse(localStorage.getItem("horario"));
        var index = horario.map(item => item.IdSucursal).indexOf(sucursal_id);
        //  console.log("esete es el index horario ", horario);
        if (index != -1) {
            return horario[index];
        }
        return index;

    }


    function volverAtras() {
        mt_indice(4)
    }

    async function get_datos_pago() {
        return await JSON.parse(localStorage.getItem("metodo_pago"));


    }


    function existe_val(val) {
        return typeof (val.vale) != 'undefined';
    }

    async function get_productos(sucursal_id) {
        let array = [];
        let pesos = [];
        subPedidos.forEach((pedido) => {
            if (pedido.Sala.Id == sucursal_id) {
                pedido.Productos.forEach((pro) => {
                    if (pro.PesoDefinido) {
                        if (pro.Pesos != null) {
                            pesos = pro.Pesos.map((e) => {
                                return {
                                    IdPeso: e.IdPeso,
                                    Piezas: e.Piezas,
                                };
                            });
                        }
                    }
                    array.push({
                        'IdProducto': pro.IdProducto,
                        'Cantidad': pro.Cantidad,
                        'Pesos': pesos,
                    })
                });
            }
        })

        return array;
    }

    const setComentario = (idsucursal, texto) => {
        console.log("add comentario " + idsucursal + "vale " + texto);
        var aux = listaComentarios.slice();

        let index = aux.map(item => item.idsucursal).indexOf(idsucursal);

        if (index === -1) {
            aux.push({idsucursal: idsucursal, comentario: texto});
        } else {

            aux[index] = {idsucursal: idsucursal, comentario: texto};
        }
        setListaComentarios(aux);
    }

    function guardarProceso() {
    }

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const save_pedidos = async (e) => {
        // agregar modal registro movil
        console.log("save_pedidos");
        if (profile.Telefono === "") {
            // if (true) {
            // if (es_anonimo) {
            // navigate("/registro_mobil");
            console.log("hola");
            setModalRegistro(true)
            return;
        } else if (!profile.TerminoCondicion.AceptoTerminoActual) {
            console.log("open termino");
            selected_opcion(true);
            setModalRegistro(true)
            cambiar_posicion(3);
            return;
        }
        console.log("va concluir pedido normal");

        let SubpedidosConfirmado = [];

        for (let index = 0; index < subPedidos.length; index++) {
            let item = subPedidos[index];


            var tipoentrega = item.IdTipoEntrega;
            var ubicacion = direccion;
            var horario = item.Horario;
            var datospago = await get_datos_pago();
            var productos = await get_productos(item.Sala.Id)
            if (horario == -1) {
                console.log("no se encontro el horario");
                return;
            }
            if (ubicacion == null) {
                console.log("no se encontro la ubicacion");
                return;
            }
            if (tipoentrega == -1) {
                console.log("no se encontro el tipo de entrega");
                return;
            }
            if (datospago == null) {
                console.log("no se encontro el metodo de pago");
                return;
            }

            var datos = {
                IdSucursal: Number(item.Sala.Id),
                IdTipoEntrega: Number(tipoentrega),
                IdUbicacion: parseInt(ubicacion.Id),
                Horario: {
                    IdFecha: horario["IdFecha"],
                    IdHorario: horario["IdHorario"],
                    IdHorarioEspecial: horario["IdHorarioEspecial"]
                },
                DatosPago: {
                    IdMetodoPago: Number(datospago["IdMetodoPago"]),
                    Nit: Number(datospago["Nit"]),
                    RazonSocial: datospago["RazonSocial"]
                },
                Productos: productos
            }
            console.log("await confirmar_pago", datos)
            await confirmar_pago(datos);

            var ele = listaVales.find(ele => ele.idsucursal === item.Sala.Id);
            var aux_list = [];
            if (typeof (ele) != 'undefined') {
                aux_list = get_new_formato_lista_vales(ele.vale);
            }

            SubpedidosConfirmado.push({
                IdSubPedido: Number(item.IdPedido),
                NotaPedido: getComentario(item.Sala.Id),
                ListaVale: aux_list
            });

        }


        let huella = await huellaDigital(profile.IdUsuario);

        let datos_pedido = {
            SubpedidosConfirmado: SubpedidosConfirmado,
            SubPedidos: [],
            IdSession: huella
        }

        console.log("datos enviados,,,xxxxxx ", datos_pedido);
        await confirmar_carrito(datos_pedido);
        clear_pedidos()
    };


    // any
    const save_pedidos_anonimo = async (e) => {
        // agregar modal registro movil
        console.log("save_pedidos");
        if (profile.Telefono === "") {
            // if (true) {
            // if (es_anonimo) {
            // navigate("/registro_mobil");

            setModalRegistro(true)
            return;
        } else if (!profile.TerminoCondicion.AceptoTerminoActual) {
            console.log("open termino");
            selected_opcion(true);
            setModalRegistro(true)
            cambiar_posicion(3);
            return;
        }
        // await timeout(1000);
        console.log("va concluir pedido anonimo");
        // await cargar_carrito_user();

        await actualizar_lista_carrito_db();
        console.log("actualizó carrrito");
        await cargar_carrito_user();


    };

    async function cargar_carrito_user() {
        const token = localStorage.getItem('access_token');
        await get_lista_carrito_compras(token);
    }

    // function deleteDato(productId, IdMarket, IdSucursal) {
    //     let params = {
    //         IdMarket: IdMarket,
    //         IdSucursal: IdSucursal,
    //         IdTipoEntrega: tipo_entrega.Id,
    //         IdDireccion: direccion.Id,
    //     }
    //     let carritos_aux = get_carrito_sucursal(IdSucursal);
    //     console.log("eliminar_item_carrito",carritos_aux)
    //     let producto_delete = [{IdProducto: productId}];
    //     store_carrito_data(params, carritos_aux, producto_delete);
    //     if (IdSucursal === sucursal.IdSucursal) {
    //         remove_al_carrito_sucursal({IdProducto: productId})
    //     }
    // }
    async function actualiza_pedido() {
        console.log("my", list_carrito);
        if (list_carrito) {
            let subPedido = list_carrito.SubPedidos;
            console.log("1.1my", subPedido)
            guardarsubPedidos(subPedido);
        }
    }

    async function cargar_horarios() {
        let aux_hora = [];
        for (let index = 0; index < subPedidos.length; index++) {

            let item = subPedidos[index];
            let horario = item.Horario;


            let datos_hora = {
                IdSucursal: Number(item.Sala.Id),
                IdFecha: horario["IdFecha"],
                IdHorario: Number(horario["IdHorario"]),
                IdHorarioEspecial: horario["IdHorarioEspecial"]
            };
            aux_hora.push(datos_hora);
        }
        console.log("se va enviar hrs", aux_hora);
        await save_horarios(aux_hora);
    }

    const actualizar_lista_carrito_db = async () => {
        console.log("aaa..", subPedidos);
        for (let index = 0; index < subPedidos.length; index++) {
            let item = subPedidos[index];
            let params = {
                IdMarket: item.IdMarket,
                IdSucursal: item.Sala.Id,

                IdTipoEntrega: item.IdTipoEntrega,
                IdDireccion: direccion.Id,
            }
            let carritos_aux = item.Productos;

            console.log('params', params, carritos_aux);
            console.log('prod', carritos_aux);
            await store_carrito_data_anonimo(params, carritos_aux, []);

        }
    }

    const endModalRegistro = () => {
        console.log("cerrar modal");
        setModalRegistro(false);
    }
    const endModalVale = () => {
        console.log("cerrar modal");
        setModalVale(false);
    }


    const mostrarModal = (idsucursal) => {
        console.log("show modal ", idsucursal);
        cerrar_modal(idsucursal);
    };

    function get_IdPedidoPrincipal() {
        var carritox = JSON.parse(localStorage.getItem("mycart"));
        return carritox.IdPedidoPrincipal;
    }

    const delete_item_val_sucursal = (idsucursal, index, e) => {
        console.log("show modal ", idsucursal, " index ", index);
        var aux = listaVales.slice();
        let i = aux.map(item => item.idsucursal).indexOf(idsucursal);
        var auxlistaval = aux[i].vale;
        auxlistaval.splice(index, 1);
        aux[i] = {idsucursal: idsucursal, vale: auxlistaval};
        setListaVales(aux);
        //   cerrar_modal(idsucursal);
    };

    function get_new_formato_lista_vales(vales) {
        var aux = [];
        for (var item of vales) {
            aux.push({Codigo: "", Monto: item});
        }
        return aux;
    }

    function getComentario(sucursal_id) {
        var comentario = "";
        var aux = listaComentarios.find(ele => ele.idsucursal === sucursal_id);
        if (typeof (aux) != 'undefined') {
            return aux.comentario;
        }
        return comentario;
    }

    function showModal_vale(idsucursal) {
        console.log("show modal ", idsucursal);
        cerrar_modal(idsucursal);
    }


    function addVale(idsucursal) {
        console.log("monto vale *" + vale + "*");
        setErrorMonto("");
        if (vale != "") {
            if (vale > 0) {
                var aux = listaVales.slice();
                let index = aux.map(item => item.idsucursal).indexOf(idsucursal);
                if (index === -1) {
                    aux.push({idsucursal: idsucursal, vale: [vale]});
                } else {
                    var auxlistaval = aux[index].vale;
                    auxlistaval.push(vale);
                    aux[index] = {idsucursal: idsucursal, vale: auxlistaval};
                }
                setListaVales(aux);
                return true;
            } else {
                return false;
            }
        } else {
            setErrorMonto("Solo se permite números");
        }
    }

    function updateListaVales(array, idSucursal) {
        let object = {
            idsucursal: idSucursal, vale: array
        };
        let check = listaVales.find(list => list.idsucursal === idSucursal);
        console.log(object, listaVales, check)
        if (check) {
            console.log("entra")
            setListaVales(listaVales.map((lista) => {
                if (lista.idsucursal === idSucursal) {
                    return {
                        ...lista,
                        vale: array
                    }
                } else {
                    return lista
                }
            }));
        } else {
            console.log("no entra")
            setListaVales([...listaVales, object]);
        }
    }

    function _countVales(idSucursal) {
        let check = listaVales.find(list => list.idsucursal === idSucursal);
        if (check) {
            return check.vale.length;
        }
        return 0;
    }

    function cerrar_modal(idsucursal) {
        document.getElementById('modal' + idsucursal).classList.toggle('hidden');
    }

    function get_monto_importe(sucursal_id) {
        var total = 0;
        const objetoClonado = Object.assign({}, list_carrito);
        var subpedidosaux = [];
        for (let item of objetoClonado.SubPedidos) {
            var ob = item;
            if (item.IdMarket == sucursal_id) {
                var productoaux = [];
                for (let prod of item.Productos) {
                    var importe = prod.Cantidad * prod.PrecioVenta;
                    total += importe
                }
            }
            subpedidosaux.push(ob);
        }
        return total;
    }

    // opcione registro

    async function selected_opcion(value) {
        console.log("opc bool", value);
        setOpcion_numero(value);
    }

    async function cambiar_posicion(numero) {
        setPosicion(numero);
    }

    const check_no_anonimo = async () => {
        setEsAnonimo(false);

    }

    function formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <>


            <div className={classNames(
                {"altura_carrito_default contenedor_mobile md:w-[60%]": width > 768},
                {"h-[calc(100%-12rem)] w-100": width <= 768},
            )}>
                <div className="w-100  bg-white rounded-2xl h-full overflow-hidden">
                    <div className="bg-white rounded-hiper_g">
                        <div
                            className="bg-hiperorange relative w-100 text-white flex items-center justify-center  content_title">
                            <h2 className='p-0 m-0 text-title_g'>Comentario y vale </h2>
                            <div className="absolute right-2 ">
                                <ButtonClose className="text-white bg-transparent" onClick={() => exit()}/>
                            </div>
                        </div>
                        <div className={classNames("bg-white", "overflow-y-auto", "container_scroll",
                            {"max-h-[62vh]": height <= 667},
                            {"max-h-[66vh]": height > 667 && height <= 843},
                            {"max-h-[72vh]": height > 843},
                        )}>
                            {
                                subPedidos.map((pedido, key) =>
                                    <div
                                        className="sm:w-100 border border-hiperorange shadow px-0  mb-2" key={key}>
                                        <Disclosure defaultOpen as="div" className="my-2 px-2">
                                            {({open}) => (
                                                <>
                                                    {/*header*/}
                                                    <div
                                                        className=" w-full px-2 bg-hiperplomo_claro overflow-hidden py-0 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75"
                                                        style={{
                                                            borderTopLeftRadius: '8px',
                                                            borderTopRightRadius: '8px',
                                                            borderBottom: '1px solid #9CA3AF',
                                                        }}>
                                                        <div
                                                            className={classNames("w-full", "text-sm", "flex", "flex-wrap", "justify-between", "items-center", "md:px-2")}
                                                            style={{
                                                                boxShadow: '0px 10px 10px -10px rgba(33, 35, 38, 0.1)',
                                                                color: pedido.check_activo ? '#ffffff' : '#494A4E'
                                                            }}
                                                        >
                                                            <div className="grid grid-rows-2 gap-3 col_uno">
                                                                <div
                                                                    className={classNames("rounded-lg", "rounded-xl", "text-hiperdark")}
                                                                >
                                                                    <div className="flex items-center  h-100">
                                                                        <h6 className="mb-0 font-bold "><span
                                                                            className=" font-bold uppercase">{pedido.TipoServicio.Descripcion} </span> {pedido.Sala.Descripcion}
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <button
                                                                        className="flex items-center cursor-pointer bg-hiperorange p-1 px-2  text-white text-start"
                                                                        style={{borderRadius: '8px'}}
                                                                        onClick={() => startModalVale(pedido)}>
                                                                        <p className="mx-2 mb-0 fontzize_text_carrito inline">Vales
                                                                            agregados </p>
                                                                        <span
                                                                            className="m-0 flex justify-center items-center w-5 h-5 bg-white rounded-full text-hiperorange">{_countVales(pedido.Sala.Id)}</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="hipergrisdark text-right text-hiperdark  py-2  col_dos flex justify-end flex-wrap">
                                                                {
                                                                    pedido.ahorro > 0 &&
                                                                    <div className="px-3 py-1 bg-hiperorange text-white"
                                                                         style={{
                                                                             borderRadius: '20px',
                                                                             width: 'fit-content',
                                                                             float: 'right'
                                                                         }}>
                                                                        <p className="mb-0 font-bold "
                                                                           style={{fontSize: '15px'}}>Bs. {pedido.ahorro} ahorrado</p>
                                                                    </div>
                                                                }

                                                                <div>
                                                                    <p className="mb-0 fontzize_text_carrito font-bold">Total
                                                                        parcial &#40; {pedido.Productos.length} productos&#41;&#58; Bs. {Number(pedido.ImporteSubtotal).toFixed(2)}</p>
                                                                </div>
                                                                <div className='w-100'>
                                                                    <p className="mb-0  fontzize_text_carrito font-bold">Costo
                                                                        envío: {pedido.CostoEnvio ? " " + pedido.CostoEnvio : " Gratis"}</p>
                                                                </div>
                                                            </div>
                                                            <div className='w-100  self-end'>
                                                                <label htmlFor="comentario"
                                                                       className="fontzize_label text-black ">Agrega un
                                                                    comentario o nota adicional (opcional)</label>
                                                                <textarea name="comentario" id="comentario" rows="4"
                                                                          className=" w-100 text-black p-2 border_black radius_input my-2"
                                                                          placeholder="Comentario sobre tu pedido (Opcional)"
                                                                          onChange={e => setComentario(pedido.Sala.Id, e.target.value)}>
                                                                        
                                                                    </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*end end*/}
                                                    {/*body*/}
                                                    <Disclosure.Panel
                                                        className="flex justify-between  mt-1 flex-column ">
                                                        <div className="overflow-y-auto  container_scroll">
                                                            {
                                                                pedido.Productos.map((producto, key) =>
                                                                    <ItemConfirmacionPedido
                                                                        colour={(key % 2 === 0)}
                                                                        producto={producto}
                                                                        idProducto={producto.IdProducto}
                                                                        key={producto.IdProducto}
                                                                        descripcion={producto.Descripcion}
                                                                        cantidad={producto.Cantidad}
                                                                        precioVenta={producto.PrecioVenta}
                                                                        moneda={producto.Moneda}
                                                                        importe={producto.Importe}
                                                                        imagen={producto.UrlFoto}
                                                                        IdMarket={pedido.IdMarket}
                                                                        unidadMedida={producto.UnidadMedida}

                                                                    >
                                                                    </ItemConfirmacionPedido>
                                                                )
                                                            }
                                                        </div>
                                                    </Disclosure.Panel>
                                                    {/*end body*/}
                                                    <div
                                                        className='w-100 bg-hiperorange flex justify-center overflow-hidden'
                                                        style={{
                                                            borderBottomLeftRadius: '10px',
                                                            borderBottomRightRadius: '10px',
                                                            height: '30px'
                                                        }}>
                                                        <Disclosure.Button className='bg-hiperorange'
                                                                           style={{width: '100%'}}>
                                                            <FontAwesomeIcon
                                                                className={`${
                                                                    !open ? 'rotate-180 transform' : ''
                                                                } h-5 w-5 text-white`}
                                                                style={{width: '28px', height: '28px'}}
                                                                icon={faChevronUp}/>
                                                        </Disclosure.Button>
                                                    </div>
                                                </>
                                            )}
                                        </Disclosure>
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </div>
            </div>

            <div className="md:w-[22%]">
                <div className="content_card_footer text-darck  rounded-hiper_g "
                     style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem'}}>
                    <div className="content_child_footer mb-1 ">
                        <div className=" px-3 py-1 bg-hiperorange mb-1 text-white "
                             style={{borderRadius: '20px', width: 'fit-content', float: 'right'}}>
                            <p className="mb-0 font-bold "
                               style={{fontSize: '15px'}}>Bs. {ahorro_total} ahorrado</p>
                        </div>
                        <div className='flex p-1 justify-between  text-black '>
                            <span className='text-center m-0 fontzize_text_carrito font-bold'>Total:</span>
                            <span className='fontzize_text_carrito font-bold'>Bs {get_total_carrito()}</span>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center  text-center gap-2 text-white">
                        <ButtonText
                            type='button'
                            label='Volver'
                            principal={false}
                            className="bg-hiperorange_claro hover:bg-hiperorange_claro_hover text-black hover:text-black border border-black"
                            onClick={() => volverAtras()}

                        />
                        <ButtonText
                            label='Confirmar pedido'
                            principal={true}
                            className="bg-hiperorange hover:bg-hiperorange_hover"
                            onClick={() => save_pedidos()}
                        />
                    </div>
                </div>
            </div>
            <Modal
                isOpen={modalRegistro}
                ariaHideApp={false}
                onRequestClose={endModalRegistro}
                style={customStyles2}
                contentLabel="Ingresar"
                id="theIdRegister"
            >

                {posicion == 1 &&
                    <Registro_user ps_posicion={cambiar_posicion}></Registro_user>
                }
                {posicion == 2 &&
                    <Registro_activate ps_posicion={cambiar_posicion}
                                       ps_save_pedidos={save_pedidos_anonimo} ps_endModalRegistro={endModalRegistro}
                                       ps_opcion_numero={selected_opcion}
                                       ps_check_no_anonimo={check_no_anonimo}></Registro_activate>
                }
                {posicion == 3 &&
                    <Termino_condicion ps_posicion={cambiar_posicion}
                                       ps_save_pedidos={save_pedidos_anonimo} ps_endModalRegistro={endModalRegistro}
                                       ps_opcion_numero={selected_opcion}
                                       ps_check_no_anonimo={check_no_anonimo}></Termino_condicion>
                }

            </Modal>

            {pedidoSeleccionado &&
                <Modal
                    isOpen={modalVale}
                    ariaHideApp={false}
                    onRequestClose={endModalVale}
                    style={style_vale}
                    contentLabel="VALE"
                >
                    <RegistroVale idsucursal={pedidoSeleccionado.Sala.Id}
                                  listaVales={listaVales}
                                  updateVales={updateListaVales}
                                  endModalVale={endModalVale}
                    ></RegistroVale>
                </Modal>
            }


        </>
    );
}

const mapStateToProps = state => ({
    branch_offices: state.BranchOffice.branch_offices,
    loading: state.Location.loading,
    list_carrito: state.Carrito.list_carrito,
    carrito_favorito: state.Carrito.carrito_favorito,
    sucursal: state.BranchOffice.sucursal,
    tipo_entrega: state.BranchOffice.tipo_entrega,
    direccion: state.Location.direccion,
    carritos: state.Carrito.carrito,
    producto_delete: state.Carrito.producto_delete,
    respuesta_confirmar_carrito: state.Carrito.respuesta_confirmar_carrito,
    profile: state.Profile.profile,
    respuesta_carrito_anonimo: state.Carrito.respuesta_carrito_anonimo
})

export default connect(mapStateToProps, {
    get_lista_carrito_compras,
    update_carrito,
    store_carrito,
    store_carrito_data,
    delete_list_carrito,
    update_list_carrito_compras,
    set_cambio_carrito,
    reset_datos_pago,
    confirmar_pago,
    confirmar_carrito,
    save_pedido,
    save_formas_pago,
    save_horarios,
    store_carrito_data_anonimo, clear_pedidos,
    end_peticion
})(ComentarioVale)