//Authentication
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const REMOVE_AUTH_LOADING = 'REMOVE_AUTH_LOADING';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAIL = 'REFRESH_FAIL';
export const ACTIVACION_ANONIMO_SUCCESS= 'ACTIVACION_ANONIMO_SUCCESS';
export const CLEAR_AUTH= 'CLEAR_AUTH';
export const PREFIJO_SUCCESS= 'PREFIJO_SUCCESS';
export const PREFIJO_FAIL= 'PREFIJO_FAIL';
export const DEPARTAMENTO_SUCCESS= 'DEPARTAMENTO_SUCCESS';
export const DEPARTAMENTO_FAIL= 'DEPARTAMENTO_FAIL';
export const CODE_ACCESS_EXTERM_SUCCESS= 'CODE_ACCESS_EXTERM_SUCCESS';
export const CODE_ACCESS_EXTERM_FAILS= 'CODE_ACCESS_EXTERM_FAILS';

// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAD_ALERT = 'CLEAD_ALERT';
export const NO_ALERT = 'NO_ALERT';

// Ubicacion
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';
export const POST_LOCATION_SUCCESS = 'POST_LOCATION_SUCCESS';
export const POST_LOCATION_FAIL = 'POST_LOCATION_FAIL';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';
export const GET_LOCATION_ACTUAL= 'GET_LOCATION_ACTUAL';
export const SET_LOCATION_OMITIDO= 'SET_LOCATION_OMITIDO';
export const SET_LOCATION_PRINCIPAL= 'SET_LOCATION_PRINCIPAL';
export const CLEAR_ADDRESS= 'CLEAR_ADDRESS';


// Sucursal
export const GET_BRANCH_OFFICE_SUCCESS = 'GET_BRANCH_OFFICE_SUCCESS';
export const GET_BRANCH_OFFICE_FAIL = 'GET_BRANCH_OFFICE_FAIL';
export const SET_BRANCH_OFFICE_SUCCESS = 'SET_BRANCH_OFFICE_SUCCESS';
export const SET_DELIVERY_TYPE_SUCCESS = 'SET_DELIVERY_TYPE_SUCCESS';
export const GET_BRANCH_OFFICE_CERCA_SUCCESS = 'GET_BRANCH_OFFICE_CERCA_SUCCESS';
export const GET_CIUDADES_SUCCESS = 'GET_CIUDADES_SUCCESS';
export const GET_CIUDAD_FAIL = 'GET_CIUDAD_FAIL';
export const CLEAR_BRANCH_OFFICE = 'CLEAR_BRANCH_OFFICE';
export const SET_CIUDAD_SUCCESS = 'SET_CIUDAD_SUCCESS';
export const SET_CAMBIO_SUCURSAL = 'SET_CAMBIO_SUCURSAL';


// Home
export const GET_HOME_SUCCESS = 'GET_HOME_SUCCESS';
export const GET_HOME_FAIL = 'GET_HOME_FAIL';
export const SET_HOME_SUCCESS = 'SET_HOME_SUCCESS';
export const SET_ALL_PROD_CATEGORY = 'SET_ALL_PROD_CATEGORY';
export const GET_HOME_CLEAR_SUCCESS = 'GET_HOME_CLEAR_SUCCESS';

// Categoria
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FILTRO_SUCCESS = 'GET_CATEGORY_FILTRO_SUCCESS';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';

export const GET_SUBCATEGORY_SUCCESS = 'GET_SUBCATEGORY_SUCCESS';

// Banner
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAIL = 'GET_BANNER_FAIL';

//profile
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';



//pedido
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const GET_LIST_FINALIZADOS_SUCCESS = 'GET_LIST_FINALIZADOS_SUCCESS';
export const GET_LIST_PENDIENTES_SUCCESS = 'GET_LIST_PENDIENTES_SUCCESS';
export const GET_LIST_FAIL = 'GET_LIST_FAIL';
export const GET_PEDIDO_SUCCESS = 'GET_PEDIDO_SUCCESS';
export const GET_PEDIDO_FAIL = 'GET_PEDIDO_FAIL';
export const SET_LIST_PEDIDOS_FIRE_SUCCESS = 'SET_LIST_PEDIDOS_FIRE_SUCCESS';
export const GET_LIST_PEDIDOS_FIRE_SUCCESS = 'GET_LIST_PEDIDOS_FIRE_SUCCESS';
export const CLEAR_PEDIDO_SUCCESS = 'CLEAR_PEDIDO_SUCCESS';
export const CLEAR_PEDIDOS_SUCCESS = 'CLEAR_PEDIDOS_SUCCESS';

 





//lista de compras
export const GET_SHOPPING_LIST_SUCCESS = 'GET_SHOPPING_LIST_SUCCESS';
export const GET_DETALLE_SHOPPING_LIST_SUCCESS = 'GET_DETALLE_SHOPPING_LIST_SUCCESS';
export const GET_SHOPPING_LIST_FAIL = 'GET_SHOPPING_LIST_FAIL';
export const GET_DETALLE_SHOPPING_LIST_NULL = 'GET_DETALLE_SHOPPING_LIST_NULL';
export const GET_SHOPPING_LIST_SHARED_SUCCESS = 'GET_SHOPPING_LIST_SHARED_SUCCESS';
export const GET_SHOPPING_LIST_SHARED_FAIL = 'GET_SHOPPING_LIST_SHARED_FAIL';

export const INC_LIST_CART = 'INC_LIST_CART';
export const DEC_LIST_CART = 'DEC_LIST_CART';
export const REMOVE_LIST_CART = 'REMOVE_LIST_CART';
export const ACTUALIZAR_LIST_CART = 'ACTUALIZAR_LIST_CART';
export const UPDATE_DETALLE_SHOPPING_LIST_SUCCESS = 'UPDATE_DETALLE_SHOPPING_LIST_SUCCESS';
export const SET_ID_LIST_COMPRA = 'SET_ID_LIST_COMPRA';
export const CLEAR_CARRITO_LISTA_COMPRA='CLEAR_CARRITO_LISTA_COMPRA';
export const CHECKED_TO_CART_SHOPPING_LIST='CHECKED_TO_CART_SHOPPING_LIST';
export const INC_PESO_CART_SHOPPING_LIST='INC_PESO_CART_SHOPPING_LIST';
export const ACT_DATA_CART_SHOPPING='ACT_DATA_CART_SHOPPING';
export const CARRITO_LISTA_COMPRA_FAIL='CARRITO_LISTA_COMPRA_FAIL';
export const UPDATE_ITEM_CARRITO_lISTA_COMPRA='UPDATE_ITEM_CARRITO_lISTA_COMPRA';
export const ACT_PIEZA_CART_SHOPPING='ACT_PIEZA_CART_SHOPPING';
export const DELETE_PRODUCT_SHOPPING_LIST='DELETE_PRODUCT_SHOPPING_LIST';
export const DELETE_PEDIDO_CARRITO='DELETE_PEDIDO_CARRITO';



//lista de tajetas
export const GET_MYCARD_LIST_SUCCESS = 'GET_MYCARD_LIST_SUCCESS';
export const GET_DETALLE_MYCARD_LIST_SUCCESS = 'GET_DETALLE_MYCARD_LIST_SUCCESS';
export const GET_MYCARD_LIST_FAIL = 'GET_MYCARD_LIST_FAIL';
export const GET_DETALLE_MYCARD_LIST_NULL = 'GET_DETALLE_MYCARD_LIST_NULL';

//Notificacion
export const GET_NOTIFICACION_SUCCESS = 'GET_NOTIFICACION_SUCCESS';
export const GET_NOTIFICACION_FAIL = 'GET_NOTIFICACION_FAIL';

//carrito
export const GET_LIST_CARRITO_SUCCESS = 'GET_LIST_CARRITO_SUCCESS';
export const GET_LIST_CARRITO_FAIL = 'GET_LIST_CARRITO_FAIL';
export const DELETE_SUBLIST_CARRITO_SUCCESS = 'DELETE_SUBLIST_CARRITO_SUCCESS';
export const DELETE_SUBLIST_CARRITO_FAIL = 'DELETE_SUBLIST_CARRITO_FAIL';
export const GET_FECHAS_ENTREGA_SUCURSAL_SUCCESS = 'GET_FECHAS_ENTREGA_SUCURSAL_SUCCESS';
export const GET_FECHAS_ENTREGA_SUCURSAL_FAIL = 'GET_FECHAS_ENTREGA_SUCURSAL_FAIL';
export const GET_HORARIO_DISPONIBLE_SUCCESS = 'GET_HORARIO_DISPONIBLE_SUCCESS';
export const GET_HORARIO_DISPONIBLE_FAIL = 'GET_HORARIO_DISPONIBLE_FAIL';
export const UPDATE_LIST_CARRITO = 'UPDATE_LIST_CARRITO';
export const UPDATE_ITEM_CARRITO = 'UPDATE_ITEM_CARRITO';
export const ACT_DATA_CART = 'ACT_DATA_CART';
export const GET_CARRITO_SUCURSAL_SUCCESS = 'GET_CARRITO_SUCURSAL_SUCCESS';
export const DELETE_PRODUCTO_CARRITO_SUCCESS = 'DELETE_PRODUCTO_CARRITO_SUCCESS';
export const UPDATE_HORARIOS_SUCCESS = 'UPDATE_HORARIOS_SUCCESS';
export const UPDATE_HORARIOS_FAIL = 'UPDATE_HORARIOS_FAIL';
export const UPDATE_HORARIOS_CLEAR = 'UPDATE_HORARIOS_CLEAR';
export const SAVE_CONFIRMAR_CARRITO_SUCCESS = 'SAVE_CONFIRMAR_CARRITO_SUCCESS';
export const SAVE_CONFIRMAR_CARRITO_FAIL = 'SAVE_CONFIRMAR_CARRITO_FAIL';
export const SET_SUBPEDIDOS_SUCCESS = 'SET_SUBPEDIDOS_SUCCESS';
export const SET_CHANGE_FAVORITE_SUCCESS = 'SET_CHANGE_FAVORITE_SUCCESS';
export const SET_CHANGE_CARRITO_SUCCESS = 'SET_CHANGE_CARRITO_SUCCESS';
export const SET_UPDATE_CARRITO_SUCCESS = 'SET_UPDATE_CARRITO_SUCCESS';
export const CLEAR_CART = 'CLEAR_CART';
export const UPDATE_CARRITO_ANONIMO= 'UPDATE_CARRITO_ANONIMO';
export const UPDATE_CARRITO_ANONIMO_FAIL = 'UPDATE_CARRITO_ANONIMO_FAIL'
export const CLEAR_STATE_ANONIMO ='CLEAR_STATE_ANONIMO'
export const CLEAR_STATE_CONFIRMADO ='CLEAR_STATE_CONFIRMADO'
export const SET_CHANGE_DETALLE_CARRITO_SUCCESS ='SET_CHANGE_DETALLE_CARRITO_SUCCESS'



export const DELETE_ITEM_CARRITO_SUCCESS = 'DELETE_ITEM_CARRITO_SUCCESS';
export const DELETE_ITEM_CARRITO_FAIL = 'DELETE_ITEM_CARRITO_FAIL';
export const GET_SAVE_PEDIDO_FAIL = 'GET_SAVE_PEDIDO_FAIL';
export const GET_SAVE_PEDIDO_SUCCESS = 'GET_SAVE_PEDIDO_SUCCESS';


 
//lista de metodos pagos
export const GET_METODO_PAGO_SUCCESS = 'GET_METODO_PAGO_SUCCESS';
export const GET_METODO_PAGO_FAIL = 'GET_METODO_PAGO_FAIL';
export const SET_DATOS_PAGO = 'SET_DATOS_PAGO';
export const ADD_TO_CART = 'ADD_TO_CART';
export const INC_TO_CART = 'INC_TO_CART';
export const SET_PESO_TO_CART = 'SET_PESO_TO_CART';
export const DEC_TO_CART = 'DEC_TO_CART';
export const REMOVE_TO_CART = 'REMOVE_TO_CART';
export const REMOVE_TO_CART_SUCURSAL = 'REMOVE_TO_CART_SUCURSAL';
export const FIND_PRODUCTO_CART = 'FIND_PRODUCTO_CART';

//lista compra carrito
export const INC_TO_CART_SHOPPING_LIST = 'INC_TO_CART_SHOPPING_LIST';
export const DEC_TO_CART_SHOPPING_LIST='DEC_TO_CART_SHOPPING_LIST';
export const CLEAR_STATE_SHOPPING_LIST='CLEAR_STATE_SHOPPING_LIST';

//productos

export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export const GET_PRODUCT_ITEM_SUCCESS = 'GET_PRODUCT_ITEM_SUCCESS';
export const GET_PRODUCT_ITEM_FAIL = 'GET_PRODUCT_ITEM_FAIL';

export const GET_PRODUCT_SECCION_SUCCESS = 'GET_PRODUCT_SECCION_SUCCESS';
export const CLEAR_DATA='CLEAR_DATA';
export const CLEAR_ITEM_PRODUCTO='CLEAR_ITEM_PRODUCTO';
export const SET_SEARCH_SUCCESS='SET_SEARCH_SUCCESS';
export const CLEAR_SEARCH_SUCCESS='CLEAR_SEARCH_SUCCESS';

//loading
export const SET_LOADING = 'SET_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';

//tarjeta
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
export const POST_CARD_SUCCESS     = 'POST_CARD_SUCCESS';
export const GET_CARD_FAIL    = 'GET_CARD_FAIL';
export const POST_CARD_FAIL        = 'POST_CARD_FAIL';

//peticion
export const ENABLE_PETITION = 'ENABLE_PETITION';
export const DISABLE_PETITION     = 'DISABLE_PETITION';


//Global
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAIL = 'DELETE_ITEM_FAIL';


//url navegacion

export const GET_URL_NAVIGATION= 'GET_URL_NAVIGATION';
export const POST_URL_NAVIGATION='POST_URL_NAVIGATION';
export const UPDATE_URL_NAVIGATION ='UPDATE_URL_NAVIGATION';
