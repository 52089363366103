import React, {useEffect,useRef } from 'react'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import {get_categorias} from "../../redux/actions/home";
import Subcategoria from "./Subcategoria";
import 'swiper/css';
import 'swiper/css/navigation';
import "../../assets/styles/globalColorPrincipal.css";

import {useNavigate} from "react-router-dom";
import {
    faAngleLeft, faAngleRight,
    faAnglesRight,
    faArrowLeft,
    faBell,
    faLeftLong,
    faLeftRight
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Categoria({get_categorias, categorias, sucursal}) {
    const swiperRef = useRef();

    useEffect(() => {
        if (categorias.length === 0) {
            get_categorias(sucursal.IdSucursal, sucursal.IdMarket);
        } else if (categorias.length > 0) {
            let item = categorias[0];
            if (item.IdSucursal !== sucursal.IdSucursal) {
                get_categorias(sucursal.IdSucursal, sucursal.IdMarket);
            }
        }
    }, [sucursal]);

    const navigate = useNavigate();
    const breakpoints = {
        300: {
            slidesPerView: 2,
            spaceBetween: 1,
        },
        400: {
            slidesPerView: 2,
            spaceBetween: 1,
        },

        640: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 10,
        },

        992: {
            slidesPerView: 4,
            spaceBetween: 10,
        },
        1200: {
            slidesPerView: 12,
            spaceBetween: 10,
        },

        1400: {
            slidesPerView: 12,
            spaceBetween: 30,
        },

    };
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    return (
        <>
            <div className="relative " style={{overflowX: 'clip'}}>
                <div className="seccion_categoria z-100">

                    <Swiper
                        breakpoints={breakpoints}
                        slidesPerView={1}
                        spaceBetween={2}
                        loop={false}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        modules={[Navigation]}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                            swiper.params.navigation.nextEl = navigationNextRef.current;
                        }}
                        className="mySwiper bg-hiperorange text-xs text-center font-light overflow-visible"
                        style={{
                            "--swiper-navigation-color": "#FFFFFF",
                            "--swiper-navigation-size": "20px",
                            "--swiper-navigation-weight": "900",
                            paddingLeft: '2rem',
                            paddingRight: '2rem'
                        }}
                    >
                        {
                            categorias &&
                            categorias !== null &&
                            categorias !== undefined &&
                            categorias.map((categoria, index) => (
                                <SwiperSlide key={index} className={"alineado_categoria"}
                                             style={{alignSelf: 'center', display: 'flex', justifyContent: 'center'}}

                                >
                                    <Subcategoria ps_categoria={categoria}></Subcategoria>
                                </SwiperSlide>

                            ))
                        }

                        <div ref={navigationPrevRef} className="absolute left-0 top-0 z-1">
                            <div className="h-[35px] w-8 bg-hiperorange text-white flex justify-center items-center">
                                <FontAwesomeIcon icon={faAngleLeft} className=" px-2 text-xl"/>
                            </div>
                        </div>
                        <div ref={navigationNextRef} className="absolute right-0 top-0 z-1">
                            <div className="h-[35px] w-8 bg-hiperorange text-white flex justify-center items-center">
                                <FontAwesomeIcon icon={faAngleRight} className=" px-2 text-xl"/>
                            </div>
                        </div>
                    </Swiper>


                </div>
            </div>

        </>
    );
}

const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    categorias: state.Home.categorias,
})

export default connect(mapStateToProps, {
    get_categorias,
})(Categoria)