import {
    GET_LIST_CARRITO_SUCCESS,
    GET_LIST_CARRITO_FAIL,
    DELETE_SUBLIST_CARRITO_SUCCESS,
    DELETE_SUBLIST_CARRITO_FAIL,
    GET_FECHAS_ENTREGA_SUCURSAL_FAIL,
    GET_FECHAS_ENTREGA_SUCURSAL_SUCCESS,
    GET_HORARIO_DISPONIBLE_SUCCESS,
    GET_HORARIO_DISPONIBLE_FAIL,
    SET_PESO_TO_CART,
    ADD_TO_CART,
    DEC_TO_CART,
    INC_TO_CART,
    REMOVE_TO_CART,
    ACT_DATA_CART,
    GET_CARRITO_SUCURSAL_SUCCESS, DELETE_PRODUCTO_CARRITO_SUCCESS,
    UPDATE_LIST_CARRITO, UPDATE_ITEM_CARRITO,
    DELETE_ITEM_CARRITO_SUCCESS, DELETE_ITEM_CARRITO_FAIL,
    GET_SAVE_PEDIDO_SUCCESS,
    SET_CHANGE_CARRITO_SUCCESS,
    SET_UPDATE_CARRITO_SUCCESS,
    UPDATE_HORARIOS_CLEAR,
    GET_SAVE_PEDIDO_FAIL, UPDATE_HORARIOS_SUCCESS, UPDATE_HORARIOS_FAIL,
    SAVE_CONFIRMAR_CARRITO_FAIL, SET_SUBPEDIDOS_SUCCESS, SET_CHANGE_FAVORITE_SUCCESS, SAVE_CONFIRMAR_CARRITO_SUCCESS,
    CLEAR_CART, REMOVE_TO_CART_SUCURSAL,
    UPDATE_CARRITO_ANONIMO,
    UPDATE_CARRITO_ANONIMO_FAIL,
    CLEAR_STATE_ANONIMO, CLEAR_STATE_CONFIRMADO, DELETE_PEDIDO_CARRITO,
    SET_CHANGE_DETALLE_CARRITO_SUCCESS

} from '../actions/types';

const initialState = {
    list_carrito: null,
    fechas_disponibles_entrega: null,
    horarios_disponibles: null,
    carrito_sucursal_db: null,
    SubPedidos: null,
    carrito: JSON.parse(localStorage.getItem('carrito')) ?? [],
    carrito_lista_compra: JSON.parse(localStorage.getItem('carrito_lista_compra')) ?? [],
    producto_delete: JSON.parse(localStorage.getItem('producto_delete')) ?? [],
    totalPrice: 0,
    totalQuantities: 0,
    carrito_favorito: false,
    cambio_carrito: false,
    error_save_carrito: false,
    tipoEntrega: 1, // 1: entrega en sala ; 2: delivery
    respuesta_update_horario: null,
    respuesta_save_pedido: null,
    bool_delete_pedido: false,
    respuesta_confirmar_carrito: null,
    respuesta_carrito_anonimo: false,
    respuesta_carrito_user: false,
    cambio_detalle_carrito: false,
};

export default function Carrito(state = initialState, action) {
    let findPro;
    let index;
    const {type, payload} = action;

    switch (type) {
        case CLEAR_CART:
            clear_carrito_finalizado();
            console.log("11111 CLEAR_CART")
            return {
                ...state,
                list_carrito: null,
                fechas_disponibles_entrega: null,
                horarios_disponibles: null,
                carrito_sucursal_db: null,
                SubPedidos: null,
                carrito: [],
                carrito_lista_compra: [],
                producto_delete: [],
                totalPrice: 0,
                totalQuantities: 0,
                carrito_favorito: false,
                cambio_carrito: false,
                respuesta_update_horario: null,
                respuesta_save_pedido: null,
                bool_delete_pedido: false,
                respuesta_confirmar_carrito: null,
                respuesta_carrito_anonimo: false,
                respuesta_carrito_user: false,
                error_save_carrito: false,
                cambio_detalle_carrito: false,
            }
        case CLEAR_STATE_ANONIMO:
            return {
                ...state,
                respuesta_carrito_anonimo: false
            }
        case GET_LIST_CARRITO_SUCCESS:
            return {
                ...state,
                list_carrito: payload.Dato,
                carrito_favorito: false,
                cambio_detalle_carrito: false
            }

        case GET_CARRITO_SUCURSAL_SUCCESS:
            updateStorage(payload.cart.Productos);
            updateStorageDeleteProduct([]);
            return {
                ...state,
                carrito_sucursal_db: payload.cart,
                carrito: payload.cart.Productos,
                cambio_carrito: false,
                producto_delete: []
            }
        case DELETE_PRODUCTO_CARRITO_SUCCESS:
            updateStorageDeleteProduct([]);
            return {
                ...state,
                producto_delete: []
            }
        case GET_LIST_CARRITO_FAIL:
            return {
                ...state,
                error_save_carrito: true
            }
        case GET_FECHAS_ENTREGA_SUCURSAL_SUCCESS:
            return {
                ...state,
                fechas_disponibles_entrega: payload.Dato
            }
        case GET_FECHAS_ENTREGA_SUCURSAL_FAIL:
            return {
                ...state
            }
        case GET_HORARIO_DISPONIBLE_SUCCESS:
            return {
                ...state,
                horarios_disponibles: payload.Dato
            }
        case GET_HORARIO_DISPONIBLE_FAIL:
            return {
                ...state
            }

        case DELETE_SUBLIST_CARRITO_SUCCESS:
            console.log("11111 DELETE_SUBLIST_CARRITO_SUCCESS")
            return {
                ...state,
                list_carrito: null
            }
        case DELETE_ITEM_CARRITO_SUCCESS:
            return {
                ...state,
            }
        case SET_CHANGE_FAVORITE_SUCCESS:
            return {
                ...state,
                carrito_favorito: payload
            }
        case SET_CHANGE_CARRITO_SUCCESS:
            return {
                ...state,
                cambio_carrito: payload
            }
        case SET_CHANGE_DETALLE_CARRITO_SUCCESS:
            return {
                ...state,
                cambio_detalle_carrito: payload
            }



        case DELETE_ITEM_CARRITO_FAIL:
            return {
                ...state,
            }
        case UPDATE_HORARIOS_SUCCESS:
            return {
                ...state,
                respuesta_update_horario: payload,
            }
        case UPDATE_HORARIOS_FAIL:
            return {
                ...state,
                respuesta_update_horario: payload.Errores,
            }
        case UPDATE_HORARIOS_CLEAR:
            return {
                ...state,
                respuesta_update_horario: null,
            }

        case GET_SAVE_PEDIDO_SUCCESS:
            return {
                ...state,
                respuesta_save_pedido: payload
            }
        case GET_SAVE_PEDIDO_FAIL:
            return {
                ...state,
                respuesta_save_pedido: payload
            }
        case SAVE_CONFIRMAR_CARRITO_SUCCESS:
            return {
                ...state,
                respuesta_confirmar_carrito: payload,
                respuesta_carrito_user: true
            }
        case SAVE_CONFIRMAR_CARRITO_FAIL:
            return {
                ...state,
                respuesta_confirmar_carrito: null,
                respuesta_carrito_user: false
            }
        case DELETE_SUBLIST_CARRITO_FAIL:
            return {
                ...state,
            }
        case UPDATE_CARRITO_ANONIMO:
            return {
                ...state,
                respuesta_carrito_anonimo: true
            }
        case UPDATE_CARRITO_ANONIMO_FAIL:
            return {
                ...state,
                respuesta_carrito_anonimo: false
            }

        case SET_UPDATE_CARRITO_SUCCESS:
            updateStorage(payload.carrito);
            return {
                ...state,
                carrito: payload.carrito
            }

        case UPDATE_ITEM_CARRITO:
            updateStorage([]);
            return {
                ...state,
                carrito: []
            }
        case DELETE_PEDIDO_CARRITO:
            return {
                ...state,
                bool_delete_pedido: payload
            }

        case SET_SUBPEDIDOS_SUCCESS:
            return {
                ...state,
                SubPedidos: payload.SubPedidos
            }

        case SET_PESO_TO_CART:
            const {producto_peso, cant, pesos} = payload;
            const producto_find = find_producto(producto_peso);
            if (producto_find) {
                index = index_producto(producto_peso);
                producto_find.Cantidad = cant;
                producto_find.Pesos = pesos;
                state.carrito[index] = producto_find;
                updateStorage(state.carrito);
                return {
                    ...state,
                    cambio_carrito: true
                }
            } else {
                let array_producto_delete = delete_producto(producto_peso);
                producto_peso.Pesos = pesos;
                producto_peso.Cantidad = cant;
                updateStorage([...state.carrito, producto_peso]);
                return {
                    ...state,
                    carrito: [...state.carrito, producto_peso],
                    cambio_carrito: true
                }
            }
        case ADD_TO_CART:
            const {producto, cantidad} = payload;
            const check = state.carrito.find(prd => prd.IdProducto === producto.IdProducto);
            const check_delete = state.producto_delete.find(prd => prd.IdProducto === producto.IdProducto);
            let product_price_a = producto.ConOferta ? producto.PrecioOferta : producto.PrecioVenta;
            if (check) {
                index = state.carrito.findIndex((pd) => pd.IdProducto === producto.IdProducto);
                check.Cantidad = cantidad;
                state.carrito[index] = check;
                updateStorage(state.carrito)
                return {
                    ...state,
                    totalPrice: state.totalPrice + product_price_a,
                    totalQuantities: state.totalQuantities + cantidad,
                    cambio_carrito: true
                }
            } else {
                let array_aux = state.producto_delete;
                if (check_delete) {
                    array_aux = state.producto_delete.filter((pd) => pd.IdProducto !== producto.IdProducto);
                    deleteStoragePorduct();
                    updateStorageDeleteProduct(array_aux);
                }

                const tPrice = state.totalPrice + product_price_a * cantidad;
                const tQuantities = state.totalQuantities + cantidad;
                producto.Cantidad = cantidad;
                updateStorage([...state.carrito, producto]);
                return {
                    ...state,
                    carrito: [...state.carrito, producto],
                    producto_delete: array_aux,
                    totalPrice: tPrice,
                    totalQuantities: tQuantities,
                    cambio_carrito: true
                }

            }
        case UPDATE_LIST_CARRITO:
            console.log("11111 UPDATE_LIST_CARRITO")
            return {
                ...state,
                list_carrito: payload
            }
        case ACT_DATA_CART:
            const {producto: prod, valor} = payload;

            findPro = find_producto(prod);
            if (findPro) {
                index = index_producto(prod);
                findPro.Cantidad = valor;
                state.carrito[index] = findPro;
                updateStorage(state.carrito)
                return {
                    ...state,
                    carrito: state.carrito,
                    cambio_carrito: true
                }
            } else {
                prod.Cantidad = valor;
                updateStorage([...state.carrito, prod]);
                return {
                    ...state,
                    carrito: [...state.carrito, prod],
                    cambio_carrito: true
                }
            }


        case INC_TO_CART:
            findPro = state.carrito.find((pd) => pd.IdProducto === payload.IdProducto);
            index = state.carrito.findIndex((pd) => pd.IdProducto === payload.IdProducto);
            findPro.Cantidad += 1;
            state.carrito[index] = findPro;
            let product_price = findPro.ConOferta ? findPro.PrecioOferta : findPro.PrecioVenta;
            updateStorage(state.carrito)
            return {
                ...state,
                totalPrice: state.totalPrice + product_price,
                totalQuantities: state.totalQuantities + 1,
                cambio_carrito: true
            }
        case DEC_TO_CART:
            findPro = state.carrito.find((pd) => pd.IdProducto === payload.IdProducto)
            index = state.carrito.findIndex((pd) => pd.IdProducto === payload.IdProducto);
            if (findPro.Cantidad > 1) {
                findPro.Cantidad -= 1;
                state.carrito[index] = findPro;
                let product_price = findPro.ConOferta ? findPro.PrecioOferta : findPro.PrecioVenta;
                updateStorage(state.carrito)
                return {
                    ...state,
                    totalPrice: state.totalPrice - product_price,
                    totalQuantities: state.totalQuantities - 1,
                    cambio_carrito: true
                }
            } else {
                return state
            }

        case REMOVE_TO_CART:
            findPro = state.carrito.find((pd) => pd.IdProducto === payload.IdProducto)

            const filtered = state.carrito.filter((pd) => pd.IdProducto !== payload.IdProducto);
            let product_pricea = findPro.ConOferta ? findPro.PrecioOferta : findPro.PrecioVenta;
            updateStorage(filtered)
            const check_item = state.producto_delete.find((pd) => pd.IdProducto === payload.IdProducto)
            if (!check_item) {
                state.producto_delete = [...state.producto_delete, {IdProducto: payload.IdProducto}];
                updateStorageDeleteProduct(state.producto_delete);
            }

            return {
                ...state,
                carrito: filtered,
                totalPrice: state.totalPrice - product_pricea * findPro.Cantidad,
                totalQuantities: state.totalQuantities - findPro.Cantidad,
                cambio_carrito: true
            };
        case REMOVE_TO_CART_SUCURSAL:
            findPro = state.carrito.find((pd) => pd.IdProducto === payload.IdProducto)

            const filtered_suc = state.carrito.filter((pd) => pd.IdProducto !== payload.IdProducto);
            let product_priceasu = findPro.ConOferta ? findPro.PrecioOferta : findPro.PrecioVenta;
            updateStorage(filtered_suc)
            return {
                ...state,
                carrito: filtered_suc,
                totalPrice: state.totalPrice - product_priceasu * findPro.Cantidad,
                totalQuantities: state.totalQuantities - findPro.Cantidad,
                cambio_carrito: true
            };
        case CLEAR_STATE_CONFIRMADO:
            return {
                ...state,
                respuesta_confirmar_carrito: false,
                respuesta_carrito_user: false,
                error_save_carrito: false
            };


        // case CLEAR_DATA_CARRITO:
        //     clear_carrito_finalizado();
        //     return {
        //         ...state,
        //         list_carrito: null,
        //         carrito:[],
        //         carrito_sucursal_db:null,
        //         horarios_disponibles:null,
        //         fechas_disponibles_entrega:null
        //     }

        default:
            return state;
    }

    function find_producto(producto) {
        return state.carrito.find((pd) => pd.IdProducto === producto.IdProducto);
    }

    function index_producto(producto) {
        return state.carrito.findIndex((pd) => pd.IdProducto === producto.IdProducto);
    }

    function delete_producto(producto) {
        let check_delete = state.producto_delete.find(prd => prd.IdProducto === producto.IdProducto);
        let array_producto_delete = state.producto_delete;
        if (check_delete) {
            array_producto_delete = state.producto_delete.filter((pd) => pd.IdProducto !== producto.IdProducto);
            deleteStoragePorduct();
            updateStorageDeleteProduct(array_producto_delete);
        }
        return array_producto_delete;
    }


}

function updateStorage(carrito) {
    localStorage.setItem("carrito", JSON.stringify(carrito));
}

function deleteStoragePorduct() {
    localStorage.removeItem("producto_delete");
}

function updateStorageDeleteProduct(producto_delete) {
    localStorage.setItem("producto_delete", JSON.stringify(producto_delete));
}

function clear_carrito_finalizado() {
    localStorage.removeItem("carrito");
    localStorage.removeItem("mycart");
    localStorage.removeItem("monto_mycart");
}



