
// import React ,{useState,useEffect} from "react";
import React,{Fragment,useEffect,useState} from "react";
// import { render } from "react-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from 'axios';

const style = {
  height: 200,
  width:'10rem',
  border: "1px solid green",
  margin: 6,
  padding: 8,
  display:'flex',
  overflow:'auto'
};


// const Productoscroll = () => {
export default function Productoscroll() {
    // const [state, setState] = useState([]);
    // const [state,setState] = useState([

    //      1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20
    // ]);
    const [state,setState] = useState({
      // loading:false,
      // items:[ 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
      items:[]
    });

    const [page,setPage]=useState(1);

    useEffect(()=>{
      console.log("carga",state);
      getPhotos();
    },[]);
   


    // state = {
    //     items: Array.from([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20])
    // };

    //   const [todos, setTodos] = useState([
    //     { idProducto: 1, Cantidad:2, descripcion: "Selectus aut autem", PrecioVenta: 40 }
    //   ]);
    const  getPhotos= ()=> {
      console.log("realiza peticion");

      // setState({ loading: true });
      setPage(page+1);
      setTimeout(() => {
        

        console.log(page);

      axios.get(
          `https://jsonplaceholder.typicode.com/photos?_page=${page}&_limit=20`
        ).then(res => {
          console.log("resp",res);
          // setState({ items: res.data });
          setState({ items: [...state.items, ...res.data] });
          // setState({ loading: false });
        });

      }, 1500);

      console.log(state);

    }

    // const fetchMoreData = () => {
    //     setTimeout(() => {
    //     setState({
    //         items: state.items.concat(Array.from({ length: 20 }))
    //     });
    //     }, 1500);
    // };


    return (
        <>
        <div>
            <h1>demo: react-infinite-scroll-component</h1>
            <hr />
            <div className="flex flex-wrap">
              {state.items.length >0 &&
                <InfiniteScroll
                dataLength={state.items.length}
                next={getPhotos}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                style={{display:'flex', justifyContent:'center',flexWrap:'wrap'}}
                >
                {state.items.map((i, index) => (
                    <div style={style} key={index}>
                        div - #{i.id}
                      <img src={i.url} height={10} />
                    </div>
                ))}
                </InfiniteScroll>
              }
            </div>

           
        </div>
      </>
    );
  
}
// export default Productoscroll;

