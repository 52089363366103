import React, {useEffect} from "react";
import {connect} from "react-redux";
import {cn} from "../libs/utils";
import {Circles} from "react-loader-spinner";

const ButtonText = ({type = 'button', label, className, onClick, peticion, peticion_label, principal = false}) => {
    useEffect(() => {
    }, []);

    return (
        <button
            type={type}
            onClick={onClick}
            disabled={peticion}
            style={peticion ? {"cursor": "not-allowed"} : {}}
            className={cn('flex justify-center items-center py-2 px-4 w-full rounded-lg hover:text-white hover:bg-[#4645e6]  transition-colors duration-300 font-medium', className)}>
            {
                principal && peticion ?
                    <>
                        <span className="mr-2">
                        <Circles
                            color={'#FFFFFF'}
                            width={20}
                            height={20}
                            ariaLabel="circles-loading"
                        />
                        </span>
                        <span>
                             {peticion_label !== '' ? peticion_label : label }
                        </span>
                    </>

                    :
                    <span>{label}</span>
            }

        </button>
    );
};

const mapStateToProps = state => ({
    peticion: state.Peticion.peticion,
    peticion_label: state.Peticion.peticion_label,
})

export default connect(mapStateToProps, {})(ButtonText)

