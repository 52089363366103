import React,{useState}   from 'react';
import '../assets/styles/hiper.scss'
import logo from '../assets/images/login/logo.png';
import background from '../assets/images/login/Background.jpg';
import texture from '../assets/images/login/texture_complete.jpg';


import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage, faMobileButton} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const containerStyle = {
    backgroundImage:
        `url(${background})`,
    width: "100%",
    height: "92.5vh",
};
const containerStyle2 = {
    backgroundImage:
        `url(${texture})`,
    width: "100%",

};
 
  
const LoginPhone = () => {
    const [value, setValue] = useState();
    
    return (
        <div id="wrapper relative" style={containerStyle}>

            <div className='shadow-md w-full static top-0 left-0'>
                <div className='md:flex items-center justify-center bg-white py-4 md:px-10 px-7'>
                    <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins]
      text-gray-800'>
                        <img src={logo} width="70"
                             alt="logo"/>
                    </div>
                </div>
            </div>

            <div className="main">
                <div className="hiper-color absolute bottom-0 w-full" style={containerStyle2}>
                    <div className="h-96 max-h-full">
                        <h1 className="text-4xl font-inter font-normal text-center text-white my-5">Ingresar</h1>
                        <form action="src/components/LoginPhone" className="flex justify-center m-0">
                            <div className="box-input">
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-white">
                                        <FontAwesomeIcon icon={faMobileButton} style={{height: "20px"}}/> Con tu numero
                                        de celular 
                                    </label>
                                    <div className="mt-1">
                                        <div className='row'>
                                        <div className='col-md-6 pr-0'>
                                                    <PhoneInput
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="BO"
                                                  
                                                    value={value}
                                                    onChange={setValue}/>
                                            </div>

                                            <div className='col-md-6'>
                                                    <input
                                                    id="phone"
                                                    name="phone"
                                                    type="number"
                                                    autoComplete="phone"
                                                    required
                                                    className="appearance-none block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                   />
                                            </div>
                                        </div>
                                  
                                  

                                      
                                    </div>
                                </div>
                                <div className="d-grid gap-2 mt-2">

                                    <Link to="/verificar">
                                        <button
                                            className="bg-transparent hover:bg-hiperblue text-white font-inter font-light hover:text-white py-1 px-4 border border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between"
                                            type="button">
                                            <FontAwesomeIcon icon={faWhatsapp} style={{height: "28px"}}/> <span> Recibir código por Whatsapp</span>
                                        </button>
                                    </Link>
                                    <Link to="/verificar">
                                        <button
                                            className="bg-transparent hover:bg-hiperblue text-white font-inter font-light hover:text-white py-1 px-4 border border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between"
                                            type="button">
                                            <FontAwesomeIcon icon={faMessage} style={{height: "28px"}}/> <span
                                            className="pr-3.5">Recibir código por SMS</span></button>
                                    </Link>

                                    <hr className="mt-2"/>
                                    <Link to="/ubicacion">
                                        <button
                                            className="py-2 px-4 bg-hiperblue text-white font-inter font-light rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 w-full mt-2"
                                            type="button">Seguir comprando
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default LoginPhone;

