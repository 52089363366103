import React, {useState,useEffect} from 'react';
import background_white from "../../assets/images/home/fondowhite.jpg";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const BackToPage = ({ps_url_page,ps_contenerdor, ps_verifyCambio, ps_functionExecute}) => {

    const [urlPage, setUrlPage] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        if(ps_url_page!==null){
            setUrlPage(ps_url_page)
        }

    }, []);
    
    async function volverAtras(){
        if(urlPage==='/'){
            ir_inicio();
        }
        else if(urlPage!==null && urlPage!==''){
            navigate(urlPage);
        }else{
            if(precesarFuncion()){
                await ps_functionExecute();
                window.setTimeout(()=>{
                    window.history.back();
                },2000);
            }else{
                window.history.back();
            }
            
        }
    }

    function precesarFuncion(){
        if(typeof(ps_verifyCambio)!==undefined &&  ps_verifyCambio){
            return true;
        }
        return false;
    }
    const ir_inicio = () => {
        let url_nav =  JSON.parse(localStorage.getItem("url_navigation"));
        if(url_nav){
            navigate("/"+url_nav.url_parameters);
        }
     
    }



    return (
        <>
            {ps_contenerdor!=undefined && ps_contenerdor=='sinContenedor' ?(
                <div className='flex mx-4 justify-start p-2 '>
                    <button  onClick={()=>volverAtras()} className='cursor-pointer' >                                            
                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:'1.5rem'}} />    
                    </button> 
                </div>
            ):(
                <div className='container flex justify-start  p-2 '>
                    <button  onClick={()=>volverAtras()} className='cursor-pointer'>                                            
                        <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:'1.5rem'}} />    
                    </button> 
                </div>
            )
            }

        </>                            
    );
};


export default BackToPage;