import React, {useEffect, useState} from "react";
import {faTimesCircle, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ItemOpcionPago from "../../widgets/item_opcion_pago";
import {connect} from "react-redux";
import {eliminar_cliente_nit, get_list_metodos_pagos} from "../../redux/actions/metodoPagos";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {compra_rapida} from "../../../src/redux/actions/shoppingList";
import Tarjeta from "./../../components/modal/carrito/tarjeta";
import Page_message from "./../../components/Page_message";
import Swal from 'sweetalert2';
import {setSwAlert} from "../../redux/actions/swalert";
import ButtonText from "../../widgets/button_text";
import '../../assets/styles/seccionResponsive.scss'
import "../../assets/styles/responsive.scss"
import {huellaDigital} from './../../huellaDigital';
import ButtonClose from "../../widgets/button_close";
import classNames from "classnames";

const FormaPago = ({

                       profile,
                       data,
                       finalizado,
                       mt_indice,
                       get_list_metodos_pagos,
                       metodopagos,
                       sucursal,
                       compra_rapida,
                       eliminar_cliente_nit,
                       importeTotal,
                       importeAhorrodo,
                       width,
                       height,
                       exit

                   }) => {
    const [tipoDocumento, setTipoDocumento] = useState(5);
    const [pago_selec, setPagoSelec] = useState(1);
    const [facturaciones, setFacturacion] = useState([]);
    const [comentario, setComentario] = useState('');
    const [nit, setNit] = useState('');
    const [razonsocial, setRazonsocial] = useState('');
    const [complemento, setComplemento] = useState('');
    const [tarjeta_online, setTarjetaOnline] = useState(false);
    const [tarjeta_selec, setTarjetaSelec] = useState();

    const [vale, setVale] = useState('');
    const [listaVales, setListaVales] = useState([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        pago_id: '',
        nit: '',
        razonsocial: '',

    });
    useEffect(() => {
        if (metodopagos) {
            setFacturacion(metodopagos.Facturaciones);
        }
    }, [metodopagos]);
    useEffect(() => {
        if (facturaciones.length > 0) {
            let data = facturaciones[facturaciones.length - 1];
            setNit(data.Nit);
            setRazonsocial(data.RazonSocial);
        }
    }, [facturaciones]);
    useEffect(() => {
        get_list_metodos_pagos();
    }, []);


    function existe_metodos_pagos() {
        return metodopagos != null;
    }


    const seleccionar_opcion = (facturacion, e) => {
        console.log("facturacion ", facturacion);

        setNit(facturacion.Nit);
        setRazonsocial(facturacion.RazonSocial);
        showModal_facturaciones();
    };
    const showModal_facturaciones = (e) => {
        document.getElementById('modalFacturacion').classList.toggle('hidden');
    };
    const showModal = (e) => {
        document.getElementById('modal').classList.toggle('hidden');
    };

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const {register, formState: {errors}, handleSubmit} = useForm();

    function cambiar_seleccion(id) {
        setPagoSelec(id);
        id == 12 ? setTarjetaOnline(true) : setTarjetaOnline(false);
    }

    const agregar_vale = (e) => {
        if (vale !== '') {
            console.log(" este es el vale ", vale);
            const aux = listaVales.slice();
            aux.push(vale);
            setListaVales(aux);
            console.log("lista vales ", aux);
        }
        setVale('');

        showModal();
    };

    function get_formato_vales(lista_vales) {
        var aux = [];
        for (var item of lista_vales) {
            aux.push({Codigo: "", Monto: item});
        }
        return aux;
    }

    const onSubmit = async () => {

        var metodo_pago = {
            IdMetodoPago: pago_selec,
            Nit: nit,
            RazonSocial: razonsocial,
            tipoDocumento: tipoDocumento,
            complemento: complemento,
            IdTarjeta: tarjeta_selec
        };

        if (pago_selec === 12) {
            if (!tarjeta_selec) {
                mensaje_alert("Debe Seleccionar un tarjeta");
                return;
            }
        }
        var res = await huellaDigital(profile.IdUsuario);

        console.log("resultado de huella", res)

        var aux_vales = get_formato_vales(listaVales);
        const datos = {
            IdSucursal: sucursal.IdSucursal,
            IdTipoEntrega: data.IdTipoEntrega,
            IdUbicacion: data.IdUbicacion,
            Horario: data.Horario,
            NotaPedido: comentario,
            DatosPago: metodo_pago,
            Productos: data.productos,
            ListaVale: aux_vales,
            IdSession: res

        };
        console.log(datos);


        const res_compra_rap = await compra_rapida(datos);
        if (res_compra_rap.data.ConError) {
            mensaje_alert(res_compra_rap.data.Mensaje);
            return;
        }
        finalizado();
    }

    function volverAtras() {
        mt_indice(1)
    }

    function cambiar_seleccion_tarjeta(id) {
        setTarjetaSelec(id);
    }

    function existe_perfil() {
        return profile != null;
    }

    function inputNit(e) {
        const esValido = e.target.validity.valid;
        if (esValido) {
            let aux_nit = e.target.value;
            if (aux_nit) {
                setNit(aux_nit)
            }
        }
    }

    function mensaje_alert(text) {
        Swal.fire({
            position: 'top',
            icon: 'error',
            title: text,
            showConfirmButton: false,
            timer: 1500
        })
    }

    const eliminarFactura = (facturacion) => {
        console.log(facturacion)
        let title = '¿Estás seguro que quiere eliminar el nit seleccionado?';
        let txt = `Nit:  ${facturacion.Nit}`;
        // preguntar(`¿Estás seguro que quiere eliminar el nit seleccionado? \n Nit:  ${facturacion.Nit}`  , delete_item_factura,facturacion);
        delete_registro(facturacion, title, txt);
    };

    function preguntar(titulo, operacion, facturacion) {
        Swal.fire({
            title: titulo,
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
        }).then((result) => {
            if (result.isConfirmed) {
                operacion(facturacion);
            } else if (result.isDenied) {

            }
        })
    }

    function delete_item_factura(factura) {
        let dato = {
            IdCliente: 0,
            Nit: factura.Nit
        };
        eliminar_cliente_nit(dato);
    }

    async function delete_registro(factura, title, txt) {
        let resp = await Page_message.delete_register_confirmation(title, txt);
        if (resp) {
            let dato = {
                IdCliente: 0,
                Nit: factura.Nit
            };
            eliminar_cliente_nit(dato);
        }


    }

    return (


        <>
            <div className='contenedor_mobile md:w-[82%]'>
                <form className="contenedor_flex compra_rapida_form_pago1 gap-2" onSubmit={handleSubmit(onSubmit)}>
                    <div className="contenedor_mobile md:w-[70%]">

                        <div className="bg-white overflow-hidden rounded-hiper_g ">
                            <div
                                className="bg-hiperorange relative w-100 text-white flex items-center justify-center h-[52px]  content_title">
                                <h2 className='p-0 m-0 title_carrito_responsivo '>Formas de pago</h2>
                                <div className="absolute right-2 ">
                                    <ButtonClose className="text-white bg-transparent" onClick={() => exit()}/>
                                </div>
                            </div>

                            <div className={classNames("overflow-y-auto", "container_scroll",
                                     {"h-[62vh]": height <= 667},
                                     {"h-[66vh]": height > 667 && height <= 843},
                                     {"h-[72vh]": height > 843},
                                 )}>
                                <div className="m-auto max-w-md pt-4 px-3">
                                    <p className="text-black fontzize_text_carrito mb-4 font-bold">Seleccionar el método de
                                        pago</p>
                                    <div>
                                        {existe_metodos_pagos() &&
                                            metodopagos.Metodos.map((m) =>
                                                <ItemOpcionPago
                                                    key={m.Id}
                                                    id={m.Id}
                                                    Descripcion={m.Descripcion}
                                                    change_selec={cambiar_seleccion}
                                                    valor={pago_selec}
                                                ></ItemOpcionPago>
                                            )
                                        }
                                    </div>
                                    {
                                        tarjeta_online &&
                                        <Tarjeta change_selec={cambiar_seleccion_tarjeta}
                                                 valor={tarjeta_selec}
                                        ></Tarjeta>
                                    }

                                </div>
                                <div className="m-2 p-2 rounded-hiper_g bg-hiperplomo_claro mt-3">
                                    <div className="flex space">
                                        <p className="text-black fontzize_text_carrito mb-4 font-bold">Datos de
                                            facturación:</p>
                                        <label htmlFor=""
                                               className="text-hiperorange ml-auto underline font-bold cursor-pointer"
                                               onClick={(e) => showModal_facturaciones()}>Cambiar</label>
                                    </div>

                                    <div className="mb-3">
                                        <label className="block fontzize_label text-black mb-2"
                                               htmlFor="documento">
                                            Tipo de Documento:
                                        </label>
                                        <select id="documento" name="documento"
                                                defaultValue={tipoDocumento}
                                                onChange={e => setTipoDocumento(e.target.value)}
                                                className="peer h-full w-full radius_input py-2 px-3 border_black text-gray-700 focus:outline-none focus:shadow-outline ">
                                            <option value="1">Cedula de identidad</option>
                                            <option value="2">Cedula de identidad extranjero</option>
                                            <option value="3">Pasaporte</option>
                                            <option value="4">Otros</option>
                                            <option value="5">Nit</option>
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label className="block fontzize_label text-black mb-1"
                                               htmlFor="nit">
                                            Nit:
                                        </label>
                                        <input
                                            className="h-full w-full radius_input py-2 px-3 border_black text-gray-700 focus:outline-none focus:shadow-outline"
                                            id="nit" type="text"
                                            name="nit"
                                            placeholder="Nit"
                                            pattern="[0-9]{0,13}"
                                            value={nit}
                                            onChange={(e) => inputNit(e)}
                                        />
                                        {errors.nit?.type === 'required' &&
                                            <p style={{"color": "#920909"}}>El campo nit obligatorio*</p>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="block fontzize_label text-black mb-1"
                                               htmlFor="username">
                                            Razon Social:
                                        </label>
                                        <input
                                            className="h-full w-full radius_input py-2 px-3 border_black text-gray-700 focus:outline-none focus:shadow-outline"
                                            id="username" type="text"
                                            name="razon"
                                            placeholder="Razón social"
                                            value={razonsocial}
                                            onChange={(e) => setRazonsocial(e.target.value)}
                                        />
                                        {errors.razon?.type === 'required' &&
                                            <p style={{"color": "#920909"}}>El campo razoón social
                                                obligatorio*</p>}
                                    </div>
                                    {
                                        tipoDocumento === "1" &&
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-1"
                                                   htmlFor="complemento">
                                                Complemento:
                                            </label>
                                            <input
                                                className="h-full w-full radius_input py-2 px-3 border_black text-gray-700 focus:outline-none focus:shadow-outline"
                                                id="complemento" type="text"
                                                name="complemento"
                                                value={complemento}
                                                onChange={(e) => setComplemento(e.target.value)}
                                                placeholder="opcional"
                                            />

                                        </div>
                                    }

                                    <div className="my-3">
                                        <label className="block fontzize_label text-black  mb-1"
                                               htmlFor="username">

                                            ¿Tienes algún comentario?
                                        </label>
                                        <input
                                            className="h-full w-full radius_input py-2 px-3 border_black text-gray-700 focus:outline-none focus:shadow-outline"
                                            id="comentario" type="text"
                                            name="comentario"
                                            placeholder="Aclaracion sobre tu pedido"
                                            onChange={(e) => setComentario(e.target.value)}
                                        />

                                    </div>
                                    <div className="my-3" key="vale">
                                        <label
                                            className=" underline px-3 fontzize_label text-hiperorange  cursor-pointer "
                                            onClick={(e) => showModal(e)}
                                        >
                                            Agregar vale hipermaxi
                                        </label>
                                    </div>

                                    <div className="rounded border-solid w-100 ">
                                        {
                                            listaVales.length > 0 &&
                                            listaVales.map((val, index) =>
                                                <div className="flex  flex-wrap">
                                                    <div key={index}>
                                                        <FontAwesomeIcon icon={faTimesCircle}
                                                                         className="mr-1 text-hiperred cursor-pointer"
                                                                         onClick={(e) => showModal(e)}/></div>
                                                    <div>Vale Nro. &nbsp; {index + 1} &nbsp; por importe de
                                                        Bs. &nbsp; {val} </div>
                                                </div>
                                            )
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-[30%]">
                        <div className="content_card_footer bg-white text-darck rounded-hiper_g "
                             style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem'}}>
                            <div className="content_child_footer mb-1">
                                <div className="px-3 py-1 bg-hiperorange mb-1 text-white"
                                     style={{borderRadius: '20px', width: 'fit-content', float: 'right'}}>
                                    <p className="mb-0 font-bold "
                                       style={{fontSize: '15px'}}>Bs. {importeAhorrodo} ahorrado</p>
                                </div>
                                <div className='flex p-1 justify-between  text-black '>
                                    <span className='text-center m-0 fontzize_text_carrito font-bold'>Total:</span>
                                    <span className='fontzize_text_carrito font-bold'>Bs {importeTotal}</span>
                                </div>
                            </div>

                            <div className="flex flex-col justify-center  text-center gap-2 text-white">
                                <ButtonText
                                    type='button'
                                    label='Volver'
                                    principal={false}
                                    className="bg-hiperorange_claro hover:bg-hiperorange_claro_hover text-black hover:text-black border border-black"
                                    onClick={() => volverAtras()}
                                    style={{border: '1px solid black'}}
                                />
                                <ButtonText
                                    label='Realizar pedido'
                                    type="submit"
                                    principal={true}
                                    className="bg-hiperorange hover:bg-hiperorange_hover"
                                    onClick={() => {
                                    }}
                                />

                            </div>
                        </div>


                        {/* <div className="bg-transparent rounded-lg p-2 text-white">

                            <ButtonText
                                type="submit"
                                label='Realizar Pedido'
                                principal={true}
                                className="bg-[#84B111] mb-2"
                                onClick={() => {
                                }}
                            />
                            <ButtonText
                                label='Volver Carrito'
                                principal={false}
                                className="bg-[#32338E]"
                                onClick={() => volverAtras()}
                            />
                        </div> */}
                    </div>
                </form>
            </div>


            <div className="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden" id="modal">
                <div
                    className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75"/>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203; </span>
                    <div
                        className="inline-block align-center bg-white rounded-hiper_g text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">

                        <div className="rounded-hiper_g overflow-hidden ">
                            <div
                                className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                                <h2 className='p-0 m-0 title_responsivo  '>Agregar vales del hipermaxi</h2>
                                <div className="absolute right-2 ">
                                    <ButtonClose className="text-white bg-transparent" onClick={(e) => showModal(e)}/>
                                </div>
                            </div>

                            <div className="  px-4 py-3 ">
                                <div className="flex  justify-center">
                                    <label className="font-bold">Monto del vale</label>
                                </div>

                                <input type="number" className="form-control w-full bg-gray p-2 mt-2 mb-3"
                                       value={vale}
                                       onChange={(e) => setVale(e.target.value)}/>
                                <span className="text-xs italic leading-3">El vale se entregará como pago al momento de recibir los productos </span>

                                <div className="flex justify-center mt-2">
                                    <button type="button"
                                            className="py-2 px-4 bg-hiperorange text-white rounded hover:bg-hiperorange-700 mx-auto "
                                            onClick={(e) => agregar_vale(e)}> Agregar vale
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div className="fixed z-10  top-2 w-full left-0 hidden" id="modalFacturacion">
                <div
                    className=" items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-900 opacity-75 overflow-hidden "/>
                    </div>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203; </span>
                    <div
                        className="inline-block align-center bg-white rounded-hiper_g text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    >

                        <div className=" rounded-hiper_g overflow-hidden ">
                            <div
                                className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                                <h2 className='p-0 m-0 title_responsivo  '>Seleccione una razón social o nit</h2>
                                <div className="absolute right-2 ">
                                    <ButtonClose className="text-white bg-transparent"
                                                 onClick={(e) => showModal_facturaciones(e)}/>
                                </div>
                            </div>
                            <div className="overflow-auto p-4" style={{height: '30rem', maxHeight: '30rem'}}>
                                {
                                    facturaciones.map((fact, indexfact) =>
                                        <div key={indexfact}
                                             className="flex border border-black  justify-between items-center bg-white hover:bg-hiperorange text-black hover:text-white   py-2 pl-4 pr-2 rounded w-100 mb-2 cursor-pointer "
                                        >
                                            <label
                                                className="cursor-pointer"
                                                onClick={(e) => seleccionar_opcion(fact, e)}
                                            >{fact.RazonSocial} - {fact.Nit}</label>
                                            <FontAwesomeIcon icon={faTrashCan} onClick={() => eliminarFactura(fact)}
                                                             style={{fontSize: '1.4rem'}}
                                                             className="mr-1 cursor-pointer"/>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    metodopagos: state.MetodoPago.metodopagos,
    sucursal: state.BranchOffice.sucursal,
    shopping_list_detalle: state.Shopping_list.shopping_list_detalle,
    carrito_lista_compra: state.Shopping_list.carrito_lista_compra,
    direccion: state.Location.direccion,
    profile: state.Profile.profile,
})


export default connect(mapStateToProps, {
    get_list_metodos_pagos, compra_rapida, setSwAlert, eliminar_cliente_nit
})(FormaPago)