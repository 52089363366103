import {
    GET_CARD_SUCCESS,
    POST_CARD_SUCCESS,
    GET_CARD_FAIL,
    POST_CARD_FAIL,
    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_FAIL,
    SET_LOADING,
    REMOVE_LOADING,

} from '../actions/types';

const initialState = {
    tarjetas: null,
    loading: false,
    post_card: false,
};

export default function Tarjeta(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }
        case REMOVE_LOADING:
            return {
                ...state,
                loading: false
            }
        case GET_CARD_SUCCESS:
            return {
                ...state,
                tarjetas: payload.Dato,
            }
        case GET_CARD_FAIL:
            return {
                ...state
            }
        case POST_CARD_SUCCESS:
            return {
                ...state,
                // tarjetas: payload.Dato,
                post_card: true
            }
        case POST_CARD_FAIL:
            return {
                ...state,
                post_card: false
            }
        case DELETE_ITEM_SUCCESS:
            return {
                ...state,
            }
        case DELETE_ITEM_FAIL:
            return {
                ...state,
            }

        default:
            return state
    }
}