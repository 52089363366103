import React, {useEffect,useState} from 'react';
import logo from '../../../../assets/images/login/logo.png';
import background from '../../../../assets/images/login/Background.jpg';
import texture from '../../../../assets/images/login/texture_complete.jpg';
import {Link} from 'react-router-dom';
import '../../../../assets/fontawesome';
import {faFacebook, faGoogle} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage,faMobileAndroidAlt} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import '../../../../assets/styles/mystyle.scss'

import "../../../../assets/styles/globalColorPrincipal.css";
import "../../../../assets/styles/stylePageGlobals.scss";
import PhoneInput from "react-phone-number-input";
import {connect} from "react-redux";
import {get_anonimo_token,codigo_acceso,get_token,get_prefijos,activate,activate_sin_autologin, set_token_externo} from "../../../../redux/actions/auth";
import {useNavigate} from "react-router-dom";
import FacebookLogin from 'react-facebook-login';
import {FacebookLoginButton} from 'react-social-login-buttons';
import {LoginSocialFacebook} from 'reactjs-social-login';
import { GoogleOAuthProvider  } from '@react-oauth/google';
import { GoogleLogin } from 'react-google-login';
import {info_session} from "../../../../redux/actions/profile";
import {useForm} from "react-hook-form";
import ButtonIcon from "../../../../widgets/button_icon";
import {Circles} from "react-loader-spinner";
import ButtonText from "../../../../widgets/button_text";
const containerStyle = {
    backgroundImage:
        `url(${background})`,
    width: "100%",
    height: "92.5vh",
};
const containerStyle2 = {
    backgroundImage:
        `url(${texture})`,
    width: "70%",
    borderRadius:'20px'

};


const Registro_activate = ({array_prefijo, peticion,loading, sendAcceso, get_prefijos,codigo_acceso, profile,datoLogin,get_token,access_token,ps_posicion,
    activate,
    activate_sin_autologin,
    phone,
    isAuthenticated,
    authenticated,
    info_session,
    set_token_externo,
    ps_save_pedidos,
    ps_endModalRegistro,
    ps_opcion_numero,
    ps_check_no_anonimo

}) => {

    const [activated, setActivated] = useState(false);

    const [identifyTiempoDeEspera,setIdentifyTiempoDeEspera] = useState(0);
    const [counter, setCounter] = useState(60);

    const [deshabilitar,setDeshabilitar] = useState(false);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        CodigoAcceso: '',
        username: phone,
    })

    const {
        CodigoAcceso,
        username
    } = formData;

    const {register, handleSubmit, formState: {errors}} = useForm();

    const messages = {
        required: "Este campo es obligatorio",
        minLength: "No puede ingresar menor caracteres de lo establecido",
        maxLength: "No puede ingresar más caracteres en este campo",
        espacioBlanco: 'No es permitido espacios en blanco',
        requiredColor: 'Debe seleccionar un color',
        aceptaNumeros: 'Solo se acepta números en este campo',
        aceptaLetra: 'Solo se acepta letras en este campo'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
        aceptaNumeros: /^[0-9,-]+$/,
        aceptaLetra: /^[A-Za-z\s]+$/,
    };
    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    useEffect(() => {
        if (access_token) {
            info_session();
        }
    }, [access_token]);
    useEffect(() => {
        console.log("profile activate",profile);
        if (profile) {
            if (profile.TerminoCondicion.AceptoTerminoActual) {
                let tipo_user  = localStorage.getItem('user');
                if (profile.Ubicaciones.length > 0 && profile.Telefono!=="" ) {
                    // navigate("/sucursal");
                    console.log("redirigir a guardar");
                    // ps_check_no_anonimo();
                    // ps_opcion_numero(false);
                    // ps_save_pedidos();
                    // ps_endModalRegistro();
                    confirmando_pedido();
                    

                } else if (tipo_user === '"anonimo"') {
                    // navigate("/ubicacion");
                    console.log("redirigir a enviar codigo");
                }

            } else {
                // navigate("/terminoscondiciones");
                
                console.log("redirigir a condicion");
                ps_posicion(3);
            }
        }
    }, [profile]);

    const confirmando_pedido = async()=>{
        await ps_check_no_anonimo();
        await ps_opcion_numero(false);
        await ps_save_pedidos();
        await ps_endModalRegistro();
    }

 
    const onSubmit = async (data) => {
        ejecutar();
        // await activate(phone, data.codigoAcceso);
        await activate_sin_autologin(phone, data.codigoAcceso);
        let redSocialId = localStorage.getItem("redId");
        console.log("social id",redSocialId);
        console.log("cod acceso registro_activate ",data.codigoAcceso);
        if(redSocialId!==null){
            await set_token_externo(redSocialId, data.codigoAcceso);
        }
        

    }
    
 
    const ejecutar= ()=>{
        console.log("ejecutar guardar");
        setDeshabilitar(true);
        tiempo_permitido();

    }

    function tiempo_permitido(){
        const interval = setInterval(() => {
                setCounter((prevCounter) =>  prevCounter - 1);
          }, 1000);

        setTimeout(() => { 
            console.log("limpiar");
            clearInterval(interval);
            setDeshabilitar(false);
            setCounter(60);
        }, 60000);

    }
  

   return (
        <>
            <div  className="relative  h-auto flex justify-center content_modal_registro" style={{minHeight:'40rem'}}>
               
                <div className="hiper-color absolute bottom-0 w-full" style={containerStyle2}>
                    <div className="h-[20rem] max-h-full">
                    <h1 className="text-4xl font-inter font-normal text-center text-white my-5">Ingresar</h1>
                            <form onSubmit={handleSubmit(onSubmit)} className="flex justify-center m-0">
                                <div className="box-input">
                                    <div>
                                        <label htmlFor="codigo" className="block text-sm font-medium text-white">
                                            <FontAwesomeIcon icon={faMobileAndroidAlt}
                                                             style={{height: "20px"}}/> Ingresa el
                                            código
                                        </label>
                                        <div className="grid grid-cols-1">
                                            <input
                                                name="CodigoAcceso"
                                                type="text"
                                                className="shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                //    className="appearance-none block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                autoComplete='off'
                                                {...register("codigoAcceso", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },
                                                    minLength: {
                                                        value: 6,
                                                        message: messages.minLength
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: messages.maxLength
                                                    },
                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    },
                                                    pattern: {
                                                        value: patterns.aceptaNumeros,
                                                        message: messages.aceptaNumeros
                                                    }
                                                })}
                                            />
                                            {errors.codigoAcceso &&
                                                <small className="text-red-600">{errors.codigoAcceso.message}</small>}

                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 mt-2 text-white">
                                            <button
                                                    className="bg-transparent  font-inter font-light  py-1 px-2 border border-white  rounded-lg w-full mt-2 mb-2 flex items-center cursor-default"
                                                    type="button">
                                                    <FontAwesomeIcon icon={faMessage} style={{height: "20px"}}/> <span
                                                    className="ml-1"
                                                    style={{fontSize: '12px'}}>Reenviar código de nuevo en {counter} segundos</span>
                                            </button>

                                        {deshabilitar?(
                                            <>
                                               
                                                <button
                                                    disabled={deshabilitar}
                                                    style={{cursor:'not-allowed'}}
                                                    className={'flex justify-center items-center py-1.5 px-4 border w-full rounded-lg bg-[#32338E]   transition-colors duration-300 font-medium'}>
                                                
                                                        Aceptar
                                                </button>
                                            </>
                                        ):(<>
                                            
                                            <ButtonText
                                            type='submit'
                                            label='Aceptar'
                                            principal={true}
                                            className="bg-hiperblue hover:bg-hiperblue_hover text-white"
                                            onClick={() => {
                                            }}
                                            />

                                            </>)
                                           
                                        }
                                       

                                     
                                        
                                    </div>
                                </div>
                            </form>
                    </div>
                </div>
                
            </div>
        </>
    );
};
const mapStateToProps = state => ({
    loading: state.Auth.loading,
    datoLogin: state.Auth.datoLogin,
    sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
    authenticated: state.Auth.authenticated,
    profile: state.Profile.profile,
    access_token: state.Auth.access_token,
    // isAuthenticated: state.Auth.isAuthenticated
    peticion: state.Peticion.peticion,
    array_prefijo: state.Auth.array_prefijo,
    sendAcceso: state.Auth.sendAcceso,
    phone: state.Auth.phone,
    isAuthenticated: state.Auth.isAuthenticated,
    

})

export default connect(mapStateToProps, {
    get_prefijos,codigo_acceso,get_token,
    activate, info_session, set_token_externo,activate_sin_autologin
})(Registro_activate)

// export default Login;

