import React, {useState, useEffect} from 'react';
import '../../assets/styles/hiper.scss'
import logo from '../../assets/images/login/logo.png';
import background from '../../assets/images/login/Background.jpg';
import texture from '../../assets/images/login/texture_complete.jpg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage, faMobileAndroidAlt} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import PhoneInput from "react-phone-number-input";
import {connect} from "react-redux";
import {codigo_acceso, get_prefijos} from "../../redux/actions/auth";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Circles} from "react-loader-spinner";
import Alert from "../../components/Swalert";
import ButtonIcon from "../../widgets/button_icon";
import WaitLoading from "../../widgets/wait_loading";
import ButtonText from "../../widgets/button_text";

import './input-phone.css'

const containerStyle = {
    backgroundImage:
        `url(${background})`,
    width: "100%",
    height: "92.5vh",
};
const containerStyle2 = {
    backgroundImage:
        `url(${texture})`,
    width: "100%",
};

const LoginPhone = ({get_prefijos, array_prefijo, codigo_acceso, peticion, sendAcceso}) => {

    const navigate = useNavigate();
    const [value, setValue] = useState("+591");
    const [paises, setPaises] = useState([]);
    const [canalEnvio, setCanalEnvio] = useState([]);
    const [formData, setFormData] = useState({
        telefono: '',
        prefijo: '',
        TipoEnvioAcceso: '',
    })
    const {
        telefono,
        prefijo,
        TipoEnvioAcceso
    } = formData;

    const {register, handleSubmit, formState: {errors}} = useForm();

    useEffect(() => {
        get_prefijos();
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        if (array_prefijo.length > 0) {
            let countries = array_prefijo.map((p) => {
                return p.ISO2;
            })
            let seleccionado = array_prefijo.find(pre => pre.NumeroPrefijo === 591);
            setCanalEnvio(seleccionado.IdCanalesEnvio);
            setPaises(countries);
        }
    }, [array_prefijo])
    useEffect(() => {
        if (value && array_prefijo.length > 0) {
            let numero = value.replace(/[^0-9]+/g, "");
            let seleccionado = array_prefijo.find(pre => Number(pre.NumeroPrefijo) === Number(numero));
            setCanalEnvio(seleccionado.IdCanalesEnvio);
        }
    }, [value])
    function is_whatsapp(){
        return canalEnvio.find(c => c === 1);
    }
    function is_sms(){
        return canalEnvio.find(c => c === 2);
    }


    const messages = {
        required: "Este campo es obligatorio",
        minLength: "No puede ingresar menor caracteres de lo establecido",
        maxLength: "No puede ingresar más caracteres en este campo",
        espacioBlanco: 'No es permitido espacios en blanco',
        requiredColor: 'Debe seleccionar un color',
        aceptaNumeros: 'Solo se acepta números en este campo',
        aceptaLetra: 'Solo se acepta letras en este campo'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
        aceptaNumeros: /^[0-9,-]+$/,
        aceptaLetra: /^[A-Za-z\s]+$/,
    };

    const onSubmit = async (data) => {
        formData.telefono = data.telefono;
        if (typeof (value) == 'undefined' || value === '') {
            formData.prefijo = 591;
        } else {
            formData.prefijo = value.replace(/[^0-9]+/g, "");
        }
        await codigo_acceso(data.telefono, formData.prefijo, formData.TipoEnvioAcceso)
    }


    useEffect(() => {
        navigateAccesso();

    }, [sendAcceso]);

    function navigateAccesso() {
        if (sendAcceso) {
            navigate("/activar");
        }
    }

    const tipoWhatsapp = (e) => {
        formData.TipoEnvioAcceso = 1;
    };
    const tipoSms = (e) => {
        formData.TipoEnvioAcceso = 2;
    };
    const go_ubicacion = () => {
        navigate("/ubicacion");
    };

    return (
        <>
            <Alert></Alert>
            <div id="wrapper relative" style={containerStyle}>
                <div className='shadow-md w-full static top-0 left-0'>
                    <div className='flex items-center justify-center bg-white py-2 md:px-10 px-7'>
                            <img src={logo} width="70"
                                 alt="logo"/>
                    </div>
                </div>

                <div className="main">
                    <div className="hiper-color absolute bottom-0 w-full" style={containerStyle2}>
                        <div className="h-96 max-h-full">
                            <h1 className="text-4xl font-inter font-normal text-center text-white my-4">Ingresar</h1>
                            <div className='flex justify-center mb-1'>
                                <Circles
                                    color="#FFFFFF"
                                    width={35}
                                    height={35}
                                    ariaLabel="circles-loading"
                                    visible={peticion}
                                />
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} className="flex justify-center m-0">
                                <div className="box-input">
                                    <div className='centenido_form'>
                                        <label htmlFor="phone" className="block text-sm font-medium text-white">
                                            <FontAwesomeIcon icon={faMobileAndroidAlt} style={{height: "20px"}}/> Con tu
                                            número de celular
                                        </label>
                                        <div className="mt-1">
                                            <div className='row'>
                                                {
                                                    paises.length > 0 &&
                                                    <div className='col-4 pr-0'>
                                                        <PhoneInput
                                                            countries={paises}
                                                            className='rounded-md'
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            defaultCountry="BO"
                                                            value={value}
                                                            onChange={setValue}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                }

                                                <div className='col-8'>
                                                    <input
                                                        name="telefono"
                                                        className='shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                        type="text"
                                                        {...register("telefono", {
                                                            required: {
                                                                value: true,
                                                                message: messages.required,
                                                            },
                                                            minLength: {
                                                                value: 8,
                                                                message: messages.minLength
                                                            },
                                                            maxLength: {
                                                                value: 8,
                                                                message: messages.maxLength
                                                            },
                                                            pattern: {
                                                                value: patterns.espacioBlanco,
                                                                message: messages.espacioBlanco
                                                            },
                                                            pattern: {
                                                                value: patterns.aceptaNumeros,
                                                                message: messages.aceptaNumeros
                                                            }
                                                        })}

                                                        // {...register('telefono',{required:true})} 
                                                        // className="appearance-none block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"

                                                    />
                                                    {errors.telefono && <small
                                                        className="text-red-600">{errors.telefono.message}</small>}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 mt-2 text-white">
                                        {
                                            canalEnvio.length > 0 &&
                                            <>
                                                {
                                                    is_whatsapp() &&
                                                    <ButtonIcon
                                                        type='submit'
                                                        icon={faWhatsapp}
                                                        label='Recibir código por WhatsApp'
                                                        principal={false}
                                                        onClick={(e) => tipoWhatsapp()}
                                                        className={'text-[#DC842D] bg-white'}
                                                    />
                                                }
                                                {is_sms() &&
                                                    <ButtonIcon
                                                        type='submit'
                                                        icon={faMessage}
                                                        principal={false}
                                                        label='Recibir código por SMS'
                                                        onClick={() => tipoSms()}
                                                        className={'text-[#DC842D] bg-white'}
                                                    />
                                                }

                                            </>
                                        }

                                        <hr className="mt-2"/>
                                        <ButtonText
                                            label='Seguir comprando'
                                            className="bg-hiperblue hover:bg-hiperblue_hover text-white"
                                            onClick={() => go_ubicacion()}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


const mapStateToProps = state => ({
    array_prefijo: state.Auth.array_prefijo,
    loading: state.Auth.loading,
    peticion: state.Peticion.peticion,
    sendAcceso: state.Auth.sendAcceso,
    // isAuthenticated: state.Auth.isAuthenticated
})

export default connect(mapStateToProps, {
    codigo_acceso, get_prefijos
})(LoginPhone)



