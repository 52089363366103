import React, {useEffect} from 'react'
import {connect} from "react-redux";
import {get_banners} from "../../redux/actions/home";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation,Pagination} from "swiper";
import Slider1 from "../../assets/images/home/Slider1.png";
import background_white from "../../assets/images/home/fondowhite.jpg";
import {Link} from "react-router-dom";

const containerStyle2 = {
    backgroundImage:
        `url(${background_white})`,
    width: "100%",
};
const breakpoints = {
    400: {
        slidesPerView: 1,
        spaceBetween: 1,
    },

    // 640: {
    //     slidesPerView: 1,
    //     spaceBetween: 10,
    // },
    // 768: {
    //     slidesPerView: 1,
    //     spaceBetween: 10,
    // },

    // 992: {
    //     slidesPerView: 1,
    //     spaceBetween: 10,
    // },
    // 1200: {
    //     slidesPerView: 1,
    //     spaceBetween: 10,
    // },
    // 1400: {
    //     slidesPerView: 1,
    //     spaceBetween: 10,
    // },
};
function Banner({descripcion, Items}) {
    useEffect(() => {
    }, [Items]);

    // const galery=[
    //     {id:1,Url_Destino:'',UrlFoto:'https://img.freepik.com/vector-gratis/banner-horizontal-negocios-degradado_23-2149695724.jpg'},
    //     {id:1,Url_Destino:'',UrlFoto:'https://img.freepik.com/vector-gratis/plantilla-banner-negocios-degradado_23-2149716414.jpg'},
    //     {id:1,Url_Destino:'',UrlFoto:'https://img.freepik.com/vector-gratis/banner-horizontal-negocios-degradado_23-2149695724.jpg'},
    //     {id:1,Url_Destino:'',UrlFoto:'https://img.freepik.com/vector-gratis/plantilla-banner-negocios-degradado_23-2149716414.jpg'},

        
    // ]

    return (
        <>
            <div className="container mx-auto mb-4">
                <Swiper
                    modules={[Navigation,Pagination]}
                    slidesPerView={1}
                    navigation={true}
                    pagination={true} 
                    breakpoints={breakpoints}
                    className="mySwiper"
                    style={{"--swiper-navigation-color": "#32338E",
                    "--swiper-navigation-size": "30px"
                    }}
                    >

                    {
                        Items &&
                        Items !== null &&
                        Items !== undefined &&
                        Items.map((banner, index) => (
                            <SwiperSlide key={index}>
                                <div style={containerStyle2} className="py-4">
                                    <div className="flex justify-center">
                                        <Link to={banner.Url_Destino}>
                                            <img src={banner.UrlFoto} alt=""/>
                                        </Link>

                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    banners: state.Home.banners,
})

export default connect(mapStateToProps, {
    get_banners,
})(Banner)