import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import Indice from "./carrito/indice";
import Carrito from "./carrito/carrito";
import Modal from "react-modal";
import {
    limpiar_carrito, limpiar_estado_confirmado
} from "../../redux/actions/carrito";
import ModoEntrega from "./carrito/modo_entrega";
import Direccion from "./carrito/direccion";
import FormaPago from "./carrito/forma_pago";
import ComentarioVale from "./carrito/comentario_vale";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {Link} from "react-router-dom";
import "../../assets/styles/responsive.scss"
import {useNavigate, useParams} from "react-router-dom";
import useScreenSize from "../../widgets/useScreenSize";

function MiCarrito({cerrarModal, limpiar_estado_confirmado, limpiar_carrito, cerrarModalAnonimo}) {
    const {width, height} = useScreenSize();
    const [sucursales, guardarSucursales] = useState([]);
    const [posicion, guardarPosicion] = useState(1); //antes 1 modif lim
    const {ciudadp, sucursalp} = useParams();

    const [is_carrito, guardarIsCarrito] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
            limpiar_estado_confirmado()
            guardarIsCarrito(true);
        }
    }, []);


    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function actualizar_posicion(numero) {
        guardarPosicion(numero);
    }

    function exit() {
        cerrarModal();
        // await limpiar_carrito();
    }

    function exitAnonimo() {
        cerrarModalAnonimo();
    }

    function finalizado_exit() {
        guardarIsCarrito(false);
    }

    function volverAtras(position) {
        if (position === 1) {
            exit();
        } else if (position === 2) {
            actualizar_posicion(1);
        } else if (position === 3) {
            actualizar_posicion(2);
        } else if (position === 4) {
            actualizar_posicion(3);
        } else if (position === 5) {
            actualizar_posicion(4);
        }
    }

    const ir_inicio = () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            navigate("/" + ciudadp + "/" + sucursalp);
        } else if (url_nav) {
            navigate("/" + url_nav.url_parameters);
        }
        cerrarModal();
    }

    async function navigate_menu(url_menu) {
        let param = await get_url();
        navigate(param + '/' + url_menu);
    }

    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/" + ciudadp + "/" + sucursalp);
        } else if (url_nav) {
            return ("/" + url_nav.url_parameters);
        }
    }

    return (
        <>
            {
                is_carrito ?
                    <div className="w-full  overflow-auto " style={{height: '100vh'}}>
                        <div className='container'>
                            <div
                                className={classNames("h-screen", "flex", "contenedor_flex", "gap-2", "mx-0", "min-h-738",
                                    {
                                        "justify-center": width > 768
                                    })}
                            >
                                <div className="hidden md:block contenedor_mobile md:w-[18%]">
                                    <Indice
                                        posicion={posicion}
                                    ></Indice>
                                </div>
                                {posicion === 1 &&
                                    <Carrito mt_indice={actualizar_posicion} width={width} height={height}
                                             exit={exit}></Carrito>
                                }
                                {posicion === 2 &&
                                    <ModoEntrega mt_indice={actualizar_posicion} width={width} height={height}
                                                 exit={exit}></ModoEntrega>
                                }
                                {posicion === 3 &&

                                    <Direccion mt_indice={actualizar_posicion} width={width} height={height}
                                               exit={exit}></Direccion>

                                }
                                {posicion === 4 &&

                                    <FormaPago mt_indice={actualizar_posicion} width={width} height={height}
                                               exit={exit}></FormaPago>

                                }
                                {posicion === 5 &&
                                    <ComentarioVale mt_indice={actualizar_posicion}
                                                    finalizado={finalizado_exit} width={width} height={height}
                                                    exit={exit}
                                                    exitAnonimo={exitAnonimo}></ComentarioVale>
                                }


                            </div>
                        </div>
                    </div>
                    :
                    <div className="flex justify-center items-center mt-12">
                        <div className="w-full max-w-xl ">
                            <div className="card border-0 rounded-xl overflow-hidden">
                                <div className="card-body  bg-hiperorange d-flex justify-center">
                                    <div className='py-5'>
                                        <div className=" text-center">
                                            <FontAwesomeIcon icon={faLocationDot} className="text-white text-8xl"/>
                                            <div>
                                                <p className="text-white text-2xl">Se realizó su
                                                    pedido <br/> correctamente</p>
                                            </div>
                                        </div>
                                        <div className="grid grid-rows-2 gap-3 text-center">

                                            <button
                                                className="h-full w-full rounded-[7px] py-2 px-3 border_white text-white focus:outline-none focus:shadow-outline"
                                                onClick={() => ir_inicio()}>
                                                Seguir comprando
                                            </button>

                                            <Link onClick={() => navigate_menu('mis_pedidos')}>
                                                <button
                                                    className="h-full w-full rounded-[7px] py-2 px-3 border_white text-white focus:outline-none focus:shadow-outline">Detalle
                                                    del pedido
                                                </button>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
            }


        </>
    );
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {limpiar_carrito, limpiar_estado_confirmado})(MiCarrito)