export default {

        data_slug (str)
        {
            str = str.replace(/^\s+|\s+$/g, ''); 
            str = str.toLowerCase();
            var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to   = "aaaaaeeeeiiiioooouuuunc------";
            for (var i=0, l=from.length ; i<l ; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }
            str = str.replace(/[^a-z0-9 -]/g, '') 
                .replace(/\s+/g, '-') 
                .replace(/-+/g, '-'); 
            return str;
        },

        data_barra(url){
            return url.replace(/\s+/g, '/');
        },

        data_lectura_url(url_slug,data){
            for(let item of  data){
 
                if(this.data_slug(item.Descripcion)=== url_slug){
               
                  return item;
                }
            }
        }
    
}