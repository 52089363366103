import React from "react";
import '../assets/styles/itemDetallePedido.scss'
const ItemDetallePedido = ({UrlFoto, nombreproducto, precio, cantidad, subtotal, moneda}) => {
    function formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <div className="flex  container_detalle_pedido border-b-2 p-3">
            <div className="product_description flex">
                <div className="flex flex-column pl-3 h-auto relative ">
                    <div className="descripcion" >
                        <p className="mb-0 text_gray font-bold texto_name_producto">{nombreproducto}</p>
                        <div className="flex gap-2 font-semibold text-zinc-400">
                            <span>Precio u. {moneda}</span>
                            <span>{formatPrice(precio)}</span>
                        </div>
                        {/* <div className="   style_cantidad">
                            <div className="flex gap-2 text-hiperorange font-bold">
                                <span>Cantidad: </span>
                                <span>{formatPrice(cantidad)} </span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="product_price flex   pr-3 detalle-item">
                <div className="  pt-1  ">
                    <div className="flex justify-between text-hiperorange font-bold">
                        <span>Cantidad: </span>
                        <span>{formatPrice(cantidad)} </span>
                    </div>
                </div>
                <div className="font-medium text-xl text-[#84B111]  "  >
                    <span>{moneda}. &nbsp; {formatPrice(subtotal)} </span>
                </div>
            </div>


        </div>
    );
};

export default ItemDetallePedido;