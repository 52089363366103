import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


import {
    inc_al_carrito_lista_compra, dec_al_carrito_lista_compra,
    checked_carrito_lista_compra,
    inc_peso_carrito_lista_compra
} from "../redux/actions/shoppingList";
import {
    faTrash,
    faPlus, faMinus
} from "@fortawesome/free-solid-svg-icons";
import "../assets/styles/globalColorPrincipal.css";
import {useNavigate,useParams} from "react-router-dom";
import Modal from "react-modal";
import FullScreenImage from './../widgets/fullScreenImage';
import Swal from 'sweetalert2';
import Page_message from "../components/Page_message";
const customStyles2 = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '100',
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0.75rem',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'visible'
    },
};
const ItemDetalleListaCompra = ({
                                    // ps_shopping_list,
                                    ps_producto,
                                    ps_object_lista_compra,
                                    carrito_lista_compra,
                                    // add_al_carrito,
                                    inc_al_carrito_lista_compra,
                                    dec_al_carrito_lista_compra,
                                    // remove_al_carrito,
                                    totalQuantities,
                                    ps_opcEdit, ps_updateDato, ps_handleCheckedProducto,
                                    ps_calcularTotal,
                                    checked_carrito_lista_compra,
                                    ps_openModalPeso,
                                    inc_peso_carrito_lista_compra,
                                    ps_viewProductoPeso,
                                    ps_actualizarCantidadCarrito,
                                    ps_eliminarProduct,
                                    ps_idSucursal
                                }) => {

    const [modalIsOpen, setIsOpen] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const {ciudadp, sucursalp} = useParams();

    function formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


    async function checkedProducto(Producto, nextChecked) {
        await checked_carrito_lista_compra(Producto, nextChecked);
        await ps_calcularTotal();
    }


    useEffect(() => {

    }, []);

    // new function increment and decrement
    const navigate = useNavigate();

    const [cantidad, setCantidad] = useState(0);

    useEffect(() => {
        let findPro = carrito_lista_compra.find((item) => item.IdProducto === ps_producto.IdProducto)
        typeof findPro === 'object' ? setCantidad(findPro.Cantidad) : setCantidad(0);
    }, [totalQuantities]);

    async function openModalPesoOrPieza(producto) {
        if (_es_peso()) {
            await ps_viewProductoPeso(producto)
            await ps_openModalPeso();
        }
        // else if(_es_pieza){
        // }

    }

    async function decrement_carrito() {
        if (_es_peso()) {
            ps_actualizarCantidadCarrito(ps_producto, 'decremento', 'es_peso')
        } else if (_es_pieza()) {
            let param= await get_url();
            navigate(param+"/lista_compra/detalle_producto/" + ps_object_lista_compra.Id + "/" + ps_producto.IdProducto);

        } else {
            normal('decremento');
        }
        ps_calcularTotal();
    }

    async function incremento_carrito() {
        if (_es_peso()) {
            ps_actualizarCantidadCarrito(ps_producto, 'incremento', 'es_peso')
        } else if (_es_pieza()) {
            let param= await get_url();
            navigate(param+"/lista_compra/detalle_producto/" + ps_object_lista_compra.Id + "/" + ps_producto.IdProducto);
        } else {
            normal('incremento');
        }

        ps_calcularTotal();
    }

    async function normal(operacion) {

        if (operacion === 'incremento') {
            if (ps_producto.Cantidad === 0) {
                await inc_al_carrito_lista_compra(ps_producto);
                await checked_carrito_lista_compra(ps_producto, true);
            } else {
                await inc_al_carrito_lista_compra(ps_producto);
            }

        } else if (operacion === 'decremento') {
            if (ps_producto.Cantidad === 1) {
                await dec_al_carrito_lista_compra(ps_producto);
                await checked_carrito_lista_compra(ps_producto, false);
            } else {
                await dec_al_carrito_lista_compra(ps_producto);
            }
        }
        await ps_calcularTotal();
    }

    function _es_peso() {
        if (ps_producto) {
            return ps_producto.Fraccionado && !ps_producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }

    function _es_pieza() {
        if (ps_producto) {
            return ps_producto.Fraccionado && ps_producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const closeFullScreen = () => {
        setShowFullScreen(false);

    };

    // delete
    function delete_item_list(id_producto) {
        delete_registro(id_producto)
    }
    async function delete_registro(idproducto) {
        let title= '¿Está seguro de quitar el producto de la lista?';
        let resp = await Page_message.delete_register_confirmation(title,'');
        if (resp) {
            ps_eliminarProduct(idproducto);
        }

 
  }
    function _estimado_precio(producto) {
        let result = 0;
        let total_estimado = 0;
        if (_es_pieza()) {
            producto.Pesos.forEach((peso) => {
                result += peso.Piezas * peso.PesoAproxMax;
            });
        } else {
            result = producto.Cantidad;
        }

        let precio_producto = producto.ConOferta ? producto.PrecioOferta : producto.PrecioVenta;
        total_estimado = result * precio_producto;
        let new_format= formatPrice(total_estimado);
        return new_format;
    }
    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/"+ciudadp+"/"+sucursalp);
        } else if (url_nav) {
            return ("/"+url_nav.url_parameters);
        }
    }

    return (

        <div className=" flex justify-between p-2 px-2 mr-3 py-0.5 border-b-2 "
             style={{boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
             background: ps_producto.Vigente ? 'none' : 'gainsboro',
             background:ps_producto.Checked? 'none':'gainsboro'
            
            }}
        >

            <div className="product_descript w-auto flex">
                <div className="bg-white flex justify-center items-center h-[9rem] w-[6rem]">
                    <img className="h-fit" style={{
                        filter: ps_producto.Vigente ? 'none' : 'grayscale(100%)',
                        filter: ps_producto.Checked ? 'none' : 'grayscale(100%)'
                    }}
                         src={ps_producto.UrlFoto + '&size=120x120'}
                         alt="image"
                         onClick={openModal}/>
                </div>

                <div className="flex flex-col pl-3 h-100" style={{alignSelf: 'center', position: 'relative'}}>

                    <div className="descripcion ">
                        <div
                            className="text_gray font-bold" style={{alignSelf: 'center', fontSize: '0.8rem'}}>
                            <span className="ml-0"> {ps_producto.Descripcion} </span>
                        </div>
                        {ps_producto.ConOferta ? (
                            <div className="flex gap-2">


                                <div
                                    className="text-[0.9rem] flex items-center gap-2  font-semibold mt-2 m-0 text-zinc-400 ">
                                    <span> Precio u.  Bs. </span>
                                    <span
                                        className="text-[0.8rem]  font-semibold  text-zinc-400 line-through">{formatPrice(ps_producto.PrecioOriginal)}</span>
                                    <span className="text-[1rem]">  {formatPrice(ps_producto.PrecioOferta)}  </span>
                                </div>

                            </div>

                        ) : (
                            <div
                                className="text-[0.9rem] flex items-center gap-2 text-zinc-400 font-semibold mt-2 m-0 ">
                                <span> Precio u. Bs. </span>
                                <span className="text-[1rem]"> {formatPrice(ps_producto.PrecioVenta)} </span>
                            </div>
                        )
                        }
                    </div>


                    <div className="cantidad flex items-end  gap-1 text-white "
                         style={{minWidth: '9.4rem', maxWidth: '9.4rem'}}
                    >
                        {ps_producto.Vigente ? (
                            <div className="flex gap-2" style={{position: 'absolute', bottom: '10px'}}>

                            <button className="bg_primario flex h-7 w-7 p-2 rounded-md border border-slate-200 text-white
                                    self-center justify-center items-center "
                                        style={{
                                            cursor: ps_producto.Cantidad === 0 ? "not-allowed" : 'pointer'
                                        }}
                                    // onClick={() => {
                                    //     handleDecrementClick(ps_producto.IdProducto);}}

                                        onClick={() => decrement_carrito()}
                                        disabled={ps_producto.Cantidad === 0}
                                >
                                    <FontAwesomeIcon icon={faMinus}
                                                     className="text-white text-xs"/>

                                </button>
                                <div className="text_primario p-1 self-center">
                                    {/* <span className="text-2xl">{cantidad} </span> */}
                                    <span className="text-2xl cursor-pointer"
                                          style={{cursor: _es_peso() === true ? 'pointer' : 'default', borderBottom:_es_peso()=== true ? '1px solid':'none'}}
                                          onClick={() => openModalPesoOrPieza(ps_producto)}>
                                        {ps_producto.Cantidad}
                                    </span>
                                </div>

                                <button className="bg_primario flex h-7 w-7 p-2 rounded-md border border-slate-200 text-white self-center
                                        justify-center items-center"
                                        onClick={() => incremento_carrito()}
                                    
                                >
                                    <FontAwesomeIcon icon={faPlus}
                                                     className="text-white text-xs"/>
                                </button>

                            </div>
                        ) : (
                            <div className="flex" style={{position: 'absolute', bottom: '10px'}}>
                                <div className="agotado bg-[#FFF] text-[#FF1010] p-1 rounded-md">
                                    <span style={{
                                        fontSize: '1.8rem',
                                        fontWeight: 'bold',
                                        color: '#FF1010'
                                    }}>AGOTADO</span>
                                </div>
                            </div>

                        )

                        }


                    </div>
                    {/* <FontAwesomeIcon icon={faTrash} className="cursor-pointer text-[#32338E] self-center"/> */}


                </div>

            </div>

            <div className=" flex " >

                <div className="grid justify-items-end pr-2 h-100">
                    <div className="flex items-center">
                        <div className="round">
                            <input type="checkbox"
                                   checked={ps_producto.Checked}
                                   onChange={e => {
                                       checkedProducto(
                                           ps_producto,
                                           e.target.checked
                                       );
                                   }}
                                   className="checkbox"
                                   disabled={ps_producto.Vigente ? false : true}
                                   id={'checkbox' + ps_producto.IdProducto}/>
                            <label htmlFor={'checkbox' + ps_producto.IdProducto}
                                   style={{
                                       cursor: ps_producto.Vigente ? 'pointer' : 'not-allowed',
                                       border: ps_producto.Vigente ? '' : '1px solid #8F8F8F'
                                   }}
                            ></label>
                            {/* <label for="checkbox"></label> */}
                        </div>


                    </div>
                    <div className="flex    " style={{ textAlign:"end" }}>

                        <p className=" font-medium text-xl m-0"
                           style={{
                               width:"128px",
                               paddingLeft:"10px",
                               alignSelf: 'center', fontWeight: '600',
                               color: ps_producto.Vigente ? '#84B111' : '#8F8F8F'
                           }}>
                            Bs. {_estimado_precio(ps_producto)}

                        </p>


                    </div>
                    <div className="flex items-center">
                        <FontAwesomeIcon icon={faTrash} onClick={() => delete_item_list(ps_producto.IdProducto)}
                                         className="cursor-pointer text_primario self-center"/>
                    </div>
                </div>

            </div>

            <Modal
                isOpen={modalIsOpen}
                appElement={document.getElementById('root')}
                onRequestClose={closeModal}
                style={customStyles2}
                contentLabel="Example Modal"
                key={"keyModal"}

            >
                <FullScreenImage
                    imagenes={[]}
                    tiene_varias_imagenes={"no"}
                    imagen={ps_producto.UrlFoto}
                    onClose={closeFullScreen}
                ></FullScreenImage>
            </Modal>
        </div>


    );
};
const mapStateToProps = state => ({
    carrito_lista_compra: state.Shopping_list.carrito_lista_compra,
    totalQuantities: state.Shopping_list.totalQuantities,
})
export default connect(mapStateToProps, {
    // add_al_carrito,
    inc_al_carrito_lista_compra, dec_al_carrito_lista_compra,
    checked_carrito_lista_compra,
    inc_peso_carrito_lista_compra
    //  remove_al_carrito, actualizar_item_carrito
})(ItemDetalleListaCompra)

// export default ItemDetalleListaCompra;