import {
    SET_AUTH_LOADING,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAIL,
    UPDATE_USER_PROFILE_FAIL,
    UPDATE_USER_PROFILE_SUCCESS,
    REMOVE_AUTH_LOADING,
    CLEAR_PROFILE
} from './types'
import axios from 'axios';

import {setSwAlert,showRespuesta} from "./swalert";
import {end_peticion, start_peticion} from "./peticion";
import respuestas from '../../components/Alertas';

export const limpiar_perfil = () => async dispatch => {
    dispatch({
        type: CLEAR_PROFILE
    });
    dispatch(end_peticion());
};
export const info_session = (loading = false) => async dispatch => {
    if (loading) {
        dispatch(start_peticion('cargando'));
    }
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/Info-Sesion`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_USER_PROFILE_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_USER_PROFILE_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }

        if (loading) {
            dispatch(end_peticion());
        }
    } catch (err) {
        dispatch({
            type: GET_USER_PROFILE_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
        if (loading) {
            dispatch(end_peticion());
        }
    }
};
export const update_usuario_anonimo = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/usuario`, datos, config);
        if (res.status === 200) {
            dispatch(info_session());
        } else {
            dispatch({
                type: UPDATE_USER_PROFILE_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        dispatch({
            type: GET_USER_PROFILE_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const update_usuario = (datos, object = null) => async dispatch => {
    dispatch(start_peticion('actualizando...'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {


        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/usuario`, datos, config);
        if (res.status === 200 && res.data.Estado === 200) {
            dispatch(info_session());
            dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200, object));
        } else {
            dispatch({
                type: UPDATE_USER_PROFILE_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());
    } catch (err) {
        dispatch({
            type: GET_USER_PROFILE_FAIL
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const actualizar_tokenfb_usuario = (datos) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/usuario`,datos, config);
        if (res.status === 200 && !res.data.ConError) {
            dispatch(info_session());
        } else {
            dispatch({
                type: UPDATE_USER_PROFILE_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {

        dispatch({
            type: GET_USER_PROFILE_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
        //   dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
    }
};

export const eliminar_usuario = ()=> async dispatch =>{

    dispatch(start_peticion('actualizando...'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        console.log("eliminando...");
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/eliminar-usuario`, config);
        console.log('resp eliminando',res);
        if (res.status === 200 && res.data.Estado === 200) {
            dispatch(info_session());
            dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
        } else {
            dispatch({
                type: UPDATE_USER_PROFILE_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());
    } catch (err) {
        dispatch({
            type: GET_USER_PROFILE_FAIL
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }

}