import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {cn} from "../libs/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Circles} from "react-loader-spinner";

const ButtonIcon = ({icon: Icon, type = 'button', label, className, onClick, principal = false, peticion}) => {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={peticion}
            style={peticion ? {"cursor": "not-allowed"} : {}}
            className={cn('flex relative justify-center py-1.5 px-4 w-full rounded-lg hover:text-hiperorange hover:bg-white transition-colors duration-300 font-medium', className)}>
            {
                principal && peticion ?
                    <Circles
                        color="#FFFFFF"
                        width={20}
                        height={20}
                        ariaLabel="circles-loading"
                        visible={peticion}
                    />
                    :
                    <FontAwesomeIcon icon={Icon} style={{height: "25px",left:'1rem'}} className="w-8 absolute "/>
            }



            <span>{label}</span>

            {/* <span></span> */}

        </button>
    );
};

const mapStateToProps = state => ({
    peticion: state.Peticion.peticion,
})

export default connect(mapStateToProps, {})(ButtonIcon)

