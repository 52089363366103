import React, {useEffect,useState} from 'react'
import {connect} from "react-redux";
import {EstadoPedido} from "../../widgets/estadoPedido";
import {list_pedidos} from "../../redux/actions/pedido";

const miEstilo = {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: "#84B111",
    position:'absolute',
    right:'0'
};

function Pedidos({profile,pedidos,list_pedidos,peticion_pedidos}) {
    const [arrayPedido, SetArrayPedido] = useState([]);
    const [Showtodopedidos, SetShowtodopedidos] = useState(false);


    useEffect(() => {
        if (pedidos.length === 0 && !peticion_pedidos) {
            list_pedidos();
        } else {
            SetArrayPedido(pedidos);
        }
    }, [pedidos]);

    const ver_todo_togle = () => Showtodopedidos ? SetShowtodopedidos(false) : SetShowtodopedidos(true);

    return (
        <div className="relative">
            {
                arrayPedido.length > 0 &&
                <div className="btn_toggle_pedidos">
                    <button className="btn  text-white my-2 float-right" style={miEstilo}
                            onClick={() => ver_todo_togle()}>
                        {Showtodopedidos ? <div key="ocultar"> Ocultar </div> : <div key="abrir"> Abrir todo </div>}

                    </button>
                </div>
            }


            <div className="estilo_panel_detalle_pedido" key="estilo_panel_detalle_pedido">
                <div className="container_items_pedidos">
                    {
                        arrayPedido.length > 0 &&
                        arrayPedido.map((d, index) =>
                            <EstadoPedido
                                pedido={d}
                                key={index}
                                profile={profile}
                                showtodopedidos={Showtodopedidos}
                            ></EstadoPedido>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    profile: state.Profile.profile,
    pedidos: state.Pedido.pedidos,
    peticion_pedidos: state.Pedido.peticion_pedidos,
})

export default connect(mapStateToProps, {
    list_pedidos,
})(Pedidos)