import React,{useEffect,useState} from "react";
import QRCode from "qrcode"

const CreateQr =({ps_formData,ps_onloadQrUrl,ps_onloadQrDescrip}) =>{
    const  [qrValue, setQrValue]= useState("");
    const  [qrDescripcion, setQrDescripcion]= useState("");
    const  [qrImageUrl, setQrImageUrl]= useState("");

    useEffect(() => {
        console.log('generearqr',ps_formData);
        setQrValue(ps_formData.HashListaCompra);
        createQrcode(ps_formData.HashListaCompra);
        setDescripcion(ps_formData.Descripcion);
    }, []);

   
    async function createQrcode(urlValue){
        console.log("ingreavalue",urlValue);
        const response= await QRCode.toDataURL(urlValue);
        console.log('imgrqr',response);
        setQrImageUrl(response);
        ps_onloadQrUrl(response);
       
    }
    function setDescripcion(descripcion){
        let dataDescription= 'Qr'+descripcion.replace(/ /g, "")+'.png';
        setQrDescripcion(dataDescription);
        ps_onloadQrDescrip(dataDescription);
    }
   

    return (
        <>
            <div>
                {qrImageUrl &&
                    <a href={qrImageUrl} download={qrDescripcion}>
                        <img src={qrImageUrl} alt="QR code" />
                    </a>
                    
                }
                
            </div>
        </>
    )
}
export default CreateQr