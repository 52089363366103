import React, {useEffect, useState, Fragment} from 'react'
import {connect} from "react-redux";
import background_white from "../../assets/images/home/fondowhite.jpg";
import {useNavigate} from "react-router-dom";
import {Menu, Popover, Transition} from "@headlessui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faHeart} from "@fortawesome/free-solid-svg-icons";
import {get_notificaciones, delete_notificacion, show_notification} from "../../redux/actions/notification";
import firebase_noti from '../../Firebase3';
import {requestForToken, onMessageListener} from '../../Firebase3';
import ButtonText from "../../widgets/button_text";
import '../../assets/styles/globals.scss';

function Notificacion({get_notificaciones, delete_notificacion, show_notification, notificaciones, profile}) {
    const navigate = useNavigate();
    const [notification, setNotification] = useState({title: '', body: ''});
    const [nro_notificaciones, setNro_notificaciones] = useState(0);

    useEffect(() => {
        if (!notificaciones) {
            // if (true) {
            get_notificaciones();

            /* onMessage(messaging,message=>{
                 console.log("tu mensaje ",message);
                 alert(message.notification.tittle);
             }) */
            requestForToken();

            onMessageListener()
                .then((payload) => {
                    console.log("payload_firebase", payload);
                    setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
                })
                .catch((err) => console.log('failed: ', err));

            const databaseRef = firebase_noti.database().ref('notificaciones/' + profile.IdUsuario);
            //  const databaseRef = firebase.database().ref('notificaciones/NzY0' );

            const handleDataChange = (snapshot) => {
                // console.log("change notificaciones ", snapshot.val());
                // const dataFromFirebase = snapshot.val();
                //     search_pedido(snapshot.val(),pedido.NroPedido);
                if (snapshot.val() != null) {
                    colocar_numero_notificaciones(snapshot.val());

                    const title = '¡Cambio en la base de datos!';
                    const options = {
                        body: 'Se ha producido un cambio en los datos.',
                    };
                    navigator.serviceWorker.ready.then(registration => {
                        registration.showNotification(title, options);
                    });
                }
            };
            databaseRef.on('value', handleDataChange);
            return () => {
                databaseRef.off('value', handleDataChange);
            };
        }
    }, []);


    function colocar_numero_notificaciones(objeto) {
        const dato = Object.values(objeto);
        // console.log("datos selc ", dato[0].CantidadTotal);

        setNro_notificaciones(dato[0].CantidadTotal);
    }

    const rechazar = async (notificacion_id) => {
        await delete_notificacion(notificacion_id);
    };
    const show_notificacion = async (notificacion_id, entidad) => {
        show_notification(notificacion_id);
        navigate(`/lista_compra_compartida/${entidad}`);
    };

    const text_notificaciones = () => {
        return '3ddd';
        if (notificaciones.length === 0) {
            return 'No tienes notificaciones pendientes.';
        }else {
            return '';
        }
    }

    return (
        <>
            <Popover className="relative inline self-center ">
                {({open}) => (
                    <>
                        <Popover.Button
                            className="text-white btn   position-relative p-0"
                        >
                            {
                                notificaciones.length === 0 ?
                                    <FontAwesomeIcon icon={faBell} className=" px-2 icon_cotificacion" title="no tiene notificación pendiente"
                                                     style={{color:'#0349AB'}}/>
                                    :
                                    <FontAwesomeIcon icon={faBell} className="text-white px-2"
                                                     style={{color:'#0349AB'}}/>
                            }

                            <span
                                className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {nro_notificaciones > 0 ?
                                (<>{nro_notificaciones}</>) : <></>
                            }
                        </span>
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Popover.Panel className="absolute right-0 md:left-0 mt-2 px-4 z-20">
                                <div
                                    className="overflow-hidden shadow-lg w-80 z-20 ring-1 ring-black rounded-b-2xl">

                                    {
                                        notificaciones &&
                                        notificaciones !== null &&
                                        notificaciones !== undefined &&
                                        notificaciones.map((notificacion, index) =>
                                            <div key={index}>
                                                <div className="bg-white flex py-2 px-3 border-b-2 border-green-500/30">
                                                    <div>
                                                        <div className="grid grid-rows-3 overflow-hidden">
                                                            <div>
                                                                <FontAwesomeIcon icon={faHeart}
                                                                                 className="text-hiperorange px-1"
                                                                                 style={{fontSize: '20px'}}/>
                                                                <h3 className="text-xs font-bold text-hiperblue inline">
                                                                    {notificacion.Titulo}
                                                                </h3>

                                                            </div>
                                                            <div className="text-xs font-light text-hiperblue">
                                                                {notificacion.Mensaje}
                                                            </div>
                                                            <div className="text-xs font-bold text-hiperblue">
                                                                {notificacion.Fecha}
                                                            </div>


                                                            <div className="flex justify-end mb-2 text-white">
                                                                <ButtonText
                                                                    label='Rechazar'
                                                                    className="bg-red-600 w-[120px]"
                                                                    onClick={() => rechazar(notificacion.Id)}
                                                                />
                                                                <ButtonText
                                                                    label='Ver'
                                                                    className="bg-[#84B111] w-[120px]"
                                                                    onClick={() => show_notificacion(notificacion.Id, notificacion.Entidad)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>

        </>
    );
}

const mapStateToProps = state => ({
    notificaciones: state.Notification.notificaciones,

})

export default connect(mapStateToProps, {
    get_notificaciones, delete_notificacion, show_notification
})(Notificacion)