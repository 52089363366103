import React,{useState} from "react";
import {connect} from "react-redux";
import {actualizar_lista_compra, store_lista_compra} from "../../../redux/actions/shoppingList";
import {useForm} from "react-hook-form";
import {Circles} from "react-loader-spinner";
import ButtonClose from "../../../widgets/button_close";
import ButtonText from "../../../widgets/button_text";
import "../../../assets/styles/responsive.scss"

const Contenido =({info,opcion,actualizar_lista_compra,store_lista_compra
    ,formData,setFormData,ps_closeModalEdit,ps_closeModalEstore,loading
}) => {

    const [validateColor, setValidateColor] = useState(false);

    const listColor=[
        {id:1, nombreColor:'Azul', color:'#2E9BFF'},
        {id:3, nombreColor:'Rojo', color:'#F15353'},
        {id:2, nombreColor:'Amarillo', color:'#FCC12E'},
        {id:4, nombreColor:'Verde', color:'#53DD12'},
        {id:5, nombreColor:'Naranja', color:'#CB6E18'},
        {id:6, nombreColor:'Morado', color:'#826882'},
        {id:7, nombreColor:'Magenta', color:'#CE45E4'},
        {id:8, nombreColor:'Celeste', color:'#5CC4E4'},
        
    ];
    const { register, handleSubmit, formState: { errors } } = useForm();
    const messages = {
        required: "Este campo es obligatorio",
        minLength:"No puede ingresar menos de 3 caracteres en este campo",
        maxLength:"No puede ingresar más 30 caracteres en este campo",
        espacioBlanco:'No es permitido espacios en blanco',
        requiredColor:'Debe seleccionar un color'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
    };
    function handleChange(event) {
        // console.log("hello", event)

    }
    function verifyOpcion(){
        if(opcion ==='nuevo'){
            return true;
        }else{
            return false;
        }
    }
    function selectColor(color){
        setFormData({'Id':formData.Id,'Descripcion':formData.Descripcion,'Color':color});
    }

const onSubmit = (listinfo) =>{
    let descripcion=  listinfo.Descripcion;
    if(formData.Color!='' && descripcion!=''){
        actualizarListaCompra(descripcion);
    }else{
        setValidateColor(true);
    }

    };
function actualizarListaCompra(dtoDescripcion){
        const datos = {
            "Id":formData.Id,
            "Descripcion" :dtoDescripcion,
            "Color" : formData.Color,
            "Productos":[],
        }
        async function guardar() {
            try {
              await actualizar_lista_compra(datos,[],null);
              await ps_closeModalEdit();
            } catch (e) {
                console.error(e);
            }
        };
    guardar();
}

function guardarListaCompra(){
    const datos = {
        "Descripcion" : formData.Descripcion,
        "Color" : formData.Color,
        "Productos":[],
    }
    function guardar() {
        try {
            store_lista_compra(datos);
            ps_closeModalEstore();
        } catch (e) {
            console.error(e);
        }
    };
    guardar();
}




    return (
        <>
            <div className="w-[18rem] rounded-hiper_g overflow-hidden">
                {/* <div className="flex justify-end">
                    <ButtonClose  onClick={() => ps_closeModalEdit()}/>
                </div> */}
                <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                    <h2 className='p-0 m-0 title_carrito_responsivo'>{info}</h2>
                    <div className="absolute right-2 ">
                        <ButtonClose className="text-white bg-transparent"  onClick={() => ps_closeModalEdit()} />
                    </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                    {info}</h1> */}
                    <div className="grid grid-rows-1 gap-3 p-3">
                        <div className="W-100">
                            <label htmlFor="Descripcion" className="block fontzize_label text-black mb-2">
                            Nombre de la lista
                            </label>
                            <div className="mt-1">
                                <input
                                    id="Descripcion"
                                    name="Descripcion"
                                    type="text"
                                    autoComplete="Descripcion"
                                    placeholder="Nombre de la lista"
                                    // onChange={(e)=>setFormData({...formData, Descripcion:e.target.value})}
                                    // value={formData.Descripcion}  
                                    defaultValue={formData.Descripcion}
                                    className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    {...register("Descripcion",{ 
                                        required:{
                                          value:true,
                                          message:messages.required,
                                        },
                                        minLength: {
                                          value: 3,
                                          message: messages.minLength
                                        },
                                        maxLength: {
                                          value: 50,
                                          message: messages.maxLength
                                        },
                                        pattern: {
                                          value: patterns.espacioBlanco,
                                          message: messages.espacioBlanco
                                        }

                                    })}

                                />
                                {errors.Descripcion && <small className="text-red-600">{errors.Descripcion.message}</small> }
                            </div>
                        </div>
                        <div>
                            <label htmlFor="color" className="block fontzize_label text-black mb-2">
                                Seleccione el color
                            </label>
                            <div className="flex justify-center  border_black rounded-hiper_g px-2 py-4">
                                <div className="grid grid-cols-3 grid-rows-3 gap-2 ">
                                    {listColor.map((item_list)=>
                                        // <div className="w-11 h-11 rounded-md" key={item_list.id}
                                        <div className="w-11 h-11 rounded-md border-2 cursor-pointer" key={item_list.id}
                                        // <div  key={item_list.id}
                                        style={{backgroundColor: item_list.color,
                                            borderColor:item_list.color===formData.Color ? "#000000":item_list.color
                                        }}
                                        onClick={() => selectColor(item_list.color)}
                                        ></div> 
                                            
                                    )}
                                </div>
                            </div>
                            <div className="mt-2">
                                {validateColor && <small className="text-red-600">{messages.requiredColor}</small>} 
                            </div>

                        </div>
                        <div >
                            {verifyOpcion() ?(
                                // <button 
                                //     className="bg-hiperblue px-4 rounded-lg border-hiperblue border-2 text-white w-full mt-5"
                                //     onClick={guardarListaCompra}
                                //     >
                                //     Crear
                                // </button>
                                  <ButtonText
                                  label='Crear'
                                  principal={false}
                                  className="bg-hiperblue text-white mb-2 hover:bg-hiperblue_hover"
                                  onClick={() =>guardarListaCompra()}
                                />
                                ):(
                                   
                                    <ButtonText
                                        type="submit"
                                        label='Actualizar'
                                        principal={false}
                                        className="bg-hiperblue text-white mb-2 hover:bg-hiperblue_hover"
                                        onClick={() =>{}}
                                        />
                   
                                )
                            
                            }

                        </div>

                    </div>
                </form>
            </div>
            

        </>

    );
};
// export default Contenido;

const mapStateToProps = state => ({
    loading: state.Shopping_list.loading,
})

export default connect(mapStateToProps, {
    actualizar_lista_compra,store_lista_compra
})(Contenido)