import React, {Fragment, useState,useEffect} from 'react';
import {Link,useNavigate,useParams} from "react-router-dom";
import Layout from '../../hocs/Layout';
import background_white from "../../assets/images/home/fondowhite.jpg";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnglesRight,
    faCartShopping, faMagnifyingGlass,
    faPenToSquare, faQrcode, faShareNodes,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import qr from "../../assets/images/home/qr.png";
import {Popover, Transition} from "@headlessui/react";

import {
    faFacebook,
    faGooglePlus,
    faInstagram,
    faSnapchat,
    faTelegram,
    faTelegramPlane
} from "@fortawesome/free-brands-svg-icons";
import ItemEstadoPedido from "../../widgets/item_estado_pedido";
import ItemDetallePedido from "../../widgets/item_detalle_pedido";
import ItemFavorito from "../../widgets/item_favorito";
import ItemListaCompra from '../../widgets/item_lista_compra';
import ItemTarjeta from '../../widgets/item_tarjeta';
// import para realizar peticiones
import { connect } from "react-redux";
import { get_tarjeta ,delete_tarjeta} from '../../redux/actions/tarjeta';

import classNames from "classnames";
import Swal from 'sweetalert2';
import BackToPage from "../../components/BackPage/BackToPage"
import  "../../assets/styles/globalColorPrincipal.css";
import  "../../assets/styles/stylePageGlobals.scss";
import ButtonText from "../../widgets/button_text";
import Alert from "../../components/Swalert";

const containerStyle2 = {
    position: 'fixed',
    inset: '0px',
    backgroundImage:
        `url(${background_white})`,
    width: "100%",
};

const customStylesFondo = {
    overlay: {
        backgroundColor: '#000000a6',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0.75rem',
        overflow: 'visible',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};



    

const ListaMisTarjetas = ({get_tarjeta,tarjetas,delete_tarjeta,profile}) => {

    const [modalPurchase, setPurchase] = React.useState(false);
    const [modalAddListPurchase, setAddListPurchase] = React.useState(false);
    const [modalEditListPurchase, setEditListPurchase] = React.useState(false);


    const startPurchase = () => setPurchase(true);
    const endPurchase = () => setPurchase(false);
    const startAddListPurchase = () => setAddListPurchase(true);
    const startEditListPurchase = () => setEditListPurchase(true);

    const endAddListPurchase = () => setAddListPurchase(false);
    const endAdEditListPurchase = () => setEditListPurchase(false);

    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();
    const [Info, setInfo] = useState('');

    // const [infoModal, setNombres] = useState('');
    // const formDato={

    // }
    // const [Id, setId] = useState('');
    // const [Descripcion, setDescripcion] = useState('');
    // const [Color, setColor] = useState('');
    // const [ImporteTotal, setImporteTotal] = useState('');

    const [formData, setFormData] = useState({
        Id: '',
        Descripcion: '',
        Color:'',
        ImporteTotal:'',
    })

    const infoModal={
        title_new:'Crear nueva lista',
        title_edit:'Editar lista',
        // "title":'Editar lista'
    }

    function setSelectedList(opcion){
        startEditListPurchase();
        setFormData({'Id':opcion.Id,'Descripcion':opcion.Descripcion,'Color':opcion.Color});
    }
    function closeModal(){
        endAdEditListPurchase();
        fetchData();
        
    }
    function eliminarTarjeta(Id){
        Swal.fire({
            title: 'La tarjeta seleccionada será eliminada.¿Está seguro que desea eliminar?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#32338E',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteTarjeta(Id); 
            }
        })
      
    }
    async function deleteTarjeta(id){
        await delete_tarjeta(id);
        await obtenerDatos();
    }

    // const deleteListaCompra = ()=>{
    //     eliminar_lista_compra(Id);
    // }


    useEffect(() => {
        // fetchData();
        obtenerDatos()
    }, [profile]);
    const obtenerDatos= async () => {
        get_tarjeta()
    }

    async function fetchData() {
        try {
            // if(verifyList()){
                // get_lista_compra()
            // }

        } catch (e) {
            console.error(e);
        }
    };
    function verifyList(){
        // if(mycard_list === null){
        //     return true;
        // }
        // return false;
    }
    function getFormatoFecha(fecha){
        let fechaSub= fecha.substring(0, 10);
       return fechaSub;
    }
    async function registrar_nuevo(){
        let param = await get_url();
        navigate(param+"/nueva_tarjeta");
    }

    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/"+ciudadp+"/"+sucursalp);
        } else if (url_nav) {
            return ("/"+url_nav.url_parameters);
        }
    }


    return (
        <Layout>
            <Alert></Alert>
            <div className="bg_image_page" style={{minHeight:'45rem'}}> 
                <div className='content_info pt-4'>
                    <BackToPage ps_url_page={''}></BackToPage>
                    <div className="container">
                        <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                            Mis tarjetas</h1>
                        <div className="flex justify-center">
                            <div className=" w-[35rem] h-[30rem] border border-hiperorange bg-white shadow rounded-xl">
                                <div className="flex justify-between flex-column h-100" style={{paddingBottom:'1rem'}}>
                                    <div className="h-[calc(100%-5.5rem)] overflow-y-auto px-4 pt-3">
                                        <div className=" flex flex-col gap-2" >

                                            <div className='text-center text_primario'>
                                                <label className='fontzize_label text-black'>Listado de tarjetas</label>
                                            </div>
                                            {/* [8rem] */}
                                            <div className=' flex justify-center flex-wrap gap-2'>
                                            {
                                                tarjetas!=null &&
                                                tarjetas.map((item_card,index)=>
                                                    <ItemTarjeta 
                                                    key={index}
                                                    ps_tarjeta={item_card}
                                                    ps_deleteTarjeta={eliminarTarjeta}
                                                    // ps_get_tarjeta={carg}
                                                    >

                                                    </ItemTarjeta>
                                                
                                                )
                                            
                                            }

                                            </div>

                                        </div>
                                    </div>
                                    <div className="h-[5.5rem] flex justify-around flex-column px-4">    
                                    
                                        {/* <Link to="/nueva_tarjeta"  
                                            className="bg_secundario rounded-lg text-center py-2 border-2 text-white"
                                            
                                            >
                                            Registrar nueva tarjeta
                                        </Link>    */}

                                        <ButtonText
                                                type='button'
                                                label='Registrar nueva tarjeta'
                                                principal={true}
                                                className="bg-hiperblue text-white hover:bg-hiperblue_hover"
                                                onClick={() =>registrar_nuevo()}
                                            />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
                                            
        





        </Layout>
);
};

const mapStateToProps = state => ({
    tarjetas: state.Tarjeta.tarjetas,
    profile: state.Profile.profile,
})

export default connect(mapStateToProps, {
    get_tarjeta,delete_tarjeta
})(ListaMisTarjetas)

// export default PurchaseList;