import {
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAIL,
    GET_PRODUCT_ITEM_SUCCESS,
    GET_PRODUCT_ITEM_FAIL,
    GET_PRODUCT_SECCION_SUCCESS,
    SET_LOADING,
    REMOVE_LOADING,
    CLEAR_DATA,
    CLEAR_ITEM_PRODUCTO,
    SET_SEARCH_SUCCESS,
    CLEAR_SEARCH_SUCCESS,

} from './types'
import axios from 'axios';
import {end_peticion, start_peticion} from "./peticion";
import {setSwAlert,showRespuesta} from "./swalert";
import respuestas from '../../components/Alertas';
export const set_search = (value) => async dispatch => {
    dispatch({
        type: SET_SEARCH_SUCCESS,
        payload:value
    });
};
export const limpiar_search = () => async dispatch => {
    dispatch({type: CLEAR_SEARCH_SUCCESS});
};



export const limpiar_array_producto = () => async dispatch => {
    dispatch({type:CLEAR_DATA});
};

export const limpiar_item_producto = () => async dispatch => {
        dispatch({type:CLEAR_ITEM_PRODUCTO});
};


export const get_producto = (datos,page) => async dispatch => {
     console.log("pasoo ..",datos);
    dispatch(start_peticion('registrando...'));
    if(page===1){

        dispatch({type:CLEAR_DATA});
    }
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    let cantidad=50;
    try {
        var res=null;
         if(datos.texto_filtro!=="" && datos.texto_filtro!==undefined){
             if(datos.IdCategoria!==null && datos.IdSubcategoria!==null){
                res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?IdMarket=${datos.IdMarket}&IdLocatario=${datos.IdLocatorio}&Descripcion=${datos.texto_filtro}&IdCategoria=${datos.IdCategoria}&IdsSubcategoria[0]=${datos.IdSubcategoria}&Pagina=${page}&Cantidad=${cantidad}`, config);
             }
             else if(datos.IdCategoria!==null){
                res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?IdMarket=${datos.IdMarket}&IdLocatario=${datos.IdLocatorio}&Descripcion=${datos.texto_filtro}&IdCategoria=${datos.IdCategoria}&Pagina=${page}&Cantidad=${cantidad}`, config);
                // res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?IdMarket=${datos.IdMarket}&IdLocatario=${datos.IdLocatorio}&Descripcion=${datos.texto_filtro}&IdCategoria=${datos.IdCategoria}&IdsSubcategoria[0]=${datos.IdSubcategoria}&Pagina=${page}&Cantidad=${cantidad}`, config);
             }else{

                res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?IdMarket=${datos.IdMarket}&IdLocatario=${datos.IdLocatorio}&Descripcion=${datos.texto_filtro}&Pagina=${page}&Cantidad=${cantidad}`, config);
             }
           
         }else{
            if(datos.IdCategoria!==null && datos.IdSubcategoria!==null){
                res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?IdMarket=${datos.IdMarket}&IdLocatario=${datos.IdLocatorio}&IdCategoria=${datos.IdCategoria}&IdsSubcategoria[0]=${datos.IdSubcategoria}&Pagina=${page}&Cantidad=${cantidad}`, config);
            }
            else if(datos.IdCategoria!==null){
                res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?IdMarket=${datos.IdMarket}&IdLocatario=${datos.IdLocatorio}&IdCategoria=${datos.IdCategoria}&Pagina=${page}&Cantidad=${cantidad}`, config);
            }else {
                res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?IdMarket=${datos.IdMarket}&IdLocatario=${datos.IdLocatorio}&Pagina=${page}&Cantidad=${cantidad}`, config);
            }
            
         }
        if (res.status === 200) {
            dispatch({
                type: GET_PRODUCT_SUCCESS,
                payload:res.data
            });
        } else {
            dispatch({
                type: GET_PRODUCT_FAIL
            });

            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());
    } catch (err) {
        dispatch({
            type: GET_PRODUCT_FAIL
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const get_all_producto = (datos,page) => async dispatch => {
    // if(page===1){
    //     dispatch({type:CLEAR_DATA});
    // }
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    let cantidad=50;
    try {
        var res= null;
        if(datos.texto_filtro!==""){
           res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?IdMarket=${datos.IdMarket}&Descripcion=${datos.texto_filtro}&IdLocatario=${datos.IdLocatorio}&IdCategoria=${datos.IdCategoria}&Pagina=${page}&Cantidad=${cantidad}`, config);
         }else{
            res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?IdMarket=${datos.IdMarket}&IdLocatario=${datos.IdLocatorio}&IdCategoria=${datos.IdCategoria}&Pagina=${page}&Cantidad=${cantidad}`, config);
         }
        // console.log("recuperando datos axios por categoria ", res);
        if (res.status === 200) {
            dispatch({
                type: GET_PRODUCT_SUCCESS,
                payload:res.data
            });
        } else {
            dispatch({
                type: GET_PRODUCT_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch({type: REMOVE_LOADING});
    } catch (err) {
        dispatch({
            type: GET_PRODUCT_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const get_filtro_productos_categoria = (datos) => async dispatch => {
    dispatch({type:CLEAR_DATA});
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
       const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?IdCategoria=${datos.categoria_id}&IdMarket=${datos.IdMarket}&Descripcion=${datos.filtro_text}&IdLocatario=${datos.IdLocatario}`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_PRODUCT_SUCCESS,
                payload:res.data
            });
        } else {
            dispatch({
                type: GET_PRODUCT_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        dispatch({
            type: GET_PRODUCT_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
// get_filtro_productos
export const get_filtro_productos = (datos) => async dispatch => {
   
    dispatch({type: SET_LOADING});
    dispatch({type:CLEAR_DATA});
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
       const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/productos?&IdMarket=${datos.IdMarket}&Barra=${datos.filtro_codigo}&IdLocatario=${datos.IdLocatario}`, config);                                                                                                                                                                                                                                                                    
                                                                                 

        if (res.status === 200) {
            dispatch({
                type: GET_PRODUCT_SUCCESS,
                payload:res.data
            });
        } else {
            dispatch({
                type: GET_PRODUCT_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch({type: REMOVE_LOADING});
    } catch (err) {
        dispatch({
            type: GET_PRODUCT_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const get_producto_item = (IdSucursal,Idproducto) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {

        let url = `${process.env.REACT_APP_API_URL}/api/v1/productos/${Idproducto}?IdSucursal=${IdSucursal}`;
        const res = await axios.get(url, config);
        if (res.status === 200) {
            dispatch({
                type: GET_PRODUCT_ITEM_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_PRODUCT_ITEM_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;

        }
    }catch (err) {
        dispatch({
            type: GET_PRODUCT_ITEM_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

export const get_producto_seccion = (datos,page) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        let api_seccion=datos.ApiSeccion;
        let IdSucursal= datos.IdSucursal;
        let pagina=page;
        let cantidad=datos.Cantidad;
        let seccion_id= datos.SeccionId; //oferta controlar respuesta
        let url = `${process.env.REACT_APP_API_URL}/api/v1/${api_seccion}?IdSucursal=${IdSucursal}&Pagina=${pagina}&Cantidad=${cantidad}`;

        const res = await axios.get(url , config);
        
        if (res.status === 200) {
            dispatch({
                type: GET_PRODUCT_SECCION_SUCCESS,
                payload:{res:res.data,seccionId:seccion_id}
            });
        } else {
            dispatch({
                type: GET_PRODUCT_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;

        }
    } catch (err) {
        dispatch({
            type: GET_PRODUCT_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

