import React,{useState,useRef,useCallback}   from "react";
import Layout from "../../hocs/Layout";
import '../../assets/styles/scanear.scss';
import { useNavigate,useParams } from "react-router-dom";
import {useDropzone} from 'react-dropzone'
import "../../assets/styles/globalColorPrincipal.css"
 import QrReader from 'react-qr-scanner'
 import QrScanner from 'qr-scanner';
import ButtonText from "../../widgets/button_text";
import Alert from "../../components/Swalert";

 const state= { 
    delay: 2000,
    result: '',
    statecamara:false,
};

 const Escanear = (props) => {
    const [file, setFile]= useState(null);
    const [fileName, setFileName]= useState(null);
    const [data, setData]= useState(null);
    const fileRef = useRef();
    const {ciudadp, sucursalp} = useParams();
    const navigate = useNavigate();


    const handleChange = async(e)=>{
        console.log("ingre");
        const fileImg = e.target.files[0];
        console.log(fileImg);
        setFile(fileImg);
        setFileName(fileImg.name);
        const result = await QrScanner.scanImage(fileImg);
        console.log(result);
    }
    const handleClick = ()=>{
        fileRef.current.click();
    }

    const [show, setShow] = useState(false);    

    const cambiarestado = () => {
        setShow(show => (!show));
        // console.log("lego "+show);
    };
    const handleScan= function(data){
        console.log("encontro ",data);
             if(data!=null){
                setShow(show => (!show));
                /*
                recupera el texto qr
               state.result=data.text;
               */
             }
       // setShow(show => (!show));
     };
     
      const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        const fileImgUno = acceptedFiles[0];

        cargarImgQr(fileImgUno);

      }, [])

      async function cargarImgQr(fileImg){

        console.log("fileImg",fileImg);
        setFile(fileImg);
        setFileName(fileImg.name);
        const result = await QrScanner.scanImage(fileImg);
        console.log("result",result);
        let param = await get_url();
        navigate(param+`/lista_compra_compartida/${result}`);
      }
      const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

     function volver(){
        //  navigate("/lista_compras");
         window.history.back();
     }

     const handleError= function(data){
        // console.log(data);
       
     };
     const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/"+ciudadp+"/"+sucursalp);
        } else if (url_nav) {
            return ("/"+url_nav.url_parameters);
        }
    }
    return (
        <Layout>
            <Alert></Alert>
            
            <div className="fondo_scanear ">
                <div className="container_scan">
                    <div className="titulo_scanear  mb-4"> 
                        <span className="text-4xl">  Scanear código</span>
                    </div>
                    <div className="cuadro_scan">
                        <div className="body_scan">
                            { show?(<QrReader
                                    delay={state.delay}
                                    style={{with:"320px", height:"240px"}}
                                    onError={handleError}
                                    onScan={handleScan}
                                    // showViewFinder={show}
                            />):<>
                                <div {...getRootProps()} className="flex w-[100%] h-[100%]  borde_subrayado">
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                        <p>Suelte los archivos aquí ...</p> :
                                        <p>Subir o arrastrar la imagen aquí</p>
                                    }
                                </div>
                                </>
                            } 
                            {/* <p>resul {state.result}</p>                                   */}
                        </div>
                        <div className="flex mb-1">
                            <aside>
                                {/* <ul>{files}</ul> */}
                                <ul>{fileName}</ul>
                            </aside>
                        </div>
                        <div className="footer_scan d-flex justify-around flex-column items-center px-4">   
                            <div className="  d-flex justify-around flex-column gap-2 px-2 text-white">
                                {/* <ButtonIcon
                                    icon={faCamera}
                                    label='Tomar foto'
                                    principal={false}
                                    className={'bg-[#84B111] w-[300px]'}
                                    onClick={() => cambiarestado()}
                                /> */}
                                <ButtonText
                                    label='Cancelar'
                                    principal={false}
                                    className="bg-hiperblue hover:hiperblue_hover w-[250px]"
                                    onClick={() => volver()}
                                />
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
        </Layout>
    );
};

<Escanear/>

 export default Escanear;