import moment from 'moment';
import axios from "axios";

export const huellaDigital = async (userCode) => {
    const huella = await uniqueTransactionCodeGenerator(userCode);
    var huella_id = `${process.env.REACT_APP_ID_SESSION}` + huella;
    return huella_id;
    const config = {
        headers: {}
    };
    const aux = await axios.get(`https://h.online-metrix.net/fp/tags.js?org_id=${process.env.REACT_APP_ORGANIZACION_ID}&session_id=${huella_id}`);
    if (aux.status === 200) {
        return huella_id;
    } else {
        return null;
    }
}

const generateRandomLetter = () => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz"
    return alphabet[Math.floor(Math.random() * alphabet.length)]
};
const uniqueTransactionCodeGenerator = (userCode) => {
    try {
        let dateString = moment().utcOffset('-0400').format('DDMMYYYYHHmmssSSS');
        let randomLetter = generateRandomLetter()
        let idSession = userCode + dateString + randomLetter;
        return idSession
    } catch (e) {
        console.log("Error en idSessionGenerator, global.js. " + e)
    }
}