import React, {useEffect, useState} from 'react';
import '../../assets/styles/hiper.scss'
import logo from '../../assets/images/login/logo.png';
import background from '../../assets/images/branch_office/sucursal.jpg';
import Modal from "react-modal";
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import {Pagination, Navigation} from "swiper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faMotorcycle,
    faStore,
    faArrowLeft,
    faChevronDown,
    faQrcode
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

import '../../assets/styles/mystyle.scss'
import {
    get_sucursales, get_sucursales_cerca, set_sucursal, set_tipo_entrega,
    get_ciudades, set_ciudad
} from "../../redux/actions/branch_office";

import {get_ubicacion} from "../../redux/actions/location";
import {useNavigate} from "react-router-dom";
import {isPointWithinRadius} from "geolib";
import classNames from "classnames";
import {Circles} from "react-loader-spinner";
import "../../assets/styles/responsive.scss"
import Direccion from "../../components/modal/direccion";
import convertSlug from "../../components/Utils/slug";
import {update_url_navigation} from "../../redux/actions/url_navegacion";
import {info_session} from "../../redux/actions/profile";
import Alert from "../../components/Swalert";
import ButtonIcon from "../../widgets/button_icon";
import ButtonText from "../../widgets/button_text";
import {setSwAlert} from "../../redux/actions/swalert";
import {limpiar_carrito} from "../../redux/actions/carrito";

const containerStyle = {
    backgroundImage:
        `url(${background})`,
    width: "100%",
    // height: "92.5vh",
    minHeight: "100vh",
    height: "auto",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const customStyles = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '30',
    },
    content: {
        top: '45%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '10px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding:'0px',
        border:'none',
        background:'transparent'
    },
};

const containerStyle2 = {
    borderRadius: '2rem',
    width: '900px',
    overflow: 'auto',
    background: '#fff'
};
const breakpoints = {
    200: {
        slidesPerView: 1,
        spaceBetween: 10,
    },
    300: {
        slidesPerView: 1,
        spaceBetween: 10,
    },
    400: {
        slidesPerView: 1,
        spaceBetween: 10,
    },

    640: {
        slidesPerView: 2,
        spaceBetween: 10,
    },
    768: {
        slidesPerView: 2,
        spaceBetween: 10,
    },

    992: {
        slidesPerView: 2,
        spaceBetween: 10,
    },
    1200: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
    1400: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
};

const InicioSucursal = ({
                            setSwAlert,
                            profile,
                            info_session,
                            get_sucursales,
                            get_ubicacion,
                            get_sucursales_cerca,
                            set_tipo_entrega,
                            set_sucursal,
                            branch_offices,
                            sucursal,
                            locations,
                            direccion,
                            tipo_entrega,
                            sucursales,
                            limpiar_carrito,
                            location_omitido,
                            get_ciudades,
                            ciudades,
                            ciudad,
                            set_ciudad,
                            loading,
                            data_slug,
                            update_url_navigation,
                            url_navigation, peticion
                        }) => {
    const navigate = useNavigate();
    // const [sucursales, guardarSucursales] = useState([]);
    const [tipo_entregas, guardarTipoEntrega] = useState([]);
    const [estadoVerify, setEstadoVerify] = useState(false);
    const [estadoVerifyNext, setEstadoVerifyNext] = useState(true);
    const [sucursalPorCiudad, setSucursalPorCiudad] = useState([]);

    const [estadoVerifyDireccion, setEstadoVerifyDireccion] = useState(false);
    const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);
    const [sliderPages, setSliderPages] = useState(2);

    const [url_base, setUrl_base] = useState('');

    const [selectedCiudad, setSelectedCiudad] = useState({
        Nombre: ''
    });

    //START CODIGO DE DIRECCION
    const [modalAddress, setAddress] = useState(false);
    const [profileAux, setProfileAux] = useState(null);
    const startAddress = () => setAddress(true);
    const endAddress = () => setAddress(false);
    const actualizarAnchoPantalla = () => {
        setAnchoPantalla(window.innerWidth);
        setSliderPages(window.innerWidth > 630 ? 2 : 1);
    };

    useEffect(() => {
        if (profile) {
            cargar_datos_perfil();
            if (profile.Ubicaciones.length === 0) {
                get_sucursales();
            }
        } else {
            info_session()
        }
    }, [profile]);

    useEffect(() => {
        if (ciudades.length === 0) {
            getCiudades();
        }
        window.addEventListener('resize', actualizarAnchoPantalla);
    }, [ciudades]);

    useEffect(() => {
        // console.log("dire",direccion);
        if (typeof (direccion) !== 'undefined' && direccion !== null) {
            get_sucursales_cerca(direccion);
            set_tipo_entrega(null);
            set_sucursal(null);
        }
    }, [direccion]);

    useEffect(() => {
        verifyTipoEntrega();
    }, [tipo_entrega]);


    useEffect(() => {

        if (sucursal) {
            guardarTipoEntrega(sucursal.TipoEntregas);
            limpiar_carrito();
        }
        if (ciudades.length > 0 && sucursal) {
            let ciudad = ciudades.find(elem => elem.IdRegion === sucursal.idRegion)
            set_ciudad(ciudad);
        }
    }, [sucursal]);

    const onload_slug = async () => {
        // NOTA
        //datos para la url  ciudad:Nombre,sucursal:Descripcion

        let ciudad = await ciudadSeleccionado(sucursal.idRegion);
        let sucursal_select = await sucursalSeleccionado(sucursal.IdSucursal);

        let parametro_url = {
            ciudad: convertSlug.data_slug(ciudad),
            sucursal: convertSlug.data_slug(sucursal_select.Descripcion)
        };
        let resp = (parametro_url.ciudad + ' ' + parametro_url.sucursal);
        let new_url = convertSlug.data_barra(resp);

        let url_parameters = new_url;
        await update_url_navigation({url_parameters, id_sucursal: sucursal.IdSucursal, id_ciudad: sucursal.idRegion});

        return url_parameters;


        //    const url_actual= 'http://localhost:8000/sucursal'

        //    let url_update= convertSlug.data_barra(url_actual+' '+url_parameters);
        //    window.location.href=url_update;


    }

    function sucursalSeleccionado(IdSucursal) {
        let seleccionado = null;
        sucursales.forEach((sucursal) => {
            if (!seleccionado) {
                seleccionado = sucursal.Locatarios.find(pre => pre.IdSucursal === IdSucursal);
            }
        })
        return seleccionado;
    }

    function ciudadSeleccionado(id_region) {
        let seleccionado = ciudades.find(pre => parseInt(pre.IdRegion) === parseInt(id_region));

        return seleccionado.Nombre;
    }

    function cargar_datos_perfil() {
        if (profile.Ubicaciones.length > 0) {
            if (locations.length === 0) {
                get_ubicacion();
            }

        }
    }

    async function actualizarSucursal(item_sucursal) {
        set_sucursal(item_sucursal);
    }

    function guardarDireccionLocal() {
        localStorage.setItem("direccion", JSON.stringify(direccion));

    }

    async function continuar() {

        if (!sucursal) {
            setSwAlert('para continuar debo tener una sucursal seleccionada', false, 400);
            return;
        }

        if (!tipo_entrega) {
            setSwAlert('para continuar debo tener un modo de entrega seleccionada', false, 400);
            return;
        }

        if (direccion) {
            guardarDireccionLocal();
        }
        let url_bas = await onload_slug();
        setEstadoVerify(true);
        window.setTimeout(() => {
            setEstadoVerify(false);
            navigate("/" + url_bas);
        }, 3000);

    }

    function actualizarTipoEntrega(item_tipo_entrega) {
        set_tipo_entrega(item_tipo_entrega);
        // setEstadoVerify(true);
    }

    const getCiudades = async () => {
        await get_ciudades();
    }

    function verifyTipoEntrega() {
        if (tipo_entrega === null) {
            setEstadoVerifyNext(true);
        } else {
            setEstadoVerifyNext(false);
        }

    }

    function optionCiudad(ciudad) {
        setSelectedCiudad({Nombre: ciudad.Nombre, Latitud: ciudad.Latitud, Longitud: ciudad.Longitud});
        let direccion = {
            Latitud: ciudad.Latitud,
            Longitud: ciudad.Longitud
        }
        onloadSucursalesCiudad(branch_offices, direccion);
    }

    function onloadSucursalesCiudad(dato, direccion) {

        let arrayDato = dato.length;
        let array_aux = [];

        if (arrayDato > 0) {
            dato.forEach((sucursalz) => {
                let coberturaSucursal = null;
                coberturaSucursal = isPointWithinRadius(
                    {
                        latitude: parseFloat(sucursalz.UbicacionLatitud),
                        longitude: parseFloat(sucursalz.UbicacionLongitud)
                    }, //punto dentro del perimetro
                    {latitude: parseFloat(direccion.Latitud), longitude: parseFloat(direccion.Longitud)}, //punto centro desde
                    8000);

                if (coberturaSucursal) {
                    array_aux.push(sucursalz);
                }
            });
            if (array_aux.length == 0) {
                array_aux = dato;
            }
        }
        setSucursalPorCiudad(array_aux);
    }

    //START CODIGO DE DIRECCION


    return (
        <>

            <Alert></Alert>
            <div id="wrapper">
                <div className='shadow-md w-full static top-0 left-0'>
                    <div className='flex items-center justify-center bg-white py-2 md:px-10 px-7'>
                            <img src={logo} width="70"
                                 alt="logo"/>
                    </div>
                </div>

                <div className="main items-start justify-center pt-1" style={containerStyle}>
                    {
                        direccion === null &&
                        <div className='container flex mt-2 p-2 cursor-pointer'>
                            <Link to={'/ubicacion'} className="text-white">
                                <FontAwesomeIcon icon={faArrowLeft} style={{fontSize: '1.5rem'}}/>
                            </Link>
                        </div>
                    }
                    <div className='container container_sucursal_ini'>
                        <div
                            className='w-full flex gap-2 justify-center contenedor_flex contenedor_mobile items-center pt-4'>

                            {ciudades && !direccion &&
                                ciudades.length > 0 &&
                                ciudades.map((item_ciudad, index) =>
                                    <button key={index}
                                            className="rounded-full  hover:bg-[#32338E] hover:text-white p-1 px-4 min-w-[10rem]"
                                            style={selectedCiudad.Nombre === item_ciudad.Nombre ? {
                                                backgroundColor: '#32338E',
                                                color: 'white'
                                            } : {backgroundColor: 'white', color: '#7C7C7C'}}

                                            onClick={() => optionCiudad(item_ciudad)}>{item_ciudad.Nombre}
                                    </button>
                                )
                            }
                        </div>
                        <div className="flex flex-wrap justify-center contenedor_flex">
                            <h1 className="text-3xl font-inter font-normal text-center text-white my-4">Seleccione una
                                sucursal
                            </h1>

                            <div className='flex justify-center  w-100 contenedor_flex contenedor_sucursal_tipoentrega'>
                                <div className='w-auto contenedor_mobile_scroll'>

                                    <div className='containerStyle2Sucursal'>
                                        {
                                            direccion &&
                                            <div className='pt-2 pl-4 '>
                                                <div className="flex gap-1 text-hiperorange mb-0">Dirección: <span
                                                    className="text-hiperblue cursor-pointer no-underline hover:underline  "
                                                    onClick={startAddress}>{direccion != undefined && direccion != null &&
                                                    direccion.Descripcion + ' - ' + direccion.Direccion
                                                }
                                                    <FontAwesomeIcon
                                                        style={{width: '15px', height: '15px', marginLeft: '3px'}}
                                                        icon={faChevronDown}/>
                                            </span>
                                                    <Circles
                                                        color="#32338E"
                                                        width={23}
                                                        height={23}
                                                        ariaLabel="circles-loading"
                                                        visible={loading}
                                                    />
                                                </div>

                                            </div>
                                        }

                                        <Swiper
                                            // slidesPerView={sliderPages}
                                            // spaceBetween={30}
                                            // slidesPerGroup={2}
                                            loop={false}
                                            loopFillGroupWithBlank={true}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={true}


                                            modules={[Pagination, Navigation]}
                                            breakpoints={breakpoints}
                                            className="mySwiper bg-white rounded-3xl py-6 px-2"
                                            style={{
                                                "--swiper-navigation-color": "#32338E",
                                                "--swiper-navigation-size": "30px"
                                            }}
                                        >


                                            {selectedCiudad.Nombre === '' ? (

                                                sucursales &&
                                                sucursales !== null &&
                                                sucursales !== undefined &&
                                                sucursales.map((branch_office, index) =>
                                                    <SwiperSlide key={'c' + index}>
                                                        {/* container */}
                                                        <div className=" mx-auto flex justify-center">
                                                            <div
                                                                className="bg-zinc-200 max-w-sm border shadow-lg rounded-xl px-4 py-5">
                                                                <div className="font-inter font-bold text-center mb-2">
                                                                    {branch_office.Descripcion}
                                                                    <br/>
                                                                    {(branch_office.distancia)} Km
                                                                </div>
                                                                <div className="responsive_grid gap-4 ">
                                                                    {
                                                                        branch_office.Locatarios.map((locatario, index2) =>

                                                                            <div key={index2}
                                                                                 className={classNames("border", "rounded-2xl", "overflow-auto", "cursor-pointer",
                                                                                     {
                                                                                         "bg-[#DC842D]": locatario.IdSucursal == (sucursal && sucursal.IdSucursal),
                                                                                         "bg-white": locatario.IdSucursal != (sucursal && sucursal.IdSucursal),
                                                                                     })}
                                                                                 style={{minWidth: '120px'}}
                                                                                 id={locatario.IdSucursal}
                                                                                 onClick={() => actualizarSucursal(locatario)}>
                                                                                <img
                                                                                    src={locatario.TipoServicio.UrlFoto}
                                                                                    alt=""/>
                                                                                <div
                                                                                    className="w-100 bg-hiperblue text-white text-center py-2 "
                                                                                >
                                                                                    {locatario.TipoServicio.Descripcion}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )

                                            ) : (
                                                sucursalPorCiudad &&
                                                sucursalPorCiudad !== null &&
                                                sucursalPorCiudad !== undefined &&
                                                sucursalPorCiudad.map((branch_office, index) =>
                                                    <SwiperSlide key={'c' + index}>
                                                        <div className=" mx-auto ">
                                                            <div
                                                                className="bg-zinc-200 w-100 max-w-sm border shadow-lg rounded-xl px-4 py-5">
                                                                <div className="font-inter font-bold text-center mb-2">
                                                                    {branch_office.Descripcion}
                                                                    <br/>
                                                                    {(branch_office.distancia)} km
                                                                </div>
                                                                <div className="responsive_grid gap-4">
                                                                    {
                                                                        branch_office.Locatarios.map((locatario, index2) =>

                                                                            <div key={index2}
                                                                                 className={classNames("border", "rounded-2xl", "overflow-auto", "cursor-pointer",
                                                                                     {
                                                                                         "bg-[#DC842D]": locatario.IdSucursal == (sucursal && sucursal.IdSucursal),
                                                                                         "bg-white": locatario.IdSucursal != (sucursal && sucursal.IdSucursal),
                                                                                     })}
                                                                                 id={locatario.IdSucursal}
                                                                                 onClick={() => actualizarSucursal(locatario)}>
                                                                                <img
                                                                                    src={locatario.TipoServicio.UrlFoto}
                                                                                    alt=""/>
                                                                                <div
                                                                                    className="w-100 bg-[#32338E] text-white text-center py-2 "
                                                                                >
                                                                                    {locatario.TipoServicio.Descripcion}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            )


                                            }
                                        </Swiper>
                                    </div>

                                </div>
                                {tipo_entregas.length > 0 &&
                                    <div className="estilo_columna1_sucursal p-3 contenedor_mobile">
                                        <h1 className="text-2xl font-inter font-normal text-center mb-2 text-white my-1">Modo de entrega</h1>
                                        <div className="flex flex-col justify-center items-center m-auto gap-3 ">
                                            {
                                                tipo_entregas &&
                                                tipo_entregas !== null &&
                                                tipo_entregas !== undefined &&
                                                tipo_entregas.map((item_tipo_entrega, index) =>

                                                    <ButtonIcon
                                                        key={index}
                                                        icon={item_tipo_entrega.Id === 1 ? faStore : faMotorcycle}
                                                        label={item_tipo_entrega.Descripcion}
                                                        principal={false}
                                                        className={tipo_entrega && item_tipo_entrega.Id === tipo_entrega.Id ? "bg-hiperblue hover:bg-hiperblue_hover hover:text-white text-white btn_entrega" : "bg-white hover:bg-hiperblue_hover hover:text-white text-hipertext btn_entrega"}
                                                        onClick={() => actualizarTipoEntrega(item_tipo_entrega)}
                                                    />
                                                )
                                            }
                                            <div className="pt-4 w-full flex justify-center">

                                                <ButtonText
                                                    type='button'
                                                    label='Continuar'
                                                    className="bg-hipergreen hover:bg-hipergreen_hover border border-white text-white btn_entrega"
                                                    onClick={() => continuar()}
                                                />

                                                {/*<button*/}
                                                {/*    className="flex justify-center bg-[#84B111] w-full border-2 border-white md:w-[15rem] gap-2 text-white  font-inter font-light py-1 px-4 rounded-2xl "*/}
                                                {/*    type="button"*/}
                                                {/*    disabled={estadoVerifyNext}*/}
                                                {/*    style={{*/}
                                                {/*        cursor: estadoVerifyNext === false ? 'pointer' : 'not-allowed',*/}
                                                {/*        background: '#84B111'*/}
                                                {/*    }}*/}
                                                {/*    onClick={() => continuar()}>*/}
                                                {/*    <Circles*/}
                                                {/*        color="#fff"*/}
                                                {/*        width={23}*/}
                                                {/*        height={23}*/}
                                                {/*        ariaLabel="circles-loading"*/}
                                                {/*        visible={estadoVerify}*/}
                                                {/*    />*/}
                                                {/*    Continuar*/}
                                                {/*</button>*/}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal
                isOpen={modalAddress}
                ariaHideApp={false}
                // onAfterOpen={afterOpenModal}
                onRequestClose={endAddress}
                style={customStyles}
                contentLabel="address"
            >
                <Direccion close_modal={endAddress}/>
            </Modal>


        </>

    );
};


const mapStateToProps = state => ({
    profile: state.Profile.profile,
    branch_offices: state.BranchOffice.branch_offices,
    sucursal: state.BranchOffice.sucursal,
    direccion: state.Location.direccion,
    locations: state.Location.locations,
    location_omitido: state.Location.location_omitido,
    tipo_entrega: state.BranchOffice.tipo_entrega,

    sucursales: state.BranchOffice.sucursales,
    ciudades: state.BranchOffice.ciudades,
    ciudad: state.BranchOffice.ciudad,
    loading: state.Location.loading,
    peticion: state.Peticion.peticion,

    url_navigation: state.UrlNavegacion.url_navigation


})

export default connect(mapStateToProps, {
    info_session,
    get_sucursales,
    get_sucursales_cerca,
    set_sucursal,
    set_tipo_entrega,
    get_ubicacion,
    get_ciudades,
    set_ciudad,
    setSwAlert,
    limpiar_carrito,
    update_url_navigation
})(InicioSucursal)