import React,{useEffect,useState} from "react";

import {connect} from "react-redux";
import {actualizar_lista_compra, store_lista_compra} from "../../../redux/actions/shoppingList";
import classNames from "classnames";
import ItemListaCompra from '../../../widgets/item_lista_compra_list';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate,useParams } from "react-router-dom";
import {Circles} from "react-loader-spinner";
import {useForm} from "react-hook-form";
import "../../../assets/styles/responsive.scss"
import ButtonClose from "../../../widgets/button_close";
// import Alert from "../../components/Swalert";
const ContenidoNuevaLista =({info,opcion,ps_productos,actualizar_lista_compra,store_lista_compra
    ,ps_closeModal,ps_closeModalEstore,ps_lista_compra,ps_lista_compartida,ps_IdSucursal,loading
}) => {
    const {ciudadp, sucursalp} = useParams();
    
    const [formData, setFormData] = useState({
        // Id: '',
        Descripcion: '',
        Color:'',
    })
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const messages = {
        required: "Este campo es obligatorio",
        minLength:"No puede ingresar menos de 3 caracteres en este campo",
        maxLength:"No puede ingresar más 30 caracteres en este campo",
        espacioBlanco:'No es permitido espacios en blanco',
        requiredColor:'Debe seleccionar un color'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
    };

    useEffect(() => {
        console.log("nuevo lista compartida",ps_lista_compartida);
        cargarListaCompartida();

    },[opcion]);
    function cargarListaCompartida(){
        setFormData({Descripcion:ps_lista_compartida.Titulo,Color:ps_lista_compartida.Color});
    }
    const listColor=[
        {id:1, nombreColor:'Azul', color:'#2E9BFF'},
        {id:3, nombreColor:'Rojo', color:'#F15353'},
        {id:2, nombreColor:'Amarillo', color:'#FCC12E'},
        {id:4, nombreColor:'Verde', color:'#53DD12'},
        {id:5, nombreColor:'Naranja', color:'#CB6E18'},
        {id:6, nombreColor:'Morado', color:'#826882'},
        {id:7, nombreColor:'Magenta', color:'#CE45E4'},
        {id:8, nombreColor:'Celeste', color:'#5CC4E4'},
        
    ];
    function handleChange(event) {
        console.log(event.target.value);
    }
    function verifyOpcion(){
        if(opcion ==='nuevo'){
            return true;
        }else{
            return false;
        }
    }
  
    function selectColor(color){

        // setFormData({'Descripcion':formData.Descripcion,'Color':color});
        // console.log(formData);
        // setFormData((formData) => ({
        //     ...formData,
        //     ['Color']:color
        // }));

        setFormData((formData)=>({
            ...formData,
            Color:color
        }));

    }
    function delay(ms){
        return new Promise(resolve => setTimeout(resolve, ms))
    }
    const onSubmit = (listinfo) =>{
        let descripcion=  listinfo.Descripcion;
        if(formData.Color!='' && descripcion!=''){
            guardarListaCompra(descripcion);
        }

    };

    async function guardarListaCompra(description){
        console.log('se va guardar',ps_productos,'--',description);

        const datos = {
            "Descripcion" : description,
            "Color" : formData.Color,
            // "Productos":productos,
        }
        console.log(datos);
        await store_lista_compra(datos,ps_productos,ps_IdSucursal);
        await ps_closeModal();
        await delay(2000);
        // navigate("/lista_compras");
        navigate_menu('lista_compras');
        
    }

    async function navigate_menu(url_menu) {
        let param = await get_url();
        navigate(param + '/' + url_menu);
    }
    
    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/" + ciudadp + "/" + sucursalp);
        } else if (url_nav) {
            return ("/" + url_nav.url_parameters);
        }
    }


    return (
        <>
            <div className="content_modal rounded-hiper_g overflow-hidden">
                <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                    <h2 className='p-0 m-0 title_carrito_responsivo'>{info}</h2>
                    <div className="absolute right-2 ">
                        <ButtonClose className="text-white bg-transparent"  onClick={() => ps_closeModal()} />
                    </div>
                </div>

                {/* <div className="flex justify-end">
                        <FontAwesomeIcon icon={faClose}
                        onClick={ps_closeModal}
                            className='bg-white text_primario p-1   w-[1.3rem] h-[1.3rem] cursor-pointer'
                            style={{borderRadius:'50%'}}
                        /> 
                    </div> */}
                <div className="contenedor_nuevo p-3">

                    <div className="contenido_form">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <label htmlFor="email" className="block fontzize_label text-black ">
                                Nombre de la lista:
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="Descripcion"
                                        name="Descripcion"
                                        type="text"
                                        autoComplete="Descripcion"
                                        placeholder="Nombre de la lista"
                                        // value={onEditDescripcion}
                                        // onChange={handleChange} 
                                        // onChange={(e)=>setFormData({...formData, Descripcion:e.target.value})}
                                        // value={formData.Descripcion} 
                                        defaultValue={formData.Descripcion} 
                                        required
                                        className="appearance-none block w-full px-3 py-1 border border-black rounded-md  text-black placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 "
                                        {...register("Descripcion",{ 
                                            required:{
                                            value:true,
                                            message:messages.required,
                                            },
                                            minLength: {
                                            value: 3,
                                            message: messages.minLength
                                            },
                                            maxLength: {
                                            value: 50,
                                            message: messages.maxLength
                                            },
                                            pattern: {
                                            value: patterns.espacioBlanco,
                                            message: messages.espacioBlanco
                                            }

                                        })}

                                    />
                                    {errors.Descripcion && <small className="text-red-600">{errors.Descripcion.message}</small> }
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="block fontzize_label text-black">
                                    Seleccione el color:
                                </label>
                                <div className="flex justify-center border border-black rounded-hiper_g px-2 py-4">
                                    <div className="grid grid-cols-3 grid-rows-3 gap-2 ">
                                        {listColor.map((item_list)=>
                                            // <div className="w-11 h-11 rounded-md" key={item_list.id}
                                            <div className="w-11 h-11 rounded-md border-2 cursor-pointer" key={item_list.id}
                                            // <div  key={item_list.id}
                                            style={{backgroundColor: item_list.color,
                                                borderColor:item_list.color===formData.Color ? "#000000":item_list.color
                                            }}
                                            onClick={() => selectColor(item_list.color)}
                                            ></div> 
                                                
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div >
                            
                                <button 
                                        className="flex justify-center items-center gap-1 bg-hiperblue hover:hiperblue_hover py-2 px-4 rounded-lg  text-white w-full mt-1"
                                        // onClick={guardarListaCompra}
                                        type="submit"
                                        disabled={loading}
                                        >
                                        <Circles
                                            color="#DC842D"
                                            width={20}
                                            height={20}
                                            ariaLabel="circles-loading"
                                            visible={loading}
                                        /> 
                                        Crear nueva lista
                                </button>

                            </div>

                        </form>

                    </div>
                </div>

            </div>
            

        </>

    );
};
// export default Contenido;

const mapStateToProps = state => ({
    loading: state.Shopping_list.loading,
})

export default connect(mapStateToProps, {
    actualizar_lista_compra,store_lista_compra
})(ContenidoNuevaLista)