import React, {Fragment, useState} from 'react';
import Layout from "../hocs/Layout";
import {Link,useParams} from "react-router-dom";
import {useEffect} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faCalendarAlt, faEnvelope, faIdCard} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {update_usuario,eliminar_usuario} from "../../src/redux/actions/profile";
import {info_session} from "../../src/redux/actions/profile";
import Alert from "../components/Swalert"
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import myImage from '../assets/images/fondopedidos.png';
import ButtonText from "../widgets/button_text";
import {cleadSwAlert} from "../redux/actions/swalert";
import "../assets/styles/responsive.scss"
import "../assets/styles/globalColorPrincipal.css";
import "../assets/styles/stylePageGlobals.scss";
// import { copy } from 'clipboard-copy';

import Page_message from "../components/Page_message";

const Cuenta = ({show_alert, redirect, cleadSwAlert, info_session, profile, update_usuario,eliminar_usuario}) => {
    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();
    const [formData, setFormData] = useState({
        nombre: '',
        Apellido_paterno: '',
        FechaNacimiento: "1993-04-30",
        Email: "",
        NroDocumento: "",

    })
    const [tipo, setTipo] = useState('')
    let getToken = (e) => {
        return localStorage.getItem('access_token');
    };


    const [nombres, setNombres] = useState('');
    const [ApellidoPaterno, setApellidoPaterno] = useState('');
    const [FechaNacimiento, setFechaNacimiento] = useState('');
    const [NroDocumento, setNroDocumento] = useState('');
    const [Email, setEmail] = useState('');
    const [NroTelefono, setNroTelefono] = useState('');
    const [genero, setGenero] = useState('');

    useEffect(() => {
        if (show_alert && redirect !== null) {
            cleadSwAlert();
            if (redirect.type === 'back') {
                window.history.back();
            }
        }
    }, [show_alert]);

    useEffect(()=>{
        return ()=>{
            setNombres('');
            setApellidoPaterno('');
            setFechaNacimiento('');
        }
    },[])

    useEffect(() => {
        console.log('perfiluser', profile);
        if (!profile) {
            console.log('ingre', profile);
            cargar_datos_sesion();
            // info_session();
        } else {
            if (!profile.Telefono) {
                // navigate("/registro_mobil");
                return;
            }
            setNombres(profile.Nombres);
            setApellidoPaterno(profile.ApellidoPaterno);
            setFechaNacimiento(profile.FechaNacimiento);
            setNroDocumento(profile.NroDocumento);
            setEmail(profile.Email);
            setNroTelefono(profile.Telefono);
            setGenero(profile.Genero?.Id);
        }
    }, [profile]);

    const cargar_datos_sesion = async () => {
        await info_session();
    };

    const cargar_datos = () => {
        console.log('El componente se ha montado 2', profile);
    };

    const getFecha_dia = (fecha) => {
        let aux = ("" + fecha).split("/");
        return Number(aux[0]);
    };
    const getFecha_Mes = (fecha) => {
        let aux = ("" + fecha).split("/");
        return aux[1];
    };

    const getFecha_Anio = (fecha) => {
        let aux = ("" + fecha).split("/");
        return aux[2];
    };
    const setMes_fechaNacimiento = (mes) => {
        let aux = ("" + FechaNacimiento).split("/");
        let fecha_actual = aux[0] + "/" + mes + "/" + aux[2];
        setFechaNacimiento(fecha_actual);

    };

    const setAnio_fechaNacimiento = (anio) => {
        let aux = ("" + FechaNacimiento).split("/");
        let fecha_actual = aux[0] + "/" + aux[1] + "/" + anio;
        setFechaNacimiento(fecha_actual);

    };

    const setDia_fechaNacimiento = (dia) => {
        let aux = ("" + FechaNacimiento).split("/");
        let fecha_actual = dia + "/" + aux[1] + "/" + aux[2];
        setFechaNacimiento(fecha_actual);

    };
    const onSubmit = e => {
        e.preventDefault();

        const datos = {
            "Nombres": nombres,
            "ApellidoPaterno": ApellidoPaterno,
            "ApellidoMaterno": "none",
            "Telefono": NroTelefono,
            "Email": Email,
            "Genero": genero,
            "FechaNacimiento": FechaNacimiento,
            "NroDocumento": NroDocumento
        }
        let object = {
            type: 'back',
        };
        if(tipo==='eliminar'){
            delete_registro();
        }else{
            update_usuario(datos, object);
        }
        
    }
    async function delete_registro(id) {
        let resp = await Page_message.delete_register_confirmation('¿Estas seguro de eliminar tu cuenta?');
        if (resp) {
            eliminar_usuario();
        }
 
  }

    return (
        <Layout>
            <Alert></Alert>
            <div className="bg_image_page" style={{minHeight: '50rem'}}>
                <div className='content_info pt-4'>
                    <div className="container">
                        <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                            Mi cuenta</h1>
                        <div className="flex flex-column mt-1 items-center">
                            <div className="md:w-[40rem] contenedor_mobile h-auto  pt-3">
                                <div className="w-full ">
                                    <form onSubmit={e => onSubmit(e)}
                                          className="bg-white shadow rounded-xl px-8 pt-6 pb-8 mb-4">
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="name">
                                                Nombre:
                                            </label>
                                            <input
                                                id="name"
                                                type="text"
                                                defaultValue={nombres}
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                onChange={e => setNombres(e.target.value)}
                                                required
                                                pattern="[a-zA-Z\s]{5,150}"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="username">
                                                Apellido:
                                            </label>
                                            <input
                                                id="username"
                                                type="text"
                                                defaultValue={ApellidoPaterno}
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                required
                                                pattern="[a-zA-Z\s]{5,150}"
                                                onChange={e => setApellidoPaterno(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <div className="d-flex">
                                                <label className="block fontzize_label text-black mb-2"
                                                       htmlFor="date">
                                                    Fecha de nacimiento:
                                                </label>
                                            </div>
                                            <div className=" grid grid-cols-3 gap-2">
                                                <select id="dia" name="dia"
                                                        onChange={e => setDia_fechaNacimiento(e.target.value)}
                                                        value={getFecha_dia(FechaNacimiento)}
                                                        required
                                                        className="peer  h-full w-full  py-2 px-3 border_black radius_input text-gray-700 focus:outline-none">
                                                    <option value="1" selected>1</option>
                                                    {_.times(30, (i) => (
                                                        <option key={i} value={i + 2}>{i + 2}</option>
                                                    ))}
                                                </select>
                                                <select id="Mes" name="mes" value={getFecha_Mes(FechaNacimiento)}
                                                        onChange={e => setMes_fechaNacimiento(e.target.value)}
                                                        className="peer  h-full w-full  py-2 px-3 border_black radius_input text-gray-700 focus:outline-none">
                                                    <option value="01" selected>Enero</option>
                                                    <option value="02">Febrero</option>
                                                    <option value="03">Marzo</option>
                                                    <option value="04">Abril</option>
                                                    <option value="05">Mayo</option>
                                                    <option value="06">Junio</option>
                                                    <option value="07">Julio</option>
                                                    <option value="08">Agosto</option>
                                                    <option value="09">Septiembre</option>
                                                    <option value="10">Octubre</option>
                                                    <option value="11">Noviembre</option>
                                                    <option value="12">Diciembre</option>
                                                </select>
                                                <select id="Anio" name="anio" value={getFecha_Anio(FechaNacimiento)}
                                                        onChange={e => setAnio_fechaNacimiento(e.target.value)}
                                                        className="peer  h-full w-full  py-2 px-3 border_black radius_input text-gray-700 focus:outline-none"
                                                >
                                                    {_.times(100, (i) => (
                                                        <option key={i} value={i + 1950}>{i + 1950}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="email">
                                                Correo:
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="email" type="email" value={Email}
                                                required
                                                onChange={e => setEmail(e.target.value)}/>
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="phone">
                                                Número de teléfono:
                                            </label>

                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 cursor-not-allowed leading-tight focus:outline-none focus:shadow-outline"
                                                id="phone" type="text" value={NroTelefono}
                                                onChange={e => setNroTelefono(e.target.value)}
                                                disabled
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="genero">
                                                Género:
                                            </label>
                                            <select id="genero" name="genero" value={genero}
                                                    onChange={e => setGenero(e.target.value)}
                                                    required
                                                    className="peer h-full w-full rounded-[7px] py-2 px-3 border_black radius_input text-gray-700 focus:outline-none">
                                                <option value="9">Femenino</option>
                                                <option value="8" selected>Masculino</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="ci">
                                                Documento de identidad:
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="ci" type="text" value={NroDocumento}
                                                required
                                                minLength={5}
                                                maxLength={15}
                                                pattern="[0-9]+"
                                                onChange={e => setNroDocumento(e.target.value)}/>
                                        </div>
                                        <div className="grid grid-rows-2 gap-2 text-white">
                                            <ButtonText
                                                type="submit"
                                                label='Eliminar cuenta'
                                                className='bg-hiperblue hover:bg-hiperblue_hover text-white'
                                                principal={false}
                                                onClick={() => setTipo('eliminar')}
                                            />

                                            <ButtonText
                                                type="submit"
                                                label='Actualizar información'
                                                className='bg-hipergreen hover:bg-hipergreen_hover'
                                                principal={true}
                                                onClick={() => setTipo('actualizar')}
                                            />
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </Layout>
    );
};

const mapStateToProps = state => ({
    profile: state.Profile.profile,
    show_alert: state.Swalerts.show_alert,
    redirect: state.Swalerts.redirect,
})

export default connect(mapStateToProps, {
    info_session, update_usuario, cleadSwAlert,eliminar_usuario
})(Cuenta)

