import React, {useState, useEffect} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import {faMotorcycle} from "@fortawesome/free-solid-svg-icons";
import {faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {setPedidosUsuarioFireb} from "../../src/redux/actions/pedido";
import {
    faAnglesRight,

} from "@fortawesome/free-solid-svg-icons";


import '../assets/styles/inicio.scss';


import firebase from './../Firebase3';

export const EstadoPedido = ({pedido, profile, pedidos_firebase, showtodopedidos}) => {

    const [showItem, setShowItem] = useState(true);
    const [show, setShow] = useState(false);
    const [pedido_firebase, setPedido_firebase] = useState(null);
    const [codigocolor, setCodigocolor] = useState(null);
    const [codigocolorcabecera, setCcodigocolorcabecera] = useState(null);

    useEffect(() => {
        if (pedido_firebase) {
            let estado_pedido_id = pedido_firebase.EstadoPedidoCliente.Id;
            if (estado_pedido_id === 7 || estado_pedido_id === 6) {
                setTimeout(() => {
                    setShowItem(false);
                }, "2000");
            }else{
                setShowItem(true);
            }
        }
    }, [pedido_firebase]);


    useEffect(() => {
        async function fetchData() {
            try {
                let tipo_user = localStorage.getItem('user');
                if (profile !== null && Object.keys(pedido).length !== 0 && tipo_user !== '"anonimo"') {
                    const databaseRef = firebase.database().ref('pedidos/' + profile.IdUsuario);
                    const handleDataChange = (snapshot) => {
                        search_pedido(snapshot.val(), pedido.NroPedido);
                    };
                    databaseRef.on('value', handleDataChange);
                    return () => {
                        databaseRef.off('value', handleDataChange);
                    };
                }
            } catch (e) {
                console.error(e);
            }
        };
        fetchData();

    }, []);


    useEffect(() => {
        setShow(showtodopedidos);
    }, [showtodopedidos]);

    function search_pedido(pedidos, pedido_id) {
        if (pedidos) {
            const pedidos_aux = Object.values(pedidos)
            pedidos_aux.forEach((el) => {
                if (el.NroPedido === pedido_id) {
                    setPedido_firebase(el);
                    const color = getcolor(el.EstadoPedidoCliente.Id);
                    const colorcabecera = getcolorcabecera(el.EstadoPedidoCliente.Id);
                    setCodigocolor(color)
                    setCcodigocolorcabecera(colorcabecera)
                    return;
                }
            });
        }


    }

    function getcolor(pedido_id) {
        const code = pedido_id;
        if (code === 1) {
            return "#acacac";
        } else {
            if (code === 3) {
                return "#84B111";
            } else {
                if (code === 4 || code === 5) {
                    return "#B528C2";
                } else {
                    if (code === 6) {
                        return "#00AEFF";
                    }
                }
            }
        }
    }

    function getcolorcabecera(pedido_id) {
        const code = pedido_id;
        if (code === 1) {
            return "#dc842d";
        } else {
            if (code === 3) {
                return "#84B111";
            } else {
                if (code === 4 || code === 5) {
                    return "#B528C2";
                } else {
                    if (code === 6) {
                        return "#00AEFF";
                    }
                }
            }
        }
    }

    function namefuncion() {
        setShow(show => (!show));
    }


    function pintar(pedido) {
        //.EstadoPedidoCliente.Id
        if (pedido === null) {
            return null;
        }

        const code = pedido.EstadoPedidoCliente.Id;
        if (code === 1) {
            return "solicitado";
        } else {
            if (code === 3) {
                return "enproceso";
            } else {
                if (code === 4 || code === 5) {
                    return "encamino";
                } else {
                    if (code === 6) {
                        return "entregado";
                    }
                }
            }
        }
    }

    function pintar_cabecera(pedido_id) {
        const code = pedido_id;
        if (code === 1) {
            return "solicitado";
        } else {
            if (code === 3) {
                return "enproceso";
            } else {
                if (code === 4 || code === 5) {
                    return "encamino";
                } else {
                    if (code === 6) {
                        return "entregado";
                    }
                }
            }
        }
    }

    return (
        showItem ?
            <div>
                {(show) ?
                    <div className=" estilo_radio_estado_pedido_panel_principal cajaDetPed ">
                        <div
                            className="estilo_radio_estado_pedido_panel_principal_cabecera cabecera_estado_pedido_panel_principal"
                            style={{backgroundColor: codigocolorcabecera}}>
                            <FontAwesomeIcon className="style_icon_rigth_header"

                                             onClick={() => namefuncion()}
                                             icon={faChevronRight}
                                             style={{height: "20px", paddingRight: "10px", paddingLeft: "10px"}}/>
                            <div className="style_header_text"> Pedido en curso # &nbsp;{pedido.NroPedido}  </div>
                            <div
                                className="style_header_text espacio_izq"> Entrega: &nbsp; {pedido.FechaEntrega}  </div>
                            {/* <div className="titulo_cabecera_state_pedido">
                        <div> Pedido en curso #2736478  </div>
                        <div> Entrega: 04/07/2022 </div>

                    </div> */}

                        </div>
                        <div className="body_panel_state_pedido">


                            <>
                                <div className="estilo_container_icono_state_pedido">
                                    <div
                                        className={`circulo_icono_state_pedidos ${pintar(pedido_firebase) === 'solicitado' ? 'color_azul' : 'color_plomo'}`}>
                                        <FontAwesomeIcon icon={faDownload} style={{
                                            height: "20px",
                                            paddingRight: "10px", paddingLeft: "10px", color: "#fff", fontSize: "13px"
                                        }}/>
                                    </div>
                                    <span style={{color: "#acacac"}} className="estilo_size_font_icon">Solicitado</span>
                                </div>
                                <div className="estilo_icon_rigth" style={{marginRight: "3px", marginLeft: "3px"}}>
                                    <FontAwesomeIcon icon={faAnglesRight} style={{
                                        height: "20px",
                                        color: "#acacac", fontSize: "13px"
                                    }} className="estilo_size_font_icon"/>
                                </div>
                                <div className="estilo_container_icono_state_pedido" style={{ width:"64px" }}>
                                    <div
                                        className={`circulo_icono_state_pedidos ${pintar(pedido_firebase) === 'enproceso' ? 'color_verde text-white' : 'color_plomo'} `}>
                                        <FontAwesomeIcon icon={faRotate} style={{
                                            height: "20px",
                                            paddingRight: "10px", paddingLeft: "10px",paddingTop: "2px", color: "#fff", fontSize: "13px"
                                        }}/>
                                    </div>
                                    <span style={{color: "#acacac", width: "100%", textAlign: "center"}}
                                          className="estilo_size_font_icon">En proceso</span>
                                </div>
                                <div className="estilo_icon_rigth" style={{marginRight: "3px", marginLeft: "3px"}}>
                                    <FontAwesomeIcon icon={faAnglesRight} style={{
                                        height: "20px",
                                        color: "#acacac", fontSize: "13px"
                                    }} className="estilo_size_font_icon"/>
                                </div>

                                <div className="estilo_container_icono_state_pedido">
                                    <div
                                        className={`circulo_icono_state_pedidos ${pintar(pedido_firebase) === 'encamino' ? 'color_lila text-white' : 'color_plomo'} `}>
                                        <FontAwesomeIcon icon={faMotorcycle} style={{
                                            height: "20px",
                                            paddingRight: "10px", paddingLeft: "8px",paddingTop: "2px", color: "#fff", fontSize: "13px"
                                        }}/>
                                    </div>
                                    <span style={{color: "#acacac"}} className="estilo_size_font_icon">En camino</span>
                                </div>
                                <div className="estilo_icon_rigth" style={{marginRight: "3px", marginLeft: "3px"}}>
                                    <FontAwesomeIcon icon={faAnglesRight} style={{
                                        height: "20px",
                                        color: "#acacac", fontSize: "13px"
                                    }} className="estilo_size_font_icon"/>
                                </div>
                                <div className="estilo_container_icono_state_pedido">
                                    <div
                                        className={`circulo_icono_state_pedidos ${pintar(pedido_firebase) === 'entregado' ? 'color_celeste text-white' : 'color_plomo'} `}>
                                        <FontAwesomeIcon icon={faThumbsUp} style={{
                                            height: "20px",
                                            paddingRight: "10px", paddingLeft: "10px", color: "#fff", fontSize: "13px"
                                        }}/>
                                    </div>
                                    <span style={{color: "#acacac"}} className="estilo_size_font_icon">Entregado</span>
                                </div>
                            </>
                        </div>
                    </div> :
                    <div className="container_panel_oculto">
                        <div className="container_oculto estilo_radio_estado_pedido_panel_principal">
                            <div
                                className="  estilo_radio_estado_pedido_panel_principal_cabecera cabecera_estado_pedido_panel_principal"
                                style={{backgroundColor: codigocolorcabecera}}>
                                <FontAwesomeIcon className="style_icon_rigth_header"

                                                 onClick={() => namefuncion()}
                                                 icon={faChevronLeft}
                                                 style={{height: "20px", paddingRight: "10px", paddingLeft: "10px"}}/>
                                <label style={{"color": "#000"}}>  {show}</label>
                            </div>
                            <div className="body_panel_state_pedido">
                            </div>
                        </div>
                    </div>
                }
            </div>
            : null

    );
};

const mapStateToProps = state => ({

    pedidos_firebase: state.Pedido.pedidos_firebase

})

export default connect(mapStateToProps, {
    setPedidosUsuarioFireb
})(EstadoPedido)

