import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import SeccionInicio from "../../widgets/seccion_inicio";
import "../../assets/styles/hiper.scss";
const breakpoints = {
    300: {
        slidesPerView: 1,
        spaceBetween: 5,
    },
    400: {
        slidesPerView: 1,
        spaceBetween: 5,
    },
    500: {
        slidesPerView: 1,
        spaceBetween: 5,
    },
    640: {
        slidesPerView: 1,
        spaceBetween: 5, //10
    },
    768: {
        slidesPerView: 2,
        spaceBetween: 5, //10
    },

    992: {
        slidesPerView: 2,
        spaceBetween: 5,//10
    },
    1200: {
        slidesPerView: 3,
        spaceBetween: 5, //10
    },
    1300: {
        slidesPerView: 3,
        spaceBetween: 5, //30
    },

    1400: {
        slidesPerView: 4,
        spaceBetween: 5, //30
    },

};

function Capsula({descripcion, Items}) {
    const [productos, guardarProductos] = useState([]);
    useEffect(() => {
        guardarProductos(Items);
    }, []);
    return (
        <>
            <div className="mb-6 mt-2">
                <div className=" containerStyle3Sucursal h-auto w-[100%] " style={{padding:'0px 10px'}}>
                    <Swiper
                        modules={[Navigation]}
                        breakpoints={breakpoints}
                        slidesPerView={1}
                        loop={false}
                        // loopFillGroupWithBlank={true}
                        navigation={true}
                        className="mySwiper bg-white rounded-3xl py-6"
                        style={{"--swiper-navigation-color": "#32338E",
                        "--swiper-navigation-size": "30px"
                        }}
                    >
                        {
                            productos &&
                            productos !== null &&
                            productos !== undefined &&
                            productos.map((producto, index) => (
                                <SwiperSlide key={index} className="mySwiper flex justify-center">
                                      <div className="relative ">
                                        <div className="content_capsula">
                                            <a href={producto.Url_Destino} target="_blank">
                                                <img src={producto.UrlFoto} alt=""
                                                    className="object-contain w-[19rem]  shadow-sm   "/>
                                            </a>

                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>

            {/*<div className="mb-10">*/}
            {/*    <div className="flex justify-between">*/}
            {/*        /!*<h3 className="text-hiperblue text-xl">{descripcion}</h3>*!/*/}
            {/*    </div>*/}
            {/*    <div className=" w-100">*/}
            {/*        <div className=" h-auto row">*/}
            {/*            {*/}
            {/*                productos &&*/}
            {/*                productos !== null &&*/}
            {/*                productos !== undefined &&*/}
            {/*                productos.map((producto, index) =>(*/}
            {/*                    <div className="col-sm-3" key={index}>*/}
            {/*                        <div className="d-inline" >*/}
            {/*                            <div className="w-[20rem]">*/}
            {/*                                <a href={producto.Url_Destino} target="_blank">*/}
            {/*                                    <img src={producto.UrlFoto} alt="" className="object-contain w-[19rem]  shadow-sm p-0.5 border-4 border-orange-500/40 "/>*/}
            {/*                                </a>*/}

            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    )*/}

            {/*                )*/}
            {/*            }*/}

            {/*        </div>*/}
            {/*    </div>*/}


            {/*</div>*/}
        </>
    );
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(Capsula)