import firebase from "firebase/compat/app";
import {initializeApp} from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import 'firebase/compat/database';

import {ToastContainer, toast} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
    apiKey: "AIzaSyBkyXLQD_TexK2iZR5bDCeh1vMwq1AyE44",
    authDomain: "hipermaxi-appmobile-dev.firebaseapp.com",
    databaseURL: "https://hipermaxi-appmobile-dev-default-rtdb.firebaseio.com",
    projectId: "hipermaxi-appmobile-dev",
    storageBucket: "hipermaxi-appmobile-dev.appspot.com",
    messagingSenderId: "999929467399",
    appId: "1:999929467399:web:a9443ce3a94f6e311010a2",
    measurementId: "G-VWGRYQYD5P"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const messaging = getMessaging();
firebase.initializeApp(firebaseConfig);
export default firebase;

export const requestForToken = () => {
    return getToken(messaging, {vapidKey: "BFfKZEgKTyzol2w0HcVycSFo18TadQzKT5qXH6DwirixVM1afUQYB6XPqqounrYXa67vMjl_Lh_ab41-9Ydkjm4"})  //*hipermaxi*/
        .then((currentToken) => {
            console.log("pasa para recuperar el token2 ", currentToken);
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                // Perform any other neccessary action with the token
                localStorage.setItem("tfb", currentToken);
                //  return currentToken;
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("payloadC", payload)
            toast(payload.notification.body)
            resolve(payload);
        });
    });


// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         onMessage(messaging, (payload) => {
//             console.log("payload", payload.notification);
//             toast(payload.notification.body)
//             resolve(payload);
//         });
//     });