import React, {useEffect,useState} from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import {set_search} from "../redux/actions/producto";

const BuscadorGeneral = ({search,set_search}) => {
    const [filtro, setFiltro] = useState('');
    const {ciudadp, sucursalp} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (search) {
            setFiltro(search);
        } else {
            setFiltro('');
        }
    }, [search]);

    const enter_opcion = (event) => {
        //  console.log("pasooo  ",event.key);
        //  console.log("pasooo 1 ",filtro);
        if (event.key === 'Backspace') {
            if (filtro === '' || filtro.length === 1) {
                event.preventDefault();
                setFiltro("");
            }
        }
        if (event.key === 'Enter' || event.type === 'click') {
            if (filtro !== "" && filtro.trim() !== "") {
                set_search(filtro)
                let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
                if (ciudadp && sucursalp) {
                    navigate("/" + ciudadp + "/" + sucursalp + "/busqueda-producto/" + filtro.trim());
                } else if (url_nav) {
                    navigate("/" + url_nav.url_parameters + "/busqueda-producto/" + filtro.trim());
                }
            }
        }
    };
    const handleChange = (event) => {

        const {value} = event.target;
        const regex = /^[0-9a-zA-ZñÑáéíóúÁÉÍÓÚ ]+$/;
        // console.log("HANDLE ",value );
        if (regex.test(value)) {
            setFiltro(event.target.value);
            save_text_filtro(event.target.value);
        }
    };
    function save_text_filtro(text) {
        localStorage.setItem("filter_g", text);
    }
    return (
        <div className="w-100">
            <div className="relative flex w-full flex-wrap items-stretch ">
                <input type="text"
                       value={filtro}
                       onKeyDown={enter_opcion}
                       onChange={(e) => handleChange(e)}
                       placeholder='QUÉ ESTAS BUSCANDO?'
                       className="w-100 py-2 placeholder-slate-400 text-slate-600 relative  bg-gray-200 rounded text-sm border-0  outline-none focus:outline-none focus:ring   "
                       style={{paddingRight: '2rem', paddingLeft: '0.5rem'}}
                />
                <span
                    className="z-10  leading-snug font-normal text-center absolute right-0  bg-hiperorange rounded-r-lg text-white w-8 pr-1 py-2">
                                              <FontAwesomeIcon onClick={(e) => enter_opcion(e)} icon={faSearch}/>
                                            </span>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    search: state.Producto.search,
})

export default connect(mapStateToProps, {
    set_search,
})(BuscadorGeneral)

