import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
    get_fechas_entrega_sucursal_carrito, update_list_carrito_compras,
    save_horarios, get_horarios_disponible_carrito
} from "../../../redux/actions/carrito";
import ItemHorario from "../../../widgets/item_Horario";
import {Menu} from "@headlessui/react";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMotorcycle, faStore} from "@fortawesome/free-solid-svg-icons";
import {set_tipo_entrega} from "../../../redux/actions/branch_office";
import "../../../assets/styles/responsive.scss"
import ButtonText from "../../../widgets/button_text";
import ButtonClose from "../../../widgets/button_close";

function ModoEntrega({
                         mt_indice,
                         sucursal,
                         tipo_entrega,
                         set_tipo_entrega,
                         list_carrito,
                         fechas_disponibles_entrega,
                         get_fechas_entrega_sucursal_carrito,
                         horarios_disponibles,
                         get_horarios_disponible_carrito,
                         update_list_carrito_compras,
                         save_horarios,
                         respuesta_update_horario,
                         width,
                         height,
                         exit
                     }) {
    const [token] = useState(localStorage.getItem('access_token'));
    const [tipo_entregas, guardarTipoEntrega] = useState([]);
    const [subPedidos, guardarsubPedidos] = useState([]);
    const [aux_list_fechas, setAux_list_fechas] = useState([]);
    const [items_selecionados, setItems_selecionados] = useState([]);
    const [list_horarios, setList_horarios] = useState([]);
    const [list_selec_fechas, setList_selec_fechas] = useState([]);
    const [cargado_fecha, GuardarCargadoFecha] = useState(false);
    const [dias, setDias] = useState([
        {
            name: 'Lunes',
            cod: 'LU',
        },
        {
            name: 'Martes',
            cod: 'MA',
        },
        {
            name: 'Miércoles',
            cod: 'MI',
        },
        {
            name: 'Jueves',
            cod: 'JU',
        },
        {
            name: 'Viernes',
            cod: 'VI',
        },
        {
            name: 'Sábado',
            cod: 'SA',
        },
        {
            name: 'Domingo',
            cod: 'DO',
        }
    ]);
    const [meses, setMeses] = useState([
        {
            name: 'en.',
            cod: '01',
        },
        {
            name: 'feb',
            cod: '02',
        },
        {
            name: 'mar.',
            cod: '03',
        },
        {
            name: 'abr.',
            cod: '04',
        },
        {
            name: 'may.',
            cod: '05',
        },
        {
            name: 'jun.',
            cod: '06',
        },
        {
            name: 'jul.',
            cod: '07',
        },
        {
            name: 'ag.',
            cod: '08',
        },
        {
            name: 'sept.',
            cod: '09',
        },
        {
            name: 'oct.',
            cod: '10',
        },
        {
            name: 'nov.',
            cod: '11',
        },
        {
            name: 'dic.',
            cod: '12',
        }

    ]);


    const navigate = useNavigate();
    const [lista_meses] = useState(["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"])

    const ahorro_total = _ahorroTotal();

    function _ahorroTotal() {
        let total = 0;
        subPedidos.forEach(sp => {
            if (sp.check) {
                total += Number(sp.ahorro);
            }
        });
        return total.toFixed(2);
    }

    // codigo a usar
    useEffect(() => {
        if (sucursal !== null) {
            guardarTipoEntrega(sucursal.TipoEntregas);
        }
    }, [sucursal]);
    useEffect(() => {
        if (fechas_disponibles_entrega && subPedidos.length > 0) {
            GuardarCargadoFecha(true);
            let aux_fecha = fechas_disponibles_entrega.map((item) => {
                let sucursal = subPedidos.find(ps => ps.Sala.Id === item.IdSucursal);
                let dia = item.Dias[0];

                return {
                    ...item,
                    Importe: sucursal.ImporteSubtotal,
                    seleccionado_id: dia.IdFecha,
                    fecha_selec: dia.Fecha
                }
            })
            setAux_list_fechas(aux_fecha);
        }

    }, [fechas_disponibles_entrega]);

    useEffect(() => {
        if (horarios_disponibles && fechas_disponibles_entrega) {
            if (horarios_disponibles.Horarios.length > 0) {
                colocar_lista_horario_en_sucursal(horarios_disponibles);
                console.log("horarios_disponibles.Horarios", horarios_disponibles.Horarios)
                let horario = horarios_disponibles.Horarios[0];
                cambiarSeleccion(horario.IdHorario, horario.IdHorarioEspecial, horarios_disponibles.IdSucursal);
            }

        }

    }, [horarios_disponibles]);

    useEffect(() => {
    }, [list_horarios]);


    useEffect(() => {
        async function cargarHorarioAsyn() {
            if (aux_list_fechas.length > 0) {
                GuardarCargadoFecha(true);
                await cargarHorario();
            }
        }

        cargarHorarioAsyn();

    }, [aux_list_fechas]);


    function actualizarTipoEntrega(item_tipo_entrega) {
        set_tipo_entrega(item_tipo_entrega);
    }

    useEffect(() => {
        if (list_carrito) {
            let subPedido = list_carrito.SubPedidos.filter((pedido) => pedido.check);
            guardarsubPedidos(subPedido);
        }
    }, [list_carrito]);
    useEffect(() => {
        async function cargarFechaAsyn() {
            if (!cargado_fecha) {
                await cargarFecha();
            }
        }

        cargarFechaAsyn();
    }, [subPedidos]);
    useEffect(() => {
        if (!cargado_fecha) {
            cargarFecha();
        }
    }, [tipo_entrega]);

    async function cargarFecha() {
        if (!cargado_fecha) {
            if (subPedidos.length > 0) {
                GuardarCargadoFecha(true);
                let sucursal_ids = subPedidos.map((item) => item.Sala.Id);
                await get_fechas_entrega_sucursal_carrito(tipo_entrega.Id, sucursal_ids);
            }
        }
    }

    function cargarHorario() {
        aux_list_fechas.forEach(async item => {
            try {
                let dia = item.Dias[0];
                let data = {
                    IdSucursal: item.IdSucursal,
                    FechaEntrega: dia.Fecha,
                    IdFecha: dia.IdFecha,
                    Importe: item.Importe,
                    IdTipoEntrega: tipo_entrega.Id,
                };
                console.log("data", data)
                await get_horarios_disponible_carrito(data);
            } catch (e) {

            }
        })

        setItems_selecionados(aux_list_fechas);
    }


    const pluck = function (arr, key) {
        var newArr = [];
        for (var i = 0, x = arr.length; i < x; i++) {
            if (arr[i].hasOwnProperty(key)) {
                newArr.push(arr[i].key)
            }
        }
        return newArr;
    }
    useEffect(() => {
        if (respuesta_update_horario) {
            if (!respuesta_update_horario.ConError) {
                guardar_lista();
                // tipo entrega 1 = sala, 2 = delivery
                if (tipo_entrega.Id === 1) {
                    mt_indice(4)
                } else {
                    //direccion
                    mt_indice(3)
                }
            }
        }
    }, [respuesta_update_horario]);

    function guardar_lista() {
        const objetoClonado = Object.assign({}, list_carrito);
        const listAux = {
            IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
            IdUbicacion: objetoClonado.IdUbicacion,
            ImporteTotal: objetoClonado.ImporteTotal,
            InstruccionDelivery: objetoClonado.InstruccionDelivery,
            InstruccionEntrega: objetoClonado.InstruccionEntrega,
            NroTotalProductos: objetoClonado.NroTotalProductos,
            SubPedidos: subPedidos
        }
        update_list_carrito_compras(listAux);
    }


    // end codigo a usar

    function existe_lista_horarios() {
        //   return  typeof(list_horarios)!='undefined'  ;
        return typeof (list_horarios) != 'undefined';
    }

    function existe_horarios() {
        //   return  typeof(list_horarios)!='undefined'  ;
        if (existe_lista_horarios()) {
            return typeof (list_horarios.Horarios) != 'undefined' && list_horarios.length > 0;
        }
        return false;

    }

    function get_dia(fecha) {
        var aux = fecha.split("/");
        return aux[0];
    }

    function get_mes(fecha) {
        var aux = fecha.split(" ");
        return aux[2];
    }


    function get_dia_litera(fecha) {
        var aux = fecha.split(" ");
        let d = dias_text(aux[0]);
        if (d) {
            return d;
        }
        return aux[0];
    }

    function dias_text(dia) {
        let di = dias.find((item) => item.cod === dia);
        if (di) {
            return di.name;
        }
        return dia;
    }

    function get_mes_anio_literal(fecha) {
        if (typeof (fecha) !== 'undefined') {
            var aux = fecha.split("/");
            var anio = aux[2];
            var mes_index = aux[1]
            return lista_meses[mes_index - 1] + " " + anio;
        }
        return "";
    }


    function tiene_elementos_aux_list_fechas() {
        return typeof (aux_list_fechas) != 'undefined';
    }

    function get_label_fecha(idsucursal) {
        if (items_selecionados.length > 0) {
            var index_lista = items_selecionados.map(item => item.IdSucursal).indexOf(idsucursal);
            if (index_lista !== -1) {
                return get_mes_anio_literal(items_selecionados[index_lista].fecha_selec);

            }
        }

        return "";
    }

    const seleccionarfecha = async (fechaid, sala_id, importe) => {
        var index_lista = aux_list_fechas.map(itemf => itemf.IdSucursal).indexOf(sala_id);
        var lista = aux_list_fechas[index_lista].Dias;
        let index = lista.map(item => item.IdFecha).indexOf(fechaid);
        aux_list_fechas[index_lista].seleccionado_id = fechaid;
        setAux_list_fechas(aux_list_fechas);
        const copyarray = aux_list_fechas.slice();
        copyarray[index_lista].fecha_selec = lista[index].Fecha;
        setItems_selecionados(copyarray);
        const res = await get_lista_horarios_fecha(token, sala_id, lista[index].Fecha, fechaid, importe, tipo_entrega.Id);
        colocar_lista_horario_en_sucursal(res);
    };

    function colocar_lista_horario_en_sucursal(horario) {
        if (horario != null) {
            let existe_horario = list_horarios.find(list_hr => list_hr.IdSucursal === horario.IdSucursal);
            if (existe_horario) {
                setList_horarios(list_horarios.map(listahr => {
                    if (listahr.IdSucursal === horario.IdSucursal) {
                        return {
                            ...listahr,
                            IdSucursal: horario.IdSucursal,
                            IdFecha: horario.IdFecha,
                            horarios: horario.Horarios
                        };
                    } else {
                        return {
                            ...listahr
                        };
                    }
                }));
            } else {
                var dato = {IdSucursal: horario.IdSucursal, IdFecha: horario.IdFecha, horarios: horario.Horarios};
                setList_horarios(list_horarios => [...list_horarios, dato]);
            }
        }
    }

    function get_monto_mycart() {
        return localStorage.getItem("monto_mycart");
    }

    async function get_lista_horarios_fecha(token, idsucursal, fecha_entrega, idfecha, importe, tipoentregaid) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/carrito/horarios-disponible?IdSucursal=${idsucursal}&FechaEntrega=${fecha_entrega}&IdFecha=${idfecha}&Importe=${Number(importe)}&IdTipoEntrega=${tipoentregaid}`, config);
            // console.log("res horarios", res)
            // console.log(token,idsucursal,fecha_entrega,idfecha,importe,tipoentregaid);
            /* const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/carrito/horarios-disponible?IdSucursal=${idsucursal}&FechaEntrega=${fecha_entrega}&IdFecha=${idfecha}&Importe=${importe}&IdTipoEntrega=${tipoentregaid}`, config);
               */

            if (res.status === 200) {
                return res.data.Dato;
            }
        } catch (err) {
            console.log("error ", err)
        }
    }

    function set_tipo_entrega2(op) {
        var tipo_entregax = 0;
        if (op === 'delivery') {
            tipo_entregax = 2;
        }
        if (op === 'sucursal') {
            tipo_entregax = 1
        }
        localStorage.setItem("tipo_entrega_mycart", tipo_entregax);
    }

    const save_datos_entrega = async (e) => {
        if (list_selec_fechas.length === 0) {
            mensaje_alert("debe seleccionar horarios");
            return;
        }
        await cargar_horarios(list_selec_fechas);
    };

    function mensaje_alert(text) {
        Swal.fire({
            position: 'top',
            icon: 'error',
            title: text,
            showConfirmButton: true,

        })
    }

    async function cargar_horarios(list_selec_fechas) {
        console.log("list_selec_fechas", list_selec_fechas)
        guardarsubPedidos(subPedidos.map(ps => {
                if (ps.check && ps.check_activo) {
                    let data_fecha = list_selec_fechas.find(list_date => list_date.IdSucursal === ps.Sala.Id);
                    let is_horario = false;
                    if ((data_fecha.seleccionado_id !== '' && data_fecha.horarioId !== '' && data_fecha.IdHorarioEspecial !== '')) {
                        is_horario = true;
                    }
                    let Horario = {
                        IdFecha: '',
                        IdHorario: '',
                        IdHorarioEspecial: '',
                    };
                    if (is_horario) {
                        Horario = {
                            IdFecha: data_fecha.seleccionado_id,
                            IdHorario: data_fecha.horarioId,
                            IdHorarioEspecial: data_fecha.IdHorarioEspecial
                        };
                    }

                    return {
                        ...ps,
                        Horario: Horario,
                        is_horario: is_horario
                    }
                } else {
                    return {
                        ...ps
                    }
                }
            })
        );

        var aux = [];
        for (var item of list_selec_fechas) {
            var datos = {
                IdSucursal: item.IdSucursal,
                IdFecha: item.seleccionado_id,
                IdHorario: item.horarioId,
                IdHorarioEspecial: item.IdHorarioEspecial
            };

            aux.push(datos);
        }

        const respuesta = await save_horarios(aux);
    }

    function is_item_check(h1) {
        let item = list_selec_fechas.find(lista => lista.horarioId === h1.IdHorario);
        if (item) {
            return true;
        } else {
            return false;
        }
    }

    function cambiarSeleccion(horarioId, IdHorarioEspecial, IdSucursal) {
        console.log("")
        var aux = items_selecionados.slice();

        var index = aux.map(item => item.IdSucursal).indexOf(IdSucursal);
        if (index !== -1) {
            aux[index].horarioId = horarioId;
            aux[index].IdHorarioEspecial = IdHorarioEspecial;
        }
        setList_selec_fechas(aux);
    }

    function volverAtras() {
        mt_indice(1)
    }

    function get_total_carrito() {
        let total = 0;
        let precio_no_disponible = 0;
        subPedidos.forEach((sp) => {
            if (sp.check) {
                sp.Productos.forEach(prod => {
                    if (!prod.Disponible) {
                        precio_no_disponible += prod.Importe;
                    }
                })
            }
        });

        subPedidos.forEach((sp) => {
            if (sp.check) {
                total += sp.ImporteSubtotal;
            }
        });

        let result = parseFloat(total) - parseFloat(precio_no_disponible);
        return parseFloat(result).toFixed(2);
    }

    function get_mes(fecha) {
        var aux = fecha.split("/");
        let m = mes_text(aux[1]);
        if (m) {
            return m;
        }
        return m;

    }

    function mes_text(mes) {
        let me = meses.find((item) => item.cod === mes);
        if (me) {
            return me.name;
        }
        return me;
    }

    return (
        <>
            <div className={classNames(
                {"altura_carrito_default contenedor_mobile md:w-[60%]": width > 768},
                {"h-[calc(100%-12rem)] w-100": width <= 768},
            )}>
                <div className="w-100 bg-white rounded-2xl overflow-hidden h-full">
                    <div className="bg-white rounded-hiper_g ">
                        <div
                            className="bg-hiperorange relative w-100 text-white flex items-center justify-center content_title">
                            <h2 className='p-0 m-0 title_carrito_responsivo '>Modo de entrega</h2>
                            <div className="absolute right-2 ">
                                <ButtonClose className="text-white bg-transparent" onClick={() => exit()}/>
                            </div>
                        </div>

                        <div className={classNames("bg-white", "overflow-y-auto", "container_scroll",
                            {"max-h-[62vh]": height <= 667},
                            {"max-h-[66vh]": height > 667 && height <= 843},
                            {"max-h-[72vh]": height > 843},
                        )}>
                            <div className='p-1 bg-white h-full'>
                                <div className="bg-white overflow-y-auto container_scroll ">

                                    <div className="my-2 mx-2 p-2 bg-hiperplomo_claro rounded-hiper_g">
                                        <p className="text-black fontzize_text_carrito mb-4 font-bold">Selecciona el
                                            modo de
                                            entrega</p>

                                        <div className="flex flex-wrap gap-2 justify-center">
                                            {
                                                tipo_entregas &&
                                                tipo_entregas !== null &&
                                                tipo_entregas !== undefined &&
                                                tipo_entregas.map((item_tipo_entrega, index) =>
                                                    <button key={index}
                                                            className={classNames("border-1", "border-black", "hover:border-0", "font-medium", "rounded-lg", "mx-2", "flex", "items-center", "px-4", "py-2", "text-sm", "text-hiperplomo_fuerte", "hover:bg-hiperorange", "hover:text-white",
                                                                {
                                                                    "bg-hiperorange text-white  border-0": item_tipo_entrega.Id === (tipo_entrega != null && tipo_entrega.Id)
                                                                })}
                                                            onClick={() => {
                                                                actualizarTipoEntrega(item_tipo_entrega)
                                                            }}>
                                                        <FontAwesomeIcon
                                                            icon={item_tipo_entrega.Id === 2 ? faMotorcycle : faStore}
                                                            style={{height: "20px"}} className="hover:text-white "/>
                                                        <span className="ml-3"> {item_tipo_entrega.Descripcion} </span>
                                                    </button>
                                                )
                                            }
                                        </div>

                                    </div>

                                    {
                                        subPedidos &&
                                        subPedidos !== null &&
                                        subPedidos !== undefined &&
                                        subPedidos.map((pedido, index2) =>
                                            <div key={index2} className='mx-2'>
                                                {pedido.check &&
                                                    <div
                                                        className="bg-hiperplomo_claro rounded-hiper_g overflow-hidden my-1 px-2 py-4">

                                                        <div className="mb-2">

                                                            <div className="flex gap-2">
                                                                <p className=" fontzize_text_carrito text-black font-bold mb-0 text-uppercase">{pedido.TipoServicio.Descripcion}</p>
                                                                <p className="fontzize_text_carrito  text-black font-bold mb-0">{pedido.Sala.Descripcion}</p>

                                                            </div>
                                                        </div>

                                                        <div className="bg-white p-2  rounded-hiper_g  mb-2">
                                                            <div className="text-start ">
                                                                <p className="text-black font-bold">Selecciona el dia de
                                                                    tu
                                                                    entrega:</p>
                                                            </div>
                                                            <div>
                                                                {
                                                                    tiene_elementos_aux_list_fechas() ?
                                                                        < >
                                                                            {
                                                                                aux_list_fechas.map((item, index3) =>
                                                                                    <div key={index3 + "2c1"}>
                                                                                        {item.IdSucursal === pedido.Sala.Id &&
                                                                                            <div
                                                                                                className='flex flex-wrap gap-2'>
                                                                                                {
                                                                                                    item.Dias.map((fecha) =>
                                                                                                        <button
                                                                                                            className={` rounded-hiper_g text-white mx-1 px-2 ${(item.seleccionado_id === fecha.IdFecha) ? 'bg-hiperorange' : 'bg-white border-1 border-black'}   `}
                                                                                                            style={{
                                                                                                                cursor: "pointer",
                                                                                                                width: '5rem',
                                                                                                                height: '5rem'
                                                                                                            }}
                                                                                                            onClick={async (e) => await seleccionarfecha(fecha.IdFecha, pedido.Sala.Id, item.Importe)}
                                                                                                            key={fecha.IdFecha}>

                                                                                                            <div
                                                                                                                className="text-white">
                                                                                                                <span
                                                                                                                    className={`text-[12px] d-block text-dark font-bold ${(item.seleccionado_id === fecha.IdFecha) ? 'text-white' : 'text-hiperplomo_suave'} `}>{get_dia_litera(fecha.Titulo)}</span>
                                                                                                                <span
                                                                                                                    className={`text-[12px] d-block text-dark font-bold ${(item.seleccionado_id === fecha.IdFecha) ? 'text-white' : 'text-hiperplomo_suave'} `}>{get_dia(fecha.Fecha)} {get_mes(fecha.Fecha)}  </span>

                                                                                                            </div>
                                                                                                        </button>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                )

                                                                            }
                                                                        </> : <></>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="h-[calc(100%-4.5rem)] overflow-y-auto mt-2 bg-white p-2">
                                                            <div className="text-start py-1">
                                                                <p className="text-black font-bold">Selecciona el dia de
                                                                    tu
                                                                    entrega:</p>
                                                            </div>
                                                            {
                                                                existe_lista_horarios() ?
                                                                    <>
                                                                        {
                                                                            list_horarios.map((h, key) =>
                                                                                <div key={"key" + key}>
                                                                                    {(h.IdSucursal === pedido.Sala.Id) ?
                                                                                        <>
                                                                                            {
                                                                                                h.horarios.map((h1, index) =>
                                                                                                    <div
                                                                                                        key={"hr" + index}>
                                                                                                        <ItemHorario
                                                                                                            idHorario={h1.IdHorario}
                                                                                                            IdHorarioEspecial={h1.IdHorarioEspecial}
                                                                                                            Descripcion={h1.Descripcion}
                                                                                                            CostoDelivery={h1.CostoDelivery}
                                                                                                            Costo={h1.Costo}
                                                                                                            sucursal={h.IdSucursal}
                                                                                                            tipo_entrega={tipo_entrega}
                                                                                                            is_check={is_item_check(h1)}
                                                                                                            changeSelec={cambiarSeleccion}
                                                                                                        >
                                                                                                        </ItemHorario>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </> :
                                                                                        <></>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </> : <> No hay</>


                                                            }


                                                        </div>

                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div className="md:w-[22%]">
                <div className="content_card_footer text-darck rounded-hiper_g"
                     style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem'}}>
                    <div className="content_child_footer mb-1">
                        <div className="px-3 py-1 bg-hiperorange mb-1 text-white"
                             style={{borderRadius: '20px', width: 'fit-content', float: 'right'}}>
                            <p className="mb-0 font-bold " style={{fontSize: '15px'}}>Bs. {ahorro_total} ahorrado</p>
                        </div>
                        <div className='flex p-1 justify-between  text-black '>
                            <span className='text-center m-0 fontzize_text_carrito font-bold'>Total:</span>
                            <span className='fontzize_text_carrito font-bold'>Bs {get_total_carrito()}</span>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center  text-center gap-2 text-white">
                        <ButtonText
                            type='button'
                            label='Volver'
                            principal={false}
                            className="bg-hiperorange_claro hover:bg-hiperorange_claro_hover text-black hover:text-black border border-black"
                            onClick={() => volverAtras()}

                        />
                        <ButtonText
                            label='Continuar'
                            principal={true}
                            className="bg-hiperorange hover:bg-hiperorange_hover"
                            onClick={() => save_datos_entrega()}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    tipo_entrega: state.BranchOffice.tipo_entrega,
    list_carrito: state.Carrito.list_carrito,
    fechas_disponibles_entrega: state.Carrito.fechas_disponibles_entrega,
    horarios_disponibles: state.Carrito.horarios_disponibles,
    respuesta_update_horario: state.Carrito.respuesta_update_horario,
})

export default connect(mapStateToProps, {
    set_tipo_entrega,
    get_fechas_entrega_sucursal_carrito,
    save_horarios,
    update_list_carrito_compras,
    get_horarios_disponible_carrito
})(ModoEntrega)