//import Layout from "../../hocs/Layout";
import React, {useEffect, useState} from "react";
import ItemCarrito from "../../widgets/item_carrito";
import {Link} from "react-router-dom";
import ItemHorario from "../../widgets/item_Horario";
import {connect} from "react-redux";
import {get_horarios_disponible_carrito, get_lista_carrito_compras} from "../../../src/redux/actions/carrito";
import {get_fechas_entrega_sucursal_carrito} from "../../../src/redux/actions/carrito";
import axios from 'axios';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faMotorcycle, faStore, faUser} from "@fortawesome/free-solid-svg-icons";
/*import {get_ubicacion} from "../../redux/actions/location"; */
import {get_ubicacion} from "../../../src/redux/actions/location";
import {info_session} from "../../../src/redux/actions/profile";
import {useNavigate} from "react-router-dom";
import {set_tipo_entrega} from "../../../src/redux/actions/branch_office";
import Swal from 'sweetalert2';
import classNames from "classnames";
import "../../assets/styles/responsive.scss"
import ButtonText from "../../widgets/button_text";
import ButtonClose from "../../widgets/button_close";
const EntregaCompraRapida = ({
                                 exit,
                                 mt_next,
                                 productos,
                                 importeTotal,
                                 set_tipo_entrega,
                                 tipo_entrega,
                                 sucursal,
                                 profile,
                                 get_lista_carrito_compras,
                                 list_carrito,
                                 fechas_disponibles_entrega,
                                 get_fechas_entrega_sucursal_carrito,
                                 horarios_disponibles,
                                 get_horarios_disponible_carrito,
                                 get_ubicacion,
                                 locations,
                                 direccion,
                                 info_session,
                                 products_shoping,
                                 width,
                                 height,
                                 importeAhorrodo
                             }) => {
    const [token] = useState(localStorage.getItem('access_token'));
    const [tipo_entregas, guardarTipoEntrega] = useState([]);
    const [arrayFechaEntregas, setArrayFechasEntregas] = useState([]);
    const [arrayHorarios, setArrayHorarios] = useState([]);

    const [subPedidos, guardarsubPedidos] = useState([]);
    const [costo_shoping] = useState(localStorage.getItem('costo_shoping'));
    const [aux_list_fechas, setAux_list_fechas] = useState([]);
    const [items_selecionados, setItems_selecionados] = useState([]);
    const [list_horarios, setList_horarios] = useState([]);
    const [list_selec_fechas, setList_selec_fechas] = useState([]);
    const [opcionselec, setOpcionselec] = useState(1);
    const [formData, setFormData] = useState({telefono: '', direccion: ''});
    const [dia, setDia] = useState({});
    const [horario, setHorario] = useState({});
    //hola use navegad
    const navigate = useNavigate();
    const [lista_meses] = useState(["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"])
    const [dias, setDias] = useState([
        {
          name: 'Lunes',
          cod: 'LU',
        },
        {
        name: 'Martes',
        cod: 'MA',
        },
        {
        name: 'Miércoles',
        cod: 'MI',
        },
        {
        name: 'Jueves',
        cod: 'JU',
        },
        {
        name: 'Viernes',
        cod: 'VI',
        },
        {
        name: 'Sábado',
        cod: 'SA',
        },
        {
        name: 'Domingo',
        cod: 'DO',
        }
      ]);
      const [meses, setMeses] = useState([
        {
          name: 'en.',
          cod: '01',
        },
        {
        name: 'feb',
        cod: '02',
        },
        {
        name: 'mar.',
        cod: '03',
        },
        {
        name: 'abr.',
        cod: '04',
        },
        {
        name: 'may.',
        cod: '05',
        },
        {
        name: 'jun.',
        cod: '06',
        },
        {
        name: 'jul.',
        cod: '07',
        },
        {
        name: 'ag.',
        cod: '08',
        },
        {
        name: 'sept.',
        cod: '09',
        },
        {
        name: 'oct.',
        cod: '10',
        },
        {
        name: 'nov.',
        cod: '11',
        },
        {
        name: 'dic.',
        cod: '12',
        }
        
      ]);

    useEffect(() => {
        if (sucursal !== null) {
            guardarTipoEntrega(sucursal.TipoEntregas);
        }
    }, [sucursal]);



    useEffect(() => {
        if (profile && direccion) {
            setFormData({telefono: profile.Telefono, direccion: direccion.Descripcion})
        }
    }, [profile, direccion]);

    useEffect(() => {
        if (!fechas_disponibles_entrega) {
            if (tipo_entrega && sucursal) {
                let sucursal_ids = [sucursal.IdSucursal];
                get_fechas_entrega_sucursal_carrito(tipo_entrega.Id, sucursal_ids);
            }
        }
    }, []);

    useEffect(() => {
        if (fechas_disponibles_entrega) {
            setArrayFechasEntregas(fechas_disponibles_entrega[0].Dias);
            let dia1 = fechas_disponibles_entrega[0].Dias[0];
            setDia(dia1);
        }
    }, [fechas_disponibles_entrega]);
    useEffect(() => {
        if (Object.keys(dia).length > 0) {
            let data = {
                IdSucursal: sucursal.IdSucursal,
                FechaEntrega: dia.Fecha,
                IdFecha: dia.IdFecha,
                Importe: importeTotal,
                IdTipoEntrega: tipo_entrega.Id,
            };
            get_horarios_disponible_carrito(data);
        }
    }, [dia]);
    useEffect(() => {
        if (horarios_disponibles) {
            setArrayHorarios(horarios_disponibles.Horarios);
            if (horarios_disponibles.Horarios.length > 0) {
                setHorario(horarios_disponibles.Horarios[0])
            } else {
                setHorario({});
            }
        }
    }, [horarios_disponibles]);

    function actualizarTipoEntrega(item_tipo_entrega) {
        set_tipo_entrega(item_tipo_entrega);
    }

    const save_datos_entrega = (e) => {
        if (Object.keys(dia).length == 0) {
            mensaje_alert("Debe Seleccionar una fecha");
            return;
        }
        if (Object.keys(horario).length == 0) {
            mensaje_alert("Debe Seleccionar un horario de entrega");
            return;
        }
        let data = {
            productos: productos,
            IdTipoEntrega: tipo_entrega.Id,
            IdUbicacion: direccion.Id,
            Horario: {
                IdSucursal: sucursal.IdSucursal,
                IdFecha: dia.IdFecha,
                IdHorario: horario.IdHorario,
                IdHorarioEspecial: horario.IdHorarioEspecial,
                CostoDelivery: horario.CostoDelivery
            }

        };
        mt_next(data);
    };

    function existe_lista_horarios() {
        return arrayHorarios.length > 0 ? true : false;
    }

    function Cerrar() {
        exit()
    }

    function cambiarSeleccion(horarioId, IdHorarioEspecial, IdSucursal, CostoDelivery, Costo) {
        let horario = arrayHorarios.find(h => h.IdHorario === horarioId);
        setHorario(horario);
    }

    function is_item_check(h1) {
        return horario == h1 ? true : false;
    }

    function mensaje_alert(text) {
        Swal.fire({
            position: 'top',
            icon: 'error',
            title: text,
            showConfirmButton: false,
            timer: 1500
        })
    }
    function get_dia_litera(fecha) {
        var aux = fecha.split(" ");
        let d = dias_text(aux[0]);
        if(d){
            return d;
        }
        return aux[0];
    }
    function dias_text(dia){
        let di= dias.find((item)=>item.cod ===dia);
        if(di){
            return di.name;
        }
        return dia;
    }
    function get_dia(fecha) {
        var aux = fecha.split("/");
        return aux[0];
    }
    function get_mes(fecha) {
        var aux = fecha.split("/");
        let m = mes_text(aux[1]);
        if(m){
            return m;
        }
        return m;

    }
    function mes_text(mes){
        let me= meses.find((item)=>item.cod ===mes);
        if(me){
            return me.name;
        }
        return me;
    }


    return (
        <>
            <div className={classNames(
                    {"altura_carrito_default contenedor_mobile md:w-[60%]": width > 768},
                    {"h-[calc(100%-12rem)] w-100": width <= 768},
                )}>
                <div className="w-100  bg-white rounded-2xl h-full overflow-hidden">
                    <div className="bg-white rounded-hiper_g ">
                        <div
                            className="bg-hiperorange w-100 relative text-white flex items-center justify-center    content_title">
                            <h2 className='p-0 m-0 title_carrito_responsivo'>Modo de entrega </h2>
                            <div className="absolute right-2 ">
                                <ButtonClose className="text-white bg-transparent" onClick={() => exit()}/>
                            </div>
                        </div>
                        <div className={classNames("bg-white", "overflow-y-auto", "container_scroll",
                            {"max-h-[62vh]": height <= 667},
                            {"max-h-[66vh]": height > 667 && height <= 843},
                            {"max-h-[72vh]": height > 843},
                        )}>
                            <div className=" h-full my-2 mx-2 p-2 bg-hiperplomo_claro rounded-hiper_g">
                                <p className="text-black fontzize_text_carrito mb-4 font-bold">Selecciona el modo de entrega</p>

                                <div className="h-full flex flex-wrap gap-2 justify-center">
                                    {
                                        tipo_entregas &&
                                        tipo_entregas !== null &&
                                        tipo_entregas !== undefined &&
                                        tipo_entregas.map((item_tipo_entrega, index) =>
                                            <button key={index}
                                                    className={classNames("border-1","border-black","hover:border-0","font-medium", "rounded-lg", "mx-2", "flex", "items-center", "px-4", "py-2", "text-sm", "text-hiperplomo_fuerte", "hover:bg-hiperorange", "hover:text-white",
                                                        {
                                                            "bg-hiperorange text-white  border-0": item_tipo_entrega.Id === (tipo_entrega != null && tipo_entrega.Id)
                                                        })}
                                                    onClick={() => {
                                                        actualizarTipoEntrega(item_tipo_entrega)
                                                    }}>
                                                <FontAwesomeIcon icon={item_tipo_entrega.Id === 2 ? faMotorcycle : faStore}
                                                                 style={{height: "20px"}} className="hover:text-white "/>
                                                <span className="ml-3"> {item_tipo_entrega.Descripcion} </span>
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="bg-hiperplomo_claro rounded-hiper_g overflow-hidden my-1  mx-2 px-2 py-2 " key={sucursal.IdSucursal}>

                                <div className="flex py-1 gap-2">
                                    <p className=" fontzize_text_carrito text-black font-bold mb-0 text-uppercase">{'Sucursal'}</p>
                                    <p className="fontzize_text_carrito  text-black font-bold mb-0">{sucursal.Descripcion}</p>
                                </div>


                                <div className="flex  flex-column h-[calc(100%-4.5rem)]">
                                    <div className="p-2 bg-white  rounded-hiper_g mb-2 ">
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black  mb-2"
                                                   htmlFor="name">
                                                <FontAwesomeIcon icon={faUser}
                                                                 className="mr-1"/>
                                                Teléfono
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="name" type="number" name="telefono"
                                                value={formData.telefono}
                                                disabled
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black  mb-2"
                                                   htmlFor="name">
                                                <FontAwesomeIcon icon={faLocationDot}
                                                                 className="mr-1"/>
                                                Dirección
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="name" type="text" name="direccion"
                                                value={formData.direccion}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-white rounded-hiper_g p-2">
                                        <p className="text-black fontzize_text_carrito mb-4 font-bold">Selecciona el modo de entrega</p>

                                        <div className="flex flex-wrap gap-2">
                                            {
                                                arrayFechaEntregas.map((fecha, index4) =>
                                                        <button
                                                            className={` rounded-hiper_g text-white mx-1 px-2 ${(dia.IdFecha === fecha.IdFecha) ? 'bg-hiperorange' : 'bg-white border-1 border-black'}   `}
                                                            style={{cursor: "pointer",width:'5rem',height:'5rem'}}
                                                            onClick={async (e) => await setDia(fecha)}
                                                            key={index4}>

                                                            <div
                                                                className="text-white">
                                                                        <span
                                                                            className={`text-[12px] d-block text-black font-bold ${(dia.IdFecha === fecha.IdFecha) ? 'text-white' : 'text-hiperplomo_suave'} `}>{get_dia_litera(fecha.Titulo)}</span>
                                                                <span
                                                                    className={`text-[12px] d-block text-black font-bold ${(dia.IdFecha === fecha.IdFecha) ? 'text-white' : 'text-hiperplomo_suave'} `}>{get_dia(fecha.Fecha) } {get_mes(fecha.Fecha)}  </span>

                                                            </div>
                                                        </button>
                                                    // <button
                                                    //     className={`h-[3.5rem] w-16 rounded text-white mx-1 px-2 ${(dia.IdFecha === fecha.IdFecha) ? 'bg-hiperorange' : 'bg-hipergris'}   `}
                                                    //     style={{cursor: "pointer"}}
                                                    //     onClick={(e) => setDia(fecha)}
                                                    //     key={index4}>

                                                    //     <div className="text-white">
                                                    //             <span
                                                    //                 className={`text-[12px] d-block text-dark font-bold ${(dia.IdFecha === fecha.IdFecha) ? 'text-white' : 'text-dark'} `}>{fecha.Titulo}</span>
                                                    //     </div>
                                                    // </button>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="h-[calc(100%-4.5rem)] bg-white overflow-y-auto mt-2 p-2">
                                        <div className="text-start py-1" >
                                            <p className="text-black font-bold">Selecciona el dia de tu entrega:</p>
                                        </div>
                                        {
                                            existe_lista_horarios() ?
                                                <>
                                                    {
                                                        arrayHorarios.map((h1, index) =>
                                                            <ItemHorario
                                                                key={index}
                                                                idHorario={h1.IdHorario}
                                                                IdHorarioEspecial={h1.IdHorarioEspecial}
                                                                Descripcion={h1.Descripcion}
                                                                CostoDelivery={h1.CostoDelivery}
                                                                Costo={h1.Costo}
                                                                sucursal={sucursal.IdSucursal}
                                                                tipo_entrega={tipo_entrega}
                                                                is_check={is_item_check(h1)}
                                                                changeSelec={cambiarSeleccion}

                                                            >
                                                            </ItemHorario>
                                                        )
                                                    }
                                                </> : <> No hay</>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:w-[22%]">
            <div className="content_card_footer  text-darck  rounded-hiper_g "
                 style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem'}}
                >
                    <div className="content_child_footer mb-1">
                        <div className="px-3 py-1 bg-hiperorange mb-1 text-white"
                             style={{borderRadius: '20px', width: 'fit-content', float: 'right'}}>
                            <p className="mb-0 font-bold " style={{fontSize: '15px'}}>Bs. {importeAhorrodo} ahorrado</p>
                        </div>
                        <div className='flex p-1 justify-between  text-black '>
                            <span className='text-center m-0 fontzize_text_carrito font-bold'>Total:</span>
                            <span className='fontzize_text_carrito font-bold'>Bs {importeTotal}</span>
                        </div> 
                    </div>
                    
                    <div className="flex flex-col justify-center  text-center gap-2 text-white">
                        <ButtonText
                            type='button'
                            label='Seguir comprando'
                            principal={false}
                            className="bg-hiperorange_claro hover:bg-hiperorange_claro_hover text-black hover:text-black border border-black"
                            onClick={() => Cerrar()}
                            style={{border: '1px solid black'}}
                        />
                        <ButtonText
                            label='Continuar'
                            principal={true}
                            className="bg-hiperorange hover:bg-hiperorange_hover"
                            onClick={() => save_datos_entrega()}
                        />

                    </div>
                </div>

                {/* <div className="flex flex-col bg-transparent rounded-2xl  gap-2  text-white" >

                    <ButtonText
                        label='Continuar'
                        principal={true}
                        className="bg-[#84B111]"
                        onClick={() => save_datos_entrega()}
                    />

                    <ButtonText
                        label='Volver'
                        principal={false}
                        className="bg-[#32338E]"
                        onClick={() => Cerrar()}
                    />
                </div> */}


            </div>
        </>
    );
};


const mapStateToProps = state => ({
    list_carrito: state.Carrito.list_carrito,
    fechas_disponibles_entrega: state.Carrito.fechas_disponibles_entrega,
    horarios_disponibles: state.Carrito.horarios_disponibles,
    sucursal: state.BranchOffice.sucursal,
    locations: state.Location.locations,
    direccion: state.Location.direccion,
    profile: state.Profile.profile,
    products_shoping: state.Shopping_list.products_shoping,
    tipo_entrega: state.BranchOffice.tipo_entrega,
})


export default connect(mapStateToProps, {
    get_lista_carrito_compras,
    get_fechas_entrega_sucursal_carrito,
    get_horarios_disponible_carrito,
    get_ubicacion,
    info_session,
    set_tipo_entrega
})(EntregaCompraRapida)