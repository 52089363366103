import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faPenToSquare, faTrash,faClose} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {delete_ubicacion, get_ubicacion, update_ubicacion} from "../../redux/actions/location";
import React, {useEffect} from 'react';
import classNames from "classnames";
import ButtonText from "../../widgets/button_text";
import ButtonClose from "../../widgets/button_close";
import {cleadSwAlert} from "../../redux/actions/swalert";
import Page_message from "../Page_message";
import {Circles} from "react-loader-spinner";
import "../../assets/styles/responsive.scss";

function Direccion({show_alert,redirect,cleadSwAlert,get_ubicacion, update_ubicacion,delete_ubicacion, locations,direccion,close_modal,peticion}) {
    const navigate = useNavigate();

    useEffect(() => {
        if (show_alert && redirect!==null) {
            if (redirect.type === 'modal' && !redirect.type_modal) {
                console.log("cleadSwAlert2")
                cleadSwAlert();
                if (!redirect.type_modal) {
                    close_modal();
                }
            }
        }
    }, [show_alert]);

    useEffect(() => {
        if (locations.length == 0) {
            get_ubicacion();
        }
    }, []);

    useEffect(() => {
        return () => {
            cleadSwAlert()
        };
    }, []);
    async function delete_registro(id) {
        let resp = await Page_message.delete_register_confirmation();
        if (resp) {
          delete_ubicacion(id);
        }

 
  }

    const establecer_ubicacion_principal = async (locationp, e) => {
        if (validar_location(locationp) && direccion.Id !== locationp.Id) {

            var datos =
                {
                    "Descripcion": locationp.Descripcion,
                    "Direccion": locationp.Direccion,
                    "Referencia": locationp.Referencia,
                    "Latitud": locationp.Latitud,
                    "Longitud": locationp.Longitud,
                    "EsPrincipal": true,
                    "Id": locationp.Id
                };
            let object = {
                type:'modal',
                type_modal: false,
                ruta:null,
            };
            try {
                const res = await update_ubicacion(datos, locationp.Id,object);
                await cleadSwAlert();
                await close_modal();
            } catch (error) {
                
            }
         
        }
    };


    const eliminarDireccion = async (locationp) => {
        if (direccion.Id !== locationp.Id) {
            delete_registro(locationp.Id);
           
        }
    }

    function validar_location(locationp) {
        return typeof (locationp) != 'undefined' && locationp != null;
    }

    function irDireccion(){
        // navigate("/nueva_direccion");
        navigate("/nueva_direccion_registro");
    }

    return (
        <>
            <div className="w-[21rem] bg-white rounded-hiper_g overflow-hidden">
                 <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                    <h2 className='p-0 m-0 title_carrito_responsivo'>Mis direcciones</h2>
                    <div className="absolute right-2 ">
                        <ButtonClose className="text-white bg-transparent"  onClick={() => close_modal()} />
                    </div>
                </div>  
       
                <div className="p-3">
                    {peticion &&
                        <div className='flex justify-center text-black text-center pb-2'>
                            <Circles
                                color="#32338E"
                                width={30}
                                height={30}
                                ariaLabel="circles-loading"
                                visible={true}
                            />
                        </div>
                    }                            
                    <div className="container_scroll" style={{minHeight:'15rem',maxHeight:'20rem', overflow: 'auto'}}>
                        <div className="flex flex-column gap-2">

                            {
                                locations &&
                                locations !== null &&
                                locations !== undefined &&
                                locations.map((location) => (

                                    <div key={location.Id}
                                        className={classNames("flex", "justify-between", "px-2","mx-1", "py-0.5", "rounded-lg", "border-hiperorange", "border-2", "text-[#DC842D]",
                                            {
                                                "bg-hiperorange text-white ": location.EsPrincipal
                                            })}
                                        style={{height: 'fit-content'}}
                                    >
                                        <div className="cursor-pointer w-9/12" onClick={(e) => establecer_ubicacion_principal(location, e)}>
                                            <FontAwesomeIcon icon={faLocationDot}/>

                                            <span className="ml-3">{location.Descripcion} </span>
                                        </div>
                                        <div>
                                            <Link to={`/actualizar_direccion_registro/${location.Id}`}>
                                                <FontAwesomeIcon icon={faPenToSquare} className={classNames("mx-2", "cursor-pointer",
                                                    {
                                                        "text-white": location.EsPrincipal,
                                                        "text-hiperorange": !location.EsPrincipal
                                                    })}/>
                                            </Link>
                                            {
                                                direccion.Id !== location.Id &&
                                                <FontAwesomeIcon icon={faTrash} className="cursor-pointer" onClick={()=>eliminarDireccion(location)}/>
                                            }

                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                 
                    <div className="pt-2  text-white">
                        <ButtonText
                            label='Añadir nueva dirección'
                            principal={false}
                            className="bg-hiperblue hover:bg-hiperblue_hover text-white"
                            onClick={() => irDireccion()}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    locations: state.Location.locations,
    direccion: state.Location.direccion,
    show_alert: state.Swalerts.show_alert,
    redirect: state.Swalerts.redirect,
    peticion: state.Peticion.peticion,
})

export default connect(mapStateToProps, {
    get_ubicacion, update_ubicacion,delete_ubicacion,cleadSwAlert,
})(Direccion)