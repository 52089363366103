import React, {useEffect, useState} from "react";
import Layout from "../hocs/Layout";
import '../assets/styles/mystyle.scss';
import "swiper/css";
import "swiper/css/pagination";
import {connect} from "react-redux";
import {get_homepage} from "../redux/actions/home";
import Categoria from "../components/home/Categoria";
import Banner from "../components/home/Banner";
import CarouselProducto from "../components/home/CarouselProducto";
import Market from "../components/home/Market";
import {get_carrito_por_sucursal, store_carrito} from "../redux/actions/carrito";
import ListaCompra from "../components/home/ListaCompra";
import Alert from "../components/Swalert";
import {actualizar_tokenfb_usuario} from "../../src/redux/actions/profile";
import {limpiar_data_lista_compra} from '../../src/redux/actions/shoppingList';
import Capsula from "../components/home/Capsula";
import Capsula2 from "../components/home/Capsula2";
import Capsula3 from "../components/home/Capsula3";
import Pedidos from "../components/home/Pedidos";
import BuscadorGeneral from "../widgets/BuscadorGeneral";

const Home = ({
                  get_homepage,
                  sucursal,
                  cambio_sucursal,
                  novedades,
                  mas_vendidos,
                  store_carrito,
                  carritos,
                  cambio_carrito,
                  direccion,
                  tipo_entrega,
                  producto_delete,
                  get_carrito_por_sucursal,
                  profile,
                  homes,
                  bool_delete_pedido,
                  actualizar_tokenfb_usuario,limpiar_data_lista_compra,carrito_favorito,cambio_detalle_carrito
              }) => {

    const IdSucursal = sucursal ? sucursal.IdSucursal : '';
    const [arraySeccion, SetArraySeccion] = useState([]);


    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'tfb') {
                update_datos_user();
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);
    useEffect(() => {
        return () => {
            limpiar_data_lista_compra();
        }
    }, [])
    useEffect(()=>{
        if(carrito_favorito){
            console.log("hubo cambio favorit");
            // SetArraySeccion([]);
            cargarDataosHome();
        }
    },[carrito_favorito]);

    useEffect(() => {
        if (homes) {
            let array = homes.Secciones.filter((ss) => ss.IdSeccion !== 1); // menos categoria
            SetArraySeccion(array);
            // console.log("actualiz");
        }
    }, [homes]);
    useEffect(() => {
        if (carritos.length === 0 && !bool_delete_pedido) {
            store_carrito_function();
        }
    }, [carritos]);

    useEffect(() => {
        if (arraySeccion.length > 0) {

        }
    }, [arraySeccion]);
    useEffect(() => {
        store_carrito_function();
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
        if (sucursal && cambio_sucursal) {
            SetArraySeccion([]);
            cargarDataosHome();
        }
    }, [sucursal,cambio_sucursal]);

 


    async function cargarDataosHome() {
        await get_homepage(sucursal.IdSucursal, sucursal.IdMarket, tipo_entrega.Id);
        await update_datos_user();
    }

    async function store_carrito_function() {
        if (cambio_carrito) {
            if (direccion) {
                await store_carrito(sucursal, tipo_entrega, direccion, carritos, producto_delete);
            }
        }else if (carritos.length === 0 && !cambio_detalle_carrito) {
            console.log("cargado home")
            get_carrito_por_sucursal(sucursal)
        }
    }

    function update_datos_user() {
        const datofb = localStorage.getItem('tfb');
        if (datofb) {
            if (datofb !== '' && profile !== null) {
                if (datofb !== profile.TokenFirebase) {
                    const datouser = {
                        Telefono: profile.Telefono,
                        NroDocumento: profile.NroDocumento,
                        TokenFirebase: datofb,
                    };
                    actualizar_tokenfb_usuario(datouser);
                }
            }
        }
    }

    const miEstilo = {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        backgroundColor: "#84B111",
        position:'absolute',
        right:'0'
    };



    return (
        <Layout>
            <Alert></Alert>
            <Categoria/>
            <div className="block md:hidden p-2">
                <BuscadorGeneral/>
            </div>
            <Pedidos/>
            <div className="container mx-auto relative z-[-1] ">
                {

                    arraySeccion.map((ss, index) =>
                        <div key={index}>
                            {
                                !ss.EsPublicidad && ss.IdTipoSeccion === 2 && ss.ProductosOferta &&
                                <CarouselProducto descripcion={ss.Descripcion} Items={ss.ProductosOferta}
                                                  ps_seccion={ss} ps_IdSucursal={IdSucursal}/>
                            }
                            {
                                !ss.EsPublicidad && ss.IdTipoSeccion === 14 &&
                                <Banner descripcion={ss.Descripcion} Items={ss.Banner}
                                        ps_seccion={ss} ps_IdSucursal={IdSucursal}/>
                            }
                            {
                                !ss.EsPublicidad && ss.IdTipoSeccion === 15 &&
                                <Banner descripcion={ss.Descripcion} Items={ss.Banner}
                                        ps_seccion={ss} ps_IdSucursal={IdSucursal}/>
                            }
                            {
                                !ss.EsPublicidad && ss.IdTipoSeccion === 6 &&
                                <CarouselProducto descripcion={ss.Descripcion} Items={ss.ProductosMasVendidos}
                                                  ps_seccion={ss} ps_IdSucursal={IdSucursal}/>
                            }
                            {
                                !ss.EsPublicidad && ss.IdTipoSeccion === 7 &&
                                <CarouselProducto descripcion={ss.Descripcion} Items={ss.ProductosMasVendidosCliente}
                                                  ps_seccion={ss} ps_IdSucursal={IdSucursal}/>
                            }
                            {
                                ss.IdTipoSeccion === 8 &&
                                <ListaCompra/>
                            }
                            {
                                ss.IdTipoSeccion === 9 &&
                                <Market/>
                            }
                            {
                                ss.IdTipoSeccion === 10 && ss.ProductosTemporada.length > 0 &&
                                <CarouselProducto descripcion={ss.Descripcion} ps_seccion={ss}
                                                  Items={ss.ProductosTemporada} ps_IdSucursal={IdSucursal}/>
                            }
                            {
                                ss.IdTipoSeccion === 11 && ss.Capsula.length > 0 &&
                                <Capsula descripcion={ss.Descripcion} Items={ss.Capsula}/>
                            }
                            {
                                ss.IdTipoSeccion === 12 && ss.CustomCapsula !== null && ss.CustomCapsula.length > 0 &&
                                <Capsula2 descripcion={ss.Descripcion} Items={ss.CustomCapsula}/>
                            }
                            {
                                ss.IdTipoSeccion === 13 && ss.Capsula.length > 0 &&
                                <Capsula3 descripcion={ss.Descripcion} Items={ss.Capsula}/>
                            }
                        </div>
                    )
                }
            </div>
        </Layout>
    );
};


const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    homes: state.Home.homes,
    novedades: state.Home.novedades,
    mas_vendidos: state.Home.mas_vendidos,
    tipo_entrega: state.BranchOffice.tipo_entrega,
    direccion: state.Location.direccion,
    carritos: state.Carrito.carrito,
    bool_delete_pedido: state.Carrito.bool_delete_pedido,
    producto_delete: state.Carrito.producto_delete,
    profile: state.Profile.profile,
    cambio_carrito: state.Carrito.cambio_carrito,
    cambio_sucursal: state.BranchOffice.cambio_sucursal,
    carrito_favorito: state.Carrito.carrito_favorito,
    cambio_detalle_carrito: state.Carrito.cambio_detalle_carrito,

})

export default connect(mapStateToProps, {
    store_carrito, get_homepage, get_carrito_por_sucursal, actualizar_tokenfb_usuario,limpiar_data_lista_compra
})(Home)
