import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {get_sucursales, set_sucursal, get_ciudades, get_sucursales_cerca} from "../../redux/actions/branch_office";

import classNames from "classnames";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import {isPointInPolygon, getDistance, isPointWithinRadius} from 'geolib';
import Location from "../../redux/reducers/location";
import "../../assets/styles/responsive.scss"
import convertSlug from "../../components/Utils/slug";
import {update_url_navigation} from "../../redux/actions/url_navegacion";
import {limpiar_carrito, store_carrito_data} from "../../redux/actions/carrito";
import {info_session} from "../../redux/actions/profile";
import ButtonClose from "../../widgets/button_close";
const containerStyle3 = {
    borderRadius: '2rem',
    width: '900px',
};
const breakpoints = {
    200: {
        slidesPerView: 1,
        spaceBetween: 2,
    },
    300: {
        slidesPerView: 1,
        spaceBetween: 2,
    },
    400: {
        slidesPerView: 1,
        spaceBetween: 2,
    },

    640: {
        slidesPerView: 2,
        spaceBetween: 10,
    },
    768: {
        slidesPerView: 2,
        spaceBetween: 10,
    },

    992: {
        slidesPerView: 2,
        spaceBetween: 10,
    },
    1200: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
    1400: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
};

function Sucursal({
                      get_sucursales,
                      get_sucursales_cerca,
                      set_sucursal,
                      branch_offices,
                      sucursales,
                      onCloseModal,
                      direccion,
                      tipo_entrega,
                      sucursal,
                      carrito,
                      producto_delete,
                      update_url_navigation,
                      get_ciudades,
                      ciudades,
                      limpiar_carrito,
                      store_carrito_data,
                      info_session,
                      profile,
                      
                  }) {

    const [array_sucursales, guardarSucursales] = useState([]);
    const [AuxSucursal, guardarAuxSucursal] = useState(sucursal.IdSucursal);
    const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);
    const [sliderPages, setSliderPages] = useState(2);
    const [selectedCiudad, setSelectedCiudad] = useState({
        Nombre: ''
    });
    const [sucursalPorCiudad, setSucursalPorCiudad] = useState([]);
    const navigate = useNavigate();

    const actualizarAnchoPantalla = () => {
        setAnchoPantalla(window.innerWidth);
        setSliderPages(window.innerWidth > 760 ? 2 : 1);
    };

    useEffect(() => {
        if (profile) {
            if (profile.Ubicaciones.length === 0) {
                get_sucursales();
            }
        } else {
            info_session()
        }
    }, [profile]);


    useEffect(() => {
        if (sucursal.IdSucursal !== AuxSucursal) {
            guardarAuxSucursal(sucursal.IdSucursal);
            limpiar_carrito()
            onload_slug(sucursal);
            onCloseModal();
        }
    }, [sucursal]);

    useEffect(() => {
        if (direccion) {
            get_sucursales_cerca(direccion);
            guardarCarrito();
        }
        window.addEventListener('resize', actualizarAnchoPantalla);
        getCiudades();
    }, [direccion]);
    useEffect(() => {
        if (ciudades.length>0){
            if (sucursal) {
                let item_ciudad = ciudades.find((c) => c.IdRegion === sucursal.idRegion);
                optionCiudad(item_ciudad);
            }
        }

    }, [ciudades]);

    useEffect(() => {
        guardarSucursales(sucursales)
    }, [sucursales]);


    const calcularDistanciaEntreDosCoordenadas = (lat1, lon1, lat2, lon2) => {
        // Convertir todas las coordenadas a radianes
        lat1 = gradosARadianes(lat1);
        lon1 = gradosARadianes(lon1);
        lat2 = gradosARadianes(lat2);
        lon2 = gradosARadianes(lon2);
        // Aplicar fórmula
        const RADIO_TIERRA_EN_KILOMETROS = 6371;
        let diferenciaEntreLongitudes = (lon2 - lon1);
        let diferenciaEntreLatitudes = (lat2 - lat1);
        let a = Math.pow(Math.sin(diferenciaEntreLatitudes / 2.0), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(diferenciaEntreLongitudes / 2.0), 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return RADIO_TIERRA_EN_KILOMETROS * c;
    };
    const gradosARadianes = (grados) => {
        return grados * Math.PI / 180;
    };


    async function actualizarSucursal(item_sucursal) {
        set_sucursal(item_sucursal);
    }

    const guardarCarrito = async () => {
        let params = {
            IdMarket: sucursal.IdMarket,
            IdSucursal: sucursal.IdSucursal,
            IdTipoEntrega: tipo_entrega.Id,
            IdDireccion: direccion.Id,
        };
        await store_carrito_data(params, carrito, producto_delete);
    }

    const onload_slug = async (item_sucursal) => {
        // NOTA
        let ciudad = await ciudadSeleccionado(item_sucursal.idRegion);
        let sucursal_select = await sucursalSeleccionado(item_sucursal.IdSucursal);
        let parametro_url = {
            ciudad: convertSlug.data_slug(ciudad.Nombre),
            sucursal: convertSlug.data_slug(sucursal_select.Descripcion)
        }
        let resp = (parametro_url.ciudad + ' ' + parametro_url.sucursal);
        let new_url = convertSlug.data_barra(resp);

        let url_parameters = new_url;
        await update_url_navigation({
            url_parameters,
            id_sucursal: item_sucursal.IdSucursal,
            id_ciudad: item_sucursal.idRegion
        });
        navigate("/" + url_parameters);

    }

    function sucursalSeleccionado(IdSucursal) {
        let seleccionado = null;
        sucursales.forEach((sucursal) => {
            let item = sucursal.Locatarios.find(pre => parseInt(pre.IdSucursal) === parseInt(IdSucursal));
            if (item) {
                seleccionado = item;
            }
        })
        return seleccionado;
    }

    function ciudadSeleccionado(id_region) {
        if (validateCiudad) {
            let seleccionado = ciudades.find(pre => parseInt(pre.IdRegion) === parseInt(id_region));
            return seleccionado;
        }
    }

    const getCiudades = async () => {
        if (ciudades.length === 0) {
            await get_ciudades();
        }
    }

    function validateCiudad() {
        return ciudades.length > 0 ? true : false;
    }

    function optionCiudad(ciudad) {
        setSelectedCiudad({Nombre: ciudad.Nombre, Latitud: ciudad.Latitud, Longitud: ciudad.Longitud});
        let direccion = {
            Latitud: ciudad.Latitud,
            Longitud: ciudad.Longitud
        }
        onloadSucursalesCiudad(branch_offices, direccion);
    }
    function onloadSucursalesCiudad(dato, direccion) {

        let arrayDato = dato.length;
        let array_aux = [];

        if (arrayDato > 0) {
            dato.forEach((sucursalz) => {
                let coberturaSucursal = null;
                coberturaSucursal = isPointWithinRadius(
                    {
                        latitude: parseFloat(sucursalz.UbicacionLatitud),
                        longitude: parseFloat(sucursalz.UbicacionLongitud)
                    }, //punto dentro del perimetro
                    {latitude: parseFloat(direccion.Latitud), longitude: parseFloat(direccion.Longitud)}, //punto centro desde
                    8000);

                if (coberturaSucursal) {
                    array_aux.push(sucursalz);
                }
            });
            if (array_aux.length == 0) {
                array_aux = dato;
            }
        }
        setSucursalPorCiudad(array_aux);
    }
    return (
        <>
            <div className='bg-white overflow-hidden'>
                <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                    <h2 className='p-0 m-0 title_carrito_responsivo'>Seleccione una sucursal</h2>
                    <div className="absolute right-2 ">
                        <ButtonClose className="text-white bg-transparent"  onClick={() => onCloseModal()} />
                    </div>
                </div> 
                {/* <h1 className="text-4xl font-inter font-normal text-center text-hiperblue texto_sucursal">Seleccione una
                    sucursal</h1> */}

                <div
                    className='w-full flex gap-2 justify-center contenedor_flex contenedor_mobile items-center pt-4'>

                    {ciudades && !direccion &&
                    ciudades.length > 0 &&
                    ciudades.map((item_ciudad, index) =>
                        <button key={index}
                                className="rounded-full  hover:bg-[#32338E] hover:text-white p-1 px-4 min-w-[10rem]"
                                style={selectedCiudad.Nombre === item_ciudad.Nombre ? {
                                    backgroundColor: '#32338E',
                                    color: 'white'
                                } : {backgroundColor: 'white', color: '#7C7C7C'}}

                                onClick={() => optionCiudad(item_ciudad)}>{item_ciudad.Nombre}
                        </button>
                    )
                    }
                </div>
                <div className='containerStyle3Sucursal'>
                    <div>
                        {/* {selectedCiudad.Nombre === '' ? (<div>sin seleccion</div>):<> {selectedCiudad.Nombre }</>} */}
                        <Swiper
                            // slidesPerView={sliderPages}
                            spaceBetween={10}
                            breakpoints={breakpoints}
                            slidesPerView={1}
                            loop={false}
                            loopFillGroupWithBlank={true}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper bg-white rounded-3xl py-6"
                            style={{
                                "--swiper-navigation-color": "#32338E",
                                "--swiper-navigation-size": "30px"
                            }}

                        >
                            {

                                selectedCiudad.Nombre === '' ?(
                                    array_sucursales &&
                                    array_sucursales !== null &&
                                    array_sucursales !== undefined &&
                                    array_sucursales.map((branch_office, index) =>
                                        <SwiperSlide key={index}>
                                            <div className="px-4 mx-auto flex justify-center">
                                                <div
                                                    className="bg-zinc-200 max-w-sm border shadow-lg rounded-xl px-4 py-5">
                                                    <div className="font-inter font-bold text-center mb-2">
                                                        {branch_office.Descripcion}
                                                        <br/>
                                                        {(branch_office.distancia)} km
                                                    </div>
                                                    <div className="responsive_grid gap-4">
                                                        {
                                                            branch_office.Locatarios.map((locatario, index2) =>

                                                                <div key={index2}
                                                                     className={classNames("border", "rounded-2xl", "overflow-auto", "cursor-pointer",
                                                                         {
                                                                             "bg-hiperorange": locatario.IdSucursal === (sucursal && sucursal.IdSucursal),
                                                                             "bg-white": locatario.IdSucursal !== (sucursal && sucursal.IdSucursal),
                                                                         })}
                                                                     id={locatario.IdSucursal}
                                                                     onClick={() => actualizarSucursal(locatario)}>
                                                                    <img
                                                                        src={locatario.TipoServicio.UrlFoto}
                                                                        alt=""/>
                                                                    <div
                                                                        className="w-100 bg-hiperblue text-white text-center py-2 "
                                                                    >
                                                                        {locatario.TipoServicio.Descripcion}
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )

                                ):(

                                    sucursalPorCiudad &&
                                    sucursalPorCiudad !== null &&
                                    sucursalPorCiudad !== undefined &&
                                    sucursalPorCiudad.map((branch_office, index) =>
                                        <SwiperSlide key={index}>
                                            <div className="px-4 mx-auto flex justify-center">
                                                <div
                                                    className="bg-zinc-200 max-w-sm border shadow-lg rounded-xl px-4 py-5">
                                                    <div className="font-inter font-bold text-center mb-2">
                                                        {branch_office.Descripcion}
                                                        <br/>
                                                        {(branch_office.distancia)} km
                                                    </div>
                                                    <div className="responsive_grid gap-4">
                                                        {
                                                            branch_office.Locatarios.map((locatario, index2) =>

                                                                <div key={index2}
                                                                     className={classNames("border", "rounded-2xl", "overflow-auto", "cursor-pointer",
                                                                         {
                                                                             "bg-hiperorange": locatario.IdSucursal === (sucursal && sucursal.IdSucursal),
                                                                             "bg-white": locatario.IdSucursal !== (sucursal && sucursal.IdSucursal),
                                                                         })}
                                                                     id={locatario.IdSucursal}
                                                                     onClick={() => actualizarSucursal(locatario)}>
                                                                    <img
                                                                        src={locatario.TipoServicio.UrlFoto}
                                                                        alt=""/>
                                                                    <div
                                                                        className="w-100 bg-hiperblue text-white text-center py-2 "
                                                                    >
                                                                        {locatario.TipoServicio.Descripcion}
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                )

                            }
                        </Swiper>
                    </div>

                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    profile: state.Profile.profile,
    branch_offices: state.BranchOffice.branch_offices,
    sucursales: state.BranchOffice.sucursales,
    sucursal: state.BranchOffice.sucursal,
    direccion: state.Location.direccion,
    tipo_entrega: state.BranchOffice.tipo_entrega,
    ciudades: state.BranchOffice.ciudades,
    carrito: state.Carrito.carrito,
    producto_delete: state.Carrito.producto_delete,
})

export default connect(mapStateToProps, {
    info_session, get_sucursales_cerca,
    get_sucursales, set_sucursal, update_url_navigation, get_ciudades, limpiar_carrito, store_carrito_data
})(Sucursal)