import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import SeccionInicio from "../../widgets/seccion_inicio";
const breakpoints = {
    300: {
        slidesPerView: 1,
        spaceBetween: 5,
    },
    400: {
        slidesPerView: 1,
        spaceBetween: 5,
    },
    500: {
        slidesPerView: 1,
        spaceBetween: 5,
    },
    640: {
        slidesPerView: 1,
        spaceBetween: 5, //10
    },
    768: {
        slidesPerView: 2,
        spaceBetween: 5, //10
    },

    992: {
        slidesPerView: 2,
        spaceBetween: 5,//10
    },
    1200: {
        slidesPerView: 3,
        spaceBetween: 5, //10
    },
    1300: {
        slidesPerView: 3,
        spaceBetween: 5, //30
    },

    1400: {
        slidesPerView: 4,
        spaceBetween: 5, //30
    },

};

function Capsula2({descripcion, Items}) {
    const [productos, guardarProductos] = useState([]);
    useEffect(() => {
        guardarProductos(Items);
    }, []);
    return (
        <>
            <div className="mb-6 mt-2">
                <div className="flex justify-between">
                    {/*<h3 className="text-hiperblue text-xl">{descripcion}</h3>*/}
                </div>
               
                <div className="h-auto w-[100%]">
                    <Swiper
                        modules={[Navigation]}
                        breakpoints={breakpoints}
                        slidesPerView={1}
                        spaceBetween={5}
                        loop={false}
                        navigation={true}
                        className="mySwiper"
                        style={{"--swiper-navigation-color": "#32338E",
                        "--swiper-navigation-size": "30px"
                        }}
                    >
                        {
                            productos &&
                            productos !== null &&
                            productos !== undefined &&
                            productos.map((producto, index) => (
                                <SwiperSlide key={index} className="flex justify-center">
                                    <div className="relative my-2 mx-2">
                                        <SeccionInicio
                                            texto1={producto.Titulo}
                                            texto2={producto.Descripcion}
                                            imagen={producto.UrlFoto}
                                            url={producto.Url_Destino}
                                        />    
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
              
            </div>
        </>
    );
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(Capsula2)