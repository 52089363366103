import React, {Fragment, useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHeart, faClose
} from "@fortawesome/free-solid-svg-icons";
import {Popover, Transition} from "@headlessui/react";
import { useNavigate} from "react-router-dom";
import ItemFavorito from "./item_favorito";
import {get_lista_compra, get_lista_compra_favorito} from '../redux/actions/shoppingList';
import {connect} from "react-redux";
import "../assets/styles/globalColorPrincipal.css"
import ButtonText from "./button_text";

const Favorito = ({
                      get_lista_compra,
                      get_lista_compra_favorito,
                      shopping_list,
                      ps_producto,
                      ps_cantidad,
                      ps_pesos,
                      ps_IdSucursal
                  }) => {
    const navigate = useNavigate();
    const [modalEditListPurchase, setEditListPurchase] = React.useState(false);
    const [tienefavoritos, setTienefavoritos] = useState(false);
    const [estado, setEstado] = useState(false);
    const startEstado = () => setEstado(true);
    const closeEstado = () => setEstado(false);
    useEffect(() => {
        console.log("propro",ps_producto);
        console.log("proproidsuc",ps_IdSucursal);
        hay_producto();
    }, [ps_producto]);
    useEffect(() => {

    }, []);

    const obtenerDatos = async () => {
        // get_lista_compra();
        get_lista_compra_favorito(ps_producto.IdProducto, ps_IdSucursal);
        startEstado();
    }
    const reload_list = () => {
        get_lista_compra_favorito(ps_producto.IdProducto, ps_IdSucursal);
    }

    function hay_producto() {
        if ((typeof (ps_producto) != 'undefined') && (ps_producto != null)) {
            if (ps_producto.EnListadoCliente == true) {
                setTienefavoritos(true);
                return;
            } else {
                setTienefavoritos(false);
                return;
            }

        }
        setTienefavoritos(false);
    }

    function nuevaLista() {
        navigate('/nueva_lista_compra');
    }

    return (
        <>

            <Popover className="relative inline">
                {({open, close}) => (
                    <>
                        <Popover.Button
                            className="absolute right-2 top-2 "
                            onClick={obtenerDatos}
                        >
                            {tienefavoritos ? (<FontAwesomeIcon icon={faHeart} className="text-hiperorange w-7 h-7"/>) :
                                <FontAwesomeIcon icon={faHeart} className="hipergrisdark text-hipergrisdark w-7 h-7"/>}


                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Popover.Panel className="absolute right-1 -mt-4 z-20">
                                <div
                                    className="bg-white border border-hiperorange shadow w-[24rem] h-[22rem] rounded-xl">
                                    <div
                                        className="flex justify-between bg_primario py-1 rounded-tl-xl h-[2.2rem] items-center">
                                        <span></span>
                                        <h6 className="text-center text-white text-sm font-semibold mb-0">Mi lista de
                                            compras</h6>
                                        <button className='flex'
                                                onClick={() => close()}
                                        >
                                            <FontAwesomeIcon icon={faClose}
                                                             className='bg-white text_primario p-1  mr-2 w-[1rem] h-[1rem] cursor-pointer'
                                                             style={{borderRadius: '50%'}}
                                            />
                                        </button>
                                    </div>
                                    {/* <div className="flex justify-between flex-column h-[calc(100%-28px)]"> */}
                                    <div className="flex  flex-column  h-auto">
                                        <div className="px-2 ">
                                            <label className="text-hiperorange text-sm" htmlFor="">Añadir este producto
                                                a:</label>

                                            {/* h-[calc(100%-5.5rem)] */}
                                            <div className="flex-column h-[14.5rem] overflow-y-auto gap-1 p-2 text-white">
                                                {shopping_list &&
                                                    shopping_list !== null &&
                                                    shopping_list.map((item_shopping_list) =>
                                                            <ItemFavorito ps_shopping_list={item_shopping_list}
                                                                          key={item_shopping_list.Id}
                                                                          idProducto={ps_producto.IdProducto}
                                                                          IdSucursal={ps_IdSucursal}
                                                                          cantidad={ps_cantidad}
                                                                          pesos={ps_pesos}
                                                                          peso_definitivo={ps_producto.PesoDefinido}
                                                                          favorito={item_shopping_list.favorito}
                                                                          recargar_listado={reload_list}
                                                            >
                                                            </ItemFavorito>
                                                    )
                                                }
                                            </div>

                                        </div>
                                        <div className="text-center py-2 px-4 text-white">
                                            <ButtonText
                                                label='Nueva lista'
                                                className="bg-[#84B111]"
                                                onClick={() => nuevaLista()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>

        </>
    );
};
const mapStateToProps = state => ({
    shopping_list: state.Shopping_list.shopping_list
})

export default connect(mapStateToProps, {
    get_lista_compra, get_lista_compra_favorito
})(Favorito)

// export default Favorito;