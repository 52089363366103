import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {get_banners} from "../../redux/actions/home";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import Slider1 from "../../assets/images/home/Slider1.png";
import background_white from "../../assets/images/home/fondowhite.jpg";
import {Link, useNavigate} from "react-router-dom";
import imagen1 from "../../assets/images/home/market/Super.png";
import imagen2 from "../../assets/images/home/market/Farmacia.png";
import Item_market from "../../widgets/item_market";
import {get_sucursales, get_sucursales_cerca, set_sucursal} from "../../redux/actions/branch_office";
import ItemProducto from "../../widgets/item_producto";
import convertSlug from "../Utils/slug";
import {update_url_navigation} from "../../redux/actions/url_navegacion";

const containerStyle2 = {
    backgroundImage:
        `url(${background_white})`,
    width: "100%",
};



function Market({get_sucursales,get_sucursales_cerca,direccion,set_sucursal,sucursal,branch_offices,update_url_navigation,ciudades}) {

    const navigate = useNavigate();
    const [sucursales, guardarSucursales] = useState([]);
    const [market, guardarMarket] = useState({});
    useEffect(() => {
        if (direccion) {
            get_sucursales_cerca(direccion)
        }else {
            get_sucursales();
        }
    }, [direccion]);
    useEffect(() => {
        getSucursales(sucursal.IdMarket, branch_offices);
    }, [branch_offices]);


    function getSucursales(IdMarket, branch_offices){
        let items = [];
        let item_market = {};
        branch_offices.forEach((market)=>{
            if (market.IdMarket === IdMarket) {
                items = market.Locatarios;
                item_market = market;
            }
        });
        guardarSucursales(items)
        guardarMarket(item_market)
    }
    const seleccionar_sucursal = (sucursal) =>{
        set_sucursal(sucursal);
        onload_slug(sucursal);
        console.log("sucursalxdxd",sucursal)
    }
    const onload_slug = async (item_sucursal) => {
        // NOTA
        let ciudad = await ciudadSeleccionado(item_sucursal.idRegion);
        let sucursal_select = item_sucursal;
        let parametro_url = {
            ciudad: convertSlug.data_slug(ciudad.Nombre),
            sucursal: convertSlug.data_slug(sucursal_select.Descripcion)
        }
        let resp = (parametro_url.ciudad + ' ' + parametro_url.sucursal);
        let new_url = convertSlug.data_barra(resp);

        console.log('url new', new_url);

        let url_parameters = new_url;
        await update_url_navigation({url_parameters, id_sucursal: item_sucursal.IdSucursal, id_ciudad: item_sucursal.idRegion});
        navigate("/"+url_parameters);

    }
    function ciudadSeleccionado(id_region) {
        console.log(ciudades);
        if(validateCiudad){
            let seleccionado = ciudades.find(pre => parseInt(pre.IdRegion) === parseInt(id_region));
            return seleccionado;
        }
    }
    function validateCiudad(){
        return ciudades.length>0? true : false;
    }
    return (
        <>
            <div className="mb-6">
                <div className="flex justify-between">
                    <h3 className="text-hiperblue text-xl">Market</h3>
                </div>
                <div className=" w-100">
                    <div className=" h-auto  ">
                        <div className="style_seccion_supermercado_farmacia p-4">
                            <div className="style_seccion_supermercado_farmacia_block1">
                                <div className="sec_superm_farm_head">
                                    <label style={{fontWeight: "bold"}}>{sucursal.TipoServicio.Descripcion}</label>
                                    <div className="flex justify-between">
                                        <label>{market.Descripcion}</label>

                                    </div>
                                </div>
                                <div className="sec_superm_farm_body">
                                    <label>{sucursal.Direccion}</label>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-2">

                                {

                                    sucursales &&
                                    sucursales !== null &&
                                    sucursales !== undefined &&
                                    sucursales.map((item, index) => (
                                    <Item_market key={index} url={item.TipoServicio.UrlFoto}
                                                 texto={item.TipoServicio.Descripcion}
                                                 sucursal={item}
                                                 IdSucursal={sucursal.IdSucursal}
                                                 opcion={seleccionar_sucursal}
                                    ></Item_market>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    direccion: state.Location.direccion,
    sucursales: state.BranchOffice.sucursales,
    branch_offices: state.BranchOffice.branch_offices,
    sucursal: state.BranchOffice.sucursal,
    ciudades: state.BranchOffice.ciudades,
})

export default connect(mapStateToProps, {
    get_sucursales,set_sucursal,update_url_navigation,get_sucursales_cerca
})(Market)