import React from "react";
 
import {Link} from "react-router-dom";
 

const SeccionInicio = ({texto1,texto2,imagen,url}) => {
    return (
        <article
            className=" bg-white  shadow flex justify-center flex-column  overflow-visible   relative    p-0.5 "
                style={{width:'19rem',height:'19rem'}}
               >
                    <img src={imagen} style={{minHeight:'250px', backgroundRepeat:'no-repeat',backgroundPosition:'center', objectFit:'contain' }} className="w-[19rem]" />
             
                <div
                    className="flex overflow-visible  mx-auto transition-all duration-300 relative  items-end w-[100%] h-[50%] " style={{position:'absolute', bottom:'2px'}}>
                    <Link to={url} className="w-[100%] cursor-pointer ">
                        <div className=" p-1  transition duration-200 ease-in-out transform group-hover:scale-105  
                        w-[100%] h-[100%] bg-white rounded-b-lg text-white text-center flex flex-col justify-center items-center">
                                {/* <label style={{"fontSize":"8pt"}}> Lorem Ipsum is simply dummy text of the printing   </label>  */}
                                 <label className="text-hiperorange" style={{fontSize:"11pt",fontWeight:"bold",lineHeight:"1"}}> {texto1}  </label>  
                                 <label className="text-black pt-2"style={{fontSize:"8pt",lineHeight:"1.2"}}> {texto2}  </label>  
                        </div>
                    </Link>

                  

                </div>
            
            {/* <div className="flex flex-col px-3 md:px-4 lg:px-[18px] pb-5 lg:pb-6 lg:pt-1.5 h-full divide-y">
              
            </div> */}
        </article>
    );
};

export default SeccionInicio;