import React, {useState, useEffect} from 'react';
import {useNavigate,useParams} from "react-router-dom";
import Layout from '../../hocs/Layout';
import {faQrcode} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import ItemListaCompra from '../../widgets/item_lista_compra';
import {connect} from "react-redux";
import {get_lista_compra, eliminar_lista_compra, limpiar_data_lista_compra} from '../../redux/actions/shoppingList';
import ContenidoModal from './Partials/ModalContainer'
import ContenidoCompartir from './Partials/ModalCompartirLista.js'
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/stylePageGlobals.scss";
import BackToPage from "../../components/BackPage/BackToPage"
import Swal from 'sweetalert2';
import Alert from "../../components/Swalert";
import "../../assets/styles/responsive.scss"
import ButtonIcon from "../../widgets/button_icon";
import ButtonText from "../../widgets/button_text";
import {Circles} from "react-loader-spinner";
import ButtonClose from "../../widgets/button_close";

const customStylesFondo = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '1'
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '10px',
        border:'none',
        overflow: 'visible',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding:'0px'

    },
};


const PurchaseList = ({
                          get_lista_compra,
                          eliminar_lista_compra,
                          shopping_list,
                          sucursal,
                          cambio_sucursal,
                          peticion,
                          limpiar_data_lista_compra
                      }) => {

    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();
    const [modalShare, setModalShare] = React.useState(false);
    const [modalAddListPurchase, setAddListPurchase] = React.useState(false);
    const [modalEditListPurchase, setEditListPurchase] = React.useState(false);

    const startAddListPurchase = () => setAddListPurchase(true);
    const startEditListPurchase = () => setEditListPurchase(true);

    const endAddListPurchase = () => setAddListPurchase(false);
    const endAdEditListPurchase = () => setEditListPurchase(false);

    const openModalShare = () => setModalShare(true);
    const closeModalShare = () => setModalShare(false);
    let IdSucursal = sucursal.IdSucursal;

    const [formData, setFormData] = useState({
        Id: '',
        Descripcion: '',
        Color: '',
        ImporteTotal: '',
        HashListaCompra: '',
    })

    const infoModal = {
        title_new: 'Crear nueva lista',
        title_edit: 'Editar lista'
    }
    
    useEffect(() => {
        if (shopping_list.length === 0 && sucursal) {
            get_lista_compra(IdSucursal);
        } 
        // else if (cambio_sucursal) {
        //     get_lista_compra(IdSucursal);
        //     console.log("o2");
        // }
    }, [shopping_list]);

    useEffect(() => {
        return () => {
            limpiar_data_lista_compra();
        }
    }, [])

    function setSelectedList(object, opcion) {

        setFormData(object);
        if (opcion == 'edit') {
            startEditListPurchase();
        }
        if (opcion == 'share') {
            openModalShare();
        }

    }

    function closeModal() {
        endAdEditListPurchase();
    }

    function closeModalStore() {
        endAddListPurchase();
        // obtenerDatos();

    }

    function deleteListaCompra(Id) {
        Swal.fire({
            title: 'Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0349AB',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteLista(Id);
            }
        })
    }

    async function deleteLista(id) {
        await eliminar_lista_compra({'Id': id, 'IdSucursal': IdSucursal});
        // await obtenerDatos();
    }

    async function navegarScanear() {
        let url_param = await get_url();
        navigate(url_param+"/escanearCodigo");
    }

    async function navegarNuevaLista() {
        let url_param = await get_url();
        navigate(url_param+"/nueva_lista_compra");
    }
    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/"+ciudadp+"/"+sucursalp);
        } else if (url_nav) {
            return ("/"+url_nav.url_parameters);
        }
    }
    function verifyLista() {
        if (shopping_list !== undefined &&
            shopping_list !== null &&
            shopping_list.length === 0
        ) {

            return true;
        }
        return false;
    }

    function verifyListaOk() {
        if (shopping_list !== undefined &&
            shopping_list !== null &&
            shopping_list.length > 0
        ) {
            return true;
        }
        return false;
    }

    return (
        <Layout>
            <Alert></Alert>
            <div className="bg_image_page" style={{minHeight: '45rem'}}>
                <div className='content_info pt-4'>
                    <BackToPage ps_url_page={"/"}></BackToPage>
                    <Alert></Alert>
                    <div className="container">
                        <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                            Mi lista de compras</h1>
                        <div className="flex w-100 flex-column pb-4 justify-center items-center">
                            <div
                                className=" md:w-[40rem] h-auto contenedor_mobile border bg-white border-hiperorange shadow rounded-xl">
                                <div className="flex justify-between flex-column h-100" style={{padding: '1rem'}}>
                                    <div className="h-[30rem] overflow-y-auto px-3 pt-4 container_scroll">
                                        <div className=" grid gap-2" style={{minWidth: '30rem'}}>

                                            {verifyListaOk() ? (
                                                    shopping_list.map((item_shopping_list) =>
                                                        <ItemListaCompra
                                                            ps_shopping_list={item_shopping_list}
                                                            ps_opcSelectedList={setSelectedList}
                                                            key={item_shopping_list.Id}
                                                            ps_deleteList={deleteListaCompra}
                                                            ps_openModalShare={openModalShare}
                                                            // ps_opcEdit={startEditListPurchase}

                                                        ></ItemListaCompra>)
                                                )

                                                : (
                                                    <>
                                                        {peticion ? (
                                                            <div className='flex justify-center text-black text-center'>
                                                                <Circles
                                                                    color="#32338E"
                                                                    width={40}
                                                                    height={40}
                                                                    ariaLabel="circles-loading"
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        ) : (verifyLista() && !peticion &&
                                                            <p className='text-md text-gray-400 text-center'>
                                                                No hay lista para mostrar
                                                            </p>
                                                        )

                                                        }
                                                    </>
                                                )


                                            }

                                        </div>
                                    </div>
                                    <div className="h-auto flex justify-around flex-column px-3 pt-4 text-white">
                                        <ButtonIcon
                                            icon={faQrcode}
                                            label='Scanear'
                                            principal={false}
                                            className={'bg-hipergreen mb-2 hover:bg-hipergreen_hover hover:text-[#FFFFFF]'}
                                            onClick={() => navegarScanear()}
                                        />
                                        <ButtonText
                                            label='Nueva lista'
                                            principal={false}
                                            className="bg-hiperblue hover:bg-hiperblue_hover text-white"
                                            onClick={() => navegarNuevaLista()}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>


            {/* en proceso  modal compartir    */}
            <Modal
                isOpen={modalShare}
                onRequestClose={closeModalShare}
                ariaHideApp={false}
                style={customStylesFondo}
                contentLabel="share"
            >

                <ContenidoCompartir
                    info={infoModal.title_new} opcion={'nuevo'}
                    formData={formData}
                    setFormData={setFormData}
                    ps_closeModal={closeModalShare}

                >
                </ContenidoCompartir>
            </Modal>

            {/* nueva lista compra */}
            <Modal
                isOpen={modalAddListPurchase}
                onRequestClose={endAddListPurchase}
                ariaHideApp={false}
                style={customStylesFondo}
                contentLabel="shopping"
            >
                {/* container content */}
                <ContenidoModal
                    info={infoModal.title_new}
                    opcion={'nuevo'}
                    formData={formData}
                    setFormData={setFormData}
                    ps_closeModalEstore={closeModalStore}
                ></ContenidoModal>

            </Modal>

            {/* editar lista compra */}
            <Modal
                isOpen={modalEditListPurchase}
                onRequestClose={endAdEditListPurchase}
                ariaHideApp={false}
                style={customStylesFondo}
                contentLabel="shopping"
            >
                {/* container content */}
                <ContenidoModal
                    info={infoModal.title_edit}
                    opcion={'edit'}
                    formData={formData}
                    setFormData={setFormData}
                    ps_closeModalEdit={closeModal}
                >
                </ContenidoModal>
            </Modal>
        </Layout>
    );
};

const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    shopping_list: state.Shopping_list.shopping_list,
    peticion: state.Peticion.peticion,
    cambio_sucursal: state.BranchOffice.cambio_sucursal
})

export default connect(mapStateToProps, {
    get_lista_compra, eliminar_lista_compra, limpiar_data_lista_compra
})(PurchaseList)
