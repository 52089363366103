import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useParams,useNavigate } from "react-router-dom";

import { useLocalStorage } from "react-use";
import convertSlug from "./slug";
import { get_ciudades,get_sucursales,set_sucursal,set_tipo_entrega } from "../../redux/actions/branch_office";
import {end_peticion_total, update_url_navigation} from "../../redux/actions/url_navegacion";
import {get_anonimo_token} from "../../redux/actions/auth";
import {info_session} from "../../redux/actions/profile";
import RegistroInit from "../modal/registroUser/registro_init";
import Error404 from "../../containers/errors/Error404";

import {connect} from "react-redux";
const ProtectedUserRegistered = ({
  profile,
  info_session
}) => {
  const { ciudadp, sucursalp } = useParams();
  const [url_navigation,setUrl_navigation]=useState(null);    
  
  const navigate = useNavigate();


    const [direccion, seDireccion] = useLocalStorage("direccion");
    const [sucursal, setSucursal] = useLocalStorage("sucursal");
    const [tipo_entrega, setTipo_entrega] = useLocalStorage("tipo_entrega");
  
    const [phone, setPhone] = useLocalStorage("phone");
    const [userAutenticated, setUserAutenticated] =useState(false);


  const[state_pase, setState_pase]= useState(false);


  // ciudades,sucursales
  useEffect(() => {
    let token = localStorage.getItem('access_token');
    if (!profile && token) {
        info_session();
    }
}, [profile]);


  const verifyEstado=()=>{
    if(profile){
      if(profile.Telefono!==""){
        return true;
      }
    }

    return false;
  }




if(verifyEstado()){
  return <Outlet />;
}else{
  // return <RegistroInit></RegistroInit>
   return <Error404></Error404>
}

};
// export default ProtectedRoute;
const mapStateToProps = (state) => ({
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  info_session
})(ProtectedUserRegistered);
