import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import ButtonText from "../../../../widgets/button_text";
import ItemConfirmacionPedido from "../../../../widgets/item_confirmacion_pedido";
import {faTimesCircle, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonClose from "../../../../widgets/button_close";

import "../../../../assets/styles/responsive.scss"

const RegistroVale = ({idsucursal, listaVales, updateVales, endModalVale}) => {
    const [vales, setVales] = useState([]);
    const [vale, setVale] = useState('');
    const [errorMonto, setErrorMonto] = useState("");

    useEffect(() => {
        let check = listaVales.find(list => list.idsucursal === idsucursal);
        if (check) {
            setVales(check.vale);
        }
    }, []);

    useEffect(() => {
        updateVales(vales, idsucursal)
    }, [vales]);


    function addVale() {
        setErrorMonto("");
        if (vale !== "") {
            if (vale > 0) {
                setVales([...vales, vale])
                setVale('')
            }
        } else {
            setErrorMonto("Solo se permite números");
        }
    }

    function deleteVale(index) {
        let array = vales.toSpliced(index, 1);
        setVales(array)
    }


    function inputchangeVale(e) {
        const esValido = e.target.validity.valid;
        if (esValido) {
            let cant_vale = e.target.value;
            if (cant_vale) {
                setVale(cant_vale)
            }else {
                setVale('')
            }
        }
    }

    return (
        <>
            <div className="content_modal_new bg-white" style={{minHeight: '26rem'}}>
                <div className="w-100 ">
                    <div
                        className="bg-hiperorange relative w-100 text-white flex items-center justify-center h-[52px] content_title">
                        <h2 className='p-0 m-0 title_carrito_responsivo'>Vales agregados</h2>
                        <div className="absolute right-2 ">
                            <ButtonClose className="text-white bg-transparent" onClick={() => endModalVale()}/>
                        </div>
                    </div>
                    <div className="p-3">
                        <div>
                            <p className="font-bold">Agregar vales del Hipermaxi</p>
                            <label className="block text-black fontzize_label  mb-1"
                                           htmlFor="vale">
                                        Monto (Bs.)
                                    </label>

                            <div className="content_vale">
                                <div className="input-group mr-1">
                                    
                                    <div className='flex overflow-hidden w-100 ' style={{borderRadius:'8px', border:'1px solid black'}}>
                                        <div className='p-1 px-3' style={{borderRight:'1px solid black'}} >
                                            <span>Bs.</span>
                                        </div>
                                        <input
                                            className=" h-full w-full  py-2 px-3 border-0 text-gray-700 focus:outline-none focus:shadow-outline" 
                                            
                                            id="vale" type="text"
                                            pattern="[0-9]{0,13}"
                                            value={vale}
                                            onChange={(e) => inputchangeVale(e)}
                                            placeholder="Ingresar monto del vale"
                                        />
                                        {/* <input
                                            className=" h-full w-full  py-2 px-3  text-gray-700 focus:outline-none focus:shadow-outline" 
                                            style={{borderBottom:'1px solid black',borderTop:'1px solid black', 
                                                borderRight:'1px solid black', borderTopRightRadius:'10px', borderBottomRightRadius:'10px' }}
                                            id="vale" type="text"
                                            pattern="[0-9]{0,13}"
                                            value={vale}
                                            onChange={(e) => inputchangeVale(e)}
                                            placeholder="Ingresar monto del vale"
                                        /> */}
                                    </div>
                                   
                                </div>
                                <ButtonText
                                    label='Agregar vale'
                                    principal={false}
                                    className="bg-hiperplomo_fuerte text-white w-[13rem] hover:bg-hiperorange px-1"
                                    onClick={() => addVale()}
                                />
                            </div>
                            <p className="text-sm  font-semibold italic pt-2 pb-3"> * El vale se entregará como pago al momento de recibir los productos</p>
                        </div>
                        {/*    body     */}
                        <div className="overflow-y-auto h-[15rem] pr-1 container_scroll">
                            {
                                vales.map((item, key) =>
                                    <div key={key}
                                         className="flex border border-black  justify-between items-center bg-white hover:bg-hiperorange text-black hover:text-white   py-2 pl-4 pr-2 rounded w-100 mb-2">
                                        <label>Vale Nro. {key + 1} por Bs. {item} </label>
                                        <FontAwesomeIcon icon={faTrashCan} onClick={() => deleteVale(key)}
                                                         style={{fontSize: '1.4rem'}}
                                                         className="mr-1 cursor-pointer"/>
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};
const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(RegistroVale)

