import React from "react";

const ItemListaCompra = ({ps_shopping_list, ps_opcSelectedList, ps_deleteList, ps_selected}) => {


    function selectedList(datoList) {
        ps_opcSelectedList(datoList);

    }

    return (
        <div
            // className=" flex justify-between px-3 py-0.5 rounded-lg border-violet-600 border-2 text-white cursor-pointer"
            className=" flex justify-between px-3 py-0.5 rounded-lg  text-white cursor-pointer"
            style={{
                backgroundColor: ps_shopping_list.Color,
                border: ps_shopping_list.Id === ps_selected.Id ? "2px solid #000000" : '2px solid transparent'
            }}
            onClick={() => selectedList(ps_shopping_list)}
        >
            <div className="cursor-pointer flex items-center" key={ps_shopping_list.Id}>
                <span className=""> {ps_shopping_list.Descripcion} </span>
            </div>
        </div>


    );
};

export default ItemListaCompra;