import React,{useEffect,useState} from 'react';
import Modal from 'react-modal';
/*import BackToPage from "../components/BackPage/BackToPage";*/
 
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import ImageGallery from 'react-image-gallery';
 
import '../assets/styles/full_scren.scss';



import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';

// Estilos CSS para la modal
const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    border: 'none',
 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    cursor:'pointer',
    background: 'rgba(0, 0, 0, 0.8)',
   
  },
};



// Componente FullScreenImage
const FullScreenImage = ({ imagenes, tiene_varias_imagenes,imagen, onClose   }) => {

  const [images, guardarImagenes] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0); // Estado para controlar la imagen actual

 

  function dar_formato(imagenes){
   // console.log("init ",imagenes);
    var  aux=[];
    for(let item of  imagenes){
      var dato={
            original: item.Url + '&size=900x900',
            thumbnail:item.Url + '&size=120x120',
          };
        aux.push(dato);
    }
    guardarImagenes(aux);
  }

  function dar_formato_una_imagen(imagenv){
    // console.log("init ",imagenes);
     var  aux=[];
  
       var dato={
             original: imagenv + '&size=900x900',
             thumbnail:imagenv + '&size=120x120',
           };
         aux.push(dato);
   
     guardarImagenes(aux);
   }
  useEffect(() => {

      if(tiene_varias_imagenes=="si"){
        dar_formato(imagenes);
      }else{
        dar_formato_una_imagen(imagen);
      }
    
}, []);

/*

  const imagesx = [
    {
      original: 'https://picsum.photos/id/1018/1000/600/',
      thumbnail: 'https://picsum.photos/id/1018/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
  ]; */
  
  return (
        <ImageGallery items={images} /> 

  );
};

export default FullScreenImage;