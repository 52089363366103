import React, {useEffect} from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {cn} from "../libs/utils";
const ButtonClose = ({onClick,className}) => {

    return (
        <FontAwesomeIcon icon={faClose}
            onClick={onClick}
            className={cn('bg-white text-[#DC842D] p-1   w-[1.3rem] h-[1.3rem] cursor-pointer',className)}
            style={{borderRadius:'50%'}}
        /> 
       
    );
};

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {})(ButtonClose)

