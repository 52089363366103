import React,{PropTypes,createElement  ,Fragment,useEffect,useState} from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import {useForm} from "react-hook-form";
import {Circles} from "react-loader-spinner";
import qr from "../../../assets/images/home/qr.png"
import {Popover, Transition} from "@headlessui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QrFiestas from "../../../assets/images/Qr_lista_compra/QrFiestas.png";
import ButtonClose from "../../../widgets/button_close";
import axios from 'axios';

import {faClose
} from "@fortawesome/free-solid-svg-icons";
import {
    faFacebook,
    faGooglePlus,
    faInstagram,
    faSnapchat,
    faTelegram,
    faTelegramPlane,
    faWhatsapp,
    faLinkedin,
    faMarkdown

} from "@fortawesome/free-brands-svg-icons";

import CreateQr from "../../compras/CreateQr";
import { document } from "postcss";

const ContenidoCompartir =({info,opcion,actualizar_lista_compra,store_lista_compra
    ,formData,setFormData,ps_closeModal,ps_closeModalEstore,loading
}) => {

    const [validateColor, setValidateColor] = useState(false);

    const  [qrImageUrl, setQrImageUrl]= useState("");
    const  [qrDescripcion, setQrDescripcion]= useState("");
    const  [file, setFile]= useState("");

    const [copy, setCopy]= useState(false);

    const [permiso, setPermiso]= useState(false);

    const { ClipboardItem } = window;
    useEffect(() => {
        console.log('contai',formData);
        verificarPermiso();
    }, []);

    function onloadQrUrl(QrImage){
        setQrImageUrl(QrImage);
    }
    function onloadQrDescrip(description){
        setQrDescripcion(description)
    }
    async function askWritePermission() {
        try {
          // The clipboard-write permission is granted automatically to pages
          // when they are the active tab. So it's not required, but it's more safe.
          const { state } = await navigator.permissions.query({ name: 'clipboard-write' })
          return state === 'granted'
        } catch (error) {
          // Browser compatibility / Security error (ONLY HTTPS) ...
          return false
        }
    }

    const verificarPermiso = async() =>{
        const canWriteToClip= await askWritePermission();
        if(canWriteToClip){
            setPermiso(true);
        }
        else{
            setPermiso(false);
        }
    }
    const setToClipboard = async blob => {
        const data = [new ClipboardItem({ [blob.type]: blob })]
        await navigator.clipboard.write(data)
    }

    const copyImage = async() => {
        const canWriteToClipboard = await askWritePermission()
        if(canWriteToClipboard){
            const img = qrImageUrl;
            const blob = await fetch(img).then(r => r.blob());
            const item = new ClipboardItem({ 'image/png': blob });
            navigator.clipboard.write([item])
            .then(() => {
                console.log('Imagen copiada al portapapeles');
                setCopy(true);
            })
            .catch(err => {
                console.error('Error al copiar al portapapeles:', err)
                setCopy(false);
            })

        //     console.log('¡Imagen copiada!');
        }else{
            console.log('no hay permiso');
        }
    }

    const cerrarModal = ()=>{

    }


    return (
        <>
        <div className="w-[18rem] rounded-hiper_g overflow-hidden">
             <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                    <h2 className='p-0 m-0 title_carrito_responsivo'>Compartir</h2>
                    <div className="absolute right-2 ">
                        <ButtonClose className="text-white bg-transparent"  onClick={() => ps_closeModal()} />
                    </div>
                </div>
            <div className="grid grid-rows-1 gap-1 p-3">
                    {/* <div className="flex justify-end">
                        <ButtonClose  onClick={() => ps_closeModal()}/>
                    </div> */}
                <span className='text-center font-bold'>Comparte tu lista de compra</span>
                <button
                    className=" px-4 rounded-lg  text-white w-full mt-2"
                        style={{backgroundColor:formData.Color}}
                    >
                    {formData.Descripcion}
                </button>
                <div className="flex justify-center">
                    {/* generar qr */}
                    {/* <img src={file}  className="imgCopy w-20 h-20"
                            alt="logo"/> */}

                    <CreateQr ps_formData={formData} ps_onloadQrUrl={onloadQrUrl} ps_onloadQrDescrip={onloadQrDescrip}></CreateQr>
                </div>
                <div>
                <div className="flex flex-column justify-center">
                    {permiso ?(
                        // faFlipboard
                        <button onClick={copyImage} className="w-full rounded-lg bg-hipergreen hover:hipergreen_hover py-2  items-center text-center text-white mb-2 mt-1">
                         {/* <FontAwesomeIcon icon={faFly}></FontAwesomeIcon> Copiar */}
                         {copy?(
                            <span className="text-white">Imagen copiada</span>
                            
                         ):(
                            'Copiar'
                         )
                         }
                         {/* <FontAwesomeIcon icon={faCodiepie} /> */}
                        </button>
                        ):(
                            <small>  <i>hacer clic derecho sobre la imagen y copiar </i> </small>
                        )   
                    }

                    <a href={qrImageUrl} download={qrDescripcion}  className="w-full bg-hiperorange hover:bg-hiperorange_hover rounded-lg border-hiperorange py-2  items-center text-center text-white ">
                    
                    {/* <FontAwesomeIcon icon={faMarkdown} /> */}
                        Descargar Qr
                    </a>
                </div>

                    {/* <button className="px-4 rounded-lg border-hiperorange py-1 border-2  text-hiperorange w-full mt-1">Descargar Qr</button> */}
                    {/* redes sociales */}
                    <Popover className="relative inline ">
                        {({open}) => (
                            <>
                                {/* <Popover.Button
                                    className="px-4 rounded-lg border-hiperorange py-1 border-2  text-hiperorange w-full mt-1">
                                    <span>Compartir por redes</span>
                                </Popover.Button> */}
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Popover.Panel className="absolute mt-2 w-full z-10">
                                        <div
                                            className="bg-white px-4 py-4 border border-gray-500 shadow-2xl">
                                            <div className="flex justif-center gap-2 flex justify-center">
                                                <div className="text-center">
                                                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://espai.es" target="_blank">
                                                        <FontAwesomeIcon icon={faFacebook}
                                                        style={{height: "35px"}}
                                                        className="text-zinc-400"/>
                                                    </a>

                                                </div>
                                                <div className="text-center">
                                                    <a href={`https://api.whatsapp.com/send?text=${qrImageUrl}`} target="_blank">
                                                    {/* to={`/detalle_lista_compra/${ps_shopping_list.Id}`} */}
                                                        <FontAwesomeIcon icon={faWhatsapp}
                                                        style={{height: "35px"}}
                                                        className="text-zinc-400"/>
                                                    </a>

                                                </div>
                                                <div className="text-center">
                                                    <a href="https://telegram.me/share/url?url=DIRECCION_URL&text=TEXTO" target="_blank">
                                                    <FontAwesomeIcon icon={faTelegram}
                                                            style={{height: "35px"}}
                                                            className="text-zinc-400"/>
                                                        </a>
                                                </div>
                                                <div className="text-center">
                                                    <a href="https://www.linkedin.com/sharing/share-offsite/?url=[direccion]">
                                                        <FontAwesomeIcon icon={faLinkedin}
                                                        style={{height: "35px"}}
                                                        className="text-zinc-400"/>
                                                    </a>
                                                </div>

                                            </div>
                                            <div className="mt-4">
                                                <button
                                                    className="bg-hiperblue w-full border-2 border-hiperblue text-white rounded-lg">Cancelar
                                                </button>
                                            </div>
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>

                </div>

            </div>
        </div>


        </>

    );
};
// export default Contenido;

const mapStateToProps = state => ({
    loading: state.Shopping_list.loading,
})

export default connect(mapStateToProps, {
})(ContenidoCompartir)