import React, {Fragment, useState,useEffect} from 'react';
import {Link} from "react-router-dom";
import Layout from '../../hocs/Layout';
import background_white from "../../assets/images/home/fondowhite.jpg";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,faBackward
} from "@fortawesome/free-solid-svg-icons";

import Modal from "react-modal";
import qr from "../../assets/images/home/qr.png";
import {Popover, Transition} from "@headlessui/react";

import ItemDetalleListaCompra from '../../widgets/item_detalle_lista_compra';

// import para realizar peticiones
import { connect } from "react-redux";
import { get_detalle_lista_compra } from '../../redux/actions/shoppingList';

import classNames from "classnames";

import ContenidoModal from './Partials/ModalContainer'
import {useParams} from 'react-router'
import {faCartShopping, faLocationDot, faPenToSquare, faShareNodes, faTrash} from "@fortawesome/free-solid-svg-icons";

// import Swal from 'sweetalert2/dist/sweetalert2.js'

// import 'sweetalert2/src/sweetalert2.scss'

import BackToPage from "../../components/BackPage/BackToPage"

const containerStyle2 = {
    position: 'fixed',
    inset: '0px',
    backgroundImage:
        `url(${background_white})`,
    width: "100%",
};

const customStylesFondo = {
    overlay: {
        backgroundColor: '#000000a6',
    },
    content: {
        top: '54%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0.75rem',
        overflow: 'visible',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};



    

const DetalleListaCompra = ({get_detalle_lista_compra,shopping_list_detalle,sucursal}) => {
    const params = useParams();
    const IdListaCompra = params.IdListaCompra;

    let IdSucursal = sucursal.IdSucursal;
    let IdMarket = sucursal.IdMarket;
     




    const [modalPurchase, setPurchase] = React.useState(false);
    const [modalAddListPurchase, setAddListPurchase] = React.useState(false);
    const [modalEditListPurchase, setEditListPurchase] = React.useState(false);


    const startPurchase = () => setPurchase(true);
    const endPurchase = () => setPurchase(false);
    const startAddListPurchase = () => setAddListPurchase(true);
    const startEditListPurchase = () => setEditListPurchase(true);

    const endAddListPurchase = () => setAddListPurchase(false);
    const endAdEditListPurchase = () => setEditListPurchase(false);



    const [Info, setInfo] = useState('');

    const [costoTotal, setCostoTotal]= useState(0);


    const [formData, setFormData] = useState({
        Id: null,
        Descripcion: null,
        Color:null,
        ImporteTotal:null,
    })

    const infoModal={
        title_new:'Crear nueva lista',
        title_edit:'Editar lista',
        // "title":'Editar lista'
    }

    // const [todos, setTodos] = useState([
    //     { IdProducto: null, Cantidad:null, selected: false },
    // ]);
    const [ArrayProducto, setArrayProducto] = useState();


    let productos=[];

    const [todos, setTodos] = useState([]);
    //   const [todos, setTodos] = useState([
    //     { idProducto: 1, Cantidad:2, descripcion: "Selectus aut autem", PrecioVenta: 40 }
    //   ]);



    function setSelectedList(opcion){
        startEditListPurchase();
        setFormData({'Id':opcion.Id,'Descripcion':opcion.Descripcion,'Color':opcion.Color});
    }
    function closeModal(){
        // fetchData();
        endAdEditListPurchase();
        
    }
    function cargarArray(){
        // clearData();
        if(verifyDetalleProductos()){
            // clearData();
            let arrayP=shopping_list_detalle.Productos;
            for (let index = 0; index < arrayP.length; index++) {
                const element = arrayP[index];
                if(!siExiste(element.IdProducto)){

                    todos.push({
                        IdProducto:element.IdProducto,
                        Descripcion:element.Descripcion,
                        Cantidad:element.Cantidad,
                        PrecioVenta:element.PrecioVenta,
                        UrlFoto:element.UrlFoto,
                        Stock:element.Stock,
                        Checked:true,
                    })
                }
            }
            // cargarDatoLista();
        }
    }
    function siExiste(Idproduct){
        let proAux= todos.find(item=>item.IdProducto===Idproduct);
        if(typeof(proAux)==='undefined' || proAux===null){
            return false;
        }
        return true;
    }
    function verifyDato(){
        let countProResp= shopping_list_detalle.Productos.length;
        let countprodArray=todos.length;
        if(countProResp===countprodArray){
            return true;
        }
        return false;
    }
    function actualizarDato(productId,operacion){
        if(operacion==='incremento'){
            setTodos(todos.map(product => {
                if (product.IdProducto === productId && product.Cantidad < product.Stock) {
                  return {
                    ...product,
                    Cantidad: product.Cantidad + 1
                  };
                } else {
                  return product;
                }
              }))
        }else if(operacion==='decremento'){
            setTodos(todos.map(product => {
                if (product.IdProducto === productId && product.Cantidad >=1) {
                  return {
                    ...product,
                    Cantidad: product.Cantidad - 1
                  };
                } else {
                  return product;
                }
              }))

        }
       

        // setTodos(objectDato);
    }
    function handleCheckedProducto(productoId, nextChecked) {
        setTodos(todos.map(product => {
          if (product.IdProducto === productoId) {
            // Create a *new* object with changes
            return { ...product, Checked: nextChecked };
          } else {
            // No changes
            return product;
          }
        }));
    }

    function comprar(){
        // console.log('ok comprar', todos);

        // setTodos([]);
        // console.log('ok comprar1', todos);
        
        // Swal.fire({
        //     title: 'Error!',
        //     text: 'Do you want to continue',
        //     icon: 'error',
        //     confirmButtonText: 'Cool'
        //   })

    }
    function clearData(){
        setTodos([]);
    }
    function calcularTotal(){
        const countSuma = todos.map(product => {
            // return + (product.Cantidad* product.PrecioVenta)
            if(product.Checked===true){
                return  (product.Cantidad* product.PrecioVenta)
            }
            return 0;
        })
        let sumaPrecios=0;
        if(countSuma[0]!== undefined){
            sumaPrecios = countSuma.reduce((prev, next) => prev + next);
        }
        setCostoTotal(valorPrecio(sumaPrecios));


    }
    function valorPrecio(precio){
        return parseFloat(precio).toFixed(2);
    }



    useEffect(() => {
        // if(verifyinit()){
        // obtenerDatos()
        fetchData(IdListaCompra);
        // }
        console.log("iDLISTACOMPRA",IdListaCompra)
        
        // if(!verifyinit()){
        //     console.log('repet');
        //     cargarArray();
        //     calcularTotal();
        // }  
        // if(verifyDetalleProductos()){
            // console.log("cargarproduct");
            // cargarArray();
            calcularTotal();
        // }  

        // [shopping_list_detalle,todos,costoTotal,fetchData,IdListaCompra]
    }, [shopping_list_detalle,todos])

    async function fetchData(IdLista) {
        try {
            // todos=[];
                // console.log(verifyinit());
            if(!verifyShoopingListDetalle()){
                const parametro={'Id':IdLista,'Sucursal':IdSucursal}
                get_detalle_lista_compra(parametro);
                console.log("get fetchdata resultado de detalle lista compra   ",shopping_list_detalle);
                console.log("get fetchdata resultado de detalle lista compra  tudo  ",todos);
                
            }
            // if(verifyinit()){
            //     clearData();
            // }
            if(verifyDetalleProductos()){
                cargarArray();
                calcularTotal();
            }  
                
            

        } catch (e) {
            console.error(e);
        }
    }
    
    function verifyinit(){
        if(shopping_list_detalle !== null){
            return true;
        }
        return false;
    }

    function verifyShoopingListDetalle(){
        if(shopping_list_detalle !== undefined && 
            shopping_list_detalle !== null){
            return true;
        }
        return false;
    }
    function verifyDetalleProductos(){
        if(shopping_list_detalle !== undefined && 
            shopping_list_detalle !== null &&
            shopping_list_detalle.Productos.length > 0
            // productos.length > 0
            ){

            return true;
        }
        return false;
    }
    function enDesarrollo(){
        alert("Actualmente se encuentra en desarrollo");
    }



    return (
        <Layout>
             {/* <Alert></Alert> */}
            {/* <div className='container flex mt-2 p-2 cursor-pointer'>
                <Link  to="/lista_compras" className="col-md-12" onClick={()=>{clearData()}}>                                            
                    <FontAwesomeIcon icon={faArrowLeft} style={{fontSize:'1.5rem'}} />    
                </Link> 

                
            </div> */}
            <BackToPage></BackToPage>
            <div className="flex justify-center mt-12 pb-12">
                <div className="">
                    {/* <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                        {verifyShoopingListDetalle() && shopping_list_detalle.Descripcion}
                        </h1> */}
                    <div className="flex flex-column gap-4">
                        <div className=" w-[24rem] h-[35rem] border border-hiperorange shadow rounded-xl">
                            <div className="flex justify-between flex-column h-100">
                                <div className="h-[calc(100%-5.5rem)] overflow-y-auto px-3 pt-3">
                                    <div className='grid gap-1 mb-2'>
                                        {verifyShoopingListDetalle() ?(
                                        <div className={'flex justify-between px-2 py-0.5 text-white rounded-lg border-gray-400 border-[0.1rem]'}
                                            style={{backgroundColor: shopping_list_detalle.Color,
                                                    borderColor:shopping_list_detalle.Color
                                                }}
                                            >
                                            <span>  {shopping_list_detalle.Descripcion}</span>
                                            <span>{verifyDetalleProductos() && shopping_list_detalle.Productos.length} productos en lista</span>

                                        </div>
                                        ):(
                                            <div className={'flex justify-between px-2 py-0.5 text-white rounded-lg border-gray-400 border-[0.1rem]'}>
                                                <p>Vacio</p>
                                            </div>
                                        )}
                                       
                                    </div>
                                    <div className=" grid gap-2" >
                                       
                                          {/* ${verifyDetalleProductos()===true ? 'bg-':[shopping_list_detalle.Color]}  */}

                                        {verifyDetalleProductos() ?(
                                            // shopping_list_detalle.Productos
                                            todos.map((item_shopping_list)=>
                                            <ItemDetalleListaCompra 
                                                key={item_shopping_list.IdProducto}
                                                ps_shopping_list={item_shopping_list}
                                                ps_updateDato={actualizarDato}
                                                ps_handleCheckedProducto={handleCheckedProducto}
                                            ></ItemDetalleListaCompra>)
                                            
                                            ):(
                                                // <label className='text-black' htmlFor="">hola</label>
                                                // <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                                //     role="status">
                                                //     <span
                                                //         className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                                //         >Loading...</span>
                                                // </div>

                                                <p className='text-lg text-black text-center'>Aún no se agregaron productos</p>
                                            )
                                        }

                                    </div>
                                </div>
                                {/* bg-neutral-400 bg-[#E4E4E4] */}
                                <div className="content_card_footer text-darck rounded-b-lg py-3 px-4 ">
                                    <div className="flex flex-col ">
                                        <div className='flex p-1 justify-between bg-[#E4E4E4] rounded-md '>
                                            <span  className='text-center m-0'>Total:</span>
                                            <span>Bs {costoTotal}</span> 
                                        </div>
                                        <div className='flex gap-12 mt-2 justify-center text-[#32338E]'>
                                            <span className='m-0'>Monto mínimo de compra:</span>
                                            <span>Bs 100.00</span>
                                        </div>
                                        
                                    </div>
                                    <div className="flex flex-col justify-center  text-center py-2  flex gap-2">
                                        <div className='flex justify-between text-[#DC842D] border-[#DC842D] border-2 flex items-center w-full p-2 px-1 rounded-lg text-[#DC842D]'>
                                            <FontAwesomeIcon icon={faCartShopping} 
                                            className="cursor-pointer text-[#DC842D]"/>
                                            <button onClick={enDesarrollo}  style={{fontSize:'0.8rem'}}
                                                className="items-center bg-white-500 text-[#DC842D] text-center ">
                                                <span> Agregar a carrito </span>
                                            </button>
                                            <div></div>    
                                        </div>
                                        
                                        <button  onClick={enDesarrollo}  style={{fontSize:'0.8rem'}}
                                            className=" items-center bg-[#32338E] border-2  hover:bg-[#32338E]-100 w-full p-2 px-1 rounded-lg text-white">
                                            <span>Compra rápida</span>
                                        </button> 
                                        {/* <button className=''    onClick={() => {
                                            comprar();}}
                                        >
                                        com</button>     */}
                                 
                                    </div>


                                </div>
                                
                               
                            </div>
                        </div>

                    </div>
                </div>
            </div>
                                            





        </Layout>
);
};

const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    shopping_list_detalle: state.Shopping_list.shopping_list_detalle
})

export default connect(mapStateToProps, {
    get_detalle_lista_compra
})(DetalleListaCompra)

// export default PurchaseList;