import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
    get_fechas_entrega_sucursal_carrito,
    get_lista_carrito_compras, limpiar_horario,
    save_horarios
} from "../../../redux/actions/carrito";
import ItemHorario from "../../../widgets/item_Horario";
import {Menu} from "@headlessui/react";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faMessage, faMotorcycle, faRoad, faStore, faUser} from "@fortawesome/free-solid-svg-icons";
import {set_tipo_entrega} from "../../../redux/actions/branch_office";
import {useForm} from "react-hook-form";
import {cambiar_ubicacion, get_ubicacion, save_datos_entrega_p} from "../../../redux/actions/location";
import {info_session, update_usuario_anonimo} from "../../../redux/actions/profile";

import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/responsive.scss"
import ButtonText from "../../../widgets/button_text";
import ButtonClose from "../../../widgets/button_close";

function Direccion({
                       mt_indice,
                       get_ubicacion,
                       locations,
                       update_usuario_anonimo,
                       info_session,
                       profile,
                       save_datos_entrega_p,
                       limpiar_horario,
                       direccion,
                       cambiar_ubicacion,
                       list_carrito,
                       width,
                       height,
                       exit
                   }) {
    const [token] = useState(localStorage.getItem('access_token'));
    const [hubo_cambio_direccion, setHuboCambioDireccion] = useState(false);
    const [es_anonimo, setEsAnonimo] = useState(false);
    const [subPedidos, guardarsubPedidos] = useState([]);
    const [formData, setFormData] = useState({
        nombres: '',
        apellidopaterno: '',
        telefono: '',
        direccion: '',
        ref_direccion: '',
        nota_entrega: '',
    })

    const set_datos_entrega = (e) => {
    };

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const {register, formState: {errors}, handleSubmit, setValue} = useForm();
// const {register,formState,handleSubmit}=useForm();
    const ahorro_total = _ahorroTotal();

    function _ahorroTotal() {
        let total = 0;
        subPedidos.forEach(sp => {
            if (sp.check) {
                total += Number(sp.ahorro);
            }
        });
        return total.toFixed(2);
    }

    useEffect(() => {
        console.log("profile dir", profile);
        if (!profile) {
            info_session();
        } else {
            if (!profile.Nombres && !profile.Telefono) {
                console.log("14.1", profile)
                setEsAnonimo(true);
                return;
            }
            setFormData({
                telefono: profile.Telefono,
                direccion: '',
                ref_direccion: '',
                nota_entrega: '',
            });
            cargar_datos_localst()
        }
    }, [profile]);
    useEffect(() => {
        setFormData({
            telefono: profile.Telefono,
            direccion: direccion.Direccion,
            ref_direccion: direccion.Referencia,
            nota_entrega: '',
        });
        setValue('refdireccion', direccion.Referencia);
        setValue('nota', '');
        limpiar_horario();
        console.log(formData)
    }, []);
    useEffect(() => {
        if (list_carrito) {
            let subPedido = list_carrito.SubPedidos.filter((pedido) => pedido.check);
            guardarsubPedidos(subPedido);
        }
    }, [list_carrito]);

    useEffect(() => {
        if (hubo_cambio_direccion) {
            mt_indice(1);
        }
    }, [direccion]);

    function cargar_datos_localst() {
        var data = JSON.parse(localStorage.getItem("datos_personales"));
        if (data) {
            console.log("rescupera", data);
            setValue('refdireccion', data.refdireccion);
            setValue('nota', data.nota);
        }

    }


    const onSubmit = (data) => {

        localStorage.setItem("datos_personales", JSON.stringify(data));
        var datos = {
            Telefono: profile.Telefono,
            Prefijo: profile.Prefijo,
            IdPersonaUbicacion: direccion.Id,
            InstruccionDelivery: data.refdireccion,
            InstruccionEntrega: data.nota
        };
        if (profile.Telefono === '') {
            let data_profile = {
                Nombres: data.nombres,
                ApellidoPaterno: data.apellidopaterno
            };
            update_usuario_anonimo(data_profile);
        }
        save_datos_entrega_p(datos);
        mt_indice(4);
    }

    function cambio_direccion(id) {
        if (id != direccion.Id) {
            cambiar_ubicacion(locations, id);
            setHuboCambioDireccion(true);
        }
    }

    function existePerfil() {
        return profile != null;
    }

    function hayubicacion() {
        return locations.length > 0;
    }

    function volverAtras() {
        mt_indice(2)
    }

    function get_total_carrito() {
        let total = 0;
        let precio_no_disponible = 0;
        subPedidos.forEach((sp) => {
            if (sp.check) {
                sp.Productos.forEach(prod => {
                    if (!prod.Disponible) {
                        precio_no_disponible += prod.Importe;
                    }
                })
            }
        });

        subPedidos.forEach((sp) => {
            if (sp.check) {
                total += sp.ImporteSubtotal;
            }
        });

        let result = parseFloat(total) - parseFloat(precio_no_disponible);
        return parseFloat(result).toFixed(2);
    }

    return (
        <>
            <div className='contenedor_mobile md:w-[82%]'>
                <form className="contenedor_flex gap-2" onSubmit={handleSubmit(onSubmit)}>
                    <div className="contenedor_mobile md:w-[70%]">

                        <div className="bg-white rounded-hiper_g overflow-hidden">
                            <div
                                className="bg-hiperorange relative w-100 text-white flex items-center justify-center h-[52px]  content_title">
                                <h2 className='p-0 m-0 title_carrito_responsivo '>Direcciones y datos entrega</h2>
                                <div className="absolute right-2 ">
                                    <ButtonClose className="text-white bg-transparent" onClick={() => exit()}/>
                                </div>
                            </div>
                            {/* <div className="px-2 pb-2 pt-3 shadow text-center">
                                <h6 className="text_secundario text-xl">Direcciones y datos entrega</h6>
                            </div> */}
                            <div className={classNames("overflow-y-auto", "container_scroll",
                                {"h-[62vh]": height <= 667},
                                {"h-[66vh]": height > 667 && height <= 843},
                                {"h-[72vh]": height > 843},
                            )}>


                                <div className="m-auto max-w-md pt-4 px-3">
                                    {
                                        es_anonimo &&
                                        <>
                                            <div className="mb-3">
                                                <label className="block fontzize_label text-black  mb-2"
                                                       htmlFor="name">
                                                    <FontAwesomeIcon icon={faUser}
                                                                     cl1assName="mr-1"/>
                                                    Nombre
                                                </label>
                                                <input
                                                    className="appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="name" type="text" name="nombres"
                                                    {...register('nombres', {required: true})}
                                                />
                                                {errors.nombres?.type === 'required' &&
                                                    <p style={{"color": "#920909"}}>El campo nombre obligatorio*</p>}

                                            </div>
                                            <div className="mb-3">
                                                <label className="block fontzize_label text-black  mb-2"
                                                       htmlFor="name">
                                                    <FontAwesomeIcon icon={faUser}
                                                                     className="mr-1"/>
                                                    Apellido
                                                </label>
                                                <input
                                                    className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="name" type="text" name="apellidopaterno"
                                                    {...register('apellidopaterno', {required: true})}
                                                />
                                                {errors.apellidopaterno?.type === 'required' &&
                                                    <p style={{"color": "#920909"}}>El campo apellido obligatorio*</p>}
                                            </div>

                                        </>
                                    }
                                    {
                                        profile.Telefono &&
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black  mb-2"
                                                   htmlFor="name">
                                                <FontAwesomeIcon icon={faUser}
                                                                 className="mr-1"/>
                                                Movil de confirmación:
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="name" type="text" name="telefono"
                                                value={formData.telefono}
                                                disabled
                                            />
                                        </div>
                                    }


                                    <div className="mb-3">
                                        <label className="block fontzize_label text-black  mb-2"
                                               htmlFor="username">
                                            <FontAwesomeIcon icon={faLocationDot} className="mr-1"/>
                                            Seleccione dirección:
                                        </label>


                                        <select id="direccion" name="direccion"
                                                value={direccion.Id}
                                                onChange={e => cambio_direccion(e.target.value)}
                                                className="peer h-full w-full radius_input py-2 px-3 border_black text-gray-700 ">
                                            <option value="0">Selccione una opcion</option>
                                            {
                                                hayubicacion() ? (
                                                    locations.map((item) =>
                                                        <option key={item.Id}
                                                                value={item.Id}>{item.Descripcion}</option>
                                                    )
                                                ) : <></>
                                            }


                                        </select>


                                        {errors.direccion?.type === 'required' &&
                                            <p style={{"color": "#920909"}}>El campo dirección es obligatorio*</p>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="block fontzize_label text-black  mb-2"
                                               htmlFor="email">
                                            <FontAwesomeIcon icon={faRoad} className="mr-1"/>
                                            Referencia de la dirección:
                                        </label>
                                        <textarea
                                            className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="email" type="text" name="refdireccion"

                                            {...register('refdireccion', {required: true})}

                                        />
                                        {errors.refdireccion?.type === 'required' &&
                                            <small style={{"color": "#920909"}}>El campo referencia es
                                                obligatorio*</small>}
                                    </div>
                                    <div className="mb-3">
                                        <label className="block fontzize_label text-black  mb-2" htmlFor="ci">
                                            <FontAwesomeIcon icon={faMessage} className="mr-1"/>
                                            Nota para la entrega:
                                        </label>
                                        <textarea
                                            className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="ci" type="text" name="nota"
                                            {...register('nota', {required: true})}
                                        />
                                        {errors.nota?.type === 'required' &&
                                            <small style={{"color": "#920909"}}>El campo nota es obligatorio*</small>}
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="md:w-[30%]">
                        <div className="content_card_footer bg-white text-darck rounded-hiper_g "
                             style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem'}}>
                            <div className="content_child_footer mb-1 ">
                                <div className="px-3 py-1 bg-hiperorange mb-1 text-white"
                                     style={{borderRadius: '20px', width: 'fit-content', float: 'right'}}>
                                    <p className="mb-0 font-bold "
                                       style={{fontSize: '15px'}}>Bs. {ahorro_total} ahorrado</p>
                                </div>
                                <div className='flex p-1 justify-between  text-black '>
                                    <span className='text-center m-0 fontzize_text_carrito font-bold'>Total:</span>
                                    <span className='fontzize_text_carrito font-bold'>Bs {get_total_carrito()}</span>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center  text-center gap-2 text-white">
                                <ButtonText
                                    type="cancel"
                                    label='Volver'
                                    principal={false}
                                    className="bg-hiperorange_claro hover:bg-hiperorange_claro_hover text-black hover:text-black border border-black"
                                    onClick={() => volverAtras()}

                                />
                                <ButtonText
                                    label='Continuar'
                                    type='submit'
                                    principal={true}
                                    className="bg-hiperorange hover:bg-hiperorange_hover"
                                    onClick={() => {
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    locations: state.Location.locations,
    direccion: state.Location.direccion,
    profile: state.Profile.profile,
    list_carrito: state.Carrito.list_carrito,
})

export default connect(mapStateToProps, {
    get_ubicacion, update_usuario_anonimo, info_session, save_datos_entrega_p, cambiar_ubicacion, limpiar_horario
})(Direccion)