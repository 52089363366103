import React, {useState, useEffect} from 'react';
import Layout from '../../hocs/Layout';
import background_white from "../../assets/images/home/fondowhite.jpg";
import {connect} from "react-redux";
import {post_tarjeta} from '../../redux/actions/tarjeta';
import BackToPage from "../../components/BackPage/BackToPage"
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/stylePageGlobals.scss";
import Alert from "../../components/Swalert";
import {useForm} from "react-hook-form";
import "../../assets/styles/responsive.scss"
import ButtonText from "../../widgets/button_text";
import {huellaDigital} from './../../huellaDigital';
import {get_prefijos,get_departamento} from "../../redux/actions/auth";
import useScreenSize from "./useScreenSize";
import {cleadSwAlert} from "../../redux/actions/swalert";
import {useNavigate} from "react-router-dom";

const NuevaTarjeta = ({show_alert,redirect,cleadSwAlert,get_prefijos, array_prefijo,get_departamento, array_departamento, post_tarjeta, mycard_list, loading, profile}) => {
    const navigate = useNavigate();
    const { width, height } = useScreenSize();
    const [ipAddress, setIPAddress] = useState('');
    const [formData, setFormData] = useState({
        Color: '',
        CodeTypeTarjeta: 'visa',
        Preferida: false,
        IsoPais: 'BO'
    })
    const [validateColor, setValidateColor] = useState(false);
    const [pais, setPais] = useState('BO');
    const [ciudades, setCiudades] = useState([])
    const {register, handleSubmit, formState: {errors}, setValue} = useForm();
    const messages = {
        required: "Este campo es obligatorio",
        minLength: "No puede ingresar menor caracteres de lo establecido",
        maxLength: "No puede ingresar más caracteres en este campo",
        espacioBlanco: 'No es permitido espacios en blanco',
        requiredColor: 'Debe seleccionar un color',
        aceptaNumeros: 'Solo se acepta números en este campo',
        aceptaLetra: 'Solo se acepta letras en este campo'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
        aceptaNumeros: /^[0-9,-]+$/,
        aceptaLetra: /^[A-Za-z\s]+$/,
        // mail:/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    };


    useEffect(() => {
        if (validateColor) {
            window.scrollTo(0, 0);
        }
    }, [validateColor]);
    useEffect(() => {
        if (show_alert && redirect!==null) {
            console.log("cleadSwAlert3")
            cleadSwAlert();
            if (redirect.type === 'back') {
                window.history.back();
            }
        }
    }, [show_alert]);

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIPAddress(data.ip))
            .catch(error => console.log(error))
    }, []);


    useEffect(() => {
        if (array_prefijo.length === 0) {
            get_prefijos()
        } else {
            setValue('IsoPais', 'BO');
            setValue('codigo_postal', '0000');
        }

    }, [array_prefijo]);
    useEffect(() => {
        if (array_departamento.length === 0) {
            get_departamento()
        }else{
            if (ciudades.length === 0){
                filtro_ciudad()
            }
        }
    }, [array_departamento]);


    useEffect(() => {
        setValue('IsoPais', pais);
        if (pais==='BO') {
            setValue('codigo_postal', '0000');
        }else{
            setValue('codigo_postal', '');
        }
        setValue('IsoDepartamento', '');
        if (array_departamento.length>0) {
            filtro_ciudad();
        }
    }, [pais]);

    const filtro_ciudad=()=>{
        let array_aux = array_departamento.filter((pd) => pd.PaisCode === pais);
        setCiudades(array_aux);
    }


    const listColor = [
        {id: 1, nombreColor: 'Azul', color: '#2E9BFF'},
        {id: 3, nombreColor: 'Rojo', color: '#F15353'},
        {id: 2, nombreColor: 'Amarillo', color: '#FCC12E'},
        {id: 4, nombreColor: 'Verde', color: '#53DD12'},
        {id: 5, nombreColor: 'Naranja', color: '#CB6E18'},
        {id: 6, nombreColor: 'Morado', color: '#826882'},
        {id: 7, nombreColor: 'Magenta', color: '#CE45E4'},
        {id: 8, nombreColor: 'Celeste', color: '#5CC4E4'},

    ];

    function selectColor(color) {
        // setFormData({'Color':color});
        setFormData((prevState) => ({
            ...prevState,
            ['Color']: color
        }));

    }

    function clearFormData() {
        setValidateColor(false);
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setFormData((prevState) => ({
            ...prevState,
            [event.target.name]: value
        }));

    }

    const onSubmit = async (formInfo) => {
        var huella = await huellaDigital(profile.IdUsuario);
        const datos = {
            MesExpiracion: formInfo.MesExpiracion,
            Color: formData.Color,
            AnioExpiracion: formInfo.AnioExpiracion,
            NroTarjeta: Number(formInfo.NroTarjeta),
            CCV: formInfo.CCV,
            CodeTypeTarjeta: 'visa',
            Preferida: formData.Preferida,
            Nombre: formInfo.Nombre,
            Apellido: formInfo.Apellido,
            Direccion: formInfo.Direccion,
            IsoPais: formData.IsoPais,
            IsoDepartamento: formInfo.IsoDepartamento,
            Correo: formInfo.Correo,
            Telefono: formInfo.Telefono,
            CodigoPostal: formInfo.codigo_postal,
            IdSession: huella,
            IPNavegador: ipAddress,
            AnchoPantalla: `${width}`,
            AltoPantalla: `${height}`,
        }
        console.log("nueva tarjeta ", datos);
        if (formData.Color != '') {
            guardarTarjeta(datos);
        } else {
            setValidateColor(true);
        }

    };

    async function guardarTarjeta(datos) {
        let object = {
            type:'back',
            ruta:'/mis_tarjetas',
        };
        await post_tarjeta(datos,object);
        await clearFormData();
    }

    return (
        <Layout>
            <Alert></Alert>
            <div className="bg_image_page" style={{minHeight: '85rem'}}>
                <div className='content_info pt-4'>
                    <BackToPage ps_url_page={''}></BackToPage>
                    <div className="container">
                        <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                            Nueva tarjeta</h1>
                        <div className="flex flex-column mt-1 items-center">
                            <div className="md:w-[40rem] contenedor_mobile h-auto  pt-3">
                                <div className="w-full shadow">
                                    {/* onSubmit={e => onSubmit(e)}  */}
                                    {/* <form onSubmit={e => onSubmit(e)}  */}
                                    <form onSubmit={handleSubmit(onSubmit)}
                                          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="marcar como preferida">
                                                Marcar como preferida
                                            </label>
                                            <input
                                                type="checkbox"
                                                name='Preferida'
                                                className="w-[1.4rem] h-[1.5rem]"
                                                checked={formData.Preferida}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="color">
                                                Color <span>*</span>
                                            </label>
                                            <div className="justify-center px-2 py-0">
                                                <div className="flex justify-center flex-wrap gap-2 ">
                                                    {listColor.map((item_list) => (
                                                        <div
                                                            className="w-10 h-10 rounded-md border-2 cursor-pointer"
                                                            key={item_list.id}
                                                            name='Color'
                                                            style={{
                                                                backgroundColor: item_list.color,
                                                                borderColor:
                                                                    item_list.color === formData.Color
                                                                        ? "#000000"
                                                                        : item_list.color,
                                                            }}
                                                            onClick={() => selectColor(item_list.color)}
                                                        ></div>
                                                    ))}

                                                </div>
                                                <div className="mt-2">
                                                    {validateColor && <small
                                                        className="text-red-600">{messages.requiredColor}</small>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3  ">

                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="nombre_titular">
                                                Nombre del titular <span>*</span>
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                name='Nombre'
                                                id="Nombre" type="text"
                                                {...register("Nombre", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: messages.minLength
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: messages.maxLength
                                                    },
                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    },
                                                    pattern: {
                                                        value: patterns.aceptaLetra,
                                                        message: messages.aceptaLetra
                                                    }
                                                })}
                                            />
                                            {errors.Nombre &&
                                                <small className="text-red-600">{errors.Nombre.message}</small>
                                            }

                                        </div>
                                        <div className="mb-3  ">

                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="nombre_titular">
                                                Apellido <span>*</span>
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                name='Apellido'
                                                id="Apellido" type="text"
                                                {...register("Apellido", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: messages.minLength
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: messages.maxLength
                                                    },
                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    },
                                                    pattern: {
                                                        value: patterns.aceptaLetra,
                                                        message: messages.aceptaLetra
                                                    }
                                                })}
                                            />
                                            {errors.Apellido &&
                                                <small className="text-red-600">{errors.Apellido.message}</small>
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="numero_tarjeta">
                                                Número de tarjeta <span>*</span>
                                            </label><input
                                            className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                            name='NroTarjeta'
                                            id="numero_tarjeta"
                                            type="text"
                                            {...register("NroTarjeta", {
                                                required: {
                                                    value: true,
                                                    message: messages.required,
                                                },
                                                minLength: {
                                                    value: 16,
                                                    message: messages.minLength
                                                },
                                                maxLength: {
                                                    value: 16,
                                                    message: messages.maxLength
                                                },
                                                pattern: {
                                                    value: patterns.espacioBlanco,
                                                    message: messages.espacioBlanco
                                                },
                                                pattern: {
                                                    value: patterns.aceptaNumeros,
                                                    message: messages.aceptaNumeros
                                                }
                                            })}
                                        />
                                            {errors.NroTarjeta &&
                                                <small className="text-red-600">{errors.NroTarjeta.message}</small>}
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="fecha_expiracion">
                                                Fecha expiración <span>*</span>
                                            </label>
                                            <div className='flex gap-1'>
                                                <div className='divide w-[50%]'>
                                                    <input
                                                        className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id="mes"
                                                        name='MesExpiracion'
                                                        maxLength={2}

                                                        type="text"
                                                        placeholder="MM"
                                                        {...register("MesExpiracion", {
                                                            required: {
                                                                value: true,
                                                                message: messages.required,
                                                            },
                                                            minLength: {
                                                                value: 2,
                                                                message: messages.minLength
                                                            },
                                                            maxLength: {
                                                                value: 2,
                                                                message: messages.maxLength
                                                            },
                                                            pattern: {
                                                                value: patterns.espacioBlanco,
                                                                message: messages.espacioBlanco
                                                            },
                                                            pattern: {
                                                                value: patterns.aceptaNumeros,
                                                                message: messages.aceptaNumeros
                                                            }

                                                        })}
                                                    />
                                                    {errors.MesExpiracion && <small
                                                        className="text-red-600">{errors.MesExpiracion.message}</small>}
                                                </div>

                                                <div className='divide w-[50%]'>
                                                    <input
                                                        className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id="anio"
                                                        name='AnioExpiracion'
                                                        maxLength={4}
                                                        type="text"
                                                        placeholder="YYYY"
                                                        {...register("AnioExpiracion", {
                                                            required: {
                                                                value: true,
                                                                message: messages.required,
                                                            },
                                                            minLength: {
                                                                value: 4,
                                                                message: messages.minLength
                                                            },
                                                            maxLength: {
                                                                value: 4,
                                                                message: messages.maxLength
                                                            },
                                                            pattern: {
                                                                value: patterns.espacioBlanco,
                                                                message: messages.espacioBlanco
                                                            },
                                                            pattern: {
                                                                value: patterns.aceptaNumeros,
                                                                message: messages.aceptaNumeros
                                                            }

                                                        })}

                                                    />
                                                    {errors.AnioExpiracion && <small
                                                        className="text-red-600">{errors.AnioExpiracion.message}</small>}
                                                </div>

                                            </div>


                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="codigo_seguridad">
                                                Código de seguridad <span>*</span>
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                maxLength={3}
                                                name='CCV'
                                                id="codigo_seguridad"
                                                type="text" placeholder="CVV"
                                                {...register("CCV", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: messages.minLength
                                                    },
                                                    maxLength: {
                                                        value: 3,
                                                        message: messages.maxLength
                                                    },
                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    },
                                                    pattern: {
                                                        value: patterns.aceptaNumeros,
                                                        message: messages.aceptaNumeros
                                                    }

                                                })}
                                            />
                                            {errors.CCV && <small className="text-red-600">{errors.CCV.message}</small>}
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="pais">
                                                País
                                            </label>

                                            <select
                                                className="peer h-full w-full border_black radius_input  py-2 px-3  text-gray-700  "
                                                id='IsoPais'
                                                name='IsoPais'
                                                defaultValue={pais}
                                                onChange={e => setPais(e.target.value)}
                                            >

                                                <option value="" disabled selected>Seleccionar un Pais</option>
                                                {array_prefijo &&
                                                    array_prefijo.length > 0 &&
                                                    array_prefijo.map((pais_item) =>
                                                        <option value={pais_item.ISO2}
                                                                key={pais_item.NumeroPrefijo}
                                                        > {pais_item.Nombre}</option>
                                                    )
                                                }
                                            </select>


                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2" htmlFor="ciudad">
                                                Ciudad <span>*</span>
                                            </label>
                                            <select className="peer h-full w-full border_black radius_input py-2 px-3 text-gray-70 "
                                            id='IsoDepartamento'
                                            name='IsoDepartamento'
                                            {...register("IsoDepartamento",{
                                                required:{
                                                    value:true,
                                                    message:messages.required,
                                                },
                                            })}
                                            >
                                                <option value="" disabled selected>Seleccionar una ciudad</option>
                                                {
                                                ciudades.map((item_ciudad,index)=>
                                                    <option value={item_ciudad.Code}
                                                    key={index}
                                                    > {item_ciudad.Nombre}</option>
                                                )}

                                            </select>
                                            {errors.IsoDepartamento && <small className="text-red-600">{errors.IsoDepartamento.message}</small> }

                                        </div>


                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="codigo_postal">
                                                Código Postal <span>*</span>
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                                name='codigo_postal'
                                                id="codigo_postal"
                                                type="text" placeholder="Código postal"
                                                {...register("codigo_postal", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },
                                                    minLength: {
                                                        value: 1,
                                                        message: messages.minLength
                                                    },
                                                    maxLength: {
                                                        value: 8,
                                                        message: messages.maxLength
                                                    },
                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    },
                                                    pattern: {
                                                        value: patterns.aceptaNumeros,
                                                        message: messages.aceptaNumeros
                                                    }

                                                })}
                                            />
                                            {errors.codigo_postal &&
                                                <small className="text-red-600">{errors.codigo_postal.message}</small>}
                                        </div>
                                        <div className="mb-3">
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <label className="block fontzize_label text-black mb-2"
                                                           htmlFor="telefono">
                                                        Tel&eacute;fono <span>*</span>
                                                    </label>
                                                    <input
                                                        className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id="telefono"
                                                        name='Telefono'
                                                        type="text"
                                                        {...register("Telefono", {
                                                            required: {
                                                                value: true,
                                                                message: messages.required,
                                                            },
                                                            minLength: {
                                                                value: 8,
                                                                message: messages.minLength
                                                            },
                                                            maxLength: {
                                                                value: 8,
                                                                message: messages.maxLength
                                                            },
                                                            pattern: {
                                                                value: patterns.espacioBlanco,
                                                                message: messages.espacioBlanco
                                                            },
                                                            pattern: {
                                                                value: patterns.aceptaNumeros,
                                                                message: messages.aceptaNumeros
                                                            }

                                                        })}
                                                    />
                                                    {errors.Telefono && <small
                                                        className="text-red-600">{errors.Telefono.message}</small>}
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className="block fontzize_label text-black mb-2"
                                                           htmlFor="correo">
                                                        Correo <span>*</span>
                                                    </label>
                                                    <input
                                                        className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id="correo"
                                                        name='Correo'
                                                        type="email"
                                                        {...register("Correo", {
                                                            required: {
                                                                value: true,
                                                                message: messages.required,
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: messages.maxLength
                                                            },
                                                            pattern: {
                                                                value: patterns.espacioBlanco,
                                                                message: messages.espacioBlanco
                                                            }

                                                        })}

                                                    />
                                                    {errors.Correo &&
                                                        <small className="text-red-600">{errors.Correo.message}</small>}

                                                </div>
                                            </div>

                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black mb-2"
                                                   htmlFor="direccion">
                                                Dirección <span>*</span>
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="direccion"
                                                name='Direccion'
                                                type="text"
                                                {...register("Direccion", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },
                                                    minLength: {
                                                        value: 5,
                                                        message: messages.minLength
                                                    },
                                                    maxLength: {
                                                        value: 300,
                                                        message: messages.maxLength
                                                    },
                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    }

                                                })}

                                            />

                                            {errors.Direccion &&
                                                <small className="text-red-600">{errors.Direccion.message}</small>}
                                        </div>

                                        <div className="flex gap-2 mt-2 text-white">
                                            <ButtonText
                                                type='submit'
                                                label='Registrar'
                                                principal={true}
                                                className="bg-hiperblue text-white hover:bg-hiperblue_hover"
                                                onClick={() => {
                                                }}
                                            />
                                        </div>
                                    </form>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    );
};

const mapStateToProps = state => ({
    tarjetas: state.Tarjeta.tarjetas,
    loading: state.Tarjeta.loading,
    profile: state.Profile.profile,
    array_prefijo: state.Auth.array_prefijo,
    array_departamento: state.Auth.array_departamento,
    show_alert: state.Swalerts.show_alert,
    redirect: state.Swalerts.redirect,
})

export default connect(mapStateToProps, {
    post_tarjeta, get_prefijos,get_departamento,cleadSwAlert
})(NuevaTarjeta)

// export default PurchaseList;