import {
    SET_ALERT,
    REMOVE_ALERT,
    CLEAD_ALERT,
    NO_ALERT
} from './types';

export const showRespuesta = (dispatch, res) => {
    if (res.data.ConError) {
        if (res.data.Errores.length > 0) {
            let mensaje = res.data.Errores[0].Mensaje;
            dispatch(setSwAlert(mensaje, res.data.ConError, res.data.Estado));
        } else {
            dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
        }
    }
};

export const setSwAlert = (msg,conError,codigoEstado,redireccion = null, timeout = 2000) => dispatch => {
    dispatch({
        type: SET_ALERT,
        payload: { msg, conError, codigoEstado, redireccion }
    });
}

export const setSwNoAlert = (redireccion = null, timeout = 2000) => dispatch => {
    dispatch({
        type: NO_ALERT,
        payload: { redireccion }
    });
}


export const endSwAlert = () => dispatch => {
    dispatch({ type: REMOVE_ALERT })
}

export const cleadSwAlert = () => dispatch => {
    dispatch({ type: CLEAD_ALERT })
}



