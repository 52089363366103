import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping, faLocationDot, faPenToSquare, faShareNodes, faTrash,
    faCircleXmark, faClose, faSquareMinus, faSquarePlus, faTag
} from "@fortawesome/free-solid-svg-icons";

import Modal from "react-modal";

function ModalPeso({ps_objectProducto,ps_modal_peso,ps_closeModalPeso,ps_operacion_peso,ps_cantidad,ps_set_peso_pieza}) {

    const [sucursales, guardarSucursales] = useState([]);
    const [posicion, guardarPosicion] = useState(1);
    // const [modal_peso, setModalPeso] = React.useState(false);
    // const openModalPeso =()=>setModalPeso(true);
    // const closeModalPeso =()=>setModalPeso(false);

    const [cantidadPeso, setCantidadPeso] = useState();

    useEffect(() => {
        console.log("hay cambio de cantidad");
    }, [ps_cantidad]);

    
    const pesajes = [
        {name: '500g', peso: '500'},
        {name: '1Kg', peso: '1000'},
        {name: '2Kg', peso: '2000'},
        {name: '3Kg', peso: '3000'},
        {name: '5Kg', peso: '5000'},
        {name: '10Kg', peso: '10000'},
        {name: '15Kg', peso: '15000'},
        {name: '20Kg', peso: '20000'},
        {name: '25Kg', peso: '25000'},
        {name: '30Kg', peso: '30000'},
        {name: '35Kg', peso: '35000'},
        {name: '40Kg', peso: '40000'},
        {name: '45Kg', peso: '45000'},
        {name: '50Kg', peso: '50000'},
    ];
    const customStyles = {
        overlay: {
            backgroundColor: '#000000a6',
            zIndex: '1',
            minHeight:'45rem'
        },
        content: {
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            borderRadius: '0.75rem',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible'
        },
    };

    function actualizarCantidadPeso(producto, operacion) {
        console.log("ss");
        // let cantidad = Number(producto.Cantidad);
        // let resultKg= 0;
        // if (operacion === 'incremento') {
        //     resultKg= redondeo((cantidad + 0.1),2);
        // } else if (operacion === 'decremento') {
        //     resultKg= redondeo((cantidad - 0.1),2);
        // }
        // actualizarPesoDeModal(producto,resultKg);

        // updateCantidadPeso(resultKg);
    }
    function convertToRounded(num){
        console.log('roud',num);
        return Math.round(num).toFixed(1);
    }
    function set_peso(pesaje){
        // let pesoKg= convertGramosToKg(Number(pesaje.peso));
        // updateCantidadPeso(pesoKg);
        // actualizarPesoDeModal(objectProducto,pesoKg);
    }
    function incremetOrDecrement(operacion){
        ps_operacion_peso(operacion);
    }
    function convertKgToGramos(datoKg){
        // console.log('datokg',datoKg);
        // let datoKgRd= Math.round(datoKg).toFixed(1);
        // console.log('como llegar a mukt',datoKg);

        return datoKg*1000;
    }
    function convertGramosToKg(datoKg){
        return datoKg/1000;
    }


    return (
        <>
            <Modal
                isOpen={ps_modal_peso}
                ariaHideApp={false}
                onRequestClose={ps_closeModalPeso}
                style={customStyles}
                contentLabel="address"
                >
                <div className="w-80 relative">
                    <div className="card border-0 relative">
                        <div className="absolute top-[-30px] right-[-2rem]">
                            <FontAwesomeIcon icon={faCircleXmark}
                                                className='w-[2.5rem] h-[2.5rem] rounded-full text-hiperorange bg-white cursor-pointer '
                            onClick={ps_closeModalPeso}
                            />
                        </div>
                        <div className="text-center">
                            <h5 className="font-bold">Seleccione el Peso</h5>
                        </div>
                        <div className="card-body pt-0" style={{maxHeight:'20rem', overflow:'auto'}}>
                            <div className="row">
                                {
                                    pesajes.map((pesaje, index) => (
                                        <div className="col-6" key={index}>
                                            <div
                                                className="bg-[#EEEEEE] m-2 px-3 py-2 rounded text-center cursor-pointer hover:bg-gray-400"
                                            onClick={()=>ps_set_peso_pieza(pesaje)}>
                                                <span className="font-semibold text-xl">{pesaje.name}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center">
                            <div className="flex border-[3px] border-hiperorange p-1 rounded">
                                <FontAwesomeIcon icon={faSquareMinus}
                                        className='w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer'
                                        onClick={() => ps_operacion_peso('decremento')}
                                />
                                <div className="mx-2 flex align-items-center">
                                    {/* <span>{cantidad_pieza}</span> */}
                                    <span>{convertKgToGramos(ps_cantidad)}g</span>
                                </div>
                                <FontAwesomeIcon icon={faSquarePlus}
                                    className='w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer'
                                    onClick={() => ps_operacion_peso('incremento')}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(ModalPeso)