import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './tailwind.output.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {dom} from '@fortawesome/fontawesome-svg-core'
import { register } from './serviceWorker';

dom.watch() // This will kick off the initial replacement of i to svg tags and configure a MutationObserver
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <StrictMode>
    //     <App/>
    // </StrictMode>
    <App/>
);

register();