import React from "react";
import {Link,useNavigate,useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping, faPenToSquare, faShareNodes, faTrash} from "@fortawesome/free-solid-svg-icons";


const ItemListaCompra = ({ps_shopping_list,ps_opcSelectedList,ps_deleteList,ps_openModalShare}) => {
    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();
    function SelectedOpc(valor,opcion){

        ps_opcSelectedList(valor,opcion);
    }
    function eliminarList(Id){
        ps_deleteList(Id);
    }
    function formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    async function verdetalle(id){
        let param = await get_url()
        navigate(param+`/detalle_lista_compra/${id}`);
    }
    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/"+ciudadp+"/"+sucursalp);
        } else if (url_nav) {
            return ("/"+url_nav.url_parameters);
        }
    }
    return (
        // <div className="bg-violet-600 flex justify-between px-4 py-0.5 rounded-lg border-violet-600 border-2 text-white"
        <div className=" flex justify-between px-3 py-0.5 rounded-lg border-1 text-white "
            style={{backgroundColor: ps_shopping_list.Color,
                borderColor:ps_shopping_list.Color,
                Width:'100% !important'
            }}
           
            >
            <div className="cursor-pointer" style={{width:'100%',minWidth:'20rem',overflow:"hidden",textOverflow:'ellipsis',whiteSpace:'nowrap'}} key={ps_shopping_list.Id} 
            onClick={()=>verdetalle(ps_shopping_list.Id)}>
                <FontAwesomeIcon icon={faCartShopping}/>
                {/* <span className="ml-3">{ps_shopping_list.Descripcion}</span> */}
                {/* <Link  to="/detalle_lista_compra/${}" */}
                <span className="mx-1">{+ps_shopping_list.NroItems}</span> 

                {/* <Link  to={`/detalle_lista_compra/${ps_shopping_list.Id}`}
                    className="text-white">                                           
                                     
                </Link> */}
                <span  className="ml-2"> {ps_shopping_list.Descripcion} </span>  
                    
            </div>
            <div className="z-2 " style={{minWidth:'12rem', textAlign:'end'}}>
                <span>Bs. {formatPrice(ps_shopping_list.ImporteTotal)}</span>
                <FontAwesomeIcon icon={faShareNodes} onClick={()=>SelectedOpc(ps_shopping_list,'share')} className="mx-2 cursor-pointer"/>
                <FontAwesomeIcon icon={faPenToSquare} 
                onClick={() => SelectedOpc(ps_shopping_list,'edit')}
                // onClick={() => {
                //     //function expression
                //     SelectedOpc('w');
                //     //function call:ejecuta
                //     // ps_verifyOpcModal();
                //   }}
                // onClick={SelectedOpc(ps_shopping_list.Id)} 
                className="mx-2 cursor-pointer"/>

                <FontAwesomeIcon icon={faTrash}  onClick={() => eliminarList(ps_shopping_list.Id)} className="cursor-pointer"/>
            </div>
        </div>

        



    );
};

export default ItemListaCompra;