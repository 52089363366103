import React, {useState, useEffect} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import {faMotorcycle} from "@fortawesome/free-solid-svg-icons";
import {faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {setPedidosUsuarioFireb} from "../../src/redux/actions/pedido";
import {
    faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import '../assets/styles/inicio.scss';
import firebase from './../Firebase3';

const EstadoPedidoItem = ({pedido, profile, pedidos_firebase, showtodopedidos, actualizar_item}) => {
    const [showItem, setShowItem] = useState(true);
    const [pedido_firebase, setPedido_firebase] = useState(null);
    useEffect(() => {
        if (pedido_firebase) {
            let estado_pedido_id = pedido_firebase.EstadoPedidoCliente.Id;
            if (estado_pedido_id === 7 || estado_pedido_id === 6) {
                setTimeout(() => {
                    actualizar_item();
                    setShowItem(false);
                }, "2000");
            }else{
                setShowItem(true);
            }
        }
    }, [pedido_firebase]);


    useEffect(() => {
        async function fetchData() {
            try {

                let tipo_user = localStorage.getItem('user');
                if (profile !== null && Object.keys(pedido).length !== 0 && tipo_user !== '"anonimo"') {
                    const databaseRef = firebase.database().ref('pedidos/' + profile.IdUsuario);
                    const handleDataChange = (snapshot) => {
                        search_pedido(snapshot.val(), pedido.NroPedido);
                    };
                    databaseRef.on('value', handleDataChange);
                    return () => {
                        databaseRef.off('value', handleDataChange);
                    };
                }
            } catch (e) {
                console.error(e);
            }
        };
        fetchData();

    }, []);

    function search_pedido(pedidos, pedido_id) {
        if (pedidos) {
            const pedidos_aux = Object.values(pedidos)
            pedidos_aux.forEach((el) => {
                if (el.NroPedido === pedido_id) {
                    setPedido_firebase(el);
                    return;
                }
            });
        }
    }

    function pintar(pedido) {
        if (pedido === null) {
            return null;
        }

        const code = pedido.EstadoPedidoCliente.Id;
        if (code === 1) {
            return "solicitado";
        } else {
            if (code === 3) {
                return "enproceso";
            } else {
                if (code === 4 || code === 5) {
                    return "encamino";
                } else {
                    if (code === 6) {
                        return "entregado";
                    }
                }
            }
        }
    }

    return (
        showItem ?
        (
            <div>
                
                <div className=" bg-white border  " style={{borderBottomLeftRadius:'10px', borderBottomRightRadius:'10px'}}>
                    <div className="body_panel_state_pedido">
                        <>
                            <div className="estilo_container_icono_state_pedido2">
                                <div
                                    className={`circulo_icono_state_pedidos ${pintar(pedido_firebase) === 'solicitado' ? 'color_azul' : 'color_plomo'}`}>
                                    <FontAwesomeIcon icon={faDownload} style={{
                                        height: "20px",
                                        paddingRight: "10px", paddingLeft: "10px", color: "#fff", fontSize: "13px"
                                    }}/>
                                </div>
                                <span style={{color: "#acacac"}} className="estilo_size_font_icon">Solicitado</span>
                            </div>
                            <div className="estilo_icon_rigth2" style={{position:"relative",marginRight: "3px", marginLeft: "3px"}}>
                                 <div style={{ position:"absolute", top:"26%"}}>
                                     <FontAwesomeIcon icon={faAnglesRight} style={{
                                         height: "20px",
                                         color: "#acacac", fontSize: "13px"
                                     }} className="estilo_size_font_icon"/>
                                 </div>

                            </div>
                            <div className="estilo_container_icono_state_pedido2"  style={{ width:"64px",height:"auto"}}>
                                <div
                                    className={`circulo_icono_state_pedidos ${pintar(pedido_firebase) === 'enproceso' ? 'color_verde text-white' : 'color_plomo'} `}>
                                    <FontAwesomeIcon icon={faRotate} style={{
                                        height: "20px",
                                        paddingRight: "10px", paddingLeft: "10px",paddingTop:"2px" , color: "#fff", fontSize: "13px"
                                    }}/>
                                </div>
                                <span style={{color: "#acacac", width: "100%", textAlign: "center"}}
                                        className="estilo_size_font_icon">En proceso</span>
                            </div>
                            <div className="estilo_icon_rigth2" style={{position:"relative", marginRight: "3px", marginLeft: "3px"}}>
                                <div style={{ position:"absolute", top:"26%"}}   >
                                    <FontAwesomeIcon icon={faAnglesRight} style={{
                                        height: "20px",
                                        color: "#acacac", fontSize: "13px"
                                    }} className="estilo_size_font_icon"/>
                                </div>

                            </div>

                            <div className="estilo_container_icono_state_pedido2">
                                <div
                                    className={`circulo_icono_state_pedidos ${pintar(pedido_firebase) === 'encamino' ? 'color_lila text-white' : 'color_plomo'} `}>
                                    <FontAwesomeIcon icon={faMotorcycle} style={{
                                        height: "20px",
                                        paddingRight: "10px", paddingLeft: "8px", color: "#fff", fontSize: "13px",paddingTop:"2px"
                                    }}/>
                                </div>
                                <span style={{color: "#acacac"}} className="estilo_size_font_icon">En camino</span>
                            </div>
                            <div className="estilo_icon_rigth2" style={{position:"relative",marginRight: "3px", marginLeft: "3px"}}>
                                <div   style={{ position:"absolute", top:"26%"}}  >
                                    <FontAwesomeIcon icon={faAnglesRight} style={{
                                        height: "20px",
                                        color: "#acacac", fontSize: "13px"
                                    }} className="estilo_size_font_icon"/>
                                </div>

                            </div>
                            <div className="estilo_container_icono_state_pedido2">
                                <div
                                    className={`circulo_icono_state_pedidos ${pintar(pedido_firebase) === 'entregado' ? 'color_celeste text-white' : 'color_plomo'} `}>
                                    <FontAwesomeIcon icon={faThumbsUp} style={{
                                        height: "20px",
                                        paddingRight: "10px", paddingLeft: "10px", color: "#fff", fontSize: "13px"
                                    }}/>
                                </div>
                                <span style={{color: "#acacac"}} className="estilo_size_font_icon">Entregado</span>
                            </div>
                        </>
                    </div>
                </div> 
                    
                
            </div>
        ):(
            <></>
        )
            

    );
};

const mapStateToProps = state => ({

    pedidos_firebase: state.Pedido.pedidos_firebase

})

export default connect(mapStateToProps, {
    setPedidosUsuarioFireb
})(EstadoPedidoItem)

