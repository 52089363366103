import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowCircleUp,
    faCartShopping, faChevronUp,
    faCircleXmark, faEye,
    faHeart, faLocationDot, faMessage,
    faMinus, faMoneyBill, faMotorcycle,
    faPlus,
    faRightFromBracket, faSquareMinus, faSquarePlus, faTimesCircle,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";
import {Popover, Transition} from '@headlessui/react';
import classNames from "classnames";
import {
    confirmar_carrito,
} from "../../../redux/actions/carrito";
import {save_facturacion} from "../../../redux/actions/profile";
import { save_formas_pago} from "../../../redux/actions/metodoPagos";
import {Circles} from "react-loader-spinner";
import {Disclosure} from '@headlessui/react'
import cafe from "../../../assets/images/cart/cafe.jpg";
import ItemCarrito from "../../../widgets/item_carrito";
import ItemCarritoModal from "../../../widgets/item_carrito_modal";
import Swal from "sweetalert2";
import {useNavigate,useParams} from "react-router-dom";
import Modal from "react-modal";
import Favorito from "../../../widgets/favoritoMycart";
import ItemConfirmacionPedido from "../../../widgets/item_confirmacion_pedido";
import {reset_datos_pago} from "../../../redux/actions/metodoPagos";
import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/responsive.scss"
import ButtonText from "../../../widgets/button_text";

import Registro_user from './registro_user';
import Registro_activate from './registro_activate';
import Termino_condicion from './termino_condicion';


const customStyles2 = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '31',
    },
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0 px',
        border: 'none',
        background: 'none',
        padding: '0px 10px 10px 10px',
        marginRight: '-50%',
        // marginTop: '50px',
        transform: 'translate(-50%, -50%)',
        display:'flex',
        justifyContent:'center',
        width:'100%'
    },
};


function RegistroInit({
                            profile,
                          setCloseModal
                            
                        }) {
    const { ciudadp, sucursalp } = useParams();
    const [token] = useState(localStorage.getItem('access_token'));
    const [subPedidos, guardarsubPedidos] = useState([]);
    const [listaVales, setListaVales] = useState([]);
    const [listaComentarios, setListaComentarios] = useState([]);
    const [es_anonimo, setEsAnonimo] = useState(false);
    const [vale, setVale] = useState('');
    const navigate = useNavigate();
    

    const [opc_numero,setOpcion_numero]= useState(true);
    const [posicion, setPosicion] = useState(1);

    const [modalRegistro, setModalRegistro] = useState(true);
    const [sucursales, guardarSucursales] = useState([]);
    useEffect(() => {
        if (profile) {
            if (!profile.Telefono) {
                setEsAnonimo(true);
                return;
            }else{
                setEsAnonimo(false);
            }
        }
    }, [profile]);

 


    const endModalRegistro = ()=>{
        console.log("cerrar modal");
        setModalRegistro(false);
        setCloseModal(true);
        if(!verifyParametroUrl()){
         //   window.history.back();
        }
        
    }
    function verifyParametroUrl(){
        console.log("sucursalp XD1",sucursalp , ciudadp)
        if(sucursalp=="" && ciudadp==""){
             console.log("pasoo por tyrue");
          return true;
        }
        return false;
      }



    function showModal_vale(idsucursal) {
        console.log("show modal ", idsucursal);
        cerrar_modal(idsucursal);
    }



    function cerrar_modal(idsucursal) {
        document.getElementById('modal' + idsucursal).classList.toggle('hidden');
    }



    // opcione registro

    async function selected_opcion(value){
        console.log("opc bool", value);
        setOpcion_numero(value);
    }
    async function cambiar_posicion(numero){
        setPosicion(numero);
    }
    const check_no_anonimo= async ()=>{
        setEsAnonimo(false);
        
      }
   


    return (
    
           
            <Modal
                isOpen={modalRegistro}
                ariaHideApp={false}
                onRequestClose={endModalRegistro}
                style={customStyles2}
                contentLabel="Ingresar"
                id="theIdRegister"
            >
             
                {posicion === 1 &&
                    <Registro_user 
                    ps_posicion={cambiar_posicion}
                    ps_endModalRegistro={endModalRegistro} 
                    ></Registro_user>
                }
                {posicion === 2 &&
                    <Registro_activate 
                    ps_posicion={cambiar_posicion}
                    ps_endModalRegistro={endModalRegistro} 
                    ></Registro_activate>
                }
                {posicion === 3 &&
                    <Termino_condicion 
                    ps_posicion={cambiar_posicion}
                    ps_endModalRegistro={endModalRegistro} 
                    ></Termino_condicion>
                }

            </Modal>

    
    );
}

const mapStateToProps = state => ({
    profile: state.Profile.profile,

})

export default connect(mapStateToProps, {
    
 
})(RegistroInit)