import React from "react";
import {connect} from "react-redux";
import "./wait_loading.css";

const WaitLoading = ({peticion,peticion_label}) => {
    return (
        <div className={peticion ? 'block':'hidden'}>
            <div className="loading">
                Loading&#8230;
            </div>
            <div className="loading_title">
                {peticion_label}
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    peticion: state.Peticion.peticion,
    peticion_label: state.Peticion.peticion_label,
})

export default connect(mapStateToProps, {})(WaitLoading)

