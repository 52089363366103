import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../components/navigation/Navbar";
import Footer from "../components/navigation/Footer";
import React, {useEffect, useState} from 'react';
import {refresh} from '../redux/actions/auth';
import {connect} from 'react-redux';
import {info_session} from "../redux/actions/profile";
import {get_ubicacion} from "../redux/actions/location";
import {get_sucursales} from "../redux/actions/branch_office";
/*import RegistroInit from "../components/modal/registroUser/registro_init";*/
import RegistroInit from "../components/modal/registroUser/registro_init"
const Layout = (props ) => {


    /*
    const [openModalUserRegister, setOpenModalUserRegister] = useState(false);
    const ModalUserRegister = () => setOpenModalUserRegister(!openModalUserRegister);
    */

    let token = (e) => {
        return localStorage.getItem('access_token');
    };
    useEffect(() => {
        // props.refresh();

        if (props.locations.length === 0) {
            props.get_ubicacion();
        }

        if (props.branch_offices.length === 0) {
            console.log("branch")
            props.get_sucursales();
        }
        if (!props.profile) {
            props.info_session();
        }


    }, []);

  /*  const verifyUserAutenticate = () => {
        if (profile.Telefono !== "") {
            return true;
        }
        return false;
    }
    */

    return (
        <div className='relative' style={{zIndex: '1'}}>

            <Navbar/>  
            <ToastContainer autoClose={5000}/>
            {props.children}

        </div>
    )
}

const mapStateToProps = state => ({
    profile: state.Profile.profile,
    locations: state.Location.locations,
    branch_offices: state.BranchOffice.branch_offices,
})

export default connect(mapStateToProps, {
    refresh, info_session, get_ubicacion, get_sucursales
})(Layout)

// export default Layout;