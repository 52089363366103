import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {Link, useNavigate,useParams} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
    get_fechas_entrega_sucursal_carrito,
    get_lista_carrito_compras,
    save_horarios
} from "../../../redux/actions/carrito";
import ItemHorario from "../../../widgets/item_Horario";
import {Menu} from "@headlessui/react";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHeart,
    faLocationDot,
    faMessage,
    faMotorcycle,
    faRoad,
    faStore, faTrash,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {set_tipo_entrega} from "../../../redux/actions/branch_office";
import {useForm} from "react-hook-form";
import {cambiar_ubicacion, get_ubicacion, save_datos_entrega_p} from "../../../redux/actions/location";
import {info_session} from "../../../redux/actions/profile";
import {get_list_metodos_pagos, save_formas_pago} from "../../../redux/actions/metodoPagos";
import ItemOpcionPago from "../../../widgets/item_opcion_pago";

import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/stylePageGlobals.scss";
import "../../../assets/styles/responsive.scss"
import {get_tarjeta} from "../../../redux/actions/tarjeta";


function Tarjeta({get_tarjeta, tarjetas, change_selec, valor}) {
    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();

    useEffect(() => {
        get_tarjeta()
    }, []);

    function getFormatoFecha(fecha) {
        let fechaSub = fecha.substring(0, 10);
        return fechaSub;
    }

    const selec_op = (id) => {
        change_selec(id);
    };
    async function navigate_menu(url_menu) {
        let param = await get_url();
        navigate(param + '/' + url_menu);
    }

    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/" + ciudadp + "/" + sucursalp);
        } else if (url_nav) {
            return ("/" + url_nav.url_parameters);
        }
    }


    return (
        <>
            <div className="flex justify-center flex-wrap gap-2">
                {
                    tarjetas &&
                    tarjetas !== null &&
                    tarjetas !== undefined &&
                    tarjetas.map((ps_tarjeta, index) => (
                        <div
                            className={classNames("w-[13rem]", "h-auto", "p-2", "rounded-lg", "cursor-pointer",
                                {
                                    "border-8 border-black": ps_tarjeta.idTarjeta == valor
                                })}
                            style={{backgroundColor: ps_tarjeta.color}}
                            key={ps_tarjeta.idTarjeta}
                            onClick={(e) => selec_op(ps_tarjeta.idTarjeta)}
                        >
                            <div className="flex justify-end h-[1rem]">
                                {ps_tarjeta.preferida &&
                                    <FontAwesomeIcon className=" text-white" icon={faHeart}/>
                                }

                            </div>
                            <div className='flex flex-col justify-center text-center text-white'>
                                <span>{ps_tarjeta.propietario}</span>
                                <div className='text-center p-1 mb-1 bg-white text-gray-500 rounded-lg'>
                                    <span>{ps_tarjeta.cardPrefix}xxxxxx{ps_tarjeta.cardSufix}</span>
                                </div>
                                <div className='text-center p-1 mb-1 bg-white text-black rounded-lg'>
                                    <span
                                        className="font-extrabold  uppercase text-xl">{ps_tarjeta.descripcionTypeCard}</span>
                                </div>
                            </div>
                            <div className='flex justify-between mt-1 text-white items-center'>
                                <span className="font-bold"
                                      style={{fontSize: '0.7rem'}}>Registro {getFormatoFecha(ps_tarjeta.fecha_Crea)}</span>
                            </div>

                        </div>
                    ))
                }
            </div>
            <div className="flex justify-center my-2">
                <Link onClick={()=>navigate_menu('nueva_tarjeta')}
                      className="bg_primario rounded-lg text-center py-2  text-white px-2"
                >
                    Registrar nueva tarjeta de crédito o debito
                </Link>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    tarjetas: state.Tarjeta.tarjetas
})

export default connect(mapStateToProps, {
    get_tarjeta
})(Tarjeta)