import {Fragment, useEffect} from 'react'
import {connect} from 'react-redux'

import {CheckCircleIcon} from '@heroicons/react/solid'
import Swal from 'sweetalert2';
import {useNavigate} from "react-router-dom";
import {limpiar_perfil} from "../redux/actions/profile";
import {limpiar_auth, refresh} from "../redux/actions/auth";
import {limpiar_direccion} from "../redux/actions/location";
import {limpiar_sucursal} from "../redux/actions/branch_office";
import {limpiar_carrito} from "../redux/actions/carrito";
import {endSwAlert} from "../redux/actions/swalert";

const Swalert = ({endSwAlert, alert, limpiar_perfil, limpiar_auth, limpiar_direccion, limpiar_sucursal, limpiar_carrito,refresh}) => {


    const navigate = useNavigate();

    useEffect(() => {
        if (alert){
            onloadAlert();
        }
    }, [alert]);

    function onloadAlert() {
        let mensaje = null;
        let conError = false;
        let codigoEstado = 0;
        if (alert.alert !== null) {
            mensaje = alert.msg;
            codigoEstado = alert.codigoEstado;
            conError = alert.conError;
            if (!conError && Number(codigoEstado) === 200) {
                messageExito(mensaje);
            } else if (Number(codigoEstado) === 400 || Number(codigoEstado) === 412) {
                messageError(mensaje);
            }else{
                messageError(mensaje);
            }
            if (codigoEstado !== null && Number(codigoEstado) === 401) {

                refresh();
                setTimeout(function (){
                    window.location.reload();
                },700)


                // redirectPageLogin(mensaje);

            }
        }
    }

    function messageExito(mensaje) {
        if (mensaje !== null && mensaje !== '') {
            Swal.fire({
                icon: 'success',
                title: 'Éxito!',
                text: mensaje,
                backdrop: false
            }).then((result)=>{
                if (result.isConfirmed) {
                    endSwAlert();
                }
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Éxito!',
                text: 'Operación finalizada correctamente!',
                backdrop: false
            }).then((result)=>{
                if (result.isConfirmed) {
                    endSwAlert();
                }
            })
        }
    }

    function messageError(mensaje) {
        if (mensaje !== null && mensaje !== '') {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: mensaje,
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Hubo un problema al procesar, verifique los datos',
            })
            
        }
        endSwAlert();

    }

    function messageConfirm() {
        Swal.fire({
            title: 'Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
    }

    function redirectPageLogin(mensaje) {
        Swal.fire({
            title: 'No autorizado',
            text: mensaje,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Iniciar Sesión'
        }).then((result) => {
            localStorage.clear();
            limpiar_perfil();
            limpiar_auth();
            limpiar_direccion();
            limpiar_sucursal();
            limpiar_carrito();
            if (result.isConfirmed) {
                navigate("/login");
            }
        })
    }
}


const mapStateToProps = state => ({
    alert: state.Swalerts.alert
})

export default connect(mapStateToProps, {
    limpiar_perfil,
    limpiar_auth,
    limpiar_direccion,
    limpiar_sucursal,
    limpiar_carrito,
    refresh,endSwAlert
})(Swalert)