import React, {useEffect,useState} from 'react';

import {useNavigate} from "react-router-dom";
import Layout from "../../hocs/Layout";
import Map from "../../components/Map";
import {connect} from "react-redux";
import {update_ubicacion} from "../../redux/actions/location";
import BackToPage from "../../components/BackPage/BackToPage";
import {useParams} from 'react-router'
import ButtonText from "../../widgets/button_text";
import "../../assets/styles/seccionResponsive.scss";
import "../../assets/styles/globalColorPrincipal.css";
const ActualizarDireccion = ({show_alert,redirect,update_ubicacion, loading, locations}) => {
    const navigate = useNavigate();
    const params = useParams()
    const locationId = params.locationId


    let location = '';
    getObjectLocation();

    const [formData, setFormData] = useState({
        Id: location.Id,
        Descripcion: location.Descripcion,
        Direccion: location.Direccion,
        Referencia: location.Referencia,
        Latitud: location.Latitud,
        Longitud: location.Longitud,
        EsPrincipal: location.EsPrincipal,
    });

    const {
        Id,
        Descripcion,
        Direccion,
        Referencia,
        Latitud,
        Longitud,
        EsPrincipal
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});


    useEffect(() => {
        if (show_alert && redirect!==null) {
            if (redirect.type === 'modal') {
                if (redirect.ruta === 'back') {
                    window.history.back();
                }
            }
        }
    }, [show_alert]);

    function getObjectLocation() {
        locations.forEach((item) => {
            if (item.Id == locationId) {
                location = item;
            }
        });
    }

    function setLatLng(latlng) {
        console.log("entra a sadffas",latlng)
        setFormData({
            ...formData,
            'Latitud': latlng.lat.toString(),
            'Longitud': latlng.lng.toString()
        });
    }

    const onSubmit = e => {
        e.preventDefault();

        function guardar() {
            try {
                let object = {
                    type:'modal',
                    type_modal: true,
                    type_title: 'direccion',
                    ruta : 'back'
                };
                console.log("formData",formData)



                update_ubicacion(formData, locationId,object);
            } catch (e) {
                console.error(e);
            }
        };
        guardar();
    }

    function volverHome() {
        navigate("/");
    }


    return (
        <Layout>
            <div className=" mt-12 mb-5">
                <BackToPage ps_url_page={''}></BackToPage>
                <div className="container">
                    <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                        Actualizar Dirección </h1>
                    <div className="w-[75rem] h-auto border border-hiperorange shadow rounded-xl overflow-hidden main_container_update_direccion">
                        <div className="  container_direccion">
                            <div className="col-md-8 col-sm-12  h-[38rem]">
                                <Map onlatlng={setLatLng} data={location} editable={false}/>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <form onSubmit={e => onSubmit(e)}>
                                    <div className="grid gap-2 pr-10 mt-3">
                                        <div className="mb-3">
                                            <label className="block text_primario text-sm font-bold mb-2"
                                                   htmlFor="alias">
                                                Alias:
                                            </label>
                                            <input
                                                className="shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="alias" type="text" placeholder="Nombre para direccion"
                                                name="Descripcion" value={Descripcion} onChange={e => onChange(e)}/>
                                        </div>
                                        <div className="mb-3">
                                            <label className="block text_primario text-sm font-bold mb-2"
                                                   htmlFor="referencia">
                                                Referencia:
                                            </label>
                                            <input
                                                className="shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="referencia" type="text" placeholder="referencia del lugar"
                                                name="Referencia" value={Referencia} onChange={e => onChange(e)}/>
                                        </div>
                                        <div className="mb-3">
                                            <label className="block text_primario text-sm font-bold mb-2"
                                                   htmlFor="direccion">
                                                Dirección:
                                            </label>
                                            <textarea
                                                className="shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="direccion" type="text" placeholder="dirección del lugar"
                                                rows={2}
                                                name="Direccion" value={Direccion} onChange={e => onChange(e)}>    
                                            </textarea>
                                        </div>

                                        <div className="grid gap-3 text-white">
                                            <ButtonText
                                                type='submit'
                                                label='Guardar'
                                                principal={true}
                                                className="bg-[#DC842D] w-40"
                                                onClick={() => {
                                                }}
                                            />

                                            <ButtonText
                                                label='Cancelar'
                                                principal={false}
                                                className="w-40 text_primario"
                                                onClick={() => volverHome()}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
};


const mapStateToProps = state => ({
    loading: state.Location.loading,
    locations: state.Location.locations,
    show_alert: state.Swalerts.show_alert,
    redirect: state.Swalerts.redirect,
})

export default connect(mapStateToProps, {
    update_ubicacion
})(ActualizarDireccion)