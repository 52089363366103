import {
    SET_AUTH_LOADING,
    GET_METODO_PAGO_SUCCESS,
    GET_METODO_PAGO_FAIL,
    REMOVE_AUTH_LOADING,
    SET_DATOS_PAGO
} from './types'
import axios from 'axios';
import {setSwAlert,showRespuesta} from "./swalert";
import {end_peticion, start_peticion} from "./peticion";
import respuestas from '../../components/Alertas';


export const eliminar_cliente_nit = (dato) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/ClienteNit/eliminar`,dato, config);

        if (res.status === 200) {
            dispatch(get_list_metodos_pagos());
        } else {
            dispatch({
                // type: GET_METODO_PAGO_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;


        }

    } catch (err) {
        dispatch({
            // type: GET_METODO_PAGO_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

export const get_list_metodos_pagos = () => async dispatch => {
    dispatch({
        type: SET_AUTH_LOADING
    });

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/metodos-pago`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_METODO_PAGO_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_METODO_PAGO_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch({
            type: REMOVE_AUTH_LOADING
        });
    } catch (err) {
        dispatch({
            type: GET_METODO_PAGO_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

export const save_formas_pago = (datos) => async dispatch => {
    dispatch(start_peticion(''));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        console.log("dt_pago",datos);
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/carrito/datos-pago`, datos, config);
        if (res.status === 200) {
            dispatch({
                type: SET_DATOS_PAGO,
                payload: res.data
            });
            if (res.data.ConError) {
                dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, res.data.Estado));
            }
        }else{
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());
    } catch (err) {
        let response = err.response;
        console.log(response);
        dispatch({
            type: SET_DATOS_PAGO,
            payload: null
        });
        dispatch(end_peticion());

        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};

export const reset_datos_pago = () => async dispatch => {
    dispatch({
        type: SET_DATOS_PAGO,
        payload: null
    });
};