import {
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAIL,
    GET_PRODUCT_ITEM_SUCCESS,
    GET_PRODUCT_ITEM_FAIL, GET_PRODUCT_SECCION_SUCCESS,
    SET_LOADING,
    REMOVE_LOADING,
    CLEAR_DATA,
    CLEAR_ITEM_PRODUCTO,
    SET_SEARCH_SUCCESS,
    CLEAR_SEARCH_SUCCESS

} from '../actions/types';

const initialState = {
    productos: [],
    producto: null,
    peticion: false,
    loading: false,
    productos_seccion: [],
    search: null,
    productos_peticion: true,
    productos_peticion_seccion: true,
    end_message:false,
    
};

export default function Producto(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true
            }
        case REMOVE_LOADING:
            return {
                ...state,
                loading: false
            }

        case GET_PRODUCT_SUCCESS:
            if (payload.Dato.length > 0) {
                return {
                    ...state,
                    productos: [...state.productos, ...payload.Dato],
                    peticion: true,
                    productos_peticion: true,
                    end_message:false,
                }
            } else {
                // console.log("viene sin datos");
                return {
                    ...state,
                    productos_peticion: false,
                    peticion:false,
                    end_message:true,
                }
            }

        case GET_PRODUCT_SECCION_SUCCESS:
            let prods = observerResponse(payload.res.Dato, payload.seccionId);
            if (prods.length > 0) {
                return {
                    ...state,
                    productos_seccion: [...state.productos_seccion, ...prods],
                    productos_peticion_seccion: true
                };
            } else {
                return {
                    ...state,
                    productos_peticion_seccion: false
                }
            }

        case GET_PRODUCT_ITEM_SUCCESS:
            return {
                ...state,
                producto: payload.Dato
            }
        case GET_PRODUCT_ITEM_FAIL:
        case GET_PRODUCT_FAIL:
            return {
                ...state
            }
        case CLEAR_DATA:
            return {
                ...state,
                productos: [],
                productos_seccion: [],
                productos_peticion: true,
                productos_peticion_seccion: true,
                end_message:false
            }
        case CLEAR_ITEM_PRODUCTO:
            return {
                ...state,
                producto: null
            }
        case SET_SEARCH_SUCCESS:
            return {
                ...state,
                search: payload
            }
        case CLEAR_SEARCH_SUCCESS:
            return {
                ...state,
                search: null
            }


        default:
            return state
    }
}

function observerResponse(data, seccionId) {
    if (typeof (seccionId) != 'undefined' && typeof (data) != 'undefined') {

        if (data.length > 0) {
            return data;
        }
        if (seccionId === 3) {
            return data.Productos;
        }
        return [];
    }
    return [];
}