import {
    GET_LOCATION_SUCCESS,
    GET_LOCATION_FAIL,
    SET_AUTH_LOADING,
    REMOVE_AUTH_LOADING,
    POST_LOCATION_SUCCESS,
    POST_LOCATION_FAIL,
    GET_LOCATION_ACTUAL,
    SET_LOCATION_OMITIDO,
    SET_LOCATION_PRINCIPAL, CLEAR_ADDRESS
} from '../actions/types';

const initialState = {
    locations: [],
    direccion: null,
    loading: false,
    is_autorizado:false,
    post_location: false,
    location_omitido:true
};

export default function Location(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SET_AUTH_LOADING:
            return {
                ...state,
                loading: true
            }
        case CLEAR_ADDRESS:
            return {
                ...state,
                locations: [],
                direccion: null,
                loading: false,
                is_autorizado:false,
                post_location: false,
                location_omitido:true
            }

        case REMOVE_AUTH_LOADING:
            return {
                ...state,
                loading: false
            }
        case GET_LOCATION_SUCCESS:
            return {
                ...state,
                locations: payload.Dato,
                
            }
        case GET_LOCATION_FAIL:
            return {
                ...state
            }
        case POST_LOCATION_SUCCESS:
            return {
                ...state,
                post_location: true,
                is_autorizado: true,
            }
        case POST_LOCATION_FAIL:
            return {
                ...state,
                post_location: false
            }
        case GET_LOCATION_ACTUAL:
            return {
                ...state,
                direccion: dirfet(payload)
            }
        case SET_LOCATION_OMITIDO:
            return{
                ...state,
                location_omitido:payload
            }
        case SET_LOCATION_PRINCIPAL:
            localStorage.setItem("direccion", JSON.stringify(payload));
            return {
                ...state,
                // is_autorizado:true
            }
            
            

        default:
            return state
    
    }
    
}
function dirfet(dato){
    // console.log("dirdir",dato);
        return dato;
    
}
