import React, {useEffect,useState} from 'react';

import {Link, useNavigate} from "react-router-dom";
import Map from "../../components/Map";
import {connect} from "react-redux";
import {post_ubicacion} from "../../redux/actions/location";
import {Circles} from "react-loader-spinner";
import Location from "../../redux/reducers/location";
import BackToPage from "../../components/BackPage/BackToPage";
import ButtonText from "../../widgets/button_text";
import "../../assets/styles/seccionResponsive.scss"
import "../../assets/styles/responsive.scss"
import {useForm} from "react-hook-form";
import "../../assets/styles/globalColorPrincipal.css";
import Alert from "../../components/Swalert";
import {cleadSwAlert} from "../../redux/actions/swalert";

const Direccion = ({show_alert,redirect,post_ubicacion,cleadSwAlert, locations, loading}) => {
    const [Longitud, setLongitud] = useState('');
    const [Latitud, setLatitud] = useState('');


    const {register, handleSubmit, formState: {errors}, setValue} = useForm();
    const messages = {
        required: "Este campo es obligatorio",
        minLength: "No puede ingresar menor caracteres de lo establecido",
        maxLength: "No puede ingresar más caracteres en este campo",
        espacioBlanco: 'No es permitido espacios en blanco',
        requiredColor: 'Debe seleccionar un color',
        aceptaNumeros: 'Solo se acepta números en este campo',
        aceptaLetra: 'Solo se acepta letras en este campo'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
        aceptaNumeros: /^[0-9,-]+$/,
        aceptaLetra: /^[A-Za-z\s]+$/,
        // mail:/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    };

    useEffect(() => {
        
        if (show_alert) {
            console.log(redirect , 'modal')
            if(redirect){
                console.log(redirect.type , 'modal')
                if (redirect.type === 'modal') {
                    if (redirect.ruta === 'back') {
                        window.history.back();
                    }
                }
            }
           
        }
    }, [show_alert]);

    useEffect(() => {
        return () => {
        cleadSwAlert()
        };
    }, []);



    const setDireccion = (value) => {
        setValue('Direccion', value);
    }

    const navigate = useNavigate();

    function setLatLng(latlng) {
        setLatitud(latlng.lat);
        setLongitud(latlng.lng);
    }

    const onSubmit = async (formInfo) => {
        let EsPrincipal = false;
        if (locations.length === 0) {
            EsPrincipal = true;
        }
        const datos = {
            "Descripcion": formInfo.Descripcion,
            "Direccion": formInfo.Direccion,
            "Referencia": formInfo.Referencia,
            "Longitud": Longitud,
            "Latitud": Latitud,
            "EsPrincipal": EsPrincipal
        };
        let object = {
            type:'modal',
            type_modal: true,
            type_title: 'direccion',
            ruta : 'back'
        };
        await post_ubicacion(datos,object);
    }

    // function volverHome() {
    //     navigate("/");
    // }

    return (
        <>
           <Alert></Alert>
            <div className="mt-12">
                <BackToPage ps_url_page={''}></BackToPage>
                <div className="container">
                    <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                        Nueva dirección </h1>
                    <div
                        className="w-auto h-auto border border-hiperorange shadow rounded-xl overflow-hidden main_container_update_direccion">
                        <div className="container_direccion">
                            <div className="col-sm-8  h-[38rem] ">
                                <Map onlatlng={setLatLng} onClicDireccion={setDireccion} editable={true}/>
                            </div>
                            <div className="col-sm-4">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="grid gap-2  mt-3">
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black  mb-2"
                                                   htmlFor="alias">
                                                Alias:
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="alias" type="text" placeholder="Nombre para direccion"
                                                {...register("Descripcion", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: messages.minLength
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: messages.maxLength
                                                    },
                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    },
                                                })}

                                            />
                                            {errors.Descripcion &&
                                                <small className="text-red-600">{errors.Descripcion.message}</small>
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black  mb-2"
                                                   htmlFor="referencia">
                                                Referencia:
                                            </label>
                                            <input
                                                className=" appearance-none border_black radius_input  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="referencia" type="text" placeholder="referencia del lugar"
                                                {...register("Referencia", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: messages.minLength
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: messages.maxLength
                                                    },
                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    },
                                                })}
                                            />
                                            {errors.Referencia &&
                                                <small className="text-red-600">{errors.Referencia.message}</small>
                                            }
                                        </div>
                                        <div className="mb-3">
                                            <label className="block fontzize_label text-black  mb-2"
                                                   htmlFor="direccion">
                                                Dirección:
                                            </label>
                                            <textarea
                                                className=" appearance-none border_black radius_input  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="direccion" placeholder="dirección del lugar"
                                                rows={2}
                                                {...register("Direccion", {
                                                    required: {
                                                        value: true,
                                                        message: messages.required,
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message: messages.minLength
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: messages.maxLength
                                                    },
                                                    pattern: {
                                                        value: patterns.espacioBlanco,
                                                        message: messages.espacioBlanco
                                                    },
                                                })}
                                            >
                                            </textarea>
                                            {errors.Direccion &&
                                                <small className="text-red-600">{errors.Direccion.message}</small>
                                            }
                                        </div>


                                        <div className="grid gap-3 text-white">

                                            <ButtonText
                                                type='submit'
                                                label='Guardar'
                                                principal={true}
                                                className="bg-hiperorange w-40 hover:bg-hiperorange_hover text-white"
                                                onClick={() => {
                                                }}
                                            />

                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};


const mapStateToProps = state => ({
    locations: state.Location.locations,
    loading: state.Location.loading,
    show_alert: state.Swalerts.show_alert,
    redirect: state.Swalerts.redirect,
})

export default connect(mapStateToProps, {
    post_ubicacion,cleadSwAlert
})(Direccion)