import React, {Fragment, useEffect, useState} from 'react';
import {Popover, Transition} from "@headlessui/react";
import logo from "../../assets/images/login/logo.png";
import {MenuIcon} from "@heroicons/react/outline";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBagShopping,
    faBars,
    faCamera,
    faCartShopping,
    faChevronDown,
    faCreditCard,
    faHeadset,
    faHeart,
    faMapLocationDot,
    faMotorcycle,
    faRightFromBracket,
    faShoppingCart,
    faStore,
    faTimes,
    faUser,
    faSearch, faQrcode, faArrowDown,

} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate, useParams} from "react-router-dom";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Modal from "react-modal";


import '../../assets/styles/globals.scss';
import '../../assets/styles/carrito.scss';
import Direccion from "../modal/direccion";
import Sucursal from "../modal/sucursal";

import {connect} from "react-redux";
import Notificacion from "../home/Notificacion";
import MiCarrito from "../modal/mi_carrito";

import {limpiar_carrito, limpiar_estado_anonimo, store_carrito_data, update_carrito} from "../../redux/actions/carrito";
import {limpiar_perfil} from "../../redux/actions/profile";
import {limpiar_auth} from "../../redux/actions/auth";
import {limpiar_direccion,get_ubicacion} from "../../redux/actions/location";
import {limpiar_sucursal} from "../../redux/actions/branch_office";
import Swal from "sweetalert2";
import Alert from "../Swalert";
import ButtonText from "../../widgets/button_text";
import {cleadSwAlert} from "../../redux/actions/swalert";
import {set_search} from "../../redux/actions/producto";
import {limpiar_data_lista_compra} from '../../redux/actions/shoppingList';
import {clear_pedidos} from "../../redux/actions/pedido";
import ButtonIcon from "../../widgets/button_icon";
import BuscadorGeneral from "../../widgets/BuscadorGeneral";
import RegistroInit from "../../components/modal/registroUser/registro_init"
import classNames from "classnames";
import {
    get_lista_carrito_compras,get_carrito_por_sucursal
} from "../../redux/actions/carrito";

const customStyles = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '30',
    },
    content: {
        top: '45%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '10px',
        marginRight: '-50%',
        padding:'0px',
        transform: 'translate(-50%, -50%)',
        border:'0px',
        background:'transparent'

    },
};
const customStyles2 = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '10',
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0 px',
        border: 'none',
        background: 'none',
        padding: '0px 0px 0px 0px',
        marginRight: '-50%',
        marginTop: '120px',
        transform: 'translate(-50%, -50%)',
        overflow:'none',
    },
};

function Navbar({
                    show_alert, redirect, cleadSwAlert,
                    cambio_carrito,
                    list_carrito,
                    direccion,
                    store_carrito_data,
                    update_carrito,
                    sucursal,
                    profile,
                    limpiar_perfil,
                    limpiar_auth,
                    limpiar_direccion,
                    limpiar_sucursal,
                    limpiar_carrito,
                    clear_pedidos,
                    carrito,
                    url_navigation,
                    respuesta_carrito_anonimo,
                    respuesta_carrito_user,
                    limpiar_estado_anonimo,
                    limpiar_data_lista_compra,
                    get_ubicacion,
                    get_lista_carrito_compras,
                    cambio_detalle_carrito,
                    get_carrito_por_sucursal
                }) {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [showMenu, setShow2] = React.useState(true);

    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();
    const [numerocel, setNumerocel] = React.useState(59172682571);
    const [openModalUserRegister, setOpenModalUserRegister] = useState(false);


    function afterOpenModal() {
    }

    //START CODIGO DE DIRECCION
    const [modalAddress, setAddress] = useState(false);
    const [profileAux, setProfileAux] = useState(null);
    const startAddress = () => setAddress(true);
    const endAddress = () => setAddress(false);


    useEffect(() => {
        if (sucursal) {
            setNumerocel(sucursal.ContactoWhatsapp);
        }

    }, [sucursal]);

    useEffect(()=>{
        if(verifyUserAutenticate()){
            get_ubicacion();
            if (list_carrito===null) {
                console.log("cargado NavBar")
                get_carrito_por_sucursal(sucursal);
            }
        }
    },[profile]);


    useEffect(() => {
        if (show_alert && redirect !== null) {
            if (redirect.type === 'modal' && redirect.type_modal) {
                if (redirect.type_title === 'direccion') {
                    startAddress();
                    setTimeout(() => {
                        cleadSwAlert();
                    }, "1000");
                }
                if (redirect.type_title === 'carrito') {
                    startCarrito();
                    setTimeout(() => {
                        cleadSwAlert();
                    }, "1000");
                }

            }
        }
    }, [show_alert]);


    // useEffect(() => {
    //     if (list_carrito !== null) {
    //         for (var item of list_carrito.SubPedidos) {
    //             let params = {
    //                 IdMarket: item.IdMarket,
    //                 IdSucursal: item.Sala.Id,
    //                 IdTipoEntrega: item.IdTipoEntrega,
    //                 IdDireccion: direccion.Id,
    //             };
    //             let carritos_aux = item.Productos;
    //             if (item.IdMarket === sucursal.IdSucursal) {
    //                 update_carrito(carritos_aux);
    //             }
    //             store_carrito_data(params, carritos_aux, []);
    //         }
    //
    //     }
    // }, [list_carrito]);

    const iniciar_session = () => {
        setOpenModalUserRegister(true);
    }
    const logout = () => {
        const val = localStorage.getItem("redId");
        localStorage.clear();
        if (val) {
            localStorage.setItem("redId", val);
        }
        clear_pedidos();
        limpiar_perfil();
        limpiar_auth();
        limpiar_direccion();
        limpiar_sucursal();
        limpiar_carrito();
        limpiar_data_lista_compra();
        navigate("/login")
    };
    // async function clearLocalStorage(){
    //     await localStorage.clear();
    // }
//START CODIGO DE CARRITO
    const [modalCarrito, setCarrito] = useState(false);
    const startCarrito = () => {

        if(!verifyUserAutenticate()){
            setOpenModalUserRegister(true);
        }else if (direccion) {
            setCarrito(true)
        } else {
            Swal.fire({
                title: "Oops...",
                text: "Debe registrar una ubicación",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#0349AB",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ir a registrar"
            }).then((result) => {
                if (result.isConfirmed) {
                    // navigate("/nueva_direccion");
                    navigate("/nueva_direccion_registro")
                    // navigate_menu('nueva_direccion_registro');
                }
            });
        }

    };


    async function endCarrito() {
        if (respuesta_carrito_user) {
            await limpiar_carrito();
            setCarrito(false);
            navigate("/" + ciudadp + "/" + sucursalp);
        } else {
            setCarrito(false);
        }


    }

    function cerraModal() {
        setCarrito(false);
    }


    async function endCarritoAnonimo() {
        // for (var item of list_carrito.SubPedidos) {
        //     let params = {
        //         IdMarket: item.IdMarket,
        //         IdSucursal: item.Sala.Id,
        //         IdTipoEntrega: item.IdTipoEntrega,
        //         IdDireccion: direccion.Id,
        //     };
        //     let carritos_aux = item.Productos;
        //     if (item.IdMarket === sucursal.IdSucursal) {
        //         update_carrito(carritos_aux);
        //     }
        // }

        await limpiar_estado_anonimo();
        setCarrito(false);
        navigate("/" + ciudadp + "/" + sucursalp);
    }


    //END CODIGO DE DIRECCION

    useEffect(() => {
        // console.log("este es prof test", profile);
        setProfileAux(profile);
    }, [profile]);


    const cambiarestado = () => {
        setShow(show => (!show));
        // console.log("lego lista" + show);
    };
    const abrirMenu = () => {
        setShow2(showMenu => (false));
    };
    const hideMenu = () => {
        setShow2(showMenu => (true));
    };

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    function hasSpecialChars(str) {
        const specialCharsRegex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        return specialCharsRegex.test(str);
    }


    const ir_inicio = () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            navigate("/" + ciudadp + "/" + sucursalp);
        } else if (url_nav) {
            navigate("/" + url_nav.url_parameters);
        }
    }

    async function navigate_menu(url_menu) {
        if( verifyUserAutenticate()){
            let param = await get_url();
            navigate(param + '/' + url_menu);
        }else{

            setOpenModalUserRegister(true);
        }

    }

    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/" + ciudadp + "/" + sucursalp);
        } else if (url_nav) {
            return ("/" + url_nav.url_parameters);
        }
    }

    const verifyUserAutenticate = () => {
        if(profile){
            if(profile.Telefono!=null && profile.Telefono!==""){
              return true;
            }
          }
        // if (profile && profile.Telefono!=null && profile.Telefono !== "") {
        //     return true;
        // }
        return false;
    }
    const cerrarVentana = (valor) => {
      console.log("llego valor cerrar ventana ",valor)
        setOpenModalUserRegister(false);
    }

    const abrirDirecciones= ()=>{
        if(verifyUserAutenticate()){
            startAddress();
        }else{
            setOpenModalUserRegister(true);
        }
        
    }

    return (
        <>
            <Alert></Alert>
            {openModalUserRegister &&
            <RegistroInit  setCloseModal={cerrarVentana}></RegistroInit>
            }

            <Popover className="relative py-1">
                {({open}) => (
                    <>
                        <div className="absolute inset-0 shadow z-30 pointer-events-none" aria-hidden="true"/>
                        <div className="relative z-20 bg-white">
                            <div
                                className="flex justify-between items-center px-2 sm:px-6  lg:px-8 md:justify-start md:space-x-10">
                                <div className="mr-1">
                                    <div className="cursor-pointer" onClick={() => ir_inicio()}>
                                        <span className="sr-only">Workflow</span>
                                        <img
                                            className="h-8 w-auto sm:h-10"
                                            src={logo}
                                            alt="Hipermaxi"
                                        />
                                    </div>
                                </div>

                                <div className='flex flex-row'>
                                    <div>
                                        <p className="mb-0 text-sm">Sucursal</p>
                                        <p
                                            className="mb-0 text-hiperblue font-bold cursor-pointer no-underline hover:underline"
                                            onClick={() => openModal()}
                                        ><span
                                            className="hidden md:inline-block text-sm">{sucursal.TipoServicio.Descripcion}</span> {sucursal.Descripcion}
                                            <FontAwesomeIcon
                                                style={{width: '15px', height: '15px'}}
                                                icon={faChevronDown}/>
                                        </p>
                                    </div>
                                    <div className={classNames("relative", "self-center", "carrito_movil",
                                             {"cursor-pointer": !cambio_detalle_carrito}
                                         )}
                                         onClick={() => (!cambio_detalle_carrito? startCarrito() : {} ) }>
                                        <FontAwesomeIcon icon={faCartShopping}
                                                         className="relative  px-2 icon_cotificacion"
                                                         style={{color: '#0349AB'}}>
                                        </FontAwesomeIcon>
                                        {
                                            carrito &&
                                            carrito.length > 0 &&
                                            <div
                                                className="absolute top-[-20%] left-[50%] w-4 h-4 p-2.5  flex justify-center items-center rounded-full"
                                                style={{backgroundColor: '#DC842D'}}>
                                                <span className="text-white">{carrito.length}</span>
                                            </div>
                                        }
                                    </div>
                                    <div className="relative self-center notificacion_movil">
                                        {profileAux && <Notificacion profile={profileAux}></Notificacion>}
                                    </div>


                                    {/* <div className="flex items-center -mr-2 -my-2 md:hidden"> */}
                                    <div className="w-auto self-center md:hidden">
                                        <Popover.Button
                                            className={`
                                    ${open ? 'text-black' : 'text-black'}
                                     inline-flex items-center rounded-md bg-white   font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                                        >
                                            {!open ? (
                                                // <MenuIcon className="px-1"    style={{width: '25px',minWidth:'25px', maxWidth:'35px',color:'#0349AB'}} />
                                                <FontAwesomeIcon icon={faBars}
                                                                 className=" px-1 "
                                                                 style={{
                                                                     width: '25px',
                                                                     minWidth: '25px',
                                                                     maxWidth: '25px',
                                                                     color: '#0349AB'
                                                                 }}/>

                                            ) : (
                                                <FontAwesomeIcon icon={faTimes}
                                                                 className=" px-1 "
                                                                 style={{
                                                                     width: '25px',
                                                                     minWidth: '25px',
                                                                     maxWidth: '25px',
                                                                     color: '#0349AB'
                                                                 }}/>
                                            )}
                                        </Popover.Button>
                                    </div>
                                </div>

                                {/* desktop   */}
                                <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                                    <BuscadorGeneral/>
                                    <div className="flex items-center">
                                        <div className="flex mr-2 text-white">
                                            <Link
                                                to={'https://api.whatsapp.com/send?phone=' + numerocel + '&text=Hola!'}
                                                target="_blank">
                                                <FontAwesomeIcon icon={faWhatsapp} className=" px-2"
                                                                 style={{fontSize: '30px', color: '#4EC76D'}}/>
                                            </Link>
                                            <div className={classNames("relative",
                                                     {"cursor-pointer": !cambio_detalle_carrito}
                                                 )}
                                                 onClick={() => (!cambio_detalle_carrito? startCarrito() : {} ) }>
                                                <FontAwesomeIcon icon={faCartShopping} className="relative  px-2"
                                                                 style={{fontSize: '30px', color: '#0349AB'}}>
                                                </FontAwesomeIcon>
                                                {
                                                    carrito &&
                                                    carrito.length > 0 &&
                                                    <div
                                                        className="absolute top-[-20%] left-[50%] w-4 h-4 p-2.5  flex justify-center items-center rounded-full"
                                                        style={{backgroundColor: '#DC842D'}}>
                                                        <span className="text-white">{carrito.length}</span>
                                                    </div>
                                                }


                                            </div>
                                            {profileAux && <Notificacion profile={profileAux}></Notificacion>}
                                            {/* <ButtonText
                                        label='Cambiar Sucursal'
                                        className="bg-[#32338E]"
                                        onClick={() => openModal()}
                                    /> */}

                                        </div>

                                        <Popover className="relative inline ">
                                            {({open}) => (
                                                <>
                                                    <Popover.Button
                                                        className="text-white focus:outline-none ">
                                                        <FontAwesomeIcon icon={faBars}
                                                                         style={{fontSize: '20px', color: '#0349AB'}}
                                                        />
                                                    </Popover.Button>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >

                                                        <Popover.Panel
                                                            className="absolute bg-hiperorange -right-8 px-4 z-20 rounded-bl-2xl mt-4">
                                                            {({close}) => (
                                                                <div
                                                                    className=" w-52 ">
                                                                    <div className="mb-11">
                                                                        <ul className="list-none pl-0 divide-y divide-gray-800 divide-opacity-10">
                                                                            <li className="cursor-pointer relative py-2 px-3"
                                                                                onClick={async () => {
                                                                                    // startAddress();
                                                                                    abrirDirecciones();
                                                                                    close();
                                                                                }}>
                                                    <span className="flex items-center">
                                                    <FontAwesomeIcon icon={faMapLocationDot} className="text-white"/>
                                                        <span className="text-white font-normal block truncate ml-2">Mis direcciones</span>
                                                    </span>
                                                                            </li>
                                                                            <li className="cursor-pointer relative py-2 px-3 ">
                                                                                <Link
                                                                                    onClick={() => navigate_menu('mis_pedidos')}>
                                                                        <span className="flex items-center">
                                                                        <FontAwesomeIcon icon={faBagShopping}
                                                                                         className="text-white"/>
                                                                            <span
                                                                                className="text-white font-normal block truncate ml-2">Mis pedidos</span>
                                                                        </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li className="cursor-pointer relative py-2 px-3">
                                                                                <Link
                                                                                    onClick={() => navigate_menu('lista_compras')}>
                                                                        <span className="flex items-center">
                                                                    <FontAwesomeIcon icon={faHeart}
                                                                                     className="text-white"/>
                                                                        <span
                                                                            className="text-white font-normal block truncate ml-2">Mis listas
                                                                        de compras</span>
                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li className="cursor-pointer relative py-2 px-3 ">
                                                                                <Link
                                                                                    onClick={() => navigate_menu('cuenta')}>
                                                                    <span className="flex items-center">
                                                                    <FontAwesomeIcon icon={faUser}
                                                                                     className="text-white"/>
                                                                        <span
                                                                            className="text-white font-normal block truncate ml-2">Mi cuenta</span>
                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li className="cursor-pointer relative py-2 px-3">
                                                                                <Link
                                                                                    onClick={() => navigate_menu('mis_tarjetas')}>
                                                                        <span className="flex items-center">
                                                                    <FontAwesomeIcon icon={faCreditCard}
                                                                                     className="text-white"/>
                                                                        <span
                                                                            className="text-white font-normal block truncate ml-2">Mis
                                                                        tarjetas</span>
                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li className="cursor-pointer relative py-2 px-3 ">
                                                                                {verifyUserAutenticate()?(
                                                                                    <span className="flex items-center " onClick={() => logout()}>
                                                                        <FontAwesomeIcon icon={faRightFromBracket}
                                                                                         className="text-white"/>
                                                                        <span className="text-white font-normal block truncate ml-2">
                                                                            Cerrar sesión </span>
                                                                    </span>):
                                                                                    (<span className="flex items-center " onClick={() => iniciar_session()}>
                                                                        <FontAwesomeIcon icon={faRightFromBracket}
                                                                                         className="text-white"/>
                                                                        <span className="text-white font-normal block truncate ml-2">
                                                                            Iniciar sesión </span>
                                                                    </span>)}

                                                                            </li>
                                                                        </ul>
                                                                    </div>

                                                                    <div className="my-2">
                                                                        <Link
                                                                            to={'https://api.whatsapp.com/send?phone=' + numerocel + '&text=Hola!'}
                                                                            target="_blank">
                                                                            <ButtonIcon
                                                                                icon={faHeadset}
                                                                                label='Atención al cliente'
                                                                                className={'bg-hiperblue text-white px-1'}
                                                                                onClick={() => {
                                                                                }}
                                                                            />
                                                                        </Link>
                                                                    </div>

                                                                </div>
                                                            )}
                                                        </Popover.Panel>


                                                    </Transition>
                                                </>
                                            )}
                                        </Popover>
                                    </div>
                                </div>
                                {/*end desktop   */}

                            </div>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="duration-200 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel focus
                                           className="absolute z-30 top-9 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                                <div
                                    className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-hiperorange divide-y-2 divide-gray-50">
                                    <div className=" w-100 mt-3 ">
                                        <div className="mb-11">

                                            <ul className="list-none pl-0 divide-y divide-gray-800 divide-opacity-10">

                                                {/* dispositivo_movil */}
                                                <li className="cursor-pointer relative py-2 px-3"
                                                    onClick={() => abrirDirecciones()}
                                                    
                                                >
                                            <span className="flex items-center">
                                            <FontAwesomeIcon icon={faMapLocationDot}
                                                             className="text-white"/>
                                                <span
                                                    className="text-white font-normal block truncate ml-2">Mis direcciones</span>
                                            </span>
                                                </li>
                                                <li className="cursor-pointer relative py-2 px-3 ">
                                                    <Link onClick={() => navigate_menu('mis_pedidos')}>
                                                <span className="flex items-center">
                                                    <FontAwesomeIcon icon={faBagShopping} className="text-white"/>
                                                    <span className="text-white font-normal block truncate ml-2">Mis pedidos</span>
                                                </span>
                                                    </Link>
                                                </li>
                                                <li className="cursor-pointer relative py-2 px-3">
                                                    <Link onClick={() => navigate_menu('lista_compras')}>
                                                <span className="flex items-center">
                                                    <FontAwesomeIcon icon={faHeart} className="text-white"/>
                                                    <span
                                                        className="text-white font-normal block truncate ml-2">Mis listas de compras</span>
                                                </span>
                                                    </Link>
                                                </li>
                                                <li className="cursor-pointer relative py-2 px-3 ">
                                                    <Link onClick={() => navigate_menu('cuenta')}>
                                                <span className="flex items-center">
                                                    <FontAwesomeIcon icon={faUser} className="text-white"/>
                                                    <span className="text-white font-normal block truncate ml-2">Mi cuenta</span>
                                                </span>
                                                    </Link>
                                                </li>
                                                <li className="cursor-pointer relative py-2 px-3">
                                                    <Link
                                                        to={'https://api.whatsapp.com/send?phone=' + numerocel + '&text=Hola!'}
                                                        target="_blank">
                                                <span className="flex items-center">
                                                    <FontAwesomeIcon icon={faWhatsapp} className="text-white"/>
                                                    <span className="text-white font-normal block truncate ml-2">Ayuda en línea </span>
                                                </span>
                                                    </Link>

                                                </li>
                                                <li className="cursor-pointer relative py-2 px-3">
                                                    <Link onClick={() => navigate_menu('mis_tarjetas')}>
                                                <span className="flex items-center">
                                                    <FontAwesomeIcon icon={faCreditCard} className="text-white"/>
                                                    <span className="text-white font-normal block truncate ml-2">Mis tarjetas</span>
                                                </span>
                                                    </Link>
                                                </li>
                                                <li className="cursor-pointer relative py-2 px-3 ">
                                            <span className="flex items-center" onClick={() => logout()}>
                                                <FontAwesomeIcon icon={faRightFromBracket} className="text-white"/>
                                                <span className="text-white font-normal block truncate ml-2">Cerrar sesión</span>
                                            </span>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>

                )}
            </Popover>

            <Modal
                isOpen={modalIsOpen}
                appElement={document.getElementById('root')}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                key={"sdfsdg"}
            >
                <Sucursal onCloseModal={closeModal}/>
            </Modal>

            <Modal
                isOpen={modalAddress}
                ariaHideApp={false}
                onAfterOpen={afterOpenModal}
                onRequestClose={endAddress}
                style={customStyles}
                contentLabel="address"
            >
                <Direccion close_modal={endAddress}/>
            </Modal>
            <Modal
                isOpen={modalCarrito}
                ariaHideApp={false}
                onAfterOpen={afterOpenModal}
                onRequestClose={cerraModal}
                style={customStyles2}
                contentLabel="carrito"
                id="theIdCart"
            >
                <MiCarrito cerrarModal={endCarrito} cerrarModalAnonimo={endCarritoAnonimo}/>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    carrito: state.Carrito.carrito,
    cambio_carrito: state.Carrito.cambio_carrito,
    respuesta_carrito_anonimo: state.Carrito.respuesta_carrito_anonimo,
    respuesta_carrito_user: state.Carrito.respuesta_carrito_user,
    list_carrito: state.Carrito.list_carrito,
    direccion: state.Location.direccion,
    categorias: state.Home.categorias,
    category: state.Home.category,
    profile: state.Profile.profile,
    url_navigation: state.UrlNavegacion.url_navigation,
    show_alert: state.Swalerts.show_alert,
    redirect: state.Swalerts.redirect,
    cambio_detalle_carrito: state.Carrito.cambio_detalle_carrito,
    
})

export default connect(mapStateToProps, {
    store_carrito_data,
    update_carrito,
    limpiar_perfil,
    limpiar_auth,
    limpiar_direccion,
    limpiar_sucursal,
    limpiar_carrito,
    clear_pedidos,
    cleadSwAlert,
    limpiar_estado_anonimo,
    limpiar_data_lista_compra,
    get_ubicacion,
    get_lista_carrito_compras,
    get_carrito_por_sucursal
})(Navbar)

