import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation} from "swiper";
import Slider1 from "../../assets/images/home/Slider1.png";
import background_white from "../../assets/images/home/fondowhite.jpg";
import {Link,useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import imgsec1 from "../../assets/images/home/imgsec1.png";
import ItemProducto from "../../widgets/item_producto";

const containerStyle2 = {
    backgroundImage:
        `url(${background_white})`,
    width: "100%",
};

const breakpoints = {

    300: {
        slidesPerView: 2,
        spaceBetween: 1,
        slidesPerGroup: 2,
    },

    400: {
        slidesPerView: 2,
        spaceBetween: 1,
        slidesPerGroup: 2,
    },

    640: {
        slidesPerView: 3,
        spaceBetween: 10,
        slidesPerGroup: 3,
    },
    768: {
        slidesPerView: 4,
        spaceBetween: 10,
        slidesPerGroup: 4,
    },

    992: {
        slidesPerView: 5,
        spaceBetween: 10,
        slidesPerGroup: 5,
    },
    1200: {
        slidesPerView: 6,
        spaceBetween: 10,
        slidesPerGroup: 6,
    },
    1400: {
        slidesPerView: 7,
        spaceBetween: 10,
        slidesPerGroup: 7,
    },
};

function CarouselProducto({descripcion, Items, ps_seccion, ps_IdSucursal}) {
    const [productos, guardarProductos] = useState([]);
    const { ciudadp, sucursalp } = useParams();

    useEffect(() => {
        guardarProductos(Items.Productos ? Items.Productos : Items);
    }, []);
    return (
        <>
            <div className="mb-10">
                <div className="flex justify-between">
                    {
                        ps_seccion !== undefined && ps_seccion.Api !== null ?
                            <Link to={`/${(ciudadp+'/'+sucursalp)}/seccion/${ps_seccion.IdSeccion}`}>
                                <h3 className="text-hiperblue text-xl">{descripcion}</h3>
                            </Link>
                            :
                            <h3 className="text-hiperblue text-xl">{descripcion}</h3>
                    }


                    {ps_seccion !== undefined && ps_seccion.Api !== null &&
                        <Link to={`/${(ciudadp+'/'+sucursalp)}/seccion/${ps_seccion.IdSeccion}`}
                              className="text-center  text-white">
                          <span className="flex items-center style_icon_rigth_header text-hiperorange">
                              Ver más
                              <FontAwesomeIcon
                                  icon={faChevronRight}
                                  style={{height: "20px", paddingRight: "20px", paddingLeft: "10px"}}/>
                          </span>
                        </Link>
                    }
                </div>
                <div className=" w-100">
                    <div className=" h-auto w-[100%] ">

                        <Swiper
                            modules={[Navigation, Pagination]}
                            breakpoints={breakpoints}
                            slidesPerView={1}
                            navigation={true}
                            pagination={false}
                            scrollbar={{ draggable: true }}
                            className="mySwiper"
                            style={{"--swiper-navigation-color": "#32338E",
                            "--swiper-navigation-size": "30px"
                            // marginLeft:'1rem'
                        }}

                        >
                            {
                                productos &&
                                productos !== null &&
                                productos !== undefined &&
                                productos.map((producto, index) =>
                                    <SwiperSlide key={index} className=" mySwiper z-10 flex justify-center" >
                                        <div className="relative pb-4 pt-2">
                                            <ItemProducto key={index} producto={producto} ps_IdSucursal={ps_IdSucursal}/>   
                                        </div>
                                        
                                    </SwiperSlide>
                                )
                            }
                        </Swiper>
                    </div>
                </div>


            </div>
        </>
    );
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(CarouselProducto)