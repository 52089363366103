import React, {useEffect,useState} from 'react';
import logo from '../../../../assets/images/login/logo.png';
import background from '../../../../assets/images/login/Background.jpg';
import texture from '../../../../assets/images/login/texture_complete.jpg';
import {Link} from 'react-router-dom';
import '../../../../assets/fontawesome';
import {faFacebook, faGoogle} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage,faMobileAndroidAlt} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import '../../../../assets/styles/mystyle.scss'

import "../../../../assets/styles/globalColorPrincipal.css";
import "../../../../assets/styles/stylePageGlobals.scss";
import PhoneInput from "react-phone-number-input";
import {connect} from "react-redux";
import {get_anonimo_token,codigo_acceso,get_token,get_prefijos} from "../../../../redux/actions/auth";
import {useNavigate} from "react-router-dom";
import FacebookLogin from 'react-facebook-login';
import {FacebookLoginButton} from 'react-social-login-buttons';
import {LoginSocialFacebook} from 'reactjs-social-login';
import { GoogleOAuthProvider  } from '@react-oauth/google';
import { GoogleLogin } from 'react-google-login';
import {info_session} from "../../../../redux/actions/profile";
import {useForm} from "react-hook-form";
import ButtonIcon from "../../../../widgets/button_icon";
import {Circles} from "react-loader-spinner";

const containerStyle = {
    backgroundImage:
        `url(${background})`,
    width: "100%",
    height: "92.5vh",
};
const containerStyle2 = {
    backgroundImage:
        `url(${texture})`,
    width: "70%",
    borderRadius:'20px'

};


const Registro_user = ({array_prefijo, peticion,loading, sendAcceso, get_prefijos,codigo_acceso, profile,datoLogin,get_token,access_token,ps_posicion}) => {
    const navigate = useNavigate();
    const [value, setValue] = useState("+591");
    const [paises, setPaises] = useState([]);
    const [canalEnvio, setCanalEnvio] = useState([]);
    const [accountCreated, setAccountCreated] = useState(false);
    const [formData, setFormData] = useState({
        telefono: '',
        prefijo: '',
        TipoEnvioAcceso: '',
    })

    const {
        telefono,
        prefijo,
        TipoEnvioAcceso
    } = formData;

    //   const [setTipoEnvioAcceso] = useState(1);

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
    const {register, handleSubmit, formState: {errors}} = useForm();

    useEffect(() => {
        if(array_prefijo.length===0){
            console.log("getprefijos",array_prefijo);
            get_prefijos();    
        }
        
    }, [array_prefijo])
    useEffect(() => {
        console.log("arraypref",array_prefijo);
        if (array_prefijo.length > 0) {
            let countries = array_prefijo.map((p) => {
                return p.ISO2;
            })
            let seleccionado = array_prefijo.find(pre => pre.NumeroPrefijo === 591);
            setCanalEnvio(seleccionado.IdCanalesEnvio);
            setPaises(countries);
        }
    }, [array_prefijo])
    useEffect(() => {
        console.log("valu",value);
        console.log("valu",array_prefijo);
        if (value && array_prefijo.length > 0) {
            let numero = value.replace(/[^0-9]+/g, "");
            let seleccionado = array_prefijo.find(pre => Number(pre.NumeroPrefijo) === Number(numero));
            console.log("seleccionaod",seleccionado);
            if(seleccionado !== undefined){
                setCanalEnvio(seleccionado.IdCanalesEnvio);
            }
            
        }
    }, [value])

    useEffect(() => {
      if(sendAcceso){
        ps_posicion(2);
      }

    }, [sendAcceso]);

    // useEffect(()=>{
    //     let inp = document.getElementsByClassName("PhoneInputInput");
    //     if(inp){
    //         inp.disabled = true;
    //     }

    // })


    function is_whatsapp(){
        return canalEnvio.find(c => c === 1);
    }
    function is_sms(){
        return canalEnvio.find(c => c === 2);
    }


    const messages = {
        required: "Este campo es obligatorio",
        minLength: "No puede ingresar menor caracteres de lo establecido",
        maxLength: "No puede ingresar más caracteres en este campo",
        espacioBlanco: 'No es permitido espacios en blanco',
        requiredColor: 'Debe seleccionar un color',
        aceptaNumeros: 'Solo se acepta números en este campo',
        aceptaLetra: 'Solo se acepta letras en este campo'
    };
    const patterns = {
        espacioBlanco: /^(?!\s)/,
        aceptaNumeros: /^[0-9,-]+$/,
        aceptaLetra: /^[A-Za-z\s]+$/,
    };

    const onSubmit = async (data) => {
        //   setFormData(data);
        //   console.log("datos form ",data);
        console.log("login reg",data);
        formData.telefono = data.telefono;
        if (typeof (value) == 'undefined' || value === '') {
            formData.prefijo = 591;
        } else {
            formData.prefijo = value.replace(/[^0-9]+/g, "");
        }
        
        await codigo_acceso(data.telefono, formData.prefijo, formData.TipoEnvioAcceso)
        // setAccountCreated(true);
    }
    const tipoWhatsapp = (e) => {
        formData.TipoEnvioAcceso = 1;
    };
    const tipoSms = (e) => {
        formData.TipoEnvioAcceso = 2;
    };
    const go_ubicacion = () => {
        navigate("/ubicacion");
    };

   return (
        <>
            <div  className="relative  h-auto flex justify-center content_modal_registro" style={{minHeight:'40rem'}}>
               
                <div className="hiper-color absolute bottom-0 w-full" style={containerStyle2}>
                    <div className="h-[auto] max-h-full p-4">
                    <h1 className="text-4xl font-inter font-normal text-center text-white my-4">Ingresar</h1>
                        <div className='flex justify-center mb-1'>
                                <Circles
                                    color="#FFFFFF"
                                    width={35}
                                    height={35}
                                    ariaLabel="circles-loading"
                                    visible={peticion}
                                />
                            </div>
                        <div className="content_info text-center bg-white px-4 mx-4 py-1 mb-2" style={{borderRadius:'10px'}}>
                            <p className='text-gray-400'>Para tener una mejor experiencia y poder utilizar todas las funcionalidades de la
                                aplicación verifica tu número de teléfono
                            </p>
                        </div>
                           
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="flex justify-center m-0 pb-4">
                                <div className="box-input">
                                    <div className='centenido_form'>
                                        <label htmlFor="phone" className="block text-sm font-medium text-white">
                                            <FontAwesomeIcon icon={faMobileAndroidAlt} style={{height: "20px"}}/> Con tu
                                            número de celular
                                        </label>
                                        <div className="mt-1">
                                        <div className='w-full flex gap-1'>
                                                {
                                                    paises.length > 0 &&
                                                    <div className='codigo' style={{width:'30%'}}>
                                                        <PhoneInput
                                                            countries={paises}
                                                            className=' rounded-md '
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            defaultCountry="BO"
                                                            value={value}
                                                            onChange={setValue}
                                                            rules={{ required: true }}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                }

                                                <div className='input_tel'style={{width:'70%'}}>

                                                    <input
                                                        name="telefono"
                                                        // className='shadow-sm appearance-none border_primario rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                                        className=' w-full h-100 focus:outline-none rounded-md'
                                                        style={{padding:'0px 8px'}}
                                                        type="text"
                                                        autoComplete='false'
                                                        {...register("telefono", {
                                                            required: {
                                                                value: true,
                                                                message: messages.required,
                                                            },
                                                            minLength: {
                                                                value: 8,
                                                                message: messages.minLength
                                                            },
                                                            maxLength: {
                                                                value: 8,
                                                                message: messages.maxLength
                                                            },
                                                            pattern: {
                                                                value: patterns.espacioBlanco,
                                                                message: messages.espacioBlanco
                                                            },
                                                            pattern: {
                                                                value: patterns.aceptaNumeros,
                                                                message: messages.aceptaNumeros
                                                            }
                                                        })}

                                                        // {...register('telefono',{required:true})} 
                                                        // className="appearance-none block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"

                                                    />
                                                    {errors.telefono && <small
                                                        className="text-red-600">{errors.telefono.message}</small>}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 mt-2 text-white">
                                        {
                                            canalEnvio.length > 0 &&
                                            <>
                                                {
                                                    is_whatsapp() &&
                                                    <ButtonIcon
                                                        type='submit'
                                                        icon={faWhatsapp}
                                                        label='Recibir código por WhatsApp'
                                                        principal={false}
                                                        onClick={(e) => tipoWhatsapp()}
                                                        className={'text-[#DC842D] bg-white'}
                                                    />
                                                }
                                                {is_sms() &&
                                                    <ButtonIcon
                                                        type='submit'
                                                        icon={faMessage}
                                                        principal={false}
                                                        label='Recibir código por SMS'
                                                        onClick={() => tipoSms()}
                                                        className={'text-[#DC842D] bg-white'}
                                                    />
                                                }

                                            </>
                                        }

                                        
                                    </div>
                                </div>
                            </form>
                    </div>
                </div>
                
            </div>
        </>
    );
};
const mapStateToProps = state => ({
    loading: state.Auth.loading,
    datoLogin: state.Auth.datoLogin,
    sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
    authenticated: state.Auth.authenticated,
    profile: state.Profile.profile,
    access_token: state.Auth.access_token,
    // isAuthenticated: state.Auth.isAuthenticated
    peticion: state.Peticion.peticion,
    array_prefijo: state.Auth.array_prefijo,
    sendAcceso: state.Auth.sendAcceso,
})

export default connect(mapStateToProps, {
    get_prefijos,codigo_acceso,get_token,info_session
})(Registro_user)

// export default Login;

