import React,{useEffect,useState} from "react";
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useNavigate } from "react-router-dom";

export default function BarcodeRead() {
  const [data, setData] = useState("");

  useEffect(()=>{
    console.log('informacion de iamgen',data);
    // setData('ok');
    buscarProductoBarcode(data);
  },[data]);

  const navigate = useNavigate();
    
  const buscarProductoBarcode = (filtro)=>{
    console.log("fil",filtro);
      if(filtro!=="" && filtro.trim()!==""){
        navigate("/busqueda_producto_codigo/"+filtro.trim());
      }
      
  }
// comentado temporal por motivo de incompatibilidad 
  

  return (
    <>
      {/* <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) setData(result.text);
          else setData("");
        }}
      /> */}
      <p>product code:{data}</p>
    </>
  );
}