import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import Indice from "./compra_rapida/indice_compra_rapida";
import Entregacomprarapida from "../../containers/cart/EntregaCompraRapida";
import Formapagocomprarapida from "../../containers/cart/FormaPagoCompraRapida";
import "../../assets/styles/responsive.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useNavigate,useParams} from "react-router-dom";
import useScreenSize from "../../widgets/useScreenSize";
import classNames from "classnames";

function Compra_rapida({cerrarModal, productos, importeTotal,importeAhorrodo}) {
    const {width, height} = useScreenSize();
    const [productosCarrito, guardarProductosCarrito] = useState([]);
    const [data, setData] = useState({});
    const [posicion, guardarPosicion] = useState(1);
    const [is_carrito, guardarIsCarrito] = useState(true);
    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();
    useEffect(() => {
        let aux_productos = productos.filter((prod) => prod.Vigente);
        guardarProductosCarrito(aux_productos)
        document.body.style.overflow = 'hidden';
        return ()=>{
            document.body.style.overflow = 'unset';
        }
    }, []);

    useEffect(() => {
        console.log("4.1", data);
    }, [data]);

    function actualizar_posicion(numero) {
        guardarPosicion(numero);
    }

    function actualizar_data(data) {
        setData(data);
        guardarPosicion(2);
    }

    function exit() {
        cerrarModal()
    }

    function finalizado_exit() {
        guardarIsCarrito(false);
    }
    
    const ir_inicio = () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (url_nav) {
            navigate("/" + url_nav.url_parameters);
        } else {
            navigate("/");
        }
    }
    async function navigate_menu(url_menu) {
        let param = await get_url();
        navigate(param + '/' + url_menu);
    }

    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/" + ciudadp + "/" + sucursalp);
        } else if (url_nav) {
            return ("/" + url_nav.url_parameters);
        }
    }

    return (
        <>
            {
                is_carrito ?
                    <div className="w-full overflow-y-auto" style={{height: '100vh'}}>
                        <div className='container'>
                            <div className={classNames("h-screen", "flex", "contenedor_flex", "gap-2", "mx-0", "min-h-738",
                                {
                                    "justify-center": width > 768
                                })}>
                                <div className="hidden md:block contenedor_mobile md:w-[18%]">
                                    <Indice
                                        posicion={posicion}
                                    ></Indice>
                                </div>
                                {posicion === 1 &&
                                    <Entregacomprarapida exit={exit}
                                                         key={posicion}
                                                         width={width}
                                                         height={height}
                                                         mt_next={actualizar_data}
                                                         productos={productosCarrito}
                                                         importeTotal={importeTotal}
                                                         importeAhorrodo={importeAhorrodo}
                                    ></Entregacomprarapida>
                                }
                                {posicion === 2 &&
                                    <Formapagocomprarapida mt_indice={actualizar_posicion}
                                                           data={data}
                                                           width={width}
                                                           height={height}
                                                           exit={exit}
                                                           finalizado={finalizado_exit}
                                                           importeTotal={importeTotal}
                                                           importeAhorrodo={importeAhorrodo}
                                                           
                                                           ></Formapagocomprarapida>
                                }

                            </div>
                        </div>
                    </div>
                    :
                    <div className="flex w-full justify-center items-center mt-12">
                    <div className="w-full max-w-xl ">
                        <div className="card border-0 rounded-xl overflow-hidden">
                            <div className="card-body  bg-hiperorange d-flex justify-center">
                                <div className='py-5'>
                                    <div className=" text-center">
                                        <FontAwesomeIcon icon={faLocationDot} className="text-white text-8xl"/>
                                        <div>
                                            <p className="text-white text-2xl">Se realizó su pedido <br/> correctamente</p>
                                        </div>
                                    </div>
                                    <div className="grid grid-rows-2 gap-3 text-center">

                                        <button
                                            className="h-full w-full rounded-[7px] py-2 px-3 border_white text-white focus:outline-none focus:shadow-outline"
                                            onClick={() => ir_inicio()}>
                                            Seguir comprando
                                        </button>

                                        <Link onClick={()=>navigate_menu('mis_pedidos')}>
                                            <button
                                                className="h-full w-full rounded-[7px] py-2 px-3 border_white text-white focus:outline-none focus:shadow-outline">Detalle del pedido
                                            </button>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    );
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, {})(Compra_rapida)