import Layout from "../hocs/Layout";
import React, {useState,useEffect}from "react";
import ItemCarrito from "../widgets/item_carrito";
import {faCamera, faLocationDot, faSearch, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link,useParams } from "react-router-dom";
import ItemProducto from "../widgets/item_producto";
// import {useParams} from 'react-router'
import {connect} from "react-redux";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import {get_categorias} from "../redux/actions/home";
import {get_producto,get_all_producto} from "../redux/actions/producto"
import {get_filtro_productos_categoria} from "../redux/actions/producto"
import BranchOffice from "../redux/reducers/branch_office";
import Categoria from "../components/home/Categoria";
import BackToPage from "../components/BackPage/BackToPage";
import {Circles} from "react-loader-spinner";
import { color } from "tailwindcss/lib/util/dataTypes";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import {set_all_prod_category} from "../redux/actions/home";
// const style = {
//   height: 30,
//   border: "1px solid green",
//   margin: 6,
//   padding: 8,
//   display:'flex',
//   overflow:'auto'
// };



let page=0;
const HomeCategoria = ({get_categorias,categorias,sucursal,
    get_producto,productos,subcategorias,category,subcategoria_id,loading,get_filtro_productos_categoria,productos_peticion,productosCategory,get_all_producto,set_all_prod_category}) => {
    // const params = useParams();
    // const SubcategoriaId = params.SubcategoriaId;
     const { subcategoriaId } = useParams();
     const navigate = useNavigate();
    // const [page,setPage]= useState(1);

    // let page=0;
    // const [SubcategoriaId, setSubcategoriaId]= useState();
    // setSubcategoriaId(params.SubcategoriaId);

    let IdSucursal = sucursal.IdSucursal;
    let IdMarket = sucursal.IdMarket;
    
    const [categoria, setCategoria]= useState({
        IdCategoria: '',
        Descripcion: '',
    });
    const [subcategoria, setSubcategoria]= useState({
        IdSubcategoria: '',
        Descripcion: '',
    });
    const [subcateid, setSubcateid]= useState(null);
    const [aux, setAux]= useState();
     const [subcategoriaIdAux, setSubcategoriaIdAux]= useState(useParams().subcategoriaId);

    
    const [subcategorias1 ,setSubcategorias1] = useState([]);
    const [subcategoriascopia ,setSubcategoriascopia] = useState([]);
    const [filtro ,setFiltro] = useState('');
    const [filtrosubcategoria ,setFiltrosubcategoria] = useState('');
    const [allcategory ,setAllcategory] = useState(productosCategory.todo);
   

    useEffect(() => {
        // if(subcategorias.length===0){
        // setSubcateId(subcategoria_id,SubcategoriaId);

      //  console.log("MOSTRAR TODO ???? ",allcategory);
        if( typeof(subcategoriaId)=='undefined'|| Object.keys(subcategoriaId).length === 0){
            setAllcategory(true);
         //   SubcategoriaId=productosCategory.IdSubcategoria;
           setSubcategoriaIdAux(productosCategory.IdSubcategoria);
      //     console.log("mostrar ",productosCategory.IdSubcategoria);
            obtenerDatosCategoria(productosCategory.IdSubcategoria);
          }else{
            setAllcategory(false);  
            obtenerDatosCategoria(subcategoriaId);
          } 

    console.log("EJEC 1 ",subcategoriaId);


    },[subcategoriaId]);

    useEffect(() => {
        console.log("productos xxx",productos);

    },[productos]);

    useEffect(() => {
      
    const subcategoria=    colocar_subcategoria_id();
        setSubcateid(subcategoria);
        setAux(subcategoria);
        console.log("EJEC  2 ",subcategoria_id);
     
    },[subcategoria_id]);

    useEffect(() => {
        // console.log("home4subcateLocal",subcateid);
        // console.log("home4subcateIdRela",aux);
     console.log("EJECT 3 ",subcateid);
     

     const subcategoria=    colocar_subcategoria_id();
  //   console.log("EFECT SUBCATEGORIA ",subcategoria);
        if(category!==null && category!=='' &&  typeof(subcateid)!=='undefined' && subcateid=== aux){
        //    console.log("llego por acuuuu2222 ",subcategoria);
            obtenerDatosProducto(subcategoria);
            
        }else{
          //    console.log("PAOOOO POR AQUII ",subcategoria);
            obtenerDatosProducto(subcategoria);
        }

       // console.log("ejec 3 SUBCATEGORIAS",subcategorias);
        //setSubcategorias1(subcategorias);
        setSubcategorias1(subcategorias);
        setSubcategoriascopia(subcategorias);
       
    },[subcateid]); //ctaeogry


      useEffect(() => {
      
        const subcategoria=    colocar_subcategoria_id();
        console.log("USE FECET SUSBCATEGORIAS ",subcategoria);
        obtenerDatosProducto(subcategoria);
        setSubcategorias1(subcategorias);
     
    },[subcategorias]);

    useEffect(() => {
       // console.log("este es el filtro*"+filtrosubcategoria+"*");
      //  console.log("sucateg   ",subcategorias1);
      //  console.log("sucateg copia  ",subcategoriascopia);
        setSubcategorias1(subcategoriascopia);
        const fil=filtrosubcategoria.split(' ').join('');
        //    console.log("este es el fill*"+fil+"*");
        
          if(fil !==""){
            const subcategorias_filtradas = subcategorias1.filter(item =>
                item.Descripcion.toLowerCase().includes(fil.toLowerCase())
              );
              setSubcategorias1(subcategorias_filtradas);
            console.log("este es el filtor ",subcategorias_filtradas);
          }
       
    },[filtrosubcategoria]);
    function colocar_subcategoria_id(){

         if( typeof(subcategoriaId)=='undefined'|| Object.keys(subcategoriaId).length === 0){
        
            return productosCategory.IdSubcategoria;
         } 
         return subcategoria_id;
    }
    function verifyCateSubcate(){
         if(typeof(category)=='undefined'||category==null){
            return false;
         }
        if(category.IdCategoria!==undefined && subcateid!==null){
            return true;
        }
        return false;
    }
    const obtenerDatosCategoria = async (subcatId) => {
        console.log("peticion categoria",subcatId)
       await get_categorias(IdSucursal, IdMarket, subcatId);
       page=0;
    }

  async  function cargar_productos(datos,page){
       // if(productosCategory.todo)
      // console.log("CARGOOOO  TOODOS LOS PRODUCTOS AAAAA",productosCategory);
        if(productosCategory.todo)
        {
          console.log("CARGOOOO  TOODOS LOS PRODUCTOS",datos,page);
          await get_all_producto(datos,page);
        }else{
          console.log("CARGO PRODCUTOS ",datos,page);
          await get_producto(datos,page);
        }
     
    }

    const obtenerDatosProducto = async (subcatId) => {
        // console.log("realiza peticion producto");
       console.log("obtenerDatosProducto ",category)
       if(verifyCateSubcate()){
            page= page+1;
         //   console.log("obtinene onloadxxxxxxxx",category);
         /*   let datos={
                IdMarket:IdMarket,
                IdLocatorio:91, //revisar_estatico
                IdCategoria:category.IdCategoria, //verify
                IdSubcategoria: Number(subcatId),
                texto_filtro:  filtro,
            }
            */
           
        console.log("obtenerDatosProductoz ",productosCategory.todo);
           // console.log("allcategory ",allcategory);
            // cargar_productos(datos,page);

             if(productosCategory.todo){
                let datos={
                    IdMarket:IdMarket,
                    IdLocatario:sucursal.IdSucursal, //revisar_estatico
                    categoria_id:category.IdCategoria, //verify IdCategoria
                    IdSubcategoria: Number(subcatId),
                    filtro_text:  filtro,
                }
                get_filtro_productos_categoria( datos);
             }else{
                let datos2={
                    IdMarket:IdMarket,
                    IdLocatorio:sucursal.IdSucursal, //revisar_estatico
                    IdCategoria:category.IdCategoria, //verify
                    IdSubcategoria: Number(subcatId),
                    texto_filtro:  filtro,
                }
                cargar_productos(datos2,page);
             } 
           
       }else{
        console.log("obtenerDatosProducto2 ",category.IdCategoria);
       }
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
       
    const obtenerProductos = async()=>{
        if(productos_peticion && verifyCateSubcate()){
            // setPage(page+1);
            page= page+1;
            console.log("obtenerProductos scroll",category);
            let datos={
                IdMarket:IdMarket,
                IdLocatorio:sucursal.IdSucursal, //revisar_estatico
                IdCategoria:category.IdCategoria, //verify
                IdSubcategoria: Number(subcateid),
                texto_filtro:  filtro,
            }
            await delay(1500);
           // await get_producto(datos,page);
           console.log("filtrandoe stos datos MMMM",datos);
           cargar_productos(datos,page);
            
        }else{
            console.log("no hay mas datos para mostrar");
        }
        
    }
    const obtenerProductosSelected = async(subcate_id)=>{
        // setPage(page+1);
        if(verifyCateSubcate()){
            page= page+1;
            console.log("obtiene selected v1",category);
            let datos={
                IdMarket:IdMarket,
                IdLocatorio:sucursal.IdSucursal, //revisar_estatico
                IdCategoria:category.IdCategoria, //verify
                IdSubcategoria: Number(subcate_id),
                texto_filtro:  filtro,
            }
            //await get_producto(datos,page);
            cargar_productos(datos,page);
        }

    }


    function change_seleccion_todo(subcat){
          if( productosCategory.todo ){
            set_all_prod_category({
                IdSubcategoria:subcat.IdSubcategoria,
                IdCategoria:subcat.IdCategoria,
                todo:false          
            });
          } else{
            set_all_prod_category({
                IdSubcategoria:subcat.IdSubcategoria,
                IdCategoria:subcat.IdCategoria,
                todo:true          
            });
          }  
       
    }
    function selectSubcategory(subcat){
        // let num_page=1;
       // console.log("MOSTRAR TODOS LOS PRODUCTOS ",allcategory);

           if(subcateid== subcat.IdSubcategoria ){
                console.log("ES LA MISMA OPCION SELECCIONADA ",productosCategory.todo );
                
                if( productosCategory.todo ){
                    set_all_prod_category({
                        IdSubcategoria:subcat.IdSubcategoria,
                        IdCategoria:subcat.IdCategoria,
                        todo:false          
                    });
                    navigate(`/categoria/1/${subcat.IdSubcategoria}`);
                  } else{
                    set_all_prod_category({
                        IdSubcategoria:subcat.IdSubcategoria,
                        IdCategoria:subcat.IdCategoria,
                        todo:true          
                    });
                    navigate(`/categoria`);
                  }  

                
             
           }else{
               console.log("ES OTRA OPCION");
               set_all_prod_category({
                IdSubcategoria:subcat.IdSubcategoria,
                IdCategoria:subcat.IdCategoria,
                todo:false          
            });
                  
            window.scrollTo(0,0);
         // setSubcateid(subcat.IdSubcategoria);
           /*   setTimeout(() => {
                page=0;
                obtenerProductosSelected(subcat.IdSubcategoria);
    
            },1000);*/
            navigate(`/categoria/1/${subcat.IdSubcategoria}`);

           }

           console.log("MOSTRANDO",productosCategory.todo);

         
         
       

        // setSubcategoria({'IdSubcategoria':subcat.IdSubcategoria,'Descripcion':subcat.Descripcion});
        // obtenerDatos();
    }


/*
    function obtenerObject(){
        categorias.forEach(rubro => {
            rubro.Categorias.forEach(category =>{
                let cateAxu = category;
                category.SubCategorias.forEach(subcate =>{
                    if(Number(subcate.IdSubcategoria) === Number(subcategoriaId)){
                        setCategoria({'IdCategoria':cateAxu.IdCategoria,'Descripcion':cateAxu.Descripcion});
                        setSubcategoria({'IdSubcategoria':subcate.IdSubcategoria,'Descripcion':subcate.Descripcion});

                    }

                })

            })

        });
        cargarSubcategoria();
    }
     

    function cargarSubcategoria(){
        categorias.forEach(rubro => {
            rubro.Categorias.forEach(category =>{
                category.SubCategorias.forEach(subcate =>{
                    if(Number(subcate.IdCategoria) === Number(categoria.IdCategoria)){
                        if(siExiste(subcate.IdSubcategoria)){
                            subcategorias.push(subcate);
                            // setSubcategorias(...subcategorias ,subcate);

                        }
                        // subcategorias.push(subcate);
                    }

                })

            })

        });

    }


    function siExiste(Idsubcategoria){
        let proAux= subcategorias.find(item=>item.IdSubcategoria===Idsubcategoria);
        if (typeof(proAux)==='undefined' || proAux===null) {
            return true;
        }
        return false;
    }
        */
    const filtro_productos = ( e) => {
        const  categoria_id =category.IdCategoria;
        const filtro_text=filtro;
        const datos={
            categoria_id:categoria_id,
            filtro_text:filtro_text,
            IdMarket:sucursal.IdMarket,
            IdLocatario:sucursal.IdSucursal, 
            todo:productosCategory
        };

        console.log("ESTOS SON LOS DATOS PARA BUSCAR PRODUCTOS ",datos);

        console.log("ESTA FILTRANDO POR TODAS CATEGORIAS ",productosCategory.todo);
       if(productosCategory.todo){
            get_filtro_productos_categoria( datos);
         }else{
            const datos2={
                IdCategoria:categoria_id,
                texto_filtro:filtro_text,
                IdMarket:sucursal.IdMarket,
                IdLocatario:sucursal.IdSucursal, 
                todo:productosCategory
            };
            
            get_producto(datos2);
         } 
        

     const subcategoria=    colocar_subcategoria_id();
         obtenerDatosProducto(subcategoria);
       
      //  console.log(categoria_id,filtro_text);
   };
   const handleChange = (event) => {
    localStorage.setItem("filter_loc",event.target.value);
   setFiltro(event.target.value);
  };
   const handleChange2 = (event) => {
     setFiltrosubcategoria(event.target.value)
  };

  const verifyProductos= ()=>{
    if(productos !== undefined && productos!==null ){
        return true;
    }
    return false;
  }

  const obtenerCantidad = ()=> {
    if(verifyProductos){
        return productos.length;
    }
    return 0;
  }
  
  const enter_opcion = (event) => {
    if (event.key === 'Enter') {
        filtro_productos();
    }
  };
 

  function cambiar_estado_buscar_por_all_categoria(){
      if(allcategory){
        setAllcategory(false);
      }else{
        setAllcategory(true);
      }
  }
    return (
        <Layout>
            <Categoria/>
            <BackToPage ps_url_page={"/"} ps_contenerdor={'sinContenedor'}></BackToPage>  
            <main className="relative flex-grow">

                <div className="mx-auto max-w-[1920px] px-4">
                    <div name="grid" className="flex pb-16 pt-7">
                        <div className="sticky top-5 h-full w-80">
                            <div className="block">
                                <div className="max-h-full overflow-hidden rounded-xl border border-border-base shadow">
                                    <h3 className="text-center text-hiperorange">{category!=null && category.Descripcion}</h3>

                                    <div className="relative flex w-full flex-wrap items-stretch px-4">
                                        <input type="text"
                                               className="px-3 py-1.5 placeholder-slate-300 text-slate-600 relative rounded-2xl text-sm border border-hiperorange  outline-none w-full pr-10 "
                                               value={filtrosubcategoria}
                                               onChange={handleChange2}  
                                           
                                               /> 
                                        <span
                                            className=" absolute text-hiperorange right-5 pr-3 py-1.5">
                                            <FontAwesomeIcon icon={faSearch} onClick={ (e) => handleChange2( e)}/>  
                                          </span>
                                    </div>  

                                    <div className="mt-2" 
                                    // style={{maxHeight:'50vh',overflow:'scroll'}}
                                    >

                                        <ul className=" px-3 grid gap-1">

                                            {
                                                subcategorias1 &&
                                                subcategorias1 !== null &&
                                                subcategorias1 !== undefined &&
                                                subcategorias1.length>0 &&
                                                subcategorias1.map((subcategory, index) => 
                                                    <li key={index}  className="py-1.5 px-2 rounded-lg border text-[#32338E] cursor-pointer"
                                                    style={{backgroundColor:(Number(subcategory.IdSubcategoria)=== Number(subcateid) && productosCategory.todo==false )? 'var(--primario)': "transparent",
                                                    color:(Number(subcategory.IdSubcategoria)=== Number(subcateid) && productosCategory.todo==false )?'#fff':'var(--secundario)'
                                                    }}
                                                    onClick={() => selectSubcategory(subcategory)}
                                                    >{subcategory.Descripcion} </li>
                                                )

                                            }

                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="text-center my-3">
                                <div className="relative flex w-full flex-wrap items-stretch px-4">
                                    <input type="text"
                                        className="px-3 py-1.5 placeholder-slate-300 text-slate-600 relative rounded-2xl text-sm border border-hiperorange  outline-none w-full pr-10 "
                                        value={filtro}
                                        onChange={handleChange}
                                        onKeyDown={enter_opcion}
                                        />   
                                    <span
                                        className=" absolute text-hiperorange right-5 pr-3 py-1.5">
                                        <FontAwesomeIcon icon={faSearch} onClick={ (e) => filtro_productos( e)}/> 
                                    </span>
                                </div>                             
                            </div>
                            <div className="p-1">
                                                
                                    <div className="w-100 p-4">
                                            
                                    <InfiniteScroll
                                        dataLength={obtenerCantidad}
                                        next={obtenerProductos}
                                        hasMore={true}
                                        loader={<div className="flex justify-center w-100" style={{visibility:productos_peticion===true? 'visible':'hidden'}}>

                                            <Circles
                                            color="#32338E"
                                            width={50}
                                            height={50}
                                            ariaLabel="circles-loading"
                                            visible={true}
                                            />
                                            </div>}
                                        // loader={<h4 style={{visibility:productos_peticion===true? 'visible':'hidden'}}>
                                        //                                                 Cargando...
                                        // </h4>}
                                        style={{display:'flex', justifyContent:'center',flexWrap:'wrap',gap:'1rem', paddingBottom:'2rem'}}
                                        >
                                        {
                                            productos &&
                                            productos !== null &&
                                            productos !== undefined &&
                                            productos.map((producto, index) => (
                                                // <>
                                                    <ItemProducto key={index} producto={producto}/> 
                                                   
                                                    
                                                // </>
                                            
                                                
                                            ))
                                        }

                                    </InfiniteScroll>
                                            
                                    </div>
                            </div>
                        </div>

                    </div>

                </div>


            </main>
        </Layout>
    );
};
const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    categorias: state.Home.categorias,
    subcategorias: state.Home.subcategorias,
    category: state.Home.category,
    subcategoria_id: state.Home.subcategoria_id,
    
    loading: state.Producto.loading,
    productos: state.Producto.productos,
    productos_peticion:state.Producto.productos_peticion,
    productosCategory:state.Home.productosCategory,

})

export default connect(mapStateToProps, {
get_categorias,get_producto,get_filtro_productos_categoria,get_all_producto,set_all_prod_category
})(HomeCategoria)

// export default HomeCategoria;