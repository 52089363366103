

const Error404 = () => {
    return (

            <h4 className="text-black font-bold text-center">Error 404 </h4>
            

    );
};

 export default Error404;

