import Layout from "../../hocs/Layout";
import React,{useState,useEffect} from "react";
import ItemCarrito from "../../widgets/item_carrito";
import {Link} from "react-router-dom";
import ItemHorario from "../../widgets/item_Horario";
import {faLocationDot, faMessage, faRoad, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {connect} from "react-redux";
import {get_ubicacion} from "../../redux/actions/location"; 
import {save_datos_entrega_p} from "../../redux/actions/location"; 
import {info_session} from "../../redux/actions/profile"; 
import { data } from "autoprefixer";


const DatosEntrega = ({get_ubicacion,locations,info_session,profile,save_datos_entrega_p,direccion}) => {
    const navigate = useNavigate();
    const set_datos_entrega = (e) => {

   };
   const [token] = useState(localStorage.getItem('access_token'));
   const [formData, setFormData] = useState({
    telefono: '',
    direccion: '',
    ref_direccion: '',
    nota_entrega: '',
})

const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});
 const {register,formState:{errors},handleSubmit,setValue }=useForm(); 
// const {register,formState,handleSubmit}=useForm();
useEffect(() => {
    async function fetchData() {
        try {
          if( formData.telefono==''){
            const res=  await get_ubicacion();
            const res2= await info_session(token);
               if(existePerfil()){
                 console.log("esta la dir  ",locations);
                 setFormData({
                     telefono: profile.Telefono ,
                     direccion: '',
                     ref_direccion: '',
                     nota_entrega: '',
                 });
                
              

               }
             
               cargar_datos_localst();
          }
       
 
 
      
       
        } catch (e) {
            console.error(e);
        }
    };
    fetchData();
   
},[profile] );


const onSubmit = (data) => {
    //   setFormData(data);
        console.log("datos a guardar ",data);
     localStorage.setItem("datos_personales", JSON.stringify(data));
     var datos= {
        Telefono:profile.Telefono,
        Prefijo:profile.Prefijo,
        IdPersonaUbicacion: direccion.Id,
        InstruccionDelivery:data.refdireccion ,
        InstruccionEntrega:data.nota
      };
     save_datos_entrega_p(datos);
 
    navigate("/forma_pago");
     
   }

function cargar_datos_localst(){
    var data= JSON.parse(localStorage.getItem("datos_personales"));
     console.log("rescupera",data );
     setValue('refdireccion', data.refdireccion);
     setValue('nota', data.nota);
     
     
}
  function existePerfil(){
    return profile!=null;
  }

function hayubicacion(){
    return locations.length > 0;
}
    return (
        <Layout>

            <div className="flex justify-center items-center mt-12">
                <div className="">
                    <h2 className="text-center mb-4 text-hiperorange">Datos de entrega</h2>
                    <form className=" "  onSubmit={handleSubmit(onSubmit)}>
                    <div className="sm:w-100 lg:w-[33rem] border border-hiperorange shadow rounded-xl">
                        <div
                            className="bg-white shadow rounded-t-xl text-hiperblue mb-3 text-sm flex items-center h-[3rem] px-2">
                                <h6 className="mb-0">Datos Personales</h6>
                        </div>
                   
                        <div className="px-16">
                            <div className=" mt-2">
                            
                                    <div className="mb-3">
                                        <label className="block text-hiperorange text-sm font-normal mb-1" htmlFor="name">
                                            <FontAwesomeIcon icon={faUser}
                                                             className="mr-1"/>
                                            Movil de confirmación:
                                        </label>
                                        <input
                                            className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none
                                     focus:border-hiperorange focus:ring-1"
                                            id="name" type="number"  name="telefono"
                                            value={formData.telefono}
                                            disabled
                                            />

                                 
                                    </div>
                                   

                                    <div className="mb-3">
                                        <label className="block text-hiperorange text-sm font-normal mb-1" htmlFor="username">
                                            <FontAwesomeIcon icon={faLocationDot} className="mr-1"/>
                                            Seleccione dirección:
                                        </label>


                                        <select id="direccion" name="direccion" 
                                            
                                            {...register('direccion',{required:true})} 
                                        className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                                               <option value="0">Selccione una opcion </option>
                                                {
                                                   hayubicacion()?(
                                                       locations.map((item) => 
                                                   
                                                           <option value={item.Id}  >{item.Descripcion}</option>
                                                     
                                                   
                                                         )
                                                 
                                                   ):<></> 
                                                }
                                           
                                            
                                    </select>

                                       
                                           {errors.direccion?.type==='required' && <p style={{"color":"#920909"}}>El campo dirección es obligatorio*</p>}  
                                    </div>

                                    <div className="mb-3">
                                        <label className="block text-hiperorange text-sm font-normal mb-1" htmlFor="email">
                                            <FontAwesomeIcon icon={faRoad} className="mr-1"/>
                                            Referencia de la dirección:
                                        </label>
                                        <textarea
                                            className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="email" type="text" name="refdireccion"
                                            
                                             {...register('refdireccion',{required:true})} 
                                           
                                            />
                                             {errors.refdireccion?.type==='required' && <p style={{"color":"#920909"}}>El campo referencia es obligatorio*</p>}  
                                    </div>
                                    <div className="mb-3">
                                        <label className="block text-hiperorange text-sm font-normal mb-1" htmlFor="ci">
                                            <FontAwesomeIcon icon={faMessage} className="mr-1"/>
                                            Nota para la entrega:
                                        </label>
                                        <textarea
                                            className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            id="ci" type="text" name="nota"
                                           {...register('nota',{required:true})} 
                                            />
                                              {errors.nota?.type==='required' && <p style={{"color":"#920909"}}>El campo nota es obligatorio*</p>} 
                                    </div>
                            
                            </div>
                        </div>
                    </div>
                            <div className="card-footer d-flex justify-between pt-2 pb-5">
                                <Link to="/entrega">
                                    <button
                                        className="bg-hiperblue px-3 border text-white rounded-lg">
                                        Volver
                                    </button>
                                </Link>
                                {/* <Link to="/forma_pago">
                                    <button
                                        onClick={ (e) => set_datos_entrega( e)}
                                        type="submit"
                                        className="bg-green-500 px-3 border  text-white rounded-lg">Continuar
                                    </button>
                                </Link> */}
                                   <button
                                        onClick={ (e) => set_datos_entrega( e)}
                                        type="submit"
                                        className="bg-green-500 px-3 border  text-white rounded-lg">Continuar
                                    </button>
                            </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

 
const mapStateToProps = state => ({
    
    locations: state.Location.locations, 
    direccion: state.Location.direccion, 
    profile: state.Profile.profile, 

})

 
export default connect(mapStateToProps, {
    get_ubicacion,info_session,save_datos_entrega_p
})(DatosEntrega)