import React,{useEffect,useState} from "react";

import {connect} from "react-redux";
import {actualizar_lista_compra, store_lista_compra} from "../../../redux/actions/shoppingList";
import classNames from "classnames";
import ItemListaCompra from '../../../widgets/item_lista_compra_list';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose
} from "@fortawesome/free-solid-svg-icons";
import {Circles} from "react-loader-spinner";
import { useNavigate,useParams } from "react-router-dom";
import "../../../assets/styles/responsive.scss"
import ButtonClose from "../../../widgets/button_close";
import ButtonText from "../../../widgets/button_text";
const ContenidoCompartida =({info,opcion,ps_productos,actualizar_lista_compra,store_lista_compra
    ,formData,setFormData,ps_closeModal,ps_closeModalEstore,ps_lista_compra,ps_IdSucursal,loading
}) => {

    const {ciudadp, sucursalp} = useParams();
    const [listaSeledted, setListaSelected] = useState({
            Id: '',
            Descripcion: '',
            Color:'',
        })
    const navigate = useNavigate();

    useEffect(() => {
        

    },[opcion]);
    const customStyles = {
        width: '100%',
        minWidth:'18rem',

    };

    const listColor=[
        {id:1, nombreColor:'Azul', color:'#2E9BFF'},
        {id:3, nombreColor:'Rojo', color:'#F15353'},
        {id:2, nombreColor:'Amarillo', color:'#FCC12E'},
        {id:4, nombreColor:'Verde', color:'#53DD12'},
        {id:5, nombreColor:'Naranja', color:'#CB6E18'},
        {id:6, nombreColor:'Morado', color:'#826882'},
        {id:7, nombreColor:'Magenta', color:'#CE45E4'},
        {id:8, nombreColor:'Celeste', color:'#5CC4E4'},
        
    ];
    function handleChange(event) {
        // console.log("hello", event)
        console.log(event.target.value);
    }
    function verifyOpcion(){
        if(opcion ==='nuevo'){
            return true;
        }else{
            return false;
        }
    }
    function selectedLista(dato){
        console.log('lista seleccionado',dato);
        setListaSelected(dato);
        console.log(listaSeledted);
    }
    function delay(ms){
        return new Promise(resolve => setTimeout(resolve, ms))
    }

async function actualzarListaCompra(){
    // let productos=[];
    // for (let index = 0; index < ps_productos.length; index++) {
    //     const element = ps_productos[index];
    //     productos.push({
    //         IdProducto: element.IdProducto,
    //         Cantidad: element.Cantidad,
    //         IdSucursal: element.IdSucursal,
    //         Pesos: []
    //     })
    // }

    const datos = {
        "Id":listaSeledted.Id,
        "Descripcion" : listaSeledted.Descripcion,
        "Color" : listaSeledted.Color,
        // "Productos":productos,

    }
    console.log(datos);
    await actualizar_lista_compra(datos,ps_productos,ps_IdSucursal);
    await ps_closeModal();
    await delay(2000);
    // navigate("/lista_compras");
    navigate_menu('lista_compras')
    
}
async function navigate_menu(url_menu) {
    let param = await get_url();
    navigate(param + '/' + url_menu);
}

const get_url = async () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
        return ("/" + ciudadp + "/" + sucursalp);
    } else if (url_nav) {
        return ("/" + url_nav.url_parameters);
    }
}



    return (
        <>
            <div className="content_modal rounded-hiper_g overflow-hidden" >
                <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                    <h2 className='p-0 m-0 title_carrito_responsivo'>{info}</h2>
                    <div className="absolute right-2 ">
                        <ButtonClose className="text-white bg-transparent"  onClick={() => ps_closeModal()} />
                    </div>
                </div>
                <div className="contenedor_modificar p-3">

                    <div className="flex flex-col h-[20rem] overflow-y-auto gap-2 p-2">
                        {ps_lista_compra &&
                            ps_lista_compra !== null &&
                            ps_lista_compra !== undefined &&
                            ps_lista_compra.map((item_shopping_list)=>
                            <ItemListaCompra 
                                ps_shopping_list={item_shopping_list}
                                // ps_opcSelectedList={setSelectedList}
                                key={item_shopping_list.Id}
                                ps_opcSelectedList={selectedLista}
                                ps_selected={listaSeledted}
                            ></ItemListaCompra>)
                        }
                    </div>
                    <div>
                        <button 
                            className="flex justify-center items-center gap-1 bg-hiperblue hover:hiperblue_hover  px-4 py-2 rounded-lg  text-white w-full mt-3"
                            // type="submit"
                            onClick={actualzarListaCompra}
                            disabled={loading}
                            >
                            <Circles
                                color="#DC842D"
                                width={20}
                                height={20}
                                ariaLabel="circles-loading"
                                visible={loading}
                            /> 
                            Modificar lista
                        </button>

                    </div>

                </div> 

            </div>
            

        </>

    );
};
// export default Contenido;

const mapStateToProps = state => ({
    loading: state.Shopping_list.loading,
})

export default connect(mapStateToProps, {
    actualizar_lista_compra,store_lista_compra
})(ContenidoCompartida)