import Layout from "../hocs/Layout";
import React, {useState, useEffect} from "react";
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useNavigate, useParams} from "react-router-dom";
import ItemProducto from "../widgets/item_producto";
import {connect} from "react-redux";
import {get_homepage} from "../redux/actions/home";
import {get_producto_seccion, limpiar_array_producto} from "../redux/actions/producto"
import Categoria from "../components/home/Categoria";
import BackToPage from "../components/BackPage/BackToPage";
import InfiniteScroll from "react-infinite-scroll-component";
import {Circles} from "react-loader-spinner";
import '../../src/assets/styles/categoria.scss';
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import "../assets/styles/seccionResponsive.scss"

import Alert from "../components/Swalert";

import classNames from "classnames";

let page = 0;
const HomeSeccion = ({
                         get_homepage,
                         homes,
                         secciones,
                         sucursal,
                         oferta,
                         novedades,
                         mas_vendidos,
                         subcategoria_id,
                         tipo_entrega,
                         productos_seccion,
                         productos_peticion_seccion,
                         get_producto_seccion,limpiar_array_producto
                     }) => {


    const {SeccionId} = useParams();
    const navigate = useNavigate();
    const { ciudadp, sucursalp } = useParams();

    let IdSucursal = sucursal.IdSucursal;
    let IdMarket = sucursal.IdMarket;
    const [arraySeccion, setArraySeccion] = useState([]);
    const [seccion, setSeccion] = useState({
        IdSeccion: '',
        Descripcion: '',
        Api: '',
    });
    const [dataInfo, setDataInfo] = useState({});
    const breakpoints = {
        300: {
            slidesPerView: 2,
            spaceBetween: 2,
        },
        400: {
            slidesPerView: 2,
            spaceBetween: 2,
        },

        640: {
            slidesPerView: 3,
            spaceBetween: 2,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 5,
        },
        800: {
            slidesPerView: 4,
            spaceBetween: 5,
        },

        992: {
            slidesPerView: 4,
            spaceBetween: 5,
        },
        1200: {
            slidesPerView: 12,
            spaceBetween: 5,
        },

        1400: {
            slidesPerView: 12,
            spaceBetween: 5,
        },

    };
    useEffect(() => {
        page = 0;
        setDataInfo({});
        limpiar_array_producto();
    }, []);
    useEffect(() => {
        if (!homes) {
            get_homepage(IdSucursal, IdMarket, tipo_entrega.Id);
        } else {
            obtenerSeccion();
        }
    }, [homes]);


    useEffect(() => {
        if (SeccionId) {
            obtenerSeccion();
        }
    }, [SeccionId]);
    useEffect(() => {
        if (secciones.length>0){
            let aux_secciones = secciones.filter((sec) => tiene_producto(sec));
            setArraySeccion(aux_secciones);
        }
    }, [secciones]);
    useEffect(() => {
        if (arraySeccion.length>0){
            obtenerSeccion();
        }
    }, [arraySeccion]);


    function tiene_producto(sec){
        let resul = false;
        if (sec.ProductosOferta !== null) {
            if (sec.ProductosOferta.Productos.length>0) {
                return true;
            }
        }
        if (sec.ProductosTemporada !== null) {
            if (sec.ProductosTemporada.length>0) {
                return true;
            }
        }
        if (sec.ProductosMasVendidos !== null) {
            if (sec.ProductosMasVendidos.length>0) {
                return true;
            }
        }
        // if (sec.ProductosMasVendidosCliente !== null) {
        //     if (sec.ProductosMasVendidosCliente.length>0) {
        //         return true;
        //     }
        // }

        return resul;

    }
    useEffect(() => {
        if (seccion) {
            llenar_data();
        }
    }, [seccion]);
    useEffect(() => {
        if (Object.keys(dataInfo).length > 0 &&dataInfo.ApiSeccion !== '') {
            page = page + 1;
             get_producto_seccion(dataInfo,page);
        }
    }, [dataInfo]);



    async function obtenerSeccion() {
        if (arraySeccion.length > 0) {
            let result = arraySeccion.find(sec =>Number(sec.IdSeccion) === Number(SeccionId));
            await _setSeccion(result.IdSeccion, result.Descripcion, result.Api);
        }
    }

    async function _setSeccion(IdSeccion, Descripcion, api) {
        await setSeccion({IdSeccion: IdSeccion, Descripcion: Descripcion, Api: api})
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function llenar_data() {
        if (productos_peticion_seccion) {
            if (secciones.length > 0) {
                let datos = {
                    IdSucursal: IdSucursal,
                    Cantidad: 50,
                    ApiSeccion: seccion.Api,
                    SeccionId: seccion.IdSeccion,
                }
                await delay(1500);
                setDataInfo(datos);
            }
        }
    }

    function selectSeccion(section) {
        page = 0;
        limpiar_array_producto()
        navigate(`/${(ciudadp+'/'+sucursalp)}/seccion/${section.IdSeccion}`);
        
    }

    const obtenerCantidad = () => {
        return productos_seccion.length;
    }

    const subir = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <Layout>
            <Alert></Alert>
            <Categoria/>
            {/* <BackToPage ps_url_page={""} ps_contenerdor={'sinContenedor'}></BackToPage> */}
            <BackToPage ps_url_page={`/${(ciudadp+'/'+sucursalp)}`} ps_contenerdor={'sinContenedor'}></BackToPage>
            <main className="relative flex-grow">

                <div className="mx-auto max-w-[1920px] container_main">
                    <div className="w-full content_responsivo_movil px-2 relative z-0">
                    <h4 className="text-center text-hiperorange">{seccion.Descripcion}</h4>
                   <Swiper
                       breakpoints={breakpoints}
                       slidesPerView={1}
                       loop={false}
                       pagination={{
                           clickable: true,
                       }}
                       navigation={true}
                       modules={[Navigation]}
                       className="mySwiper  py-2 text-xs text-center font-light overflow-hidden"
                       style={{
                           "--swiper-navigation-color": "#32338E",
                           "--swiper-navigation-size": "20px",
                           "--swiper-navigation-weight": "900"
                       }}
                   >
                       {
                           arraySeccion &&
                           arraySeccion !== null &&
                           arraySeccion !== undefined &&
                           arraySeccion.map((seccion_item, index) => (
                               <SwiperSlide key={index} className={"alineado_seccion"}
                                            style={{alignSelf: 'center', display: 'flex', justifyContent: 'center'}}
                               >
                               <div className="relative">
                                   <div 
                                       className="py-1.5 px-2 rounded-lg border cursor-pointer"
                                       style={{
                                           backgroundColor: (Number(seccion_item.IdSeccion) === Number(seccion.IdSeccion)) ? 'var(--primario)' : "transparent",
                                           color: (Number(seccion_item.IdSeccion) === Number(seccion.IdSeccion)) ? '#FFFFFF' : 'var(--secundario)'
                                       }}
                                       onClick={() => selectSeccion(seccion_item)}
                                   >

                                       {seccion_item.Descripcion}
                                   </div>
                               </div>
                                       
                               </SwiperSlide>

                           ))
                       }
                   </Swiper>
                   
                    </div>

                    <div name="grid" className="flex pb-16 pt-7 container_seccion_main" style={{overflow: 'auto'}}>
                        <div className="sticky top-5 h-full   w-80 container_seccion content_responsivo_desktop">
                            <div className="block">
                                <div className="max-h-full overflow-hidden rounded-xl border border-border-base shadow">
                                    {/* <h3 className="text-center text-hiperorange">{category!=null && category.Descripcion}</h3> */}
                                    <h3 className="text-center text-hiperorange">Secciones</h3>
                                    <div className="mt-2">
                                        <ul className=" px-3 grid gap-1">
                                            {
                                                arraySeccion &&
                                                arraySeccion !== null &&
                                                arraySeccion !== undefined &&
                                                arraySeccion.length > 0 &&
                                                arraySeccion.map((seccion_item, index) =>
                                                    <li key={index}
                                                        className={classNames("py-1.5", "px-2", "rounded-lg", "cursor-pointer",
                                                            {
                                                                "border": (Number(seccion_item.IdSeccion) !== Number(seccion.IdSeccion))
                                                            })}
                                                        style={{
                                                            backgroundColor: Number(seccion_item.IdSeccion) === Number(seccion.IdSeccion) ? 'var(--primario)' : "transparent",
                                                            color: Number(seccion_item.IdSeccion) === Number(seccion.IdSeccion) ? '#FFFFFF' : "#32338E",

                                                        }}
                                                        onClick={() => selectSeccion(seccion_item)}
                                                    >{seccion_item.Descripcion}</li>
                                                )
                                            }
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="w-full container_productos">
                            <div className="w-100 p-2 ">

                                <InfiniteScroll
                                    dataLength={obtenerCantidad}
                                    next={llenar_data}
                                    hasMore={true}
                                    loader={
                                    productos_peticion_seccion?(
                                        <div className="flex justify-center w-100"
                                        style={{visibility: productos_peticion_seccion === true ? 'visible' : 'hidden'}}>

                                        <Circles
                                            color="#32338E"
                                            width={50}
                                            height={50}
                                            ariaLabel="circles-loading"
                                            visible={true}
                                        />
                                    </div>
                                    ):(
                                        <div className="flex w-full justify-center">
                                            {page===1?(
                                                <p className="text-gray-400">No hay resultados para este criterio de búsqueda en el supermercado.</p>
                                            ):(
                                                <p className="text-gray-400">No hay mas productos para mostrar</p>
                                            )
                                        }

                                        </div>
                                    )
                                    }
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexWrap: 'wrap',
                                        gap: '1rem',
                                        paddingBottom: '2rem'
                                    }}
                                >
                                      <div className="content_infinit" style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexWrap: 'wrap',
                                                // gap: '1rem',
                                                paddingBottom: '2rem'
                                            }}>
                                        {
                                            productos_seccion &&
                                            productos_seccion !== null &&
                                            productos_seccion !== undefined &&
                                            productos_seccion.map((producto, index) => (
                                                <ItemProducto key={index} producto={producto} ps_IdSucursal={sucursal.IdSucursal}/>
                                            ))
                                        }
                                    </div>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>

                </div>

                <button className="footer-button  bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
                        type="button" onClick={() => subir()}>
                    <FontAwesomeIcon icon={faArrowUp}/>
                </button>
            </main>
        </Layout>
    );
};

const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    homes: state.Home.homes,
    secciones: state.Home.secciones,
    oferta: state.Home.oferta,
    novedades: state.Home.novedades,
    mas_vendidos: state.Home.mas_vendidos,
    tipo_entrega: state.BranchOffice.tipo_entrega,
    direccion: state.Location.direccion,
    carritos: state.Carrito.carrito,
    producto_delete: state.Carrito.producto_delete,
    productos_peticion_seccion: state.Producto.productos_peticion_seccion,

    productos_seccion: state.Producto.productos_seccion
})

export default connect(mapStateToProps, {
    get_homepage, get_producto_seccion,limpiar_array_producto
})(HomeSeccion)
