import Layout from "../../hocs/Layout";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";
import {clear_pedido, getpedido} from "../../../src/redux/actions/pedido";
import ItemDetallePedido from "../../widgets/item_detalle_pedido";
import ButtonText from "../../widgets/button_text";
import classNames from "classnames";
import EstadoPedidoItem from "../../widgets/estadoPedidoItem";
import "../../assets/styles/detallePedido.scss";
import Alert from "../../components/Swalert";


const DetallePedido = ({getpedido, pedido, profile, clear_pedido}) => {
    const {id} = useParams();
    const [pedidoPendiente, setPedidoPendiente] = useState(false);

    useEffect(() => {
        if (Object.keys(pedido).length === 0) {
            getpedido(id);
        } else {
            let pedidoEstadoId = pedido.Estado.Id;
            if (pedidoEstadoId !== 8 && pedidoEstadoId !== 9) {
                setPedidoPendiente(true);
            }
        }
    }, [pedido]);

    useEffect(() => {
        return () => {
            clear_pedido();
        };
    }, []);


    function haypedido() {
        return (Object.keys(pedido).length !== 0) ? true : false;
    }

    function volverAtras() {
        window.history.back();
    }

    const actualizar_item = () => {
        setPedidoPendiente(false);
        getpedido(id);
    }

    function formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    function get_tipo_entrega(oracion) {
     let nuevacadena=oracion.toLowerCase();
       let sinPrimerLetra=nuevacadena.slice(1);
        console.log("sin primer ",sinPrimerLetra);
       let primerLetra=(oracion.charAt(0)).toUpperCase();
        console.log(" primer ",primerLetra);
  return primerLetra+sinPrimerLetra;
    }

    return (
        <Layout>
            <Alert></Alert>
            <div className="bg_image_page" style={{minHeight: '60rem', height: '60rem'}}>
                <div className="content_info pt-4">
                    <div className="container">
                        <div className="w-full pb-3  ">
                            <h3 className="title text_primario font-medium">Detalle de pedido  {haypedido() &&
                                <span className="text_primario  " style={{ textTransform:"lowercase" }}  > - { pedido.TipoEntrega.Descripcion}</span>

                            }</h3>


                        </div>

                        {
                            haypedido() &&
                            <div className="w-full contenedor_flex contenedor_altura">
                                <div
                                    className="md:w-[65%]  p-0  contenedor_mobile rounded-xl overflow-hidden border-gray-400 border-[0.1rem]"
                                    style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem' }}>
                                    <div className="">
                                        <div className="row mx-0 py-3  bg-hiperorange px-2">
                                            <div className="col-6 text-white data_sucursal_pdet">
                                                <div className="flex    mt-2   rounded-2 data_sucursal_header_pdet">
                                                    <p className="mb-0 text-white font-bold  " >Sucursal: {pedido.Sala.Descripcion}</p>
                                                </div>
                                                <p className="mb-0 ">Fecha de entrega: {pedido.FechaEntrega}</p>
                                                <p className="mb-0 ">Hora de entrega: {pedido.HoraAproxEntrega}</p>
                                            </div>

                                            <div className="col-6 text-white data_pedido_pdet">
                                                <div className="flex    mt-2 pr-3 rounded-2 data_pedido_header_pdet">
                                                    <p className="mb-0   text-white font-bold" >Nro de pedido # {id}</p>
                                                </div>
                                                <p className="mb-0 pr-3  data_pedido_parraf_pdet">Fecha de
                                                    solicitud: {pedido.FechaCreacion}</p>
                                            </div>
                                        </div>
                                        <div className="w-100 bg-white h-[auto]">
                                            <div className="w-100 h-[559px] container_scroll    overflow-auto    " >
                                                {
                                                    haypedido() &&
                                                    pedido.Productos.map((item, index) =>
                                                        <ItemDetallePedido key={item.IdProducto}
                                                                           UrlFoto={item.UrlFoto}
                                                                           nombreproducto={item.Descripcion}
                                                                           precio={item.Precio}
                                                                           moneda={item.Moneda}
                                                                           cantidad={item.Cantidad}
                                                                           subtotal={item.SubTotal}>
                                                        </ItemDetallePedido>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" md:w-[35%]   style_panelB ">

                                    <div className="mb-2 rounded-xl overflow-hidden border-gray-400 border-[0.1rem]"
                                         style={{
                                             boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                             borderRadius: '1.4rem'
                                         }}>
                                        {
                                            pedidoPendiente ?
                                                <div className="bg-hiperorange text-white py-1">
                                                    <p className="text-center mb-0">Pedido en curso  </p>
                                                </div>
                                                :
                                                <div className={classNames("text-white", "py-1",
                                                    {
                                                        "bg-hiperorange": pedido.Estado.Id === 8,
                                                        "bg-red-600": pedido.Estado.Id === 9,
                                                    })}>
                                                    <p className="text-center mb-0">{pedido.Estado.Descripcion}</p>
                                                </div>
                                        }

                                        {
                                            pedidoPendiente &&
                                            <EstadoPedidoItem
                                                key={pedido.NroPedido}
                                                pedido={pedido}
                                                profile={profile}
                                                actualizar_item={actualizar_item}
                                            >
                                            </EstadoPedidoItem>
                                        }

                                    </div>


                                    <div className="mb-2  rounded-xl overflow-hidden border-gray-400 border-[0.1rem]"
                                         style={{
                                             boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                             borderRadius: '1.4rem'
                                         }}>
                                        <div className="bg-hiperorange text-white py-1">
                                            <p className="text-center mb-0">Método de pago</p>
                                        </div>
                                        <div className="row px-3 bg-white pb-2  pt-1 flex justify-center">
                                            {
                                                pedido.PagoPedido.map((pago, key) =>
                                                    <div className="col-6" key={key}>
                                                        <div
                                                            className='w-100 h-100 bg-green-600 pt-2.5 rounded-sm border border-1'>
                                                            <div
                                                                className='flex flex-col justify-center text-center text-white'>
                                                                <div className='text-center bg-white text-black '>
                                                                    <span
                                                                        className="font-bold  uppercase text-sm break-words">{pago.MetodoPago}</span>
                                                                </div>
                                                            </div>
                                                            <div className="pb-1">
                                                                <div className="text-white pl-2">
                                                                    <span className="font-bold"
                                                                          style={{fontSize: '0.7rem'}}>IMPORTE {pago.MonedaIso} {pago.Importe}</span>
                                                                </div>
                                                                {pago.Cancelado &&
                                                                    <div className="h-6 bg-gray-400/50 text-center">
                                                                        <span
                                                                            className="text-red-600 font-bold">Cancelado</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>


                                    <div
                                        className="content_card_footer bg-white text-darck rounded-b-lg p-4 mb-4 px-4 rounded-xl "
                                        style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem'}}
                                    >
                                        <div className="flex flex-col ">
                                            <div
                                                className='flex p-1 justify-between mb-1 text-white bg-[#DC842D] rounded-md'>
                                                <span className='text-center m-0'>Total Parcial:</span>
                                                <span>Bs {formatPrice(pedido.TotalParcial)}</span>
                                            </div>
                                            <div
                                                className='flex p-1 justify-between bg-[#ACACAC] text-white rounded-md '>
                                                <span className='text-center m-0'>Costo de envío:</span>
                                                <span>Bs {formatPrice(pedido.CostoEntrega)}</span>
                                            </div>
                                            <div className='flex gap-12 mt-3 mb-3 justify-center text-[#32338E]'>
                                                <span className='m-0'>Total pagado:</span>
                                                <span>Bs. &nbsp; {formatPrice(pedido.ImporteTotal)}</span>
                                            </div>

                                        </div>
                                        <div className="flex flex-col justify-center  text-center text-white">
                                            <ButtonText
                                                label='Volver'
                                                principal={false}
                                                className="bg-[#32338E]"
                                                onClick={() => volverAtras()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </Layout>
    );
};

const mapStateToProps = state => ({
    pedido: state.Pedido.pedido,
    profile: state.Profile.profile
})

export default connect(mapStateToProps, {
    getpedido, clear_pedido
})(DetallePedido)

