import React, {useState, useEffect} from 'react';
import Layout from '../../hocs/Layout';
import background_white from "../../assets/images/home/fondowhite.jpg";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import ItemDetalleListaCompra from '../../widgets/item_detalle_lista_compra';

// import para realizar peticiones
import {connect} from "react-redux";
import {
    get_detalle_lista_compra,
    actualizar_detalles_lista_compra,
    actualizar_lista_carrito,
    inc_peso_carrito_lista_compra,
    eliminar_product_lista_compra,
    limpiar_data_lista_compra
} from '../../redux/actions/shoppingList';
import {set_id_list_compra}
    from '../../redux/actions/shoppingList';
import {useParams} from 'react-router'
import {faCartShopping, faCircleXmark, faSquareMinus, faSquarePlus} from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import {useNavigate} from "react-router-dom";
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/stylePageGlobals.scss";
import "../../assets/styles/responsive.scss"
import BackToPage from "../../components/BackPage/BackToPage"
import Comprarapida from "../../components/modal/compra_rapida";
import {Circles} from "react-loader-spinner";
import {add_al_carrito, store_carrito_data, update_carrito} from "../../redux/actions/carrito";
import MiCarrito from "../../components/modal/mi_carrito"
import ButtonIcon from "../../widgets/button_icon";
import ButtonText from "../../widgets/button_text";
import Alert from "../../components/Swalert";

const customStyles2 = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '10',
    },
    // content: {
    //     // top: '40%',

    //     right: 'auto',
    //     bottom: 'auto',
    //     left: 'auto',
    //     borderRadius: '0 px',
    //     border: 'none',
    //     background: 'none',
    //     padding: '0px 10px 10px 10px',
    //     width: '100%',
    //     display: 'flex',
    //     marginTop: '120px',
    //     justifyContent: 'center'


    // },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0 px',
        border: 'none',
        background: 'none',
        padding: '0px 0px 0px 0px',
        marginRight: '-50%',
        marginTop: '120px',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        display: 'flex',        
        justifyContent: 'center'
    },

};
const customStyles22 = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '10',
    },
    content: {
        // top: '40%',
        inset: '0 auto auto',
        right: 'auto',
        bottom: 'auto',
        left: 'auto',
        borderRadius: '0 px',
        border: 'none',
        background: 'none',
        padding: '0px 10px 10px 10px',
        width: '100%',
        display: 'flex',
        marginTop: '30px',
        justifyContent: 'center'
    },
};

const customStyles = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '1',
        minHeight: '45rem'
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        borderRadius: '0.75rem',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'visible'
    },
};
const pesajes = [
    {name: '500g', peso: '500'},
    {name: '1Kg', peso: '1000'},
    {name: '2Kg', peso: '2000'},
    {name: '3Kg', peso: '3000'},
    {name: '5Kg', peso: '5000'},
    {name: '10Kg', peso: '10000'},
    {name: '15Kg', peso: '15000'},
    {name: '20Kg', peso: '20000'},
    {name: '25Kg', peso: '25000'},
    {name: '30Kg', peso: '30000'},
    {name: '35Kg', peso: '35000'},
    {name: '40Kg', peso: '40000'},
    {name: '45Kg', peso: '45000'},
    {name: '50Kg', peso: '50000'},
];
const DetalleListaCompra = ({
                                get_detalle_lista_compra,
                                shopping_list_detalle,
                                sucursal,

                                carrito_lista_compra,
                                actualizar_lista_carrito,
                                set_id_list_compra,
                                add_al_carrito,
                                carritos,

                                actualizar_detalles_lista_compra,
                                inc_peso_carrito_lista_compra,
                                eliminar_product_lista_compra,
                                productos_delete_lista_compra,
                                cambio_carrito,
                                list_carrito,
                                store_carrito_data,
                                update_carrito,
                                direccion,
                                estado_cambios, limpiar_data_lista_compra,
                                peticion
                            }) => {
    const params = useParams();
    const navigate = useNavigate();
    const IdListaCompra = params.IdListaCompra;

    let IdSucursal = sucursal.IdSucursal;
    const [modalCarrito, setCarrito] = useState(false);
    const [modalComprarapida, setCarritoComprarapida] = useState(false);
    const [modalPeso, setModalPeso] = React.useState(false);
    const [costoTotal, setCostoTotal] = useState(0);
    const [ahorradoTotal, setAhorradoTotal] = useState(0);
    const [verifyCambio, setVerifyCambio] = useState(false);
    const [todos, setTodos] = useState([]);
    const [objectProducto, setObjectProducto] = useState();
    const [cantidadPeso, setCantidadPeso] = useState();

    useEffect(() => {
        fetchData(IdListaCompra);
    }, [shopping_list_detalle])

    useEffect(() => {
        return () => {
            console.log("si");
            setCostoTotal(0);
            setAhorradoTotal(0);
        }
    }, []);

    useEffect(() => {
        calcularTotal();
    }, [carrito_lista_compra]);

    useEffect(() => {
        return () => {
            guardarBD1()
        }
    }, [])

    useEffect(() => {
        if (estado_cambios) {
            setVerifyCambio(true);
        }

    }, [estado_cambios]);

    useEffect(() => {
        verifyCambioListaCarrito();
    }, [costoTotal]);

    const openModalPeso = () => setModalPeso(true);
    const closeModalPeso = () => setModalPeso(false);
    const startCarrito = () => setCarrito(true);
    const startComprarapida = () => {
        if (shopping_list_detalle.Productos.length === 0) {
            mensaje_alert("No tiene producto seleccionado para realizar la compra.");
            return;
        }

        if (validar_monto_minimo()) {
            setCarritoComprarapida(true);
        } else {
            var monto = (sucursal.ImpMinimoPedido - costoTotal);
            var texto = "Faltan Bs." + monto + " para completar el mínimo de compra.";
            mensaje_alert(texto);
        }

    }

    function validar_monto_minimo() {
        if (costoTotal >= sucursal.ImpMinimoPedido) {
            return true;
        }
        return false;
    }

    const endCompraRapida = () => setCarritoComprarapida(false);

    async function fetchData(IdLista) {
        try {
            if (!verifyShoopingListDetalle()) {
                const parametro = {'Id': IdLista, 'Sucursal': IdSucursal}
                await get_detalle_lista_compra(parametro);
                // await actualizar_lista_carrito()
            }


        } catch (e) {
            console.error(e);
        }
    }

    function verifyCambioListaCarrito() {
        let resp = false;
        if (verifyShoopingListDetalle()) {
            let cantidad_axu = 0;
            let cantidad_act = 0;

            shopping_list_detalle.Productos.map(pso => {
                cantidad_axu += pso.Cantidad
            });
            carrito_lista_compra.map(ps => {
                cantidad_act += ps.Cantidad
            });
            if (parseFloat(cantidad_axu) !== parseFloat(cantidad_act)) {
                setVerifyCambio(true);
                resp = true;
            } else {
                resp = false;
                setVerifyCambio(false);
            }
        }
        return resp;

    }

    async function guardarBD1() {
        let listaux = JSON.parse(localStorage.getItem("item_lista_compra"));
        const datos = {
            "Id": listaux.Id,
            "Descripcion": listaux.Descripcion,
            "Color": listaux.Color,
        }
        let IdSucursal = sucursal.IdSucursal;
        let productoaux = JSON.parse(localStorage.getItem("carrito_lista_compra")) ?? [];
        let prod_delete = JSON.parse(localStorage.getItem("productos_delete_lista_compra")) ?? [];
        await actualizar_detalles_lista_compra(datos, productoaux, prod_delete, IdSucursal);
        await limpiar_data_lista_compra();
    }

    async function guardarBD() {
        if (estado_cambios) {
            const datos = {
                "Id": shopping_list_detalle.Id,
                "Descripcion": shopping_list_detalle.Descripcion,
                "Color": shopping_list_detalle.Color,
            }
            let IdSucursal = sucursal.IdSucursal;
            await actualizar_detalles_lista_compra(datos, carrito_lista_compra, productos_delete_lista_compra, IdSucursal);
            await limpiar_data_lista_compra();
        }

    }

    function actualizarDato(productId, operacion) {
        if (operacion === 'incremento') {
            setTodos(todos.map(product => {
                if (product.IdProducto === productId) {
                    // normal(productId,operacion);
                    return {
                        ...product,
                        Cantidad: product.Cantidad + 1
                    };
                } else {
                    return product;
                }
            }))
        } else if (operacion === 'decremento') {
            setTodos(todos.map(product => {
                if (product.IdProducto === productId && product.Cantidad >= 1) {
                    // normal(productId,operacion);
                    return {
                        ...product,
                        Cantidad: product.Cantidad - 1
                    };
                } else {
                    return product;
                }
            }))

        }


        // setTodos(objectDato);
    }

    function handleCheckedProducto(productoId, nextChecked) {
        setTodos(carrito_lista_compra.map(product => {
            if (product.IdProducto === productoId) {
                // Create a *new* object with changes
                return {...product, Checked: nextChecked};
            } else {
                // No changes
                return product;
            }
        }));
    }

    function viewProductoPeso(producto) {
        setObjectProducto(producto);
        updateCantidadPeso(producto.Cantidad);
    }

    function updateCantidadPeso(cantidad) {
        setCantidadPeso(convertKgToGramos(cantidad));
    }

    function convertKgToGramos(datoKg) {
        return datoKg * 1000;
    }

    function convertGramosToKg(datoKg) {
        return datoKg / 1000;
    }

    function convertToRounded(num) {
        return Math.round(num).toFixed(1);
    }

    function calcularTotal() {
        const countSuma = carrito_lista_compra.map(product => {
            if (product.Checked === true && product.Vigente === true) {
                let result = 0;
                let total_estimado = 0;
                if (product.Fraccionado && product.PesoDefinido) {
                    product.Pesos.forEach((peso) => {
                        result += peso.Piezas * peso.PesoAproxMax;
                    });
                } else {
                    result = product.Cantidad;
                }
                let precio_producto = product.ConOferta ? product.PrecioOferta : product.PrecioVenta;
                total_estimado = result * precio_producto;
                return parseFloat(total_estimado.toFixed(2));

            }
            return 0;
        })

        const countSumaAhorrado = carrito_lista_compra.map(product => {
            console.log("pro pro", product);
            let resultado = 0;
            if(product.Checked === true && product.Vigente === true && product.Cantidad>0 && product.ConOferta===true) {
               
                let precio_venta = product.ConOferta
                    ? product.PrecioOferta
                    : product.PrecioVenta;

                let cantidad_pieza = 0;
                if (product.Fraccionado && product.PesoDefinido) {
                    product.Pesos.forEach((peso) => {
                        cantidad_pieza += peso.Piezas * peso.PesoAproxMax;
                    });
                    let total_sin_oferta = cantidad_pieza * product.PrecioOriginal;
                    let total_oferta = cantidad_pieza * precio_venta;
                    resultado = total_sin_oferta  - total_oferta;
                }else {
                    let rest = (product.PrecioOriginal - product.PrecioOferta);
                    // return (product.Cantidad * rest);

                    resultado = product.Cantidad * rest;
                }

                return resultado;
                // return (product.Cantidad * rest);

            }
            return 0;
        })
        let sumaPrecios = 0;
        let sumaAhorrado = 0;

        if (countSuma[0] !== undefined) {
            sumaPrecios = countSuma.reduce((prev, next) => prev + next);
        }
        if (countSumaAhorrado[0] !== undefined) {
            sumaAhorrado = countSumaAhorrado.reduce((prev, next) => prev + next);
        }
        console.log("sumaahora",countSumaAhorrado);
        setCostoTotal(sumaPrecios);
        setAhorradoTotal(formatPrice(sumaAhorrado));


    }

    function verifyShoopingListDetalle() {
        if (shopping_list_detalle !== undefined &&
            shopping_list_detalle !== null) {
            return true;
        }
        return false;
    }

    function verifyDetalleProductosVacio() {
        if (carrito_lista_compra !== undefined &&
            carrito_lista_compra !== null &&
            carrito_lista_compra.length === 0
        ) {

            return true;
        }
        return false;
    }

    function verifyDetalleProductosOk() {
        if (carrito_lista_compra !== undefined &&
            carrito_lista_compra !== null &&
            carrito_lista_compra.length > 0
        ) {

            return true;
        }
        return false;
    }

    function update_lista_detalle_carrito(costoTotal) {

        localStorage.setItem("costo_shoping", costoTotal);
    }


    function mensaje_alert(text) {
        Swal.fire({
            position: 'top',
            icon: 'error',
            title: text,
            showConfirmButton: false,
            timer: 1500
        })
    }

    function preguntar(titulo, operacion) {
        Swal.fire({
            title: titulo,
            // text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0349AB',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                operacion();
            }
        })
        // Swal.fire({
        //     title: titulo,
        //     showCancelButton: true,
        //     confirmButtonText: 'Confirmar',
        //     confirmButtonColor: '#32338E',
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         operacion();
        //     } else if (result.isDenied) {
        //     }
        // })
    }

    async function agregar_al_carrito() {
        carrito_lista_compra.forEach((producto) => {
            if (producto.Checked) {
                add_al_carrito(producto, Number(producto.Cantidad));
            }
        });

        if (verifyCambio) {
            console.log('tiene cambio');
            await guardarBD();
        }
        startCarrito();
    }

    const confirmar_traspaso = () => {
        preguntar("Esta acción modificará el carrito de compra, ¿Desea continuar?", agregar_al_carrito);
    };

    function redondeo(num, dec) {
        return Number(num.toFixed(dec));
    }

    function actualizarCantidadCarrito(producto, operacion, tipo_cantidad) {
        if (tipo_cantidad === 'es_peso') {
            actualizarCantidadPeso(producto, operacion);
        } else if (tipo_cantidad === 'es_pieza') {

        } else if (tipo_cantidad === 'normal') {

        }
    }

    function actualizarCantidadPeso(producto, operacion) {
        let cantidad = Number(producto.Cantidad);
        let resultKg = 0;
        if (operacion === 'incremento') {
            resultKg = redondeo((cantidad + 0.1), 2);
            // inc_peso_carrito_lista_compra(producto,resultKg);
        } else if (operacion === 'decremento') {
            resultKg = redondeo((cantidad - 0.1), 2);
            // inc_peso_carrito_lista_compra(producto,resultKg);

        }
        actualizarPesoDeModal(producto, resultKg);

        updateCantidadPeso(resultKg);
    }

    function set_peso(pesaje) {
        // console.log(pesaje);
        let pesoKg = convertGramosToKg(Number(pesaje.peso));
        updateCantidadPeso(pesoKg);
        // inc_peso_carrito_lista_compra(objectProducto,pesoKg);
        actualizarPesoDeModal(objectProducto, pesoKg);
    }

    async function actualizarPesoDeModal(producto, peso_kg) {
        await inc_peso_carrito_lista_compra(producto, peso_kg);
        calcularTotal()
    }

    function eliminarProduct(id_producto) {
        eliminar_product_lista_compra(id_producto, IdSucursal);
    }

    async function endCarrito() {
        if (cambio_carrito) {
            console.log("hubo cambio en carrito");
            for (var item of list_carrito.SubPedidos) {
                console.log("item_detalle",item)
                let params = {
                    IdMarket: item.IdMarket,
                    IdSucursal: item.IdMarket,
                    IdTipoEntrega: item.IdTipoEntrega,
                    IdDireccion: direccion.Id,
                };
                let carritos_aux = item.Productos;
                if (item.Sala.Id === sucursal.IdSucursal) {
                    await update_carrito(carritos_aux);
                }
                await store_carrito_data(params, carritos_aux, []);
            }
        }

        setCarrito(false)
    }

    function afterOpenModal() {
    }

    function formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }


    return (
        <Layout>
            <div className="bg_image_page" style={{minHeight: '60rem', height: '60rem'}}>
                <div className='content_info pt-4'>

                    {/* <BackToPage ps_url_page={''}></BackToPage> */}
                    <BackToPage ps_url_page={""} ps_contnerdor={''} ps_verifyCambio={false}
                                ps_functionExecute={guardarBD}></BackToPage>
                    <Alert></Alert>

                    <div className="container">
                        <div className='w-full pb-3'>
                            <h3 className='title text_primario font-medium'>Mi lista de compras</h3>
                            <div className='contenedor_mobile md:w-[50%] '>

                                {verifyShoopingListDetalle() ? (
                                    <div
                                        className={'contenedor_flex justify-between contenedor_mobile px-2 py-0.5 text-white rounded-lg  border-[0.1rem]'}
                                        style={{
                                            backgroundColor: shopping_list_detalle.Color,
                                            borderColor: shopping_list_detalle.Color
                                        }}
                                    >
                                        <span>{shopping_list_detalle.Descripcion}</span>
                                        <span>{verifyDetalleProductosOk() && carrito_lista_compra.length} productos en lista</span>
                                        {/* shopping_list_detalle.Productos.length */}

                                    </div>
                                ) : (
                                    <div
                                        className={'flex justify-between px-2 py-0.5 text-white rounded-lg  border-[0.1rem] '}
                                        // style={{
                                        //     backgroundColor: shopping_list_detalle.Color,
                                        //     borderColor: shopping_list_detalle.Color
                                        // }}
                                    >
                                        {peticion ? (
                                            <></>
                                        ) : (
                                            <p className='text-md text-gray-400 text-center'>
                                                Lista vacía
                                            </p>
                                        )

                                        }
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className="w-full contenedor_flex contenedor_altura">
                            <div className='md:w-[65%]  p-0  contenedor_mobile'>

                                <div
                                    className='w-100 bg-white py-3 px-2   mb-4 rounded-xl border-solid subcontenedor_mobile '
                                    style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem'}}
                                >

                                    <div className=" w-100   container_scroll"
                                         style={{height: '40rem', overflow: 'auto'}}
                                    >
                                        {verifyDetalleProductosOk() ? (
                                            carrito_lista_compra.map((item_shopping_list, index) =>

                                                <ItemDetalleListaCompra
                                                    key={index}
                                                    ps_object_lista_compra={shopping_list_detalle}
                                                    ps_producto={item_shopping_list}
                                                    ps_updateDato={actualizarDato}
                                                    ps_handleCheckedProducto={handleCheckedProducto}
                                                    ps_calcularTotal={calcularTotal}
                                                    ps_openModalPeso={openModalPeso}
                                                    ps_viewProductoPeso={viewProductoPeso}
                                                    ps_actualizarCantidadCarrito={actualizarCantidadCarrito}
                                                    ps_eliminarProduct={eliminarProduct}
                                                    ps_idSucursal={IdSucursal}
                                                ></ItemDetalleListaCompra>
                                            )

                                        ) : (
                                            <>
                                                {peticion ? (
                                                    <div className='flex justify-center text-black text-center'>
                                                        <Circles
                                                            color="#32338E"
                                                            width={40}
                                                            height={40}
                                                            ariaLabel="circles-loading"
                                                            visible={true}
                                                        />
                                                    </div>
                                                ) : (verifyDetalleProductosVacio() &&
                                                    <p className='text-md text-gray-400 text-center'>
                                                        No hay productos para mostrar
                                                    </p>
                                                )

                                                }
                                            </>

                                        )

                                        }

                                        {/* <div className='flex justify-between'>
                                            <div className='product_description'>

                                            </div>
                                            <div className='product_preci'></div>

                                        </div> */}


                                    </div>
                                </div>

                            </div>

                            <div className=" md:w-[35%]  px-3">
                                <div
                                    className="content_card_footer bg-white text-darck rounded-b-lg p-4 mb-4 px-4 rounded-xl "
                                    style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '1.4rem'}}
                                >
                                    <div className="flex flex-col ">
                                        <div
                                            className='flex p-1 justify-between mb-1 text-white bg-hiperorange rounded-md'>
                                            <span className='text-center m-0'>Ahorrado:</span>
                                            <span>Bs {ahorradoTotal}</span>
                                        </div>
                                        <div className='flex p-1 justify-between bg-[#ACACAC] text-white rounded-md '>
                                            <span className='text-center m-0'>Total:</span>
                                            <span>Bs {formatPrice(costoTotal)}</span>
                                        </div>
                                        <div className='flex gap-12 mt-3 mb-3 justify-center text-[#32338E]'>
                                            <span className='m-0'>Monto mínimo de compra:</span>
                                            <span>Bs. &nbsp; {sucursal.ImpMinimoPedido}</span>
                                        </div>

                                    </div>
                                    <div className="flex flex-col justify-center  text-center text-white">
                                        <ButtonIcon
                                            icon={faCartShopping}
                                            label='Agregar a carrito'
                                            principal={true}
                                            className={'bg-[#84B111] mb-2 hover:bg-[#5b7a11] hover:text-[#FFFFFF]'}
                                            onClick={() => confirmar_traspaso()}
                                        />
                                        <ButtonText
                                            label='Compra rápida'
                                            principal={false}
                                            className="bg-hiperblue hover:bg-hiperblue_hover text-white"
                                            onClick={() => startComprarapida()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={modalPeso}
                ariaHideApp={false}
                onRequestClose={closeModalPeso}
                style={customStyles}
                contentLabel="address"
            >
                <div className="w-80 relative">
                    <div className="card border-0 relative">
                        <div className="absolute top-[-30px] right-[-2rem]">
                            <FontAwesomeIcon icon={faCircleXmark}
                                             className='w-[2.5rem] h-[2.5rem] rounded-full text-hiperorange bg-white cursor-pointer '
                                             onClick={() => closeModalPeso()}
                            />
                        </div>
                        <div className="text-center">
                            <h5 className="font-bold">Seleccione el Peso</h5>
                        </div>
                        <div className="card-body pt-0" style={{maxHeight: '20rem', overflow: 'auto'}}>
                            <div className="row">
                                {
                                    pesajes.map((pesaje, index) => (
                                        <div className="col-6" key={index}>
                                            <div
                                                className="bg-[#EEEEEE] m-2 px-3 py-2 rounded text-center cursor-pointer hover:bg-gray-400"
                                                onClick={() => set_peso(pesaje)}>
                                                <span className="font-semibold text-xl">{pesaje.name}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center">
                            <div className="flex border-[3px] border-hiperorange p-1 rounded">
                                <FontAwesomeIcon icon={faSquareMinus}
                                                 className='w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer'
                                                 onClick={() => actualizarCantidadPeso(objectProducto, 'decremento')}
                                />
                                <div className="mx-2 flex align-items-center">
                                    {/* <span>{cantidad_pieza}</span> */}
                                    <span>{convertToRounded(cantidadPeso)}g</span>
                                </div>
                                <FontAwesomeIcon icon={faSquarePlus}
                                                 className='w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer'
                                                 onClick={() => actualizarCantidadPeso(objectProducto, 'incremento')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={modalComprarapida}
                ariaHideApp={false}
                onAfterOpen={afterOpenModal}
                onRequestClose={endCompraRapida}
                style={customStyles22}
                contentLabel="compra_rapida"
                id="theIdCart"
            >
                <Comprarapida cerrarModal={endCompraRapida} productos={carrito_lista_compra} importeTotal={costoTotal} importeAhorrodo={ahorradoTotal} />
            </Modal>
            <Modal
                isOpen={modalCarrito}
                ariaHideApp={false}
                onAfterOpen={afterOpenModal}
                onRequestClose={endCarrito}
                style={customStyles2}
                contentLabel="carrito"
                id="theIdCart"
            >
                <MiCarrito cerrarModal={endCarrito}/>
            </Modal>
        </Layout>
    );
};


const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    shopping_list_detalle: state.Shopping_list.shopping_list_detalle,
    carrito_lista_compra: state.Shopping_list.carrito_lista_compra,
    carritos: state.Carrito.carrito,
    productos_delete_lista_compra: state.Shopping_list.productos_delete_lista_compra,
    list_carrito: state.Carrito.list_carrito,
    cambio_carrito: state.Carrito.cambio_carrito,
    direccion: state.Location.direccion,
    estado_cambios: state.Shopping_list.estado_cambios,
    peticion: state.Peticion.peticion,

})

export default connect(mapStateToProps, {
    get_detalle_lista_compra,
    actualizar_detalles_lista_compra,
    actualizar_lista_carrito,
    set_id_list_compra,
    add_al_carrito,
    // get_producto_carrito_lista_compra,
    inc_peso_carrito_lista_compra,
    eliminar_product_lista_compra,
    store_carrito_data,
    update_carrito,
    limpiar_data_lista_compra

})(DetalleListaCompra)