import React from "react";
import classNames from "classnames";

const Item_market = ({url, texto, opcion, sucursal,IdSucursal}) => {
    return (
    <div
         className={classNames("w-[150px]", "border", "rounded-2xl", "overflow-auto", "cursor-pointer","relative",
             {
                 "bg-hiperorange": IdSucursal === (sucursal && sucursal.IdSucursal),
                 "bg-white": IdSucursal !== (sucursal && sucursal.IdSucursal),
             })}
         onClick={() => opcion(sucursal)}>
        <img
            src={url}
            alt=""/>
        <div className="w-100 bg-hiperblue text-white text-center py-2 absolute" style={{bottom:'0'}}>
            {texto}
        </div>
    </div>
)
    ;
};

export default Item_market;