import Layout from "../hocs/Layout";
import React, {useState, useEffect, useRef} from "react";
import { faSearch, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useNavigate, useParams} from "react-router-dom";
import ItemProducto from "../widgets/item_producto";
import {connect} from "react-redux";
import {get_categorias, get_categorias_filtro} from "../redux/actions/home";
import {get_producto , limpiar_array_producto, limpiar_search, set_search} from "../redux/actions/producto"
import {get_filtro_productos_categoria} from "../redux/actions/producto"
import BackToPage from "../components/BackPage/BackToPage";
import {Circles} from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import '../../src/assets/styles/categoria.scss';
import convertSlug from "../components/Utils/slug";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import LoaderSpinner from "../widgets/loaderSpinner";

import Alert from "../components/Swalert";

import classNames from "classnames";


let page = 0;
let cont=0;
const SearchProducto = ({
                            get_categorias,
                            categorias,
                            sucursal,
                            set_search,
                            limpiar_search,
                            limpiar_array_producto,
                            get_producto ,
                            productos,
                            productos_peticion,
                            subcategorias,
                            category,
                            subcategoria_id,
                            loading,
                            get_filtro_productos_categoria, sucursales, get_categorias_filtro,peticion,
                            end_message
                        }) => {

    const {  IdCategoria, ciudadp, sucursalp} = useParams();
    const { producto} = useParams();
    //const decodedProducto = decodeURIComponent(producto);
    const navigate = useNavigate();
    let IdSucursal = sucursal ? sucursal.IdSucursal : null;
    let IdMarket = sucursal ? sucursal.IdMarket : null;

    const [categoria, setCategoria] = useState({
        IdCategoria: '',
        Descripcion: '',
    });
 //   const [producto, setProducto] = useState('');
    const [dataInfo, setDataInfo] = useState({});
    const [categoriaid_select, SetCategoriaid_select] = useState();
    const [aux, setAux] = useState();

    const myDivRef = useRef();
    const [subcategorias1, setSubcategorias1] = useState([]);
    const [filtro, setFiltro] = useState('');
    const [allcategory, setAllcategory] = useState(IdCategoria ? false : true);
    const [url_navigation, setUrl_navigationl] = useState(null);
    const [idsucursal, setIdsucursal] = useState(0);
    /* agregado*/
    const [filtrosubcategoria, setFiltrosubcategoria] = useState('');
    /* agregado*/

    const breakpoints = {
        300: {
            slidesPerView: 3,
            spaceBetween: 2,
        },
        400: {
            slidesPerView: 3,
            spaceBetween: 2,
        },

        640: {
            slidesPerView: 4,
            spaceBetween: 2,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 5,
        },
        800: {
            slidesPerView: 4,
            spaceBetween: 5,
        },

        992: {
            slidesPerView: 4,
            spaceBetween: 5,
        },
        1200: {
            slidesPerView: 12,
            spaceBetween: 5,
        },

        1400: {
            slidesPerView: 12,
            spaceBetween: 5,
        },

    };



    useEffect(() => {

        return () => {
            limpiar_search();
            limpiar_array_producto();
            page=0;
        };
    }, []);
  /*  useEffect(() => {
      console.log("PASOOOOOOOOOOOOOOOOOOO");
       setProducto(localStorage.getItem("filter_g"));
    }, [productop]);
    */


    useEffect(() => {
        if(producto){
            console.log("prod FILTROOOOOO*"+producto+"*");


            setFiltro(producto);
          //  setFiltro(producto);
        }else if(!IdCategoria){

            llenar_data_filtro();
        }
    }, [producto]);



    const convert_url = async () => {
        let resp = (ciudadp + ' ' + sucursalp);
        let new_url = convertSlug.data_barra(resp);
        let url_parameters = new_url;
        return url_parameters;
    }

    function getIdsucursal(slug) {
        for (let item of sucursales) {
            if (convertSlug.data_slug(item.Descripcion) === slug) {
                return item.IdMarket;
            }
        }
        return null;
    }
    async function cargar_url_var() {
        let url_bas = await convert_url();
        setUrl_navigationl(url_bas);
    }
    const subir = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    function validarAnchoPantalla() {
        var anchoPantalla = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        var anchoDeseado = 420;
        if (anchoPantalla <= anchoDeseado) {
            return true;
        } else {
            return false;
        }
    }

    function mover_scroll() {
        if (validarAnchoPantalla()) {
            if (myDivRef.current) {
                const scrollY = myDivRef.current.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({top: scrollY, behavior: 'smooth'});
            }
        }
    }

    // OK
    useEffect(() => {
        console.log(" filtrosubcategoria ", filtrosubcategoria);
        get_categorias_filtro(IdSucursal, IdMarket, null, filtrosubcategoria);
    }, [filtrosubcategoria]);
    useEffect(() => {
        console.log("TWO");
        if (allcategory) {
            console.log("1.1")
            setCategoria({});
        }
        mover_scroll();
    }, [allcategory]);

    useEffect(() => {
        console.log("THREE");
        console.log("2", page)
        if (IdCategoria) {
            console.log("2.1")
            obtenerCategoria();
        }
        if (categorias.length > 0) {
            console.log("2.2")
            cargar_productos_categoria();
        }
        mover_scroll();
    }, [IdCategoria]);

    useEffect(() => {
        console.log("FOUR");
        console.log("3")
        if (categorias.length === 0 || !categorias) {
            console.log("catenull");
            get_categorias(IdSucursal, IdMarket);
        } else {
            if(IdCategoria && productos.length===0) {
                async function onloadCate(){
                    await obtenerCategoria();
                    await limpiar_array_producto();
                    page=0;
                    await llenar_data();
                }
                onloadCate();
               
            }
        }
        // mover_scroll();
    }, [categorias]);
    useEffect(() => {
        console.log("FIVE");
        console.log("4")

        async function fetchData() {
            try {
                console.log("INIT READER PRODUCT");
                page = 0;
                if (!productos_peticion) {
                    await limpiar_array_producto();
                }
                const res2 = await llenar_data();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            mover_scroll();
        }

        fetchData();
    }, [producto]);
    useEffect(() => {
        console.log("SIX PET");
        console.log("estado_pet",productos_peticion)
        console.log("5")
        if(Object.keys(dataInfo).length > 0 ) {

            page = page + 1;
            console.log("5.1", page)
            console.log("PASO CARGA SCROLLinfnix");
            cargar_productos(dataInfo, page);
        }
        if(page===1){
            mover_scroll();
        }
        
    }, [dataInfo]);

    useEffect(() => {
        console.log("SEVEN");
        cargar_url_var();
        let sucursal_id = getIdsucursal(sucursalp);
        setIdsucursal(sucursal_id);

        window.addEventListener("scroll", function () {
            // Obtiene la posición actual del scroll en el eje Y
            var scrollY = window.scrollY;

        });
    }, [sucursales])

    const cargar_productos_categoria = async () => {
        console.log("CAMBIO CATE ");
        page = 0;
        if (!productos_peticion) {
            await limpiar_array_producto();
        }
        await llenar_data();
    }

    async function obtenerCategoria() {
        let categ = await getCategoriaUrl(IdCategoria);
        if (categorias.length > 0) {
            let result = categorias.find(cat => cat.IdCategoria === categ.IdCategoria);
            await _setCategoria(result.IdCategoria, result.Descripcion);
        }
    }

    async function _setCategoria(IdCategoria, Descripcion) {
        console.log("CATEGORY...", IdCategoria, Descripcion);
        await setCategoria({IdCategoria: IdCategoria, Descripcion: Descripcion})
    }

    async function getCategoriaUrl(slug) {
        let resp = null;
        if (categorias.length > 0) {
            for (let item of categorias) {
                if (convertSlug.data_slug(item.Descripcion) === slug) {
                    resp = item;
                    break;
                }
            }
        }

        return resp;
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function llenar_data() {
        // await delay(3000);
        console.log("estadopeticion",peticion);
        console.log("6 metod scroll", productos_peticion);
        console.log("idcate", IdCategoria);
        if(!peticion){

        
            let categ = await getCategoriaUrl(IdCategoria);
            if(categ === null) {
                setCategoria({IdCategoria: '', Descripcion: ''})
            }
            console.log('CATECATE', categ);
            if(productos_peticion) {
                console.log("PASO CARGA SCROLL 1");
                console.log("filtrazo1",filtro);
                console.log('MARCADOR', IdMarket, IdSucursal);
                // let IdCategoria_aux = IdCategoria ? Number(IdCategoria) : null;
                // categorias.length > 0 &&
                if(categorias.length > 0 && IdSucursal !== null && IdMarket !== null ) {
                    console.log("SETEA");
                    let datos = {
                        IdMarket: IdMarket,
                        IdLocatorio: IdSucursal,  //antes idsucursal
                        IdCategoria: categ ? Number(categ.IdCategoria) : null,  // antes IdCategoria_aux
                        IdSubcategoria: null,
                        texto_filtro: producto? producto: filtro,
                    }

                    // await delay(1500);
                    setDataInfo(datos);
                }
            }
        }
    }
    async function llenar_data_filtro() {
        console.log("6 metod scroll", productos_peticion);
        console.log("PASO CARGA SCROLL 1");
        console.log("filtrazo2",filtro);
        console.log('MARCADOR', IdMarket, IdSucursal);
        // let IdCategoria_aux = IdCategoria ? Number(IdCategoria) : null;
        if (categorias.length > 0 && IdSucursal !== null && IdMarket !== null) {
            console.log("SETEA");
            let datos = {
                IdMarket: IdMarket,
                IdLocatorio: IdSucursal,  //antes idsucursal
                IdCategoria:  null,  // antes IdCategoria_aux
                IdSubcategoria: null,
                texto_filtro: '',
            }

            await delay(1500);
            setDataInfo(datos);
        }
        
    }


    function seleccionarCategoria(cat) {
        console.log("7", cat);
       
        let cate_slug = convertSlug.data_slug(cat.Descripcion);
        if(cate_slug=== IdCategoria && filtro===''){
            console.log("igual");
            return;
        }else if(cate_slug=== IdCategoria && filtro!=='' && producto=== filtro){
            console.log("igual");
            return;
        }else{
            page = 0;
            limpiar_array_producto();
            if (categoria.IdCategoria === cat.IdCategoria && filtro!=='' ) {
                console.log("unocateg");
                setAllcategory(true);
                let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
                if (ciudadp && sucursalp) {
                    navigate("/" + ciudadp + "/" + sucursalp + "/busqueda-producto-g/"+cate_slug+'/' + filtro.trim());
                } else if (url_nav) {
                    navigate("/" + url_nav.url_parameters + "/busqueda-producto-g/"+cate_slug+'/'+ filtro.trim());
                }
    
    
            }
            else{
                window.scrollTo(0, 0);
                let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
                if (ciudadp && sucursalp) {
                    // navigate("/"+ciudadp+"/"+sucursalp+"/busqueda-producto/" + producto.trim()+'/'+cat.IdCategoria);
                    navigate("/"+ciudadp+"/"+sucursalp+"/busqueda-producto-g/"+cate_slug);
                } else if (url_nav) {
                    navigate("/" + url_nav.url_parameters + "/busqueda-producto-g/"+cate_slug);
                }
            }
        }

       
    }
    function onloadFiltroCategoria(){
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            navigate("/" + ciudadp + "/" + sucursalp + "/busqueda-producto-g/"+IdCategoria);
        } else if (url_nav) {
            navigate("/" + url_nav.url_parameters + "/busqueda-producto-g/"+IdCategoria);
        }
        if(productos.length===0){
            page = 0;
            limpiar_array_producto();
            llenar_data();
        }
    }

    const obtenerCantidad = () => {
        console.log('cantidadxxxx',productos.length);


        return productos.length;
    }

    async function cargar_productos(datos, page_aux) {
        await get_producto(datos, page_aux);
    }


    function save_text_filtro(text) {
        localStorage.setItem("categoria_filter_g", text);
    }


    /* agregado*/
    const handleChange2 = (event) => {
        setFiltrosubcategoria(event.target.value);

    };


    const enter_opcion = (event) => {
        // console.log(event);
        let cate_slug = convertSlug.data_slug(categoria.Descripcion);
        let valor= event.target.value;
        // console.log("valr",valor);
       
        if(event.type==='keyup'){
            if(valor === "" && valor.trim() === ""){
                cont++;
                limpiar_search();
                // console.log("limpiar11");
                // console.log("categr",IdCategoria);
                if(IdCategoria){
                    onloadFiltroCategoria();
                }else{
                    page=0;
                    llenar_data_filtro();
                }


            }
        }
        if (event.key === 'Enter' || event.type === 'click') {
            console.log("passaaa ");
            cont=0;
            if(filtro !== "" && filtro.trim() !== "" && cate_slug) {
                set_search(filtro)
                let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
                if (ciudadp && sucursalp) {
                    navigate("/" + ciudadp + "/" + sucursalp + "/busqueda-producto-g/"+cate_slug+'/'+ filtro.trim() );
                } else if (url_nav) {
                    navigate("/" + url_nav.url_parameters + "/busqueda-producto-g/"+cate_slug+'/'+ filtro.trim());
                }
            }else if(filtro !== "" && filtro.trim() !== ""){
                set_search(filtro)
                let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
                if (ciudadp && sucursalp) {
                    // navigate("/"+ciudadp+"/"+sucursalp+"/busqueda-producto/" + producto.trim()+'/'+cate_slug);
                    navigate("/" + ciudadp + "/" + sucursalp + "/busqueda-producto/" + filtro.trim());
                } else if (url_nav) {
                    navigate("/" + url_nav.url_parameters + "/busqueda-producto/" + filtro.trim());
                }

                if(filtro === producto){
                    limpiar_array_producto();
                    page=0;
                    llenar_data();
                }
            }
        }
    };

    const handleChange = (event) => {
        setFiltro(event.target.value);
        save_text_filtro(event.target.value);
    };

    /* agregado*/
    return (
        <Layout>
            {/* <Categoria/>    */}
            {/* <BackToPage ps_url_page={"/"} ps_contenerdor={'sinContenedor'}></BackToPage> */}
            <BackToPage ps_url_page={`/${convertSlug.data_barra(ciudadp + ' ' + sucursalp)}`}
                        ps_contenerdor={'sinContenedor'}></BackToPage>
            <Alert></Alert>
            <main className="relative flex-grow">

                <div className="mx-auto max-w-[1920px]  container_main">
                    <div className="w-full content_responsivo_movil px-2 relative z-0">
                    {/* categoria */}
                    {categoria !== null &&
                    categoria !== undefined &&
                        <h4 className="text-center text-hiperorange">{categoria.Descripcion}</h4>
                    }
                    
                   <Swiper
                       breakpoints={breakpoints}
                       slidesPerView={1}
                       loop={false}
                       pagination={{
                           clickable: true,
                       }}
                       navigation={true}
                       modules={[Navigation]}
                       className="mySwiper  py-2 text-xs text-center font-light overflow-hidden"
                       style={{
                           "--swiper-navigation-color": "#32338E",
                           "--swiper-navigation-size": "20px",
                           "--swiper-navigation-weight": "900"
                       }}
                   >
                       {
                           categorias &&
                           categorias !== null &&
                           categorias !== undefined &&
                           categorias.map((cat, index) => (
                               <SwiperSlide key={index}
                                            style={{alignSelf: 'center', display: 'flex', justifyContent: 'center'}}
                               >
                               <div className="relative">
                                   <div 
                                       className="py-1.5 px-2 rounded-lg border  cursor-pointer slider_opciones"
                                       style={{
                                           backgroundColor: (Number(cat.IdCategoria) === Number(categoria.IdCategoria)) ? 'var(--primario)' : "transparent",
                                           color: (Number(cat.IdCategoria) === Number(categoria.IdCategoria)) ? '#FFFFFF' : 'var(--secundario)',
                                           minWidth:'6rem'
                                       
                                       }}
                                       onClick={(e) => seleccionarCategoria(cat, e)}
                                   >

                                       {cat.Descripcion}
                                   </div>
                               </div>
                                       
                               </SwiperSlide>

                           ))
                       }
                   </Swiper>
                   
                    </div>

                    <div name="grid" className="flex pb-16 pt-1 container_categoria">
                        <div className="sticky top-5 h-full w-80 subcontainer_cat content_responsivo_desktop">
                            <div className="block">
                                <div className="max-h-full overflow-hidden rounded-xl border border-border-base shadow">

                                    <div className="relative flex w-full flex-wrap items-stretch px-3 mt-2">
                                        <input type="text"
                                               className=" py-1.5 placeholder-slate-400 text-slate-600 relative rounded-2xl text-sm border border-hiperorange  outline-none w-full"
                                               value={filtrosubcategoria}
                                               onChange={(e)=>handleChange2(e)}
                                               placeholder='Buscar categoría'
                                               style={{paddingRight:'2rem',paddingLeft:'0.5rem'}}

                                        />
                                        <span
                                            className=" absolute text-hiperorange right-5 pr-3 py-1.5">
                                            <FontAwesomeIcon icon={faSearch} onClick={(e) => handleChange2(e)}/>
                                          </span>
                                    </div>

                                    <div className="mt-2">
                                        <ul className=" px-3 grid gap-1">
                                            {
                                                categorias &&
                                                categorias !== null &&
                                                categorias !== undefined &&
                                                categorias.length > 0 &&
                                                categorias.map((cat, indexcat) =>
                                                    <li key={indexcat}
                                                        className={classNames("py-1.5", "px-2", "rounded-lg", "cursor-pointer",
                                                            {
                                                                "border": (Number(cat.IdCategoria) !== Number(categoria.IdCategoria))
                                                            })}
                                                        style={{
                                                            backgroundColor: Number(cat.IdCategoria) === Number(categoria.IdCategoria) ? 'var(--primario)' : "transparent",
                                                            color: Number(cat.IdCategoria) === Number(categoria.IdCategoria)? '#FFFFFF' : 'var(--secundario)'
                                                        }} onClick={(e) => seleccionarCategoria(cat, e)}>

                                                        {cat.Descripcion}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full container_prod" ref={myDivRef} id="myDivRef2">

                            <div className="relative flex w-full flex-wrap items-stretch px-3 mt-2">
                                <input type="text"
                                       className=" py-1.5 placeholder-slate-400 text-slate-600 relative rounded-2xl text-sm border border-hiperorange  outline-none w-full "
                                       value={filtro}
                                       onKeyDown={enter_opcion}
                                       onKeyUp={enter_opcion}
                                       onChange={(e)=>handleChange(e)}
                                       placeholder='Buscar en esta categoría'
                                       style={{paddingRight:'2.1rem',paddingLeft:'0.5rem'}}
                                />
                                <span
                                    className=" absolute text-hiperorange right-5 pr-3 py-1.5">
                                            <FontAwesomeIcon icon={faSearch}/>
                                        </span>
                            </div>

                            <div className="w-100 h-100 p-2">
                                <InfiniteScroll
                                    dataLength={obtenerCantidad()}
                                    next={llenar_data}
                                    hasMore={true}
                                  
                                    loader={
                                        peticion ?
                                            <LoaderSpinner bool={peticion}></LoaderSpinner>
                                            :
                                            end_message &&
                                            <div className="flex w-full justify-center">
                                                <p className="text-gray-400 ">No hay mas productos para mostrar</p>
                                            </div>
                                    }
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                          <b>No hay mas productos para mostrar</b>
                                        </p>
                                      }
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        flexWrap: 'wrap',
                                        gap: '1rem',
                                        paddingBottom: '2rem',
                                        marginLeft:'10px'
                                    }}
                                >
                                    <div className="content_infinit" style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexWrap: 'wrap',
                                                // gap: '1rem',
                                                paddingBottom: '2rem'
                                            }}>
                                    {
                                        productos &&
                                        productos !== null &&
                                        productos !== undefined &&
                                        productos.map((producto, index) => (
                                            
                                             <ItemProducto key={index} producto={producto}
                                                          ps_IdSucursal={sucursal.IdSucursal}/>
                                           
                                        ))
                                    }
                                    </div>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="footer-button  bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
                        type="button" onClick={() => subir()}>
                    <FontAwesomeIcon icon={faArrowUp}/>
                </button>
            </main>
        </Layout>
    );
};
const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    sucursales: state.BranchOffice.sucursales,
    categorias: state.Home.categorias,
    subcategorias: state.Home.subcategorias,
    category: state.Home.category,
    subcategoria_id: state.Home.subcategoria_id,
    productos_peticion: state.Producto.productos_peticion,
    loading: state.Producto.loading,
    productos: state.Producto.productos,
    peticion:state.Peticion.peticion,
    end_message:state.Producto.end_message
})

export default connect(mapStateToProps, {
    get_categorias, get_producto , get_filtro_productos_categoria,
    limpiar_array_producto, get_categorias_filtro,set_search,limpiar_search
})(SearchProducto)