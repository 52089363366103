import {Provider} from 'react-redux';
import store from './store';
// import {BrowserRouter  as Router, Route, Routes,Switch} from 'react-router-dom';

import {HashRouter   as Router, Route, Routes,Switch} from 'react-router-dom';
import React, {useState, useEffect} from 'react';

import Error404 from "./containers/errors/Error404";
import Home from "./containers/Home";
import LoginPhone from "./components/LoginPhone";
// import Verification from "./components/Verification";
import Prueba01 from "./Practice/Prueba01";
import Navbar from "./components/Nav";
import MyCart from "./containers/cart/MyCart";
import PurchaseList from "./containers/ListaCompra/ListaCompra";
import DetalleListaCompra from "./containers/ListaCompra/DetalleListaCompra";
import DetalleListaCompraHome from './containers/ListaCompra/DetalleListaCompraHome';
import NuevaListaCompra from './containers/ListaCompra/NuevaListaCompra';
import ListaMisTarjetas from "./containers/MisTarjetas/ListaMisTarjetas.jsx";
import NuevaTarjeta from "./containers/MisTarjetas/NuevaTarjeta";
import Entrega from "./containers/cart/Entrega";
import EntregaCompraRap from "./containers/cart/EntregaCompraRapida";
import DatosEntrega from "./containers/cart/DatosEntrega";
import ConfirmacionPedido from "./containers/cart/ConfirmacionPedido";
import DetallePedido from "./containers/cart/DetallePedido";
import InformacionProducto from "./containers/InformacionProductoNew";
import InformacionProductoNew from "./containers/InformacionProductoNew";
import InformacionProductoNew2 from "./containers/InformacionProductoNew2";
import InformacionProductoPieza from "./containers/ListaCompra/ProductoPiezaNew";
/*import InformacionProductoPieza from "./containers/ListaCompra/ProductoPieza";*/
import HomeCategoria from "./containers/HomeCategoria";
import HomeCategoriaProducto from "./containers/HomeCategoriaProducto";
import SearchProducto from "./containers/SearchProducto";
// import SearchProductoBarcode from "./containers/SearchProductoBarcode";
import Cuenta from "./containers/Cuenta";
import MisPedidos from "./containers/pedido/MisPedidos";
import Direccion from "./containers/direccion/Direccion";
import DireccionInit from "./containers/direccion/DireccionInit"
import Escanear from "./containers/compras/Escanear";

import HomeLogin from "./containers/auth/HomeLogin";
import Login from "./containers/auth/Login";
import Activation from "./containers/auth/Activate";
import TokenMomentaneo from "./containers/auth/TokenMomentaneo";

import TerminosCondiciones from "./containers/auth/TerminosCondiciones";

import ActualizarDireccion from "./containers/direccion/ActualizarDireccion";
import ActualizarDireccionRegistro from "./containers/direccion/ActualizarDireccionRegistro";
import InicioDireccion from "./containers/direccion/InicioDireccion";
import InicioSucursal from "./containers/sucursal/inicioSucursal";
import HomeSeccion from "./containers/HomeSeccion";
import DetalleListaCompraCompartida from "./containers/ListaCompra/DetalleListaCompraCompartida";
import InformacionProductoCarrito from "./containers/InformacionProductoCarritoNew";
import ProtectedRoute from './components/Utils/ProtectedRoute';
import ProtectedUserRegistered from './components/Utils/ProtectedUserRegistered';
import { register } from './serviceWorker';

import ProductoScroll from './containers/productoscroll';
// import Pruebascanner from './containers/pruebaEscaner';
import BuscarPorCodigoBarra from './containers/Search/BuscarPorCodigoBarra';

import {useLocalStorage} from 'react-use';
import HomeCategoriaCopia130923 from "./containers/HomeCategoriaCopia130923";


function App() {


    const [direccion, setUser] = useLocalStorage('direccion');
    const [branch, setBranch] = useLocalStorage('sucursal');


    return (


        <Provider store={store}>

            <Router>

            <Routes>
                {/* public routes authentication*/}
                <Route exact path='/' element={<HomeLogin/>}/>
                <Route exact path="/login" element={<HomeLogin/>}/>
                <Route exact path="/registro_mobil" element={<Login/>}/>
                <Route exact path="/activar" element={<Activation/>}/>
                <Route exact path="/ubicacion" element={<InicioDireccion/>}/>
                <Route exact path="/sucursal" element={<InicioSucursal/>}/>
                <Route exact path="/sucursal/:ciudad" element={<InicioSucursal/>}/>
                <Route exact path="/sucursal/:ciudad/:sucursal" element={<InicioSucursal/>}/>

                <Route exact path="/terminoscondiciones" element={<TerminosCondiciones/>}/>
                <Route exact path='/prueba' element={<Prueba01/>}/>
                {/* <Route element={<ProtectedUserRegistered></ProtectedUserRegistered>}>


                </Route>  */}
                <Route exact path="/nueva_direccion_registro" element={<DireccionInit/>}/>
                <Route exact path="/actualizar_direccion_registro/:locationId" element={<ActualizarDireccionRegistro/>}/>
                {/* protected routes */}

                {/* exact path="/" */}
                <Route path="/" element={<ProtectedRoute canAtivate={direccion} branch={branch}/>}>
                    <Route path="/:ciudadp/:sucursalp" component={App}>
                        <Route exact path='' element={<Home/>}/>
                        {/* <Route exact path="producto/:productoId" element={<InformacionProducto/>}/> */}
                        {/*<Route exact path="producto/:productoId/:productoSlug" element={<InformacionProducto/>}/> */}
                        <Route exact path="producto/:productoId/:productoSlug" element={<InformacionProductoNew/>}/>
                        <Route exact path="producto-test/:productoId/:productoSlug" element={<InformacionProductoNew/>}/>
                        <Route exact path="producto-test2/:productoId/:productoSlug" element={<InformacionProductoNew2/>}/>

                        <Route exact path="categoria/:IdCategoria/:subcategoriaId" element={<HomeCategoria/>}/>
                        <Route exact path="categoria/:IdCategoria" element={<HomeCategoria/>}/>
                        <Route exact path="categoria/productos/:filter" element={<HomeCategoriaProducto/>}/>
                        {/* <Route exact path="busqueda-producto" element={<SearchProducto/>}/> */}
                        <Route exact path="busqueda-producto/:producto" element={<SearchProducto/>}/>
                        {/* <Route exact path="busqueda-producto/:producto/:IdCategoria" element={<SearchProducto/>}/> */}

                        {/* <Route exact path="busqueda-producto/:producto" element={<SearchProducto/>}/>*/}
                        <Route exact path="busqueda-producto-g/:IdCategoria" element={<SearchProducto/>}/> 
                        <Route exact path="busqueda-producto-g/:IdCategoria/:producto" element={<SearchProducto/>}/>
                        {/* <Route exact path="busqueda-producto/:IdCategoria" element={<SearchProducto/>}/>
                        <Route exact path="busqueda-producto/:IdCategoria/:producto" element={<SearchProducto/>}/> */}

                        <Route exact path="seccion/:SeccionId" element={<HomeSeccion/>}/>

                        <Route exact path="carrito/:productoId/:IdMarket/:IdSucursal" element={<InformacionProductoCarrito/>}/>

                   
                        <Route element={<ProtectedUserRegistered></ProtectedUserRegistered>}>
                            {/* <Route exact path="/lista_compras" element={<PurchaseList/>}/>
                            <Route exact path="/detalle_lista_compra/:IdListaCompra" element={<DetalleListaCompra/>}/>
                            <Route exact path="/lista_compra_compartida/:HashListaCompra" element={<DetalleListaCompraCompartida/>}/>
                            <Route exact path="/nueva_lista_compra" element={<NuevaListaCompra/>}/>
                            <Route exact path="/escanearCodigo" element={<Escanear/>}/>
                            <Route exact path="/cuenta" element={<Cuenta/>}/>
                            <Route exact path="/mis_tarjetas" element={<ListaMisTarjetas/>}/>
                            <Route exact path="/nueva_tarjeta" element={<NuevaTarjeta/>}/> 
                            <Route exact path="/mis_pedidos" element={<MisPedidos/>}/>
                            */}
                            <Route exact path="lista_compras" element={<PurchaseList/>} />
                            <Route exact path="detalle_lista_compra/:IdListaCompra" element={<DetalleListaCompra/>}/>
                            <Route exact path="lista_compra_compartida/:HashListaCompra" element={<DetalleListaCompraCompartida/>}/>
                            <Route exact path="nueva_lista_compra" element={<NuevaListaCompra/>}/>
                            <Route exact path="escanearCodigo" element={<Escanear/>}/>
                            <Route exact path="cuenta" element={<Cuenta/>}/>
                            <Route exact path="mis_tarjetas" element={<ListaMisTarjetas/>}/>
                            <Route exact path="nueva_tarjeta" element={<NuevaTarjeta/>}/>
                            <Route exact path="mis_pedidos" element={<MisPedidos/>}/>
                            <Route exact path="pedido_detalle/:id" element={<DetallePedido/>}/>
                            <Route exact path="lista_compra/detalle_producto/:listaCompraId/:productoId"element={<InformacionProductoPieza/>}/>

                            {/* <Route exact path="nueva_direccion" element={<Direccion/>}/> */}
                            
                            {/* <Route exact path="nueva_direccion_registro" element={<DireccionInit/>}/>
                            <Route exact path="actualizar_direccion_registro/:locationId" element={<ActualizarDireccionRegistro/>}/> */}
                

                        </Route>
                    </Route>

                    {/* protected  autenticacion */}
                    <Route exact path="/carrito" element={<MyCart/>}/>
                    <Route exact path="/entrega" element={<Entrega/>}/>
                    <Route exact path="/entrega_compra_rapida" element={<EntregaCompraRap/>}/>
                    <Route exact path="/datos_entrega" element={<DatosEntrega/>}/>
                    <Route exact path="/confirmacion_pedido" element={<ConfirmacionPedido/>}/>
                    
                 

                    <Route exact path="/actualizar_direccion/:locationId" element={<ActualizarDireccion/>}/>
                


                    {/* prueba temporal */}
                    <Route exact path="/producto_scroll" element={<ProductoScroll/>}/>
                    {/* <Route exact path="/prueba_scanner" element={<Pruebascanner/>}/> */}
                    <Route exact path="/buscar_producto_por_codigo_barra" element={<BuscarPorCodigoBarra/>}/>


                </Route>

                <Route exact path="/token" element={<TokenMomentaneo/>}/>
                <Route path="*" element={<Error404/>}/>


            </Routes>
            </Router>


        </Provider>
    );
}

export default App;
