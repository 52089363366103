import {
    GET_CARD_SUCCESS,
    POST_CARD_SUCCESS,
    GET_CARD_FAIL,
    POST_CARD_FAIL,

    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_FAIL,

    SET_LOADING,
    REMOVE_LOADING,

} from './types'
import axios from 'axios';
import {setSwAlert,showRespuesta} from "./swalert";
import {end_peticion, start_peticion} from "./peticion";
import { huellaDigital } from './../../huellaDigital';
import respuestas from '../../components/Alertas';


export const get_tarjeta = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/tarjeta`, config);
        console.log("recuperando datos axios tarje ", res);
        if (res.status === 200) {
            dispatch({
                type: GET_CARD_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_CARD_FAIL
            });
            dispatch(showRespuesta(dispatch,res) ) ;
        }
    } catch (err) {
        // console.log("este es el error ubicacion ", err)
        dispatch({
            type: GET_CARD_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};


export const post_tarjeta = (datos,object) => async dispatch => {
    dispatch(start_peticion('registrando...'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/tarjeta`, datos, config);

        console.log("respuestadata_enviartarjeta", res,datos);
        if (res.status === 200 && res.data.Estado === 200) {
            dispatch({
                type: POST_CARD_SUCCESS,
                payload: res.data
            });
            dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200,object));
        } else {
            dispatch({
                type: POST_CARD_FAIL
            });
            //dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        dispatch(end_peticion());
    } catch (err) {
        console.log("errortarjeta",err)
        // console.log("este es el error ubicacion ", err)
        dispatch({
            type: POST_CARD_FAIL
        });
        dispatch(end_peticion());
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
};
export const delete_tarjeta = (Id) => async dispatch => {
    // dispatch({type: SET_AUTH_LOADING});
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
        }
    };
    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/tarjeta/${Id}`, config);

        console.log("respuesta  delete tarjeta", res);
        if (res.status === 200 && res.data.Estado === 200) {
            dispatch({
                type: DELETE_ITEM_SUCCESS,
                payload: res.data
            });
            // dispatch(setSwAlert('Tarjeta eliminada correctamente'));
            dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
        } else {
            dispatch({
                type: DELETE_ITEM_FAIL
            });
            //dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
            dispatch(showRespuesta(dispatch,res) ) ;
        }
        // dispatch({type: REMOVE_AUTH_LOADING});
    } catch (err) {
        // console.log("este es el error ubicacion ", err)
        dispatch({
            type: DELETE_ITEM_FAIL
        });
        dispatch(setSwAlert(respuestas.peticionError, true, 500));
        // dispatch({type: REMOVE_AUTH_LOADING});
    }
};
