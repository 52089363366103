import React, {useEffect,useState,useRef} from 'react';
import '../../assets/styles/hiper.scss'
import logo from '../../assets/images/login/logo.png';
import background from '../../assets/images/login/Background.jpg';
import texture from '../../assets/images/login/texture_complete.jpg';
import {Link} from 'react-router-dom';
import '../../assets/fontawesome';
import {faFacebook, faGoogle} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMobileAndroidAlt} from "@fortawesome/free-solid-svg-icons";
import '../../assets/styles/mystyle.scss'

import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/stylePageGlobals.scss";
import {connect} from "react-redux";
import {get_anonimo_token,codigo_acceso_externo,get_token} from "../../redux/actions/auth";
import {useNavigate} from "react-router-dom";
import ButtonText from "../../widgets/button_text";
import FacebookLogin from 'react-facebook-login';
import {FacebookLoginButton} from 'react-social-login-buttons';
import {LoginSocialFacebook,LoginSocialGoogle } from 'reactjs-social-login';


import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

import {info_session} from "../../redux/actions/profile";
import Alert from "../../components/Swalert";
import { fireEvent } from '@testing-library/react';
const containerStyle = {
    backgroundImage:
        `url(${background})`,
    width: "100%",
    height: "92.5vh",
};
const containerStyle2 = {
    backgroundImage:
        `url(${texture})`,
    width: "100%",

};


const Login = ({get_anonimo_token, loading, sendAccesoAnonimo, authenticated,codigo_acceso_externo, profile,datoLogin,get_token,access_token,info_session}) => {
    const navigate = useNavigate();
    const [idFacebook,setIdFacebook]=useState(null);
    const [idGoogle,setIdGoogle]=useState(null);

    const [isVisible, setIsVisible] = useState(false);
    const googleLoginButtonRef = useRef("LOF");

    const responseFacebook = (respuesta) => {
        console.log(respuesta);
      }
       
      const componentClicked=()=>{
            alert("evento click ")
      }
      const facetoken = async () => {
        let token="113017900889709";
        get_codigo_login(token,1)
    };
    async function get_codigo_login(token,codigoAcceso){
   
        await codigo_acceso_externo(token,codigoAcceso);
    }
    const obtenerTokenAnonimo = () => {
        window.setTimeout(() => {
            navigate("/ubicacion");
        }, 1000);
        // await get_anonimo_token();
    };
    useEffect(()=>{
        console.log("llego access_token ... ",access_token);
        if (access_token) {
           console.log("access token entrooooo ");
            info_session();
        }
    },[access_token]);

    

    useEffect(() => {
        inicio();
    }, []);

    function inicio(){
        let token = localStorage.getItem('access_token');
        let tipo_user  = localStorage.getItem('user');
        if (token) {
            if (profile) {
                if (profile.Ubicaciones.length > 0) {
                    navigate("/sucursal");
                } else if ( tipo_user !== '"anonimo"' ) {
                    navigate("/ubicacion");
                }
            }
        } else {
            console.log("no existe")
        }
    }
    useEffect(()=>{
        console.log("llego el profile .....",profile);
        let tipo_user  = localStorage.getItem('user');
        if (profile) {
            if (profile.TerminoCondicion.AceptoTerminoActual) {
                if (profile.Ubicaciones.length>0) {
                    navigate("/sucursal");
                } else if ( tipo_user !== '"anonimo"' ) {
                    console.log("netrasaf")
                    navigate("/ubicacion");
                }
            }else {
                navigate("/terminoscondiciones");

            }
        }
    },[profile]);

    useEffect(() => {
        // console.log("oknaveigate",sendAccesoAnonimo);
        // console.log("logueado como",authenticated);

    }, [sendAccesoAnonimo]);
    useEffect(() => {
      console.log("datoLogin",datoLogin);
       if(datoLogin!=null){
        if(datoLogin.ConError==true){
            // redireccionar a login para q el user se regsitre o inicie sesion con numero cel
             if( localStorage.getItem("access_token")!=null){ // ya inicio session con cel ejecutar endpoint
                   // ejecutar_token_externo(idFacebook,)
             }else{
                //redireccionar al login con numero tel
                console.log("redireccionar a la voista");
             }
           
        }else{
                console.log("poaso .....Q");
            get_token(localStorage.getItem("redId"),datoLogin.Dato);  
        }
       }
      /* 
        */


    }, [datoLogin]);


    // function navigateAccesso(){
    //     if(sendAccesoAnonimo){
    //         console.log("si");
    //         window.setTimeout(()=>{
    //             navigate("/ubicacion");
    //         },1000);
    //     }else{
    //         console.log("no");
    //     }
    // }

function handleError(){

}
function handleSuccess(){

}
 /* google login*/

    const handleLoginGoogle = (response) => {
        console.log(response);
        // Use the response.user object to access the user's profile information
    };

    /*login google*/
/*const handleLogin = () => {
    const googleLogin = new GoogleLogin({
      clientId: "999929467399-jvrn1dkp46q2d34mqn2ld91pvh9t8vv4",
    });

    googleLogin.login().then((credentials) => {
        console.log(credentials);
        console.log("te has logueado con google");
    });
  };
*/


    const handleClick = () => {
        setIsVisible(!isVisible); // Toggle visibility
    };

    const handleButtonClick = () => {

        const googleLoginButton = document.getElementById('my-google-login-button');
          console.log("fris ",googleLoginButton);
        if (googleLoginButton) {
            fireEvent.click(googleLoginButton); // Simulate click event
        } else {
            console.error('Google Login button not found');
        }

    };

    const handleGoogleLoginSuccess = (response) => {
        console.log('Login successful:', response);
        console.log('Login successful 2:', response.credential);
        setIdGoogle(response.credential);
        localStorage.setItem("redId", response.credential);
        get_codigo_login(response.credential,2);
        // Send the response.code (authorization code) to your backend
        // for server-side authentication and profile information retrieval
    };

    const handleGoogleLoginError = (error) => {
        console.error('Login failed:', error);
    };

    useEffect(() => {
        console.log("botono ",isVisible );

          //  googleLoginButtonRef.current.click(); // Simulate click on GoogleLogin button


    }, [isVisible]);


   return (
        <>
            <Alert></Alert>
            <div id="wrapper relative" style={containerStyle}>


            {/* <button onClick={handleLogin}>Iniciar sesión con Google</button> */}
            

                <div className='shadow-md w-full static top-0 left-0'>
                    <div className='flex items-center justify-center bg-white py-2 md:px-10 px-7'>
                            <img src={logo} width="70"
                                 alt="logo"/>
                    </div>
                </div>
                <div className="main">
                    <div className="hiper-color absolute bottom-0 w-full" style={containerStyle2}>
                        <div className="h-96 max-h-full">
                            <h1 className="text-4xl font-inter font-normal text-center text-white my-5">Ingresar</h1>
                            <form action="" className="flex justify-center m-0">
                                <div className="box-input">
                                    <div className="d-grid gap-2 mt-2 text-white">
                                        {/* registro_mobil */}
                                        <Link to="/registro_mobil" className='text-white'>
                                            <button
                                                className=" text-[#DC842D] bg-white  font-inter font-light  py-1 px-4 border border-white  rounded-lg w-full mt-2 flex items-center justify-between"

                                                type="button">
                                                <FontAwesomeIcon icon={faMobileAndroidAlt} style={{height: "25px"}} className="w-8"/>

                                                <span className="text-left">Con tu número de celular  </span>
                                                <span></span>

                                            </button>
                                        </Link>

                                        <button
                                            className=" hover:text-[#DC842D] text-[#DC842D] bg-white  font-inter font-light hover:bg-[#ffffff] py-1 px-4 border border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between"

                                            type="button">
                                            <FontAwesomeIcon icon={faFacebook} style={{height: "25px"}} className="w-8"/>
                                            <span className="text-left"> 
                                            
                                             <LoginSocialFacebook
                                             appId={process.env.REACT_APP_FB_APP_ID || '653327285310405'}
                                             onResolve={(response)=>{
                                                console.log("datos respuesta api facebook",response)
                                                setIdFacebook(response.data.id);
                                                localStorage.setItem("redId", response.data.id);
                                                get_codigo_login(response.data.id,1);
                                             }}
                                             onReject={ (error)=>{
                                                console.log(error)
                                             }}
                                             >
                                               Iniciar con Facebook
                                             </LoginSocialFacebook>
                                            </span> <span></span>
                                        </button>


                                     <div className="contenedor_google" >
                                         <button
                                             className=" hover:text-[#DC842D] text-[#DC842D] bg-white  font-inter font-light hover:bg-[#ffffff] py-1 px-4 border border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between"

                                             type="button"
                                             onClick={()=>handleButtonClick()}>

                                             <FontAwesomeIcon icon={faGoogle} style={{height: "25px"}} className="w-8"/>
                                             <span className="text-left">

                                                <div style={{BackgroundColor:"transparent", Border:"4px solid red"  }} className="boton_google">
                                             <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_APP_ID}  id="test" className="boton_google2">
                                                 <GoogleLogin
                                                     className="boton_google3"
                                                     id={"my-google-login-button"}
                                                     clientId={process.env.REACT_APP_GG_APP_ID}
                                                     buttonText="Sign in with Google"
                                                     texto="s"
                                                     onSuccess={handleGoogleLoginSuccess}
                                                     onError={handleGoogleLoginError}
                                                     style={{ border:"3px solid green"}}

                                                 />

                                             </GoogleOAuthProvider>
                                         </div>
                                        Iniciar con Google

                                            </span> <span></span>
                                         </button>


                                         {/*<div style={{BackgroundColor:"transparent", Border:"4px solid red"  }} className="boton_google">
                                             <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_APP_ID}  id="test">
                                                 <GoogleLogin
                                                     id={"my-google-login-button"}
                                                     clientId={process.env.REACT_APP_GG_APP_ID}
                                                     buttonText="Sign in with Google"
                                                     texto="s"
                                                     onSuccess={handleGoogleLoginSuccess}
                                                     onError={handleGoogleLoginError}

                                                 />

                                             </GoogleOAuthProvider>
                                         </div> */}
                                     </div>




                                        {/* <GoogleLogin
                                                clientId="381759824406-of4rfja7h0j3osstfs2kc7n7rl3rtgbp.apps.googleusercontent.com"
                                               
                                                buttonText="Login with Google"
                                                onSuccess={responseGoogle}
                                                onFailure={responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            /> */}
                                          
                                            
                                        {/* <button
                                            className=" hover:text-[#DC842D]  hover:bg-[#ffffff]  font-inter font-light  py-1 px-4 border border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between"
                                            type="button">

                                            <FontAwesomeIcon icon={faGoogle} style={{height: "28px"}}/>
                                            <span className="text-left">
                                           
                                       

                                            </span>
                                            <span>



                                            </span>
                                        </button> */}
                                        <hr className="mt-2"/>

                                        <ButtonText
                                            label='Continuar sin iniciar sesión'
                                            className="bg-hiperblue hover:bg-hiperblue_hover text-white"
                                            onClick={() => obtenerTokenAnonimo()}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = state => ({
    loading: state.Auth.loading,
    datoLogin: state.Auth.datoLogin,
    sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
    authenticated: state.Auth.authenticated,
    profile: state.Profile.profile,
    access_token: state.Auth.access_token,
    // isAuthenticated: state.Auth.isAuthenticated
})

export default connect(mapStateToProps, {
    get_anonimo_token,codigo_acceso_externo,get_token,info_session
})(Login)

// export default Login;

