import React, { useEffect} from 'react';
import {Link,useNavigate,useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping, faPlus} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import "../../assets/styles/responsive.scss"
import {get_lista_compra} from '../../redux/actions/shoppingList';
import Alert from "../Swalert";
import ButtonText from "../../widgets/button_text";


const PurchaseList = ({get_lista_compra, shopping_list,peticion_shopping_list,cambio_sucursal, sucursal}) => {
    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();
    useEffect(() => {
        if (shopping_list.length === 0 && sucursal && !peticion_shopping_list) {
            get_lista_compra(sucursal.IdSucursal)
        }else if (cambio_sucursal){
            get_lista_compra(sucursal.IdSucursal)
        }
    }, [shopping_list,cambio_sucursal]);

    function verifyList() {
        return shopping_list.length === 0 ? false : true;
    }

    function verdetalle(id){
        // navigate(`/detalle_lista_compra/${id}`);
        navigate_menu(`detalle_lista_compra/${id}`);
    }
    const new_lista= ()=>{
        navigate_menu('nueva_lista_compra');
        // navigate(`/nueva_lista_compra`);
    }
    async function navigate_menu(url_menu) {
        let param = await get_url();
        navigate(param + '/' + url_menu);
    }

    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/" + ciudadp + "/" + sucursalp);
        } else if (url_nav) {
            return ("/" + url_nav.url_parameters);
        }
    }
    return (
        <>

            <Alert></Alert>
            <div className='mb-4'>
                <div className="flex  mb-2 justify-start">
                    <h3 className="text-hiperblue text-xl">Mis listas de compras</h3>
                </div>

                <div className="flex w-100 gap-3 flex-wrap mb-4">

                    {verifyList() &&
                        shopping_list.map((item_shopping_list) =>
                            <div
                                className=" flex justify-between px-3 py-0.5 rounded-lg  border-2 text-white width_responsive cursor-pointer item_lista_compra_ellip"
                                style={{
                                    backgroundColor: item_shopping_list.Color,
                                    borderColor: item_shopping_list.Color
                                }}
                                key={item_shopping_list.Id}
                                onClick={()=>verdetalle(item_shopping_list.Id)}
                            >
                                <div className="cursor-pointer">
                                    <FontAwesomeIcon icon={faCartShopping}/>
                                    <Link className="text-white">
                                        <span className="ml-2"> {item_shopping_list.Descripcion} </span>
                                    </Link>
                                </div>
                            </div>
                        )

                    }

                </div>

                <div className='w-100'>
                    <ButtonText
                        label='Nueva lista'
                        principal={false}
                        className="bg-hiperorange text-white width_responsive hover:bg-hiperorange_hover"
                        onClick={() => new_lista()}
                    />

                    {/* <Link to={'/nueva_lista_compra'}
                          className='flex width_responsive justify-between text-[#DC842D] border-[#DC842D] border-2 flex items-center  p-1 px-1 rounded-lg '>
                        <FontAwesomeIcon icon={faPlus}
                                         className="cursor-pointer border-[#DC842D] border-2 text-[#DC842D] rounded w-[1.4rem] h-[1.4rem]"/>
                        <div style={{fontSize: '0.8rem'}}
                             className="items-center bg-white-500 text-[#DC842D] text-center ">
                            <span> Nueva lista </span>
                        </div>
                        <div></div>
                    </Link> */}
                </div>

            </div>


        </>
    );
};

const mapStateToProps = state => ({
    peticion_shopping_list: state.Shopping_list.peticion_shopping_list,
    shopping_list: state.Shopping_list.shopping_list,
    sucursal: state.BranchOffice.sucursal,
    cambio_sucursal: state.BranchOffice.cambio_sucursal
})

export default connect(mapStateToProps, {
    get_lista_compra
})(PurchaseList)

// export default PurchaseList;