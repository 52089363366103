import React from "react";
import '../assets/styles/radio.scss';
import classNames from "classnames";
const ItemOpcionPago = ({id, Descripcion,change_selec,valor}) => {
  
    const selec_op = (e) => {
        change_selec(id);
   };

    return (
        // <div className="py-0.5 px-2" key={"fs"+id}>
        //     <div className="d-flex justify-between py-1 px-2 ">
        //         <label  className="custom-radio-checkbox">
        //             <input className="custom-radio-checkbox__input"  
        //             checked={id===valor}
        //             value={id}
        //             type="radio"  name="pago_id"  onChange={ (e) => selec_op( e)} />
        //             <span className="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
        //             <span className="custom-radio-checkbox__text text_primario">{Descripcion}</span>
        //         </label>
        //     </div>
        // </div>
        <div className={classNames("py-1", "w-auto", "h-auto", "overflow-hidden","mb-2",
            {'bg-hiperorange border border-hiperorange text-white':id===valor},
            {'border border-black ':id!==valor}
            )} 
            key={"fs"+id}
            style={{borderRadius:'10px'}} 
        >

            <div className="d-flex justify-between py-1 px-2  " >
                <label  className="custom-radio-checkbox">
                    <input className="custom-radio-checkbox__input" value={id} type="radio"  checked={id===valor} name={`pago_id`}
                        onChange={ (e) => selec_op( e)}
                    />
                    <span className="custom-radio-checkbox__show custom-radio-checkbox__show--radio"></span>
                    <span className="custom-radio-checkbox__text">{Descripcion}</span>
                </label>
               
            </div>
        </div>
    );
};

export default ItemOpcionPago;