import React, {useState} from "react";
import {getpedido} from "../redux/actions/pedido";
import {update_usuario} from "../redux/actions/profile";
import {connect} from "react-redux";
import classNames from "classnames";

const ItemPedidoFinalizado = ({
                                  pedido,
                                  nropedido,
                                  metodoPago,
                                  item,
                                  fechaEntrega,
                                  sala,
                                  importeTotal,
                                  profile,
                                  update_usuario,
                                  getpedido,
                                  pedid
                              }) => {

    function formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <div
            className={classNames(
                {
                    'bg-[#e5e7eb]': true, 'bg-gray-300 px-2 py-2 rounded-md mb-4': true
                })}

            style={{minHeight: '5rem', height: 'auto'}}

        >
            <div className="flex justify-between w-100 px-2 text-hipergrisdark">
                <h6 className="text-gray-500">Pedido #{nropedido}</h6>
                <span className="text-gray-500">{fechaEntrega}</span>
                <div>

                </div>
            </div>


            <div className="flex justify-between bg-white  py-1 px-2 "
                 style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}>
                <div>
                    <p className="mb-0 text-sm text-hipergrisdark">{sala}  </p>
                    <p className="mb-0 text-sm text-hipergrisdark">{metodoPago}</p>
                </div>
                <div className="flex items-end">
                    <p className="mb-0 text-hipergrisdark">Bs. {formatPrice(importeTotal)}</p>
                </div>
            </div>
            <div className={classNames("py-1",
                {
                    "bg-white text-hipergrisdark": pedido.Estado.Id === 8,
                    "bg-hiperredcancelado text-white  ": pedido.Estado.Id === 9,
                })}>
                <p className="text-center mb-0">{pedido.Estado.Descripcion}</p>
            </div>

        </div>
    );
};
const mapStateToProps = state => ({
    profile: state.Profile.profile,

})
export default connect(mapStateToProps, {
    getpedido, update_usuario
})(ItemPedidoFinalizado)
// export default ItemPedido;