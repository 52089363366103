import React, {useEffect} from 'react';

import {Link,useParams} from "react-router-dom";
import Layout from "../../hocs/Layout";
import ItemPedido from "../../widgets/item_pedido";
import '../../../src/assets/styles/pedidoFinalizado.scss';
import {connect} from "react-redux";
import {
    clear_pedidos,
    list_pedidos,
    list_pedidos_finalizados,
    list_pedidos_paginados
} from "../../../src/redux/actions/pedido";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemPedidoFinalizado from "../../widgets/item_pedido_finalizado";
import LoaderSpinner from "../../widgets/loaderSpinner";
import Alert from "../../components/Swalert";
import sucursal from '../../components/modal/sucursal';
var page_finalizado = 0;
var page_pendiente = 0;
const MisPedidos = ({
                        list_pedidos,
                        clear_pedidos,
                        list_pedidos_finalizados,
                        pedidosfinalizados,
                        pedidosfinalizados_peticion,
                        pedidospendientes,
                        pedidospendientes_peticion,
                        list_pedidos_paginados
                    }) => {
            const {ciudadp, sucursalp} = useParams();
    useEffect(() => {
        llenar_data_pendiente()
        llenar_data_finalizado()
        return () => {
            clear_pedidos()
            page_finalizado = 0;
            page_pendiente = 0;
        };
    }, []);

    function llenar_data_pendiente() {
        setTimeout(() => {
            if (pedidospendientes_peticion) {
                let datos = {
                    Confirmado: false,
                    Cantidad: 5,
                }
                page_pendiente = page_pendiente + 1;
                list_pedidos_paginados(datos, page_pendiente);
            }
        }, 800);
    }

    function llenar_data_finalizado() {
        setTimeout(() => {
            if (pedidosfinalizados_peticion) {
                let datos = {
                    Confirmado: true,
                    Cantidad: 5,
                }
                page_finalizado = page_finalizado + 1;
                list_pedidos_paginados(datos, page_finalizado);
            }
    }, 800);
    }

    const refresh_pedido = async () => {
        await clear_pedidos();
        page_finalizado = 0;
        page_pendiente = 0;
        llenar_data_pendiente();
        llenar_data_finalizado();
    };


    return (
        <Layout>
            <Alert></Alert>
            <div className="fondo_imagen pt-12">
                <div className="container overflow-x-scroll ">
                    <div className=" w-full">
                        <h1 className=" text-3xl font-inter font-normal text-center text-hiperorange mb-4">
                            Mis pedidos </h1>
                        <div className="flex gap-4 container_cardPedF">
                            <div className="card2  h-[35rem] border border-hiperorange shadow rounded-xl">
                                <div className="bg-hiperorange rounded-t-xl h-[3rem] flex justify-center items-center">
                                    <h6 className="text-center text-white text-md mb-0">Pendientes</h6>
                                </div>
                                <div
                                    className="flex justify-between flex-column h-[calc(100%-3rem)] bg-white container_item">
                                    <InfiniteScroll
                                        height={"30rem"}
                                        dataLength={pedidospendientes.length}
                                        next={llenar_data_pendiente}
                                        hasMore={true}
                                        loader={
                                            pedidospendientes_peticion ?
                                                <LoaderSpinner bool={pedidospendientes_peticion}></LoaderSpinner>
                                                :
                                                <div className="flex w-full justify-center">
                                                    <p className="text-gray-400">No hay mas pedidos para mostrar</p>
                                                </div>
                                        }
                                    >
                                        {
                                            pedidospendientes.map((d, index) =>
                                                <Link to={'/'+ciudadp+'/'+sucursalp+"/pedido_detalle/" + d.NroPedido} key={index}
                                                      className="w-100">
                                                    <ItemPedido
                                                        item={index}
                                                        nropedido={d.NroPedido}
                                                        pedid={d}
                                                        fechaEntrega={d.FechaEntrega}
                                                        sala={d.Sala.Descripcion}
                                                        metodoPago={d.MetodoPago}
                                                        importeTotal={d.ImporteTotal}
                                                        refresh_pedido={refresh_pedido}
                                                    ></ItemPedido>
                                                </Link>
                                            )}
                                    </InfiniteScroll>
                                </div>
                            </div>
                            <div className="card2  h-[35rem] border border-hiperorange shadow rounded-xl">
                                <div className="bg-hiperorange rounded-t-xl h-[3rem] flex justify-center items-center">
                                    <h6 className="text-center text-white text-md mb-0">Finalizados</h6>
                                </div>
                                <div
                                    className="flex justify-between flex-column h-[calc(100%-3rem)] bg-white container_item">
                                    <InfiniteScroll
                                        height={"30rem"}
                                        dataLength={pedidosfinalizados.length}
                                        next={llenar_data_finalizado}
                                        hasMore={true}
                                        loader={
                                            pedidosfinalizados_peticion ?
                                                <LoaderSpinner bool={pedidosfinalizados_peticion}></LoaderSpinner>
                                                :
                                                <div className="flex w-full justify-center">
                                                    <p className="text-gray-400">No hay mas pedidos para mostrar</p>
                                                </div>
                                        }
                                    >
                                        {
                                            pedidosfinalizados.map((d, index) =>
                                                <Link to={'/'+ciudadp+'/'+sucursalp+"pedido_detalle/" + d.NroPedido} key={index}
                                                      className="w-100">
                                                    <ItemPedidoFinalizado
                                                        key={index}
                                                        pedido={d}
                                                        nropedido={d.NroPedido}
                                                        fechaEntrega={d.FechaEntrega}
                                                        sala={d.Sala.Descripcion}
                                                        metodoPago={d.MetodoPago}
                                                        importeTotal={d.ImporteTotal}
                                                    ></ItemPedidoFinalizado>
                                                </Link>
                                            )}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
};


const mapStateToProps = state => ({
    pedidospendientes: state.Pedido.pedidospendientes,
    pedidosfinalizados: state.Pedido.pedidosfinalizados,
    pedidosfinalizados_peticion: state.Pedido.pedidosfinalizados_peticion,
    pedidospendientes_peticion: state.Pedido.pedidospendientes_peticion,
})


export default connect(mapStateToProps, {
    list_pedidos, clear_pedidos, list_pedidos_finalizados, list_pedidos_paginados
})(MisPedidos)