import Layout from "../../hocs/Layout";
import React, {useEffect, useState} from "react";
// import ItemCarrito from "../../widgets/item_carrito";
// import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import ItemConfirmacionPedido from "../../widgets/item_confirmacion_pedido";
import ModalVale from "../../widgets/modal_vale";
import {connect} from "react-redux";
import {get_lista_carrito_compras} from "../../../src/redux/actions/carrito";
import {save_pedido} from "../../../src/redux/actions/carrito";
import {useNavigate} from "react-router-dom";
// import { get_ubicacion } from "../../redux/actions/location";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
// const ConfirmacionPedido = ({get_lista_carrito_compras,list_carrito,save_pedido}) => {
const ConfirmacionPedido = ({
                                get_lista_carrito_compras,
                                save_pedido,
                                respuesta_save_pedido,
                                respuesta_confirmar_carrito
                            }) => {
    const [token] = useState(localStorage.getItem('access_token'));
    const [listaVales, setListaVales] = useState([]);
    const [listaComentarios, setListaComentarios] = useState([]);
    const [list_carrito, setlist_carrito] = useState(JSON.parse(localStorage.getItem('mycart')));
    const navigate = useNavigate();
    useEffect(() => {
        async function fetchData() {
            try {


                //  const res= await get_lista_carrito_compras(token);

                //  console.log("carrito storage eee ",list_carrito);


            } catch (e) {
                console.error(e);
            }
        };
        fetchData();

    }, []);

    function existe_list_carrito() {
        return list_carrito != null ? true : false;
    }

    function getTipoEntrega() {
        return JSON.parse(localStorage.getItem("tipo_entrega_mycart"))

        /* var mycart_storage= JSON.parse(localStorage.getItem("mycart"));

         var index=mycart_storage.SubPedidos.map(item => item.IdMarket).indexOf(sucursal_id);

          if(index!==-1){
           return   mycart_storage.SubPedidos[index]["IdTipoEntrega"];
          }
          return -1;
          */
    }

    function get_ubicacion() {
        var mycart_storage = JSON.parse(localStorage.getItem("datos_personales"));
        if (typeof (mycart_storage) != 'undefined' && mycart_storage != null) {
            return mycart_storage.direccion;
        }
        return null;
    }

    function get_horario(sucursal_id) {
        var horario = JSON.parse(localStorage.getItem("horario"));
        var index = horario.map(item => item.IdSucursal).indexOf(sucursal_id);
        //  console.log("esete es el index horario ", horario);
        if (index != -1) {
            return horario[index];
        }
        return index;

    }

    function get_datos_pago() {
        return JSON.parse(localStorage.getItem("metodo_pago"));


    }


    function existe_val(val) {
        return typeof (val.vale) != 'undefined';
    }

    function get_productos(sucursal_id) {
        var aux = [];
        list_carrito.SubPedidos.forEach((item) => {
            if (item.IdMarket == sucursal_id) {
                item.Productos.forEach((pro) => {
                    aux.push({
                        IdProducto: pro.IdProducto,
                        Cantidad: pro.Cantidad,
                        Pesos: null
                    })
                });
            }
        })

        return aux;
    }

    const setComentario = (idsucursal, texto) => {
        console.log("add comentario " + idsucursal + "vale " + texto);
        var aux = listaComentarios.slice();

        let index = aux.map(item => item.idsucursal).indexOf(idsucursal);

        if (index === -1) {
            aux.push({idsucursal: idsucursal, comentario: texto});
        } else {

            aux[index] = {idsucursal: idsucursal, comentario: texto};
        }


        setListaComentarios(aux);


    }
    const save_pedidos = (e) => {

        // console.log("vales ",listaVales);

        list_carrito.SubPedidos.forEach((item) => {

            //var tipoentrega=   getTipoEntrega(item.Sala.Id) ;
            var tipoentrega = getTipoEntrega();
            var ubicacion = get_ubicacion();
            var horario = get_horario(item.Sala.Id);
            var datospago = get_datos_pago();
            var productos = get_productos(item.Sala.Id)


            if (horario == -1) {
                console.log("no se encontro el horario");
                return;
            }
            if (ubicacion == null) {
                console.log("no se encontro la ubicacion");
                return;
            }
            if (tipoentrega == -1) {
                console.log("no se encontro el tipo de entrega");
                return;
            }
            if (datospago == null) {
                console.log("no se encontro el metodo de pago");
                return;
            }

            var objeto = {

                IdSucursal: item.Sala.Id,
                IdTipoEntrega: tipoentrega,
                IdUbicacion: parseInt(ubicacion),
                Horario: {
                    IdFecha: horario["seleccionado_id"],
                    IdHorario: horario["horarioId"],
                    IdHorarioEspecial: horario["IdHorarioEspecial"]
                },
                DatosPago: {
                    IdMetodoPago: datospago["IdMetodoPago"],
                    Nit: datospago["Nit"],
                    RazonSocial: datospago["RazonSocial"]
                },
                Productos: productos

            }


            /* var objeto=   {
               IdSucursal:  item.Sala.Id,
               IdTipoEntrega: tipoentrega,
               IdUbicacion: parseInt(ubicacion),
               Horario: {
                   IdSucursal: item.Sala.Id,
                   IdFecha:horario["seleccionado_id"],
                   IdHorario: horario["horarioId"],
                   IdHorarioEspecial: horario["IdHorarioEspecial"],
                   CostoDelivery: "Bs. 0"
               },
               NotaPedido: "prueba nota del pedido",
               DatosPago: {
                   IdMetodoPago:  datospago["IdMetodoPago"],
                   Nit: datospago["Nit"],
                   RazonSocial:  datospago["RazonSocial"]
               },
              Productos:productos
           }
           */

            //console.log("este es el param   ",objeto);
            var ele = listaVales.find(ele => ele.idsucursal === item.Sala.Id);
            var aux_list = [];
            if (typeof (ele) != 'undefined') {
                aux_list = get_new_formato_lista_vales(ele.vale);

                //       console.log("new formato ",aux_list);
            }


            /*  const datosAux=
               {SubpedidosConfirmado:[
                    {IdSubPedido:item.IdPedido,
                    NotaPedido: getComentario(item.Sala.Id),
                    ListaVale: aux_list,

                    }],
                    SubPedidos:[],
                };


   */

            /*   var  datosAux=  {"SubpedidosConfirmado":
                                        [{"IdSubPedido":item.IdPedido,
                                           "NotaPedido":getComentario(item.Sala.Id),
                                          "ListaVale":aux_list


                                          }

                                          ],
                                      "SubPedidos":[],
                                      }
                                      */
            var datosAux = {
                SubpedidosConfirmado:
                    [{
                        IdSubPedido: item.IdPedido,
                        NotaPedido: getComentario(item.Sala.Id),
                        ListaVale: aux_list
                    }
                    ],
                SubPedidos: [],
            };
            var IdPedidoPrincipal = get_IdPedidoPrincipal();

            // console.log(" IdPedidoPrincipal = ",IdPedidoPrincipal);
            /*        const  datosAux  =   {
                        IdPedidoPrincipal:IdPedidoPrincipal ,
                        // SubpedidosConfirmado: [
                        //     ],
                        SubPedidos:[{
                            IdSubPedido:""+item.IdPedido+"",
                            NotaPedido: getComentario(item.Sala.Id),
                            ListaVale:aux_list,
                        }]
                    }
             */

            //   console.log("datos vales ",datosAux);

            save_pedido(token, objeto, datosAux);
            console.log("respuesta 1...", respuesta_save_pedido);
            console.log("respuesta 2...", respuesta_confirmar_carrito);


        })

        //  navigate("/pedido_realizado");
    };

    const mostrarModal = (idsucursal) => {
        console.log("show modal ", idsucursal);
        cerrar_modal(idsucursal);
    };

    function get_IdPedidoPrincipal() {
        var carritox = JSON.parse(localStorage.getItem("mycart"));
        return carritox.IdPedidoPrincipal;
    }

    const delete_item_val_sucursal = (idsucursal, index, e) => {
        console.log("show modal ", idsucursal, " index ", index);
        var aux = listaVales.slice();
        let i = aux.map(item => item.idsucursal).indexOf(idsucursal);
        var auxlistaval = aux[i].vale;
        auxlistaval.splice(index, 1);
        aux[i] = {idsucursal: idsucursal, vale: auxlistaval};
        setListaVales(aux);
        //   cerrar_modal(idsucursal);
    };

    function get_new_formato_lista_vales(vales) {
        var aux = [];
        for (var item of vales) {
            aux.push({Codigo: "", Monto: item});
        }
        return aux;
    }

    function getComentario(sucursal_id) {
        var comentario = "";
        var aux = listaComentarios.find(ele => ele.idsucursal === sucursal_id);
        if (typeof (aux) != 'undefined') {
            return aux.comentario;
        }
        return comentario;
    }

    function showModal_vale(idsucursal) {
        console.log("show modal ", idsucursal);
        cerrar_modal(idsucursal);
    }

    function addVale(idsucursal, vale) {
        console.log("add vale " + idsucursal + "vale " + vale);
        var aux = listaVales.slice();

        let index = aux.map(item => item.idsucursal).indexOf(idsucursal);

        if (index === -1) {
            aux.push({idsucursal: idsucursal, vale: [vale]});
        } else {
            var auxlistaval = aux[index].vale;
            auxlistaval.push(vale);
            aux[index] = {idsucursal: idsucursal, vale: auxlistaval};
        }


        setListaVales(aux);
        cerrar_modal(idsucursal);
    }

    function cerrar_modal(idsucursal) {
        document.getElementById('modal' + idsucursal).classList.toggle('hidden');
    }

    function get_monto_importe(sucursal_id) {
        var total = 0;
        const objetoClonado = Object.assign({}, list_carrito);
        var subpedidosaux = [];
        for (let item of objetoClonado.SubPedidos) {
            var ob = item;
            if (item.IdMarket == sucursal_id) {
                var productoaux = [];
                for (let prod of item.Productos) {

                    var importe = prod.Cantidad * prod.PrecioVenta;
                    total += importe
                }
            }
            subpedidosaux.push(ob);
        }
        return total;
    }

    return (
        <Layout>

            <div className="flex justify-center items-center mt-12">
                <div className="px-2">
                    <h2 className="text-center mb-4 text-hiperorange">Confirmación de pedido</h2>


                    {existe_list_carrito() ?


                        list_carrito.SubPedidos.map((item, indexItem) =>
                            <>
                                {/* <div className="sm:w-100 lg:w-[33rem]  min-h-1200  h-[29rem] border border-hiperorange shadow rounded-xl mb-2"> */}
                                <div
                                    className="sm:w-100 lg:w-[33rem]  min-h-1200  h-auto border border-hiperorange shadow rounded-xl mb-2">
                                    <div
                                        className="bg-white shadow rounded-t-xl text-hiperblue lg:mb-3 text-sm flex items-center h-[3rem] px-2">
                                        <div className="flex">
                                            <h6 className="mb-0"><span
                                                className="text-hiperorange">Super Mercado </span>- Hipermaxi
                                                {item.Sala.Descripcion}</h6>
                                        </div>
                                    </div>
                                    <div className="flex justify-between flex-column h-[calc(100%-3rem)]"
                                         key={indexItem}>
                                        <div className=" h-[calc(100%-3rem)] overflow-y-auto">
                                            {
                                                item.Productos.map((producto, index2) =>
                                                    <>
                                                        <ItemConfirmacionPedido
                                                            colour={(index2 % 2 == 0)}
                                                            idProducto={producto.IdProducto}
                                                            key={producto.IdProducto}
                                                            descripcion={producto.Descripcion}
                                                            cantidad={producto.Cantidad}
                                                            precioVenta={producto.PrecioVenta}
                                                            moneda={producto.Moneda}
                                                            importe={producto.Importe}
                                                            imagen={producto.UrlFoto}
                                                            IdMarket={item.IdMarket}
                                                            unidadMedida={producto.UnidadMedida}

                                                        >
                                                        </ItemConfirmacionPedido>
                                                    </>
                                                )
                                            }

                                            {/* <ItemConfirmacionPedido colour={false}></ItemConfirmacionPedido>
                                <ItemConfirmacionPedido colour={true}></ItemConfirmacionPedido>
                                <ItemConfirmacionPedido colour={false}></ItemConfirmacionPedido> */}
                                        </div>
                                        <div className=" border-t h-auto border-hiperorange text-gray-600 px-2">
                                            <div className="row h-auto">
                                                <div className="col-sm-7">

                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="lg:mt-4 text-center font-bold">
                                                        <label htmlFor="" className="mr-2">Total parcial:
                                                            Bs. &nbsp; {get_monto_importe(item.Sala.Id)} </label>
                                                        <label htmlFor="" className="mr-2">Costo Envio: Bs. &nbsp;

                                                            {item.CostoEnvio == "" ? <>
                                                                Gratis
                                                            </> : <> {item.CostoEnvio} </>}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="my-2">
                                                        <label htmlFor="">Comentario sobre tu pedido (opcional):</label>
                                                        <textarea
                                                            className="shadow appearance-none border border-hiperorange rounded-lg w-full px-3 py-0.5 text-gray-700 leading-tight focus:outline-none
                                        focus:border-hiperorange focus:ring-1"
                                                            id="comentario" type="text"
                                                            onChange={e => setComentario(item.Sala.Id, e.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className=" my-3 float-right" key="vale">

                                                <label className="px-3   cursor-pointer  text-hiperblue  "
                                                       onClick={(e) => showModal_vale(item.Sala.Id, e)}
                                                >
                                                    Agregar Vale hipermaxi
                                                </label>

                                            </div>

                                            <div className="row my-4 w-100 h-100 border-black">

                                                {
                                                    listaVales.map((val) =>


                                                        <div className="w-100 h-auto text-hiperred">
                                                            {val.idsucursal === item.Sala.Id ?

                                                                <>
                                                                    {
                                                                        existe_val(val) ?
                                                                            <>
                                                                                {
                                                                                    val.vale.map((itemvale, indexval) =>
                                                                                        <div
                                                                                            className="flex  flex-wrap text-hiperred">
                                                                                            <div><FontAwesomeIcon
                                                                                                icon={faTimesCircle}
                                                                                                className="mr-1 text-hiperred cursor-pointer"
                                                                                                onClick={(e) => delete_item_val_sucursal(item.Sala.Id, indexval, e)}/>
                                                                                            </div>
                                                                                            <div
                                                                                                className="text-hiperdark">Vale
                                                                                                Nro. &nbsp; {indexval + 1} &nbsp; por
                                                                                                importe de
                                                                                                Bs. &nbsp; {itemvale} </div>

                                                                                        </div>
                                                                                    )


                                                                                }


                                                                            </> : <></>

                                                                    }

                                                                </>


                                                                : <></>
                                                            }
                                                        </div>
                                                    )

                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <ModalVale
                                        showModal={mostrarModal}
                                        agregar_vale={addVale}
                                        idsucursal={item.Sala.Id}
                                    >

                                    </ModalVale>
                                </div>


                            </>
                        )
                        : <></>


                    }


                    <div className="card-footer d-flex justify-between pt-2 pb-5">
                        <Link to="/forma_pago">
                            <button
                                className="bg-hiperblue px-3 border text-white rounded-lg">
                                Volver
                            </button>
                        </Link>
                        {/* <Link to="/pedido_realizado">
                            <button
                                  onClick={ (e) => save_pedidos( e)}
                                className="bg-green-500 px-3 border  text-white rounded-lg">Confirmar Pedido
                            </button>
                        </Link> */}
                        <button
                            onClick={(e) => save_pedidos(e)}
                            className="bg-green-500 px-3 border  text-white rounded-lg">Confirmar Pedido
                        </button>
                    </div>
                </div>
            </div>


        </Layout>
    );
};


const mapStateToProps = state => ({

    // list_carrito: state.Carrito.list_carrito,
    respuesta_save_pedido: state.Carrito.respuesta_save_pedido,
    respuesta_confirmar_carrito: state.Carrito.respuesta_confirmar_carrito

})


export default connect(mapStateToProps, {
    get_lista_carrito_compras, save_pedido
})(ConfirmacionPedido)