import {
    GET_BRANCH_OFFICE_SUCCESS,
    GET_BRANCH_OFFICE_FAIL,
    SET_BRANCH_OFFICE_SUCCESS,
    SET_DELIVERY_TYPE_SUCCESS,
    GET_BRANCH_OFFICE_CERCA_SUCCESS,
    GET_CIUDADES_SUCCESS,
    SET_CIUDAD_SUCCESS,
    CLEAR_BRANCH_OFFICE,
    SET_CAMBIO_SUCURSAL

} from '../actions/types';
import {isPointInPolygon} from "geolib";

const initialState = {
    branch_offices: [],
    sucursales: [],
    ciudades: [],
    sucursal: JSON.parse(localStorage.getItem('sucursal')),
    tipo_entrega: JSON.parse(localStorage.getItem('tipo_entrega')),
    loading: false,
    cambio_sucursal: true,
    ciudad: null,
};

export default function BranchOffice(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case CLEAR_BRANCH_OFFICE:
            return {
                ...state,
                branch_offices: [],
                sucursales: [],
                ciudades: [],
                sucursal: null,
                tipo_entrega: null,
            }
        case GET_BRANCH_OFFICE_SUCCESS:
            return {
                ...state,
                branch_offices: payload.Dato,
                sucursales: payload.Dato
            }

        case GET_BRANCH_OFFICE_CERCA_SUCCESS:
            return {
                ...state,
                branch_offices: payload.res.Dato,
                sucursales: onloadSucursales(payload.res.Dato, payload.direccion),
            }

        case GET_BRANCH_OFFICE_FAIL:
            return {
                ...state
            }
        case SET_BRANCH_OFFICE_SUCCESS:
            updateStorageSucursal(payload);
            return {
                ...state,
                sucursal: payload
            }
        case SET_CIUDAD_SUCCESS:
            return {
                ...state,
                ciudad: payload
            }

        case GET_CIUDADES_SUCCESS:
            return {
                ...state,
                ciudades: payload.Dato
            }
        case SET_DELIVERY_TYPE_SUCCESS:
            deleteStorageTypeDelivery();
            updateStorage(payload)
            return {
                ...state,
                tipo_entrega: payload
            }
        case SET_CAMBIO_SUCURSAL:
            return {
                ...state,
                cambio_sucursal: payload
            }


        // case DELETE_TIPO_ENTREGA:


        default:
            return state
    }
}

function onloadSucursales(dato, direccion) {
    let arrayDato = dato.length;

    let array_aux = [];
    if (direccion == null || Object.keys(direccion).length === 0 || typeof (direccion) == 'undefined') {
        return array_aux;
    }
    if (arrayDato > 0) {
        dato.forEach((sucursalz) => {
            let array_location = [];
            let locatarios = sucursalz.Locatarios;
            locatarios.forEach((sucursal) => {
                let array_zona_cobertura = sucursal.ZonasCobertura;
                array_zona_cobertura.forEach((zona) => {
                    const points = zona.PuntosCobertura.map((e) => {
                        return {
                            latitude: Number(e.Latitud),
                            longitude: Number(e.Longitud),
                        };
                    });
                    let coberturaSucursal = isPointInPolygon(
                        {
                            latitude: Number(direccion.Latitud),
                            longitude: Number(direccion.Longitud)
                        }, points)
                    if (coberturaSucursal) {
                        array_location.push(sucursal);
                    }
                });
            })
            if (array_location.length > 0) {
                sucursalz.Locatarios = array_location;

                /*   let nuevo_array = array_aux.map((e) => {
                       let sucursal_latlng = {
                           latitud: e.UbicacionLatitud,
                           longitud: e.UbicacionLongitud,
                       };
                       let direccion_latlng = {
                           latitud: direccion.Latitud,
                           longitud: direccion.Longitud,
                       }
                       return {
                           ...e,
                           distancia: calcularDistanciaEntreDosCoordenadas(sucursal_latlng.latitud, sucursal_latlng.longitud, direccion_latlng.latitud, direccion_latlng.longitud)
                       }
                   })
                   console.log("suc ",nuevo_array)
                   */
                // console.log("sucursales ",sucursalz);
                sucursalz.distancia = calcularDistanciaEntreDosCoordenadas(sucursalz.UbicacionLatitud, sucursalz.UbicacionLongitud, direccion.Latitud, direccion.Longitud);
                array_aux.push(sucursalz);
            }

            // let array_zona_cobertura = sucursalz.ZonasCobertura;
            // array_zona_cobertura.forEach((zona) => {
            //     const points = zona.PuntosCobertura.map((e) => {
            //         return {
            //             latitude: e.Latitud,
            //             longitude: e.Longitud,
            //         };
            //     });
            //     let coberturaSucursal = null;
            //     if (direccion !== null) {
            //         coberturaSucursal = isPointInPolygon(
            //             {
            //                 latitude: direccion.Latitud,
            //                 longitude: direccion.Longitud
            //             }, points)
            //     }
            //
            //
            //     if (coberturaSucursal) {
            //         array_aux.push(sucursalz);
            //     }
            // });

        });
        if (array_aux.length === 0) {
            array_aux = dato;
        }
    }
    array_aux.sort(function (a, b) {
        if (Number(a.distancia) > Number(b.distancia)) {
            return 1;
        }
        if (Number(a.distancia) < Number(b.distancia)) {
            return -1;
        }
        return 0;
    });
    return array_aux;
}

const calcularDistanciaEntreDosCoordenadas = (lat1, lon1, lat2, lon2) => {
    // Convertir todas las coordenadas a radianes
    lat1 = gradosARadianes(lat1);
    lon1 = gradosARadianes(lon1);
    lat2 = gradosARadianes(lat2);
    lon2 = gradosARadianes(lon2);
    // Aplicar fórmula
    const RADIO_TIERRA_EN_KILOMETROS = 6371;
    let diferenciaEntreLongitudes = (lon2 - lon1);
    let diferenciaEntreLatitudes = (lat2 - lat1);
    let a = Math.pow(Math.sin(diferenciaEntreLatitudes / 2.0), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(diferenciaEntreLongitudes / 2.0), 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (RADIO_TIERRA_EN_KILOMETROS * c).toFixed(2);
};
const gradosARadianes = (grados) => {
    return grados * Math.PI / 180;
};

function updateStorageSucursal(sucursal) {
    localStorage.setItem("sucursal", JSON.stringify(sucursal));
}


function deleteStorageTypeDelivery() {
    localStorage.removeItem("tipo_entrega");
}

function updateStorage(tipo_entrega) {
    console.log("tipo_entrega",tipo_entrega)
    localStorage.setItem("tipo_entrega", JSON.stringify(tipo_entrega));
}