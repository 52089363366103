import {
    ENABLE_PETITION,
    DISABLE_PETITION
} from './types';

export const start_peticion = (message) => dispatch => {
    dispatch({
        type: ENABLE_PETITION,
        payload: { message }
    });
}
export const end_peticion = () => dispatch => {
    dispatch({
        type: DISABLE_PETITION,
    });
}



