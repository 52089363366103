// import the library
import {library} from '@fortawesome/fontawesome-svg-core';

// import your icons

import {faCode, faHighlighter} from '@fortawesome/free-solid-svg-icons';


import {
    faGithubAlt,
    faGoogle,
    faFacebook,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';


/**entero brands**/
import {fab} from '@fortawesome/free-brands-svg-icons';
//
//
// library.add(fab);
/**end entero**/

library.add(
    faCode,
    faHighlighter,
    faGithubAlt,
    faGoogle,
    faFacebook,
    faTwitter

);




//Importar el mismo icono desde múltiples estilos
// import { faBoxingGlove } from '@fortawesome/pro-light-svg-icons';
// import {
//     faBoxingGlove as faBoxingGloveRegular
// } from '@fortawesome/pro-regular-svg-icons';
// import {
//     faBoxingGlove as faBoxingGloveSolid
// } from '@fortawesome/pro-solid-svg-icons';
// library.add(
//     faBoxingGlove,
//     faBoxingGloveRegular,
//     faBoxingGloveSolid
// );