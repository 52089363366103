import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import Favorito from "./favoritoMycart";
import {
    actualizar_item_carrito,
    add_al_carrito,
    dec_al_carrito,
    inc_al_carrito,
    remove_al_carrito
} from "../redux/actions/carrito";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faHeart} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import ModalPeso from "../components/modal/modal_peso"
import convertSlug from "../components/Utils/slug";

import "../assets/styles/globalColorPrincipal.css"
import "../assets/styles/responsive.scss"
import ButtonText from "./button_text";
import Modal from "react-modal";
import RegistroInit from "../components/modal/registroUser/registro_init";

const style_favorito = {
    overlay: {
        backgroundColor: '#000000a6',
        zIndex: '31',
    },
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        padding: '0',
        borderRadius: '10px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'auto',
        border:'none',
        background:'transparent'
    },
};

function ItemProducto({
                          actualizar_item_carrito,
                          producto,
                          producto_db,
                          carrito,
                          add_al_carrito,
                          inc_al_carrito,
                          dec_al_carrito,
                          remove_al_carrito,
                          totalQuantities,
                          ps_IdSucursal,
                          profile
                      }) {
    const navigate = useNavigate();
    const {ciudadp, sucursalp} = useParams();
    const [cantidad, setCantidad] = useState(0);
    const [pesos, guardarPesos] = useState([]);
    const [estado_modal, setEstadoModal] = useState(false);
    const [modal_favorito, setModalFavorito] = useState(false);
    const [enListadoCliente, setEnListadoCliente] = useState(producto.EnListadoCliente);

    const [modal_peso, setModalPeso] = useState(false);
    const openModalPeso = () => setModalPeso(true);
    const closeModalPeso = () => setModalPeso(false);

    const [openModalUserRegister, setOpenModalUserRegister] = useState(false);
    const ModalUserRegister = () => setOpenModalUserRegister(true);


    const openModalFavorito = () => {
        if (_es_pieza()) {
            let producto_carrito = carrito.find((prd) => prd.IdProducto === producto.IdProducto);
            if (producto_carrito) {
                guardarPesos(producto_carrito.Pesos)
            }
        }
        setModalFavorito(true);
    }
    const closeModalFavorito = () => {
        guardarPesos([])
        setModalFavorito(false);
    }

    useEffect(() => {
        if (producto_db) {
            if (producto_db.IdProducto === producto.IdProducto) {
                setEnListadoCliente(producto_db.EnListadoCliente);
            }
        }

    }, [producto_db]);
    useEffect(() => {
        carrito.forEach((item) => {
            if (item.IdProducto === producto.IdProducto) {
                setCantidad(item.cantidad);
            }
        });

    }, []);

    useEffect(() => {
        let findPro = carrito.find((item) => item.IdProducto === producto.IdProducto)
        typeof findPro == 'object' ? setCantidad(findPro.Cantidad) : setCantidad(0);
    }, [totalQuantities]);
    useEffect(() => {
        let findPro = carrito.find((item) => item.IdProducto === producto.IdProducto)
        typeof findPro == 'object' ? setCantidad(findPro.Cantidad) : setCantidad(0);
    }, [carrito]);


    function decrement_carrito() {
        if (_es_pieza()) {
            navigate(`/${convertSlug.data_barra(ciudadp + ' ' + sucursalp)}/producto/${producto.IdProducto}/${convertSlug.data_slug(producto.Descripcion)}`);
        } else if (_es_peso()) {
            set_peso('decremento');
        } else {
            normal('decremento');
        }
    }

    function incremento_carrito() {
        if (_es_pieza()) {
            navigate(`/${convertSlug.data_barra(ciudadp + ' ' + sucursalp)}/producto/${producto.IdProducto}/${convertSlug.data_slug(producto.Descripcion)}`);
        } else if (_es_peso()) {
            set_peso('incremento');
        } else {
            normal('incremento');
        }
    }

    function normal(operacion) {
        if (operacion === 'incremento') {
            inc_al_carrito(producto)
        } else if (cantidad === 1) {
            remove_al_carrito(producto);
        } else {
            dec_al_carrito(producto);
        }
    }

    function set_peso(operacion) {
        let resultado = 0;
        let valor = 0.1;
        console.log(cantidad,valor)
        resultado = operacion === 'incremento' ? Number((cantidad + valor).toFixed(1)) : Number((cantidad - valor).toFixed(1));
        if (operacion === 'incremento' && cantidad === 0) {
            setCantidad(resultado);
            add_al_carrito(producto, resultado);
        } else if (operacion === 'decremento' && cantidad === valor) {
            setCantidad(resultado);
            remove_al_carrito(producto);
        } else {
            setCantidad(resultado);
            actualizar_item_carrito(producto, resultado);
        }
    }


    function anadir_al_carrito() {
        if (_es_pieza()) {
            navigate(`/${convertSlug.data_barra(ciudadp + ' ' + sucursalp)}/producto/${producto.IdProducto}/${convertSlug.data_slug(producto.Descripcion)}`);
        }else if (_es_peso()) {
            add_al_carrito(producto, 0.1);
        } else {
            add_al_carrito(producto, 1);
        }
    }

    function _es_pieza() {
        if (producto) {
            return producto.Fraccionado && producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }

    function _es_peso() {
        if (producto) {
            return producto.Fraccionado && !producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }


    function verify_es_peso() {
        if (producto) {
            return producto.Fraccionado && !producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }

    function redondeo(num, dec) {
        return Number(num.toFixed(dec));
    }

    function abrirModalPeso(producto) {
        setEstadoModal(true);
        openModalPeso();
    }

    function operacion_peso(operacion) {
        let resultado = 0;
        let valor = 0.1;
        let procesoResult = operacion === 'incremento' ? cantidad + valor : cantidad - valor;
        resultado = redondeo((procesoResult), 1);

        if (operacion === 'incremento' && cantidad === 0) {
            setCantidad(resultado);
            add_al_carrito(producto, resultado);
        } else if (operacion === 'decremento' && cantidad === valor) {
            setCantidad(resultado);
            remove_al_carrito(producto);
        } else {
            setCantidad(resultado);
            actualizar_item_carrito(producto, resultado);
        }
    }

    function set_peso_pieza(peso) {
        let procesoResult = peso.peso / 1000;
        let resultado = redondeo((procesoResult), 1);

        setCantidad(resultado);
        actualizar_item_carrito(producto, resultado);
    }

    const fueraFoco = () => {

    }

    const verifyUserAutenticate = () => {
        if (profile.Telefono !== "") {
            return true;
        }
        return false;
    }

    function formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    const cerrarVentana = (valor) => {
       // console.log("llego valor cerrar ventana ",valor)
        setOpenModalUserRegister(false);
    }

    async function navigate_menu(url_menu) {
        let param = await get_url();
        navigate(param + '/' + url_menu);

    }

    const get_url = async () => {
        let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
        if (ciudadp && sucursalp) {
            return ("/" + ciudadp + "/" + sucursalp);
        } else if (url_nav) {
            return ("/" + url_nav.url_parameters);
        }
    }

    return (
        <>
            <article
                className="w-[165px] h-[260px] bg-white border shadow flex flex-col overflow-visible rounded-xl  relative">
                <div className="group">
                    <div
                        className="relative overflow-visible  mx-auto transition-all duration-300 relative">
                        {/* <Link
                            to={`/${convertSlug.data_barra(ciudadp + ' ' + sucursalp)}/producto/${producto.IdProducto}/${convertSlug.data_slug(producto.Descripcion)}`}> */}
                            <div onClick={()=>navigate_menu(`producto/${producto.IdProducto}/${convertSlug.data_slug(producto.Descripcion)}`)}
                                className="flex justify-center px-1 pt-2 transition duration-200 ease-in-out transform group-hover:scale-105 contenedor_image cursor-pointer">
                                <img src={producto.UrlFoto + '&size=120x120'} alt="" className="object-contain w-32"/>
                            </div>
                        {/* </Link> */}
                        <div className="absolute top-0 right-0 inline">
                            {verifyUserAutenticate() ? (
                                <button className="absolute right-2 top-2" onClick={() => openModalFavorito()}>
                                    {
                                        enListadoCliente ?
                                            <FontAwesomeIcon icon={faHeart} className="text-hiperorange w-7 h-7"/>
                                            : <FontAwesomeIcon icon={faHeart}
                                                               className="hipergrisdark text-hipergrisdark w-7 h-7"/>

                                    }
                                </button>
                            ) : (
                                <button className="absolute right-2 top-2" onClick={() => ModalUserRegister()}>
                                    {
                                        producto.EnListadoCliente ?
                                            <FontAwesomeIcon icon={faHeart} className="text-hiperorange w-7 h-7"/>
                                            : <FontAwesomeIcon icon={faHeart}
                                                               className="hipergrisdark text-hipergrisdark w-7 h-7"/>
                                    }
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-col px-3 md:px-4 lg:px-[18px] pb-5 lg:pb-6 lg:pt-1.5 h-full divide-y" */}
                <div className="flex flex-col px-3 divide-y"
                     style={{height: '100%'}}
            >
                    <div className="descripcion_producto" >
                        <Link
                            to={`/${convertSlug.data_barra(ciudadp + ' ' + sucursalp)}/producto/${producto.IdProducto}/${convertSlug.data_slug(producto.Descripcion)}`}>
                            <h6 className="text-[10px] mb-0 text-zinc-400">{producto.Descripcion}</h6>
                        </Link>
                        {/*<p className="text-xs mb-[10px]">Nescafé Tradición tarro 400g</p>*/}
                    </div>
                    <div className="flex flex-col items-end flex-wrap justify-center" style={{height: '100%'}}>
                        {producto.ConOferta ?
                            <div className="flex justify-between w-100 py-1">
                                <del
                                    className="text-zinc-400 text-sm">{producto.Moneda} {formatPrice(producto.PrecioOriginal)}</del>
                                <span
                                    className="text_tercero font-bold text-sm "
                                    style={{fontSize: '1rem'}}> {producto.Moneda} {formatPrice(producto.PrecioOferta)} </span>
                            </div>
                            :
                            <div className="flex justify-end w-100 py-1">
                            <span
                                className="text_tercero font-bold text-sm"
                                style={{fontSize: '1rem'}}> {producto.Moneda} {formatPrice(producto.PrecioVenta)} </span>
                            </div>
                        }
                        <div className="w-100 text-white">

                            {
                                cantidad === 0 ?
                                    <ButtonText
                                        label='Agregar'
                                        className="bg-hiperorange py-1 hover:bg-[#c1601e]"
                                        onClick={() => anadir_al_carrito()}
                                    />
                                    :
                                    <div className="flex-auto text-white flex">
                                        <div
                                            className="flex-auto  justify-center  gap-1 text-white flex   rounded-2xl"
                                        >


                                            <button className="flex h-6 w-6 p-2 bg_primario rounded text-white self-center justify-center items-center "
                                                    onClick={() => decrement_carrito()}
                                            >
                                                <FontAwesomeIcon icon={faMinus}
                                                                 className="text-white text-xs"/>
                                            </button>

                                            {
                                                verify_es_peso() ?
                                                    <div className="text-red-600">
                                                        <button className="flex h-6 p-2 bg_primario rounded text-white
                                        self-center justify-center items-center"
                                                                onClick={() => abrirModalPeso(producto)}>
                                                            {redondeo((cantidad), 2)} kg
                                                        </button>
                                                    </div>
                                                    :
                                                    <div className="self-center">
                                                        <div className="flex h-6 p-2 bg-hipergris rounded self-center justify-center items-center text-black">
                                                            {cantidad} Und
                                                        </div>
                                                    </div>
                                            }

                                            <button className="flex h-6 w-6 p-2 bg_primario rounded  text-white self-center
                                        justify-center items-center"
                                                    onClick={() => incremento_carrito()}
                                            >
                                                <FontAwesomeIcon icon={faPlus}
                                                                 className="text-white text-xs"/>
                                            </button>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </article>

            {estado_modal &&
                <ModalPeso
                    ps_modal_peso={modal_peso}
                    ps_closeModalPeso={closeModalPeso}
                    ps_objectProducto={0}
                    ps_operacion_peso={operacion_peso}
                    ps_cantidad={cantidad}
                    ps_set_peso_pieza={set_peso_pieza}
                ></ModalPeso>
            }
            <Modal
                isOpen={modal_favorito}
                ariaHideApp={false}
                onRequestClose={closeModalFavorito}
                style={style_favorito}
                contentLabel="favorito"
            >
                <Favorito
                    idProducto={producto.IdProducto}
                    IdSucursal={ps_IdSucursal}
                    cantidad={cantidad}
                    pesos={pesos}
                    onBlur={fueraFoco}
                    ps_closeModalFavorito={closeModalFavorito}
                ></Favorito>
            </Modal>


            {openModalUserRegister &&
                <RegistroInit  setCloseModal={cerrarVentana}></RegistroInit>
            }
        </>

    )
}


const mapStateToProps = state => ({
    carrito: state.Carrito.carrito,
    totalQuantities: state.Carrito.totalQuantities,
    profile: state.Profile.profile,
    producto_db: state.Producto.producto,

})

export default connect(mapStateToProps, {
    add_al_carrito, inc_al_carrito, dec_al_carrito, remove_al_carrito, actualizar_item_carrito
})(ItemProducto)
