import Layout from "../hocs/Layout";
import {React, useEffect, useState} from "react";
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {
    get_producto_item,
    limpiar_item_producto,
} from "../redux/actions/producto";
import {
    actualizar_item_carrito,
    add_al_carrito,
    dec_al_carrito,
    inc_al_carrito,
    remove_al_carrito,
    set_peso_al_carrito,
} from "../redux/actions/carrito";
import {
    faCircleXmark,
    faClose,
    faHeart,
    faSquareMinus,
    faSquarePlus,
    faTag, faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Direccion from "../components/modal/direccion";
import Modal from "react-modal";
import BackToPage from "../components/BackPage/BackToPage";
import FullScreenImage from "./../widgets/fullScreenImage";
import {useNavigate} from "react-router-dom";
import {set_all_prod_category} from "../redux/actions/home";
import Favorito from "../widgets/favoritoMycart";
import convertSlug from "../components/Utils/slug";
import "../../src/assets/styles/seccionResponsive.scss";
import "../../src/assets/styles/seccionResponsiveSwiper.scss";
import Alert from "../components/Swalert";
import ButtonText from "../widgets/button_text";



import 'swiper/css';
import 'swiper/css/pagination';


import { FreeMode,Mousewheel } from 'swiper';
import useScreenSize from "../widgets/useScreenSize";
import RegistroInit from "../components/modal/registroUser/registro_init";
const customStyles = {
    overlay: {
        backgroundColor: "#000000a6",
        zIndex: "1",
    },
    content: {
        top: "40%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        borderRadius: "0.75rem",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        overflow: "visible",
    },
};
const customStyles2 = {
    overlay: {
        backgroundColor: "#000000a6",
        zIndex: "100",
    },
    content: {
        top: "40%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        borderRadius: "0.75rem",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        overflow: "visible",
    },
};

const pesajes = [
    {name: "500g", peso: "500"},
    {name: "1Kg", peso: "1000"},
    {name: "2Kg", peso: "2000"},
    {name: "3Kg", peso: "3000"},
    {name: "5Kg", peso: "5000"},
    {name: "10Kg", peso: "10000"},
    {name: "15Kg", peso: "15000"},
    {name: "20Kg", peso: "20000"},
    {name: "25Kg", peso: "25000"},
    {name: "30Kg", peso: "30000"},
    {name: "35Kg", peso: "35000"},
    {name: "40Kg", peso: "40000"},
    {name: "45Kg", peso: "45000"},
    {name: "50Kg", peso: "50000"},
];

const style_favorito = {
    overlay: {
        backgroundColor: "#000000a6",
        zIndex: "31",
    },
    content: {
        top: "40%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        padding: "0",
        borderRadius: "0.75rem",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        overflow: "auto",
        border:'none',
        background:'transparent'
    },
};
const breakpoints = {
    300: {
        slidesPerView: 1,
        spaceBetween: 5,
    },
    400: {
        slidesPerView: 1,
        spaceBetween: 5,
    },
    500: {
        slidesPerView: 1,
        spaceBetween: 5,
    },
    640: {
        slidesPerView: 1,
        spaceBetween: 5, //10
    },
    768: {
        slidesPerView: 2,
        spaceBetween: 5, //10
    },

    992: {
        slidesPerView: 2,
        spaceBetween: 5,//10
    },
    1200: {
        slidesPerView: 3,
        spaceBetween: 5, //10
    },
    1300: {
        slidesPerView: 3,
        spaceBetween: 5, //30
    },

    1400: {
        slidesPerView: 4,
        spaceBetween: 5, //30
    },

};
const InformacionProducto = ({
                                 limpiar_item_producto,
                                 get_producto_item,
                                 producto,
                                 carrito,
                                 sucursal,
                                 set_peso_al_carrito,
                                 add_al_carrito,
                                 inc_al_carrito,
                                 dec_al_carrito,
                                 remove_al_carrito,
                                 actualizar_item_carrito,
                                 tipo_entrega,
                                 direccion,
                                 producto_delete,
                                 set_all_prod_category,
                                 sucursales,profile
                             }) => {
    const {productoId} = useParams();
    const {ciudadp, sucursalp} = useParams();
    let subtitle;
    const [imagenes, guardarImagenes] = useState([]);
    const [pesos, guardarPesos] = useState([]);
    const [cantidad, guardarCantidad] = useState(0);
    const [monto, guardarMonto] = useState(0);
    const [hubo_cambios, setHuboCambios] = useState(false);
    const [modal, setModal] = useState(false);
    const [modal_favorito, setModalFavorito] = useState(false);
    const startModal = () => setModal(true);
    const endModal = () => setModal(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);
    const [sizeImagen, setSizeImagen] = useState("400x400");
    const [url_navigation, setUrl_navigation] = useState(null);
    const [imagengrande, setImagengrande] = useState(null);
    const [index_imagen, setIndex_imagen] = useState(1);
    const [swiperRef, setSwiperRef] = useState(null);

    const openFullScreen = () => {
        setShowFullScreen(true);
    };
    const {width, height} = useScreenSize();





    const openModalFavorito = () => setModalFavorito(true);
    const closeModalFavorito = () => setModalFavorito(false);

    const [openModalUserRegister, setOpenModalUserRegister] = useState(false);

    const closeFullScreen = () => {
        setShowFullScreen(false);
    };
    const handleClick = () => {
        setShowFullScreen(false);
    };

    const actualizarAnchoPantalla = () => {
        setAnchoPantalla(window.innerWidth);
    };

    const total = (monto * cantidad).toFixed(2);
    const ahorrado = _ahorrado();
    const descuento = _descuento();
    const estimado = _estimado();
    const total_estimado = _total_estimado();
    const cantidad_peso = _cantidad_peso();

    function _ahorrado() {
        let resultado = 0;
        if (producto) {
            let precio_venta = producto.ConOferta
                ? producto.PrecioOriginal
                : producto.PrecioVenta;
            if (_es_pieza()) {
                let cantidad_pieza = _estimado();
                let aux = producto.ConOferta ? producto.PrecioOferta : producto.PrecioVenta;
                let total_sin_oferta = cantidad_pieza * producto.PrecioOriginal;
                let total_oferta = cantidad_pieza * aux;
                resultado = total_sin_oferta - total_oferta;
            } else {
                resultado = precio_venta * cantidad - total;
            }
        }

        return resultado.toFixed(2);
    }

    function _descuento() {
        let resultado = 0;
        if (producto) {
            if (producto.ConOferta) {
                resultado =
                    100 - (producto.PrecioOferta * 100) / producto.PrecioOriginal;
            }
        }
        return resultado.toFixed(1);
    }

    function _cantidad_peso() {
        let result = cantidad * 1000;
        return result.toFixed(0) + "g";
    }

    function _estimado() {
        let result = 0;
        if (_es_pieza()) {
            pesos.forEach((peso) => {
                result += peso.Piezas * peso.PesoAproxMax;
            });
        } else {
            result = cantidad;
        }
        return Number(result).toFixed(2);
    }

    function _total_estimado() {
        let result = 0;
        result = _es_pieza() ? monto * estimado : monto * cantidad;
        return result.toFixed(2);
    }

    function getIdsucursal(slug) {
        let seleccionado_id = null;
        sucursales.forEach((sucursal) => {
            let item = sucursal.Locatarios.find(item => convertSlug.data_slug(item.Descripcion) === slug);
            if (item) {
                seleccionado_id = item.IdSucursal;
            }
        })
        return seleccionado_id;
    }


    async function cargar_url_var() {
        let resp = ciudadp + " " + sucursalp;
        let url_parameters = await convertSlug.data_barra(resp);
        setUrl_navigation(url_parameters);
    }

    useEffect(() => {
        setAnchoPantalla(width);
    }, [width]);
    useEffect(() => {
        let sucursal_id = getIdsucursal(sucursalp);
        // cargar_url_var();
        get_producto_item(sucursal_id, productoId);
        //   get_producto_item(sucursal.IdSucursal, productoId);
    }, [sucursales]);
    useEffect(() => {
        if (ciudadp != null && sucursalp != null) {
            cargar_url_var();
        }
    }, [ciudadp, sucursalp])


    useEffect(() => {
        return () => {
            limpiar_item_producto();

            window.addEventListener("resize", actualizarAnchoPantalla);
        };
    }, []);

    useEffect(() => {
        let cantidad = 0;
        pesos.forEach((peso) => {
            cantidad += peso.Piezas;
        });
        guardarCantidad(cantidad);
    }, [pesos]);
    useEffect(() => {
        if (cantidad === 0) {
            if (_es_pieza() && hubo_cambios) {
                remove_al_carrito(producto);
            }
        } else {
            if (_es_pieza() && hubo_cambios) {
                let pesos_aux = [];
                pesos.forEach((elem) => {
                    if (elem.Piezas > 0) {
                        pesos_aux.push(elem);
                    }
                });
                set_peso_al_carrito(producto, cantidad, pesos_aux);
            }
        }
    }, [cantidad]);

    useEffect(() => {
        if (producto) {
            if (_es_pieza()) {
                _peso_definido();
            }
            let producto_carrito = carrito.find(
                (prd) => prd.IdProducto === producto.IdProducto
            );
            let monto_aux = producto.ConOferta
                ? producto.PrecioOferta
                : producto.PrecioVenta;
            guardarMonto(monto_aux);
            if (producto_carrito) {
                guardarCantidad(producto_carrito.Cantidad);
            }

            let fotos = [];
            if (producto.Fotos) {
                fotos = producto.Fotos;
            } else {
                let object = {
                    orden: 1,
                    Url: producto.UrlFoto,
                };
                fotos.push(object);
            }

            guardarImagenes(fotos);
        }
    }, [producto]);
    useEffect(() => {
    }, [carrito]);

    useEffect(() => {
          if(imagenes.length>0){
              setImagengrande(imagenes[0].Url);
          }
    }, [imagenes]);

    async function add_carrito() {
        let pesaje = 1;
        if (_es_peso()) {
            pesaje = 0.1;
        }
        await guardarCantidad(Number(cantidad) + pesaje);
        if (cantidad === 0) {
            add_al_carrito(producto, pesaje);
        } else {
            inc_al_carrito(producto);
        }
    }

    //cuando tiene array peso
    function _es_pieza() {
        if (producto) {
            return producto.Fraccionado && producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }

    function _peso_definido() {
        let producto_carrito = carrito.find(
            (prd) => prd.IdProducto === producto.IdProducto
        );
        let pesos_aux = set_piezas_carrito(null);
        if (producto_carrito) {
            if (_es_pieza()) {
                pesos_aux = set_piezas_carrito(producto_carrito.Pesos);
            }
            guardarCantidad(producto_carrito.Cantidad);
        }
        guardarPesos(pesos_aux);
    }

    function actualizarDato(peso, operacion) {
        setHuboCambios(true);
        if (operacion === "incremento") {
            guardarPesos(
                pesos.map((ps) => {
                    if (ps.IdPeso === peso.IdPeso) {
                        return {
                            ...ps,
                            Piezas: Number(ps.Piezas) + 1,
                        };
                    } else {
                        return ps;
                    }
                })
            );
        } else if (operacion === "decremento") {
            guardarPesos(
                pesos.map((ps) => {
                    if (ps.IdPeso === peso.IdPeso && ps.Piezas >= 1) {
                        return {
                            ...ps,
                            Piezas: Number(ps.Piezas) - 1,
                        };
                    } else {
                        return ps;
                    }
                })
            );
        }
    }

    function set_piezas_carrito(pesos) {
        producto.Pesos.forEach((peso) => {
            let peso_aux = null;
            if (pesos) {
                peso_aux = pesos.find((ps) => ps.IdPeso === peso.IdPeso);
            }
            if (peso_aux) {
                peso.Piezas = peso_aux.Piezas;
            } else {
                peso.Piezas = 0;
            }
        });
        return producto.Pesos;
    }

    function inputchangePeso(e, peso) {
        const esValido = e.target.validity.valid;
        if (esValido) {
            let peso_value = e.target.value;
            if (peso_value) {
                guardarPesos(
                    pesos.map((ps) => {
                        if (ps.IdPeso === peso.IdPeso) {
                            setHuboCambios(true);
                            return {
                                ...ps,
                                Piezas: Number(e.target.value),
                            };
                        } else {
                            return ps;
                        }
                    })
                );
            }

        }

    }


    //finaliza array peso su lógica
    //cuando es por pieza la logica
    function _es_peso() {
        if (producto) {
            return producto.Fraccionado && !producto.PesoDefinido ? true : false;
        } else {
            return false;
        }
    }

    function abrirModal() {
        startModal(true);
    }

    function actualizar_item(valor, operacion) {
        let resultado = 0;
        resultado = operacion == "incremento" ? cantidad + valor : parseFloat(cantidad).toFixed(2) - valor;
        if (resultado < 0) {
            return;
        }
        if (operacion == "incremento" && cantidad == 0) {
            guardarCantidad(resultado);
            add_al_carrito(producto, resultado);
        } else if (operacion == "decremento" && cantidad == valor) {
            guardarCantidad(resultado);
            remove_al_carrito(producto);
        } else {
            guardarCantidad(resultado);
            actualizar_item_carrito(producto, resultado);
        }
    }

    function set_peso_pieza(peso) {
        let resultado = peso.peso / 1000;
        guardarCantidad(resultado);
        actualizar_item_carrito(producto, resultado);
    }

    //end cuando es por pieza la logica

    function reducir_carrito() {
        if (cantidad === 1) {
            guardarCantidad(cantidad - 1);
            remove_al_carrito(producto);
        } else if (cantidad >= 1) {
            guardarCantidad(cantidad - 1);
            dec_al_carrito(producto);
        }
    }

    function inputchange(e) {
        const esValido = e.target.validity.valid;
        if (esValido) {
            let cant = e.target.value;
            if (cant) {
                if (producto.Fraccionado & !producto.PesoDefinido) {
                } else {
                    guardarCantidad(cant);
                    if (cant > 0) {
                        add_al_carrito(producto, Number(cant));
                    } else {
                        remove_al_carrito(producto);
                    }
                }
            }

        }
    }

    function afterOpenModal() {
        setTimeout(function () {
            subtitle.style.color = "blue";
        }, 2000);
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function show_subcategoria(cat_id, subcat_id) {
        navigate("/" + url_navigation + `/categoria/${cat_id}/${subcat_id}/`);
    }

    function show_categoria(cat_id) {
        navigate("/" + url_navigation + `/categoria/${cat_id}`);
    }

    function formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    const fueraFoco = () => {
    };

    const show_botones = () => {
        add_carrito();
         if(_es_pieza()){
           //  actualizarDato(peso, "incremento");
         }
    };


    const selec_imagen=(foto,index)=>{
        setImagengrande(foto)
       // setIndex_imagen((index+1));
        swiperRef.slideTo(index  , 0);
    }

    const obtenerDatos = async (e) => {
        let value = e.clientX / width;

      //   setPositionWidth(value)

    }
    const handleSlideChange = (swiper) => {
        const currentSlideIndex = swiper.activeIndex + 1; // Adjust for 0-based indexing
        setIndex_imagen(currentSlideIndex);
    };


    const verifyUserAutenticate = () => {
        if (profile.Telefono !== "") {
            return true;
        }
        return false;
    }

    const show_ventana_favorito = () => {
           if(verifyUserAutenticate()){
               openModalFavorito();
           }else{
               abrir_modal_registro();
           }
    }

    const abrir_modal_registro=()=>{
        setOpenModalUserRegister(true);
    }
    const cerrarVentana = (valor) => {
        setOpenModalUserRegister(false);
    }

    return (
        <Layout>
            {producto && (
                <>
                    <BackToPage
                        ps_url_page={""}
                        ps_contenerdor={"sinContenedor"}
                    ></BackToPage>
                    <Alert></Alert>
                    {openModalUserRegister &&
                    <RegistroInit  setCloseModal={cerrarVentana}></RegistroInit>
                    }
                    <div className="container"  >

                        <div className="header-responsive-categorias   ">


                                <div className="  px-0  py-3">

                                    <div className="flex justify-start"  >

                                        <div
                                            className="bg-gray-400 font-bold text-white rounded mr-1"
                                            style={{cursor: "pointer",  padding:"10px 8px 10px 8px"   }}
                                            onClick={() =>
                                                show_categoria(
                                                    convertSlug.data_slug(producto.Categoria.Descripcion)
                                                )
                                            }
                                        >
                                            {producto.Categoria.Descripcion}
                                        </div>
                                        <div
                                            className="bg-gray-500   font-bold  text-white rounded mx-1"
                                            style={{cursor: "pointer",  padding:"10px 8px 10px 8px"  }}
                                            onClick={() =>
                                                show_subcategoria(
                                                    convertSlug.data_slug(producto.Categoria.Descripcion),
                                                    convertSlug.data_slug(producto.SubCategoria.Descripcion)
                                                )
                                            }
                                        >
                                            {producto.SubCategoria.Descripcion}
                                        </div>
                                    </div>

                            </div>
                        </div>
                        <div className="container-favorito-resposive">

                                <div className="header-corazon-responsive">
                                    <div className="float-right">
                                        <div className="relative inline">
                                            <button
                                                className="absolute right-2 top-2"
                                                onClick={() => show_ventana_favorito()}
                                            >
                                                {producto.EnListadoCliente ? (
                                                    <FontAwesomeIcon
                                                        icon={faHeart}
                                                        className="text-hiperorange w-10 h-10"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faHeart}
                                                        className="hipergrisdark text-hipergrisdark w-10 h-10"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                        </div>

                        <div className="contenedorInfo"       >
                            <div className="columnaImagen    "  >
                                    <div className="imagenes-vertical      " style={{  height:"500px", maxHeight:"500px" }} >

                                          <Swiper
                                              direction={'vertical'}
                                              slidesPerView={3}
                                              centeredSlides={true}
                                              spaceBetween={0}
                                              navigation={true}
                                              freeMode={true}
                                              
                                              mousewheel={true}
                                              scrollbar={{ draggable: true }}
                                              modules={[Mousewheel,FreeMode, Pagination,Navigation]}
                                            className="  direccionvertical"
                                              onSlideChange={handleSlideChange}
                                              id="mySwiperInfoProductov"
                                              style={{"--swiper-navigation-color": "#32338E",
                                                  "--swiper-navigation-size": "30px"
                                                  // marginLeft:'1rem'
                                              }}

                                        >


                                            {/*laterales web*/}

                                            {imagenes &&
                                            imagenes !== null &&
                                            imagenes !== undefined &&
                                            imagenes.map((foto, index) => (
                                                <SwiperSlide key={'ima'+index}>
                                                    <div className="py-4 "  >

                                                        <div
                                                             className={classNames("flex","justify-center" )}

                                                             style={{  border:((index+1)===index_imagen)? "2px solid #ED6A0F":"none", borderRadius:((index+1)===index_imagen)?"15px":"0px" }}
                                                        >
                                                            <img
                                                                src={
                                                                    foto.Url +
                                                                    (anchoPantalla >= 770
                                                                        ? `&size=400x400`
                                                                        : "&size=120x120")
                                                                }

                                                                alt=""
                                                                onClick={() => selec_imagen(foto.Url,index)}
                                                                style={{borderRadius:"15px" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}


                                        </Swiper>
                                    </div>
                                <div className="imagen-carrusel">

                                    <Swiper

                                        slidesPerView={1}
                                        spaceBetween={0}
                                        navigation={true}
                                        freeMode={true}
                                        onSwiper={setSwiperRef}
                                        mousewheel={true}
                                        scrollbar={{ draggable: true }}
                                        modules={[Mousewheel,FreeMode, Pagination,Navigation]}
                                        className="mySwiper direccionvertical text-black"
                                        onSlideChange={handleSlideChange}
                                        style={{"--swiper-navigation-color": "#32338E",
                                            "--swiper-navigation-size": "30px"
                                            // marginLeft:'1rem'
                                        }}
                                    >

                                        {/*responsive*/}
                                        {imagenes &&
                                        imagenes !== null &&
                                        imagenes !== undefined &&
                                        imagenes.map((foto, index) => (
                                            <SwiperSlide key={'ima'+index}>
                                                <div className="py-4 ">
                                                    <div className="flex justify-center"

                                                    >
                                                        <img
                                                            src={
                                                                foto.Url +
                                                                (anchoPantalla >= 770
                                                                    ? `&size=400x400`
                                                                    : "&size=120x120")
                                                            }

                                                            onClick={() => selec_imagen(foto.Url,index)}
                                                            alt="imagen carrusel"
                                                            style={{objectFit:"cover"}}
                                                        />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}


                                    </Swiper>
                                </div>
                                <div className="imagen-grande    "   >
                                        <div className="header-corazon" style={{paddingRight:"9px" }}>
                                            <div className="float-right">
                                                <div className="relative inline">
                                                    <button
                                                        className="absolute right-2 top-2"
                                                        onClick={() => show_ventana_favorito()}
                                                    >
                                                        {producto.EnListadoCliente ? (
                                                            <FontAwesomeIcon
                                                                icon={faHeart}
                                                                className="text-hiperorange w-10 h-10"
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faHeart}
                                                                className="hipergrisdark text-hipergrisdark w-10 h-10"
                                                            />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                      <div className="body-image    "   >
                                           <img src={imagengrande +   (anchoPantalla >= 770
                                               ? `&size=400x400`
                                               : "&size=120x120")} className="w-full h-full imagen_body" alt=""/>
                                           <div className="imagenes-responsive-footer  ">
                                                   {/*responsive selection*/}
                                                   {imagenes &&
                                                   imagenes !== null &&
                                                   imagenes !== undefined &&
                                                   imagenes.map((foto, index) => (


                                                               <div className=" flex  py-2 justify-start mx-2" key={'ima-resposive'+index}

                                                                    style={{  border:((index+1)===index_imagen)? "2px solid #ED6A0F":"none", borderRadius:((index+1)===index_imagen)?"15px":"0px" }}
                                                               >
                                                                   <img
                                                                       src={
                                                                           foto.Url +
                                                                           (anchoPantalla >= 770
                                                                               ? `&size=400x400`
                                                                               : "&size=120x120")
                                                                       }
                                                                       style={{borderRadius:"15px",paddingLeft:"5px",paddingRight:"5px",objectFit:"cover"}}
                                                                       alt=""
                                                                       onClick={() => selec_imagen(foto.Url,index)}


                                                                   />
                                                               </div>


                                                   ))}

                                           </div>



                                      </div>
                                    <div className="bg-hiperorange text-white rounded-md px-2  font-semibold  style_indice_number_imagen" style={{ marginRight:"10px !important" }}>
                                        {index_imagen}/{imagenes.length}
                                    </div>
                                </div>
                            </div>

                            <div className="columnaInfoProd  ">
                                        <div className="style-categorias  ">

                                                <div className="flex justify-start"  >
                                                    <div
                                                        className="bg-gray-400   font-bold text-white rounded mr-1  flex align-items-center "
                                                        style={{cursor: "pointer",padding:"10px 8px 10px 8px"}}
                                                        onClick={() =>
                                                            show_categoria(
                                                                convertSlug.data_slug(producto.Categoria.Descripcion)
                                                            )
                                                        }
                                                    >
                                                        {producto.Categoria.Descripcion}
                                                    </div>
                                                    <div
                                                        className="bg-gray-500  flex align-items-center font-bold  text-white rounded mx-1"
                                                        style={{cursor: "pointer" ,padding:"10px 8px 10px 8px"}}
                                                        onClick={() =>
                                                            show_subcategoria(
                                                                convertSlug.data_slug(producto.Categoria.Descripcion),
                                                                convertSlug.data_slug(producto.SubCategoria.Descripcion)
                                                            )
                                                        }
                                                    >
                                                        {producto.SubCategoria.Descripcion}
                                                    </div>
                                                </div>

                                        </div>
                                <div className="style-name-producto style_letras  ">

                                        {producto.Descripcion}

                                </div>
                                {producto.ConOferta &&( <div className="descuento-tag  py-2">
                                    <div className="px-2 bg-hiperorange text-white" style={{borderRadius:'20px', width:'fit-content', float:'left'}}>
                                        <p className="mb-0 text-lg">

                                            <div className="  d-inline-block py-2 px-2 rounded-3  ">
                                                <FontAwesomeIcon
                                                    icon={faTag}
                                                    className="w-[1.5rem] h-[1.5rem] text-white pr-2"
                                                    style={{borderRadius: "50%"}}
                                                />

                                                <span className="text-white font-semibold">
                                                     -{descuento}% Dcto.
                                            </span>
                                            </div>

                                        </p>
                                    </div>
                                </div>) }

                                <div className="precio-tag  ">
                                     <div className="texto-precio style-letra-info-prod" >
                                         {_es_peso()? (<span className="style_precio_kg font-bold"> Precio por Kg </span>  ):<></> }
                                         {_es_pieza()? (<span className="font-bold"> Precio por Kg </span>  ):<></> }
                                         {(!_es_pieza() && !_es_peso())? (<span className="style_precio_und font-bold"> Precio por Und </span>  ):<></> }

                                        </div>
                                     <div > <span className="valor-ahora-info-prod style-letra-info-prod">
                                      {producto.ConOferta ? (
                                          <p className="mb-0">
                                              {producto.Moneda} {formatPrice(producto.PrecioOferta)}
                                              <del className="text-zinc-400   px-2" style={{fontSize:"24px !important" }} >
                                                  {formatPrice(producto.PrecioOriginal)}
                                              </del>
                                          </p>
                                      ) : (
                                          <p className="mb-0 pl-0">
                                           {producto.Moneda} {formatPrice(producto.PrecioVenta)}
                                          </p>
                                      )}
                                     </span>
                                         {/* {producto.ConOferta &&( <span className="valor-antes-info-prod style-letra-info-prod">
                                             <del className="text-zinc-400 text-sm px-2">
                                                  {formatPrice(producto.PrecioOriginal)}
                                              </del>
                                         </span> ) } */}
                                        </div>
                                </div>
                                  <div className="controles-add-minus py-2" style={{ borderBottom: (cantidad>0)?"1px solid  #ABABAD":"none" }}>
                                      {!producto.Fraccionado && cantidad>0  && ( /* esnoremal*/
                                          <div className="flex align-items-end controles_info_prod">
                                              <div className="flex">

                                                  {cantidad>1 && (  <FontAwesomeIcon
                                                      icon={faSquareMinus}
                                                      className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                                      onClick={() => reducir_carrito()}
                                                  />

                                                  )  }
                                                  { cantidad==1&&(
                                                       <span className="bg-hiperorange  icono-delete " >
                                                             <FontAwesomeIcon
                                                                 icon={faTrashCan}
                                                                 className="w-[1.1rem] h-[1.1rem] text-white cursor-pointer "
                                                                 onClick={() => reducir_carrito()}
                                                             />
                                                       </span>
                                                     )   }

                                                  <div className="mx-1 flex justify-center" style={{ width:"100px" }}>
                                                      <input
                                                          type="text"
                                                          className="w-100 h-[2rem] text-center outline-none mx-2 bg-hipergris rounded-lg px-2 font-bold"
                                                          name="cantidad"
                                                          pattern="[0-9]{0,13}"
                                                          value={cantidad+' Und'}
                                                          onChange={(e) => inputchange(e)}
                                                      />

                                                  </div>

                                                  <FontAwesomeIcon
                                                      icon={faSquarePlus}
                                                      className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                                      onClick={() => add_carrito()}
                                                  />
                                              </div>
                                          </div>
                                      )}
                                      {_es_peso()&& (cantidad>0) ?(
                                          <div className="flex align-items-end controles_info_prod">
                                              <div className="flex">
                                                  {cantidad>0.1 && (
                                                      <FontAwesomeIcon
                                                          icon={faSquareMinus}
                                                          className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                                          onClick={() => actualizar_item(0.1, "decremento")}
                                                      />
                                                  )}


                                                  { cantidad==0.1&&(
                                                      <span className="bg-hiperorange  icono-delete " >
                                                   <FontAwesomeIcon
                                                       icon={faTrashCan}
                                                       className="w-[1.1rem] h-[1.1rem] text-white cursor-pointer "
                                                       onClick={() => actualizar_item(0.1, "decremento")}
                                                   />

                                                  </span>
                                                  )

                                                  }
                                                  <div
                                                      className=" flex justify-center align-items-center bg-white cursor-pointer"
                                                      onClick={() => abrirModal()}
                                                  >
                                                      <span  className=" mx-2 bg-hipergris rounded-lg px-3 py-1 font-bold"> {cantidad_peso}</span>
                                                  </div>

                                                  <FontAwesomeIcon
                                                      icon={faSquarePlus}
                                                      className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                                      onClick={() => actualizar_item(0.1, "incremento")}
                                                  />
                                              </div>
                                          </div>
                                      ):<></>}

                                      {_es_pieza() ? ( /* espieza*/

                                          <div className="flex flex-col" >
                                              <div className="style-letra-info-prod" style={{fontSize:"16px"}}>
                                                   Peso de unidades disponibles
                                              </div>
                                              {pesos.map((peso, index) => (
                                                  <div
                                                      className=" flex  mt-2   w-full bg-white mb-2  flex-wrap"
                                                      key={index}
                                                  >
                                                      <div
                                                          className="bg-white h-[2rem] pr-2 rounded-l flex align-items-center style_dato_peso_unidad ">
                                                                        <span className="  font-bold entre_peso_aprox"     >
                                                                           Entre Unidad {peso.PesoAproxMin} - {peso.PesoAproxMax}{" "}
                                                                            kg
                                                                        </span>
                                                      </div>
                                                      <div className="flex  justify-content-center align-items-center  style_dato_peso_unidad"    >


                                                                  { (peso.Piezas==1) &&(
                                                                      <FontAwesomeIcon
                                                                          icon={faTrashCan}
                                                                          className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                                                          onClick={() =>
                                                                              actualizarDato(peso, "decremento")
                                                                          }
                                                                      /> )    }


                                                          { (peso.Piezas>1) &&(
                                                              <FontAwesomeIcon
                                                              icon={faSquareMinus}
                                                              className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                                              onClick={() =>
                                                              actualizarDato(peso, "decremento")
                                                          }
                                                              /> )    }

                                                          { (peso.Piezas>0)&&(

                                                              <input
                                                                  type="text"
                                                                  className="  h-[2rem] text-center outline-none  mx-2 bg-hipergris rounded-lg px-3 py-1 font-bold "
                                                                  name="cantidad"
                                                                  pattern="[0-9]{0,13}"
                                                                  value={peso.Piezas+" Und"}
                                                                  onChange={(e) =>
                                                                      inputchangePeso(e, peso)
                                                                  }
                                                                  style={{   width:"auto",maxWidth:"100px"  }}
                                                              />
                                                          ) }

                                                          { (peso.Piezas>0)&&(
                                                              <FontAwesomeIcon
                                                                  icon={faSquarePlus}
                                                                  className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                                                  onClick={() =>
                                                                      actualizarDato(peso, "incremento")
                                                                  }
                                                              />

                                                          )}







                                                          { (peso.Piezas==0) &&(
                                                              <div className="w-full ">
                                                                  <ButtonText
                                                                      type="submit"
                                                                      label='Agregar'
                                                                      principal={true}
                                                                      className="bg-hiperorange  text-white mb-0 hover:bg-hiperorange_hover  w-full"
                                                                      onClick={() =>  actualizarDato(peso, "incremento")}
                                                                  />
                                                              </div>

                                                          ) }
                                                      </div>
                                                  </div>
                                              ))}
                                          </div>
                                      ) : (
                                          <></>
                                      )}

                                  </div>
                                { ( (cantidad==0||cantidad_peso=='0g')  && _es_pieza()===false  )&&(

                                    <div className="bnt-agregar-agregar"  >
                                        <ButtonText
                                            type="submit"
                                            label='Agregar'
                                            principal={true}
                                            className="bg-hiperorange  text-white mb-2 hover:bg-hiperorange_hover  w-7/12"
                                            onClick={() => show_botones()}
                                        />
                                    </div>

                                )}

                                {cantidad>0&&  (

                                    <div className="block-total ">
                                        <div  >
                                            <p className="mb-0 font-bold">
                                                { _es_peso()? "Peso" : ""}
                                                { _es_pieza()?"Peso estimado":""}
                                            </p>
                                            {
                                                (_es_peso() || _es_pieza() && (<p className="mb-0 font-bold" >{estimado} KG</p>) )
                                            }
                                        </div>
                                        {(!_es_pieza())&& (
                                            <div><span className="style-letra-info-prod  "> Total  </span> </div>
                                        ) }
                                        {(_es_pieza())&& (
                                            <div><span className="style-letra-info-prod  "> Total estimado </span> </div>
                                        ) }
                                        <div className="listado flex    justify-start  ">
                                            <div  className="style-letra-info-prod  style-texto-total  ">
                                                {producto.Moneda} {total_estimado}

                                            </div>

                                                {producto.ConOferta && (
                                                    <div
                                                        className="bg-hiperorange text-white    texto_ahorrado_info_prod  d-inline-block py-2    font-bold"  >
                                                          Bs. {ahorrado}&nbsp; Ahorrado

                                                    </div>
                                                )}

                                        </div>
                                    </div>
                                ) }

                                <div className="tag-agregar-listas text-hiperorange pt-2 hover:text-hiperorange_hover "    >
                                    <span className="cursor-pointer font-bold " onClick={() => show_ventana_favorito()}>   Agregar a tus listas de compras </span>

                                </div>
                            </div>
                        </div>







                    </div>

                    <Modal
                        isOpen={modalIsOpen}
                        appElement={document.getElementById("root")}
                        onRequestClose={closeModal}
                        style={customStyles2}
                        contentLabel="Example Modal"
                        key={"keyModal"}
                    >
                        <FullScreenImage
                            imagenes={imagenes}
                            onClose={closeFullScreen}
                            tiene_varias_imagenes={"si"}
                            imagen={null}
                        ></FullScreenImage>
                    </Modal>
                    <Modal
                        isOpen={modal}
                        ariaHideApp={false}
                        onRequestClose={endModal}
                        style={customStyles}
                        contentLabel="address"
                    >
                        <div className="w-80 relative">
                            <div className="card border-0 relative">
                                <div className="absolute top-[-30px] right-[-2rem]">
                                    <FontAwesomeIcon
                                        icon={faCircleXmark}
                                        className="w-[2.5rem] h-[2.5rem] rounded-full text-hiperorange bg-white cursor-pointer "
                                        onClick={() => endModal()}
                                    />
                                </div>
                                <div className="text-center">
                                    <h5 className="font-bold">Seleccione el Peso</h5>
                                </div>
                                <div className="card-body pt-0 ">
                                    <div className="row">
                                        {pesajes.map((pesaje, index) => (
                                            <div className="col-6" key={index}>
                                                <div
                                                    className="bg-[#EEEEEE] m-2 px-3 py-2 rounded text-center cursor-pointer hover:bg-gray-400"
                                                    onClick={() => set_peso_pieza(pesaje)}
                                                >
                          <span className="font-semibold text-xl">
                            {pesaje.name}
                          </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center">
                                    <div className="flex border-[3px] border-hiperorange p-1 rounded">
                                        <FontAwesomeIcon
                                            icon={faSquareMinus}
                                            className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                            onClick={() => actualizar_item(0.1, "decremento")}
                                        />
                                        <div className="mx-2 flex align-items-center">
                                            <span>{cantidad_peso}</span>
                                        </div>
                                        <FontAwesomeIcon
                                            icon={faSquarePlus}
                                            className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                            onClick={() => actualizar_item(0.1, "incremento")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={modal_favorito}
                        ariaHideApp={false}
                        onRequestClose={closeModalFavorito}
                        style={style_favorito}
                        contentLabel="favorito"
                    >
                        <Favorito
                            idProducto={producto.IdProducto}
                            IdSucursal={sucursal.IdSucursal}
                            cantidad={cantidad}
                            pesos={pesos}
                            onBlur={fueraFoco}
                            delete_producto={false}
                             ps_closeModalFavorito={closeModalFavorito}
                        ></Favorito>
                    </Modal>
                </>
            )}
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    carrito: state.Carrito.carrito,
    producto: state.Producto.producto,
    sucursal: state.BranchOffice.sucursal,
    sucursales: state.BranchOffice.sucursales,
    tipo_entrega: state.BranchOffice.tipo_entrega,
    direccion: state.Location.direccion,
    producto_delete: state.Carrito.producto_delete,
    profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
    limpiar_item_producto,
    get_producto_item,
    set_peso_al_carrito,
    add_al_carrito,
    inc_al_carrito,
    dec_al_carrito,
    remove_al_carrito,
    actualizar_item_carrito,
    set_all_prod_category,
})(InformacionProducto);
