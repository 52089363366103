import Layout from "../hocs/Layout";
import React, {useState, useEffect, useRef} from "react";
import {faSearch, faArrowUp} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useParams} from "react-router-dom";
import ItemProducto from "../widgets/item_producto";
import {connect} from "react-redux";
import {get_categorias} from "../redux/actions/home";
import {get_producto, get_all_producto, limpiar_array_producto} from "../redux/actions/producto"
import {get_filtro_productos_categoria} from "../redux/actions/producto"
import Categoria from "../components/home/Categoria";
import BackToPage from "../components/BackPage/BackToPage";
import {Circles} from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate} from "react-router-dom";
import {set_all_prod_category} from "../redux/actions/home";
import convertSlug from "../components/Utils/slug";
import '../../src/assets/styles/categoria.scss';
import classNames from "classnames";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import Alert from "../components/Swalert";
import LoaderSpinner from "../widgets/loaderSpinner";
import Producto from "../redux/reducers/producto";

var page = 0;
const HomeCategoria = ({
                           limpiar_array_producto,
                           get_categorias,
                           categorias,
                           sucursal,
                           get_producto,
                           productos,
                           subcategorias,
                           category,
                           subcategoria_id,
                           loading,
                           get_filtro_productos_categoria,
                           productos_peticion,
                           productosCategory,
                           get_all_producto,
                           set_all_prod_category,
                           peticion,
                           end_message
                       }) => {
    const {IdCategoria, subcategoriaId} = useParams();
    const navigate = useNavigate();
    let IdSucursal = sucursal.IdSucursal;
    let IdMarket = sucursal.IdMarket;

    const [categoria, setCategoria] = useState({
        IdCategoria: '',
        Descripcion: '',
    });
    const [subcategoria, setSubcategoria] = useState({
        IdSubcategoria: '',
        Descripcion: '',
    });
    const myDivRef = useRef();
    const [url_navigation, setUrl_navigation] = useState(null);
    const {ciudadp, sucursalp} = useParams();
    const [arraySubcategorias, setArraySubcategorias] = useState([]);
    const [arraySubCategoriasCopia, setArraySubCategoriasCopia] = useState([]);
    const [filtro, setFiltro] = useState('');
    const [filtrosubcategoria, setFiltrosubcategoria] = useState('');
    const [allcategory, setAllcategory] = useState(null);


    const breakpoints = {
        300: {
            slidesPerView: 3,
            spaceBetween: 2,
        },
        400: {
            slidesPerView: 3,
            spaceBetween: 2,
        },

        640: {
            slidesPerView: 4,
            spaceBetween: 2,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 5,
        },
        800: {
            slidesPerView: 4,
            spaceBetween: 5,
        },

        992: {
            slidesPerView: 4,
            spaceBetween: 5,
        },
        1200: {
            slidesPerView: 12,
            spaceBetween: 5,
        },

        1400: {
            slidesPerView: 12,
            spaceBetween: 5,
        },

    };


    /****IdCategoria accion para determinar si la url es de solo categoria****/
    useEffect(() => {
        if (!subcategoriaId) {
            setSubcategoria({});
            setAllcategory(true);
        }
        if (allcategory !== null || !subcategoriaId) {
            page = 0;
            limpiar_array_producto();
            obtenerArraySubCategoria();
        }
        mover_scroll();
    }, [IdCategoria]);
    /****subcategoriaId accion para determinar si la url es de sub categoria****/
    useEffect(() => {
        if (!allcategory && subcategoriaId) {
            obtenerArraySubCategoria();
        }
        mover_scroll();
    }, [subcategoriaId]);
    /****cuando se trata de recargar la pagina categoria esta []****/
    useEffect(() => {
        if (categorias.length > 0) {
            obtenerArraySubCategoria();
        }
        mover_scroll();
    }, [categorias]);


    const subir = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    async function cargar_url_var() {
        let resp = ciudadp + " " + sucursalp;
        let url_parameters = await convertSlug.data_barra(resp);
        setUrl_navigation(url_parameters);
    }

    function validarAnchoPantalla() {
        var anchoPantalla = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        var anchoDeseado = 420;
        if (anchoPantalla <= anchoDeseado) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        page = 0;
        limpiar_array_producto();
        const fetchData = async () => {
            let result = false;
            if (!subcategoriaId) {
                result = true;
            }
            setAllcategory(result);

            mover_scroll();
        }
        fetchData();

    }, []);

    useEffect(() => {
        if (ciudadp != null && sucursalp != null) {
            cargar_url_var();
        }
    }, [ciudadp, sucursalp])

    useEffect(() => {
        if (allcategory) {
            setSubcategoria({});
        }
        /***cuando viene de sub categoria a categoria**/
        if (allcategory !== null && !subcategoriaId) {
            obtenerArraySubCategoria();
        }
        if (!allcategory && subcategoriaId) {
            obtenerArraySubCategoria();
        }
        mover_scroll();
    }, [allcategory]);

    useEffect(() => {
        if (arraySubCategoriasCopia.length > 0) {
            setArraySubcategorias(arraySubCategoriasCopia);
            // const fil = filtrosubcategoria.split(' ').join('');
            const fil = filtrosubcategoria.trim();
            if (fil !== "") {
                const subcategorias_filtradas = arraySubCategoriasCopia.filter(item =>
                    // item.Descripcion.toLowerCase().includes(fil.toLowerCase())
                    item.Descripcion.toLowerCase().includes(fil.toLowerCase())
                );
                setArraySubcategorias(subcategorias_filtradas);
            }
        }
    }, [filtrosubcategoria]);

    async function getIdCategoria(slug) {

        let resp = null;
        for (let item of categorias) {
            if (convertSlug.data_slug(item.Descripcion) === slug) {
                resp = item.IdCategoria;
                break;
            }
        }
        return resp;
    }

    async function getIdsubctegoria(slug) {
        let resp = null;
        for (let item of arraySubcategorias) {
            if (convertSlug.data_slug(item.Descripcion) === slug) {
                resp = item.IdSubcategoria;
                break;
            }
        }
        return resp;
    }
    
    function getIdsubctegoriaTudo(slug) {
        let resp = null;
        for (let item of arraySubcategorias) {
            if (convertSlug.data_slug(item.Descripcion) === slug) {
                resp = item.IdSubcategoria;
                break;
            }
        }
        return resp;
    }
     function getIdCategoriaTudo(slug) {
        let resp = null;
        for (let item of categorias) {
            if (convertSlug.data_slug(item.Descripcion) === slug) {
                resp = item.IdCategoria;
                break;
            }
        }
        return resp;
    }


    async function getIdsubctegoriaInstan(arr, slug) {
        let resp = null;
        for (let item of arr) {
            if (convertSlug.data_slug(item.Descripcion) === slug) {
                resp = item.IdSubcategoria;
                break;
            }
        }
        return resp;
    }

    function mover_scroll() {
        if (validarAnchoPantalla()) {
            if (myDivRef.current) {
                const scrollY = myDivRef.current.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({top: scrollY, behavior: 'smooth'});
            }
        }
    }

    async function obtenerArraySubCategoria() {

        setArraySubCategoriasCopia([]);
        if (categorias.length > 0 && allcategory !== null) {
            let categId = await getIdCategoria(IdCategoria);
            let result = await categorias.find(cat => cat.IdCategoria === categId);
            await _setCategoria(result.IdCategoria, result.Descripcion);
            setArraySubcategorias(result.SubCategorias);
            setArraySubCategoriasCopia(result.SubCategorias);
            if (!allcategory && subcategoriaId) {
                await obtenerSubCategoria(result.SubCategorias);
                await obtenerProductosInstante(result.SubCategorias, subcategoriaId);
            } else {
                await obtenerProductos();
            }

        }
    }

    async function _setCategoria(IdCategoria, Descripcion) {
        await setCategoria({IdCategoria: IdCategoria, Descripcion: Descripcion})
    }

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function obtenerSubCategoria(array) {

        await delay(1000);
        let subcatId = await getIdsubctegoriaInstan(array, subcategoriaId);

        let result = await array.find(sub => sub.IdSubcategoria === subcatId);
        _setSubcategoria(result.IdSubcategoria, result.Descripcion);
    }

    function _setSubcategoria(IdSubcategoria, Descripcion) {
        setSubcategoria({IdSubcategoria: IdSubcategoria, Descripcion: Descripcion});
    }

    async function cargar_productos(datos, page_aux) {
        if (allcategory) {
            console.log("tuu1", page_aux);
            await get_all_producto(datos, page_aux);
        } else {
            console.log("tuu2",page_aux);
            await get_producto(datos, page_aux);
        }
    }

    const obtenerProductos = async () => {
        let subcatId = await  getIdsubctegoria(subcategoriaId);
        let categId = await  getIdCategoria(IdCategoria);
        setTimeout(() => {
           

            let IdSubcategoria = subcategoriaId ? Number(subcatId) : null;
            
            let datos = {
                IdMarket: IdMarket,
                IdLocatorio: sucursal.IdSucursal,
                IdCategoria: categId,
                IdSubcategoria: IdSubcategoria,
                texto_filtro: filtro,
            }
            if (productos_peticion) {
                page = page + 1;
                // await delay(4500);
                cargar_productos(datos, page);
            } else {
                if (page === 0) {
                    page = page + 1;
                    cargar_productos(datos, page);
                }
            }
        },800);
    }
    function obtenerProductosTudo() {
        let subcatId =   getIdsubctegoriaTudo(subcategoriaId);
        let categId =   getIdCategoriaTudo(IdCategoria);
        console.log("pro pet",productos_peticion);
        setTimeout(() => {
           
            let IdSubcategoria = subcategoriaId ? Number(subcatId) : null;
            
            let datos = {
                IdMarket: IdMarket,
                IdLocatorio: sucursal.IdSucursal,
                IdCategoria: categId,
                IdSubcategoria: IdSubcategoria,
                texto_filtro: filtro,
            }
            if (productos_peticion) {
                page = page + 1;
                // await delay(4500);
                console.log("produ pet1");
                cargar_productos(datos, page);
            }
            //  else {
            //     if (page === 0) {
            //         console.log("produ pet2");
            //         page = page + 1;
            //         cargar_productos(datos, page);
            //     }
            // }
        },1000);
    }




    const obtenerProductosInstante = async (array) => {
        let subcatId = await getIdsubctegoriaInstan(array, subcategoriaId);

        let IdSubcategoria = subcategoriaId ? Number(subcatId) : null;
        let categId = await getIdCategoria(IdCategoria);
        let datos = {
            IdMarket: IdMarket,
            IdLocatorio: sucursal.IdSucursal,
            IdCategoria: categId,
            IdSubcategoria: IdSubcategoria,
            texto_filtro: filtro,
        }
        if (productos_peticion) {
            page = page + 1;
            // await delay(4500);
            cargar_productos(datos, page);
        } else {
            if (page === 0) {
                page = page + 1;
                cargar_productos(datos, page);
            }
        }
    }


    function selectSubcategory(subcat) {
        page = 0;
        limpiar_array_producto();
        delay(1000);
        let subcategoria_slug = convertSlug.data_slug(subcat.Descripcion);

        if (subcategoria.IdSubcategoria === subcat.IdSubcategoria) {
            setAllcategory(true);
            navigate("/" + url_navigation + "/" + `categoria/${IdCategoria}`);
        } else {
            setAllcategory(false);
            window.scrollTo(0, 0);
            navigate("/" + url_navigation + "/" + `categoria/${IdCategoria}/${subcategoria_slug}`);

        }

    }

    const filtro_productos = (e) => {
        page = 0;
        limpiar_array_producto();
        obtenerProductos();
    };
    const handleChange = (event) => {
        localStorage.setItem("filter_loc", event.target.value);
        setFiltro(event.target.value);
    };
    const handleChange2 = (event) => {
        setFiltrosubcategoria(event.target.value)
    };
    const verifyProductos = () => {
        if (productos !== undefined && productos !== null) {
            return true;
        }
        return false;
    }
    const obtenerCantidad = () => {
        if (verifyProductos) {
            return productos.length;
        }
        return 0;
    }
    const enter_opcion = (event) => {
        if (event.key === 'Enter') {
            filtro_productos();
        }
    };

    async function verifyLista() {
        await delay(2000);
        if (!productos_peticion) {
            return true;
        }
        return false;
    }


    return (
        <Layout>
            <Categoria/>
            <BackToPage ps_url_page={`/${convertSlug.data_barra(ciudadp + ' ' + sucursalp)}`}
                        ps_contenerdor={'sinContenedor'}></BackToPage>
            <Alert></Alert>
            <main className="relative flex-grow">

                <div className="mx-auto max-w-[1920px]  container_main">

                    <div className="w-full content_responsivo_movil px-2 relative z-0">
                        <h4 className="text-center text-hiperorange">{categoria.Descripcion}</h4>
                        <Swiper
                            breakpoints={breakpoints}
                            slidesPerView={1}
                            loop={false}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper  py-2 text-xs text-center font-light overflow-hidden"
                            style={{
                                "--swiper-navigation-color": "#32338E",
                                "--swiper-navigation-size": "20px",
                                "--swiper-navigation-weight": "900"
                            }}
                        >
                            {
                                arraySubcategorias &&
                                arraySubcategorias !== null &&
                                arraySubcategorias !== undefined &&
                                arraySubcategorias.map((subcategory, index) => (
                                    <SwiperSlide key={index}
                                                 className={"alineado_subcategoria"}
                                                 style={{
                                                     alignSelf: 'center',
                                                     display: 'flex',
                                                     justifyContent: 'center'
                                                 }}
                                    >
                                        <div className="relative">
                                            <div
                                                className="py-1.5 px-2 rounded-lg border  cursor-pointer slider_opciones"
                                                style={{
                                                    backgroundColor: (Number(subcategory.IdSubcategoria) === Number(subcategoria.IdSubcategoria) && allcategory === false) ? 'var(--primario)' : "transparent",
                                                    color: (Number(subcategory.IdSubcategoria) === Number(subcategoria.IdSubcategoria) && allcategory === false) ? '#FFFFFF' : 'var(--secundario)'
                                                }}
                                                onClick={() => selectSubcategory(subcategory)}
                                            >

                                                {subcategory.Descripcion}
                                            </div>
                                        </div>

                                    </SwiperSlide>

                                ))
                            }
                        </Swiper>

                    </div>
                    <div name="grid" className="flex pb-16 pt-1 container_categoria" style={{overflow: 'auto'}}>
                        <div className="sticky top-5 h-full w-80 subcontainer_cat content_responsivo_desktop">
                            <div className="blocky">
                                <div
                                    className="max-h-full overflow-hidden rounded-xl border border-border-base shadow">
                                    <h3 className="text-center text-hiperorange">{categoria.Descripcion}</h3>

                                    <div className="relative flex w-full flex-wrap items-stretch px-4">
                                        <input type="text"
                                               className=" py-1.5 placeholder-slate-400 text-slate-600 relative rounded-2xl text-sm border border-hiperorange  outline-none w-full"
                                               value={filtrosubcategoria}
                                               onChange={handleChange2}
                                               placeholder='Buscar categoría'
                                               style={{paddingRight: '2rem', paddingLeft: '0.5rem'}}
                                        />
                                        <span
                                            className=" absolute text-hiperorange right-5 pr-3 py-1.5">
                                            <FontAwesomeIcon icon={faSearch} onClick={(e) => handleChange2(e)}/>
                                          </span>
                                    </div>
                                    <div className="mt-2">
                                        <ul className=" px-3 grid gap-1">
                                            {
                                                arraySubcategorias &&
                                                arraySubcategorias !== null &&
                                                arraySubcategorias !== undefined &&
                                                arraySubcategorias.length > 0 &&
                                                arraySubcategorias.map((subcategory, index) =>
                                                    <li key={index}
                                                        className={classNames("py-1.5", "px-2", "rounded-lg", "cursor-pointer",
                                                            {
                                                                "border": (Number(subcategory.IdSubcategoria) !== Number(subcategoria.IdSubcategoria) )
                                                            })}
                                                        style={{
                                                            backgroundColor: (Number(subcategory.IdSubcategoria) === Number(subcategoria.IdSubcategoria) && allcategory === false) ? 'var(--primario)' : "transparent",
                                                            color: (Number(subcategory.IdSubcategoria) === Number(subcategoria.IdSubcategoria) && allcategory === false) ? '#FFFFFF' : 'var(--secundario)'
                                                        }}
                                                        onClick={() => selectSubcategory(subcategory)}
                                                    >{subcategory.Descripcion}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        < div className="w-full   container_prod">
                            <div className="text-center my-3" ref={myDivRef} id="myDivRef">
                                <div className="relative flex w-full flex-wrap items-stretch px-4">
                                    <input type="text"
                                           className=" py-1.5 placeholder-slate-400 text-slate-600 relative rounded-2xl text-sm border border-hiperorange  outline-none w-full "
                                           value={filtro}
                                           onChange={handleChange}
                                           onKeyDown={enter_opcion}
                                           placeholder='Buscar en esta categoría'
                                           style={{paddingRight: '2.2rem', paddingLeft: '0.5rem'}}
                                    />
                                    <span
                                        className=" absolute text-hiperorange right-5 pr-3 py-1.5">
                                        <FontAwesomeIcon icon={faSearch} onClick={(e) => filtro_productos(e)}/>
                                    </span>
                                </div>
                            </div>

                            <div className="w-100 p-2">

                                <InfiniteScroll
                                    // height={"30rem"}
                                    dataLength={obtenerCantidad}
                                    next={obtenerProductosTudo}
                                    hasMore={true}
                                    loader={
                                        peticion ?
                                            <LoaderSpinner bool={peticion}></LoaderSpinner>
                                            :
                                            end_message &&
                                            <div className="flex w-full justify-center pt-4">
                                                <p className="text-gray-400 p-0 m-0">No hay mas productos para mostrar</p>
                                            </div>
                                    }

                                    // style={{
                                    //     display: 'flex',
                                    //     justifyContent: 'center',
                                    //     flexWrap: 'wrap',
                                    //     // gap: '1rem',
                                    //     paddingBottom: '2rem'
                                    // }}
                                    endMessage={
                                        <p style={{textAlign: 'center'}}>
                                            <b>No hay mas productos para mostrar1</b>
                                        </p>
                                    }
                                >
                                    <div className="content_infinit" style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        flexWrap: 'wrap',
                                        // gap: '1rem',
                                        // paddingBottom: '2rem',
                                        marginLeft:"15px"
                                    }}>
                                        {
                                            productos &&
                                            productos !== null &&
                                            productos !== undefined &&
                                            productos.map((producto, index) => (
                                                <ItemProducto key={index} producto={producto}
                                                              ps_IdSucursal={sucursal.IdSucursal}/>
                                            ))
                                        }
                                    </div>

                                </InfiniteScroll>
                            </div>

                        </div>
                    </div>
                </div>
                <button className="footer-button  bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
                        type="button" onClick={() => subir()}>
                    <FontAwesomeIcon icon={faArrowUp}/>
                </button>
            </main>
        </Layout>
    );
};
const mapStateToProps = state => ({
    sucursal: state.BranchOffice.sucursal,
    categorias: state.Home.categorias,
    subcategorias: state.Home.subcategorias,
    category: state.Home.category,
    subcategoria_id: state.Home.subcategoria_id,

    loading: state.Producto.loading,
    productos: state.Producto.productos,
    productos_peticion: state.Producto.productos_peticion,
    productosCategory: state.Home.productosCategory,
    peticion: state.Peticion.peticion,
    end_message:state.Producto.end_message,

})

export default connect(mapStateToProps, {
    get_categorias,
    get_producto,
    get_filtro_productos_categoria,
    get_all_producto,
    set_all_prod_category,
    limpiar_array_producto
})(HomeCategoria)

// export default HomeCategoria;